var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(
      !_vm.loading &&
      _vm.questionnaireTemplate &&
      _vm.questionnaireAssignment &&
      _vm.questionnaireNavigation
    )?_c('v-row',{staticClass:"flex-grow-1 fill-height"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-treeview',{staticClass:"pl-0",attrs:{"active":[_vm.currentSection],"loading":_vm.loading,"items":_vm.questionnaireTemplate.sections,"item-children":"sections","return-object":"","activatable":"","open-on-click":"","transition":"","expand-icon":""},on:{"update:active":_vm.onSectionSelect},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_vm._v("\n          "+_vm._s(item.label)+"\n        ")]}},{key:"append",fn:function({ item }){return [_c('div',{staticClass:"barlow font-weight-medium body-1",staticStyle:{"width":"30px"}},[(
                _vm.sectionCompletionMapping &&
                _vm.sectionCompletionMapping[item.id] &&
                _vm.sectionCompletionMapping[item.id].isCompleted
              )?_c('v-icon',[_vm._v("mdi-check")]):_c('v-progress-linear',{attrs:{"value":(_vm.sectionCompletionMapping &&
                _vm.sectionCompletionMapping[item.id] &&
                _vm.sectionCompletionMapping[item.id].percentageCompleted &&
                _vm.sectionCompletionMapping[item.id].percentageCompleted
                  ? _vm.sectionCompletionMapping[item.id].percentageCompleted
                  : 0) * 100}})],1)]}}],null,false,1195362922)})],1),_vm._v(" "),_c('v-col',{staticClass:"max-h-[calc(100vh-80px)] overflow-scroll"},[(_vm.currentSection)?_c('div',[_c('ix-section',[_c('ix-section-text',{staticClass:"py-1 justify-center align-center",staticStyle:{"max-width":"1200px","margin-left":"auto","margin-right":"auto"}},[(_vm.currentSection.ixId === 'basicInformation' && !_vm.disabled)?_c('v-row',[_c('ix-form-input-text-master',{attrs:{"id":"companyName","title":_vm.$t('name'),"value":_vm.accountVenture.name},on:{"input":(val) => _vm.triggerUpdateAccount('name', val)}}),_vm._v(" "),_c('ix-form-input-text-master',{attrs:{"id":"companyUrl","title":_vm.$t('url'),"is-mandatory":false,"value":_vm.accountVenture.website},on:{"input":(val) => _vm.triggerUpdateAccount('website', val)}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"is-mandatory":false,"title":_vm.$t('logo')}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('company-logo',{attrs:{"logo":_vm.accountVenture.logo,"name":_vm.accountVenture.name,"tooltip-direction":{ right: false, top: true }}})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',_vm._l((_vm.currentSection.questions),function(question,f_index){return _c('v-col',{key:f_index,attrs:{"cols":"12"}},[_c('v-scroll-y-transition',[_c(question.component,_vm._b({tag:"component",attrs:{"user-input":_vm.userInputMapping[question.id],"user-input-mapping":_vm.userInputMapping,"is-editable":_vm.questionnaireAssignment.isEditable}},'component',question,false))],1)],1)}),1),_vm._v(" "),(
                _vm.currentSection.ixId === 'additionalContextInformation' &&
                _vm.esgActionsActive
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('csrdQuestion')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
                    { value: 'yes', label: _vm.$t('companyInfo.csrd.yes') },
                    { value: 'no', label: _vm.$t('companyInfo.csrd.no') },
                    {
                      value: 'iDontKnow',
                      label: _vm.$t('companyInfo.csrd.iDontKnow'),
                    },
                  ],"value":_vm.accountVenture.csrdRequirement?.report,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) => {
                      if (val === 'yes') {
                        _vm.triggerUpdateAccount('csrdRequirement', {
                          report: val,
                        })
                      } else if (val === 'no' || val === 'iDontKnow') {
                        _vm.triggerUpdateAccount('csrdRequirement', {
                          report: val,
                          date: null,
                        })
                      } else if (val === undefined) {
                        _vm.triggerUpdateAccount('csrdRequirement', {
                          report: null,
                          date: null,
                        })
                      }
                    }}})],1),_vm._v(" "),(_vm.accountVenture.csrdRequirement?.report === 'yes')?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('csrdSubquestion')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
                    {
                      value: '2025',
                      label: _vm.$t('companyInfo.csrd.options.2025'),
                    },
                    {
                      value: '2026',
                      label: _vm.$t('companyInfo.csrd.options.2026'),
                    },
                    {
                      value: '2027',
                      label: _vm.$t('companyInfo.csrd.options.2027'),
                    },
                    {
                      value: 'later',
                      label: _vm.$t('companyInfo.csrd.options.later'),
                    },
                  ],"value":_vm.accountVenture.csrdRequirement?.date,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) => {
                      if (val === undefined) {
                        _vm.triggerUpdateAccount('csrdRequirement', {
                          date: null,
                        })
                      } else {
                        _vm.triggerUpdateAccount('csrdRequirement', {
                          date: val,
                        })
                      }
                    }}})],1):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('ix-button-info',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('companyInfo.explanation.title')))])]},proxy:true},{key:"content",fn:function(){return [_c('esg-company-survey-explanation')]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }