import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useVentureId } from '~/functions/accounts';
import { Module, useGetAccountQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureName: {
      type: String,
      default: undefined
    },
    website: {
      type: String,
      default: undefined
    }
  },
  setup: function setup() {
    var ventureId = useVentureId();
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId === null || ventureId === void 0 ? void 0 : ventureId.value
      }),
      result = _useGetAccountQuery.result;
    var hasEsgModule = computed(function () {
      var _result$value, _result$value$getAcco, _result$value$getAcco2;
      return !!((_result$value = result.value) !== null && _result$value !== void 0 && (_result$value$getAcco = _result$value.getAccount) !== null && _result$value$getAcco !== void 0 && (_result$value$getAcco2 = _result$value$getAcco.bookedModules) !== null && _result$value$getAcco2 !== void 0 && _result$value$getAcco2.includes(Module.Esg));
    });
    var hasImpactGoalModule = computed(function () {
      var _result$value2, _result$value2$getAcc, _result$value2$getAcc2;
      return !!((_result$value2 = result.value) !== null && _result$value2 !== void 0 && (_result$value2$getAcc = _result$value2.getAccount) !== null && _result$value2$getAcc !== void 0 && (_result$value2$getAcc2 = _result$value2$getAcc.bookedModules) !== null && _result$value2$getAcc2 !== void 0 && _result$value2$getAcc2.includes(Module.ImpactGoal));
    });
    var hasMetricsModule = computed(function () {
      var _result$value3, _result$value3$getAcc, _result$value3$getAcc2;
      return !!((_result$value3 = result.value) !== null && _result$value3 !== void 0 && (_result$value3$getAcc = _result$value3.getAccount) !== null && _result$value3$getAcc !== void 0 && (_result$value3$getAcc2 = _result$value3$getAcc.bookedModules) !== null && _result$value3$getAcc2 !== void 0 && _result$value3$getAcc2.includes(Module.Metrics));
    });
    var hasKfWMetricAssignments = computed(function () {
      var _result$value4, _result$value4$getAcc;
      return !!((_result$value4 = result.value) !== null && _result$value4 !== void 0 && (_result$value4$getAcc = _result$value4.getAccount) !== null && _result$value4$getAcc !== void 0 && _result$value4$getAcc.hasKfw) || false;
    });
    var _useGetAccountQuery2 = useGetAccountQuery(),
      loggedInAccountResult = _useGetAccountQuery2.result,
      loggedInAccountLoading = _useGetAccountQuery2.loading;
    var loggedInAccountType = computed(function () {
      var _loggedInAccountResul, _loggedInAccountResul2;
      return (_loggedInAccountResul = loggedInAccountResult.value) === null || _loggedInAccountResul === void 0 ? void 0 : (_loggedInAccountResul2 = _loggedInAccountResul.getAccount) === null || _loggedInAccountResul2 === void 0 ? void 0 : _loggedInAccountResul2.type;
    });
    return {
      ventureId: ventureId,
      hasEsgModule: hasEsgModule,
      hasImpactGoalModule: hasImpactGoalModule,
      hasMetricsModule: hasMetricsModule,
      hasKfWMetricAssignments: hasKfWMetricAssignments,
      loggedInAccountType: loggedInAccountType,
      loggedInAccountLoading: loggedInAccountLoading
    };
  }
});