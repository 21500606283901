import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.function.name.js";
import CustomComparisonModal from '~/components/impact-goal/edit/CustomComparisonModal.vue';
import IdematComparisonModal from '~/components/impact-goal/edit/IdematComparisonModal.vue';
import { renderMarkdown } from '~/functions/markdown';
import { useUpsertEmissionSourceMutation } from '~/types/types';
export default defineComponent({
  name: 'ComparativeEmissionSourceForm',
  components: {
    CustomComparisonModal: CustomComparisonModal,
    IdematComparisonModal: IdematComparisonModal
  },
  props: {
    toEditEmissionSource: {
      type: Object,
      required: true
    },
    impactSolution: {
      type: Object,
      required: true
    },
    resetComparativeDialogForm: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup: function setup(props, context) {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useUpsertEmissionSou = useUpsertEmissionSourceMutation(),
      upsertEmissionSourceMutation = _useUpsertEmissionSou.mutate;
    var emissionSource = ref(_objectSpread({}, props.toEditEmissionSource));

    // Method to reset the form to the original state
    var resetComparativeForm = function resetComparativeForm() {
      emissionSource.value = _objectSpread({}, props.toEditEmissionSource);
    };
    var handleSelectedItem = function handleSelectedItem(item) {
      var _item$unit;
      if (!item) {
        return;
      }
      if ((_item$unit = item.unit) !== null && _item$unit !== void 0 && _item$unit.includes('/')) {
        item.unit = item.unit.split('/')[1];
      }
      emissionSource.value = _objectSpread(_objectSpread({}, emissionSource.value), {}, {
        baselineGhgFactor: item.factor,
        unitName: item.unit,
        baselineConceptName: item.name,
        baselineEvidence: item.evidence
      });
    };
    var handleCustomSelectedItem = function handleCustomSelectedItem(item, dialog) {
      var _item$unit2;
      if (!item) {
        return;
      }
      if ((_item$unit2 = item.unit) !== null && _item$unit2 !== void 0 && _item$unit2.includes('/')) {
        item.unit = item.unit.split('/')[1];
      }
      emissionSource.value = _objectSpread(_objectSpread({}, emissionSource.value), {}, {
        baselineGhgFactor: item.factor,
        unitName: item.unit,
        baselineConceptName: item.name,
        baselineEvidence: item.evidence
      });
      dialog.value = false;
    };
    var upsertEmissionSource = function upsertEmissionSource(fields) {
      upsertEmissionSourceMutation({
        input: _objectSpread({
          ghgSavingPotentialId: props.impactSolution.ghgSavingPotentialId,
          ventureId: ventureId.value
        }, fields)
      });
    };

    // Convert prop to a reactive ref
    var resetComparativeDialogFormRef = toRef(props, 'resetComparativeDialogForm');

    // Watch the reactive reference of resetDialogForm
    watch(resetComparativeDialogFormRef, function () {
      resetComparativeForm();
    });
    var getFactorText = function getFactorText(emissionSource) {
      if (emissionSource.baselineGhgFactor) {
        return emissionSource.baselineGhgFactor + ' kg CO2e / ' + emissionSource.unitName;
      } else {
        return '';
      }
    };
    var cancel = function cancel() {
      context.emit('close');
    };
    var saveAndClose = function saveAndClose() {
      var upsertDocument = {
        id: emissionSource.value.id,
        unitName: emissionSource.value.unitName,
        customBaselineConcept: false,
        baselineConceptName: emissionSource.value.baselineConceptName,
        baselineConsumption: Number(emissionSource.value.baselineConsumption),
        baselineGhgFactor: Number(emissionSource.value.baselineGhgFactor),
        baselineAssumption: emissionSource.value.baselineAssumption,
        baselineEvidence: emissionSource.value.baselineEvidence
      };

      // eslint-disable-next-line no-prototype-builtins
      if (emissionSource.value.hasOwnProperty('customBaselineConcept')) {
        upsertDocument.customBaselineConcept = emissionSource.value.customBaselineConcept || false;
      }
      var changeConsumption = props.toEditEmissionSource.solutionReducesConsumption;
      var alternativeGhg = props.toEditEmissionSource.solutionReducesGhgFactor;
      if (alternativeGhg === true && changeConsumption === false) {
        upsertDocument.solutionConsumption = Number(emissionSource.value.baselineConsumption);
      }
      if (alternativeGhg === false && changeConsumption === true) {
        upsertDocument.solutionConceptName = emissionSource.value.baselineConceptName;
        upsertDocument.solutionGhgFactor = Number(emissionSource.value.baselineGhgFactor);
        upsertDocument.solutionEvidence = emissionSource.value.baselineEvidence;
      }
      upsertEmissionSource(_objectSpread({}, upsertDocument));
      context.emit('close');
    };
    var impactProfile = computed(function () {
      var _props$impactSolution, _props$impactSolution2;
      return (_props$impactSolution = props.impactSolution) === null || _props$impactSolution === void 0 ? void 0 : (_props$impactSolution2 = _props$impactSolution.ghgSavingPotential) === null || _props$impactSolution2 === void 0 ? void 0 : _props$impactSolution2.impactProfileKind;
    });
    var disableSaveButton = computed(function () {
      var consumption = emissionSource.value.baselineConsumption;
      return !(consumption || consumption === 0);
    });
    var rules = {
      required: function required(value) {
        return !!value || 'Field is required';
      }
    };
    return {
      upsertEmissionSourceMutation: upsertEmissionSourceMutation,
      ventureId: ventureId,
      emissionSource: emissionSource,
      renderMarkdown: renderMarkdown,
      handleSelectedItem: handleSelectedItem,
      handleCustomSelectedItem: handleCustomSelectedItem,
      saveAndClose: saveAndClose,
      getFactorText: getFactorText,
      cancel: cancel,
      impactProfile: impactProfile,
      rules: rules,
      disableSaveButton: disableSaveButton
    };
  }
});