export default defineComponent({
  props: {
    content: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'blue-grey lighten-5'
    }
  },
  setup: function setup() {}
});