import { render, staticRenderFns } from "./search.vue?vue&type=template&id=6548491a"
import script from "./search.vue?vue&type=script&lang=ts"
export * from "./search.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchEvidenceForVenture: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/search/EvidenceForVenture.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})
