import { render, staticRenderFns } from "./ImpactSummaryWithStages.vue?vue&type=template&id=05301ac6"
import script from "./ImpactSummaryWithStages.vue?vue&type=script&lang=ts"
export * from "./ImpactSummaryWithStages.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,ImpactOverview: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactOverview.vue').default,ImpactSummary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactSummary.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCol,VDivider,VIcon,VRow})
