import { render, staticRenderFns } from "./_assessment.vue?vue&type=template&id=3020b5ae&scoped=true"
import script from "./_assessment.vue?vue&type=script&lang=ts"
export * from "./_assessment.vue?vue&type=script&lang=ts"
import style0 from "./_assessment.vue?vue&type=style&index=0&id=3020b5ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3020b5ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactAssessmentDetails: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactAssessmentDetails.vue').default,Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default,ScorePill: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ScorePill.vue').default,ImpCategoryCard: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpCategoryCard.vue').default,RiskCategoryCard: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/RiskCategoryCard.vue').default,SearchEvidenceForImpact: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/search/EvidenceForImpact.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCardText,VCardTitle,VCol,VDivider,VFabTransition,VIcon,VRow,VSnackbar,VSpacer})
