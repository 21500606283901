import { render, staticRenderFns } from "./_fund.vue?vue&type=template&id=0b0753bd&scoped=true"
import script from "./_fund.vue?vue&type=script&lang=ts"
export * from "./_fund.vue?vue&type=script&lang=ts"
import style0 from "./_fund.vue?vue&type=style&index=0&id=0b0753bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0753bd",
  null
  
)

/* custom blocks */
import block0 from "./_fund.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default,IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,IxSubSectionSubtitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionSubtitle.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default,SfdrActionMenu: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/ActionMenu.vue').default,SfdrExportPrecontractual: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/export/precontractual.vue').default,SfdrExportPeriodic: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/export/periodic.vue').default,SfdrExportPai: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/export/pai.vue').default,SfdrExportResultsView: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/export/ResultsView.vue').default,IxDataTableMetricAggregations: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/MetricAggregations.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VRow,VSelect,VTab,VTabItem,VTabs,VTabsItems})
