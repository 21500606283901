function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import VueMarkdown from 'vue-markdown-render';
export default defineComponent({
  components: {
    VueMarkdown: VueMarkdown
  },
  props: {
    indicator: Object,
    indicatorId: String,
    isPositive: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      confirmed: false,
      vuetifyValid: false,
      customValid: false,
      rules: {
        requiredRule: [function (v) {
          return !!v || 'This field is required';
        }],
        requiredRuleScore: function requiredRuleScore(v) {
          return v !== undefined;
        }
      }
    };
  },
  computed: {
    allValid: function allValid() {
      return this.vuetifyValid && this.customValid;
    },
    options: function options() {
      return this.scoringOptions(this.indicator['Scoring options (text form)']);
    },
    shortExplanation: function shortExplanation() {
      var _this = this;
      if (!this.indicator.Score) {
        return '';
      }
      var options = this.options;
      var currentOption = options.find(function (e) {
        return e.key === _this.indicator.Score.toString();
      });
      if (currentOption) {
        return this.formatItemExplanation(currentOption.value);
      } else {
        return '';
      }
    },
    outcomeId: function outcomeId() {
      return this.$route.params.assessment;
    },
    outcomeEditingState: function outcomeEditingState() {
      return this.$store.getters['assessment/getEditingState'](this.outcomeId);
    }
  },
  methods: {
    formatScoringOptions: function formatScoringOptions(options) {
      var lines = options.match(/(^.*|[\d.]+%\s?=\s?.*)/g);
      if (lines === null) return '';
      return lines.join('\n\n');
    },
    formatItemExplanation: function formatItemExplanation(itemExplanation) {
      if (itemExplanation !== undefined) {
        var match = itemExplanation.match(/\*\*([^*]+?)\*\*/g);
        if (match !== null) {
          return match.map(function (e) {
            return e.substr(2, e.length - 4);
          }).join(' & ');
        }
        return itemExplanation;
      }
      return '';
    },
    updateIndicatorScore: function updateIndicatorScore(value) {
      this.confirmed = false;
      this.$store.commit('assessment/UPDATE_INDICATOR', {
        indicatorId: this.indicator.id,
        outcomeId: this.outcomeId,
        payload: {
          Score: value,
          Confirmed: this.confirmed
        }
      });
      this.customValid = this.rules.requiredRuleScore(this.indicator.Score);
      this.$emit('change');
    },
    updateIndicatorComment: function updateIndicatorComment(value) {
      this.confirmed = false;
      this.$store.commit('assessment/UPDATE_INDICATOR', {
        indicatorId: this.indicator.id,
        outcomeId: this.outcomeId,
        payload: {
          'Text for customer': value,
          Confirmed: this.confirmed
        }
      });
      this.$emit('change');
    },
    confirmIndicator: function confirmIndicator() {
      if (this.allValid) {
        this.confirmed = true;
        this.$store.commit('assessment/UPDATE_INDICATOR', {
          indicatorId: this.indicator.id,
          outcomeId: this.outcomeId,
          payload: {
            Confirmed: this.confirmed
          }
        });
        this.$store.dispatch('assessment/updateIndicator', {
          rid: this.indicatorId,
          fields: {
            Confirmed: this.confirmed,
            Score: this.indicator.Score,
            'Text for customer': this.indicator['Text for customer']
          }
        });
        this.$emit('change');
      }
    },
    unconfirmIndicator: function unconfirmIndicator() {
      this.confirmed = false;
      this.$store.commit('assessment/UPDATE_INDICATOR', {
        indicatorId: this.indicator.id,
        outcomeId: this.outcomeId,
        payload: {
          Confirmed: this.confirmed
        }
      });
      this.$store.dispatch('assessment/updateIndicator', {
        rid: this.indicatorId,
        fields: {
          Confirmed: this.confirmed
        }
      });
      this.$emit('change');
    },
    scoringOptions: function scoringOptions(optionsText) {
      var options = [];
      var _iterator = _createForOfIteratorHelper(optionsText.matchAll(/([\d.]+)%\s?=\s?(.*)/g)),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var match = _step.value;
          options.push({
            key: (parseFloat(match[1]) / 100).toString(),
            value: match[2]
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return options;
    },
    mounted: function mounted() {
      this.confirmed = this.indicator.Confirmed || false;
      this.customValid = this.rules.requiredRuleScore(this.indicator.Score);
    }
  }
});