var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-layout',{attrs:{"align-baseline":""}},[_c('h4',{staticClass:"mt-0 mb-2"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.isMandatory === true)?_c('span',[(Boolean(_vm.title))?_c('ix-info-tooltip',{attrs:{"text":_vm.$t('esg.questionnaire.mandatory'),"bottom":""},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('span',_vm._g(_vm._b({staticClass:"red--text"},'span',slotProps.attrs,false),slotProps.on),[_vm._v("\n            *\n          ")])]}}],null,false,2297425020)}):_vm._e()],1):_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('esg.questionnaire.optional')))]),_vm._v(" "),((_vm.tooltip && _vm.tooltip !== '') || _vm.$slots.tooltipComponent)?_c('v-dialog',{staticClass:"max-w-screen-md",scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,false,3930023427)},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_vm._v("\n            "+_vm._s(_vm.tooltipTitle
                ? _vm.tooltipTitle
                : _vm.$t('esg.surveyPages.questionAdditionalInformation'))+"\n          ")]),_vm._v(" "),_c('v-card-text',[(_vm.tooltip && _vm.tooltip !== '')?_c('div',{staticClass:"markdown-field survey-question-tooltip",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_vm.tooltip))}}):(_vm.$slots.tooltipComponent)?_vm._t("tooltipComponent"):_vm._e()],2)],1)],1):_vm._e(),_vm._v(" "),(
          _vm.id &&
          _setup.showDebugging &&
          _vm.$attrs.__typename == 'QuestionSectionLinkType'
        )?_c('ix-dialog-question',{attrs:{"id":_vm.id}}):_vm._e(),_vm._v(" "),(_vm.id && _setup.showDebugging)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$attrs.expanded),expression:"$attrs.expanded"}],on:{"click":function($event){return _vm.$emit('expand')}}},[_vm._v("\n        mdi-chevron-down\n      ")]):_vm._e(),_vm._v(" "),(_vm.id && _setup.showDebugging)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$attrs.expanded),expression:"!$attrs.expanded"}],on:{"click":function($event){return _vm.$emit('expand')}}},[_vm._v("\n        mdi-chevron-up\n      ")]):_vm._e(),_vm._v(" "),(_setup.showDebugging && (_vm.ixId || _vm.$attrs.ixId))?_c('div',{staticStyle:{"color":"red"}},[_vm._v("\n        "+_vm._s(_vm.ixId ? _vm.ixId : _vm.$attrs.ixId)+"\n        "),_c('v-badge',{directives:[{name:"badge",rawName:"v-badge"}],attrs:{"bordered":"","color":"error","icon":"mdi-lock","overlap":""}})],1):_vm._e()],1)]),_vm._v(" "),(_vm.subtitle && _vm.subtitle !== '')?_c('p',{staticClass:"markdown-field body-2",staticStyle:{"color":"#5b7185 !important"},domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_vm.subtitle))}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }