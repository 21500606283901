import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.find.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { renderMarkdown } from '~/functions/markdown';
import { useDeleteImpactSolutionMutation, useGetImpactGoalQuery, useUpsertImpactSolutionMutation } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var impactId = computed(function () {
      return parseInt(route.params.impact);
    });
    var _useGetImpactGoalQuer = useGetImpactGoalQuery({
        ventureId: ventureId.value
      }),
      impactGoalResult = _useGetImpactGoalQuer.result,
      refetch = _useGetImpactGoalQuer.refetch;
    var impactGoal = computed(function () {
      var _impactGoalResult$val;
      return impactGoalResult === null || impactGoalResult === void 0 ? void 0 : (_impactGoalResult$val = impactGoalResult.value) === null || _impactGoalResult$val === void 0 ? void 0 : _impactGoalResult$val.getImpactGoal;
    });
    var impact = computed(function () {
      var _impactGoal$value, _impactGoal$value$imp;
      return ((_impactGoal$value = impactGoal.value) === null || _impactGoal$value === void 0 ? void 0 : (_impactGoal$value$imp = _impactGoal$value.impacts) === null || _impactGoal$value$imp === void 0 ? void 0 : _impactGoal$value$imp.find(function (e) {
        return e.id === impactId.value;
      })) || {
        id: null
      };
    });
    var _useDeleteImpactSolut = useDeleteImpactSolutionMutation(),
      deleteImpactSolution = _useDeleteImpactSolut.mutate,
      onDeleteDone = _useDeleteImpactSolut.onDone;
    onDeleteDone(function () {
      refetch();
    });
    var _useUpsertImpactSolut = useUpsertImpactSolutionMutation(),
      upsertImpactSolutionMutation = _useUpsertImpactSolut.mutate,
      onUpsertImpactSolutionDone = _useUpsertImpactSolut.onDone;
    onUpsertImpactSolutionDone(function () {
      refetch();
    });
    var loading = useQueryLoading();
    return {
      ventureId: ventureId,
      loading: loading,
      upsertImpactSolutionMutation: upsertImpactSolutionMutation,
      deleteImpactSolution: deleteImpactSolution,
      impact: impact
    };
  },
  data: function data() {
    return {
      editImpactSolutionDialog: false
    };
  },
  computed: {},
  methods: {
    renderMarkdown: renderMarkdown,
    upsertImpactSolution: function upsertImpactSolution(fields) {
      var _this$impact, _this$impact2;
      if (!((_this$impact = this.impact) !== null && _this$impact !== void 0 && _this$impact.id)) {
        return;
      }
      this.upsertImpactSolutionMutation({
        input: _objectSpread({
          ventureId: this.ventureId,
          impactId: (_this$impact2 = this.impact) === null || _this$impact2 === void 0 ? void 0 : _this$impact2.id
        }, fields)
      });
    }
  }
});