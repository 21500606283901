var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"fill-height"},[_c('ix-section',{attrs:{"tile":"","flat":""}},[_c('v-card-actions',{staticClass:"pt-4"},[_c('ix-btn-navigation',{staticClass:"mr-4"}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","width":"50%","eager":""},model:{value:(_vm.customMetricFormDialog),callback:function ($$v) {_vm.customMetricFormDialog=$$v},expression:"customMetricFormDialog"}},[_c('metrics-aggregation-batch-dialog',{attrs:{"selected-metrics":_vm.selectedMetrics,"type":"add","portfolio-id":_vm.account.id},on:{"cancel":function($event){_vm.customMetricFormDialog = false},"finish":() => {
              _vm.customMetricFormDialog = false
              _vm.selectedMetrics = []
              _vm.$router.go(-1)
            }}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('ix-data-table-metrics',{attrs:{"query":{
      assignedTos: _vm.paramAssignedTos,
      sections: [
        117, // 'IRIS Indicators',
        82, // 'SFDR: PAI Indicators',
        967, // 'ImpactNexus Indicators',
        989, // 'EU taxonomy',
        998, // 'EDCI Metrics',
        1101, // 'KfW'
      ],
      text: _vm.$route.query.search,
    }},on:{"update:query":(v) => {
        if (v?.text) {
          _vm.$router.replace({ query: { ..._vm.$route.query, search: v.text } })
        }
      },"usemetricsastemplate":_vm.addMetricAssignmentsFromTemplate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }