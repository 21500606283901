export const geographies = [
  {
    name: 'Africa',
    id: 'recyzzGK5aP6drNR2',
  },
  {
    name: 'Northern Africa',
    id: 'recvAYkonX042D1RW',
  },
  {
    name: 'Algeria',
    id: 'recvThgTBFXLbhR3o',
  },
  {
    name: 'Egypt',
    id: 'recdLdyaoUv8l9VE9',
  },
  {
    name: 'Libya',
    id: 'rec2eQ6JayF9WhEy3',
  },
  {
    name: 'Morocco',
    id: 'reczIiDCGWGgVHDMS',
  },
  {
    name: 'Sudan',
    id: 'recHRRFufFVxgxJ05',
  },
  {
    name: 'Tunisia',
    id: 'recbeHtYdIjFrsmkL',
  },
  {
    name: 'Western Sahara',
    id: 'rec0D9lYdVKyjDgfP',
  },
  {
    name: 'Sub-Saharan Africa',
    id: 'recGSHEG1uIVexD8W',
  },
  {
    name: 'Eastern Africa',
    id: 'rec561w6diW9ns6nd',
  },
  {
    name: 'British Indian Ocean Territory',
    id: 'rechgTId9gLZlv20M',
  },
  {
    name: 'Burundi',
    id: 'recth5i48Qvnz73Jv',
  },
  {
    name: 'Comoros',
    id: 'recerrZzaSlr7v0KV',
  },
  {
    name: 'Djibouti',
    id: 'recjR2PSHB24LxF6B',
  },
  {
    name: 'Eritrea',
    id: 'recB9WTbq6D6Kt2VC',
  },
  {
    name: 'Ethiopia',
    id: 'recCgco9rGqMhAMwi',
  },
  {
    name: 'French Southern Territories',
    id: 'rectCWeXNPJEjVoNZ',
  },
  {
    name: 'Kenya',
    id: 'recYinshe1VIYysD9',
  },
  {
    name: 'Madagascar',
    id: 'rechRVu2mdahjQE4s',
  },
  {
    name: 'Malawi',
    id: 'recvNk9pe2aiCMzpt',
  },
  {
    name: 'Mauritius',
    id: 'recB2vcEgb2CDOy9G',
  },
  {
    name: 'Mayotte',
    id: 'reclO5p9SUK0LX40C',
  },
  {
    name: 'Mozambique',
    id: 'reczpOqtwAPbKTnCs',
  },
  {
    name: 'Réunion',
    id: 'recMsBAqfjOhsaUVs',
  },
  {
    name: 'Rwanda',
    id: 'recpCtg5g3y5VaZxp',
  },
  {
    name: 'Seychelles',
    id: 'recdNCyWYSXidkosA',
  },
  {
    name: 'Somalia',
    id: 'rec0pTikLhOkvTMfK',
  },
  {
    name: 'South Sudan',
    id: 'rec2b81yNLxHE6rqf',
  },
  {
    name: 'Uganda',
    id: 'recK60vIzHvvNXSQF',
  },
  {
    name: 'United Republic of Tanzania',
    id: 'recjhynVtWwC2kpJF',
  },
  {
    name: 'Zambia',
    id: 'reciA6XGy0zPZKO6Q',
  },
  {
    name: 'Zimbabwe',
    id: 'recUEiHD7Qhal04SX',
  },
  {
    name: 'Middle Africa',
    id: 'recfvpQFBuKniNcAQ',
  },
  {
    name: 'Angola',
    id: 'recKYtb3neTIvEtKD',
  },
  {
    name: 'Cameroon',
    id: 'recomNqJTXegEK5uU',
  },
  {
    name: 'Central African Republic',
    id: 'recTSbC2UmlySCrrj',
  },
  {
    name: 'Chad',
    id: 'recBR5Bit3XVaM9Kr',
  },
  {
    name: 'Congo',
    id: 'recnXfgmtlQcSTeY0',
  },
  {
    name: 'Democratic Republic of the Congo',
    id: 'recSc0fWOigp9o6xW',
  },
  {
    name: 'Equatorial Guinea',
    id: 'reckNEmuVOlSUrqxw',
  },
  {
    name: 'Gabon',
    id: 'recsXbDwMHzRPax0Y',
  },
  {
    name: 'Sao Tome and Principe',
    id: 'rec4NazN8NKudlGy8',
  },
  {
    name: 'Southern Africa',
    id: 'recuG2t44BAwOxcPq',
  },
  {
    name: 'Botswana',
    id: 'reckDifFoqBmBoci1',
  },
  {
    name: 'Eswatini',
    id: 'recvjqL4nXWf2VogP',
  },
  {
    name: 'Lesotho',
    id: 'recGHJ7BMqQVt3nLH',
  },
  {
    name: 'Namibia',
    id: 'recs2dAwrPau0P7Gx',
  },
  {
    name: 'South Africa',
    id: 'recUC4UD9oexYcjGW',
  },
  {
    name: 'Western Africa',
    id: 'recDCTYEUBH6fYPn7',
  },
  {
    name: 'Benin',
    id: 'recMhvgxiWE6ogGhS',
  },
  {
    name: 'Burkina Faso',
    id: 'recRqesuoOvuqYb2q',
  },
  {
    name: 'Cabo Verde',
    id: 'rec9y8hgXN4T33HC6',
  },
  {
    name: 'Côte d’Ivoire',
    id: 'recJ1xv0IcCWcQcnb',
  },
  {
    name: 'Gambia',
    id: 'reciLeMKQRkGh62s5',
  },
  {
    name: 'Ghana',
    id: 'rec8SUKHFyfIBp23F',
  },
  {
    name: 'Guinea',
    id: 'recukQGYVw8RERnYY',
  },
  {
    name: 'Guinea-Bissau',
    id: 'rec9e17ggH5g1UjRp',
  },
  {
    name: 'Liberia',
    id: 'recEwJPD8hHwzV7uU',
  },
  {
    name: 'Mali',
    id: 'recw1RwjD7ptdZJJ8',
  },
  {
    name: 'Mauritania',
    id: 'recO18qaaA5lt6USH',
  },
  {
    name: 'Niger',
    id: 'recKKjxlT6Ygc8T7C',
  },
  {
    name: 'Nigeria',
    id: 'recoM1K2Y6mWmTs7V',
  },
  {
    name: 'Saint Helena',
    id: 'rec5iIBP63wuKfHuY',
  },
  {
    name: 'Senegal',
    id: 'recJOSghVtpQp82e3',
  },
  {
    name: 'Sierra Leone',
    id: 'recareNkzVqC6WIMU',
  },
  {
    name: 'Togo',
    id: 'recakpVz80MyQr6Br',
  },
  {
    name: 'Americas',
    id: 'recUcnaZPfQCNJDlH',
  },
  {
    name: 'Latin America and the Caribbean',
    id: 'recunGYpnm2IGrTuV',
  },
  {
    name: 'Caribbean',
    id: 'recQx8rgLe9WBEtgo',
  },
  {
    name: 'Anguilla',
    id: 'recGgsx1S9f7tCG0k',
  },
  {
    name: 'Antigua and Barbuda',
    id: 'rech5vfdSBWCdyDuX',
  },
  {
    name: 'Aruba',
    id: 'recnhTky06zKhl7Pu',
  },
  {
    name: 'Bahamas',
    id: 'recglQgYQpHf6pYG0',
  },
  {
    name: 'Barbados',
    id: 'recMgzEAi3kZCAztP',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    id: 'recQQPXMTs8fT6nmz',
  },
  {
    name: 'British Virgin Islands',
    id: 'reclc5cpbnhVbCgx5',
  },
  {
    name: 'Cayman Islands',
    id: 'recKcpMcH1EBtqX31',
  },
  {
    name: 'Cuba',
    id: 'reccjyVOn3TMNTwQo',
  },
  {
    name: 'Curaçao',
    id: 'recuvxgFF7Fzqabe3',
  },
  {
    name: 'Dominica',
    id: 'recGYxwrjbfFVe1Hr',
  },
  {
    name: 'Dominican Republic',
    id: 'recWNKKLpSONWmP5a',
  },
  {
    name: 'Grenada',
    id: 'recWkOue40DYCfeod',
  },
  {
    name: 'Guadeloupe',
    id: 'recvdBK8xaCdTBnxV',
  },
  {
    name: 'Haiti',
    id: 'recVxJQbPK2mkMyEC',
  },
  {
    name: 'Jamaica',
    id: 'recRUCZeW60VUt68r',
  },
  {
    name: 'Martinique',
    id: 'reczXK5ansuE4snxi',
  },
  {
    name: 'Montserrat',
    id: 'recRvnswHoandqyga',
  },
  {
    name: 'Puerto Rico',
    id: 'rectS0U7nqhzV19AS',
  },
  {
    name: 'Saint Barthélemy',
    id: 'recFKy6UdU1cYKvCn',
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 'recLxLbSNRNPdrrLt',
  },
  {
    name: 'Saint Lucia',
    id: 'rec0sO8nirYsVRSP0',
  },
  {
    name: 'Saint Martin (French Part)',
    id: 'recGaGPjG1PO5fY6s',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: 'recMCTiBdda1kyLl8',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    id: 'recLClxcDEP3pEyVv',
  },
  {
    name: 'Trinidad and Tobago',
    id: 'recQqecdZ1vW4vJhv',
  },
  {
    name: 'Turks and Caicos Islands',
    id: 'recYA47ZAoNR1kX75',
  },
  {
    name: 'United States Virgin Islands',
    id: 'recwVHoxzyxTGfoJf',
  },
  {
    name: 'Central America',
    id: 'recYIKsqBZumPBVUL',
  },
  {
    name: 'Belize',
    id: 'recQqbv8RNU6eRJwp',
  },
  {
    name: 'Costa Rica',
    id: 'recfyOom12j5BQ4Q8',
  },
  {
    name: 'El Salvador',
    id: 'rec1P9f7GYikOoNNv',
  },
  {
    name: 'Guatemala',
    id: 'recUbfz6uy2Lwim51',
  },
  {
    name: 'Honduras',
    id: 'recTL5TdhKRuFnQi1',
  },
  {
    name: 'Mexico',
    id: 'rec9TWKIGEQXqf7dx',
  },
  {
    name: 'Nicaragua',
    id: 'reclby2hNXLbcyu8d',
  },
  {
    name: 'Panama',
    id: 'rectdXdyXlxnZf33I',
  },
  {
    name: 'South America',
    id: 'recwhRVmKIv1HYoCj',
  },
  {
    name: 'Argentina',
    id: 'rec0fXaY9ofRIc0f4',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    id: 'recufBKDYh52e7WgM',
  },
  {
    name: 'Bouvet Island',
    id: 'reciEhVnsCHClngy0',
  },
  {
    name: 'Brazil',
    id: 'recZUZYmDuICMZv8O',
  },
  {
    name: 'Chile',
    id: 'recYBw2L60gQZfaMk',
  },
  {
    name: 'Colombia',
    id: 'rectIPodxCzw6Cgyd',
  },
  {
    name: 'Ecuador',
    id: 'rec9tidDdma6HPV2v',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    id: 'recJYKkgnJyiz8K02',
  },
  {
    name: 'French Guiana',
    id: 'recrdwzyptQJyBWPu',
  },
  {
    name: 'Guyana',
    id: 'recPr5yRVpTcE7ODH',
  },
  {
    name: 'Paraguay',
    id: 'recrj50rh2huAnfMf',
  },
  {
    name: 'Peru',
    id: 'recxSlvArXquWGv8G',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    id: 'recZjxaCLfTo9pxLC',
  },
  {
    name: 'Suriname',
    id: 'recvPucrUUjU1gW8h',
  },
  {
    name: 'Uruguay',
    id: 'rec6Rbrg7wH0pL9f0',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    id: 'recv3Fr3dhpv3YvAd',
  },
  {
    name: 'Northern America',
    id: 'rec0rfyFYF6ir1j3t',
  },
  {
    name: 'Bermuda',
    id: 'recD8KLKytYTOs1ZQ',
  },
  {
    name: 'Canada',
    id: 'recbaffphEYneYnrV',
  },
  {
    name: 'Greenland',
    id: 'rec9ITYhtwOCHsmAL',
  },
  {
    name: 'Saint Pierre and Miquelon',
    id: 'recUIGEPgQU0SL40X',
  },
  {
    name: 'United States of America',
    id: 'recwD97vCX4M23Hht',
  },
  {
    name: 'Antarctica',
    id: 'recw4tn03flv9jmqW',
  },
  {
    name: 'Asia',
    id: 'recNqezmAVTih0ftk',
  },
  {
    name: 'Central Asia',
    id: 'recSnvWtcyxD4fNIG',
  },
  {
    name: 'Kazakhstan',
    id: 'recNqbfyKT19gJSvJ',
  },
  {
    name: 'Kyrgyzstan',
    id: 'recD2IaImGyKRR06H',
  },
  {
    name: 'Tajikistan',
    id: 'recDAU1UpKCPeuRIv',
  },
  {
    name: 'Turkmenistan',
    id: 'recKoKI5WgNzrlxPX',
  },
  {
    name: 'Uzbekistan',
    id: 'recb3fRdqBiwqj40T',
  },
  {
    name: 'Eastern Asia',
    id: 'rec9j33gENRbtZ8l1',
  },
  {
    name: 'China',
    id: 'recPddZpX5EaB0K6v',
  },
  {
    name: 'China, Hong Kong Special Administrative Region',
    id: 'recsscPO7gX07CIyI',
  },
  {
    name: 'China, Macao Special Administrative Region',
    id: 'reciMwfvjN9Dh1Ate',
  },
  {
    name: "Democratic People's Republic of Korea",
    id: 'rec6TkLGcXBVvzsL8',
  },
  {
    name: 'Japan',
    id: 'recgK1AbTMekDck98',
  },
  {
    name: 'Mongolia',
    id: 'recNXNl3kdbNqwX64',
  },
  {
    name: 'Republic of Korea',
    id: 'rec7zH04P4sMH14f9',
  },
  {
    name: 'South-eastern Asia',
    id: 'recBCoGnaAeS7gY6l',
  },
  {
    name: 'Brunei Darussalam',
    id: 'recdqMakyKaUoleBg',
  },
  {
    name: 'Cambodia',
    id: 'recWGDtlqFlQe2KrN',
  },
  {
    name: 'Indonesia',
    id: 'recnmMUEwVM3E7ErW',
  },
  {
    name: "Lao People's Democratic Republic",
    id: 'rece7v4OjiVfMBb31',
  },
  {
    name: 'Malaysia',
    id: 'recgQEVEGgPveySwl',
  },
  {
    name: 'Myanmar',
    id: 'recnACmzRMwUtN59q',
  },
  {
    name: 'Philippines',
    id: 'reczAyKtFWtK0Chxc',
  },
  {
    name: 'Singapore',
    id: 'recdTFA7HMC7fElTu',
  },
  {
    name: 'Thailand',
    id: 'recZFf7gvjYnWK5XM',
  },
  {
    name: 'Timor-Leste',
    id: 'recmlB6r7k2UiEyNQ',
  },
  {
    name: 'Vietnam',
    id: 'recd24OtWgl0HYOvH',
  },
  {
    name: 'Southern Asia',
    id: 'recMEtsZgmoYX3vfP',
  },
  {
    name: 'Afghanistan',
    id: 'rec9slBqbreO6qBjX',
  },
  {
    name: 'Bangladesh',
    id: 'recscgk0RLU334ut2',
  },
  {
    name: 'Bhutan',
    id: 'recriud3b45TMKmu5',
  },
  {
    name: 'India',
    id: 'recKGC8IQGYRITkIn',
  },
  {
    name: 'Iran (Islamic Republic of)',
    id: 'recw1gh3Tlybk2Cu4',
  },
  {
    name: 'Maldives',
    id: 'recZF8yrRC4YRDKnm',
  },
  {
    name: 'Nepal',
    id: 'recY9oMBkU2lLCpVV',
  },
  {
    name: 'Pakistan',
    id: 'reclqY4gGAuJUiSCb',
  },
  {
    name: 'Sri Lanka',
    id: 'recF1GRCzp6pwsqa5',
  },
  {
    name: 'Western Asia',
    id: 'recWSIkYAoxpcIock',
  },
  {
    name: 'Armenia',
    id: 'recy9ZiwxBIWt7wXG',
  },
  {
    name: 'Azerbaijan',
    id: 'recfglu7TEE8cqHlp',
  },
  {
    name: 'Bahrain',
    id: 'recAHLnOWqXFQXKDp',
  },
  {
    name: 'Cyprus',
    id: 'recpnmlD8hUnKJqyj',
  },
  {
    name: 'Georgia',
    id: 'recnvROIqqpNJLq5Z',
  },
  {
    name: 'Iraq',
    id: 'recO75038QsS02PVO',
  },
  {
    name: 'Israel',
    id: 'recVYqW0tYh28yzWJ',
  },
  {
    name: 'Jordan',
    id: 'recazhumf2B2F0ASt',
  },
  {
    name: 'Kuwait',
    id: 'recV3kLOqtprwjRFG',
  },
  {
    name: 'Lebanon',
    id: 'recF4lo7ms4qNqzfn',
  },
  {
    name: 'Oman',
    id: 'recxVDbdIwPlNIHS3',
  },
  {
    name: 'Qatar',
    id: 'recWqS6cWXATq1zaT',
  },
  {
    name: 'Saudi Arabia',
    id: 'recXQ9nkOV1Ff5tNh',
  },
  {
    name: 'State of Palestine',
    id: 'rec1jL7kxfkroDhUX',
  },
  {
    name: 'Syrian Arab Republic',
    id: 'recXgzqxpR3i4tWDA',
  },
  {
    name: 'Turkey',
    id: 'rec7RSaVqAaQELXsc',
  },
  {
    name: 'United Arab Emirates',
    id: 'rec9X1vPMNo7COPR1',
  },
  {
    name: 'Yemen',
    id: 'recug6I6mQUABKbAv',
  },
  {
    name: 'Europe',
    id: 'recAp416TTbk1vajj',
  },
  {
    name: 'Eastern Europe',
    id: 'rec88JztSmFMuEzTg',
  },
  {
    name: 'Belarus',
    id: 'recXO0fMGX1T17Cnm',
  },
  {
    name: 'Bulgaria',
    id: 'recvkxOO2xPt6lFF4',
  },
  {
    name: 'Czechia',
    id: 'recxX0BJAYdxrweou',
  },
  {
    name: 'Hungary',
    id: 'rec8CKNSFGOeB35YB',
  },
  {
    name: 'Poland',
    id: 'recqqJKxKLVr5V2T4',
  },
  {
    name: 'Republic of Moldova',
    id: 'recVaw9lH9K3pXa9V',
  },
  {
    name: 'Romania',
    id: 'recxcSLKFJ2LOD90T',
  },
  {
    name: 'Russian Federation',
    id: 'rec6W08L38ysqHZZX',
  },
  {
    name: 'Slovakia',
    id: 'recw48cvEtpxzYzk1',
  },
  {
    name: 'Ukraine',
    id: 'rec2A9m2QgLabraib',
  },
  {
    name: 'Northern Europe',
    id: 'recWwAHfU4Umd24IH',
  },
  {
    name: 'Åland Islands',
    id: 'recMBgl2JOVvphflQ',
  },
  {
    name: 'Channel Islands',
    id: 'recBnsQheSisB0HRE',
  },
  {
    name: 'Guernsey',
    id: 'recYBUOewquuQXQQh',
  },
  {
    name: 'Jersey',
    id: 'recGSF7F1gBCG11jP',
  },
  {
    name: 'Sark',
    id: 'recGKmoXFUHJifqXm',
  },
  {
    name: 'Denmark',
    id: 'recitWLJQXCjkR9hl',
  },
  {
    name: 'Estonia',
    id: 'rec0LkBPypKQ5IFq5',
  },
  {
    name: 'Faroe Islands',
    id: 'recwICmhFMAl66jZf',
  },
  {
    name: 'Finland',
    id: 'recYtlNCAk652ttol',
  },
  {
    name: 'Iceland',
    id: 'recx4yDgwwNosOAMx',
  },
  {
    name: 'Ireland',
    id: 'rechrv8iyKjI9LUP2',
  },
  {
    name: 'Isle of Man',
    id: 'reciX1bb4u420fFZo',
  },
  {
    name: 'Latvia',
    id: 'recE37uAscYv4TDq8',
  },
  {
    name: 'Lithuania',
    id: 'recMZpZv4bXWkZ9ra',
  },
  {
    name: 'Norway',
    id: 'recrfKOiucotfBCip',
  },
  {
    name: 'Svalbard and Jan Mayen Islands',
    id: 'recdPkrzzIDcu1iNp',
  },
  {
    name: 'Sweden',
    id: 'recf1CHT7R1Sj6qhL',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    id: 'recT77Z50GUw0hBZu',
  },
  {
    name: 'Southern Europe',
    id: 'recIXTvhOifov0Xs9',
  },
  {
    name: 'Albania',
    id: 'recE8fkGTZLFrq4OM',
  },
  {
    name: 'Andorra',
    id: 'recUFnEy8txU3xGA1',
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 'rec8sLdWfDeo40gGC',
  },
  {
    name: 'Croatia',
    id: 'rec74jSOGXTdlQYZY',
  },
  {
    name: 'Gibraltar',
    id: 'rec5DDHuGN6sar2gF',
  },
  {
    name: 'Greece',
    id: 'recgpo7mufssPpqk4',
  },
  {
    name: 'Holy See',
    id: 'reczo71iLaOVb44ER',
  },
  {
    name: 'Italy',
    id: 'recqnwHHUinY8jXmy',
  },
  {
    name: 'Malta',
    id: 'rec5tgl0zOzDXRGuf',
  },
  {
    name: 'Montenegro',
    id: 'recWrtnQ4WqNQkJ7o',
  },
  {
    name: 'North Macedonia',
    id: 'recJ5w4Z68l66udDi',
  },
  {
    name: 'Portugal',
    id: 'reckBL61fi1IAf4Ur',
  },
  {
    name: 'San Marino',
    id: 'recmJncjwiaycJB8Z',
  },
  {
    name: 'Serbia',
    id: 'reczniydNU8l1aqiS',
  },
  {
    name: 'Slovenia',
    id: 'recnv5gVRCUorrTlm',
  },
  {
    name: 'Spain',
    id: 'rec8I2q5vn3HMDeP9',
  },
  {
    name: 'Western Europe',
    id: 'recB86P6b7tN8HI5Q',
  },
  {
    name: 'Austria',
    id: 'recgGXKR8KQ7un2TE',
  },
  {
    name: 'Belgium',
    id: 'recizge8p7yG3qUSK',
  },
  {
    name: 'France',
    id: 'recDzQnlYJGT0yYxa',
  },
  {
    name: 'Germany',
    id: 'rec0MRtQXUgX67dm1',
  },
  {
    name: 'Liechtenstein',
    id: 'recIPeCivjYgk18nj',
  },
  {
    name: 'Luxembourg',
    id: 'recFBx1Rjknj0skYf',
  },
  {
    name: 'Monaco',
    id: 'recoku4sgIPcpoVkW',
  },
  {
    name: 'Netherlands',
    id: 'recXKjZuVRyybkzbU',
  },
  {
    name: 'Switzerland',
    id: 'recMNkS1BLCBcKIDi',
  },
  {
    name: 'Oceania',
    id: 'recTa0KVaqJC8x4yG',
  },
  {
    name: 'Australia and New Zealand',
    id: 'rec6oXStONkNAdL9H',
  },
  {
    name: 'Australia',
    id: 'recZMqGxtnngM5v4Q',
  },
  {
    name: 'Christmas Island',
    id: 'recBvfmpyxSpLs1Uh',
  },
  {
    name: 'Cocos (Keeling) Islands',
    id: 'recekQEij4Uh6v4qL',
  },
  {
    name: 'Heard Island and McDonald Islands',
    id: 'recZvS90cZJOzj8Go',
  },
  {
    name: 'New Zealand',
    id: 'recsqX7b4JkuqLsW3',
  },
  {
    name: 'Norfolk Island',
    id: 'recEDCUw1UuAVhmn7',
  },
  {
    name: 'Melanesia',
    id: 'recNLpBNkPd4wspja',
  },
  {
    name: 'Fiji',
    id: 'recMnXxEpPGJPSUTd',
  },
  {
    name: 'New Caledonia',
    id: 'rec7Fdwt4clvnmlbN',
  },
  {
    name: 'Papua New Guinea',
    id: 'recKbxNyaVAiRWIWR',
  },
  {
    name: 'Solomon Islands',
    id: 'recB0aKw4WroUL236',
  },
  {
    name: 'Vanuatu',
    id: 'recPw3bI2ToYdl3RP',
  },
  {
    name: 'Micronesia',
    id: 'recFI7upZiuN2tHrR',
  },
  {
    name: 'Guam',
    id: 'recdVdjSMwYtzs85a',
  },
  {
    name: 'Kiribati',
    id: 'recJSOkEH6n5ivcOk',
  },
  {
    name: 'Marshall Islands',
    id: 'recE248Pg8wuGPsPG',
  },
  {
    name: 'Micronesia (Federated States of)',
    id: 'recwtZkHYAvgIvrbj',
  },
  {
    name: 'Nauru',
    id: 'recRsZ6ws2xoHq8oX',
  },
  {
    name: 'Northern Mariana Islands',
    id: 'reckQiY3sHmoxthM2',
  },
  {
    name: 'Palau',
    id: 'recxWtzQywfWvc0cI',
  },
  {
    name: 'United States Minor Outlying Islands',
    id: 'rec6zajxpg4KEkGpW',
  },
  {
    name: 'Polynesia',
    id: 'recgWjAaBhWI4a6pU',
  },
  {
    name: 'American Samoa',
    id: 'reccHqyu3S12Y2WLW',
  },
  {
    name: 'Cook Islands',
    id: 'recc9phaHQjy8jAdd',
  },
  {
    name: 'French Polynesia',
    id: 'rec8QtMiwmfSloHWE',
  },
  {
    name: 'Niue',
    id: 'recYHwMdQ8Vj0Vvn5',
  },
  {
    name: 'Pitcairn',
    id: 'reccRhqJNPnPEntaI',
  },
  {
    name: 'Samoa',
    id: 'rec5GErNKmjLQHRW4',
  },
  {
    name: 'Tokelau',
    id: 'recVYBNewBx9GHM47',
  },
  {
    name: 'Tonga',
    id: 'recBoUBWgdRiuWk4G',
  },
  {
    name: 'Tuvalu',
    id: 'recnUmJpxaVfoQvxG',
  },
  {
    name: 'Wallis and Futuna Islands',
    id: 'recEi0K8PfjnRjkmh',
  },
  {
    name: 'Worldwide',
    id: 'rec53GnKHyAxiWnFK',
  },
  {
    name: 'Global',
    id: 'recud10C04XXh3rhw',
  },
]
