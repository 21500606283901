var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.impactGoal &&
    _vm.solutionIndex !== undefined &&
    _vm.impact &&
    _vm.impact.impactSolutions &&
    _vm.impact.impactSolutions[_vm.solutionIndex]
  )?_c('nuxt-child',{attrs:{"impact-goal":_vm.impactGoal,"impact":_vm.impact,"venture-id":_vm.ventureId,"impact-solution":_vm.impact.impactSolutions[_vm.solutionIndex]}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }