import { render, staticRenderFns } from "./InsightAnnotation.vue?vue&type=template&id=30cf0d8c&scoped=true"
import script from "./InsightAnnotation.vue?vue&type=script&lang=ts"
export * from "./InsightAnnotation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30cf0d8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxAutocomplete: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Autocomplete.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCardActions,VChip,VChipGroup,VCol,VCombobox,VRow,VSelect,VSpacer,VTooltip})
