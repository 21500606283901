import { render, staticRenderFns } from "./SdgList.vue?vue&type=template&id=fef77960&scoped=true"
import script from "./SdgList.vue?vue&type=script&lang=ts"
export * from "./SdgList.vue?vue&type=script&lang=ts"
import style0 from "./SdgList.vue?vue&type=style&index=0&id=fef77960&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fef77960",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SDG: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SDG.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAvatar,VIcon,VLayout,VMenu})
