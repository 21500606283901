import "core-js/modules/es6.array.filter.js";
import { getIcon, getEsgSectionDetails, formatAsGermanDate } from '~/functions/helpers';
import { renderMarkdown } from '~/functions/markdown';
import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  props: {
    questionnaireTemplate: {
      type: Object,
      required: true
    },
    questionnaireAssignment: {
      type: Object,
      required: true
    },
    userInputMapping: {
      type: Object,
      required: true
    },
    questionnaireResultMapping: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId
      }),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount;
    });
    var esgActionsActive = computed(function () {
      return account.value.esgActionsActive === true;
    });
    var sections = computed(function () {
      return props.questionnaireTemplate.sections;
    });
    var selectedTab = ref(null);
    var currentSection = ref(null);
    var currentSubsection = ref(null);
    function updateCurrentSectionAndSubsection(current) {
      var tabIndex = current[1];
      var section = sections.value[tabIndex];
      currentSection.value = section.label;
      currentSubsection.value = section.sections && section.sections.length ? section.sections[0].label : null;
    }
    watch([props, selectedTab], function (current, _) {
      return updateCurrentSectionAndSubsection(current);
    });
    selectedTab.value = 0;
    return {
      selectedTab: selectedTab,
      currentSection: currentSection,
      currentSubsection: currentSubsection,
      account: account,
      esgActionsActive: esgActionsActive
    };
  },
  data: function data() {
    return {
      showQuestionnaireResult: true
    };
  },
  computed: {
    sections: function sections() {
      if (this.loading) return [];
      return this.questionnaireTemplate.sections.filter(function (e) {
        return e.id !== 1;
      }); // exclude company section
    } // Company info quick hack TODO: use query that filters ESG
  },
  methods: {
    formatAsGermanDate: formatAsGermanDate,
    renderMarkdown: renderMarkdown,
    getIcon: getIcon,
    getEsgSectionDetails: getEsgSectionDetails
  }
});