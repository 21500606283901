import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find-index.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { logObserver } from '~/functions/helpers';
export default defineComponent({
  props: {
    allEsgQuestionnaires: {
      type: Array,
      required: true
    },
    firstId: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props) {
    function isCompleted(questionnaire) {
      return questionnaire !== null && !questionnaire.isEditable;
    }
    var index = computed(function () {
      return props.allEsgQuestionnaires.findIndex(function (e) {
        return e.id === props.firstId;
      });
    });
    var first = computed(function () {
      return props.allEsgQuestionnaires[index.value] || null;
    });
    var second = computed(function () {
      return props.allEsgQuestionnaires[index.value + 1] || null;
    });
    var loading = useQueryLoading();
    return {
      loading: loading,
      isCompleted: isCompleted,
      first: first,
      second: second
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver
  }
});