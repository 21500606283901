
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SetupKeyInfos',
  setup() {
    const defaultKeyInfos = ref({
      pitchline: '',
      marketSector: '',
      productsServices: '',
      headquarter: '',
      founders: '',
      foundingYear: '',
      forProfit: '',
      legalEntity: '',
      payingCustomer: '',
      employeeHeadcount: '',
      turnOver: '',
      growthStage: '',
      businessModel: '',
      mainIncomeStreams: '',
      industry: '',
      subIndustry: '',
      technologies: '',
      controversialBusinessAreas: '',
      other_business_model: '',
      other_income_stream: '',
      other_industry: '',
      other_sub_industry: '',
      other_technology: '',
    })

    const defaultItems = ref([
      {
        header:
          'Press "Enter" after typing to create a new item. You can create multiple items. You can delete items by pressing the "Del" key.',
      },
    ])

    const typeOfBusinessOptions = ref([
      'For-profit organization',
      'Social business',
    ])

    const rules = ref({
      requiredRule: [(v: string) => !!v || 'This field is required'],
      requiredRuleSelect: [
        (v: any) => v.length > 0 || 'At least one item is required',
      ],
      optionalIntegerRule: [
        (v: any) => {
          if (v) {
            return (
              Number.isSafeInteger(Number(v)) || 'The value must be a number'
            )
          } else {
            return true
          }
        },
      ],
    })

    const keyInfoOptions = ref({})
    const industryDropdownItems = ref([])
    const subIndustryDropdownItems = ref([])
    const incomeStreamsDropdownItems = ref([])
    const exclusionListDropdownItems = ref([])
    const technologiesDropdownItems = ref([])
    const businessModelDropdownItems = ref([])
    const typeOfBusinessDropdownItems = ref([])
    const employeeHeadcountDropdownItems = ref([])
    const turnOverDropdownItems = ref([])
    const growthStageDropdownItems = ref([])

    const country = ref('')
    const region = ref('')
    const address = ref('')
    const selectedTechnology = ref('')
    const selectedBusinessModel = ref('')
    const selectedIncomeStream = ref('')
    const selectedIndustry = ref('')
    const selectedSubIndustry = ref('')

    const changeTechnologies = (a: any) => {
      selectedTechnology.value = a
    }

    const changeBusinessModel = (a: any) => {
      selectedBusinessModel.value = a
    }

    const changeIncomeStream = (a: any) => {
      selectedIncomeStream.value = a
    }

    const changeIndustry = (a: any) => {
      selectedIndustry.value = a
    }

    const changeSubIndustry = (a: any) => {
      selectedSubIndustry.value = a
    }

    // Example of how to define a computed property or method
    // const keyInfos = computed(() => {
    //   // Your computed property logic here
    // })

    // Define async functions such as created lifecycle hook
    // onCreated(() => {
    //   // Your logic here
    // })

    const keyInfos = () => {
      if (this.assessment !== undefined) {
        return { ...this.defaultKeyInfos, ...this.assessment.keyinfo }
      }
      return this.defaultKeyInfos
    }

    const valid = false

    const form = (): Vue & { validate: () => boolean } => {
      return this.$refs.form as Vue & { validate: () => boolean }
    }

    const update = async (doRedirect: boolean) => {
      if (this.form.validate()) {
        const id = this.keyInfos.id
        // this.keyInfos.headquarter = this.country + ',' + this.region
        const fields = {
          assessment: [this.assessment.id],
          ...this.keyInfos,
        }
        fields.id = undefined
        await this.$store.dispatch('assessment/updateKeyInfo', {
          token: (this.$auth.strategy as any).token.get(),
          id,
          fields,
        })
        if (doRedirect === true) {
          this.$router.push(`/${this.assessment.id}/open-tasks`)
        }
      }
    }

    const assessment = () => {
      return this.$store.getters['assessment/getAssessment']
    }

    const created = async () => {
      const response = await this.$axios
        .get('/api/assessment/keyinfos/getoptions', {
          headers: {
            'Content-type': 'application/json',
            Authorization: (this.$auth.strategy as any).token.get(),
          },
        })
        .catch((err) => {
          console.log(err)
        })
      if (response) {
        this.keyInfoOptions = response.data
        this.industryDropdownItems = this.keyInfoOptions[0].industry
        this.subIndustryDropdownItems = this.keyInfoOptions[1].subIndustry
        this.incomeStreamsDropdownItems = this.keyInfoOptions[2].incomeStreams
        this.exclusionListDropdownItems = this.keyInfoOptions[3].exclusionList
        this.technologiesDropdownItems = this.keyInfoOptions[4].technologies
        this.businessModelDropdownItems = this.keyInfoOptions[5].businessModel
        this.typeOfBusinessDropdownItems = this.keyInfoOptions[6].typeOfBusiness
        this.employeeHeadcountDropdownItems =
          this.keyInfoOptions[7].employeeHeadcount
        this.turnOverDropdownItems = this.keyInfoOptions[8].turnOver
        this.growthStageDropdownItems = this.keyInfoOptions[9].growthStage
      }
    }

    const getAddressData = (
      addressData: any,
      _placeResultData: any,
      _id: any,
    ) => {
      this.address = addressData
    }
    return {
      defaultKeyInfos,
      defaultItems,
      typeOfBusinessOptions,
      rules,
      keyInfoOptions,
      industryDropdownItems,
      subIndustryDropdownItems,
      incomeStreamsDropdownItems,
      exclusionListDropdownItems,
      technologiesDropdownItems,
      businessModelDropdownItems,
      typeOfBusinessDropdownItems,
      employeeHeadcountDropdownItems,
      turnOverDropdownItems,
      growthStageDropdownItems,
      country,
      region,
      address,
      selectedTechnology,
      selectedBusinessModel,
      selectedIncomeStream,
      selectedIndustry,
      selectedSubIndustry,
      changeTechnologies,
      changeBusinessModel,
      changeIncomeStream,
      changeIndustry,
      changeSubIndustry,
      // Add other properties and methods here
    }
  },
})
