
import { defineComponent, PropType } from 'vue'
import ImpactPotentialScore from '~/components/deprecated/impact-cell/ImpactPotentialScore.vue'

export default defineComponent({
  components: { ImpactPotentialScore },
  props: {
    hasLink: Boolean,
    showSources: Boolean,
    showEditableActions: Boolean,
    stageToShow: Number,
    showAll: Boolean,
    assessment: Object as PropType<any>,
    currentUserRole: String,
    assessmentStatus: String,
    outcomes: Array as PropType<string[]>,
    stages: Array as PropType<string[]>,
  },
  data() {
    return {
      rows: {
        'Outcome-selection venture': [
          {
            name: 'Outcome-stakeholder combination',
            component: 'deprecated-impact-cell-outcome',
            toggle: true,
          },
          {
            name: 'Stage',
            component: 'deprecated-impact-cell-stage',
            toggle: false,
          },
          {
            name: 'Actions',
            component: 'deprecated-impact-cell-actions',
            toggle: false,
          },
          {
            name: 'SDGs',
            component: 'deprecated-impact-cell-sdgs',
            toggle: true,
          },
          {
            name: 'IRIS Impact categories',
            component: 'deprecated-impact-cell-iris-impact-categories',
            toggle: true,
          },
          {
            name: 'Theory of change',
            component: 'deprecated-impact-cell-theory-of-change',
            toggle: false,
          },
          {
            name: 'Target geographies',
            component: 'deprecated-impact-cell-target-geographies',
            toggle: false,
          },
          {
            name: 'Number of affected stakeholders',
            component: 'deprecated-impact-cell-number-of-stakeholders',
            toggle: false,
          },
          {
            name: 'Impact potential score estimate',
            component: 'ImpactPotentialPhase1',
            toggle: true,
          },
          {
            name: 'Outcome forecast',
            component: 'deprecated-impact-cell-outcome-forecast',
            toggle: false,
          },
          {
            name: 'Relevance of impact estimate',
            component: 'deprecated-impact-cell-relevance-of-impact',
            toggle: true,
          },
          {
            name: 'Explanation for Relevance of impact estimate',
            component: 'deprecated-impact-cell-relevance-of-impact-explanation',
            toggle: true,
          },
          {
            name: 'Degree of change estimate',
            component: 'deprecated-impact-cell-degree-of-change',
            toggle: true,
          },
          {
            name: 'Explanation for Degree of change estimate',
            component: 'deprecated-impact-cell-degree-of-change-explanation',
            toggle: true,
          },
          {
            name: 'Problems addressed',
            component: 'deprecated-impact-cell-problems-addressed',
            toggle: false,
          },
          {
            name: 'Link to value chain',
            component: 'deprecated-impact-cell-causes-of-outcome',
            toggle: false,
          },
          {
            name: 'Supporting evidence',
            component: 'deprecated-impact-cell-supporting-evidence',
            toggle: true,
          },
          {
            name: 'Comment',
            component: 'deprecated-impact-cell-venture-comment',
            toggle: true,
          },
        ],
        'Outcome-selection reviewer': [
          {
            name: 'Outcome-stakeholder combination',
            component: 'deprecated-impact-cell-outcome',
            toggle: true,
          },
          {
            name: 'Stage',
            component: 'deprecated-impact-cell-stage',
            toggle: false,
          },
          {
            name: 'Actions',
            component: 'deprecated-impact-cell-actions',
            toggle: false,
          },
          {
            name: 'SDGs',
            component: 'deprecated-impact-cell-sdgs',
            toggle: true,
          },
          {
            name: 'IRIS Impact categories',
            component: 'deprecated-impact-cell-iris-impact-categories',
            toggle: true,
          },
          {
            name: 'Theory of change',
            component: 'deprecated-impact-cell-theory-of-change',
            toggle: false,
          },
          {
            name: 'Number of affected stakeholders',
            component: 'deprecated-impact-cell-number-of-stakeholders',
            toggle: false,
          },
          {
            name: 'Outcome forecast',
            component: 'deprecated-impact-cell-outcome-forecast',
            toggle: false,
          },
          {
            name: 'Impact potential score estimate',
            component: 'ImpactPotentialPhase1',
            toggle: true,
          },
          {
            name: 'Relevance of impact estimate',
            component: 'deprecated-impact-cell-relevance-of-impact',
            toggle: true,
          },
          {
            name: 'Explanation for Relevance of impact estimate',
            component: 'deprecated-impact-cell-relevance-of-impact-explanation',
            toggle: true,
          },
          {
            name: 'Degree of change estimate',
            component: 'deprecated-impact-cell-degree-of-change',
            toggle: true,
          },
          {
            name: 'Explanation for Degree of change estimate',
            component: 'deprecated-impact-cell-degree-of-change-explanation',
            toggle: true,
          },
          {
            name: 'Target geographies',
            component: 'deprecated-impact-cell-target-geographies',
            toggle: false,
          },
          {
            name: 'Problems addressed',
            component: 'deprecated-impact-cell-problems-addressed',
            toggle: false,
          },
          {
            name: 'Link to value chain',
            component: 'deprecated-impact-cell-causes-of-outcome',
            toggle: false,
          },
          {
            name: 'Supporting evidence',
            component: 'deprecated-impact-cell-supporting-evidence',
            toggle: true,
          },
          {
            name: 'Com comment',
            component: 'deprecated-impact-cell-venture-comment',
            toggle: true,
          },
          {
            name: 'Reviewer comment',
            component: 'deprecated-impact-cell-reviewer-comment',
            toggle: true,
          },
        ],
        'Assessment venture': [
          {
            name: 'Outcome-stakeholder combination',
            component: 'deprecated-impact-cell-outcome-with-link',
            toggle: true,
          },
          {
            name: 'Stage',
            component: 'deprecated-impact-cell-stage',
            toggle: false,
          },
          {
            name: 'Actions',
            component: 'deprecated-impact-cell-actions',
            toggle: false,
          },
          {
            name: 'SDGs',
            component: 'deprecated-impact-cell-sdgs',
            toggle: true,
          },
          {
            name: 'IRIS Impact categories',
            component: 'deprecated-impact-cell-iris-impact-categories',
            toggle: true,
          },
          {
            name: 'Theory of change',
            component: 'deprecated-impact-cell-theory-of-change',
            toggle: false,
          },
          {
            name: 'Number of affected stakeholders',
            component: 'deprecated-impact-cell-number-of-stakeholders',
            toggle: false,
          },
          {
            name: 'Outcome forecast',
            component: 'deprecated-impact-cell-outcome-forecast',
            toggle: false,
          },
          {
            name: 'Impact potential score',
            component: 'ImpactPotentialScore',
            toggle: true,
          },
          {
            name: 'Relevance of impact',
            component: 'deprecated-impact-cell-relevance-of-impact-score',
            toggle: true,
          },
          {
            name: 'Degree of change',
            component: 'deprecated-impact-cell-degree-of-change-score',
            toggle: true,
          },
          {
            name: 'Target geographies',
            component: 'deprecated-impact-cell-target-geographies',
            toggle: false,
          },
          {
            name: 'Problems addressed',
            component: 'deprecated-impact-cell-problems-addressed',
            toggle: false,
          },
          {
            name: 'Link to value chain',
            component: 'deprecated-impact-cell-causes-of-outcome',
            toggle: false,
          },
          {
            name: 'Supporting evidence',
            component: 'deprecated-impact-cell-supporting-evidence',
            toggle: true,
          },
        ],
        'Assessment reviewer': [
          {
            name: 'Outcome-stakeholder combination',
            component: 'deprecated-impact-cell-outcome-with-link',
            toggle: true,
          },
          {
            name: 'Stage',
            component: 'deprecated-impact-cell-stage',
            toggle: false,
          },
          {
            name: 'Actions',
            component: 'deprecated-impact-cell-actions',
            toggle: false,
          },
          {
            name: 'SDGs',
            component: 'deprecated-impact-cell-sdgs',
            toggle: true,
          },
          {
            name: 'IRIS Impact categories',
            component: 'deprecated-impact-cell-iris-impact-categories',
            toggle: true,
          },
          {
            name: 'Theory of change',
            component: 'deprecated-impact-cell-theory-of-change',
            toggle: false,
          },
          {
            name: 'Number of affected stakeholders',
            component: 'deprecated-impact-cell-number-of-stakeholders',
            toggle: false,
          },
          {
            name: 'Outcome forecast',
            component: 'deprecated-impact-cell-outcome-forecast',
            toggle: false,
          },
          {
            name: 'Impact potential score',
            component: 'ImpactPotentialScore',
            toggle: true,
          },
          {
            name: 'Relevance of impact',
            component: 'deprecated-impact-cell-relevance-of-impact-score',
            toggle: true,
          },
          {
            name: 'Degree of change',
            component: 'deprecated-impact-cell-degree-of-change-score',
            toggle: true,
          },
          {
            name: 'Target geographies',
            component: 'deprecated-impact-cell-target-geographies',
            toggle: false,
          },
          {
            name: 'Problems addressed',
            component: 'deprecated-impact-cell-problems-addressed',
            toggle: false,
          },
          {
            name: 'Link to value chain',
            component: 'deprecated-impact-cell-causes-of-outcome',
            toggle: false,
          },
          // {
          //   name: 'Confidence',
          //   component: 'deprecated-impact-cell-confidence-score',
          //   toggle: true,
          // },
          {
            name: 'Risk',
            component: 'deprecated-impact-cell-risk-score',
            toggle: true,
          },
          {
            name: 'Supporting evidence',
            component: 'deprecated-impact-cell-supporting-evidence',
            toggle: true,
          },
        ],
      },
    }
  },
  computed: {
    toggledRows() {
      if (this.showAll) {
        return this.rows[this.stages?.[this.stageToShow]]
      }
      return this.rows[this.stages?.[this.stageToShow]].filter(
        (e: any) => e.toggle,
      )
    },
  },
  methods: {
    isEditable(stage: string) {
      if (this.currentUserRole === 'venture')
        return (
          (stage === 'Outcome-selection venture' &&
            this.assessmentStatus === 'Outcome-selection input') ||
          (stage === 'Assessment venture' &&
            this.assessmentStatus === 'Assessment input')
        )
      if (this.currentUserRole === 'reviewer')
        return (
          (stage === 'Outcome-selection reviewer' &&
            this.assessmentStatus === 'Outcome-selection in review') ||
          (stage === 'Assessment reviewer' &&
            this.assessmentStatus === 'Assessment in review')
        )
      return false
    },
  },
})
