import { render, staticRenderFns } from "./KeyInfo.vue?vue&type=template&id=52db9134&scoped=true"
import script from "./KeyInfo.vue?vue&type=script&lang=ts"
export * from "./KeyInfo.vue?vue&type=script&lang=ts"
import style0 from "./KeyInfo.vue?vue&type=style&index=0&id=52db9134&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52db9134",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KeyValue: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/KeyValue.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow})
