var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('ix-section',{attrs:{"tile":"","flat":""}},[_c('v-card-actions',{staticClass:"pt-4"},[_c('ix-btn-back',{staticClass:"mr-4"}),_vm._v(" "),_c('v-dialog',{attrs:{"eager":"","persistent":"","scrollable":"","width":"50%"},model:{value:(_vm.customMetricFormDialog),callback:function ($$v) {_vm.customMetricFormDialog=$$v},expression:"customMetricFormDialog"}},[_c('metrics-assignment-batch-dialog',{attrs:{"selected-metrics":_vm.selectedMetrics,"type":"add","venture-id":_vm.$route.params.venture},on:{"cancel":function($event){_vm.customMetricFormDialog = false},"finish":() => {
              _vm.customMetricFormDialog = false
              _vm.$router.go(-1)
            }}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('ix-data-table-metrics',{attrs:{"query":{
      assignedTos: [
        _vm.TypeMetricAssignedTo.Company,
        _vm.TypeMetricAssignedTo.FundCompany,
      ],
      sections: [
        117, // 'IRIS Indicators',
        90, // 'PAI company data',
        967, // 'ImpactNexus Indicators',
        989, // 'EU taxonomy',
        998, // 'EDCI Metrics',
        1101, // 'KfW'
      ],
    }},on:{"usemetricsastemplate":_vm.addMetricAssignmentsFromTemplate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }