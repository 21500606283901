var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-button-outlined',{attrs:{"to":_vm.ventureCompanyEdit}},[_vm._v(" Edit")])],1),_vm._v(" "),_vm._l((_vm.sections),function(section){return _c('v-col',{key:section.label,attrs:{"cols":"6"}},[(_vm.companyInfo)?_c('ix-sub-section',{attrs:{"loading":_vm.loading}},[_c('ix-sub-section-title',[_vm._v("\n          "+_vm._s(section.label)+"\n        ")]),_vm._v(" "),_c('ix-sub-section-text',[_c('div',{staticClass:"font-weight-medium neutral-gray--text title"}),_vm._v(" "),_c('table',_vm._l((section.keys),function(key,index){return _c('tr',{key:index,staticClass:"border-bottom field-name"},[_c('td',{staticClass:"pb-4 pt-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('companyInfo.' + key.label)))])]),_vm._v(" "),(Array.isArray(_vm.companyInfo[key.label]))?_c('td',{staticClass:"pb-4 pt-1 field-data font-weight-bold text-right"},[(_vm.companyInfo[key.label].length == 0)?_c('div',[_vm._v("N/A")]):_vm._l((_vm.companyInfo[key.label]),function(option){return _c('div',{key:option},[_vm._v("\n                  "+_vm._s(option)+"\n                ")])})],2):_c('td',{staticClass:"pb-4 pt-1 field-data font-weight-bold text-right"},[_c('div',{staticClass:"field-data"},[(
                      key.label === 'csrd.title' &&
                      _vm.account.csrdRequirement.report === 'yes' &&
                      _vm.account.csrdRequirement.date !== null
                    )?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t('companyInfo.csrd.yes') + ', ')+"\n                    "+_vm._s(_vm.$t(
                        'companyInfo.csrd.options.' +
                          _vm.account.csrdRequirement.date,
                      )))]):(
                      key.label === 'csrd.title' &&
                      _vm.account.csrdRequirement.report === 'yes' &&
                      _vm.account.csrdRequirement.date === null
                    )?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t('companyInfo.csrd.yes')))]):(
                      key.label === 'csrd.title' &&
                      _vm.account.csrdRequirement.report === 'no'
                    )?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t('companyInfo.csrd.no')))]):_c('span',[_vm._v(_vm._s(_vm.companyInfo[key.label] || 'N/A'))])])])])}),0)])],1):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }