import { render, staticRenderFns } from "./ActionMenu.vue?vue&type=template&id=6b53ea14"
import script from "./ActionMenu.vue?vue&type=script&lang=ts"
export * from "./ActionMenu.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ActionMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonSecondary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Secondary.vue').default,SfdrExportDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/ExportDialog.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VList,VListItem,VListItemTitle,VMenu,VSelect})
