var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-sub-section',[_c('ix-sub-section-text',{staticClass:"divide-y-1"},[_c('ix-content-section',{attrs:{"divide-x":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t('impactGoal.euTaxonomy.explanation.backgroundTitle'))+"\n        ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(_vm.$t('impactGoal.euTaxonomy.explanation.background'))
          )}})]},proxy:true}])}),_vm._v(" "),_c('ix-content-section',{attrs:{"divide-x":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t('impactGoal.euTaxonomy.explanation.implementationTitle'))+"\n        ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t('impactGoal.euTaxonomy.explanation.implementation'),
            )
          )}})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }