import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount;
    });
    return {
      account: account
    };
  },
  data: function data() {
    return {
      addEsgToken: false,
      addImpactGoalsToken: false,
      addCompanyToken: false,
      addUserToken: false
    };
  },
  computed: {
    shareLink: function shareLink() {
      var venture = this.account;
      var ventureId = venture === null || venture === void 0 ? void 0 : venture.id;
      if (!venture) {
        return '';
      }
      var ventureHash = ventureId; // remove 'rec'
      var link = window.location.host + "/register/?portfolio=".concat(ventureHash, "&metricsToken=").concat(venture === null || venture === void 0 ? void 0 : venture.metricsToken);
      if (this.addEsgToken) link = link + "&esgToken=".concat(venture.esgToken);
      if (this.addImpactGoalsToken) link = link + "&impactGoalToken=".concat(venture.impactGoalToken);
      return link;
    }
  },
  methods: {
    handleEmptyString: function handleEmptyString(string) {
      // FIXME any
      return string || '';
    }
  }
});