import { render, staticRenderFns } from "./PerformanceComparisonDouble.vue?vue&type=template&id=be61d2a2&scoped=true"
import script from "./PerformanceComparisonDouble.vue?vue&type=script&lang=ts"
export * from "./PerformanceComparisonDouble.vue?vue&type=script&lang=ts"
import style0 from "./PerformanceComparisonDouble.vue?vue&type=style&index=0&id=be61d2a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be61d2a2",
  null
  
)

/* custom blocks */
import block0 from "./PerformanceComparisonDouble.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyEsgSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgSectionTitle.vue').default,EsgPerformanceComparisonProgressBarDouble: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PerformanceComparisonProgressBarDouble.vue').default,EsgPortfolioDifferenceColumn: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PortfolioDifferenceColumn.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VDataTable,VIcon,VRow,VTooltip})
