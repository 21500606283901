var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.isError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.isError),callback:function ($$v) {_vm.isError=$$v},expression:"isError"}},[_vm._v("\n    There was a problem and the last action was aborted.\n    ")]),_vm._v(" "),_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n    "+_vm._s(_vm.type === 'edit'
        ? _vm.$t('companyPerspective.tasks.edit')
        : _vm.$t('companyPerspective.tasks.add'))+"\n    "+_vm._s(_vm.$t('companyPerspective.tasks.task'))+"\n  ")]),_vm._v(" "),_c('v-card-text',[_c('task-form',{ref:"taskForm",attrs:{"value":_vm.editedTaskAssignment.task,"venture-id":_vm.ventureId,"type":_vm.type}}),_vm._v(" "),_c('task-assignment-form',{ref:"taskAssignmentForm",attrs:{"value":_vm.editedTaskAssignment,"venture-id":_vm.ventureId,"type":_vm.type}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"black","text":""},on:{"click":() => {
          _vm.resetForm()
          _vm.$emit('cancel')
        }}},[_vm._v("\n      "+_vm._s(_vm.$t('general.navigation.cancel'))+"\n    ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"primary","text":""},on:{"click":_vm.submit}},[_vm._v("\n      "+_vm._s(_vm.type === 'edit'
          ? _vm.$t('companyPerspective.tasks.update')
          : _vm.$t('companyPerspective.tasks.add'))+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }