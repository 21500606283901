import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.find-index.js";
import { flattenDeep, toNumber } from 'lodash';
import { fundSetupI18nText } from './i18n';
import { arrayAverage } from '~/functions/helpers';
import { renderMarkdown } from '~/functions/markdown';
import { findSectionRecursive, flattenSections, getSectionIdsRecursive, isAnswered, unAssignedVentureIds } from '~/functions/sfdr';
import { EsCharacteristicStrategy, FundStrategy, SfdrStrategyKind, useAddSfdrStrategyMutation, useGetAccountQuery, useGetFundIsInSyncLazyQuery, useGetFundQuery, useGetUserQuery, useUpdateAccountMutation, useUpdateFundMutation, useUpdateSfdrStrategyMutation } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser) || {
        locale: 'en-US',
        isAdmin: false
      };
    });
    var showDebugging = computed(function () {
      try {
        var _userResult$value2, _userResult$value2$ge, _JSON$parse, _userResult$value3, _userResult$value3$ge;
        return ((_userResult$value2 = userResult.value) === null || _userResult$value2 === void 0 ? void 0 : (_userResult$value2$ge = _userResult$value2.getUser) === null || _userResult$value2$ge === void 0 ? void 0 : _userResult$value2$ge.isImpersonated) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging')) || ((_userResult$value3 = userResult.value) === null || _userResult$value3 === void 0 ? void 0 : (_userResult$value3$ge = _userResult$value3.getUser) === null || _userResult$value3$ge === void 0 ? void 0 : _userResult$value3$ge.isAdmin);
      } catch (_unused) {}
    });
    var account = computed(function () {
      var _accountResult$value;
      return (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount;
    });
    var currentLocale = computed(function () {
      // In case locale is 'en' - it returns 'en'.
      // In case locale is 'en-US' - it also returns 'en'.
      return user.value.locale.substring(0, 2);
    });
    var fundId = computed(function () {
      var _route$params;
      return toNumber((_route$params = route.params) === null || _route$params === void 0 ? void 0 : _route$params.fund);
    });
    var _useGetFundQuery = useGetFundQuery({
        id: fundId.value
      }),
      result = _useGetFundQuery.result,
      error = _useGetFundQuery.error,
      loading = _useGetFundQuery.loading,
      onGetFundResult = _useGetFundQuery.onResult;
    var _useGetFundIsInSyncLa = useGetFundIsInSyncLazyQuery({
        id: fundId
      }),
      isInSyncResult = _useGetFundIsInSyncLa.result,
      load = _useGetFundIsInSyncLa.load,
      refetch = _useGetFundIsInSyncLa.refetch;
    var checkFundSync = function checkFundSync() {
      load() || refetch();
    };
    onGetFundResult(function () {
      checkFundSync();
    });
    var fund = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getFund;
    });
    var ventureIds = computed(function () {
      var _fund$value$ventureId, _fund$value;
      return (_fund$value$ventureId = (_fund$value = fund.value) === null || _fund$value === void 0 ? void 0 : _fund$value.ventureIds) !== null && _fund$value$ventureId !== void 0 ? _fund$value$ventureId : [];
    });
    var _useUpdateFundMutatio = useUpdateFundMutation(),
      updateFund = _useUpdateFundMutatio.mutate,
      updateFundLoading = _useUpdateFundMutatio.loading;
    var _useUpdateAccountMuta = useUpdateAccountMutation(),
      updateAccount = _useUpdateAccountMuta.mutate;
    var _useUpdateSfdrStrateg = useUpdateSfdrStrategyMutation(),
      updateSfdrStrategy = _useUpdateSfdrStrateg.mutate;
    var _useAddSfdrStrategyMu = useAddSfdrStrategyMutation(),
      createSfdrStrategy = _useAddSfdrStrategyMu.mutate;
    var sustainableObjectives = computed(function () {
      var _ref, _fund$value2;
      return (_ref = _toConsumableArray((_fund$value2 = fund.value) === null || _fund$value2 === void 0 ? void 0 : _fund$value2.sustainableObjectives)) === null || _ref === void 0 ? void 0 : _ref.sort(function (a, b) {
        var _a$concept, _a$concept$label, _b$concept;
        return (_a$concept = a.concept) === null || _a$concept === void 0 ? void 0 : (_a$concept$label = _a$concept.label) === null || _a$concept$label === void 0 ? void 0 : _a$concept$label.localeCompare((_b$concept = b.concept) === null || _b$concept === void 0 ? void 0 : _b$concept.label);
      });
    });
    var isInSync = computed(function () {
      var _isInSyncResult$value, _isInSyncResult$value2;
      return ((_isInSyncResult$value = isInSyncResult.value) === null || _isInSyncResult$value === void 0 ? void 0 : (_isInSyncResult$value2 = _isInSyncResult$value.getFund) === null || _isInSyncResult$value2 === void 0 ? void 0 : _isInSyncResult$value2.isInSync) || false;
    });
    var fundStrategy = computed(function () {
      var _fund$value$fundStrat, _fund$value3;
      return (_fund$value$fundStrat = fund === null || fund === void 0 ? void 0 : (_fund$value3 = fund.value) === null || _fund$value3 === void 0 ? void 0 : _fund$value3.fundStrategy) !== null && _fund$value$fundStrat !== void 0 ? _fund$value$fundStrat : FundStrategy.Article_6;
    });
    var isConsideringPais = computed(function () {
      var _fund$value$isConside, _fund$value4;
      return (_fund$value$isConside = fund === null || fund === void 0 ? void 0 : (_fund$value4 = fund.value) === null || _fund$value4 === void 0 ? void 0 : _fund$value4.isConsideringPais) !== null && _fund$value$isConside !== void 0 ? _fund$value$isConside : null;
    });
    var isArticle8Plus = computed(function () {
      return fundStrategy.value === FundStrategy.Article_8Plus;
    });
    var isArticle8 = computed(function () {
      return fundStrategy.value === FundStrategy.Article_8;
    });
    var isArticle9 = computed(function () {
      return fundStrategy.value === FundStrategy.Article_9;
    });
    var isPaiOnly = computed(function () {
      var _fund$value5;
      return fundStrategy.value === FundStrategy.OnlyPais || fundStrategy.value === FundStrategy.Article_6 && ((_fund$value5 = fund.value) === null || _fund$value5 === void 0 ? void 0 : _fund$value5.isReportingPais) === true;
    });
    var triggerUpdateSfdrStrategy = function triggerUpdateSfdrStrategy(id, key, val) {
      var input = {
        id: id
      };
      input[key] = val;
      updateSfdrStrategy({
        input: input
      });
    };
    var triggerUpdateFund = function triggerUpdateFund(key, val) {
      var _fund$value6;
      var input = {
        id: (_fund$value6 = fund.value) === null || _fund$value6 === void 0 ? void 0 : _fund$value6.id
      };
      input[key] = val === undefined || val === null ? '' : val;
      updateFund({
        input: input
      });
    };
    var triggerUpdateAccount = function triggerUpdateAccount(key, val) {
      var _account$value;
      var input = {
        id: (_account$value = account.value) === null || _account$value === void 0 ? void 0 : _account$value.id
      };
      input[key] = val === undefined || val === null ? '' : val;
      updateAccount({
        input: input
      });
    };
    var isMediumOrDarkGreen = computed(function () {
      return fundStrategy.value === FundStrategy.Article_9 || fundStrategy.value === FundStrategy.Article_8Plus;
    });
    var completionPortfolioSection = computed(function () {
      var _account$value2, _account$value3;
      return arrayAverage(isAnswered(account === null || account === void 0 ? void 0 : (_account$value2 = account.value) === null || _account$value2 === void 0 ? void 0 : _account$value2.nameOfFinancialMarketParticipant), isAnswered(account === null || account === void 0 ? void 0 : (_account$value3 = account.value) === null || _account$value3 === void 0 ? void 0 : _account$value3.legalEntityIdentifier));
    });
    var completionFundSection = computed(function () {
      var _fund$value7, _fund$value8, _fund$value9, _fund$value10, _fund$value11, _fund$value12;
      return arrayAverage(isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value7 = fund.value) === null || _fund$value7 === void 0 ? void 0 : _fund$value7.name), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value8 = fund.value) === null || _fund$value8 === void 0 ? void 0 : _fund$value8.legalEntityIdentifier), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value9 = fund.value) === null || _fund$value9 === void 0 ? void 0 : _fund$value9.volume), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value10 = fund.value) === null || _fund$value10 === void 0 ? void 0 : _fund$value10.investmentPeriod), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value11 = fund.value) === null || _fund$value11 === void 0 ? void 0 : _fund$value11.fundSpecificInfo), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value12 = fund.value) === null || _fund$value12 === void 0 ? void 0 : _fund$value12.ventureIds));
    });
    var completionBasicInfo = computed(function () {
      var toCheck = [completionPortfolioSection.value, completionFundSection.value].filter(function (e) {
        return e !== null;
      });
      return arrayAverage.apply(void 0, _toConsumableArray(toCheck));
    });
    var completionStrategySelection = computed(function () {
      var _fund$value13, _fund$value14;
      var toCheck = [isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value13 = fund.value) === null || _fund$value13 === void 0 ? void 0 : _fund$value13.fundStrategy)];
      if ((fund === null || fund === void 0 ? void 0 : (_fund$value14 = fund.value) === null || _fund$value14 === void 0 ? void 0 : _fund$value14.isReportingPais) === false) {
        var _fund$value15;
        toCheck.push(isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value15 = fund.value) === null || _fund$value15 === void 0 ? void 0 : _fund$value15.notConsideringPaisReason));
      }
      return arrayAverage.apply(void 0, toCheck);
    });
    var completionSustainableObjectiveCreation = computed(function () {
      var _fund$value16, _fund$value16$sustain, _fund$value17, _fund$value18, _fund$value19;
      var hasAtLeastOneObjective = fund !== null && fund !== void 0 && (_fund$value16 = fund.value) !== null && _fund$value16 !== void 0 && (_fund$value16$sustain = _fund$value16.sustainableObjectives) !== null && _fund$value16$sustain !== void 0 && _fund$value16$sustain.length ? 1 : 0;
      return arrayAverage(hasAtLeastOneObjective, isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value17 = fund.value) === null || _fund$value17 === void 0 ? void 0 : _fund$value17.investmentStrategy), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value18 = fund.value) === null || _fund$value18 === void 0 ? void 0 : _fund$value18.bindingElementsOfInvestmentStrategy), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value19 = fund.value) === null || _fund$value19 === void 0 ? void 0 : _fund$value19.companyGovernancePolicy));
    });
    var completionPaiConsiderations = computed(function () {
      var _fund$value20, _fund$value21, _fund$value22, _fund$value23, _fund$value24, _fund$value25;
      var toChecks = [];
      toChecks.push(isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value20 = fund.value) === null || _fund$value20 === void 0 ? void 0 : _fund$value20.isReportingPais));
      var paiChecks = [isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value21 = fund.value) === null || _fund$value21 === void 0 ? void 0 : _fund$value21.socialMetrics), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value22 = fund.value) === null || _fund$value22 === void 0 ? void 0 : _fund$value22.paiSelectionMethodologies), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value23 = fund.value) === null || _fund$value23 === void 0 ? void 0 : _fund$value23.environmentalMetrics), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value24 = fund.value) === null || _fund$value24 === void 0 ? void 0 : _fund$value24.paiPolicyAdministration)];
      if ((fund === null || fund === void 0 ? void 0 : (_fund$value25 = fund.value) === null || _fund$value25 === void 0 ? void 0 : _fund$value25.isReportingPais) === true) {
        toChecks.push.apply(toChecks, paiChecks);
      }
      return arrayAverage.apply(void 0, toChecks);
    });
    var completionKfwConsiderations = computed(function () {
      var _fund$value26, _fund$value26$venture, _fund$value27, _fund$value27$minimum, _fund$value28, _fund$value28$recomme, _fund$value29, _fund$value29$fullRep, _fund$value30;
      var numberFundCompanies = (fund === null || fund === void 0 ? void 0 : (_fund$value26 = fund.value) === null || _fund$value26 === void 0 ? void 0 : (_fund$value26$venture = _fund$value26.ventureIds) === null || _fund$value26$venture === void 0 ? void 0 : _fund$value26$venture.length) || 0;
      if (numberFundCompanies === 0) return 0;
      var numberAssigned = ((fund === null || fund === void 0 ? void 0 : (_fund$value27 = fund.value) === null || _fund$value27 === void 0 ? void 0 : (_fund$value27$minimum = _fund$value27.minimumReportingLevelVentureIds) === null || _fund$value27$minimum === void 0 ? void 0 : _fund$value27$minimum.length) || 0) + ((fund === null || fund === void 0 ? void 0 : (_fund$value28 = fund.value) === null || _fund$value28 === void 0 ? void 0 : (_fund$value28$recomme = _fund$value28.recommendedReportingLevelVentureIds) === null || _fund$value28$recomme === void 0 ? void 0 : _fund$value28$recomme.length) || 0) + ((fund === null || fund === void 0 ? void 0 : (_fund$value29 = fund.value) === null || _fund$value29 === void 0 ? void 0 : (_fund$value29$fullRep = _fund$value29.fullReportingLevelVentureIds) === null || _fund$value29$fullRep === void 0 ? void 0 : _fund$value29$fullRep.length) || 0);
      return fund !== null && fund !== void 0 && (_fund$value30 = fund.value) !== null && _fund$value30 !== void 0 && _fund$value30.isReportingKfw ? numberAssigned / numberFundCompanies : 1;
    });
    var completionSustainableObjectiveForm = function completionSustainableObjectiveForm(objective, article) {
      var toCheck = [isAnswered(objective.concept), isAnswered(objective.ventureIds), isAnswered(objective.strategyDescription), isAnswered(objective.actionsTaken), isAnswered(objective.isEnvironmentalGoal), isAnswered(objective.metrics), isAnswered(objective.sustainabilityIndicatorsMonitoringStrategy), isAnswered(objective.entityResponsibleForMonitoringIndicators)];
      if (objective.isEnvironmentalGoal && article === FundStrategy.Article_9) toCheck.push(isAnswered(objective.isCo2EmissionGoal));
      if (objective.isCo2EmissionGoal) toCheck.push(isAnswered(objective.benchmarkDescription));
      if (article === FundStrategy.Article_9) {
        toCheck.push(isAnswered(objective.dueDiligenceProcessDescription), isAnswered(objective.engagementPolicyWithSpecificInvestmentStrategy), isAnswered(objective.isConsideringSourcesAndEstimates));
        if (objective.isConsideringSourcesAndEstimates === true) {
          toCheck.push(isAnswered(objective.dataSourcesConsidered), isAnswered(objective.shareOfDataSecondHand));
        }
      }
      return arrayAverage.apply(void 0, toCheck);
    };
    var completionSustainableObjectiveSection = computed(function () {
      var _fund$value31;
      if (!isMediumOrDarkGreen.value) return 1;
      var toCheck = [completionSustainableObjectiveCreation.value];
      if (fund !== null && fund !== void 0 && (_fund$value31 = fund.value) !== null && _fund$value31 !== void 0 && _fund$value31.sustainableObjectives) {
        var _fund$value32;
        toCheck.push.apply(toCheck, _toConsumableArray(fund === null || fund === void 0 ? void 0 : (_fund$value32 = fund.value) === null || _fund$value32 === void 0 ? void 0 : _fund$value32.sustainableObjectives.map(function (e) {
          return completionSustainableObjectiveForm(e, fundStrategy.value);
        })));
      }
      if (fundStrategy.value === FundStrategy.Article_9) toCheck.push(unAssignedVentureIds(fund.value).length === 0 ? 1 : 0);

      // console.log('toCheck', toCheck)

      return arrayAverage.apply(void 0, toCheck);
    });
    var completionEsCharacteristic = computed(function () {
      var _fund$value33;
      if (fundStrategy.value === FundStrategy.Article_9 || fundStrategy.value === FundStrategy.Article_6 || fundStrategy.value === FundStrategy.OnlyPais) return 1;
      var esc = fund === null || fund === void 0 ? void 0 : (_fund$value33 = fund.value) === null || _fund$value33 === void 0 ? void 0 : _fund$value33.esCharacteristics;
      if (!esc) {
        return 0;
      }
      var toCheck = [{
        result: isAnswered(esc.esStrategy),
        why: 'esc.esStrategy'
      }, {
        result: isAnswered(esc.esCharacteristicsDescription),
        why: 'esc.esCharacteristicsDescription'
      }, {
        result: isAnswered(esc.metrics),
        why: 'esc.metrics'
      }, {
        result: isAnswered(esc.dueDiligenceProcessDescription),
        why: 'esc.dueDiligenceProcessDescription'
      }, {
        result: isAnswered(esc.engagementPolicyWithSpecificInvestmentStrategy),
        why: 'esc.engagementPolicyWithSpecificInvestmentStrategy'
      }, {
        result: isAnswered(esc.isConsideringSourcesAndEstimates),
        why: 'esc.isConsideringSourcesAndEstimates'
      }];
      if (esc.isConsideringSourcesAndEstimates === true) {
        toCheck.push({
          result: isAnswered(esc.dataSourcesConsidered),
          why: 'esc.dataSourcesConsidered'
        });
        toCheck.push({
          result: isAnswered(esc.shareOfDataSecondHand),
          why: 'esc.shareOfDataSecondHand'
        });
      }
      if (fundStrategy.value === FundStrategy.Article_8) {
        toCheck.push({
          result: isAnswered(esc.engagementPolicyWithSpecificInvestmentStrategy),
          why: 'esc.engagementPolicyWithSpecificInvestmentStrategy'
        });
      }
      if (esc.esStrategy.includes(EsCharacteristicStrategy.EsgIntegration)) toCheck.push({
        result: isAnswered(esc.esgIntegrationDescription),
        why: 'esc.esgIntegrationDescription'
      });
      if (esc.esStrategy.includes(EsCharacteristicStrategy.ExclusionCriteria)) toCheck.push({
        result: isAnswered(esc.exclusionCriteriaDescription),
        why: 'esc.exclusionCriteriaDescription'
      });
      if (esc.esStrategy.includes(EsCharacteristicStrategy.BestInClass)) toCheck.push({
        result: isAnswered(esc.bestInClassDescription),
        why: 'esc.bestInClassDescription'
      });
      if (esc.esStrategy.includes(EsCharacteristicStrategy.NormBasedScreening)) toCheck.push({
        result: isAnswered(esc.normBasedScreeningDescription),
        why: 'esc.normBasedScreeningDescription'
      });
      if (esc.esStrategy.includes(EsCharacteristicStrategy.SustainabilityLabel)) toCheck.push({
        result: isAnswered(esc.sustainabilityLabelDescription),
        why: 'esc.sustainabilityLabelDescription'
      });
      return arrayAverage.apply(void 0, _toConsumableArray(toCheck.map(function (item) {
        return item.result;
      })));
    });
    var completionSustainabilityRisk = computed(function () {
      var _fund$value34, _fund$value35;
      var toCheck = [isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value34 = fund.value) === null || _fund$value34 === void 0 ? void 0 : _fund$value34.isConsideringSustainabilityRisk)];
      if (fund !== null && fund !== void 0 && (_fund$value35 = fund.value) !== null && _fund$value35 !== void 0 && _fund$value35.isConsideringSustainabilityRisk) {
        var _fund$value36, _fund$value37;
        toCheck.push(isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value36 = fund.value) === null || _fund$value36 === void 0 ? void 0 : _fund$value36.sustainabilityRiskIntegrationDescription), isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value37 = fund.value) === null || _fund$value37 === void 0 ? void 0 : _fund$value37.likelyImpactsFromSustainabilityRisk));
      } else {
        var _fund$value38;
        toCheck.push(isAnswered(fund === null || fund === void 0 ? void 0 : (_fund$value38 = fund.value) === null || _fund$value38 === void 0 ? void 0 : _fund$value38.reasonNotConsideringSustainabilityRisk));
      }
      return arrayAverage.apply(void 0, toCheck);
    });
    var completionEntitySustainabilityApproach = computed(function () {
      var _account$value4, _account$value5;
      var toCheck = [isAnswered((_account$value4 = account.value) === null || _account$value4 === void 0 ? void 0 : _account$value4.sustainabilityRiskIntegration), isAnswered((_account$value5 = account.value) === null || _account$value5 === void 0 ? void 0 : _account$value5.remunerationPolicies)];
      return arrayAverage.apply(void 0, toCheck);
    });
    var completionSustainabilityApproach = computed(function () {
      var toCheck = [completionEntitySustainabilityApproach.value, completionStrategySelection.value, completionSustainableObjectiveSection.value, completionEsCharacteristic.value, completionSustainabilityRisk.value].filter(function (e) {
        return e !== null;
      });
      return arrayAverage.apply(void 0, _toConsumableArray(toCheck));
    });
    var completionMandatorySections = computed(function () {
      var sectionCompletion = sections.value.map(function (section) {
        return (section === null || section === void 0 ? void 0 : section.percentageCompleted) || 1;
      });
      return arrayAverage.apply(void 0, _toConsumableArray(sectionCompletion));
    });
    var completionSubmit = computed(function () {
      var toCheck = [completionMandatorySections.value, isInSync.value === true ? 1 : 0];
      return arrayAverage.apply(void 0, toCheck);
    });
    var paiSection = computed(function () {
      var _account$value6, _fund$value39, _account$value7, _fund$value40, _fund$value$paiSelect, _fund$value41, _fund$value$paiPolicy, _fund$value42, _fund$value$considera, _fund$value43, _fund$value$considera2, _fund$value44;
      return {
        id: 'pai',
        name: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.pai.name[currentLocale.value],
        header: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.pai.header[currentLocale.value],
        percentageCompleted: completionPaiConsiderations.value,
        content: [{
          id: 'sfdrPaiSetupIntro',
          component: 'sfdr-pai-setup-intro',
          props: {
            isPaiOnly: isPaiOnly.value
          }
        }, {
          id: 'socialMetricIds',
          component: 'sfdr-metrics-dialog',
          props: {
            id: 'socialMetricIds',
            title: fundSetupI18nText.questions.socialMetricIds.title[currentLocale.value],
            portfolioId: (_account$value6 = account.value) === null || _account$value6 === void 0 ? void 0 : _account$value6.id,
            query: {
              sections: [91 // 'Additional social PAI Indicators'
              ]
            },
            value: fund === null || fund === void 0 ? void 0 : (_fund$value39 = fund.value) === null || _fund$value39 === void 0 ? void 0 : _fund$value39.socialMetrics,
            allowCustom: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('socialMetricIds', val);
            }
          }
        }, {
          id: 'environmentalMetricIds',
          component: 'sfdr-metrics-dialog',
          props: {
            id: 'environmentalMetricIds',
            title: fundSetupI18nText.questions.environmentalMetricIds.title[currentLocale.value],
            subtitle: fundSetupI18nText.questions.environmentalMetricIds.subtitle[currentLocale.value],
            tooltip: renderMarkdown(fundSetupI18nText.questions.environmentalMetricIds.tooltip[currentLocale.value]),
            portfolioId: (_account$value7 = account.value) === null || _account$value7 === void 0 ? void 0 : _account$value7.id,
            query: {
              sections: [83 // 'Additional environmental PAI Indicators'
              ]
            },
            value: fund === null || fund === void 0 ? void 0 : (_fund$value40 = fund.value) === null || _fund$value40 === void 0 ? void 0 : _fund$value40.environmentalMetrics,
            allowCustom: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('environmentalMetricIds', val);
            }
          }
        }, {
          id: 'paiSelectionMethodologies',
          component: 'ix-form-input-text-master',
          props: {
            id: 'paiSelectionMethodologies',
            title: fundSetupI18nText.questions.paiSelectionMethodologies.title[currentLocale.value],
            value: (_fund$value$paiSelect = fund === null || fund === void 0 ? void 0 : (_fund$value41 = fund.value) === null || _fund$value41 === void 0 ? void 0 : _fund$value41.paiSelectionMethodologies) !== null && _fund$value$paiSelect !== void 0 ? _fund$value$paiSelect : '',
            tooltip: renderMarkdown(fundSetupI18nText.questions.paiSelectionMethodologies.tooltip[currentLocale.value]),
            useTextArea: true,
            useTextField: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('paiSelectionMethodologies', val);
            }
          }
        }, {
          id: 'paiPolicyAdministration',
          component: 'ix-form-input-text-master',
          props: {
            id: 'paiPolicyAdministration',
            title: fundSetupI18nText.questions.paiPolicyAdministration.title[currentLocale.value],
            tooltip: renderMarkdown(fundSetupI18nText.questions.paiPolicyAdministration.tooltip[currentLocale.value]),
            value: (_fund$value$paiPolicy = fund === null || fund === void 0 ? void 0 : (_fund$value42 = fund.value) === null || _fund$value42 === void 0 ? void 0 : _fund$value42.paiPolicyAdministration) !== null && _fund$value$paiPolicy !== void 0 ? _fund$value$paiPolicy : '',
            useTextArea: true,
            useTextField: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('paiPolicyAdministration', val);
            }
          }
        }, {
          id: 'considerationOfPaisOnSustainabilityEngagementPolicies',
          component: 'ix-form-input-text-master',
          props: {
            id: 'considerationOfPaisOnSustainabilityEngagementPolicies',
            title: fundSetupI18nText.questions.considerationOfPaisOnSustainabilityEngagementPolicies.title[currentLocale.value],
            tooltip: renderMarkdown(fundSetupI18nText.questions.considerationOfPaisOnSustainabilityEngagementPolicies.tooltip[currentLocale.value]),
            value: (_fund$value$considera = fund === null || fund === void 0 ? void 0 : (_fund$value43 = fund.value) === null || _fund$value43 === void 0 ? void 0 : _fund$value43.considerationOfPaisOnSustainabilityEngagementPolicies) !== null && _fund$value$considera !== void 0 ? _fund$value$considera : '',
            useTextArea: true,
            useTextField: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('considerationOfPaisOnSustainabilityEngagementPolicies', val);
            }
          }
        }, {
          id: 'considerationOfPaisOnSustainabilityInternationalStandards',
          component: 'ix-form-input-text-master',
          props: {
            id: 'considerationOfPaisOnSustainabilityInternationalStandards',
            title: fundSetupI18nText.questions.considerationOfPaisOnSustainabilityInternationalStandards.title[currentLocale.value],
            tooltip: renderMarkdown(fundSetupI18nText.questions.considerationOfPaisOnSustainabilityInternationalStandards.tooltip[currentLocale.value]),
            value: (_fund$value$considera2 = fund === null || fund === void 0 ? void 0 : (_fund$value44 = fund.value) === null || _fund$value44 === void 0 ? void 0 : _fund$value44.considerationOfPaisOnSustainabilityInternationalStandards) !== null && _fund$value$considera2 !== void 0 ? _fund$value$considera2 : '',
            useTextArea: true,
            useTextField: false
          },
          events: {
            input: function input(val) {
              triggerUpdateFund('considerationOfPaisOnSustainabilityInternationalStandards', val);
            }
          }
        }]
      };
    });
    var kfwSection = computed(function () {
      var _fund$value45, _fund$value46, _account$value8;
      return {
        id: 'kfw',
        name: fundSetupI18nText.sections.kfwReporting.name[currentLocale.value],
        header: fundSetupI18nText.sections.kfwReporting.header[currentLocale.value],
        percentageCompleted: completionKfwConsiderations.value,
        content: [{
          id: 'kfw-ventures',
          component: 'kfw-ventures',
          props: {
            value: fund === null || fund === void 0 ? void 0 : (_fund$value45 = fund.value) === null || _fund$value45 === void 0 ? void 0 : _fund$value45.ventureIds,
            fundId: fund === null || fund === void 0 ? void 0 : (_fund$value46 = fund.value) === null || _fund$value46 === void 0 ? void 0 : _fund$value46.id,
            portfolioId: (_account$value8 = account.value) === null || _account$value8 === void 0 ? void 0 : _account$value8.id,
            question: {
              title: fundSetupI18nText.questions.ventureIds.title[currentLocale.value],
              isMandatory: true
            }
          },
          events: {
            input: function input(_ref2) {
              var array = _ref2.array,
                option = _ref2.option;
              if (option === 'minimum') {
                triggerUpdateFund('minimumReportingLevelVentureIds', array);
              } else if (option === 'recommended') {
                triggerUpdateFund('recommendedReportingLevelVentureIds', array);
              } else if (option === 'full') {
                triggerUpdateFund('fullReportingLevelVentureIds', array);
              }
            }
          }
        }]
      };
    });
    var reportingRequirementsExist = computed(function () {
      if (fundStrategy.value === FundStrategy.Article_6) {
        var _fund$value47, _fund$value48;
        return ((_fund$value47 = fund.value) === null || _fund$value47 === void 0 ? void 0 : _fund$value47.isReportingKfw) || ((_fund$value48 = fund.value) === null || _fund$value48 === void 0 ? void 0 : _fund$value48.isReportingPais) || false;
      }
      return true;
    });
    var sections = computed(function () {
      var _account$value9, _account$value10, _account$value11, _fund$value49, _fund$value50, _fund$value51, _fund$value52, _fund$value53, _account$value12, _fund$value54, _fund$value55, _account$value13, _fundSetupI18nText$qu, _fundSetupI18nText$qu2, _account$value14, _fund$value56, _fund$value57, _fund$value$notConsid, _fund$value58, _fund$value59, _account$value$nameOf, _account$value15, _account$value$entity, _account$value16, _fund$value60, _fund$value61, _fund$value62, _account$value17, _fund$value63, _account$value18, _fund$value64, _fund$value65, _fund$value66, _fund$value67, _fund$value68, _fund$value69, _fund$value70;
      return [{
        id: 'intro',
        name: fundSetupI18nText.sections.introduction.name[currentLocale.value],
        header: fundSetupI18nText.sections.introduction.name[currentLocale.value],
        percentageCompleted: completionPaiConsiderations.value,
        content: [{
          id: 'sfdrIntroStepper',
          component: 'sfdr-intro-stepper',
          props: {},
          events: {
            input: function input() {
              return navigate('next');
            }
          }
        }]
      }, {
        id: 'basicInfo',
        name: fundSetupI18nText.sections.basicInformation.name[currentLocale.value],
        percentageCompleted: completionBasicInfo.value,
        content: [],
        sections: [{
          id: 'basicInfo_fundManagementCompany',
          name: fundSetupI18nText.sections.basicInformation.fundManagementCompany.name[currentLocale.value],
          header: fundSetupI18nText.sections.basicInformation.fundManagementCompany.header[currentLocale.value],
          percentageCompleted: completionPortfolioSection.value,
          content: [{
            id: 'nameOfFinancialMarketParticipant',
            component: 'ix-form-input-text-master',
            props: {
              id: 'nameOfFinancialMarketParticipant',
              title: fundSetupI18nText.questions.nameOfFinancialMarketParticipant.title[currentLocale.value],
              isMandatory: true,
              value: (_account$value9 = account.value) === null || _account$value9 === void 0 ? void 0 : _account$value9.nameOfFinancialMarketParticipant
            },
            events: {
              input: function input(val) {
                triggerUpdateAccount('nameOfFinancialMarketParticipant', val);
              }
            }
          }, {
            id: 'legalEntityIdentifier',
            component: 'ix-form-input-text-master',
            props: {
              id: 'legalEntityIdentifier',
              title: fundSetupI18nText.questions.legalEntityIdentifier.title[currentLocale.value],
              value: (_account$value10 = account.value) === null || _account$value10 === void 0 ? void 0 : _account$value10.legalEntityIdentifier
            },
            events: {
              input: function input(val) {
                triggerUpdateAccount('legalEntityIdentifier', val);
              }
            }
          }, {
            id: 'subsidiaries',
            component: 'ix-form-input-text-master',
            props: {
              id: 'subsidiaries',
              title: fundSetupI18nText.questions.subsidiaries.title[currentLocale.value],
              value: (_account$value11 = account.value) === null || _account$value11 === void 0 ? void 0 : _account$value11.subsidiaries,
              useComboBox: true,
              useTextField: false,
              isMandatory: false
            },
            events: {
              change: function change(val) {
                triggerUpdateAccount('subsidiaries', val);
              }
            }
          }, {
            id: 'entitySustainabilityExtraInfo',
            component: 'ix-form-input-text-master',
            props: {
              id: 'entitySustainabilityExtraInfo',
              title: fundSetupI18nText.questions.entitySustainabilityExtraInfo.title[currentLocale.value],
              value: fund === null || fund === void 0 ? void 0 : (_fund$value49 = fund.value) === null || _fund$value49 === void 0 ? void 0 : _fund$value49.entitySustainabilityExtraInfo
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('entitySustainabilityExtraInfo', val);
              }
            }
          }]
        }, {
          id: 'basicInfo_fund',
          name: fundSetupI18nText.sections.basicInformation.fund.name[currentLocale.value],
          header: fundSetupI18nText.sections.basicInformation.fund.header[currentLocale.value],
          percentageCompleted: completionFundSection.value,
          content: [{
            id: 'fundName',
            component: 'ix-form-input-text-master',
            props: {
              id: 'fundName',
              title: fundSetupI18nText.questions.fundName.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value50 = fund.value) === null || _fund$value50 === void 0 ? void 0 : _fund$value50.name
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('name', val);
              }
            }
          }, {
            id: 'fundLegalEntityIdentifier',
            component: 'ix-form-input-text-master',
            props: {
              id: 'fundLegalEntityIdentifier',
              title: fundSetupI18nText.questions.fundLegalEntityIdentifier.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value51 = fund.value) === null || _fund$value51 === void 0 ? void 0 : _fund$value51.legalEntityIdentifier
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('legalEntityIdentifier', val);
              }
            }
          }, {
            id: 'volume',
            component: 'ix-form-input-text-master',
            props: {
              id: 'volume',
              title: fundSetupI18nText.questions.volume.title[currentLocale.value],
              tooltip: fundSetupI18nText.questions.volume.tooltip[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value52 = fund.value) === null || _fund$value52 === void 0 ? void 0 : _fund$value52.volume,
              textFieldType: 'number'
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('volume', Number(val));
              }
            }
          }, {
            id: 'investmentPeriodId',
            component: 'ix-form-input-text-master',
            props: {
              id: 'investmentPeriodId',
              title: fundSetupI18nText.questions.investmentPeriod.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value53 = fund.value) === null || _fund$value53 === void 0 ? void 0 : _fund$value53.investmentPeriod,
              useDatePicker: true,
              useTextField: false,
              portfolioId: (_account$value12 = account.value) === null || _account$value12 === void 0 ? void 0 : _account$value12.id
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('investmentPeriodId', val.id);
              }
            }
          }, {
            id: 'fundSpecificInfo',
            component: 'ix-form-input-text-master',
            props: {
              id: 'fundSpecificInfo',
              title: fundSetupI18nText.questions.fundSpecificInfo.title[currentLocale.value],
              subtitle: fundSetupI18nText.questions.fundSpecificInfo.subtitle[currentLocale.value],
              tooltip: fundSetupI18nText.questions.fundSpecificInfo.tooltip[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value54 = fund.value) === null || _fund$value54 === void 0 ? void 0 : _fund$value54.fundSpecificInfo
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('fundSpecificInfo', val);
              }
            }
          }, {
            id: 'ventureIds',
            component: 'ix-data-table-accounts',
            props: {
              value: fund === null || fund === void 0 ? void 0 : (_fund$value55 = fund.value) === null || _fund$value55 === void 0 ? void 0 : _fund$value55.ventureIds,
              question: {
                title: fundSetupI18nText.questions.ventureIds.title[currentLocale.value],
                isMandatory: true
              }
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('ventureIds', val);
              }
            }
          }]
        }]
      }, {
        id: 'sustainabilityApproach',
        name: fundSetupI18nText.sections.sustainabilityApproach.name[currentLocale.value],
        percentageCompleted: completionSustainabilityApproach.value,
        content: [],
        sections: [{
          id: 'sustainabilityApproach_fundManagementCompany',
          name: fundSetupI18nText.sections.sustainabilityApproach.fundManagementCompany.name[currentLocale.value],
          header: fundSetupI18nText.sections.sustainabilityApproach.fundManagementCompany.header[currentLocale.value],
          percentageCompleted: completionEntitySustainabilityApproach.value,
          content: [{
            id: 'sustainabilityApproach_sustainabilityRiskIntegration',
            component: 'ix-form-input-text-master',
            props: {
              id: 'sustainabilityRiskIntegration',
              title: fundSetupI18nText.questions.sustainabilityRiskIntegration.title[currentLocale.value],
              tooltip: renderMarkdown(fundSetupI18nText.questions.sustainabilityRiskIntegration.tooltip[currentLocale.value]),
              isCopyable: false,
              isMandatory: true,
              value: (_account$value13 = account.value) === null || _account$value13 === void 0 ? void 0 : _account$value13.sustainabilityRiskIntegration
            },
            events: {
              input: function input(val) {
                triggerUpdateAccount('sustainabilityRiskIntegration', val);
              }
            }
          }, {
            id: 'remunerationPolicies',
            component: 'ix-form-input-text-master',
            props: {
              id: 'remunerationPolicies',
              title: fundSetupI18nText.questions.remunerationPolicies.title[currentLocale.value],
              subtitle: fundSetupI18nText.questions.remunerationPolicies.subtitle[currentLocale.value],
              tooltip: renderMarkdown(fundSetupI18nText.questions.remunerationPolicies.tooltip[currentLocale.value]),
              isCopyable: true,
              copyableText: (_fundSetupI18nText$qu = fundSetupI18nText.questions.remunerationPolicies) === null || _fundSetupI18nText$qu === void 0 ? void 0 : (_fundSetupI18nText$qu2 = _fundSetupI18nText$qu.template) === null || _fundSetupI18nText$qu2 === void 0 ? void 0 : _fundSetupI18nText$qu2[currentLocale.value],
              // getEntityRemunerationPoliciesCopyableOptions(),
              isMandatory: true,
              value: (_account$value14 = account.value) === null || _account$value14 === void 0 ? void 0 : _account$value14.remunerationPolicies
            },
            events: {
              input: function input(val) {
                triggerUpdateAccount('remunerationPolicies', val);
              },
              copy: function copy(val) {
                triggerUpdateAccount('remunerationPolicies', val);
              }
            }
          }]
        }, {
          id: 'sustainabilityApproach_fund',
          name: fundSetupI18nText.sections.sustainabilityApproach.fund.name[currentLocale.value],
          header: fundSetupI18nText.sections.sustainabilityApproach.fund.header[currentLocale.value],
          percentageCompleted: completionStrategySelection.value,
          content: [{
            id: 'fundStrategy',
            component: 'ix-form-input-text-master',
            props: {
              id: 'fundStrategy',
              title: fundSetupI18nText.questions.fundStrategy.title[currentLocale.value],
              isMandatory: true,
              value: fundStrategy === null || fundStrategy === void 0 ? void 0 : fundStrategy.value,
              useLargeOptions: true,
              useTextField: false,
              inputOptions: fundStrategyOptions.value
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('fundStrategy', val);
              }
            }
          }].concat(_toConsumableArray((fundStrategy === null || fundStrategy === void 0 ? void 0 : fundStrategy.value) === FundStrategy.Article_6 ? [{
            id: 'article6Alert',
            component: 'ix-alert',
            props: {
              color: 'success',
              outlined: true,
              contentText: fundSetupI18nText.article6Alert[currentLocale.value]
            }
          }] : []), [{
            id: 'isReportingPais',
            component: 'ix-form-input-text-master',
            props: {
              id: 'isReportingPais',
              title: fundSetupI18nText.questions.isReportingPais.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value56 = fund.value) === null || _fund$value56 === void 0 ? void 0 : _fund$value56.isReportingPais,
              useSmallOptions: true,
              useTextField: false,
              inputOptions: [{
                value: true,
                label: fundSetupI18nText.optionYes[currentLocale.value]
              }, {
                value: false,
                label: fundSetupI18nText.optionNo[currentLocale.value]
              }]
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('isReportingPais', val);
              }
            }
          }], _toConsumableArray((fund === null || fund === void 0 ? void 0 : (_fund$value57 = fund.value) === null || _fund$value57 === void 0 ? void 0 : _fund$value57.isReportingPais) === false ? [{
            id: 'notConsideringPaisReason',
            component: 'ix-form-input-text-master',
            props: {
              id: 'notConsideringPaisReason',
              title: fundSetupI18nText.questions.notConsideringPaisReason.title_NO[currentLocale.value],
              tooltip: fundSetupI18nText.questions.notConsideringPaisReason.tooltip_NO[fundStrategy.value][currentLocale.value],
              isMandatory: true,
              isCopyable: true,
              copyableText: fundSetupI18nText.questions.notConsideringPaisReason.template_NO[currentLocale.value],
              value: (_fund$value$notConsid = fund === null || fund === void 0 ? void 0 : (_fund$value58 = fund.value) === null || _fund$value58 === void 0 ? void 0 : _fund$value58.notConsideringPaisReason) !== null && _fund$value$notConsid !== void 0 ? _fund$value$notConsid : ''
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('notConsideringPaisReason', val);
              },
              copy: function copy(val) {
                triggerUpdateFund('notConsideringPaisReason', val);
              }
            }
          }] : []), _toConsumableArray((fund === null || fund === void 0 ? void 0 : (_fund$value59 = fund.value) === null || _fund$value59 === void 0 ? void 0 : _fund$value59.isReportingPais) === true ? [{
            id: 'entityLevelPaiConsideration',
            component: 'ix-form-input-text-master',
            props: {
              id: 'entityLevelPaiConsideration',
              title: fundSetupI18nText.questions.entityLevelPaiConsideration.title[currentLocale.value],
              isMandatory: true,
              isCopyable: true,
              copyableText: fundSetupI18nText.questions.entityLevelPaiConsideration.template[currentLocale.value].replace('{nameOfFinancialMarketParticipant}', (_account$value$nameOf = account === null || account === void 0 ? void 0 : (_account$value15 = account.value) === null || _account$value15 === void 0 ? void 0 : _account$value15.nameOfFinancialMarketParticipant) !== null && _account$value$nameOf !== void 0 ? _account$value$nameOf : ''),
              value: (_account$value$entity = account === null || account === void 0 ? void 0 : (_account$value16 = account.value) === null || _account$value16 === void 0 ? void 0 : _account$value16.entityLevelPaiConsideration) !== null && _account$value$entity !== void 0 ? _account$value$entity : ''
            },
            events: {
              input: function input(val) {
                triggerUpdateAccount('entityLevelPaiConsideration', val);
              },
              copy: function copy(val) {
                triggerUpdateAccount('entityLevelPaiConsideration', val);
              }
            }
          }] : []), [{
            id: 'isReportingKfw',
            component: 'ix-form-input-text-master',
            props: {
              id: 'isReportingKfw',
              title: fundSetupI18nText.questions.isReportingKfw.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value60 = fund.value) === null || _fund$value60 === void 0 ? void 0 : _fund$value60.isReportingKfw,
              useSmallOptions: true,
              useTextField: false,
              inputOptions: [{
                value: true,
                label: fundSetupI18nText.optionYes[currentLocale.value]
              }, {
                value: false,
                label: fundSetupI18nText.optionNo[currentLocale.value]
              }]
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('isReportingKfw', val);
              }
            }
          }])
        }].concat(_toConsumableArray(fundStrategy.value === FundStrategy.Article_8 || fundStrategy.value === FundStrategy.Article_8Plus ? [{
          id: 'sustainabilityApproach_esCharacteristics',
          name: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.esCharacteristics.name[currentLocale.value],
          header: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.esCharacteristics.header[currentLocale.value],
          percentageCompleted: completionEsCharacteristic.value,
          content: [{
            id: 'esCharacteristics',
            component: 'sfdr-es-characteristics-form',
            props: {
              sfdrStrategy: fund === null || fund === void 0 ? void 0 : (_fund$value61 = fund.value) === null || _fund$value61 === void 0 ? void 0 : _fund$value61.esCharacteristics,
              fundId: fund === null || fund === void 0 ? void 0 : (_fund$value62 = fund.value) === null || _fund$value62 === void 0 ? void 0 : _fund$value62.id,
              portfolioId: (_account$value17 = account.value) === null || _account$value17 === void 0 ? void 0 : _account$value17.id,
              fund: fund === null || fund === void 0 ? void 0 : fund.value
            }
          }]
        }] : []), _toConsumableArray(isMediumOrDarkGreen.value ? [{
          id: 'sustainabilityApproach_sfdrStrategies',
          name: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.name[currentLocale.value],
          percentageCompleted: completionSustainabilityApproach.value,
          sections: [{
            id: 'sustainabilityApproach_sfdrStrategies_select',
            name: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.select.name[currentLocale.value],
            header: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.select.header[currentLocale.value],
            percentageCompleted: completionSustainableObjectiveCreation.value,
            content: [{
              id: 'sustainableObjectivesList',
              component: 'sfdr-sustainable-objectives-list',
              props: {
                sustainableObjectives: sustainableObjectives.value,
                fundId: fund === null || fund === void 0 ? void 0 : (_fund$value63 = fund.value) === null || _fund$value63 === void 0 ? void 0 : _fund$value63.id,
                portfolioId: (_account$value18 = account.value) === null || _account$value18 === void 0 ? void 0 : _account$value18.id,
                fund: fund === null || fund === void 0 ? void 0 : fund.value
              },
              events: {
                navigateToSustainableObjective: function navigateToSustainableObjective() {
                  navigate('next');
                }
              }
            }]
          }].concat(_toConsumableArray(sustainableObjectives.value.length > 0 ? sustainableObjectives.value.map(function (objective, index) {
            var _objective$concept, _account$value19;
            return {
              id: 'sustainabilityApproach_sfdrStrategies_edit' + (index + 1),
              name: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.edit.name[currentLocale.value] + ((objective === null || objective === void 0 ? void 0 : (_objective$concept = objective.concept) === null || _objective$concept === void 0 ? void 0 : _objective$concept.label) || index),
              header: fundSetupI18nText.sections.sustainabilityApproach.sfdrStrategies.sustainableInvestmentObjective.edit.header[currentLocale.value],
              percentageCompleted: completionSustainableObjectiveForm(objective, fundStrategy.value),
              content: [{
                id: 'sustainableObjectiveForm_' + (index + 1),
                component: 'sfdr-sustainable-objective-form',
                props: {
                  sustainableObjective: objective,
                  portfolioId: (_account$value19 = account.value) === null || _account$value19 === void 0 ? void 0 : _account$value19.id,
                  fund: fund === null || fund === void 0 ? void 0 : fund.value
                },
                events: {
                  updateStrategy: function updateStrategy(id, key, val) {
                    return triggerUpdateSfdrStrategy(id, key, val);
                  }
                }
              }]
            };
          }) : []))
        }] : []), [{
          id: 'sustainabilityApproach_sustainabilityRisk',
          name: fundSetupI18nText.sections.sustainabilityApproach.sustainabilityRisks.name[currentLocale.value],
          header: fundSetupI18nText.sections.sustainabilityApproach.sustainabilityRisks.header[currentLocale.value],
          percentageCompleted: completionSustainabilityRisk.value,
          content: [{
            id: 'isConsideringSustainabilityRisk',
            component: 'ix-form-input-text-master',
            props: {
              id: 'isConsideringSustainabilityRisk',
              title: fundSetupI18nText.questions.isConsideringSustainabilityRisk.title[currentLocale.value],
              tooltip: renderMarkdown(fundSetupI18nText.questions.isConsideringSustainabilityRisk.tooltip[currentLocale.value]),
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value64 = fund.value) === null || _fund$value64 === void 0 ? void 0 : _fund$value64.isConsideringSustainabilityRisk,
              useSmallOptions: true,
              useTextField: false,
              inputOptions: [{
                value: true,
                label: fundSetupI18nText.optionYes[currentLocale.value]
              }, {
                value: false,
                label: fundSetupI18nText.optionNo[currentLocale.value]
              }]
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('isConsideringSustainabilityRisk', val);
              }
            }
          }].concat(_toConsumableArray(fund !== null && fund !== void 0 && (_fund$value65 = fund.value) !== null && _fund$value65 !== void 0 && _fund$value65.isConsideringSustainabilityRisk ? [{
            id: 'sustainabilityRiskIntegrationDescription',
            component: 'ix-form-input-text-master',
            props: {
              id: 'sustainabilityRiskIntegrationDescription',
              title: fundSetupI18nText.questions.sustainabilityRiskIntegrationDescription.title[currentLocale.value],
              tooltip: renderMarkdown(fundSetupI18nText.questions.sustainabilityRiskIntegrationDescription.tooltip[currentLocale.value]),
              useTextField: false,
              useTextArea: true,
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value66 = fund.value) === null || _fund$value66 === void 0 ? void 0 : _fund$value66.sustainabilityRiskIntegrationDescription
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('sustainabilityRiskIntegrationDescription', val);
              }
            }
          }, {
            id: 'likelyImpactsFromSustainabilityRisk',
            component: 'ix-form-input-text-master',
            props: {
              id: 'likelyImpactsFromSustainabilityRisk',
              title: fundSetupI18nText.questions.likelyImpactsFromSustainabilityRisk.title[currentLocale.value],
              tooltip: fundSetupI18nText.questions.likelyImpactsFromSustainabilityRisk.tooltip[currentLocale.value],
              useTextField: false,
              useTextArea: true,
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value67 = fund.value) === null || _fund$value67 === void 0 ? void 0 : _fund$value67.likelyImpactsFromSustainabilityRisk
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('likelyImpactsFromSustainabilityRisk', val);
              }
            }
          }] : [{
            id: 'reasonNotConsideringSustainabilityRisk',
            component: 'ix-form-input-text-master',
            props: {
              id: 'reasonNotConsideringSustainabilityRisk',
              title: fundSetupI18nText.questions.reasonNotConsideringSustainabilityRisk.title[currentLocale.value],
              isMandatory: true,
              value: fund === null || fund === void 0 ? void 0 : (_fund$value68 = fund.value) === null || _fund$value68 === void 0 ? void 0 : _fund$value68.reasonNotConsideringSustainabilityRisk
            },
            events: {
              input: function input(val) {
                triggerUpdateFund('reasonNotConsideringSustainabilityRisk', val);
              }
            }
          }]))
        }])
      }].concat(_toConsumableArray((_fund$value69 = fund.value) !== null && _fund$value69 !== void 0 && _fund$value69.isReportingPais ? [paiSection.value] : []), _toConsumableArray((_fund$value70 = fund.value) !== null && _fund$value70 !== void 0 && _fund$value70.isReportingKfw ? [kfwSection.value] : []));
    }); // end of sections = computed() => ....

    var items = computed(function () {
      return [].concat(_toConsumableArray(sections.value), _toConsumableArray(reportingRequirementsExist.value ? [{
        id: 'outro',
        name: fundSetupI18nText.sections.nextSteps.name[currentLocale.value],
        percentageCompleted: completionSubmit.value,
        content: [{
          id: 'sfdrOutroStepper',
          component: 'sfdr-outro-stepper',
          props: {
            fund: fund === null || fund === void 0 ? void 0 : fund.value,
            mandatoryQuestionsAnswered: completionMandatorySections.value === 1
          },
          events: {
            input: function input() {
              return navigate('back');
            },
            submit: function submit() {
              return checkFundSync();
            }
          }
        }]
      }] : []));
    });
    var fundStrategyOptions = computed(function () {
      return [{
        id: FundStrategy.Article_6,
        label: fundSetupI18nText.fundStrategyOptions.ARTICLE_6.label[currentLocale.value],
        tooltip: fundSetupI18nText.fundStrategyOptions.ARTICLE_6.tooltip[currentLocale.value]
      }, {
        id: FundStrategy.Article_8,
        label: fundSetupI18nText.fundStrategyOptions.ARTICLE_8.label[currentLocale.value],
        tooltip: fundSetupI18nText.fundStrategyOptions.ARTICLE_8.tooltip[currentLocale.value]
      }, {
        id: FundStrategy.Article_8Plus,
        label: fundSetupI18nText.fundStrategyOptions.ARTICLE_8_PLUS.label[currentLocale.value],
        tooltip: fundSetupI18nText.fundStrategyOptions.ARTICLE_8_PLUS.tooltip[currentLocale.value]
      }, {
        id: FundStrategy.Article_9,
        label: fundSetupI18nText.fundStrategyOptions.ARTICLE_9.label[currentLocale.value],
        tooltip: fundSetupI18nText.fundStrategyOptions.ARTICLE_9.tooltip[currentLocale.value]
      }];
    });
    var flatSectionNavigation = computed(function () {
      return flattenDeep(getSectionIdsRecursive(items.value));
    });
    var selected = computed(function () {
      var stepIdFromUrl = route.query.step;
      if (stepIdFromUrl) {
        var step = findSectionRecursive(items.value, stepIdFromUrl);
        if (step) {
          return [step];
        }
      }
      return [items.value[0]];
    });
    function getOpenArray(str) {
      var parts = str.split('_');
      if (parts.length >= 2) {
        var openTabs = [];
        for (var i = 0; i < parts.length; i++) {
          parts.pop();
          openTabs.push({
            id: parts.join('_')
          });
        }
        return openTabs;
      }
      return [{
        id: parts[0]
      }];
    }
    var openSection = computed(function () {
      var stepIdFromUrl = route.query.step;
      if (stepIdFromUrl) {
        return getOpenArray(stepIdFromUrl);
      }
      return [{
        id: items.value[0].id
      }];
    });
    var onSectionSelect = function onSectionSelect(item) {
      if (item.length !== 0) {
        router.push({
          query: {
            step: item[0].id
          }
        });
      }
    };
    var navigate = function navigate(direction) {
      if (direction === 'back') {
        router.back();
        return;
      }
      var currentSectionId = selected.value[0].id;

      // Find the current section in the sections array
      var currentSection = findSectionRecursive(items.value, currentSectionId);
      if (currentSection) {
        var _flatSectionNavigation = flattenSections(items.value);
        var currentSectionIndex = _flatSectionNavigation.findIndex(function (section) {
          return section.id === currentSectionId;
        });
        var nextSectionIndex = currentSectionIndex;
        nextSectionIndex++;
        if (nextSectionIndex >= 0 && nextSectionIndex < _flatSectionNavigation.length) {
          var _nextSection, _nextSection$content;
          var nextSection = _flatSectionNavigation[nextSectionIndex];
          if ((((_nextSection = nextSection) === null || _nextSection === void 0 ? void 0 : (_nextSection$content = _nextSection.content) === null || _nextSection$content === void 0 ? void 0 : _nextSection$content.length) || 0) === 0) {
            // If the next section has no content, skip it
            nextSectionIndex++;
            nextSection = _flatSectionNavigation[nextSectionIndex];
          }
          if (nextSection) {
            router.push({
              query: {
                step: nextSection.id
              }
            });
          } else {
            router.push('/portfolio-funds');
          }
        }
      } else {
        console.error('Could not find current section');
      }
    };
    var createNewSustainableObjective = function createNewSustainableObjective() {
      var _fund$value71;
      createSfdrStrategy({
        input: {
          fundId: fund === null || fund === void 0 ? void 0 : (_fund$value71 = fund.value) === null || _fund$value71 === void 0 ? void 0 : _fund$value71.id,
          kind: SfdrStrategyKind.SustainableObjective
        }
      });
    };
    return {
      currentLocale: currentLocale,
      user: user,
      showDebugging: showDebugging,
      fundSetupI18nText: fundSetupI18nText,
      isInSync: isInSync,
      ventureIds: ventureIds,
      error: error,
      loading: loading,
      triggerUpdateAccount: triggerUpdateAccount,
      triggerUpdateFund: triggerUpdateFund,
      updateFundLoading: updateFundLoading,
      triggerUpdateSfdrStrategy: triggerUpdateSfdrStrategy,
      sustainableObjectives: sustainableObjectives,
      fund: fund,
      account: account,
      createSfdrStrategy: createSfdrStrategy,
      fundStrategy: fundStrategy,
      isConsideringPais: isConsideringPais,
      isArticle8Plus: isArticle8Plus,
      isArticle8: isArticle8,
      isArticle9: isArticle9,
      isPaiOnly: isPaiOnly,
      arrayAverage: arrayAverage,
      isAnswered: isAnswered,
      items: items,
      fundStrategyOptions: fundStrategyOptions,
      flatSectionNavigation: flatSectionNavigation,
      selected: selected,
      openSection: openSection,
      onSectionSelect: onSectionSelect,
      navigate: navigate,
      createNewSustainableObjective: createNewSustainableObjective
    };
  }
});