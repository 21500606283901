var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-4"},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"relative",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"value":_vm.$route.query.materialStatus,"dense":"","color":"primary","mandatory":""},on:{"change":(v) =>
            _vm.$router.push({ query: { ..._vm.$route.query, materialStatus: v } })}},[_c('v-btn',{attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.$t('allStakeholders'))+" ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":"notMaterial"}},[_vm._v(" "+_vm._s(_vm.$t('notMaterial'))+" ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":"impact"}},[_vm._v(" "+_vm._s(_vm.$t('impact'))+" ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":"financial"}},[_vm._v(" "+_vm._s(_vm.$t('financial'))+" ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":"both"}},[_vm._v(_vm._s(_vm.$t('both')))])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('ix-button-action',{attrs:{"disabled":"","nuxt":"","icon":"mdi-creation"}},[_vm._v("\n        "+_vm._s(_vm.$t('suggestStakeholders'))+"\n      ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('ix-btn-add-stakeholder',{attrs:{"activity-id":_vm.$attrs['activity-id'],"ix-button":true},on:{"done":_setup.onStakeholderAdded}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"m-0 p-0"},[_c('ix-data-table-stakeholder',{ref:"dataTableStakeholder",attrs:{"headers":[
          'name',
          'type',
          'activityLinks',
          'iroLinks',
          'impactMaterial',
          'financialMaterial',
          'actions',
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }