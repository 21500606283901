import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export default defineComponent({
  name: 'ImpactOverview',
  props: {
    stageToShow: Number
  },
  computed: {
    assessment: function assessment() {
      return this.$store.getters['assessment/getAssessment'];
    },
    assessmentStatus: function assessmentStatus() {
      if (this.assessment !== undefined && this.assessment.assessment !== undefined && this.assessment.assessment.status !== undefined) {
        return this.assessment.assessment.status;
      }
      return 'Outcome-selection input';
    },
    stages: function stages() {
      if (this.assessment && this.assessment.outcomesByStages) return this.assessment.outcomesByStages.map(function (item) {
        return item.stage;
      });
      return [];
    },
    outcomes: function outcomes() {
      var _this = this;
      if (this.stageToShow === null || this.stageToShow === -1 || this.stageToShow === undefined || this.assessment === undefined) {
        return [];
      }
      var outcomeIds = this.assessment.outcomesByStages[this.stageToShow].outcomes || [];
      return outcomeIds.map(function (id) {
        return _this.assessment.outcomes[id];
      });
    },
    keyinfo: function keyinfo() {
      if (this.assessment !== undefined && this.assessment.keyinfo !== undefined) {
        var keyinfo = [];
        for (var _i = 0, _Object$entries = Object.entries(this.assessment.keyinfo); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];
          if (!(key.includes('assessmentId') || key.includes('venture') || key.includes('assessment'))) {
            keyinfo.push([key, value]);
          }
        }
        return keyinfo;
      } else {
        return [];
      }
    }
  },
  methods: {
    isAtOrAfterState: function (_isAtOrAfterState) {
      function isAtOrAfterState(_x) {
        return _isAtOrAfterState.apply(this, arguments);
      }
      isAtOrAfterState.toString = function () {
        return _isAtOrAfterState.toString();
      };
      return isAtOrAfterState;
    }(function (comparedState) {
      return isAtOrAfterState(this.assessmentStatus, comparedState);
    })
  }
});