import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.object.keys.js";
import _debounce from 'lodash/debounce';
export default defineComponent({
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // Most default browser behaviour allows scrolling to change numbers in form input fields.
    // This is off by default, set useScrollWheel to true to enable this
    useScrollWheel: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    timeout: {
      type: Number,
      default: 400
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      slots = _ref.slots;
    var parentSlots = computed(function () {
      return Object.keys(slots);
    });
    var internalValue = ref(props.value);
    var touched = ref(false);
    var updateValue = _debounce(function (value) {
      touched.value = false;
      emit('input', value);
      emit('update:value', value);
    }, props.timeout);
    watch(function () {
      return props.value;
    }, function (value) {
      if (!touched.value) internalValue.value = value;
    });
    var updateInternalValue = function updateInternalValue(value) {
      touched.value = true;
      updateValue(value);
    };
    var handleWheel = function handleWheel(event) {
      if (!props.useScrollWheel) {
        event.preventDefault();
      }
    };
    onBeforeUnmount(function () {
      updateValue.flush();
    });
    return {
      internalValue: internalValue,
      handleWheel: handleWheel,
      updateInternalValue: updateInternalValue,
      parentSlots: parentSlots
    };
  }
});