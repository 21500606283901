import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es6.function.name.js";
import { getSafe } from '~/functions/helpers';
import { SfdrStrategyKind, TypeAggregationGroup, useDeleteMetricAggregationMutation, useGetUserQuery, useListMetricAggregationsQuery, useUpdateMetricAggregationMutation } from '~/types/types';
export default defineComponent({
  props: {
    height: {
      default: 'calc(100vh - 210px)',
      type: String
    },
    headers: {
      required: false,
      type: Array,
      default: function _default() {
        return ['metric.name', 'metric.commaSeparatedTaxonomies', 'group', 'unitName', 'valueLegend'].concat(_toConsumableArray(Array.from({
          length: 2
        }, function (_, index) {
          return new Date().getFullYear() - 1 + index;
        })), ['showPeriodOption', 'action', 'comment']);
      }
    },
    commentHint: {
      require: false,
      type: String,
      default: ''
    },
    filters: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    search: {
      required: false,
      type: String,
      default: function _default() {
        return '';
      }
    },
    itemsPerPage: {
      required: false,
      type: Number,
      default: 10
    },
    sortBy: {
      required: false,
      type: Array,
      default: function _default() {
        return ['metric.name'];
      }
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var expandPeriods = ref(false);
    var options = ref({
      page: 1,
      itemsPerPage: props.itemsPerPage,
      sortBy: props.sortBy,
      sortDesc: [false]
    });
    var expanded = ref([]);
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var showDebugging = computed(function () {
      try {
        var _userResult$value, _userResult$value$get, _JSON$parse;
        return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : (_userResult$value$get = _userResult$value.getUser) === null || _userResult$value$get === void 0 ? void 0 : _userResult$value$get.isImpersonated) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging'));
      } catch (_unused) {}
    });
    var headers = computed(function () {
      var allHeaderByKey = {
        id: {
          width: '20px',
          text: 'ID',
          value: 'id',
          cellClass: 'font-weight-bold subtitle-2'
        },
        'metric.id': {
          width: '10px',
          text: 'KPI',
          value: 'metric.id',
          cellClass: 'font-weight-bold subtitle-2'
        },
        'metric.name': {
          text: $i18n.t('companyPerspective.kpis.headers.kpi'),
          value: 'metric.name',
          cellClass: '',
          width: '200px',
          sortable: true
        },
        'metric.commaSeparatedTaxonomies': {
          text: $i18n.t('companyPerspective.kpis.headers.relatedFrameworks'),
          value: 'metric.commaSeparatedTaxonomies',
          cellClass: '',
          width: '200px'
        },
        group: {
          text: $i18n.t('companyPerspective.kpis.headers.group'),
          value: 'group',
          cellClass: '',
          width: '200px'
        },
        unitName: {
          text: $i18n.t('companyPerspective.kpis.headers.unit'),
          value: 'unitName',
          cellClass: '',
          width: '100px'
          // sortable: true,
        },
        valueLegend: {
          text: $i18n.t('companyPerspective.kpis.headers.values'),
          value: 'valueLegend',
          cellClass: '',
          width: '80px'
        },
        2020: {
          text: 2020,
          value: 2020,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2021: {
          text: 2021,
          value: 2021,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2022: {
          text: 2022,
          value: 2022,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2023: {
          text: 2023,
          value: 2023,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2024: {
          text: 2024,
          value: 2024,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2025: {
          text: 2025,
          value: 2025,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2026: {
          text: 2026,
          value: 2026,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2027: {
          text: 2027,
          value: 2027,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2028: {
          text: 2028,
          value: 2028,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2029: {
          text: 2029,
          value: 2029,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        2030: {
          text: 2030,
          value: 2030,
          cellClass: 'primary--text text-center',
          align: 'center',
          width: '100px'
        },
        showPeriodOption: {
          text: '',
          value: 'showPeriodOption',
          cellClass: 'align-center subtitle-1 font-weight-bold text-center',
          sortable: false,
          width: '60px'
        },
        action: {
          text: $i18n.t('companyPerspective.kpis.headers.actions'),
          value: 'action',
          cellClass: 'subtitle-1 font-weight-bold',
          width: '100px'
        },
        comment: {
          text: $i18n.t('companyPerspective.kpis.headers.comments'),
          value: 'comment',
          width: '300px',
          cellClass: 'subtitle-1 font-weight-bold'
        }
      };

      // .map((v: any) => ({ ...v, sortable: v?.sortable || false }))

      // Overriding the attributes by key.
      // Can use ['actions', 'id'] OR
      // Can use [{ value: 'actions' }, { value: 'id' }]
      var wantedHeaders = _toConsumableArray(props.headers);
      if (showDebugging.value) {
        wantedHeaders.unshift('id');
        wantedHeaders.unshift('metric.id');
      }
      if (expandPeriods.value) {
        // when expandPeriods is true, we insert 2020 - 2030 to the right place

        // Define the range of years you want to include
        var years = Array.from({
          length: 11
        }, function (v, k) {
          return 2020 + k;
        });

        // Helper function to extract the year value
        var getYearValue = function getYearValue(item) {
          return (item === null || item === void 0 ? void 0 : item.value) || item;
        };

        // Find the first position where a year would be inserted
        var index = wantedHeaders.findIndex(function (item) {
          return getYearValue(item) >= 2020 || getYearValue(item) <= 2030;
        });
        // If no such index is found, append to the end
        var insertIndex = index === -1 ? wantedHeaders.length : index;
        // Filter out existing years in the range 2020 to 2030 from the array
        for (var i = wantedHeaders.length - 1; i >= 0; i--) {
          var year = getYearValue(wantedHeaders[i]);
          if (year >= 2020 && year <= 2030) {
            wantedHeaders.splice(i, 1);
          }
        }

        // Insert the years as objects with key 'value' at the determined position
        wantedHeaders.splice.apply(wantedHeaders, [insertIndex, 0].concat(_toConsumableArray(years.map(function (year) {
          return {
            value: year
          };
        }))));
      }
      var x = wantedHeaders === null || wantedHeaders === void 0 ? void 0 : wantedHeaders.map(function (header) {
        if (header !== null && header !== void 0 && header.value) {
          return _objectSpread(_objectSpread({}, allHeaderByKey[header.value]), header);
        } else {
          if (!allHeaderByKey[header]) {
            console.error(header, 'does NOT exist!!!!!', header);
          }
          return allHeaderByKey[header];
        }
      });
      return x;
    });
    var variables = computed(function () {
      return _objectSpread(_objectSpread({}, props.filters), {}, {
        keyword: props.search || '',
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        sort: options.value.sortBy.map(function (item, index) {
          return {
            field: item,
            sortDesc: options.value.sortDesc[index]
          };
        })
      });
    });
    var _useListMetricAggrega = useListMetricAggregationsQuery(variables),
      loading = _useListMetricAggrega.loading,
      result = _useListMetricAggrega.result,
      refetch = _useListMetricAggrega.refetch;
    var itemCount = computed(function () {
      var _result$value$countMe, _result$value;
      return (_result$value$countMe = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.countMetricAggregations) !== null && _result$value$countMe !== void 0 ? _result$value$countMe : 0;
    });
    var items = computed(function () {
      var _result$value$listMet, _result$value2;
      var fromDb = (_result$value$listMet = (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.listMetricAggregations) !== null && _result$value$listMet !== void 0 ? _result$value$listMet : [];
      var data = fromDb.map(function (e) {
        var _item$metricAggregati;
        var item = _objectSpread({}, e);
        item.metricValuesByPeriod = {};
        item.metricValuesByPeriod = Object.fromEntries(
        // This shouldn't be in front end.
        ((_item$metricAggregati = item.metricAggregationValues) === null || _item$metricAggregati === void 0 ? void 0 : _item$metricAggregati.map(function (e) {
          return [e.period, e];
        })) || []);
        item.editable = false; // TODO this is hard coded
        // item.taxonomiesString = extractTaxonomiesAsString(item.metric?.sections)
        return item;
      });
      return data;
    });
    var _useDeleteMetricAggre = useDeleteMetricAggregationMutation({}),
      deleteMetricAggregation = _useDeleteMetricAggre.mutate;
    var _useUpdateMetricAggre = useUpdateMetricAggregationMutation(),
      updateMetricAggregation = _useUpdateMetricAggre.mutate,
      onUpdateMetricAggregationDone = _useUpdateMetricAggre.onDone;
    onUpdateMetricAggregationDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var groupName = function groupName(item) {
      if (item.fund) return item.fund.name;
      if (item.sfdrStrategy) {
        var _item$sfdrStrategy$fu, _item$sfdrStrategy$fu2, _item$sfdrStrategy$co;
        if (item.sfdrStrategy.kind === SfdrStrategyKind.EsCharacteristic) return "".concat((_item$sfdrStrategy$fu = item.sfdrStrategy.fund) === null || _item$sfdrStrategy$fu === void 0 ? void 0 : _item$sfdrStrategy$fu.name, " - Environmental/Social characteristics");else return "".concat((_item$sfdrStrategy$fu2 = item.sfdrStrategy.fund) === null || _item$sfdrStrategy$fu2 === void 0 ? void 0 : _item$sfdrStrategy$fu2.name, " - Sustainable objective '").concat((_item$sfdrStrategy$co = item.sfdrStrategy.concept) === null || _item$sfdrStrategy$co === void 0 ? void 0 : _item$sfdrStrategy$co.label, "'");
      }
      return '';
    };
    return {
      getSafe: getSafe,
      expanded: expanded,
      itemCount: itemCount,
      options: options,
      refetch: refetch,
      expandPeriods: expandPeriods,
      headers: headers,
      items: items,
      loading: loading,
      SfdrStrategyKind: SfdrStrategyKind,
      updateMetricAggregation: updateMetricAggregation,
      deleteMetricAggregation: deleteMetricAggregation,
      groupName: groupName,
      TypeAggregationGroup: TypeAggregationGroup
    };
  }
});