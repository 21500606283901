import { render, staticRenderFns } from "./VentureInfo.vue?vue&type=template&id=4f79754b&scoped=true"
import script from "./VentureInfo.vue?vue&type=script&lang=ts"
export * from "./VentureInfo.vue?vue&type=script&lang=ts"
import style0 from "./VentureInfo.vue?vue&type=style&index=0&id=4f79754b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f79754b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonOutlined: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Outlined.vue').default,IxSubSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionTitle.vue').default,IxSubSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionText.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
