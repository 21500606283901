import { render, staticRenderFns } from "./CompanyConceptEntry.vue?vue&type=template&id=3b91e0ac&scoped=true"
import script from "./CompanyConceptEntry.vue?vue&type=script&lang=js"
export * from "./CompanyConceptEntry.vue?vue&type=script&lang=js"
import style0 from "./CompanyConceptEntry.vue?vue&type=style&index=0&id=3b91e0ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b91e0ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactCategoryRelevancy: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactCategoryRelevancy.vue').default,ImpactTopicEntry: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-topic/Entry.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VChip,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VTabItem,VTabsItems})
