import { useQueryLoading } from '@vue/apollo-composable';
import { useBasicInfoForCompanyQuery, useGetAccountQuery } from '~/types/types';
import Info from '~/components/ix/button/Info.vue';
export default defineComponent({
  components: {
    Info: Info
  },
  props: {
    ventureId: {
      type: String,
      default: undefined
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var _useBasicInfoForCompa = useBasicInfoForCompanyQuery({
        ventureId: route.params.venture
      }),
      result = _useBasicInfoForCompa.result;
    var companyInfo = computed(function () {
      var _result$value$basicIn, _result$value;
      return (_result$value$basicIn = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.basicInfoForCompany) !== null && _result$value$basicIn !== void 0 ? _result$value$basicIn : null;
    });
    var _useGetAccountQuery = useGetAccountQuery({
        id: props.ventureId
      }),
      accountResult = _useGetAccountQuery.result;
    var account = computed(function () {
      var _accountResult$value$, _accountResult$value;
      return (_accountResult$value$ = (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) !== null && _accountResult$value$ !== void 0 ? _accountResult$value$ : {
        csrdRequirement: {}
      };
    });
    var esgActionsActive = computed(function () {
      return account.value.esgActionsActive === true;
    });
    var loading = useQueryLoading();
    return {
      companyInfo: companyInfo,
      loading: loading,
      account: account,
      esgActionsActive: esgActionsActive
    };
  },
  computed: {
    sections: function sections() {
      var additionalContextInformationKeys = [{
        label: 'businessModel',
        title: this.$t('companyInfo.businessModel')
      }, {
        label: 'incomeStream',
        title: this.$t('companyInfo.incomeStream')
      }, {
        label: 'products',
        title: this.$t('companyInfo.products')
      }, {
        label: 'industries',
        title: this.$t('companyInfo.industries')
      }, {
        label: 'customerGroup',
        title: this.$t('companyInfo.customerGroup')
      }];
      if (this.esgActionsActive) {
        additionalContextInformationKeys.push({
          label: 'csrd.title',
          title: this.$t('companyInfo.csrd.title')
        });
      }
      return [{
        label: this.$t('companyInfo.basicInformation'),
        keys: [{
          label: 'pitchLine',
          title: this.$t('companyInfo.pitchline')
        }, {
          label: 'legalEntity',
          title: this.$t('companyInfo.legalEntity')
        }, {
          label: 'founders',
          title: this.$t('companyInfo.founders')
        }, {
          label: 'headquarter',
          title: this.$t('companyInfo.headquarter')
        }, {
          label: 'country',
          title: this.$t('companyInfo.country')
        }, {
          label: 'foundingYear',
          title: this.$t('companyInfo.foundingYear')
        },
        // {
        //   label: 'employeeHeadcount',
        //   title: this.$t('companyInfo.employeeHeadcount'),
        // },
        // { label: 'turnover', title: this.$t('companyInfo.turnover') },
        {
          label: 'fundingStage',
          title: this.$t('companyInfo.fundingStage')
        }]
      }, {
        label: this.$t('companyInfo.additionalContextInformation'),
        keys: additionalContextInformationKeys
      }];
    },
    ventureCompanyEdit: function ventureCompanyEdit() {
      var _this$companyInfo;
      return {
        name: 'venture-company-edit-questionnaire',
        params: {
          questionnaire: (_this$companyInfo = this.companyInfo) === null || _this$companyInfo === void 0 ? void 0 : _this$companyInfo.questionnaireId
        }
      };
    }
  }
});