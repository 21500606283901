export default defineComponent({
  props: {
    result: {
      type: Object,
      required: true
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    isRisk: function isRisk() {
      var _this$result$details;
      return (_this$result$details = this.result.details) === null || _this$result$details === void 0 ? void 0 : _this$result$details.hasRisk;
    },
    imageUrl: function imageUrl() {
      if (this.isRisk) return require('~/assets/icons/' + 'danger-flag.svg');
      return require('~/assets/icons/' + 'success-check.svg');
    }
  }
});