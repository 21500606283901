var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","max-width":_vm.reportingPeriodType === _vm.MetricReportingPeriodType.Annual ? 650 : 900},on:{"input":(v) => _vm.$emit('input', v)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-card-text',{ref:(el) => el?.addEventListener?.('scroll', _vm.onScroll),staticClass:"pt-4 max-h-[60vh] overflow-auto"},[_c('v-card-title',{staticClass:"absolute top-0 left-0 right-0 w-full z-10 bg-white/90",class:_vm.scrolled ? 'scrolled' : ''},[_vm._v("\n          "+_vm._s(_vm.metric.name)+" — "+_vm._s(_vm.metricValue.period)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"mt-[80px] w-full h-0"}),_vm._v(" "),(_vm.metric.conditions && _vm.metric.conditions != 'null')?_c('ix-alert-metric-conditional-children',{attrs:{"metric":_vm.metric,"metric-value":_vm.metricValue},on:{"open:by-metric-id":(id) => _vm.$emit('open:by-metric-id', id)}}):_vm._e(),_vm._v(" "),(
            _vm.metric.directComputationalChildren &&
            _vm.metric.directComputationalChildren.length
          )?_c('ix-alert-metric-direct-computational-children',{attrs:{"metric":_vm.metric},on:{"open:by-metric-id":(id) => _vm.$emit('open:by-metric-id', id)}}):_vm._e(),_vm._v(" "),(
            [
              _vm.MetricReportingPeriodType.Quarterly,
              _vm.MetricReportingPeriodType.Monthly,
            ].includes(_vm.reportingPeriodType)
          )?_c('ix-alert-metric-monthly-quarterly',{attrs:{"metric":_vm.metric}}):_vm._e(),_vm._v(" "),(_vm.isOnlyLastQuarterForValueOfInvestmentFilled)?_c('ix-alert-metric-only-last-quarter-filled'):_vm._e(),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":`${_vm.$t('labelActual')} (${_vm.mv.period})`,"is-mandatory":true}}),_vm._v(" "),_c('v-row',{staticClass:"pt-2 pb-4",attrs:{"align":"center"}},[_c('v-col',[(_vm.reportingPeriodType === _vm.MetricReportingPeriodType.Annual)?_c('div',[_c('ix-form-input-metric-single-value',{attrs:{"metric":_vm.metric,"type":"actual","children":_vm.children,"value":_vm.mv,"hint":_vm.hint,"disabled":_vm.skipped},on:{"input":(v) => {
                    _vm.mv = { ..._vm.mv, ...v } // FIXME
                    _vm.input(v)
                  }}})],1):_c('ix-form-input-metric-monthly-quarterly-values',{attrs:{"metric":_vm.metric,"reporting-period-type":_vm.reportingPeriodType,"skipped":_vm.skipped,"type":"actual","metric-values":_vm.metricValues,"inputs":_vm.inputs,"hint":_vm.hint},on:{"input":(v) => (_vm.inputs = v)}})],1),_vm._v(" "),(
              [_vm.MetricValueKind.Float, _vm.MetricValueKind.Int].includes(
                _vm.metric.valueType,
              )
            )?_c('v-col',{attrs:{"cols":"auto"}},[_vm._v("\n            "+_vm._s(_vm.unitName)+"\n          ")]):_vm._e()],1),_vm._v(" "),(
            [
              _vm.MetricValueKind.Float,
              _vm.MetricValueKind.Binary,
              _vm.MetricValueKind.Int,
            ].includes(_vm.metric.valueType)
          )?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTarget),expression:"showTarget"}]},[_c('ix-form-input-question',{attrs:{"title":`${_vm.$t('labelTarget')} (${_vm.mv.period})`,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{staticClass:"pt-2 pb-4",attrs:{"align":"center"}},[_c('v-col',[(
                    _vm.reportingPeriodType === _vm.MetricReportingPeriodType.Annual
                  )?_c('div',[_c('ix-form-input-metric-single-value',{attrs:{"metric":_vm.metric,"type":"target","children":_vm.children,"value":_vm.mv,"hint":_vm.hint,"disabled":_vm.isFrozen},on:{"input":(v) => {
                        _vm.mv = { ..._vm.mv, ...v } // FIXME
                        _vm.input(v)
                      }}})],1):_c('ix-form-input-metric-monthly-quarterly-values',{attrs:{"metric":_vm.metric,"reporting-period-type":_vm.reportingPeriodType,"type":"target","metric-values":_vm.metricValues,"inputs":_vm.inputs,"hint":_vm.hint,"is-frozen":_vm.isFrozen,"clear-targets":_vm.clearTargets},on:{"input":(v) => (_vm.inputs = v)}})],1),_vm._v(" "),(
                  [_vm.MetricValueKind.Float, _vm.MetricValueKind.Int].includes(
                    _vm.metric.valueType,
                  )
                )?_c('v-col',{attrs:{"cols":"auto"}},[_vm._v("\n                "+_vm._s(_vm.unitName)+"\n              ")]):_vm._e()],1)],1),_vm._v(" "),_c('v-row',{staticClass:"py-2",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"pa-0",attrs:{"disabled":_vm.isFrozen,"input-value":_vm.showTarget,"hide-details":""},on:{"change":_vm.handleShowTarget},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                  "+_vm._s(_vm.$t('showTargetMessage') +
                    (_vm.isFrozen ? ` (${_vm.$t('frozenHint')})` : ''))+"\n                ")]},proxy:true}],null,false,656451880)})],1),_vm._v(" "),_c('v-col',[_c('v-tooltip',{attrs:{"right":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-2"},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,3864323921)},[_vm._v("\n                "+_vm._s(_vm.$t('targetInfo'))+"\n              ")])],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-checkbox',{staticClass:"px-0 pt-2 pb-4",attrs:{"input-value":_vm.skipped,"label":_vm.$t('skipMessage'),"hide-details":""},on:{"change":_vm.handleSkipped}}),_vm._v(" "),_c('div',[(_vm.skipped)?_c('v-alert',{attrs:{"type":"info","color":"primary","outlined":""}},[_vm._v("\n            "+_vm._s(_vm.$t('skipMessageCommentMandatory'))+"\n          ")]):_vm._e(),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":_vm.$t('comment'),"is-mandatory":_vm.skipped}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.mv.comment || '',"rows":"3","outlined":""},on:{"input":(v) => {
                _vm.mv.comment = v
                _vm.input({
                  metricAssignmentId: _vm.mv.metricAssignmentId,
                  period: _vm.mv.period,
                  comment: v,
                })
              }}})],1),_vm._v(" "),_c('metrics-metric-details',{attrs:{"metric":_vm.metric,"unit-name":_vm.unitName}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('ix-button-secondary',{on:{"click":_vm.resetAndCloseDialog}},[_vm._v("\n          "+_vm._s(_vm.$t('btnCancel'))+"\n        ")]),_vm._v(" "),_c('ix-button-primary',{attrs:{"loading":_vm.loading,"disabled":_vm.disabled},on:{"click":_vm.update}},[_vm._v("\n          "+_vm._s(_vm.$t('btnSave'))+"\n        ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }