var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.annotationEdit)?_c('span',[_c('v-card-actions',{staticClass:"space-x-2"},[_c('v-select',{staticClass:"flex-grow-0",attrs:{"disabled":_vm.isDisabled,"items":_vm.contentOptions,"label":"Content control","outlined":"","hide-details":"","item-text":"text","item-value":"value"},model:{value:(_vm.annotationEdit.tasks.content_control),callback:function ($$v) {_vm.$set(_vm.annotationEdit.tasks, "content_control", $$v)},expression:"annotationEdit.tasks.content_control"}}),_vm._v(" "),_c('v-combobox',{staticClass:"flex-grow-0",attrs:{"items":_vm.insightQualityOptions,"disabled":_vm.isDisabled,"multiple":"","chips":"","small-chips":"","hide-no-data":"","hide-selected":"","deletable-chips":"","hide-details":"","label":"Insight classes","outlined":""},model:{value:(_vm.annotationEdit.tasks.insight_class),callback:function ($$v) {_vm.$set(_vm.annotationEdit.tasks, "insight_class", $$v)},expression:"annotationEdit.tasks.insight_class"}})],1),_vm._v(" "),(
      _vm.annotationEdit.tasks.concepts &&
      (_vm.annotationEdit.created_by !== _vm.email ||
        _vm.annotationEdit.type === 'MACHINE' ||
        (_vm.annotationEdit.tasks.content_control &&
          _vm.annotationEdit.tasks.content_control !== 'HIDDEN'))
    )?_c('ix-section-text',[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Company concepts")]),_vm._v(" "),_c('v-chip-group',{staticClass:"mb-2",attrs:{"column":""}},[_vm._l((_vm.annotationEdit.tasks.concepts
          .company_concepts),function(companyConcept){return [_c('v-tooltip',{key:companyConcept.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',{attrs:{"target":"_blank","rel":"noopener","href":`https://airtable.com/appGhfa7A73wMqhRB/tblHE9zSFykH1szd3/viwdtqhLqVlNYk4W0/${companyConcept.id}?blocks=hide`}},[_c('v-chip',_vm._g(_vm._b({attrs:{"disabled":_vm.isDisabled,"close":""},on:{"click:close":function($event){return _vm.updateCompanyConcepts(companyConcept)}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(companyConcept.label))])],1)]}}],null,true)},[_vm._v("\n          "+_vm._s(companyConcept.id)+"\n        ")])]})],2),_vm._v(" "),_c('v-combobox',{attrs:{"disabled":_vm.isDisabled,"search-input":_vm.companyConceptSearch,"items":_vm.companyConcepts,"loading":_vm.isLoadingCompany,"hide-no-data":"","hide-selected":"","hide-details":"","label":"Add company concepts","outlined":"","return-object":"","item-text":"label","item-value":"label"},on:{"update:searchInput":function($event){_vm.companyConceptSearch=$event},"update:search-input":function($event){_vm.companyConceptSearch=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.addConceptFromModel(
          'company',
          _vm.companyConceptSearchModel,
          _vm.annotationEdit.tasks.concepts.company_concepts,
        )
        _vm.companyConceptSearchModel = null}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","small":"","color":_vm.statusToColor(item.status)}},[_vm._v(_vm._s(item.status))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.label))]),_vm._v(" "),(item.code)?_c('span',{staticClass:"text-caption ml-4"},[_vm._v(_vm._s(item.code))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-caption ml-4"},[_vm._v(_vm._s(item.type.join(', ')))])]}}],null,false,2282852681),model:{value:(_vm.companyConceptSearchModel),callback:function ($$v) {_vm.companyConceptSearchModel=$$v},expression:"companyConceptSearchModel"}}),_vm._v(" "),_c('div',{staticClass:"text-subtitle-2 mt-4"},[_vm._v("Impact concepts")]),_vm._v(" "),_c('v-chip-group',{staticClass:"mb-2",attrs:{"column":""}},[_vm._l((_vm.annotationEdit.tasks.concepts
          .impact_concepts),function(impactConcept){return [_c('v-tooltip',{key:impactConcept.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',{attrs:{"target":"_blank","rel":"noopener","href":`https://airtable.com/appGhfa7A73wMqhRB/tblXXryKw4pfNnBlD/viwJaRTLfJiWCkjgb/${impactConcept.id}?blocks=hide`}},[_c('v-chip',_vm._g(_vm._b({attrs:{"disabled":_vm.isDisabled,"close":""},on:{"click:close":function($event){return _vm.updateImpactConcepts(impactConcept)}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(impactConcept.label))])],1)]}}],null,true)},[_vm._v("\n          "+_vm._s(impactConcept.id)+"\n        ")])]})],2),_vm._v(" "),_c('v-combobox',{attrs:{"disabled":_vm.isDisabled,"search-input":_vm.impactConceptSearch,"items":_vm.impactConcepts,"loading":_vm.isLoadingImpact,"hide-no-data":"","hide-selected":"","hide-details":"","outlined":"","return-object":"","item-text":"label","item-value":"label","label":"Add impact concepts"},on:{"update:searchInput":function($event){_vm.impactConceptSearch=$event},"update:search-input":function($event){_vm.impactConceptSearch=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.addConceptFromModel(
          'impact',
          _vm.impactConceptSearchModel,
          _vm.annotationEdit.tasks.concepts.impact_concepts,
        )
        _vm.impactConceptSearchModel = null
        _vm.$emit('input', _vm.annotationEdit)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","small":"","color":_vm.statusToColor(item.status)}},[_vm._v(_vm._s(item.status))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.label))])]}}],null,false,3762161236),model:{value:(_vm.impactConceptSearchModel),callback:function ($$v) {_vm.impactConceptSearchModel=$$v},expression:"impactConceptSearchModel"}})],1):_vm._e(),_vm._v(" "),(
      _vm.annotationEdit.tasks.relations &&
      (_vm.annotationEdit.created_by !== _vm.email ||
        _vm.annotationEdit.type === 'MACHINE' ||
        (_vm.annotationEdit.tasks.content_control &&
          _vm.annotationEdit.tasks.content_control !== 'HIDDEN'))
    )?_c('ix-section-text',[_c('v-row',[_c('v-col',{staticClass:"text-subtitle-2"},[_vm._v(" Company concept ")]),_vm._v(" "),_c('v-col',{staticClass:"text-subtitle-2"},[_vm._v(" Positive impact ")]),_vm._v(" "),_c('v-col',{staticClass:"text-subtitle-2"},[_vm._v(" Negative impact ")])],1),_vm._v(" "),_vm._l((_vm.annotationEdit.tasks.relations),function(relation,conceptLabel){return _c('v-row',{key:'rel' + conceptLabel},[_c('v-col',{staticClass:"align-center d-flex"},[_vm._v("\n        "+_vm._s(conceptLabel)+"\n      ")]),_vm._v(" "),_c('v-col',[_c('ix-autocomplete',{staticClass:"mt-0 pt-0",attrs:{"disabled":_vm.isDisabled,"label":"Positive impact concept","items":_vm.annotationEdit.tasks.concepts.impact_concepts,"return-object":"","color":"green","multiple":"","counter":"","outlined":"","item-value":"label","item-text":"label","clearable":"","chips":"","deletable-chips":"","small-chips":""},model:{value:(relation.positive),callback:function ($$v) {_vm.$set(relation, "positive", $$v)},expression:"relation.positive"}})],1),_vm._v(" "),_c('v-col',[_c('ix-autocomplete',{staticClass:"mt-0 pt-0",attrs:{"disabled":_vm.isDisabled,"label":"Negative impact concept","items":_vm.annotationEdit.tasks.concepts.impact_concepts,"return-object":"","multiple":"","counter":"","outlined":"","item-value":"label","item-text":"label","color":"red","clearable":"","chips":"","deletable-chips":"","small-chips":""},model:{value:(relation.negative),callback:function ($$v) {_vm.$set(relation, "negative", $$v)},expression:"relation.negative"}})],1)],1)})],2):_vm._e(),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('ix-button-action',{ref:"submitButton",attrs:{"error":_vm.error},on:{"click":_vm.submit}},[_vm._v("\n      Save\n    ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }