import { render, staticRenderFns } from "./periodic.vue?vue&type=template&id=59a2b7fc&scoped=true"
import script from "./periodic.vue?vue&type=script&setup=true&lang=ts"
export * from "./periodic.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./periodic.vue?vue&type=style&index=0&id=59a2b7fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a2b7fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default,IxTextField: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/TextField.vue').default,IxSurveyQuestionGroup: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/QuestionGroup.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default,IxSectionOrQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionOrQuestion.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VProgressLinear,VRow})
