import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es7.string.pad-start.js";
import { downloadWord } from '~/functions/export';
import { ReportType, useExportPrecontractualReportLazyQuery, useExportPaiReportLazyQuery, useExportFundReportLazyQuery, useGetFundQuery, FundStrategy } from '~/types/types';
export default defineComponent({
  props: {
    fundId: {
      type: Number,
      required: true
    },
    fundName: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      // add ReportType Enum
      required: true
    },
    referencePeriod: {
      type: Number,
      required: false,
      default: 2022
    }
  },
  setup: function setup(props, context) {
    var _app$i18n;
    var _useNuxtApp = useNuxtApp(),
      app = _useNuxtApp.app;
    var locale = app === null || app === void 0 ? void 0 : (_app$i18n = app.i18n) === null || _app$i18n === void 0 ? void 0 : _app$i18n.defaultLocale;
    if (locale === 'en') {
      locale = 'en-US';
    } else if (locale === 'de') {
      locale = 'de-DE';
    }
    var selectedLanguage = ref(locale);
    var _useGetFundQuery = useGetFundQuery({
        id: props.fundId
      }),
      result = _useGetFundQuery.result;
    var fund = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getFund;
    });
    var languageItems = computed(function () {
      var _fund$value;
      if (props.reportType === ReportType.SfdrPai) {
        return [{
          text: 'English',
          value: 'en-US'
        }, {
          text: 'German',
          value: 'de-DE'
        }];
      }
      if (props.reportType === ReportType.SfdrPeriodic && ((_fund$value = fund.value) === null || _fund$value === void 0 ? void 0 : _fund$value.fundStrategy) === FundStrategy.Article_8) {
        return [{
          text: 'English',
          value: 'en-US'
        }, {
          text: 'German',
          value: 'de-DE'
        }];
      }
      return [{
        text: 'English',
        value: 'en-US'
      }];
    });
    var input = computed(function () {
      if (props.reportType === ReportType.SfdrPeriodic || props.reportType === ReportType.SfdrPai) {
        return {
          fundId: props.fundId,
          locale: selectedLanguage.value,
          referencePeriod: props.referencePeriod
        };
      } else {
        return {
          fundId: props.fundId,
          locale: selectedLanguage.value
        };
      }
    });
    var reportTypeString = computed(function () {
      if (props.reportType === ReportType.SfdrPeriodic) {
        return 'Periodic';
      } else if (props.reportType === ReportType.SfdrPai) {
        return 'PAI';
      } else if (props.reportType === ReportType.SfdrPrecontractual) {
        return 'Precontractual';
      }
    });
    var reportPrefix = computed(function () {
      if (props.reportType === ReportType.SfdrPeriodic) {
        return 'Periodic';
      } else if (props.reportType === ReportType.SfdrPai) {
        return 'PAI Consideration';
      } else if (props.reportType === ReportType.SfdrPrecontractual) {
        return 'Precontractual';
      }
    });
    var resolverName = computed(function () {
      if (props.reportType === ReportType.SfdrPeriodic) {
        return 'exportFundReport';
      } else if (props.reportType === ReportType.SfdrPai) {
        return 'exportPaiReport';
      }
      return 'exportPrecontractualReport';
    });

    // TODO: Refactor this to use a single query function. Move logic to the resolver.
    var queryFunctions = computed(function () {
      switch (props.reportType) {
        case ReportType.SfdrPeriodic:
          return useExportFundReportLazyQuery({
            input: input.value
          });
        case ReportType.SfdrPai:
          return useExportPaiReportLazyQuery({
            input: input.value
          });
        default:
          return useExportPrecontractualReportLazyQuery({
            input: input.value
          });
      }
    });
    var _queryFunctions$value = queryFunctions.value,
      load = _queryFunctions$value.load,
      onResult = _queryFunctions$value.onResult,
      refetch = _queryFunctions$value.refetch,
      loading = _queryFunctions$value.loading;
    var toggleDialogRef = ref(function () {});
    function getFormattedDate() {
      var currentDate = new Date();
      return "".concat(currentDate.getFullYear(), "_").concat(String(currentDate.getMonth() + 1).padStart(2, '0'), "_").concat(String(currentDate.getDate()).padStart(2, '0'));
    }
    function handleDownload(res) {
      var _res$data;
      var file = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data[resolverName.value].file;
      if (file) {
        downloadWord(file, "".concat(props.fundName, " ").concat(reportPrefix.value, " Report ").concat(getFormattedDate(), ".docx"));
        context.emit('finish');
        toggleDialogRef.value(); // Calls the toggleDialog function that is passed as a prop into triggerExport.
        toggleDialogRef.value = function () {}; // Resets the toggleDialogRef to an empty function.
      }
    }
    onMounted(function () {
      onResult(function (res) {
        if (!loading.value) {
          handleDownload(res);
        }
      });
    });
    function triggerExport(toggleDialog) {
      load(null, {
        input: input.value
      }) || refetch();
      toggleDialogRef.value = toggleDialog;
    }
    return {
      triggerExport: triggerExport,
      loading: loading,
      selectedLanguage: selectedLanguage,
      languageItems: languageItems,
      reportTypeString: reportTypeString
    };
  }
});