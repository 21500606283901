import { render, staticRenderFns } from "./SourceList.vue?vue&type=template&id=0c5ce478&scoped=true"
import script from "./SourceList.vue?vue&type=script&lang=ts"
export * from "./SourceList.vue?vue&type=script&lang=ts"
import style0 from "./SourceList.vue?vue&type=style&index=0&id=0c5ce478&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5ce478",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommaSeperatedValues: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/CommaSeperatedValues.vue').default,EditableList: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/EditableList.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog,VIcon,VImg,VListItemContent,VListItemIcon,VSheet,VSpacer})
