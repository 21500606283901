import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
import * as Diff from 'diff';
import { useUpsertMeasureMutation, useChatgptTranslateLazyQuery, ConceptStatus, RiskManagementScheme, useListConceptsQuery } from '~/types/types';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    dialogType: {
      type: String,
      required: false,
      default: 'Edit'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var originalItem = ref(structuredClone(props.item));
    var editedItem = ref(structuredClone(props.item));
    watch(function () {
      return props.item;
    }, function (value) {
      // sync prop changes.
      editedItem.value = structuredClone(value);
    });
    var _useUpsertMeasureMuta = useUpsertMeasureMutation(),
      mutate = _useUpsertMeasureMuta.mutate,
      onDone = _useUpsertMeasureMuta.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
      if (props.dialogType === 'Edit') {
        originalItem.value = structuredClone(editedItem.value);
        originalSubmeasures.value = editedSubmeasures.value;
      } else {
        emit('input', false);
      }
    });
    var isJsonString = function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };
    var save = function save() {
      var item = editedItem.value;
      var input = {
        id: item.id
      };
      if (props.dialogType !== 'Edit') {
        // eslint-disable-next-line no-unused-expressions
        ;
        input.kind = item.kind, input.status = item.status, input.isSubmeasure = item.isSubmeasure, input.scheme = item.scheme;
      }
      for (var _i = 0, _Object$keys = Object.keys(changesByKey.value); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        if (['conditions', 'externalData'].includes(key)) {
          input[key] = isJsonString(item[key]) ? JSON.parse(item[key]) : item[key];
        } else {
          input[key] = item[key];
        }
      }
      if (changesActions.value.length !== 0) {
        input.submeasureIds = editedSubmeasures.value.map(function (submeasure) {
          return submeasure.id;
        });
      }
      if (changesSubsection.value.length !== 0) {
        input.currentSubsectionId = originalSubsection.value.id;
        input.newSubsectionId = editedSubsection.value;
      }
      mutate({
        input: input
      });
    };
    var _useChatgptTranslateL = useChatgptTranslateLazyQuery({
        input: {}
      }),
      translatedResult = _useChatgptTranslateL.result,
      loading = _useChatgptTranslateL.loading,
      translate = _useChatgptTranslateL.load,
      onResult = _useChatgptTranslateL.onResult;
    onResult(function () {
      // when translation API returns, show the popover
      showTranslationPopover.value = true;
    });
    var showTranslationPopover = ref(false);
    var changesByKey = computed(function () {
      var obj = {};
      for (var _i2 = 0, _Object$keys2 = Object.keys(editedItem.value); _i2 < _Object$keys2.length; _i2++) {
        var _originalItem$value, _editedItem$value;
        var key = _Object$keys2[_i2];
        if (key === 'isSubmeasure') continue;
        var changes = [];
        changes = Diff.diffJson(((_originalItem$value = originalItem.value) === null || _originalItem$value === void 0 ? void 0 : _originalItem$value[key]) || '', ((_editedItem$value = editedItem.value) === null || _editedItem$value === void 0 ? void 0 : _editedItem$value[key]) || '').filter(function (c) {
          return c.added || c.removed;
        });
        if (changes.length) {
          obj[key] = changes;
        }
      }
      return obj;
    });
    var listSubsectionsInput = computed(function () {
      return {
        queryInput: {
          onlySubsections: true
        },
        limit: 1000,
        offset: 0
      };
    });
    var _useListConceptsQuery = useListConceptsQuery(listSubsectionsInput),
      resultSubsections = _useListConceptsQuery.result;
    var listAssignedActionsInput = computed(function () {
      var _editedItem$value$id, _editedItem$value2;
      return {
        queryInput: {
          submeasureOfConceptsId: (_editedItem$value$id = (_editedItem$value2 = editedItem.value) === null || _editedItem$value2 === void 0 ? void 0 : _editedItem$value2.id) !== null && _editedItem$value$id !== void 0 ? _editedItem$value$id : 0
        },
        limit: 1000,
        offset: 0
      };
    });
    var _useListConceptsQuery2 = useListConceptsQuery(listAssignedActionsInput),
      resultSubmeasures = _useListConceptsQuery2.result;
    var listAllVerifiedActionsInput = computed(function () {
      return {
        queryInput: {
          isSubmeasure: true,
          onlyMeasuresAndActions: true,
          status: ['VERIFIED']
        },
        limit: 1000,
        offset: 0
      };
    });
    var _useListConceptsQuery3 = useListConceptsQuery(listAllVerifiedActionsInput),
      resultAllVerifiedActionsInput = _useListConceptsQuery3.result;
    var subsections = computed(function () {
      var _resultSubsections$va, _resultSubsections$va2, _resultSubsections$va3;
      // Extract only the 'id' and 'label' from each concept.
      return (_resultSubsections$va = (_resultSubsections$va2 = resultSubsections.value) === null || _resultSubsections$va2 === void 0 ? void 0 : (_resultSubsections$va3 = _resultSubsections$va2.listConcepts) === null || _resultSubsections$va3 === void 0 ? void 0 : _resultSubsections$va3.map(function (_ref2) {
        var id = _ref2.id,
          label = _ref2.label;
        return {
          id: id,
          label: label
        };
      })) !== null && _resultSubsections$va !== void 0 ? _resultSubsections$va : [];
    });
    var submeasures = computed(function () {
      var _resultSubmeasures$va, _resultSubmeasures$va2;
      return (_resultSubmeasures$va = (_resultSubmeasures$va2 = resultSubmeasures.value) === null || _resultSubmeasures$va2 === void 0 ? void 0 : _resultSubmeasures$va2.listConcepts) !== null && _resultSubmeasures$va !== void 0 ? _resultSubmeasures$va : [];
    });
    var allVerifiedActions = computed(function () {
      var _resultAllVerifiedAct, _resultAllVerifiedAct2;
      return (_resultAllVerifiedAct = (_resultAllVerifiedAct2 = resultAllVerifiedActionsInput.value) === null || _resultAllVerifiedAct2 === void 0 ? void 0 : _resultAllVerifiedAct2.listConcepts) !== null && _resultAllVerifiedAct !== void 0 ? _resultAllVerifiedAct : [];
    });
    var originalSubmeasures = ref([]);
    var editedSubmeasures = ref([]);
    watch(function () {
      return submeasures.value;
    }, function (value) {
      originalSubmeasures.value = value;
      editedSubmeasures.value = value;
    });

    // Track changes for actions/submeasures
    var changesActions = computed(function () {
      var diffActions = Diff.diffArrays(originalSubmeasures.value, editedSubmeasures.value);
      // Map the changes to a format that includes the action type and the item itself
      var mappedChanges = diffActions.map(function (change) {
        return {
          action: change.added ? 'added' : change.removed ? 'removed' : 'unchanged',
          items: change.value // change.value is an array of items that were added/removed
        };
      });
      // Filter out unchanged items
      return mappedChanges.filter(function (change) {
        return change.action !== 'unchanged';
      });
    });
    var editedSubsection = ref('');
    var originalSubsection = computed(function () {
      var objectLinks = Array.isArray(props.item.objectLinks) ? props.item.objectLinks : [];
      var firstMatch = objectLinks.find(function (link) {
        return link.predicate === 'POSITIVELY_AFFECTS';
      });
      var subsection = firstMatch && firstMatch.object ? {
        id: firstMatch.object.id,
        label: firstMatch.object.label
      } : {};
      editedSubsection.value = subsection;
      return subsection;
    });

    // Format item text by combining id and label (include current parent)
    var formatItemText = function formatItemText(item) {
      var baseText = "".concat(item.id, " - ").concat(item.label);
      if (item.objectLinks && Array.isArray(item.objectLinks)) {
        var subMeasuresText = item.objectLinks.filter(function (link) {
          return link.predicate === 'IS_SUBMEASURE_OF';
        }).map(function (link) {
          return "(Currently Assigned to: ".concat(link.object.id, " - ").concat(link.object.label, ")");
        }).join(' / ');
        return subMeasuresText ? "".concat(baseText, " - ").concat(subMeasuresText) : baseText;
      }
      return baseText;
    };
    var headerDialog = computed(function () {
      return props.dialogType;
    });
    var changesSubsection = computed(function () {
      if (!originalSubsection.value || !editedSubsection.value) {
        return [];
      }

      // Compare the original and edited subsections
      var diffSubsection = Diff.diffJson(originalSubsection.value, editedSubsection.value);

      // Filter the changes to only include added or removed parts
      return diffSubsection.filter(function (c) {
        return c.added || c.removed;
      });
    });
    return {
      headerDialog: headerDialog,
      changesByKey: changesByKey,
      changesActions: changesActions,
      showTranslationPopover: showTranslationPopover,
      translate: translate,
      loading: loading,
      translatedResult: computed(function () {
        var _translatedResult$val, _translatedResult$val2, _translatedResult$val3;
        return (_translatedResult$val = translatedResult.value) === null || _translatedResult$val === void 0 ? void 0 : (_translatedResult$val2 = _translatedResult$val.chatgptTranslate) === null || _translatedResult$val2 === void 0 ? void 0 : (_translatedResult$val3 = _translatedResult$val2[0]) === null || _translatedResult$val3 === void 0 ? void 0 : _translatedResult$val3.translatedText;
      }),
      save: save,
      editedItem: editedItem,
      originalItem: originalItem,
      ConceptStatus: ConceptStatus,
      RiskManagementScheme: RiskManagementScheme,
      submeasures: submeasures,
      editedSubmeasures: editedSubmeasures,
      originalSubmeasures: originalSubmeasures,
      allVerifiedActions: allVerifiedActions,
      subsections: subsections,
      originalSubsection: originalSubsection,
      editedSubsection: editedSubsection,
      changesSubsection: changesSubsection,
      formatItemText: formatItemText
    };
  }
});