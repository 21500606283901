var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('title')))]),_vm._v(" "),_c('ix-section-text',[_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t('kpiSelection.title'),"subtitle":_vm.$t('kpiSelection.subtitle')}}),_vm._v(" "),_c('ix-custom-select',{attrs:{"disabled":!_vm.impactGoal.isEditable,"deletable-chips":"","multiple":"","item-text":"name","item-value":"id","item-disabled":"disabled","value":_vm.selectedMetricAssignments,"items":_vm.metricAssignments.length
          ? [
              {
                header: _vm.$t('dropdownHeader'),
              },
              ..._vm.metricAssignments,
            ]
          : [
              {
                header: _vm.$t('dropdownEmptyWarning'),
              },
            ],"placeholder":_vm.$t('kpiSelection.placeholder')},on:{"change":(val) => _vm.triggerUpdateImpact('metricAssignments', val)}}),_vm._v(" "),_c('div',{staticClass:"mt-5",staticStyle:{"float":"right"}},[_c('ix-dialog-metric',{on:{"update:metric":(data) => {
            // FIXME it's probably not the best practice to split this into 3 API calls
            _vm.addMetricAssignmentBatch({
              inputs: [
                {
                  metricId: data.id,
                  assigneeId: _vm.ventureId,
                  isFrozen: false,
                },
              ],
            }).then((result) => {
              _vm.onMetricAssignmentAddFinished(
                result?.data?.addMetricAssignmentBatch,
              )
            })
          }},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('ix-button-action',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"mdi-plus"}},'ix-button-action',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t('defineCustomKpiButton'))+"\n          ")])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","width":"50%"},model:{value:(_vm.metricFormBatchDialog),callback:function ($$v) {_vm.metricFormBatchDialog=$$v},expression:"metricFormBatchDialog"}},[_c('metrics-assignment-batch-dialog',{attrs:{"selected-metrics":_vm.toAddMetrics,"type":_vm.metricFormType,"venture-id":_vm.ventureId},on:{"finish":_vm.onMetricAssignmentAddFinished,"cancel":() => {
              _vm.metricFormBatchDialog = false
              _vm.toAddMetrics = null
            }}})],1),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('ix-button-action',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"mdi-magnify"},on:{"click":function($event){_vm.metricFormType = 'add'}}},'ix-button-action',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t('kpiSearchButton'))+"\n          ")])]}}]),model:{value:(_vm.metricAssignmentSearchDialog),callback:function ($$v) {_vm.metricAssignmentSearchDialog=$$v},expression:"metricAssignmentSearchDialog"}},[_vm._v(" "),_c('ix-section',{staticStyle:{"min-height":"800px"}},[_c('ix-section-title',{staticStyle:{"border-bottom":"0px !important"}},[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('kpiSearch.kpiSearchDialog.title'))+"\n            ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","small":"","color":"#DEEEF7"},on:{"click":function($event){_vm.metricAssignmentSearchDialog = false}}},[_c('v-icon',{staticClass:"font-weight-bold",attrs:{"small":"","color":"primary"}},[_vm._v("\n                mdi-close\n              ")])],1)],1),_vm._v(" "),_c('ix-section-text',[_c('ix-data-table-metrics',{attrs:{"query":{
                sections: [
                  117, // 'IRIS Indicators',
                  90, // 'PAI company data'
                ],
              }},on:{"usemetricsastemplate":(items) => {
                  _vm.metricFormBatchDialog = true
                  _vm.metricAssignmentSearchDialog = false
                  _vm.toAddMetrics = items // FIXME this is also very bad design.
                }}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }