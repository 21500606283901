import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.number.constructor.js";
import { useMutation } from '@vue/apollo-composable';
import ADD_TARGET_PARAMETERS from '~/graphql/mutations/custom-target/AddTarget.gql';
import UPDATE_TARGET_PARAMETERS from '~/graphql/mutations/custom-target/UpdateTargetParameters.gql';
import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    periods: {
      type: Array,
      required: true
    }
  },
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount;
    });
    var portfolioName = computed(function () {
      var _account$value;
      return (_account$value = account.value) === null || _account$value === void 0 ? void 0 : _account$value.name;
    });
    var portfolioId = computed(function () {
      var _account$value2;
      return (_account$value2 = account.value) === null || _account$value2 === void 0 ? void 0 : _account$value2.id;
    });
    var _useMutation = useMutation(ADD_TARGET_PARAMETERS),
      addTargetParameters = _useMutation.mutate;
    var _useMutation2 = useMutation(UPDATE_TARGET_PARAMETERS),
      updateTargetParameters = _useMutation2.mutate;
    return {
      addTargetParameters: addTargetParameters,
      updateTargetParameters: updateTargetParameters,
      portfolioName: portfolioName,
      portfolioId: portfolioId
    };
  },
  data: function data() {
    var _this$item;
    return {
      valid: true,
      selected: [],
      isValidation: false,
      options: [{
        id: 'sum',
        tooltip: null,
        label: 'Sum',
        sublabel: 'sums the ‘actual’ values in the reporting period and compares them with the target value'
      }, {
        id: 'average',
        tooltip: null,
        label: 'Average',
        sublabel: 'averages across the ‘actual’ values in the reporting period and compares them with the target value'
      }, {
        id: 'most_recent_value',
        tooltip: null,
        label: 'Most recent value',
        sublabel: 'takes the most recent ‘actual’ value and compares it with the target value'
      }],
      userInput: {
        selectedOptions: []
      },
      activePicker: '',
      startDate: '',
      endDate: '',
      date: null,
      target: null,
      calculationType: 'sum',
      weight: null,
      isTargetParameters: !!((_this$item = this.item) !== null && _this$item !== void 0 && _this$item.targetParameters)
    };
  },
  computed: {
    form: function form() {
      return this.$refs.form;
    }
  },
  mounted: function mounted() {
    var _this$item2;
    var targetParameters = (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : _this$item2.targetParameters;
    if (targetParameters) {
      var startDate = targetParameters.startDate,
        endDate = targetParameters.endDate,
        target = targetParameters.target,
        weight = targetParameters.weight,
        calculationType = targetParameters.calculationType;
      this.startDate = startDate;
      this.endDate = endDate;
      this.target = target;
      this.calculationType = calculationType;
      this.weight = weight;
    }
  },
  methods: {
    submit: function submit() {
      if (this.form.validate() && this.calculationType) {
        var _this$item3;
        var startDate = this.startDate,
          endDate = this.endDate,
          target = this.target,
          calculationType = this.calculationType,
          weight = this.weight,
          portfolioName = this.portfolioName,
          portfolioId = this.portfolioId;
        var targetParameters = (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : _this$item3.targetParameters;
        this.isValidation = false;
        var targetParametersInput = _objectSpread(_objectSpread({}, targetParameters && {
          id: targetParameters.id
        }), {}, {
          startDate: startDate,
          endDate: endDate,
          target: Number(target),
          calculationType: calculationType,
          weight: Number(weight),
          metricAssignmentId: this.item.id,
          portfolioName: portfolioName,
          createdBy: portfolioId
        });
        console.log('store', targetParametersInput);
        if (!targetParameters) {
          this.addTargetParameters({
            input: targetParametersInput
          });
        } else {
          this.updateTargetParameters({
            input: targetParametersInput
          });
        }
        this.$emit('close');
      } else this.isValidation = true;
    }
  }
});