import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.number.is-safe-integer.js";
import "core-js/modules/es6.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Sugar from 'sugar';
import { geographies } from '~/assets/geographies';
export default defineComponent({
  props: {
    outcomeToEdit: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    stage: {
      type: String,
      default: ''
    },
    currentUserRole: String
  },
  setup: function setup(props) {
    var _this = this;
    var defaultOutcome = ref({
      outcome: '',
      stakeholder: '',
      theoryOfChange: '',
      isPositive: false,
      geographies: [],
      problemsAddressed: [],
      causesOfOutcome: [],
      primarySDGs: [],
      secondarySDGs: [],
      relevanceOfImpact: 0,
      relevanceOfImpactExplanation: '',
      minStakeholders: null,
      maxStakeholders: null,
      outcomeForecastMetric: '',
      minOutcomeForecast: null,
      maxOutcomeForecast: null,
      degreeOfChange: 0,
      degreeOfChangeExplanation: '',
      informantComment: '',
      stage: this.stage,
      confirmedForNextStage: 'confirmed',
      reviewerComment: '',
      irisImpactCategories: []
    });
    var newOutcome = ref({});
    // irisImpactCategoriesDropdown

    // @Watch('outcomeToEdit', { immediate: true, deep: true })
    // onOutcomeEditChange() {
    //   this.newOutcome = {
    //     ...this.defaultOutcome,
    //     ...this.outcomeToEdit,
    //   }
    //   this.indicators = this.$store.getters['assessment/getIndicators'](
    //     this.outcomeToEdit.id,
    //   )
    // }
    watch(function () {
      return props.outcomeToEdit;
    }, function (newValue) {
      newOutcome.value = _objectSpread(_objectSpread({}, defaultOutcome.value), newValue);
    }, {
      immediate: true,
      deep: true
    });
    var allIndicators = function allIndicators() {
      return _this.$store.getters['assessment/getAssessment'].indicators;
    };
    watch(allIndicators, function () {
      _this.indicators = _this.$store.getters['assessment/getIndicators'](_this.outcomeToEdit.id);
    });
    var sources = function sources() {
      if (_this.assessment === undefined || _this.outcomeToEdit === undefined) return [];
      var indicatorIds = _this.indicators.map(function (e) {
        return e.id;
      });
      var sources = _this.assessment.sources.filter(function (source) {
        return indicatorIds.some(function (e) {
          return source.indicatorLinks.includes(e);
        });
      });

      // Filter duplicate items
      var u = {};
      return sources.filter(function (e) {
        var key = e.comment;
        // eslint-disable-next-line no-prototype-builtins
        var unique = u[key] = !u.hasOwnProperty(key);
        return unique;
      }).map(function (e) {
        return _objectSpread(_objectSpread({}, e), {}, {
          created: new Sugar.Date(e.created)
        });
      });
    };
    var sourcesForRelevanceOfImpact = function sourcesForRelevanceOfImpact() {
      if (_this.indicators.length === 0 || _this.sources === undefined) return [];
      var indicator = _this.indicators.find(function (e) {
        return e.Indicator === 'Relevance of impact';
      });
      if (!indicator) return [];
      return _this.sources.filter(function (source) {
        return source.indicatorLinks.includes(indicator.id);
      });
    };
    var sourcesForDegreeOfChange = function sourcesForDegreeOfChange() {
      if (_this.indicators.length === 0 || _this.sources === undefined) return [];
      var indicator = _this.indicators.find(function (e) {
        return e.Indicator === 'Degree of change';
      });
      if (!indicator) return [];
      return _this.sources.filter(function (source) {
        return source.indicatorLinks.includes(indicator.id);
      });
    };
    var mergeOutcomes = function mergeOutcomes() {
      return _objectSpread(_objectSpread(_objectSpread({}, _this.defaultOutcome), _this.outcomeToEdit), _this.currentOutcome);
    };
    var editingSource = ref({});
    var setEditingSource = function setEditingSource(source) {
      var editingSource = _this.assessment.sources.find(function (e) {
        return e.id === source.id;
      });
      if (editingSource) {
        _this.editingSource = _objectSpread({}, editingSource);
      }
    };
    var currentOutcome = ref({});
    var setOutcome = function setOutcome(key, val) {
      if (key === 'isPositive' && val === null) {
        return;
      }
      _this.$store.dispatch('assessment/updateOutcome', {
        id: _this.outcomeToEdit.id,
        fields: _defineProperty({}, key, val)
      });
      _this.currentOutcome[key] = val;
    };
    var indicatorItems = function indicatorItems() {
      return _this.indicators.map(function (e) {
        return {
          text: e.Indicator,
          value: e.id
        };
      });
    };
    var geographyItems = function geographyItems() {
      return geographies;
    };
    var sdgs = ['SDG 1 - No poverty', 'SDG 2 - Zero hunger', 'SDG 3 - Good health & well-being', 'SDG 4 - Quality education', 'SDG 5 - Gender equality', 'SDG 6 - Clean water & sanitation', 'SDG 7 - Affordable & clean energy', 'SDG 8 - Decent work & economic growth', 'SDG 9 - Industry & Innovation & Infrastructure', 'SDG 10 - Reduced inequalities', 'SDG 11 - Sustainable cities & communities', 'SDG 12 - Responsible consumption & production', 'SDG 13 - Climate action', 'SDG 14 - Life below water ', 'SDG 15 - Life on land', 'SDG 16 - Peace & Justice & Strong Institutions', 'SDG 17 - Partnerships', 'Not relevant according to SDGs'];
    var valid = false;
    var rules = {
      requiredRule: [function (v) {
        return !!v || 'This field is required';
      }],
      requiredRuleSelect: [function (v) {
        return v.length > 0 || 'At least one item is required';
      }],
      causeOfOutcomeRules: [function (v) {
        return v.length > 0 || 'At least one item is required. If you did not choose any business activities in the previous section yet, do that first so that you can select them here.';
      }],
      optionalIntegerRule: [function (v) {
        if (v) {
          return Number.isSafeInteger(Number(v)) || 'The value must be an integer number';
        } else {
          return true;
        }
      }]
    };
    var businessActivities = function businessActivities() {
      if (_this.assessment !== undefined && _this.assessment.keyinfo !== undefined) {
        var inputs = _this.assessment.keyinfo.inputs || [];
        var activities = _this.assessment.keyinfo.activities || [];
        var outputs = _this.assessment.keyinfo.outputs || [];
        return [].concat(inputs.map(function (v) {
          return 'Input: ' + v;
        })).concat(activities.map(function (v) {
          return 'Activity: ' + v;
        })).concat(outputs.map(function (v) {
          return 'Output: ' + v;
        }));
      }
      return [];
    };
    var assessment = function assessment() {
      return _this.$store.getters['assessment/getAssessment'];
    };
    var form = function form() {
      return _this.$refs.form;
    };
    var close = function close() {
      if (_this.form.validate()) {
        _this.$emit('closeDialog');
        _this.$refs.form.reset();
        _this.currentOutcome = {};
      }
    };
  }
});