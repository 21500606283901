import { useMutation } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import UPSERT_GHG_SAVING_POTENTIAL from '~/graphql/mutations/impact-goal/UpsertGhgSavingPotential.gql';
export default defineComponent({
  props: {
    impactGoal: {
      required: true,
      type: Object
    },
    impact: {
      required: true,
      type: Object
    }
  },
  setup: function setup(props) {
    var _props$impact, _props$impact$impactS, _props$impact$impactS2, _impactProfile$value;
    var route = useRoute();
    var impactSolutionId = computed(function () {
      return toNumber(route.params.solution);
    });
    var ghgSavingPotentialId = ref((_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$impactS = _props$impact.impactSolutions[impactSolutionId.value]) === null || _props$impact$impactS === void 0 ? void 0 : (_props$impact$impactS2 = _props$impact$impactS.ghgSavingPotential) === null || _props$impact$impactS2 === void 0 ? void 0 : _props$impact$impactS2.id);
    var impactProfile = computed(function () {
      var _props$impact2, _props$impact2$impact, _props$impact2$impact2;
      return (_props$impact2 = props.impact) === null || _props$impact2 === void 0 ? void 0 : (_props$impact2$impact = _props$impact2.impactSolutions[impactSolutionId.value]) === null || _props$impact2$impact === void 0 ? void 0 : (_props$impact2$impact2 = _props$impact2$impact.ghgSavingPotential) === null || _props$impact2$impact2 === void 0 ? void 0 : _props$impact2$impact2.impactProfileKind;
    });
    var _useMutation = useMutation(UPSERT_GHG_SAVING_POTENTIAL),
      upsertGhgSavingPotential = _useMutation.mutate;
    var selectedImpactProfiles = ref((_impactProfile$value = impactProfile.value) !== null && _impactProfile$value !== void 0 ? _impactProfile$value : 'CIRCULAR_ECONOMY');
    return {
      upsertGhgSavingPotential: upsertGhgSavingPotential,
      selectedImpactProfiles: selectedImpactProfiles,
      ghgSavingPotentialId: ghgSavingPotentialId
    };
  },
  data: function data() {
    return {
      showIntroductionImpactProfile: false
    };
  },
  methods: {
    renderMarkdown: renderMarkdown,
    valueChange: function valueChange(value) {
      this.selectedImpactProfiles = value;
      this.upsertGhgSavingPotential({
        input: {
          id: this.ghgSavingPotentialId,
          impactProfileKind: value
        }
      });
    }
  }
});