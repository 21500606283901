import { downloadExcel } from '~/functions/export';
import { useExportNkiCompanyDataLazyQuery } from '~/types/types';
export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup(_, _ref) {
    var emit = _ref.emit;
    var _useExportNkiCompanyD = useExportNkiCompanyDataLazyQuery(),
      onResult = _useExportNkiCompanyD.onResult,
      refetch = _useExportNkiCompanyD.refetch,
      loading = _useExportNkiCompanyD.loading,
      load = _useExportNkiCompanyD.load;
    onResult(function (res) {
      var _res$data;
      var result = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.exportNkiCompanyData;
      if (result) {
        downloadExcel(result.base64String, result.fileName);
        emit('finish');
      }
    });
    function triggerExcelExport() {
      load() || refetch();
    }
    return {
      triggerExcelExport: triggerExcelExport,
      loading: loading
    };
  }
});