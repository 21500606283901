export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    },
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var route = useRoute();
    var router = useRouter();
    var fullPath = computed(function () {
      return route.fullPath;
    });
    router.push(fullPath.value + '/define');
    return {
      fullPath: fullPath,
      router: router
    };
  },
  watch: {
    fullPath: function fullPath(value) {
      this.router.push(value + '/define');
    }
  }
});