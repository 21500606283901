import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useGetMetricAggregationQuery, MetricCalculationType, MetricReportingPeriodType, TypeMetricAssignedTo, useUpdateMetricAggregationMutation, useAddMetricAndAggregationMutation, useUpsertMetricMutation, TypeAggregationGroup } from '~/types/types';
import { parseMetricForBackend } from '~/functions/metrics';
export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          metric: {
            editable: true,
            name: null,
            description: null,
            unitName: null,
            defaultGoalType: 'none',
            defaultGoalTarget: null,
            defaultReportingPeriodType: MetricReportingPeriodType.Annual,
            assignedTo: TypeMetricAssignedTo.Company
          },
          calculationType: MetricCalculationType.Sum,
          comment: null,
          groupId: null
        };
      }
    }
  },
  setup: function setup(props, _ref) {
    var _aggregation$value2, _aggregation$value3, _props$value3;
    var emit = _ref.emit;
    var dialog = ref(false);
    var aggregation = ref(_objectSpread({}, props.value)); // this is a reactive object we will either create or modify
    var _useGetMetricAggregat = useGetMetricAggregationQuery({
        id: computed(function () {
          var _props$value;
          return (_props$value = props.value) === null || _props$value === void 0 ? void 0 : _props$value.id;
        })
      }, computed(function () {
        var _props$value2;
        return {
          enabled: !!((_props$value2 = props.value) !== null && _props$value2 !== void 0 && _props$value2.id)
        };
      })),
      onResult = _useGetMetricAggregat.onResult,
      loading = _useGetMetricAggregat.loading;
    onResult(function (_ref2) {
      var data = _ref2.data;
      if (data !== null && data !== void 0 && data.getMetricAggregation) {
        aggregation.value = _objectSpread({}, data.getMetricAggregation);
        if (aggregation.value.groupType === TypeAggregationGroup.Fund) {
          var _aggregation$value;
          selectedFundIds.value = [(_aggregation$value = aggregation.value) === null || _aggregation$value === void 0 ? void 0 : _aggregation$value.groupId];
        }
      }
    });
    var selectedFundIds = ref(((_aggregation$value2 = aggregation.value) === null || _aggregation$value2 === void 0 ? void 0 : _aggregation$value2.groupType) === TypeAggregationGroup.Fund ? [(_aggregation$value3 = aggregation.value) === null || _aggregation$value3 === void 0 ? void 0 : _aggregation$value3.groupId] : []);
    watch(selectedFundIds, function () {
      if (selectedFundIds.value.length > 0) {
        aggregation.value.groupType = TypeAggregationGroup.Fund;
        aggregation.value.groupId = selectedFundIds.value[0];
        assignToAll.value = false;
      }
    });
    var assignToAll = ref(((_props$value3 = props.value) === null || _props$value3 === void 0 ? void 0 : _props$value3.groupType) === TypeAggregationGroup.PortfolioVentureLink);
    watch(assignToAll, function () {
      if (assignToAll.value) {
        aggregation.value.groupType = TypeAggregationGroup.PortfolioVentureLink;
        aggregation.value.groupId = 0;
        selectedFundIds.value = []; // clear
      }
    });
    var _useAddMetricAndAggre = useAddMetricAndAggregationMutation(),
      addMetricAndAggregation = _useAddMetricAndAggre.mutate;
    var _useUpdateMetricAggre = useUpdateMetricAggregationMutation(),
      updateMetricAggregation = _useUpdateMetricAggre.mutate;
    var isEdit = computed(function () {
      var _props$value4;
      return (_props$value4 = props.value) === null || _props$value4 === void 0 ? void 0 : _props$value4.id;
    });
    var _useUpsertMetricMutat = useUpsertMetricMutation(),
      upsertMetric = _useUpsertMetricMutat.mutate;
    var submit = function submit() {
      var _form$value, _form$value$validate;
      if ((_form$value = form.value) !== null && _form$value !== void 0 && (_form$value$validate = _form$value.validate) !== null && _form$value$validate !== void 0 && _form$value$validate.call(_form$value)) {
        var obj = aggregation.value;
        if (isEdit.value) {
          // TODO updateMetricAndMetricAggregation
          upsertMetric({
            input: parseMetricForBackend(obj.metric)
          }).then(function () {
            updateMetricAggregation({
              input: {
                id: obj.id,
                calculationType: obj.calculationType,
                groupId: obj.groupId,
                comment: obj.comment,
                groupType: obj.groupType
              }
            }).then(function () {
              dialog.value = false;
              emit('input');
              window.$nuxt.$emit('userMsg');
            });
          });
        } else {
          addMetricAndAggregation({
            metric: parseMetricForBackend(obj.metric),
            // FIXME: we should remove this
            input: {
              calculationType: obj.calculationType,
              groupId: obj.groupId,
              comment: obj.comment,
              groupType: obj.groupType
            }
          }).then(function (data) {
            emit('input', data);
            window.$nuxt.$emit('userMsg');
            dialog.value = false;
          });
        }
      } else {
        window.$nuxt.$emit('userError', {
          message: 'Please check your input',
          color: 'red'
        });
      }
    };
    var form = ref(null);
    var disabled = computed(function () {
      if (loading.value) {
        return true;
      }
      if (isEdit.value && aggregation.value.groupType === TypeAggregationGroup.Fund && !aggregation.value.groupId) {
        return true;
      }
      if (!assignToAll.value && selectedFundIds.value.length === 0) {
        return true;
      }
      return false;
    });
    return {
      disabled: disabled,
      selectedFundIds: selectedFundIds,
      assignToAll: assignToAll,
      form: form,
      submit: submit,
      dialog: dialog,
      aggregation: aggregation,
      loading: loading,
      updateMetricAggregation: updateMetricAggregation,
      isEdit: isEdit
    };
  }
});