var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('ix-btn-back',{staticClass:"mr-4"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "),_c('span',[_vm._v(" Edit Stakeholder ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","dense":"","type":"info"}},[_vm._v("\n      Note: Non-listed SMEs only need to list their key IROs and business\n      relationships.\n    ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('name')}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.item.name,"hide-details":"","outlined":"","clearable":""},on:{"input":(val) => {
          _setup.updateStakeholder({ stakeholder: { id: _setup.item.id, name: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('type')}}),_vm._v(" "),_c('ix-autocomplete-stakeholder-type',{attrs:{"value":_setup.item.type},on:{"input":(val) => {
          _setup.updateStakeholder({ stakeholder: { id: _setup.item.id, type: val } })
        }}})],1),_vm._v(" "),(false)?_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('desc'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_setup.item.description,"outlined":"","hide-details":"","auto-grow":"","clearable":""},on:{"input":(val) => {
          _vm.updateIRO({ iro: { id: _setup.item.id, description: val || '' } })
        }}})],1):_vm._e(),_vm._v(" "),(false)?_c('v-col',{attrs:{"cols":"6"}}):_vm._e(),_vm._v(" "),(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-data-table-iro-section-link',{attrs:{"iro-id":_setup.item.id,"height":"300px","headers":[
        // 'id',
        'stakeholder.name',
        'stakeholder.type',
        // 'relationship',
        // 'affected_by_impacts',
        'impactMaterial',
        'financialMaterial',
        'Concerns and requirements',
      ]}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('relatedActivities'),"sublabel":""}}),_vm._v(" "),(_setup.item.id)?_c('ix-data-table-activity',{ref:"dataTableActivity",attrs:{"filters":{ stakeholderIds: [_setup.item.id] },"headers":[
        // 'id',
        {
          value: 'actions',
          cellClass: 'max-w-[20px]',
          width: '10px',
          text: ' ',
        },
        'name',
        'type',
        'industry',
        'location',
        'riskProfile',
      ]},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item: activity }){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{staticClass:"mb-2",attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","small":""}},'v-icon',attrs,false),on),[_vm._v("\n                mdi-close\n              ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 break-keep"},[_vm._v("\n                "+_vm._s(_vm.$t('confirmTitleActivity'))+"\n              ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":() => {
                      dialog.value = false
                    }}},[_vm._v("\n                  Cancel\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_setup.delActivityLoading},on:{"click":() => {
                      _setup.delActivityStakeholderLink({
                        activityId: activity.id,
                        stakeholderId: _setup.item.id,
                      }).then(() => {
                        _setup.dataTableActivity?.refetch?.() // refresh the table so items are removed
                        // addIroBtn?.refetch?.() // refresh the add IRO button so removed items are available again.
                      })
                    }}},[_vm._v("\n                  "+_vm._s(_vm.$t('btnUnAssign'))+"\n                ")])],1)],1)]}}],null,true)})]}},(false)?{key:"footer.prepend",fn:function(){return [_c('v-toolbar',{staticClass:"toolbar",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('ix-btn-add-activity',{ref:"addActivityBtn",attrs:{"stakeholder-id":_setup.item.id},on:{"done":() => _setup.dataTableActivity?.refetch?.()}})],1),_vm._v(" "),_c('v-spacer')],1)]},proxy:true}:null],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('relatedIros'),"sublabel":""}}),_vm._v(" "),(_setup.item.id)?_c('ix-data-table-iro',{ref:"dataTableIro",attrs:{"query":{ stakeholderIds: [_setup.item.id] },"headers":[
        // 'id',
        {
          value: 'actions',
          cellClass: 'max-w-[20px]',
          width: '10px',
          text: ' ',
        },
        'name',
        'type',
        'activity',
        'sections',
        // 'stakeholders',
        'likelihood',
        'severity',
        'timeHorizon',
      ]},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item: iro }){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{staticClass:"mb-2",attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","small":""}},'v-icon',attrs,false),on),[_vm._v("\n                mdi-close\n              ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 break-keep"},[_vm._v("\n                "+_vm._s(_vm.$t('confirmTitle'))+"\n              ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":() => {
                      dialog.value = false
                    }}},[_vm._v("\n                  Cancel\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_setup.delLoading},on:{"click":() => {
                      _setup.delIroStakeholderLink({
                        iroId: iro.id,
                        stakeholderId: _setup.item.id,
                      }).then(() => {
                        _setup.dataTableIro?.refetch?.() // refresh the table so items are removed
                        _setup.addIroBtn?.refetch?.() // refresh the add IRO button so removed items are available again.
                      })
                    }}},[_vm._v("\n                  "+_vm._s(_vm.$t('btnUnAssign'))+"\n                ")])],1)],1)]}}],null,true)})]}},{key:"footer.prepend",fn:function(){return [_c('v-toolbar',{staticClass:"toolbar",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('ix-btn-add-iro',{ref:"addIroBtn",attrs:{"stakeholder-id":_setup.item.id},on:{"done":() => _setup.dataTableIro?.refetch?.()}})],1),_vm._v(" "),_c('v-spacer')],1)]},proxy:true}],null,true)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }