import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=64327d98"
import script from "./dashboard.vue?vue&type=script&setup=true&lang=ts"
export * from "./dashboard.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PortfolioKeyFacts: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/KeyFacts.vue').default,PortfolioEsgPerformance: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/EsgPerformance.vue').default,PortfolioAggregatedKpis: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/AggregatedKpis.vue').default,PortfolioImpactGoalPerformance: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/ImpactGoalPerformance.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,InvitePortfolioCompaniesCard: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/InvitePortfolioCompaniesCard.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default,IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardText,VCardTitle,VDivider})
