import { render, staticRenderFns } from "./ImpactComparisonView.vue?vue&type=template&id=3e83db46&scoped=true"
import script from "./ImpactComparisonView.vue?vue&type=script&lang=ts"
export * from "./ImpactComparisonView.vue?vue&type=script&lang=ts"
import style0 from "./ImpactComparisonView.vue?vue&type=style&index=0&id=3e83db46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e83db46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,VentureSummary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/VentureSummary.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxButtonText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Text.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtnToggle,VCard,VCardSubtitle,VCardText,VCardTitle,VCheckbox,VCol,VDataTable,VDivider,VLayout,VRow,VTextField})
