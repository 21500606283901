import "core-js/modules/es6.regexp.replace.js";
import { abbreviateNumber } from '~/assets/helper/functions';
export default defineComponent({
  props: {
    metricValue: {
      type: Object,
      default: undefined,
      reqrequired: true
    }
  },
  methods: {
    getActualOrTargetValue: function getActualOrTargetValue(label) {
      if (label === 'actual') {
        var _this$metricValue, _this$metricValue2;
        var actual = (_this$metricValue = this.metricValue) !== null && _this$metricValue !== void 0 && _this$metricValue.actual ? (_this$metricValue2 = this.metricValue) === null || _this$metricValue2 === void 0 ? void 0 : _this$metricValue2.actual : undefined;
        return this.handleUndefined(actual);
      } else {
        var _this$metricValue3, _this$metricValue4;
        var target = (_this$metricValue3 = this.metricValue) !== null && _this$metricValue3 !== void 0 && _this$metricValue3.target ? (_this$metricValue4 = this.metricValue) === null || _this$metricValue4 === void 0 ? void 0 : _this$metricValue4.target : undefined;
        return this.handleUndefined(target);
      }
    },
    formatNumber: function formatNumber(num) {
      return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    handleUndefined: function handleUndefined(num) {
      var _this$$i18n;
      var locale = (_this$$i18n = this.$i18n) === null || _this$$i18n === void 0 ? void 0 : _this$$i18n.locale;
      if (!num) return '⋯';
      return abbreviateNumber(num, locale);
    }
  }
});