var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"loading":_vm.addMetricAggregationLoading}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n    "+_vm._s(_vm.type === 'edit' ? _vm.$t('titleEdit') : _vm.$t('titleAdd'))+"\n  ")]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"form"},[(
          (
            _vm.selectedMetrics.filter(
              (m) => m.assignedTo == _vm.TypeMetricAssignedTo.Company,
            ) || []
          ).length > 0
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t('label')))]),_vm._v(" "),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"accordion":""}},_vm._l((_vm.selectedMetrics.filter(
                (m) => m.assignedTo == _vm.TypeMetricAssignedTo.Company,
              )),function(metric){return _c('v-expansion-panel',{key:metric.id},[_c('v-expansion-panel-header',[_vm._v("\n                "+_vm._s(metric.name)+"\n              ")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('metrics-metric-form',{attrs:{"value":metric,"account-id":_vm.portfolioId}})],1)],1)}),1)],1)],1):_vm._e(),_vm._v(" "),(
          (
            _vm.selectedMetrics.filter(
              (m) => m.assignedTo == _vm.TypeMetricAssignedTo.Company,
            ) || []
          ).length > 0
        )?_c('v-row',[_c('v-col',[_c('v-label',[_vm._v(_vm._s(_vm.$t('labelAssign')))]),_vm._v(" "),_c('ix-data-table-accounts',{staticClass:"max-h-[670px] overflow-scroll",model:{value:(_vm.assigneeIds),callback:function ($$v) {_vm.assigneeIds=$$v},expression:"assigneeIds"}})],1)],1):_vm._e(),_vm._v(" "),((_vm.selectedMetrics.filter(_vm.filterToFund) || []).length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t('labelFund')))]),_vm._v(" "),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"accordion":""}},_vm._l((_vm.selectedMetrics.filter(_vm.filterToFund)),function(metric){return _c('v-expansion-panel',{key:metric.id},[_c('v-expansion-panel-header',[_vm._v("\n                "+_vm._s(metric.name)+"\n              ")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('metrics-metric-form',{attrs:{"value":metric,"account-id":_vm.portfolioId}})],1)],1)}),1)],1)],1):_vm._e(),_vm._v(" "),((_vm.selectedMetrics.filter(_vm.filterToFund) || []).length > 0)?_c('v-row',[_c('v-col',[_c('v-label',[_vm._v(_vm._s(_vm.$t('labelAssignFund')))]),_vm._v(" "),_c('ix-data-table-funds',{staticClass:"max-h-[670px] overflow-scroll",attrs:{"height":'300px',"show-select":true},model:{value:(_vm.fundIds),callback:function ($$v) {_vm.fundIds=$$v},expression:"fundIds"}})],1)],1):_vm._e(),_vm._v(" "),(
          (
            _vm.selectedMetrics.filter(
              (m) => m.assignedTo == _vm.TypeMetricAssignedTo.FundMgmt,
            ) || []
          ).length > 0
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t('labelPortfolio')))]),_vm._v(" "),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"accordion":""}},_vm._l((_vm.selectedMetrics.filter(
                (m) => m.assignedTo == _vm.TypeMetricAssignedTo.FundMgmt,
              )),function(metric){return _c('v-expansion-panel',{key:metric.id},[_c('v-expansion-panel-header',[_vm._v("\n                "+_vm._s(metric.name)+"\n              ")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('metrics-metric-form',{attrs:{"value":metric,"account-id":_vm.portfolioId}})],1)],1)}),1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":_vm.disableSubmit,"color":"primary","text":""},on:{"click":() => {
          _vm.$emit('finish')
          _vm.submit()
        }}},[_vm._v("\n      "+_vm._s(_vm.type === 'edit' ? 'Update' : 'Add')+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }