import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import VueMarkdown from 'vue-markdown-render';
export default defineComponent({
  name: 'ScoreBarDiscrete',
  components: {
    VueMarkdown: VueMarkdown
  },
  props: {
    type: String,
    score: [Object, Number],
    options: Array,
    isPositive: Boolean,
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var readOnly = ref(false);
    var safeScore = computed(function () {
      if (props.score !== undefined) {
        return props.score.toString();
      } else {
        return props.options[0].key;
      }
    });
    var onChange = function onChange(value) {
      props.score = +value;
      emit('onchange', +value);
    };
    var formatPercentage = function formatPercentage(score) {
      if (score !== undefined && score !== null && score.specialValue !== 'NaN') {
        return (parseFloat(score) * 100).toFixed(0);
      } else {
        return '—';
      }
    };
    watch(function () {
      return props.score;
    }, function (newValue) {
      if (!props.editable) {
        readOnly.value = true;
      }
    });
    return {
      readOnly: readOnly,
      safeScore: safeScore,
      onChange: onChange,
      formatPercentage: formatPercentage
    };
  }
});