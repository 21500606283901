import { render, staticRenderFns } from "./Stakeholder.vue?vue&type=template&id=01464d6a&scoped=true"
import script from "./Stakeholder.vue?vue&type=script&setup=true&lang=ts"
export * from "./Stakeholder.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Stakeholder.vue?vue&type=style&index=0&id=01464d6a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01464d6a",
  null
  
)

/* custom blocks */
import block0 from "./Stakeholder.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSearchText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SearchText.vue').default,IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,IxDialogDelStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/DelStakeholder.vue').default,IxDataTable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/DataTable.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VList,VMenu,VTooltip})
