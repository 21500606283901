var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.completionStatus.hasTarget)?_c('div',{staticClass:"ml-2"},[_c('v-row',{staticClass:"flex-nowrap"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"blue-grey--text text--darken-1 font-weight-bold",staticStyle:{"font-size":"14px !important"},attrs:{"cols":"12"}},[_vm._v("\n            "+_vm._s(_vm.$t('impactGoal.overview.ourMeasurement.forecastFor', {
                metricName: _vm.metricNameOverride || _vm.metric.name,
                impactName: _vm.impactName,
              }))+"\n          ")]),_vm._v(" "),_c('v-col',{staticClass:"text-caption blue-grey--text text--darken-1 pb-4",attrs:{"cols":"12"}},[_vm._v("\n            "+_vm._s(_vm.unitName)+"\n          ")]),_vm._v(" "),_c('v-col',{staticStyle:{"margin-top":"-10px !important"}},[_c('v-row',{staticClass:"flex-no-wrap align-start",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"flex-shrink-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"bar",style:(`width: ${_vm.score}%`)})])]),_vm._v(" "),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[(_vm.completionStatus.actual !== 0)?_c('span',{staticClass:"caption blue-grey--text text--darken-1"},[_vm._v("0")]):_vm._e(),_vm._v(" "),_c('div',{style:({
                        marginLeft: `${_vm.calculateActualPosition}%`,
                        position: 'relative',
                      })},[_c('span',{staticClass:"caption primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.actual))])]),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"caption blue-grey--text text--darken-1"},[_vm._v(_vm._s(_vm.target)+"\n                        ")])])],1)],1)],1)],1),_vm._v(" "),(_vm.score !== 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"margin-left":"-2px !important"}},[_c('v-icon',[_vm._v("mdi-calculator-variant-outline")]),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.calculationMessage))])],1)]):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('metrics-progress-score',{staticStyle:{"margin-top":"-13px"},attrs:{"percentage":_vm.score}})],1)],1)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }