import "core-js/modules/es6.number.constructor.js";
import CompanyLogo from '../components/company/CompanyLogo.vue';
export default defineComponent({
  components: {
    CompanyLogo: CompanyLogo
  },
  props: {
    ventureId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      required: true
    },
    metricAssignmentId: {
      type: Number,
      default: null
    }
  },
  setup: function setup() {}
});