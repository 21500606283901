import Dashboard from '~/pages/portfolio/dashboard.vue';
import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  components: {
    Dashboard: Dashboard
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $auth = _useNuxtApp.$auth;
    var _useGetAccountQuery = useGetAccountQuery({}, computed(function () {
        return {
          enabled: $auth.loggedIn
        };
      })),
      result = _useGetAccountQuery.result,
      loading = _useGetAccountQuery.loading;
    var account = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount;
    });
    return {
      loading: loading,
      account: account
    };
  }
});