import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
export default defineComponent({
  props: {
    sections: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    questionnaireResultMapping: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup() {},
  methods: {
    selectedOptions: function selectedOptions(section) {
      var result = this.questionnaireResultMapping[section.label];
      if (!result) return [];
      return result.details.questionDetails.filter(function (e) {
        return e.hasRisk === true;
      }).flatMap(function (e) {
        return e.options;
      });
    },
    getViolations: function getViolations(section) {
      var _result$details,
        _this = this;
      var result = this.questionnaireResultMapping[section.label];
      if ((result === null || result === void 0 ? void 0 : (_result$details = result.details) === null || _result$details === void 0 ? void 0 : _result$details.hasRisk) === true) {
        var counts = new Map();
        var optionIxIds = {};
        section.questions.flatMap(function (question) {
          return question.options;
        }).forEach(function (option) {
          if (option.id) {
            optionIxIds[option.id] = option.ixId;
          }
        });
        this.selectedOptions(section).forEach(function (option) {
          var ixId = optionIxIds[option.id];
          var label = _this.$t("database.option.".concat(ixId, ".label"));
          counts.set(label, (counts.get(label) || 0) + 1);
        });
        return Array.from(counts).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            violation = _ref2[0],
            count = _ref2[1];
          return (count > 1 ? count + ' x ' : '') + violation;
        });
      }
      return [this.$t('general.nothingToDisclose')];
    }
  }
});