import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import { ImplementationStatus } from '~/types/types';
export default defineComponent({
  props: {
    isFirstAssessment: {
      type: Boolean
    }
  },
  setup: function setup() {
    var route = useRoute();
    var questionnaireId = computed(function () {
      return toNumber(route === null || route === void 0 ? void 0 : route.params.questionnaire);
    });
    return {
      questionnaireId: questionnaireId
    };
  },
  data: function data() {
    return {
      step: 1
    };
  },
  computed: {
    doing: function doing() {
      return ImplementationStatus.Doing;
    },
    planned: function planned() {
      return ImplementationStatus.In_12Months;
    },
    notPlanned: function notPlanned() {
      return ImplementationStatus.NotPlanned;
    }
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});