import { render, staticRenderFns } from "./MetricBox.vue?vue&type=template&id=768556ac&scoped=true"
import script from "./MetricBox.vue?vue&type=script&lang=ts"
export * from "./MetricBox.vue?vue&type=script&lang=ts"
import style0 from "./MetricBox.vue?vue&type=style&index=0&id=768556ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768556ac",
  null
  
)

/* custom blocks */
import block0 from "./MetricBox.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxMetricThermometer: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/MetricThermometer.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VHover,VRow,VSpacer})
