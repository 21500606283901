var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[_c('v-layout',{staticStyle:{"background-color":"white !important"},attrs:{"align-center":""}},[_c('v-tabs',{staticClass:"parent-step pl-3",attrs:{"color":"primary","elevation":"0"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.sections),function(section,index){return _c('v-tab',{key:section.label,staticClass:"justify-center pt-2",attrs:{"value":index,"editable":"","color":"primary","ripple":false}},[_c('v-layout',{attrs:{"column":"","justify-center":""}},[_c('span',{staticClass:"barlow",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(section.label))])])],1)}),1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',[_c('v-col',_vm._l((_vm.sections.filter(
            (section) => section.label === _vm.currentSection,
          )),function(section){return _c('v-stepper',{key:section.label,staticClass:"child-step h-full",staticStyle:{"background":"white"},attrs:{"flat":"","outlined":"","color":"primary"},model:{value:(_vm.currentSubsection),callback:function ($$v) {_vm.currentSubsection=$$v},expression:"currentSubsection"}},[_c('v-stepper-header',{staticStyle:{"box-shadow":"unset !important"}},[_c('v-row',{staticClass:"ma-0"},_vm._l((section.sections),function(subsection){return _c('v-col',{key:subsection.label,staticClass:"align-center"},[_c('v-stepper-step',{staticClass:"justify-center align-center flex-grow-1 h-full hide-first-span",attrs:{"ripple":false,"editable":"","step":subsection.label}},[_c('v-btn',{class:_vm.currentSubsection === subsection.label
                        ? 'primary-gradiant'
                        : '',staticStyle:{"width":"100%","font-weight":"400 !important"},attrs:{"align-center":"","depressed":"","color":_vm.currentSubsection === subsection.label ? '' : 'white',"x-large":""}},[(
                        _vm.getIcon(subsection.id) &&
                        _vm.currentSubsection === subsection.label
                      )?_c('img',{attrs:{"id":"img","src":_vm.getIcon(subsection.id).active,"height":"28"}}):_vm._e(),_vm._v(" "),(
                        _vm.getIcon(subsection.id) &&
                        _vm.currentSubsection !== subsection.label
                      )?_c('img',{attrs:{"src":_vm.getIcon(subsection.id).inactive,"height":"28"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"barlow ml-3",staticStyle:{"font-weight":"500"},style:(_vm.currentSubsection === subsection.label
                          ? 'color: white'
                          : 'color: #5B7185')},[_vm._v("\n                      "+_vm._s(subsection.label)+"\n                    ")])])],1)],1)}),1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_vm._l((section.sections),function(subsection){return _c('v-stepper-content',{key:subsection.label,staticStyle:{},attrs:{"step":subsection.label}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('ix-sub-section',[_c('ix-sub-section-title',[_c('span',[_vm._v("\n                      "+_vm._s(_vm.$t('esg.resultView.assessmentResult'))+" '"+_vm._s(subsection.label)+"' ("+_vm._s(_vm.formatAsGermanDate(_vm.questionnaireAssignment.updatedAt))+"):\n                    ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.questionnaireResultMapping[subsection.label])?_c('div',[(
                          _vm.getEsgSectionDetails(subsection.id)
                            .showDisclosureScore
                        )?_c('ix-survey-disclosure-subsection-score',{attrs:{"has-risk":_vm.questionnaireResultMapping[subsection.label].details
                            .hasRisk}}):_c('ix-survey-esg-subsection-score',{attrs:{"questionnaire-result":_vm.questionnaireResultMapping[subsection.label]}})],1):_vm._e()],1),_vm._v(" "),(_vm.showQuestionnaireResult)?_c('ix-sub-section-text',[_c('v-row',[_vm._l((subsection.questions),function(question,f_index){return [(
                            _vm.userInputMapping &&
                            _vm.userInputMapping[question.id] &&
                            _vm.userInputMapping[question.id].isActive
                          )?_c('v-col',{key:f_index,attrs:{"cols":"12"}},[(
                              _vm.userInputMapping[question.id] &&
                              _vm.userInputMapping[question.id].isActive
                            )?_c(question.component,_vm._b({tag:"component",attrs:{"user-input":_vm.userInputMapping[question.id],"user-input-mapping":_vm.userInputMapping,"is-editable":false,"esg-actions-active":_vm.esgActionsActive}},'component',question,false)):_vm._e()],1):_vm._e()]})],2),_vm._v(" "),_c('v-divider',{attrs:{"vertical":""}}),_vm._v(" "),(_vm.showQuestionnaireResult)?_c('v-col',{attrs:{"cols":"4","justify":"center"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-divider')],1),_vm._v(" "),_c('v-divider',{staticStyle:{"z-index":"1 !important"},attrs:{"vertical":""}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-survey-sdg-gri-relevant-explanation',{staticClass:"pt-3",attrs:{"label":subsection.label,"questionnaire-result":_vm.questionnaireResultMapping[subsection.label],"esg-actions-active":_vm.esgActionsActive,"section-details":_vm.getEsgSectionDetails(subsection.id)}})],1)],1)],1)})],2)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }