import "core-js/modules/es6.number.constructor.js";
import { abbreviateNumber } from '~/assets/helper/functions';
import { MetricValueKind } from '~/types/types';
export default defineComponent({
  props: {
    metric: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    metricValue: {
      type: Object,
      required: true
    },
    useTarget: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(props) {
    var actual = ref(props.metricValue.actual);
    var _useNuxtApp = useNuxtApp(),
      i18n = _useNuxtApp.$i18n;
    var isAggregation = computed(function () {
      var _props$metricValue;
      return ((_props$metricValue = props.metricValue) === null || _props$metricValue === void 0 ? void 0 : _props$metricValue.__typename) === 'MetricAggregationValueType';
    });
    var actualCompletionRate = computed(function () {
      if (!isAggregation.value) return '';
      var metricAggregationValue = props === null || props === void 0 ? void 0 : props.metricValue;
      var txt = '';
      var actualCompletedLength = (metricAggregationValue.actualCompleted || []).length;
      var requiredLength = (metricAggregationValue.required || []).length;
      if (requiredLength === 0) {
        return '';
      }
      txt += "Completion rate: ".concat(formatNumber(actualCompletedLength / requiredLength * 100, i18n.locale), "%.<br>").concat(actualCompletedLength, " out of ").concat(requiredLength, " companies entered data.");
      return txt;
    });
    var valueType = computed(function () {
      return props.metric.valueType;
    });
    var formatNumber = function formatNumber(num, locale) {
      if (num === null) {
        // if we run Number(null) it will return 0, so this is an edge case
        return '⋯';
      }
      num = Number(num);
      if (isNaN(num)) {
        return '⋯';
      }
      return abbreviateNumber(num, locale);
    };
    var formatText = function formatText(text) {
      if (text === undefined || text === null) {
        return '⋯';
      }
      return text;
    };
    var formatOptions = function formatOptions(options) {
      if (options === undefined || options === null || options.length < 1) {
        return '⋯';
      }
      return options.join(', ');
    };
    return {
      MetricValueKind: MetricValueKind,
      actual: actual,
      actualCompletionRate: actualCompletionRate,
      valueType: valueType,
      formatNumber: formatNumber,
      formatText: formatText,
      formatOptions: formatOptions,
      isAggregation: isAggregation
    };
  }
});