import { render, staticRenderFns } from "./EsgScoresAndDetails.vue?vue&type=template&id=fb286a2c"
import script from "./EsgScoresAndDetails.vue?vue&type=script&lang=js"
export * from "./EsgScoresAndDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyEsgSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgSectionTitle.vue').default,IxSurveyEsgScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgScore.vue').default,IxSurveyEsgScoreSectionDetails: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgScoreSectionDetails.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
