import { calculateProgressBarWidth } from './metric_assignment_info'
export const handleImpactOverviewInfo = (impactGoal: any) => {
  return [
    {
      text: 'Impact Overview',
      width: '*',
      style: ['impactOverview'],
    },
    ...(impactGoal?.targetProgress?.completionRate || 0
      ? [
          {
            text: 'Impact goal progress ',
            width: '*',
            style: ['header'],
          },
          impactProgressSection(impactGoal.targetProgress),
        ]
      : []),
  ]
}
const impactProgressSection = (targetProgress) => {
  const { hasTarget, message } = targetProgress
  if (hasTarget) {
    return {
      columns: [
        progressSection(targetProgress),
        messageSection(hasTarget, message),
      ],
      margin: [-5, 5, 0, 0],
    }
  } else
    return {
      columns: [messageSection(hasTarget, message)],
      margin: [-5, 5, 0, 0],
    }
}

const messageSection = (hasTarget, message) => {
  return {
    text: message,
    style: ['message'],
    width: hasTarget ? 120 : '*',
    margin: [hasTarget ? -230 : 0, 0, 0, 0],
  }
}
const progressSection = (targetProgress) => {
  const { startDate, endDate, completionRate } = targetProgress
  const score = completionRate ? Math.round(completionRate * 100) : 0
  const xPos = setPositionForProgressScore(String(score).length)
  return [
    {
      svg: `<svg width="622" height="31" viewBox="0 0 622 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.25" x="0.799072" y="0.285156" width="350" height="15" rx="8" fill="#8FA5AF"/>
    <rect y="0.285156" width="${calculateProgressBarWidth(
      350,
      Number(score),
    )}" height="14" x="1" rx="8" fill="url(#paint0_linear_8054_2075)"/>
    <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="9" letter-spacing="0em"><tspan x="0" y="30.5852">${startDate}</tspan></text>
    <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="9" letter-spacing="0em"><tspan x="330" y="30.5852">${endDate}</tspan></text>
    <text fill="#5188ff " xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="16" font-weight="600" letter-spacing="0em"><tspan x="${
      xPos.scoreX
    }" y="14">${score}</tspan></text>
    <line x1="${xPos.lineX}" y1="-5" x2="${
      xPos.lineX
    }" y2="19" stroke="#8FA5AF"/>
    <text fill="#8FA5AF" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="11" font-weight="600" letter-spacing="0em"><tspan x="${
      xPos.finalScoreX
    }" y="13">100</tspan></text>
    <defs>
    <linearGradient id="paint0_linear_8054_2075" x1="-197.159" y1="91.8575" x2="17.7758" y2="-215.708" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00679E"/>
    <stop offset="1" stop-color="#23A9F1"/>
    </linearGradient>
    </defs>
    </svg>`,
    },
  ]
}
const setPositionForProgressScore = (scoreLength: number) => {
  if (scoreLength === 1) return { scoreX: 360, lineX: 375, finalScoreX: 380 }
  else if (scoreLength === 2)
    return { scoreX: 360, lineX: 385, finalScoreX: 390 }
  else return { scoreX: 360, lineX: 395, finalScoreX: 400 }
}
export const impactOverviewStyle = {
  message: {
    color: '#5B7185',
    fontSize: 9,
  },
  impactOverview: {
    color: '#5188ff ',
    fontSize: 20,
    margin: [-7, -40, 0, 0],
    bold: true,
  },
  header: {
    color: '#5B7185',
    fontSize: 11,
    bold: true,
    margin: [-5, 10, 0, 0],
  },
}
