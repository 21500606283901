import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.match.js";
export default defineComponent({
  name: 'ImpactAssessmentDetails',
  props: {
    toggled: Boolean,
    outcome: Object
  },
  data: function data() {
    return {
      cols: [{
        name: 'Problems addressed',
        component: 'impact-cell-problems-addressed',
        toggle: false
      }, {
        name: 'Link to value chain',
        component: 'impact-cell-causes-of-outcome',
        toggle: false
      }, {
        name: 'Theory of change',
        component: 'impact-cell-theory-of-change',
        toggle: false
      }, {
        name: 'SDGs',
        component: 'impact-cell-sdgs',
        toggle: true
      }, {
        name: 'IRIS Impact categories',
        component: 'impact-cell-iris-impact-categories',
        toggle: true
      }, {
        name: 'Target geographies',
        component: 'impact-cell-target-geographies',
        toggle: false
      }, {
        name: 'Number of affected stakeholders',
        component: 'impact-cell-number-of-stakeholders',
        toggle: false
      }, {
        name: 'Outcome forecast',
        component: 'impact-cell-outcome-forecast',
        toggle: false
      }]
    };
  },
  computed: {
    assessment: function assessment() {
      return this.$store.getters['assessment/getAssessment'];
    },
    geographyItems: function geographyItems() {
      return geographies;
    }
  },
  methods: {
    findGeographyNames: function findGeographyNames(recordIds) {
      var _this = this;
      if (!recordIds) {
        return [];
      }
      return recordIds.map(function (recordId) {
        var found = _this.geographyItems.find(function (geography) {
          return geography.id === recordId;
        });
        if (found) return found.name;
        return '';
      });
    },
    sortSDGs: function sortSDGs(sdgList) {
      return _toConsumableArray(sdgList).sort(function (a, b) {
        return parseInt(a.match(/(\d+)/)[1]) > parseInt(b.match(/(\d+)/)[1]) ? 1 : -1;
      });
    }
  }
});