var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('impactGoal.outro.afterSubmit.feedback.title')))]),_vm._v(" "),_c('ix-section-text',[_vm._v("\n                "+_vm._s(_vm.$t('impactGoal.outro.afterSubmit.feedback.description'))+"\n              ")]),_vm._v(" "),_c('ix-section-text',[_c('ix-form-input-question',{attrs:{"title":_vm.$t('impactGoal.outro.afterSubmit.feedback.rating.title'),"is-mandatory":""}}),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-4"},[_c('span',{staticClass:"grey--text text--lighten text-caption mr-2"},[_vm._v("\n                    ("+_vm._s(_vm.$t(
                        'impactGoal.outro.afterSubmit.feedback.rating.descriptions',
                      )[_vm.feedback.rating])+")\n                  ")]),_vm._v(" "),_c('v-rating',{staticClass:"ma-2 text-center",attrs:{"item-label-position":"top","large":""},model:{value:(_vm.feedback.rating),callback:function ($$v) {_vm.$set(_vm.feedback, "rating", $$v)},expression:"feedback.rating"}})],1),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":_vm.$t('impactGoal.outro.afterSubmit.feedback.comment.title'),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"label":_vm.$t('impactGoal.outro.afterSubmit.feedback.comment.label'),"outlined":"","auto-grow":"","row-height":"2","hide-details":""},model:{value:(_vm.feedback.description),callback:function ($$v) {_vm.$set(_vm.feedback, "description", $$v)},expression:"feedback.description"}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-button-action',{on:{"click":_vm.submitFeedback}},[_vm._v("\n                      "+_vm._s(_vm.$t('general.submitFeedbackButton'))+"\n                    ")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('impactGoal.outro.afterSubmit.title')))]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                    _vm.renderMarkdown(
                      _vm.$t('impactGoal.outro.afterSubmit.description'),
                    )
                  )}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-button-action',{attrs:{"nuxt":"","to":`/${_vm.ventureId}/impact`}},[_vm._v("\n                      "+_vm._s(_vm.$t('impactGoal.outro.afterSubmit.toImpactTab'))+"\n                    ")]),_vm._v(" "),_c('ix-button-action',{attrs:{"nuxt":"","to":`/${_vm.ventureId}/task`}},[_vm._v("\n                      "+_vm._s(_vm.$t('impactGoal.outro.afterSubmit.toTaskTab'))+"\n                    ")]),_vm._v(" "),_c('ix-button-action',{attrs:{"nuxt":"","to":`/${_vm.ventureId}/metrics`}},[_vm._v("\n                      "+_vm._s(_vm.$t('impactGoal.outro.afterSubmit.toKPITab'))+"\n                    ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }