import { renderMarkdown } from '~/functions/markdown';
import { useListUniqueIdematUnitsAndCategoriesQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _useListUniqueIdematU = useListUniqueIdematUnitsAndCategoriesQuery(),
      listUniqueIdematUnitsAndCategoriesResult = _useListUniqueIdematU.result;
    var unitOptions = computed(function () {
      var _listUniqueIdematUnit, _listUniqueIdematUnit2;
      return (listUniqueIdematUnitsAndCategoriesResult === null || listUniqueIdematUnitsAndCategoriesResult === void 0 ? void 0 : (_listUniqueIdematUnit = listUniqueIdematUnitsAndCategoriesResult.value) === null || _listUniqueIdematUnit === void 0 ? void 0 : (_listUniqueIdematUnit2 = _listUniqueIdematUnit.listUniqueIdematUnitsAndCategories) === null || _listUniqueIdematUnit2 === void 0 ? void 0 : _listUniqueIdematUnit2.units) || [];
    });
    var categoryOptions = computed(function () {
      var _listUniqueIdematUnit3, _listUniqueIdematUnit4;
      return (listUniqueIdematUnitsAndCategoriesResult === null || listUniqueIdematUnitsAndCategoriesResult === void 0 ? void 0 : (_listUniqueIdematUnit3 = listUniqueIdematUnitsAndCategoriesResult.value) === null || _listUniqueIdematUnit3 === void 0 ? void 0 : (_listUniqueIdematUnit4 = _listUniqueIdematUnit3.listUniqueIdematUnitsAndCategories) === null || _listUniqueIdematUnit4 === void 0 ? void 0 : _listUniqueIdematUnit4.categories) || [];
    });
    var input = ref({
      keyword: '',
      units: [],
      categories: []
    });
    return {
      unitOptions: unitOptions,
      categoryOptions: categoryOptions,
      input: input
    };
  },
  data: function data() {
    return {
      renderMarkdown: renderMarkdown
    };
  }
});