import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useMutation } from '@vue/apollo-composable';
import SUBMIT_QUESTIONNAIRE from '~/graphql/mutations/impact-goal/SubmitEuAlignmentQuestionnaire.gql';
export default defineComponent({
  props: {
    questionnaireAssignment: {
      type: Object,
      required: true
    },
    questionnaireTemplate: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var _useMutation = useMutation(SUBMIT_QUESTIONNAIRE),
      submitEuAlignmentQuestionnaire = _useMutation.mutate,
      submitLoading = _useMutation.loading;
    return {
      submitEuAlignmentQuestionnaire: submitEuAlignmentQuestionnaire,
      submitLoading: submitLoading
    };
  },
  data: function data() {
    return {
      step: 1
    };
  },
  computed: {
    sectionResults: function sectionResults() {
      var _this$questionnaireTe;
      var sectionIds = (_this$questionnaireTe = this.questionnaireTemplate.sections) === null || _this$questionnaireTe === void 0 ? void 0 : _this$questionnaireTe.map(function (e) {
        return e.id;
      });
      return this.questionnaireAssignment.questionnaireResults.filter(function (e) {
        return sectionIds === null || sectionIds === void 0 ? void 0 : sectionIds.includes(e.sectionId);
      });
    },
    allQuestionsAnswered: function allQuestionsAnswered() {
      return this.questionnaireAssignment.sectionCompletion.every(function (item) {
        return item.isCompleted;
      });
    },
    isSubmittable: function isSubmittable() {
      return this.questionnaireAssignment.isEditable && this.allQuestionsAnswered;
    }
  },
  methods: {
    triggerSubmit: function triggerSubmit(isEditable) {
      this.submitEuAlignmentQuestionnaire({
        questionnaireId: this.questionnaireAssignment.id,
        isEditable: isEditable
      });
    }
  }
});