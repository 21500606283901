import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.number.constructor.js";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { useMutation } from '@vue/apollo-composable';
import { renderMarkdown } from '~/functions/markdown';
import UPDATE_IMPACT_GOAL from '~/graphql/mutations/impact-goal/UpdateImpactGoal.gql';
import { useListConceptsQuery } from '~/types/types';
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var activityIds = computed(function () {
      var _props$impactGoal, _props$impactGoal$bus, _props$impactGoal$bus2;
      return ((_props$impactGoal = props.impactGoal) === null || _props$impactGoal === void 0 ? void 0 : (_props$impactGoal$bus = _props$impactGoal.businessActivities) === null || _props$impactGoal$bus === void 0 ? void 0 : (_props$impactGoal$bus2 = _props$impactGoal$bus.map(function (e) {
        return e.id;
      })) === null || _props$impactGoal$bus2 === void 0 ? void 0 : _props$impactGoal$bus2.filter(Boolean)) || [];
    } // remove falsy
    );
    var _useMutation = useMutation(UPDATE_IMPACT_GOAL),
      updateImpactGoal = _useMutation.mutate;
    var _useListConceptsQuery = useListConceptsQuery({
        taxonomy: 'EU Taxonomy',
        kind: 'COMPANY',
        limit: 120,
        offset: 0
      }),
      companyConceptsResult = _useListConceptsQuery.result;
    var fundImpactConceptIds = computed(function () {
      var _props$impactGoal2, _props$impactGoal2$fu;
      return ((_props$impactGoal2 = props.impactGoal) === null || _props$impactGoal2 === void 0 ? void 0 : (_props$impactGoal2$fu = _props$impactGoal2.fundSustainableObjectives) === null || _props$impactGoal2$fu === void 0 ? void 0 : _props$impactGoal2$fu.map(function (e) {
        var _e$concept;
        return (_e$concept = e.concept) === null || _e$concept === void 0 ? void 0 : _e$concept.id;
      }).filter(Boolean)) || [];
    });
    function contributesToFundObjective(concept) {
      return concept.objectLinks.map(function (e) {
        return e.object;
      }).some(function (impact) {
        var _fundImpactConceptIds;
        return (_fundImpactConceptIds = fundImpactConceptIds.value) === null || _fundImpactConceptIds === void 0 ? void 0 : _fundImpactConceptIds.includes(impact.id);
      });
    }
    var businessActivityOptions = computed(function () {
      var _filter, _companyConceptsResul, _companyConceptsResul2;
      return (_filter = (_companyConceptsResul = companyConceptsResult.value) === null || _companyConceptsResul === void 0 ? void 0 : (_companyConceptsResul2 = _companyConceptsResul.listConcepts) === null || _companyConceptsResul2 === void 0 ? void 0 : _companyConceptsResul2.filter(contributesToFundObjective)) !== null && _filter !== void 0 ? _filter : [];
    });
    var recommendedActivitiesOptions = ref([]);
    return {
      ventureId: ventureId,
      businessActivityOptions: businessActivityOptions,
      recommendedActivitiesOptions: recommendedActivitiesOptions,
      activityIds: activityIds,
      updateImpactGoal: updateImpactGoal
    };
  },
  data: function data() {
    return {
      recommendedActivityIds: []
    };
  },
  methods: {
    renderMarkdown: renderMarkdown,
    triggerUpdateImpactGoal: function triggerUpdateImpactGoal(key, val) {
      var input = {
        id: this.$props.impactGoal.id
      };
      input[key] = val;
      this.updateImpactGoal({
        input: input
      });
    },
    handleUpdateRecommendedActivities: function handleUpdateRecommendedActivities(val) {
      var ids = val.map(function (e) {
        return Number(e.id);
      });
      this.recommendedActivitiesOptions = this.businessActivityOptions.filter(function (e) {
        return e.id && ids.includes(e.id);
      });
    }
  }
});