import { useQueryLoading } from '@vue/apollo-composable';
export default defineComponent({
  props: {},
  setup: function setup() {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var loading = useQueryLoading();
    return {
      ventureId: ventureId,
      loading: loading
    };
  },
  methods: {}
});