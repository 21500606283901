export default defineComponent({
  name: 'SetupOutcomeSelection',
  props: {
    assessment: Object,
    currentUserRole: String,
    assessmentStatus: String,
    stages: Array
  },
  setup: function setup() {
    var dialog11 = ref(false);
    var dialog12 = ref(false);
    var dialog2 = ref(false);
    return {
      dialog11: dialog11,
      dialog12: dialog12,
      dialog2: dialog2
    };
  }
});