export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    return {};
  }
});