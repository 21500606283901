export default defineComponent({
  props: {
    selected: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    sortKeys: {
      type: Array,
      required: true
    },
    hasBatchActions: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      selectedGroup: this.selected,
      group: null,
      sort: 'venture',
      search: '',
      sortByDesc: false
    };
  },
  computed: {
    getGroupLabel: function getGroupLabel() {
      return this.group === 'groupNames' ? this.$t('grouped') : this.$t('unGrouped');
    }
  },
  watch: {
    selected: function selected(value) {
      this.selectedGroup = value;
    }
  },
  methods: {
    groupByBatch: function groupByBatch() {
      this.group = this.group ? 'groupNames' : null;
      this.$emit('group', this.group);
    }
  }
});