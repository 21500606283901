export const state = () => ({
  list: [],
})

const SET_FEEDBACK = 'SET_FEEDBACK'
const REMOVE_FEEDBACK = 'REMOVE_FEEDBACK'

export const getters = {
  getFeedbackItems(state) {
    return state.list
  },
}

export const mutations = {
  [SET_FEEDBACK](state, payload) {
    console.log('working')
    const { type, message, timeout } = payload
    state.list.push({
      type,
      message,
      timeout: timeout ? timeout : 3000,
    })
  },
  [REMOVE_FEEDBACK](state, payload) {
    state.list = []
  },
}

export const actions = {
  setFeedback({ commit }, payload) {
    commit(SET_FEEDBACK, payload)

    if (payload.timeout === undefined) {
      setTimeout(() => {
        commit(REMOVE_FEEDBACK)
      }, 3000)
    } else if (typeof payload.timeout === 'number') {
      setTimeout(() => {
        commit(REMOVE_FEEDBACK)
      }, payload.timeout)
    }
  },
  removeFeedback({ commit }, payload) {
    commit(REMOVE_FEEDBACK, payload)
  },
}
