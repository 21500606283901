import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.replace.js";
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import HardBreak from '@tiptap/extension-hard-break';
import MentionList from './tiptapMentionList.vue';
import LIST_TABLE_MODELS_QUERY from '~/graphql/queries/ListTableModels.gql';
import { useValidateTemplateTextQuery } from '~/types/types';
import { renderMarkdown } from '~/functions/markdown';
import { replaceBraces } from '~/functions/helpers';
export default defineComponent({
  components: {
    EditorContent: EditorContent
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var _nuxt2Context$app,
      _nuxt2Context$app$apo,
      _this = this;
    var emit = _ref.emit;
    var editor = ref(null);
    var limit = ref(200);
    watch(function () {
      return props.value;
    }, function () {
      var _editor$value, _editor$value$command;
      (_editor$value = editor.value) === null || _editor$value === void 0 ? void 0 : (_editor$value$command = _editor$value.commands) === null || _editor$value$command === void 0 ? void 0 : _editor$value$command.setContent(props.value);
    });
    var supportedObjects = {
      // aligned with the variables passed into jinja_template.render in the API
      fund: {
        type: 'FUND OBJECT (fund)',
        description: 'Fund object'
      },
      social_metrics: {
        type: 'LIST OF METRIC OBJECTS (metric[])',
        description: 'List of additional social metrics considered during PAIs'
      },
      environmental_metrics: {
        type: 'LIST OF METRIC OBJECTS (metric[])',
        description: 'List of additional environmental metrics considered during PAIs'
      },
      sustainable_objectives: {
        type: 'LIST OF SFDR STRATEGY OBJECTS (sfdrstrategy[])',
        description: 'List of sustainable objectives excluding ES characteristic'
      },
      es_characteristic: {
        type: 'SFDR STRATEGY OBJECT (sfdrstrategy)',
        description: 'ES characteristic object'
      },
      account: {
        type: 'ACCOUNT OBJECT (account)',
        description: 'The account object that owns the fund'
      },
      ventures_with_sustainable_objective: {
        type: 'LIST OF ACCOUNT OBJECTS (account[])',
        description: 'List of venture accounts that are in the fund AND a sustainable objective'
      },
      sustainable_investments_by_value_and_objective: {
        type: 'STRING',
        description: "Special string. See the article 9 question: 'The company names, investment amount and percentage of total investment made...'"
      }
    };
    var tooltip = '<strong>To insert a line break, press Shift + Enter on Windows and Cmd + Enter on Mac.</strong>';
    tooltip += '<br><br>The following objects are supported. Take note of the type and description of each object:<br><br>';
    tooltip += '<ul>';
    Object.entries(supportedObjects).forEach(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        _ref3$ = _ref3[1],
        type = _ref3$.type,
        description = _ref3$.description;
      tooltip += "<li>".concat(key, "<ul><li>Type: ").concat(type, "</li><li>Description: ").concat(description, "</li></ul></li>");
    });
    tooltip += '</ul>';
    tooltip += '<br>To use an objects, type "{{" to access the supported fields.';
    tooltip += '<br><br>For LISTS, access the nth element (eg: "{{ social_metrics[0] }}").';
    tooltip += '<br><br>To loop over the list, see the following example:';
    tooltip += '<br><br><pre>';
    tooltip += '{% for sustainable_objective in sustainable_objectives -%}\n';
    tooltip += "Regarding '{{ sustainable_objective.concept.label }}':\n";
    tooltip += '{% endfor %}';
    tooltip += '</pre>';
    tooltip += '<br><br>Similarly, for if statements:';
    tooltip += '<br><br><pre>';
    tooltip += '{% if fund.is_reporting_pais -%}\n';
    tooltip += 'The fund reports pais\n';
    tooltip += '{% endif %}';
    tooltip += '</pre>';
    tooltip += '<br><br>For more information, see the <a href="https://ttl255.com/jinja2-tutorial-part-2-loops-and-conditionals/" target="_blank">Jinja documentation</a>';
    var _useNuxtApp = useNuxtApp(),
      nuxt2Context = _useNuxtApp.nuxt2Context;
    var apolloClient = nuxt2Context === null || nuxt2Context === void 0 ? void 0 : (_nuxt2Context$app = nuxt2Context.app) === null || _nuxt2Context$app === void 0 ? void 0 : (_nuxt2Context$app$apo = _nuxt2Context$app.apolloProvider) === null || _nuxt2Context$app$apo === void 0 ? void 0 : _nuxt2Context$app$apo.defaultClient;
    var _useValidateTemplateT = useValidateTemplateTextQuery({
        templateText: props.value
      }),
      validateResult = _useValidateTemplateT.result,
      refetchValidation = _useValidateTemplateT.refetch;
    var validatedText = computed(function () {
      var _validateResult$value;
      return (_validateResult$value = validateResult.value) === null || _validateResult$value === void 0 ? void 0 : _validateResult$value.validateTemplateText;
    });
    var localQuery = ref('');
    var showItems = ref(false);
    var tableNames = ['fund', 'sfdrstrategy', 'account', 'metric', 'concept'];
    var mentionItems = function mentionItems(_ref4) {
      var q = _ref4.query;
      return apolloClient.query({
        query: LIST_TABLE_MODELS_QUERY,
        variables: {
          tableNames: tableNames,
          query: q
        }
      }).then(function (_ref5) {
        var _data$listTableModels;
        var data = _ref5.data;
        return data === null || data === void 0 ? void 0 : (_data$listTableModels = data.listTableModels) === null || _data$listTableModels === void 0 ? void 0 : _data$listTableModels.map(function (path) {
          return {
            id: path.path,
            name: " ".concat(path.path, " }}")
          };
        });
      }).catch(function (error) {
        throw error;
      });
    };
    var localItems = ref([]);
    watchEffect(function () {
      mentionItems({
        query: localQuery.value
      }).then(function (items) {
        localItems.value = items; // Update the reactive property with fetched items
      }).catch(function (error) {
        console.error('Failed to fetch items:', error);
        localItems.value = []; // Reset or handle error state appropriately
      });
    });
    watch(function () {
      var _validatedText$value;
      return (_validatedText$value = validatedText.value) === null || _validatedText$value === void 0 ? void 0 : _validatedText$value.error;
    }, function () {
      var _validatedText$value2;
      if ((_validatedText$value2 = validatedText.value) !== null && _validatedText$value2 !== void 0 && _validatedText$value2.error) {
        emit('toggle-disable-save', true);
      } else {
        emit('toggle-disable-save', false);
      }
    });
    onMounted(function () {
      editor.value = new Editor({
        onCreate: function onCreate(_ref6) {
          var editor = _ref6.editor;
          var initialValue = props.value.replace(/\n/g, '<br>');
          editor.commands.setContent(initialValue, false, {
            preserveWhitespace: 'full'
          });
          editor.commands.setContent(props.value);
        },
        onUpdate: function onUpdate(_ref7) {
          var editor = _ref7.editor;
          // Triggered on each keystroke
          var html = editor.getHTML(); // editor.getText() removes line breaks

          var text = html.replace(/<p><\/p>/g, '\n').replace(/<p>/, '').replace(/<\/p>/, '').replace(/<span class="mention">/g, '') // removes the border from the mention
          .replace(/<\/span>/g, '');
          emit('input', text);
          setTimeout(function () {
            refetchValidation({
              templateText: text
            });
          }, 2000);
        },
        onBlur: function onBlur(_ref8) {
          var editor = _ref8.editor;
          emit('blur', editor.getText());
        },
        extensions: [Document, Paragraph, Text, HardBreak, Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          suggestion: {
            // https://tiptap.dev/docs/editor/api/utilities/suggestion
            allowSpaces: false,
            char: '{{',
            items: function items(query) {
              localQuery.value = query.query;
              return mentionItems(query);
            },
            render: function render() {
              var component;
              var popup;
              return {
                onStart: function onStart(props) {
                  component = new VueRenderer(MentionList, {
                    parent: _this,
                    propsData: props
                  });
                  if (!props.clientRect) {
                    return;
                  }
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: function appendTo() {
                      return document.body;
                    },
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                  });
                },
                onUpdate: function onUpdate(props) {
                  var _component;
                  (_component = component) === null || _component === void 0 ? void 0 : _component.updateProps(props);
                  if (!props.clientRect) {
                    return;
                  }
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                  });
                },
                onKeyDown: function onKeyDown(props) {
                  if (props.event.key === 'Escape') {
                    popup[0].hide();
                    return true;
                  }
                  return component.ref.onKeyDown(props);
                },
                onExit: function onExit() {
                  popup[0].destroy();
                  component.destroy();
                }
              };
            }
          }
        })]
      });
    });
    onBeforeUnmount(function () {
      editor.value.destroy();
    });
    return {
      editor: editor,
      limit: limit,
      tooltip: tooltip,
      validatedText: validatedText,
      renderMarkdown: renderMarkdown,
      mentionItems: mentionItems,
      localQuery: localQuery,
      showItems: showItems,
      localItems: localItems,
      replaceBraces: replaceBraces
    };
  },
  computed: {
    percentage: function percentage() {
      return Math.round(100 / this.limit * this.editor.storage.characterCount.characters());
    }
  }
});