import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    nonCopyableText: {
      type: String,
      default: ''
    }
  },
  setup: function setup(_, context) {
    var replaceText = ref('');
    var overlay = ref(false);
    var doNotShowAgain = ref(JSON.parse(localStorage.getItem('doNotShow.copyText') || 'false'));
    watch(doNotShowAgain, function (val) {
      localStorage.setItem('doNotShow.copyText', JSON.stringify(val));
    });
    var onNo = function onNo() {
      overlay.value = false;
    };
    var onYes = function onYes() {
      overlay.value = false;
      context.emit('copy', replaceText.value);
    };
    var useThisText = function useThisText(val) {
      var doNotShowAgainValue = JSON.parse(localStorage.getItem('doNotShow.copyText') || 'false');
      if (doNotShowAgainValue) {
        context.emit('copy', val);
      } else {
        overlay.value = true;
        replaceText.value = val; // remember the text to replace
      }
    };
    return {
      overlay: overlay,
      tab: ref(null),
      renderMarkdown: renderMarkdown,
      onNo: onNo,
      onYes: onYes,
      useThisText: useThisText,
      doNotShowAgain: doNotShowAgain
    };
  }
});