import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es7.string.pad-start.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es7.object.entries.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.user.isAdmin ? _c('div', [_c('ix-header-portfolio'), _vm._v(" "), _c('v-data-table', {
    staticClass: "flex-grow-1",
    attrs: {
      "multi-sort": "",
      "fixed-header": "",
      "height": 'calc(100vh - 210px)',
      "locale": _setup.$i18n.locale,
      "loading": _setup.loading,
      "items": _setup.items,
      "headers": _setup.headers.map(function (h) {
        return _objectSpread(_objectSpread({}, h), {}, {
          text: _vm.$t(h.value)
        });
      }),
      "options": _setup.options,
      "server-items-length": _setup.itemCount,
      "sort-by": "name",
      "footer-props": {
        'items-per-page-options': [15, 30, 50, -1]
      },
      "items-per-page": _setup.options.itemsPerPage,
      "item-key": "id",
      "show-select": "",
      "show-expand": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _setup.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header.taxonomy",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var _setup$assignedToFilt;
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [(_setup$assignedToFilt = _setup.assignedToFilters) !== null && _setup$assignedToFilt !== void 0 && _setup$assignedToFilt.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.assignedToFilters.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('ix-data-table-taxonomy', {
          model: {
            value: _setup.taxonomiesFilters,
            callback: function callback($$v) {
              _setup.taxonomiesFilters = $$v;
            },
            expression: "taxonomiesFilters"
          }
        })], 1)];
      }
    }, {
      key: "header.assignedTo",
      fn: function fn(_ref3) {
        var header = _ref3.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var _setup$assignedToFilt2;
              var on = _ref4.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [(_setup$assignedToFilt2 = _setup.assignedToFilters) !== null && _setup$assignedToFilt2 !== void 0 && _setup$assignedToFilt2.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.assignedToFilters.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.assignedToFilters,
            callback: function callback($$v) {
              _setup.assignedToFilters = $$v;
            },
            expression: "assignedToFilters"
          }
        }, _vm._l([_setup.TypeMetricAssignedTo.Fund, _setup.TypeMetricAssignedTo.Company, _setup.TypeMetricAssignedTo.FundCompany, _setup.TypeMetricAssignedTo.FundMgmt, null], function (item) {
          return _c('v-list-item', {
            key: item,
            attrs: {
              "value": item
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref5) {
                var active = _ref5.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "header.sections",
      fn: function fn(_ref6) {
        var header = _ref6.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": "",
            "content-class": "bg-white"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [_setup.sectionsFilters && _setup.sectionsFilters.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.sectionsFilters.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('ix-data-table-sections', {
          staticClass: "max-w-[680px]",
          attrs: {
            "height": 'calc(100vh - 500px)',
            "show-select": true,
            "columns": ['id', 'labelI18n.en', 'labelI18n.de'],
            "default-filters": {
              linkedToMetrics: true
            }
          },
          model: {
            value: _setup.sectionsFilters,
            callback: function callback($$v) {
              _setup.sectionsFilters = $$v;
            },
            expression: "sectionsFilters"
          }
        })], 1)];
      }
    }, {
      key: "header.isComputed",
      fn: function fn(_ref8) {
        var header = _ref8.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": "",
            "content-class": "bg-white"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [_setup.isComputedFilter.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.isComputedFilter
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', {
          staticClass: "h-[100px]"
        }, [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.isComputedFilter,
            callback: function callback($$v) {
              _setup.isComputedFilter = $$v;
            },
            expression: "isComputedFilter"
          }
        }, _vm._l([true, false], function (item) {
          return _c('v-list-item', {
            key: item,
            attrs: {
              "value": item
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref10) {
                var active = _ref10.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "top",
      fn: function fn() {
        var _vm$$route$query;
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("Metrics / KPIs")]), _vm._v(" "), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('v-text-field', {
          staticClass: "mx-4",
          attrs: {
            "value": (_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.search,
            "append-icon": "mdi-magnify",
            "label": "Search",
            "single-line": "",
            "hide-details": ""
          },
          on: {
            "input": function input(v) {
              _vm.$router.push({
                query: {
                  search: v
                }
              });
            }
          }
        }), _vm._v(" "), _c('v-dialog', {
          attrs: {
            "max-width": "50%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on,
                attrs = _ref11.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "dark": ""
                },
                on: {
                  "click": function click() {
                    _setup.newItem = {
                      nameI18n: {},
                      descriptionI18n: {},
                      usageGuidanceI18n: {}
                    };
                    _setup.createDialog = true;
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v("\n              " + _vm._s(_vm.$t('add')) + "\n            ")])];
            }
          }], null, false, 1332339453),
          model: {
            value: _setup.createDialog,
            callback: function callback($$v) {
              _setup.createDialog = $$v;
            },
            expression: "createDialog"
          }
        }, [_vm._v(" "), _c('ix-kpi-edit', {
          attrs: {
            "item": _setup.newItem
          },
          on: {
            "input": function input(v) {
              return _setup.createDialog = v;
            }
          }
        })], 1), _vm._v(" "), _c('v-btn', {
          staticClass: "mb-2",
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _setup.refetch
          }
        }, [_vm._v("\n          " + _vm._s(_vm.$t('refresh')) + "\n        ")])], 1)];
      },
      proxy: true
    }, {
      key: "item.id",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(_vm._s(item.id))];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref13) {
        var headers = _ref13.headers,
          item = _ref13.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [item.id ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('ix-data-table-metrics', {
          attrs: {
            "height": '300px',
            "show-selected": false,
            "show-filters": false,
            "allow-edit": false,
            "allow-custom": false,
            "show-action": false,
            "query": {
              parentIds: [item.id],
              sections: [117,
              // 'IRIS Indicators',
              90,
              // 'PAI company data'
              967,
              // 'ImpactNexus Indicators',
              989,
              // 'EU taxonomy',
              998,
              // 'EDCI Metrics',
              82,
              // 'SFDR: PAI Indicators',
              1101 // 'KfW Capital: Indicators',
              ]
            }
          }
        })], 1), _vm._v(" "), _c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('ix-data-table-audit-logs', {
          staticClass: "max-h-[400px] overflow-scroll",
          attrs: {
            "columns": ['diff', 'operationType', 'createdBy', 'createdAt'],
            "default-filters": {
              tableName: ['metric'],
              dataId: '00000000-0000-0000-0000-' + item.id.toString(16).padStart(12, '0')
            }
          }
        })], 1)], 1) : _vm._e()], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('metrics-cells-metric-name', {
          attrs: {
            "id": item.id,
            "name": item.name,
            "is-computed": item.isComputed,
            "metric-assignment": item,
            "search": _vm.$route.query.search
          }
        })];
      }
    }, {
      key: "item.valueOptions",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm._l((item.valueOptions && item.valueOptions.options || []).slice(0, 2), function (option) {
          return _c('v-chip', {
            key: option,
            staticClass: "max-w-[85px] inline-block",
            attrs: {
              "outlined": "",
              "label": ""
            }
          }, [_c('v-tooltip', {
            attrs: {
              "top": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref16) {
                var on = _ref16.on,
                  attrs = _ref16.attrs;
                return [_c('span', _vm._g(_vm._b({
                  staticClass: "text-truncate"
                }, 'span', attrs, false), on), [_vm._v("\n              " + _vm._s(option.text || option) + "\n            ")])];
              }
            }], null, true)
          }, [_vm._v(" "), _c('span', [_vm._v("\n            " + _vm._s(option.text || option) + "\n          ")])])], 1);
        }), _vm._v(" "), (item.valueOptions && item.valueOptions.options || []).length > 2 ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref17) {
              var on = _ref17.on,
                attrs = _ref17.attrs;
              return [_c('span', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'span', attrs, false), on), [_vm._v("...")])];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.valueOptions.options || [], function (option) {
          return _c('v-chip', {
            key: option,
            attrs: {
              "outlined": "",
              "label": ""
            }
          }, [_vm._v("\n          " + _vm._s(option.text || option) + "\n        ")]);
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.sections",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_vm._l((item.sections || []).slice(0, 2), function (section) {
          return _c('v-chip', {
            key: section.id,
            attrs: {
              "outlined": "",
              "label": ""
            }
          }, [_vm._v("\n        " + _vm._s(section.label) + "\n      ")]);
        }), _vm._v(" "), (item.sections || []).length > 2 ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref19) {
              var on = _ref19.on,
                attrs = _ref19.attrs;
              return [_c('span', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'span', attrs, false), on), [_vm._v("...")])];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(item.sections || [], function (section) {
          return _c('v-chip', {
            key: section.id,
            attrs: {
              "outlined": "",
              "label": ""
            }
          }, [_vm._v("\n          " + _vm._s(section.label) + "\n        ")]);
        })], 2) : _vm._e()];
      }
    }, {
      key: "item.taxonomy",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.taxonomy ? _c('v-chip', {
          attrs: {
            "outlined": "",
            "label": ""
          }
        }, [_vm._v("\n        " + _vm._s(item.taxonomy.label) + "\n      ")]) : _vm._e()];
      }
    }, {
      key: "item.externalData",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return _vm._l(Object.entries(item.externalData || {}), function (_ref22) {
          var _ref23 = _slicedToArray(_ref22, 2),
            key = _ref23[0],
            val = _ref23[1];
          return _c('v-chip', {
            key: key,
            attrs: {
              "outlined": "",
              "label": ""
            }
          }, [_vm._v("\n        " + _vm._s(key) + ": " + _vm._s(val) + "\n      ")]);
        });
      }
    }, {
      key: "item.isComputed",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('v-checkbox', {
          attrs: {
            "disabled": "",
            "label": ""
          },
          model: {
            value: item.isComputed,
            callback: function callback($$v) {
              _vm.$set(item, "isComputed", $$v);
            },
            expression: "item.isComputed"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_c('v-layout', {
          staticClass: "flex-nowrap pa-4",
          attrs: {
            "row": ""
          }
        }, [item.external_url !== undefined ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref26) {
              var on = _ref26.on,
                attrs = _ref26.attrs;
              return [_c('ix-button-white', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "icon": "",
                  "href": item.external_url,
                  "target": "_blank"
                }
              }, 'ix-button-white', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": "blue-grey"
                }
              }, [_vm._v("mdi-information-variant ")])], 1)];
            }
          }], null, true)
        }, [_vm._v("\n          Go to detailed information at " + _vm._s(item.source.name) + ".\n        ")]) : _vm._e(), _vm._v(" "), _c('v-btn', {
          staticClass: "mb-2 custom-border-box",
          attrs: {
            "outlined": "",
            "icon": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-close ")])], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [_c('v-dialog', {
          attrs: {
            "scrollable": "",
            "value": _setup.editDialog[item.id],
            "max-width": "50%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref28) {
              var on = _ref28.on,
                attrs = _ref28.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click() {
                    _setup.editDialog[item.id] = true;
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("\n            mdi-pencil\n          ")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c('ix-kpi-edit', {
          attrs: {
            "item": item
          },
          on: {
            "input": function input(v) {
              _setup.setEditDialog(item.id, v);
            }
          }
        })], 1), _vm._v(" "), _c('v-dialog', {
          attrs: {
            "max-width": "70%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref29) {
              var on = _ref29.on,
                attrs = _ref29.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true),
          model: {
            value: _setup.deleteDialog,
            callback: function callback($$v) {
              _setup.deleteDialog = $$v;
            },
            expression: "deleteDialog"
          }
        }, [_vm._v(" "), _c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v("\n            Are you sure you want to delete this item?\n          ")]), _vm._v(" "), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _setup.closeDelete
          }
        }, [_vm._v("\n              Cancel\n            ")]), _vm._v(" "), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": "",
            "disabled": ""
          },
          on: {
            "click": function click($event) {
              return _setup.deleteItem(item);
            }
          }
        }, [_vm._v("\n              OK(WIP)\n            ")]), _vm._v(" "), _c('v-spacer')], 1)], 1)], 1)];
      }
    }], null, true),
    model: {
      value: _setup.selected,
      callback: function callback($$v) {
        _setup.selected = $$v;
      },
      expression: "selected"
    }
  })], 1) : !_setup.loading ? _c('div', [_vm._v("404")]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };