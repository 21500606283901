import { render, staticRenderFns } from "./CompanyButton.vue?vue&type=template&id=913f9d6c&scoped=true"
import script from "./CompanyButton.vue?vue&type=script&lang=ts"
export * from "./CompanyButton.vue?vue&type=script&lang=ts"
import style0 from "./CompanyButton.vue?vue&type=style&index=0&id=913f9d6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "913f9d6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompanyLogo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/company/CompanyLogo.vue').default})
