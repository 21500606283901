import { render, staticRenderFns } from "./IrisCategoriesInput.vue?vue&type=template&id=27097700"
import script from "./IrisCategoriesInput.vue?vue&type=script&lang=ts"
export * from "./IrisCategoriesInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxCustomSelect: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/CustomSelect.vue').default})
