export const geographies = [
  {
    label: 'World',
    id: 280,
    M49: '001',
    children: [
      {
        label: 'Africa',
        M49: '002',
        id: 1,
        children: [
          {
            label: 'Northern Africa',
            M49: '015',
            id: 2,
            children: [
              { label: 'Algeria', id: 3, M49: '012', 'ISO-alpha2': 'DZ' },
              { label: 'Egypt', id: 4, M49: '818', 'ISO-alpha2': 'EG' },
              { label: 'Libya', id: 5, M49: '434', 'ISO-alpha2': 'LY' },
              { label: 'Morocco', id: 6, M49: '504', 'ISO-alpha2': 'MA' },
              { label: 'Sudan', id: 7, M49: '729', 'ISO-alpha2': 'SD' },
              { label: 'Tunisia', id: 8, M49: '788', 'ISO-alpha2': 'TN' },
              {
                label: 'Western Sahara',
                id: 9,
                M49: '732',
                'ISO-alpha2': 'EH',
              },
            ],
          },
          {
            label: 'Sub-Saharan Africa',
            M49: '202',
            id: 10,
            children: [
              {
                label: 'British Indian Ocean Territory',
                id: 12,
                M49: '086',
                'ISO-alpha2': 'IO',
              },
              { label: 'Burundi', id: 13, M49: '108', 'ISO-alpha2': 'BI' },
              { label: 'Comoros', id: 14, M49: '174', 'ISO-alpha2': 'KM' },
              { label: 'Djibouti', id: 15, M49: '262', 'ISO-alpha2': 'DJ' },
              { label: 'Eritrea', id: 16, M49: '232', 'ISO-alpha2': 'ER' },
              { label: 'Ethiopia', id: 17, M49: '231', 'ISO-alpha2': 'ET' },
              {
                label: 'French Southern Territories',
                id: 18,
                M49: '260',
                'ISO-alpha2': 'TF',
              },
              { label: 'Kenya', id: 19, M49: '404', 'ISO-alpha2': 'KE' },
              { label: 'Madagascar', id: 20, M49: '450', 'ISO-alpha2': 'MG' },
              { label: 'Malawi', id: 21, M49: '454', 'ISO-alpha2': 'MW' },
              { label: 'Mauritius', id: 22, M49: '480', 'ISO-alpha2': 'MU' },
              { label: 'Mayotte', id: 23, M49: '175', 'ISO-alpha2': 'YT' },
              { label: 'Mozambique', id: 24, M49: '508', 'ISO-alpha2': 'MZ' },
              { label: 'Réunion', id: 25, M49: '638', 'ISO-alpha2': 'RE' },
              { label: 'Rwanda', id: 26, M49: '646', 'ISO-alpha2': 'RW' },
              { label: 'Seychelles', id: 27, M49: '690', 'ISO-alpha2': 'SC' },
              { label: 'Somalia', id: 28, M49: '706', 'ISO-alpha2': 'SO' },
              { label: 'South Sudan', id: 29, M49: '728', 'ISO-alpha2': 'SS' },
              { label: 'Uganda', id: 30, M49: '800', 'ISO-alpha2': 'UG' },
              {
                label: 'United Republic of Tanzania',
                id: 31,
                M49: '834',
                'ISO-alpha2': 'TZ',
              },
              { label: 'Zambia', id: 32, M49: '894', 'ISO-alpha2': 'ZM' },
              { label: 'Zimbabwe', id: 33, M49: '716', 'ISO-alpha2': 'ZW' },
              { label: 'Angola', id: 35, M49: '024', 'ISO-alpha2': 'AO' },
              { label: 'Cameroon', id: 36, M49: '120', 'ISO-alpha2': 'CM' },
              {
                label: 'Central African Republic',
                id: 37,
                M49: '140',
                'ISO-alpha2': 'CF',
              },
              { label: 'Chad', id: 38, M49: '148', 'ISO-alpha2': 'TD' },
              { label: 'Congo', id: 39, M49: '178', 'ISO-alpha2': 'CG' },
              {
                label: 'Democratic Republic of the Congo',
                id: 40,
                M49: '180',
                'ISO-alpha2': 'CD',
              },
              {
                label: 'Equatorial Guinea',
                id: 41,
                M49: '226',
                'ISO-alpha2': 'GQ',
              },
              { label: 'Gabon', id: 42, M49: '266', 'ISO-alpha2': 'GA' },
              {
                label: 'Sao Tome and Principe',
                id: 43,
                M49: '678',
                'ISO-alpha2': 'ST',
              },
              { label: 'Botswana', id: 45, M49: '072', 'ISO-alpha2': 'BW' },
              { label: 'Eswatini', id: 46, M49: '748', 'ISO-alpha2': 'SZ' },
              { label: 'Lesotho', id: 47, M49: '426', 'ISO-alpha2': 'LS' },
              { label: 'Namibia', id: 48, M49: '516', 'ISO-alpha2': 'NA' },
              { label: 'South Africa', id: 49, M49: '710', 'ISO-alpha2': 'ZA' },
              { label: 'Benin', id: 51, M49: '204', 'ISO-alpha2': 'BJ' },
              { label: 'Burkina Faso', id: 52, M49: '854', 'ISO-alpha2': 'BF' },
              { label: 'Cabo Verde', id: 53, M49: '132', 'ISO-alpha2': 'CV' },
              {
                label: 'Côte d’Ivoire',
                id: 54,
                M49: '384',
                'ISO-alpha2': 'CI',
              },
              { label: 'Gambia', id: 55, M49: '270', 'ISO-alpha2': 'GM' },
              { label: 'Ghana', id: 56, M49: '288', 'ISO-alpha2': 'GH' },
              { label: 'Guinea', id: 57, M49: '324', 'ISO-alpha2': 'GN' },
              {
                label: 'Guinea-Bissau',
                id: 58,
                M49: '624',
                'ISO-alpha2': 'GW',
              },
              { label: 'Liberia', id: 59, M49: '430', 'ISO-alpha2': 'LR' },
              { label: 'Mali', id: 60, M49: '466', 'ISO-alpha2': 'ML' },
              { label: 'Mauritania', id: 61, M49: '478', 'ISO-alpha2': 'MR' },
              { label: 'Niger', id: 62, M49: '562', 'ISO-alpha2': 'NE' },
              { label: 'Nigeria', id: 63, M49: '566', 'ISO-alpha2': 'NG' },
              { label: 'Saint Helena', id: 64, M49: '654', 'ISO-alpha2': 'SH' },
              { label: 'Senegal', id: 65, M49: '686', 'ISO-alpha2': 'SN' },
              { label: 'Sierra Leone', id: 66, M49: '694', 'ISO-alpha2': 'SL' },
              { label: 'Togo', id: 67, M49: '768', 'ISO-alpha2': 'TG' },
            ],
          },
        ],
      },
      {
        label: 'Americas',
        M49: '019',
        id: 68,
        children: [
          {
            label: 'Latin America and the Caribbean',
            M49: '419',
            id: 69,
            children: [
              { label: 'Anguilla', id: 71, M49: '660', 'ISO-alpha2': 'AI' },
              {
                label: 'Antigua and Barbuda',
                id: 72,
                M49: '028',
                'ISO-alpha2': 'AG',
              },
              { label: 'Aruba', id: 73, M49: '533', 'ISO-alpha2': 'AW' },
              { label: 'Bahamas', id: 74, M49: '044', 'ISO-alpha2': 'BS' },
              { label: 'Barbados', id: 75, M49: '052', 'ISO-alpha2': 'BB' },
              {
                label: 'Bonaire, Sint Eustatius and Saba',
                id: 76,
                M49: '535',
                'ISO-alpha2': 'BQ',
              },
              {
                label: 'British Virgin Islands',
                id: 77,
                M49: '092',
                'ISO-alpha2': 'VG',
              },
              {
                label: 'Cayman Islands',
                id: 78,
                M49: '136',
                'ISO-alpha2': 'KY',
              },
              { label: 'Cuba', id: 79, M49: '192', 'ISO-alpha2': 'CU' },
              { label: 'Curaçao', id: 80, M49: '531', 'ISO-alpha2': 'CW' },
              { label: 'Dominica', id: 81, M49: '212', 'ISO-alpha2': 'DM' },
              {
                label: 'Dominican Republic',
                id: 82,
                M49: '214',
                'ISO-alpha2': 'DO',
              },
              { label: 'Grenada', id: 83, M49: '308', 'ISO-alpha2': 'GD' },
              { label: 'Guadeloupe', id: 84, M49: '312', 'ISO-alpha2': 'GP' },
              { label: 'Haiti', id: 85, M49: '332', 'ISO-alpha2': 'HT' },
              { label: 'Jamaica', id: 86, M49: '388', 'ISO-alpha2': 'JM' },
              { label: 'Martinique', id: 87, M49: '474', 'ISO-alpha2': 'MQ' },
              { label: 'Montserrat', id: 88, M49: '500', 'ISO-alpha2': 'MS' },
              { label: 'Puerto Rico', id: 89, M49: '630', 'ISO-alpha2': 'PR' },
              {
                label: 'Saint Barthélemy',
                id: 90,
                M49: '652',
                'ISO-alpha2': 'BL',
              },
              {
                label: 'Saint Kitts and Nevis',
                id: 91,
                M49: '659',
                'ISO-alpha2': 'KN',
              },
              { label: 'Saint Lucia', id: 92, M49: '662', 'ISO-alpha2': 'LC' },
              {
                label: 'Saint Martin (French Part)',
                id: 93,
                M49: '663',
                'ISO-alpha2': 'MF',
              },
              {
                label: 'Saint Vincent and the Grenadines',
                id: 94,
                M49: '670',
                'ISO-alpha2': 'VC',
              },
              {
                label: 'Sint Maarten (Dutch part)',
                id: 95,
                M49: '534',
                'ISO-alpha2': 'SX',
              },
              {
                label: 'Trinidad and Tobago',
                id: 96,
                M49: '780',
                'ISO-alpha2': 'TT',
              },
              {
                label: 'Turks and Caicos Islands',
                id: 97,
                M49: '796',
                'ISO-alpha2': 'TC',
              },
              {
                label: 'United States Virgin Islands',
                id: 98,
                M49: '850',
                'ISO-alpha2': 'VI',
              },
              { label: 'Belize', id: 100, M49: '084', 'ISO-alpha2': 'BZ' },
              { label: 'Costa Rica', id: 101, M49: '188', 'ISO-alpha2': 'CR' },
              { label: 'El Salvador', id: 102, M49: '222', 'ISO-alpha2': 'SV' },
              { label: 'Guatemala', id: 103, M49: '320', 'ISO-alpha2': 'GT' },
              { label: 'Honduras', id: 104, M49: '340', 'ISO-alpha2': 'HN' },
              { label: 'Mexico', id: 105, M49: '484', 'ISO-alpha2': 'MX' },
              { label: 'Nicaragua', id: 106, M49: '558', 'ISO-alpha2': 'NI' },
              { label: 'Panama', id: 107, M49: '591', 'ISO-alpha2': 'PA' },
              { label: 'Argentina', id: 109, M49: '032', 'ISO-alpha2': 'AR' },
              {
                label: 'Bolivia (Plurinational State of)',
                id: 110,
                M49: '068',
                'ISO-alpha2': 'BO',
              },
              {
                label: 'Bouvet Island',
                id: 111,
                M49: '074',
                'ISO-alpha2': 'BV',
              },
              { label: 'Brazil', id: 112, M49: '076', 'ISO-alpha2': 'BR' },
              { label: 'Chile', id: 113, M49: '152', 'ISO-alpha2': 'CL' },
              { label: 'Colombia', id: 114, M49: '170', 'ISO-alpha2': 'CO' },
              { label: 'Ecuador', id: 115, M49: '218', 'ISO-alpha2': 'EC' },
              {
                label: 'Falkland Islands (Malvinas)',
                id: 116,
                M49: '238',
                'ISO-alpha2': 'FK',
              },
              {
                label: 'French Guiana',
                id: 117,
                M49: '254',
                'ISO-alpha2': 'GF',
              },
              { label: 'Guyana', id: 118, M49: '328', 'ISO-alpha2': 'GY' },
              { label: 'Paraguay', id: 119, M49: '600', 'ISO-alpha2': 'PY' },
              { label: 'Peru', id: 120, M49: '604', 'ISO-alpha2': 'PE' },
              {
                label: 'South Georgia and the South Sandwich Islands',
                id: 121,
                M49: '239',
                'ISO-alpha2': 'GS',
              },
              { label: 'Suriname', id: 122, M49: '740', 'ISO-alpha2': 'SR' },
              { label: 'Uruguay', id: 123, M49: '858', 'ISO-alpha2': 'UY' },
              {
                label: 'Venezuela (Bolivarian Republic of)',
                id: 124,
                M49: '862',
                'ISO-alpha2': 'VE',
              },
            ],
          },
          {
            label: 'Northern America',
            M49: '021',
            id: 125,
            children: [
              { label: 'Bermuda', id: 126, M49: '060', 'ISO-alpha2': 'BM' },
              { label: 'Canada', id: 127, M49: '124', 'ISO-alpha2': 'CA' },
              { label: 'Greenland', id: 128, M49: '304', 'ISO-alpha2': 'GL' },
              {
                label: 'Saint Pierre and Miquelon',
                id: 129,
                M49: '666',
                'ISO-alpha2': 'PM',
              },
              {
                label: 'United States of America',
                id: 130,
                M49: '840',
                'ISO-alpha2': 'US',
              },
            ],
          },
        ],
      },
      { label: 'Antarctica', M49: '010', id: 131, children: [] },
      {
        label: 'Asia',
        M49: '142',
        id: 132,
        children: [
          {
            label: 'Central Asia',
            M49: '143',
            id: 133,
            children: [
              { label: 'Kazakhstan', id: 134, M49: '398', 'ISO-alpha2': 'KZ' },
              { label: 'Kyrgyzstan', id: 135, M49: '417', 'ISO-alpha2': 'KG' },
              { label: 'Tajikistan', id: 136, M49: '762', 'ISO-alpha2': 'TJ' },
              {
                label: 'Turkmenistan',
                id: 137,
                M49: '795',
                'ISO-alpha2': 'TM',
              },
              { label: 'Uzbekistan', id: 138, M49: '860', 'ISO-alpha2': 'UZ' },
            ],
          },
          {
            label: 'Eastern Asia',
            M49: '030',
            id: 139,
            children: [
              { label: 'China', id: 140, M49: '156', 'ISO-alpha2': 'CN' },
              {
                label: 'China, Hong Kong Special Administrative Region',
                id: 141,
                M49: '344',
                'ISO-alpha2': 'HK',
              },
              {
                label: 'China, Macao Special Administrative Region',
                id: 142,
                M49: '446',
                'ISO-alpha2': 'MO',
              },
              {
                label: "Democratic People's Republic of Korea",
                id: 143,
                M49: '408',
                'ISO-alpha2': 'KP',
              },
              { label: 'Japan', id: 144, M49: '392', 'ISO-alpha2': 'JP' },
              { label: 'Mongolia', id: 145, M49: '496', 'ISO-alpha2': 'MN' },
              {
                label: 'Republic of Korea',
                id: 146,
                M49: '410',
                'ISO-alpha2': 'KR',
              },
            ],
          },
          {
            label: 'South-eastern Asia',
            M49: '035',
            id: 147,
            children: [
              {
                label: 'Brunei Darussalam',
                id: 148,
                M49: '096',
                'ISO-alpha2': 'BN',
              },
              { label: 'Cambodia', id: 149, M49: '116', 'ISO-alpha2': 'KH' },
              { label: 'Indonesia', id: 150, M49: '360', 'ISO-alpha2': 'ID' },
              {
                label: "Lao People's Democratic Republic",
                id: 151,
                M49: '418',
                'ISO-alpha2': 'LA',
              },
              { label: 'Malaysia', id: 152, M49: '458', 'ISO-alpha2': 'MY' },
              { label: 'Myanmar', id: 153, M49: '104', 'ISO-alpha2': 'MM' },
              { label: 'Philippines', id: 154, M49: '608', 'ISO-alpha2': 'PH' },
              { label: 'Singapore', id: 155, M49: '702', 'ISO-alpha2': 'SG' },
              { label: 'Thailand', id: 156, M49: '764', 'ISO-alpha2': 'TH' },
              { label: 'Timor-Leste', id: 157, M49: '626', 'ISO-alpha2': 'TL' },
              { label: 'Vietnam', id: 158, M49: '704', 'ISO-alpha2': 'VN' },
            ],
          },
          {
            label: 'Southern Asia',
            M49: '034',
            id: 159,
            children: [
              { label: 'Afghanistan', id: 160, M49: '004', 'ISO-alpha2': 'AF' },
              { label: 'Bangladesh', id: 161, M49: '050', 'ISO-alpha2': 'BD' },
              { label: 'Bhutan', id: 162, M49: '064', 'ISO-alpha2': 'BT' },
              { label: 'India', id: 163, M49: '356', 'ISO-alpha2': 'IN' },
              {
                label: 'Iran (Islamic Republic of)',
                id: 164,
                M49: '364',
                'ISO-alpha2': 'IR',
              },
              { label: 'Maldives', id: 165, M49: '462', 'ISO-alpha2': 'MV' },
              { label: 'Nepal', id: 166, M49: '524', 'ISO-alpha2': 'NP' },
              { label: 'Pakistan', id: 167, M49: '586', 'ISO-alpha2': 'PK' },
              { label: 'Sri Lanka', id: 168, M49: '144', 'ISO-alpha2': 'LK' },
            ],
          },
          {
            label: 'Western Asia',
            M49: '145',
            id: 169,
            children: [
              { label: 'Armenia', id: 170, M49: '051', 'ISO-alpha2': 'AM' },
              { label: 'Azerbaijan', id: 171, M49: '031', 'ISO-alpha2': 'AZ' },
              { label: 'Bahrain', id: 172, M49: '048', 'ISO-alpha2': 'BH' },
              { label: 'Cyprus', id: 173, M49: '196', 'ISO-alpha2': 'CY' },
              { label: 'Georgia', id: 174, M49: '268', 'ISO-alpha2': 'GE' },
              { label: 'Iraq', id: 175, M49: '368', 'ISO-alpha2': 'IQ' },
              { label: 'Israel', id: 176, M49: '376', 'ISO-alpha2': 'IL' },
              { label: 'Jordan', id: 177, M49: '400', 'ISO-alpha2': 'JO' },
              { label: 'Kuwait', id: 178, M49: '414', 'ISO-alpha2': 'KW' },
              { label: 'Lebanon', id: 179, M49: '422', 'ISO-alpha2': 'LB' },
              { label: 'Oman', id: 180, M49: '512', 'ISO-alpha2': 'OM' },
              { label: 'Qatar', id: 181, M49: '634', 'ISO-alpha2': 'QA' },
              {
                label: 'Saudi Arabia',
                id: 182,
                M49: '682',
                'ISO-alpha2': 'SA',
              },
              {
                label: 'State of Palestine',
                id: 183,
                M49: '275',
                'ISO-alpha2': 'PS',
              },
              {
                label: 'Syrian Arab Republic',
                id: 184,
                M49: '760',
                'ISO-alpha2': 'SY',
              },
              { label: 'Turkey', id: 185, M49: '792', 'ISO-alpha2': 'TR' },
              {
                label: 'United Arab Emirates',
                id: 186,
                M49: '784',
                'ISO-alpha2': 'AE',
              },
              { label: 'Yemen', id: 187, M49: '887', 'ISO-alpha2': 'YE' },
            ],
          },
        ],
      },
      {
        label: 'Europe',
        M49: '150',
        id: 188,
        children: [
          {
            label: 'Eastern Europe',
            M49: '151',
            id: 189,
            children: [
              { label: 'Belarus', id: 190, M49: '112', 'ISO-alpha2': 'BY' },
              { label: 'Bulgaria', id: 191, M49: '100', 'ISO-alpha2': 'BG' },
              { label: 'Czechia', id: 192, M49: '203', 'ISO-alpha2': 'CZ' },
              { label: 'Hungary', id: 193, M49: '348', 'ISO-alpha2': 'HU' },
              { label: 'Poland', id: 194, M49: '616', 'ISO-alpha2': 'PL' },
              {
                label: 'Republic of Moldova',
                id: 195,
                M49: '498',
                'ISO-alpha2': 'MD',
              },
              { label: 'Romania', id: 196, M49: '642', 'ISO-alpha2': 'RO' },
              {
                label: 'Russian Federation',
                id: 197,
                M49: '643',
                'ISO-alpha2': 'RU',
              },
              { label: 'Slovakia', id: 198, M49: '703', 'ISO-alpha2': 'SK' },
              { label: 'Ukraine', id: 199, M49: '804', 'ISO-alpha2': 'UA' },
            ],
          },
          {
            label: 'Northern Europe',
            M49: '154',
            id: 200,
            children: [
              {
                label: 'Åland Islands',
                id: 201,
                M49: '248',
                'ISO-alpha2': 'AX',
              },
              { label: 'Guernsey', id: 203, M49: '831', 'ISO-alpha2': 'GG' },
              { label: 'Jersey', id: 204, M49: '832', 'ISO-alpha2': 'JE' },
              { label: 'Sark', id: 205, M49: '680', 'ISO-alpha2': '' },
              { label: 'Denmark', id: 206, M49: '208', 'ISO-alpha2': 'DK' },
              { label: 'Estonia', id: 207, M49: '233', 'ISO-alpha2': 'EE' },
              {
                label: 'Faroe Islands',
                id: 208,
                M49: '234',
                'ISO-alpha2': 'FO',
              },
              { label: 'Finland', id: 209, M49: '246', 'ISO-alpha2': 'FI' },
              { label: 'Iceland', id: 210, M49: '352', 'ISO-alpha2': 'IS' },
              { label: 'Ireland', id: 211, M49: '372', 'ISO-alpha2': 'IE' },
              { label: 'Isle of Man', id: 212, M49: '833', 'ISO-alpha2': 'IM' },
              { label: 'Latvia', id: 213, M49: '428', 'ISO-alpha2': 'LV' },
              { label: 'Lithuania', id: 214, M49: '440', 'ISO-alpha2': 'LT' },
              { label: 'Norway', id: 215, M49: '578', 'ISO-alpha2': 'NO' },
              {
                label: 'Svalbard and Jan Mayen Islands',
                id: 216,
                M49: '744',
                'ISO-alpha2': 'SJ',
              },
              { label: 'Sweden', id: 217, M49: '752', 'ISO-alpha2': 'SE' },
              {
                label: 'United Kingdom of Great Britain and Northern Ireland',
                id: 218,
                M49: '826',
                'ISO-alpha2': 'GB',
              },
            ],
          },
          {
            label: 'Southern Europe',
            M49: '039',
            id: 219,
            children: [
              { label: 'Albania', id: 220, M49: '008', 'ISO-alpha2': 'AL' },
              { label: 'Andorra', id: 221, M49: '020', 'ISO-alpha2': 'AD' },
              {
                label: 'Bosnia and Herzegovina',
                id: 222,
                M49: '070',
                'ISO-alpha2': 'BA',
              },
              { label: 'Croatia', id: 223, M49: '191', 'ISO-alpha2': 'HR' },
              { label: 'Gibraltar', id: 224, M49: '292', 'ISO-alpha2': 'GI' },
              { label: 'Greece', id: 225, M49: '300', 'ISO-alpha2': 'GR' },
              { label: 'Holy See', id: 226, M49: '336', 'ISO-alpha2': 'VA' },
              { label: 'Italy', id: 227, M49: '380', 'ISO-alpha2': 'IT' },
              { label: 'Malta', id: 228, M49: '470', 'ISO-alpha2': 'MT' },
              { label: 'Montenegro', id: 229, M49: '499', 'ISO-alpha2': 'ME' },
              {
                label: 'North Macedonia',
                id: 230,
                M49: '807',
                'ISO-alpha2': 'MK',
              },
              { label: 'Portugal', id: 231, M49: '620', 'ISO-alpha2': 'PT' },
              { label: 'San Marino', id: 232, M49: '674', 'ISO-alpha2': 'SM' },
              { label: 'Serbia', id: 233, M49: '688', 'ISO-alpha2': 'RS' },
              { label: 'Slovenia', id: 234, M49: '705', 'ISO-alpha2': 'SI' },
              { label: 'Spain', id: 235, M49: '724', 'ISO-alpha2': 'ES' },
            ],
          },
          {
            label: 'Western Europe',
            M49: '155',
            id: 236,
            children: [
              { label: 'Austria', id: 237, M49: '040', 'ISO-alpha2': 'AT' },
              { label: 'Belgium', id: 238, M49: '056', 'ISO-alpha2': 'BE' },
              { label: 'France', id: 239, M49: '250', 'ISO-alpha2': 'FR' },
              { label: 'Germany', id: 240, M49: '276', 'ISO-alpha2': 'DE' },
              {
                label: 'Liechtenstein',
                id: 241,
                M49: '438',
                'ISO-alpha2': 'LI',
              },
              { label: 'Luxembourg', id: 242, M49: '442', 'ISO-alpha2': 'LU' },
              { label: 'Monaco', id: 243, M49: '492', 'ISO-alpha2': 'MC' },
              { label: 'Netherlands', id: 244, M49: '528', 'ISO-alpha2': 'NL' },
              { label: 'Switzerland', id: 245, M49: '756', 'ISO-alpha2': 'CH' },
            ],
          },
        ],
      },
      {
        label: 'Oceania',
        M49: '009',
        id: 246,
        children: [
          {
            label: 'Australia and New Zealand',
            M49: '053',
            id: 247,
            children: [
              { label: 'Australia', id: 248, M49: '036', 'ISO-alpha2': 'AU' },
              {
                label: 'Christmas Island',
                id: 249,
                M49: '162',
                'ISO-alpha2': 'CX',
              },
              {
                label: 'Cocos (Keeling) Islands',
                id: 250,
                M49: '166',
                'ISO-alpha2': 'CC',
              },
              {
                label: 'Heard Island and McDonald Islands',
                id: 251,
                M49: '334',
                'ISO-alpha2': 'HM',
              },
              { label: 'New Zealand', id: 252, M49: '554', 'ISO-alpha2': 'NZ' },
              {
                label: 'Norfolk Island',
                id: 253,
                M49: '574',
                'ISO-alpha2': 'NF',
              },
            ],
          },
          {
            label: 'Melanesia',
            M49: '054',
            id: 254,
            children: [
              { label: 'Fiji', id: 255, M49: '242', 'ISO-alpha2': 'FJ' },
              {
                label: 'New Caledonia',
                id: 256,
                M49: '540',
                'ISO-alpha2': 'NC',
              },
              {
                label: 'Papua New Guinea',
                id: 257,
                M49: '598',
                'ISO-alpha2': 'PG',
              },
              {
                label: 'Solomon Islands',
                id: 258,
                M49: '090',
                'ISO-alpha2': 'SB',
              },
              { label: 'Vanuatu', id: 259, M49: '548', 'ISO-alpha2': 'VU' },
            ],
          },
          {
            label: 'Micronesia',
            M49: '057',
            id: 260,
            children: [
              { label: 'Guam', id: 261, M49: '316', 'ISO-alpha2': 'GU' },
              { label: 'Kiribati', id: 262, M49: '296', 'ISO-alpha2': 'KI' },
              {
                label: 'Marshall Islands',
                id: 263,
                M49: '584',
                'ISO-alpha2': 'MH',
              },
              {
                label: 'Micronesia (Federated States of)',
                id: 264,
                M49: '583',
                'ISO-alpha2': 'FM',
              },
              { label: 'Nauru', id: 265, M49: '520', 'ISO-alpha2': 'NR' },
              {
                label: 'Northern Mariana Islands',
                id: 266,
                M49: '580',
                'ISO-alpha2': 'MP',
              },
              { label: 'Palau', id: 267, M49: '585', 'ISO-alpha2': 'PW' },
              {
                label: 'United States Minor Outlying Islands',
                id: 268,
                M49: '581',
                'ISO-alpha2': 'UM',
              },
            ],
          },
          {
            label: 'Polynesia',
            M49: '061',
            id: 269,
            children: [
              {
                label: 'American Samoa',
                id: 270,
                M49: '016',
                'ISO-alpha2': 'AS',
              },
              {
                label: 'Cook Islands',
                id: 271,
                M49: '184',
                'ISO-alpha2': 'CK',
              },
              {
                label: 'French Polynesia',
                id: 272,
                M49: '258',
                'ISO-alpha2': 'PF',
              },
              { label: 'Niue', id: 273, M49: '570', 'ISO-alpha2': 'NU' },
              { label: 'Pitcairn', id: 274, M49: '612', 'ISO-alpha2': 'PN' },
              { label: 'Samoa', id: 275, M49: '882', 'ISO-alpha2': 'WS' },
              { label: 'Tokelau', id: 276, M49: '772', 'ISO-alpha2': 'TK' },
              { label: 'Tonga', id: 277, M49: '776', 'ISO-alpha2': 'TO' },
              { label: 'Tuvalu', id: 278, M49: '798', 'ISO-alpha2': 'TV' },
              {
                label: 'Wallis and Futuna Islands',
                id: 279,
                M49: '876',
                'ISO-alpha2': 'WF',
              },
            ],
          },
        ],
      },
    ],
  },
]
