import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import { toNumber } from 'lodash';
import { AccountCategory, useGetAccountQuery, useGetQuestionnaireQuery, useUpdateAccountMutation, useUpdateQuestionnaireNavigationMutation } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var questionnaireId = computed(function () {
      return toNumber(route.params.questionnaire);
    });
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var account = computed(function () {
      var _accountResult$value$, _accountResult$value;
      return (_accountResult$value$ = (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) !== null && _accountResult$value$ !== void 0 ? _accountResult$value$ : {
        name: '',
        website: '',
        logo: '',
        type: AccountCategory.Portfolio
      };
    });
    var accountVenture = computed(function () {
      // Check if the type of account is VENTURE
      if (account.value.type === AccountCategory.Venture) {
        return account.value;
      } else {
        var _accountVentureResult;
        // Query for venture account if its not VENTURE
        var _useGetAccountQuery2 = useGetAccountQuery({
            id: ventureId
          }),
          accountVentureResult = _useGetAccountQuery2.result;
        return (_accountVentureResult = accountVentureResult.value) === null || _accountVentureResult === void 0 ? void 0 : _accountVentureResult.getAccount;
      }
    });
    var esgActionsActive = computed(function () {
      return accountVenture.value.esgActionsActive === true;
    });
    var _useUpdateAccountMuta = useUpdateAccountMutation(),
      updateAccount = _useUpdateAccountMuta.mutate,
      onDone = _useUpdateAccountMuta.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var triggerUpdateAccount = function triggerUpdateAccount(key, val) {
      updateAccount({
        input: _defineProperty({
          id: ventureId.value
        }, key, val)
      });
    };
    var _useGetQuestionnaireQ = useGetQuestionnaireQuery({
        id: questionnaireId.value
      }),
      result = _useGetQuestionnaireQ.result,
      loading = _useGetQuestionnaireQ.loading;
    var questionnaire = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getQuestionnaire;
    });
    var questionnaireTemplate = computed(function () {
      var _questionnaire$value, _questionnaire$value$, _questionnaire$value2, _questionnaire$value3;
      // Take the second level of the sections because the "company" level carries no useful information.
      if (!(questionnaire !== null && questionnaire !== void 0 && (_questionnaire$value = questionnaire.value) !== null && _questionnaire$value !== void 0 && (_questionnaire$value$ = _questionnaire$value.questionnaireTemplate) !== null && _questionnaire$value$ !== void 0 && _questionnaire$value$.sections)) return null;
      return questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$value2 = questionnaire.value) === null || _questionnaire$value2 === void 0 ? void 0 : (_questionnaire$value3 = _questionnaire$value2.questionnaireTemplate) === null || _questionnaire$value3 === void 0 ? void 0 : _questionnaire$value3.sections[0];
    });
    var questionnaireAssignment = computed(function () {
      var _questionnaire$value4, _questionnaire$value5;
      if (!(questionnaire !== null && questionnaire !== void 0 && (_questionnaire$value4 = questionnaire.value) !== null && _questionnaire$value4 !== void 0 && _questionnaire$value4.questionnaireAssignment)) return null;
      return questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$value5 = questionnaire.value) === null || _questionnaire$value5 === void 0 ? void 0 : _questionnaire$value5.questionnaireAssignment;
    });
    var questionnaireNavigation = computed(function () {
      var _questionnaire$value6, _questionnaire$value7;
      if (!(questionnaire !== null && questionnaire !== void 0 && (_questionnaire$value6 = questionnaire.value) !== null && _questionnaire$value6 !== void 0 && _questionnaire$value6.questionnaireNavigation)) return null;
      return questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$value7 = questionnaire.value) === null || _questionnaire$value7 === void 0 ? void 0 : _questionnaire$value7.questionnaireNavigation;
    });
    var sectionCompletionMapping = computed(function () {
      var _questionnaire$value8, _questionnaire$value9;
      if (!questionnaire.value || !questionnaire.value.questionnaireAssignment) return {};
      return Object.assign.apply(Object, [{}].concat(_toConsumableArray((_questionnaire$value8 = questionnaire.value) === null || _questionnaire$value8 === void 0 ? void 0 : (_questionnaire$value9 = _questionnaire$value8.questionnaireAssignment) === null || _questionnaire$value9 === void 0 ? void 0 : _questionnaire$value9.sectionCompletion.map(function (x) {
        return _defineProperty({}, x.sectionId, x);
      }))));
    });
    var userInputMapping = computed(function () {
      var _questionnaireAssignm, _questionnaireAssignm2;
      if ((questionnaireAssignment === null || questionnaireAssignment === void 0 ? void 0 : (_questionnaireAssignm = questionnaireAssignment.value) === null || _questionnaireAssignm === void 0 ? void 0 : (_questionnaireAssignm2 = _questionnaireAssignm.userInputs) === null || _questionnaireAssignm2 === void 0 ? void 0 : _questionnaireAssignm2.length) > 0) {
        var _questionnaireAssignm3;
        return Object.assign.apply(Object, [{}].concat(_toConsumableArray(questionnaireAssignment === null || questionnaireAssignment === void 0 ? void 0 : (_questionnaireAssignm3 = questionnaireAssignment.value) === null || _questionnaireAssignm3 === void 0 ? void 0 : _questionnaireAssignm3.userInputs.map(function (x) {
          return _defineProperty({}, String(x.question.id), x);
        }))));
      }
      return {};
    });
    var _useUpdateQuestionnai = useUpdateQuestionnaireNavigationMutation(),
      updateQuestionnaireNavigation = _useUpdateQuestionnai.mutate;
    var currentSectionId = computed(function () {
      var _questionnaireNavigat;
      return questionnaireNavigation === null || questionnaireNavigation === void 0 ? void 0 : (_questionnaireNavigat = questionnaireNavigation.value) === null || _questionnaireNavigat === void 0 ? void 0 : _questionnaireNavigat.currentSubsection;
    });
    var currentSection = computed(function () {
      if (questionnaireTemplate.value) {
        return questionnaireTemplate.value.sections.find(function (e) {
          return e.id === currentSectionId.value;
        });
      }
      return {
        ixId: ''
      };
    });
    var onSectionSelect = function onSectionSelect(item) {
      if (item.length !== 0) {
        var _questionnaire$value10, _questionnaire$value11;
        updateQuestionnaireNavigation({
          input: {
            id: questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$value10 = questionnaire.value) === null || _questionnaire$value10 === void 0 ? void 0 : (_questionnaire$value11 = _questionnaire$value10.questionnaireAssignment) === null || _questionnaire$value11 === void 0 ? void 0 : _questionnaire$value11.id,
            currentSection: 1,
            currentSubsection: item[0].id
          }
        });
      }
    };
    return {
      loading: loading,
      accountVenture: accountVenture,
      questionnaire: questionnaire,
      questionnaireAssignment: questionnaireAssignment,
      questionnaireNavigation: questionnaireNavigation,
      questionnaireTemplate: questionnaireTemplate,
      sectionCompletionMapping: sectionCompletionMapping,
      userInputMapping: userInputMapping,
      onSectionSelect: onSectionSelect,
      currentSection: currentSection,
      account: account,
      esgActionsActive: esgActionsActive,
      triggerUpdateAccount: triggerUpdateAccount,
      disabled: computed(function () {
        var _account$value;
        return (account === null || account === void 0 ? void 0 : (_account$value = account.value) === null || _account$value === void 0 ? void 0 : _account$value.type) === AccountCategory.Portfolio;
      })
    };
  }
});