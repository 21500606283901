import { render, staticRenderFns } from "./ExportDialog.vue?vue&type=template&id=3e3a4538"
import script from "./ExportDialog.vue?vue&type=script&lang=ts"
export * from "./ExportDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ExportDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonPrimary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Primary.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
