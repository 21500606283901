import { render, staticRenderFns } from "./fossilOrNuclear.vue?vue&type=template&id=4fb246be&scoped=true"
import script from "./fossilOrNuclear.vue?vue&type=script&setup=true&lang=ts"
export * from "./fossilOrNuclear.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./fossilOrNuclear.vue?vue&type=style&index=0&id=4fb246be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb246be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GChart: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/GChart.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VRow,VSimpleCheckbox,VTreeview})
