import { render, staticRenderFns } from "./scoreRemark.vue?vue&type=template&id=23548eed&scoped=true"
import script from "./scoreRemark.vue?vue&type=script&lang=ts"
export * from "./scoreRemark.vue?vue&type=script&lang=ts"
import style0 from "./scoreRemark.vue?vue&type=style&index=0&id=23548eed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23548eed",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VLayout,VProgressLinear})
