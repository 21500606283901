import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import * as _ from 'lodash';
import { useQueryLoading } from '@vue/apollo-composable';
import { useListTaxonomiesQuery } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['label'];
      }
    },
    defaultFilters: {
      type: Object,
      default: function _default() {}
    },
    showSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    showSearch: {
      type: Boolean,
      default: false,
      required: false
    },
    height: {
      type: String,
      default: 'calc(100vh - 200px)'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var headers = computed(function () {
      return [{
        value: 'id'
      }, {
        value: 'label'
      }, {
        value: 'shortLabel'
      }, {
        value: 'actions'
      }].filter(function (o) {
        var _props$columns;
        return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
      }) || [];
    });
    var options = ref({
      page: 1,
      itemsPerPage: -1,
      sortDesc: [true]
    });
    var filters = ref({});
    var variables = computed(function () {
      return {
        input: _objectSpread(_objectSpread({}, props.defaultFilters), filters.value),
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage
      };
    });
    var _useListTaxonomiesQue = useListTaxonomiesQuery(variables),
      result = _useListTaxonomiesQue.result,
      refetch = _useListTaxonomiesQue.refetch;
    var items = computed(function () {
      var _result$value$listTax, _result$value;
      return (_result$value$listTax = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listTaxonomies) !== null && _result$value$listTax !== void 0 ? _result$value$listTax : [];
    });
    var selected = computed(function () {
      return items === null || items === void 0 ? void 0 : items.value.filter(function (section) {
        return (props.value || []).filter(Boolean).includes(section.id);
      });
    });
    var search = ref('');
    var onInput = function onInput(val) {
      if (_.isEqual(val.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      }), props.value)) {
        // if the value is the same, don't fire the event
        return;
      }
      emit('input', val.map(function (_ref3) {
        var id = _ref3.id;
        return id;
      }));
    };
    var loading = useQueryLoading();
    var itemCount = computed(function () {
      var _result$value2;
      return (result === null || result === void 0 ? void 0 : (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countTaxonomies) || 0;
    });
    return {
      filters: filters,
      itemCount: itemCount,
      loading: loading,
      items: items,
      refetch: refetch,
      selected: selected,
      search: search,
      headers: headers,
      onInput: onInput,
      options: options
    };
  }
});