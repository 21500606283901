import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  name: 'ImpactPill',
  props: {
    outcome: String,
    stakeholder: String,
    linksToImpact: {
      type: Boolean,
      default: true
    },
    isPositive: Boolean,
    score: [Object, Number],
    type: String
  },
  computed: {
    color: function color() {
      return this.isPositive ? 'success' : 'danger';
    },
    getLinksToImpact: function getLinksToImpact() {
      return this.linksToImpact === null ? true : this.linksToImpact;
    },
    assessment: function assessment() {
      return this.$store.getters['assessment/getAssessment'];
    },
    noScore: function noScore() {
      return this.score === undefined || this.score.specialValue === 'NaN';
    }
  },
  methods: {
    formatScore: function formatScore(score) {
      if (this.noScore) {
        return '—&nbsp;';
      }
      if (this.type === 'confidence') {
        return score.toFixed(1);
      } else {
        return (score * 100).toFixed(0);
      }
    }
  }
});