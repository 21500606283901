import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import { questionnaireVersionTextShort } from '~/functions/esg';
import { esgSectionIdMapping } from '~/functions/helpers';
import { mapQuestionnaireResultsBySectionLabel as mapQuestionnaireResultsBySectionLabelFunction } from '~/functions/esg';
import { formatAsGermanDate, isDisclosure } from '~/functions/helpers';
import VentureDashboard from '~/pdf/Venture/venture_dashboard';
import { AggregatedQuestionnaireResultsVentureFilterType, useBasicInfoForCompanyQuery, useEsgQuestionnaireAsTableLazyQuery, useGetAggregatedEsgQuestionnaireResultQuery, useGetEsgComparisonLazyQuery, useGetQuestionnaireLazyQuery, useGetQuestionnairesForVenturesAndLabelQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    ventureName: {
      type: String,
      default: undefined
    },
    website: {
      type: String,
      default: undefined
    },
    loggedInAccountType: {
      type: String,
      required: false,
      default: 'VENTURE'
    }
  },
  setup: function setup(props) {
    var _useGetQuestionnaires = useGetQuestionnairesForVenturesAndLabelQuery({
        ventureIds: [props.ventureId],
        label: 'ESG'
      }),
      questionnairesForVentureResult = _useGetQuestionnaires.result;
    var questionnaires = computed(function () {
      if (!questionnairesForVentureResult.value) return [];
      return questionnairesForVentureResult.value.getQuestionnairesForVenturesAndLabel;
    });
    var allCompletedEsgQuestionnaires = computed(function () {
      return questionnaires.value.filter(function (item) {
        return item.isEditable === false;
      }).map(function (item, index) {
        return _objectSpread(_objectSpread({}, item), {}, {
          text: questionnaireVersionTextShort(item)
        });
      });
    });
    var selectedQuestionnaireId = ref(null);
    watch(allCompletedEsgQuestionnaires, function (current, _) {
      if (current && current.length) selectedQuestionnaireId.value = current[0].id;
    }, {
      immediate: true
    });
    var previousCompletedQuestionnaireId = ref(null);
    var showComparison = ref(false);
    var versionTextFirst = ref('Current');
    var versionTextSecond = ref('Previous');
    var questionnaireQueryReturn = useGetQuestionnaireLazyQuery({
      id: selectedQuestionnaireId
    });
    var questionnaireQueryForPreviousReturn = useGetQuestionnaireLazyQuery();
    var questionnaireSummariesReturn = useEsgQuestionnaireAsTableLazyQuery({
      id: selectedQuestionnaireId
    });
    var getEsgComparisonReturn = useGetEsgComparisonLazyQuery();
    watch(selectedQuestionnaireId, function (current, _) {
      if (selectedQuestionnaireId.value === null) return;
      questionnaireQueryReturn.load() || questionnaireQueryReturn.refetch();
      questionnaireSummariesReturn.load() || questionnaireSummariesReturn.refetch();
      var questionnaireList = allCompletedEsgQuestionnaires.value;
      var index = questionnaireList.findIndex(function (e) {
        return e.id === current;
      });
      if (index !== null && questionnaireList.length > index + 1) {
        previousCompletedQuestionnaireId.value = questionnaireList[index + 1].id;
        versionTextFirst.value = questionnaireVersionTextShort(questionnaireList[index]);
        versionTextSecond.value = questionnaireVersionTextShort(questionnaireList[index + 1]);
        showComparison.value = true;
        var forPreviousVars = {
          id: previousCompletedQuestionnaireId.value
        };
        questionnaireQueryForPreviousReturn.load(_, forPreviousVars) || questionnaireQueryForPreviousReturn.refetch(forPreviousVars);
        var esgComparisonVars = {
          firstQuestionnaireId: selectedQuestionnaireId.value,
          secondQuestionnaireId: previousCompletedQuestionnaireId.value
        };
        getEsgComparisonReturn.load(_, esgComparisonVars) || getEsgComparisonReturn.refetch(esgComparisonVars);
      } else {
        previousCompletedQuestionnaireId.value = null;
        showComparison.value = false;
      }
    }, {
      immediate: true
    });
    var esgComparisonComments = computed(function () {
      var _getEsgComparisonRetu, _getEsgComparisonRetu2;
      return (_getEsgComparisonRetu = (_getEsgComparisonRetu2 = getEsgComparisonReturn.result.value) === null || _getEsgComparisonRetu2 === void 0 ? void 0 : _getEsgComparisonRetu2.getEsgComparison) !== null && _getEsgComparisonRetu !== void 0 ? _getEsgComparisonRetu : null;
    });
    var questionnaire = computed(function () {
      var _questionnaireQueryRe, _questionnaireQueryRe2;
      return (_questionnaireQueryRe = (_questionnaireQueryRe2 = questionnaireQueryReturn.result.value) === null || _questionnaireQueryRe2 === void 0 ? void 0 : _questionnaireQueryRe2.getQuestionnaire) !== null && _questionnaireQueryRe !== void 0 ? _questionnaireQueryRe : null;
    });
    var previousQuestionnaire = computed(function () {
      var _questionnaireQueryFo, _questionnaireQueryFo2;
      return (_questionnaireQueryFo = (_questionnaireQueryFo2 = questionnaireQueryForPreviousReturn.result.value) === null || _questionnaireQueryFo2 === void 0 ? void 0 : _questionnaireQueryFo2.getQuestionnaire) !== null && _questionnaireQueryFo !== void 0 ? _questionnaireQueryFo : null;
    });
    var questionnaireSummaries = computed(function () {
      var _questionnaireSummari, _questionnaireSummari2;
      return (_questionnaireSummari = (_questionnaireSummari2 = questionnaireSummariesReturn.result.value) === null || _questionnaireSummari2 === void 0 ? void 0 : _questionnaireSummari2.esgQuestionnaireAsTable) !== null && _questionnaireSummari !== void 0 ? _questionnaireSummari : [];
    });
    var mapQuestionnaireResultsBySectionLabel = computed(function () {
      if (!questionnaire.value) return {};
      return Object.assign.apply(Object, [{}].concat(_toConsumableArray(questionnaire.value.questionnaireAssignment.questionnaireResults.map(function (x) {
        return _defineProperty({}, x.sectionLabel, x);
      }))));
    });
    var esgSections = computed(function () {
      if (!questionnaire.value) return [];
      return questionnaire.value.questionnaireTemplate.sections.filter(function (section) {
        return !isDisclosure(section.id);
      });
    });
    var mapQuestionnaireResultsBySectionLabelForPrevious = computed(function () {
      if (!previousQuestionnaire.value) return {};
      return Object.assign.apply(Object, [{}].concat(_toConsumableArray(previousQuestionnaire.value.questionnaireAssignment.questionnaireResults.map(function (x) {
        return _defineProperty({}, x.sectionLabel, x);
      }))));
    });
    var data = computed(function () {
      if (questionnaire.value) {
        return questionnaire.value.questionnaireTemplate.sections.map(function (section) {
          var subsections = section.sections.map(function (subsection) {
            return {
              label: subsection.label,
              sectionId: section.id,
              result: mapQuestionnaireResultsBySectionLabel.value[subsection.label],
              id: subsection.id,
              key: esgSectionIdMapping[subsection.id],
              questionSummaries: questionnaireSummaries.value.filter(function (e) {
                return e.subsectionId === subsection.id;
              })
            };
          });
          return {
            label: section.label,
            sectionId: section.id,
            key: esgSectionIdMapping[section.id],
            result: mapQuestionnaireResultsBySectionLabel.value[section.label],
            subsections: subsections
          };
        });
      }
      return null;
    });
    var companyReturn = useBasicInfoForCompanyQuery({
      ventureId: props.ventureId
    });
    function amendCompanyInfo(companyInfo) {
      return _objectSpread(_objectSpread({}, companyInfo), {}, {
        ventureName: props.ventureName,
        website: props.website
      });
    }
    var companyInfo = computed(function () {
      var _companyReturn$result, _companyReturn$result2;
      return amendCompanyInfo((_companyReturn$result = (_companyReturn$result2 = companyReturn.result.value) === null || _companyReturn$result2 === void 0 ? void 0 : _companyReturn$result2.basicInfoForCompany) !== null && _companyReturn$result !== void 0 ? _companyReturn$result : {});
    });
    var updatedAt = computed(function () {
      if (!questionnaire.value) return 'N/A';
      var asGermanDate = formatAsGermanDate(questionnaire.value.questionnaireAssignment.updatedAt);
      return asGermanDate !== null && asGermanDate !== void 0 ? asGermanDate : 'N/A';
    });

    // benchmark
    var benchmarkTextSecond = ref('Portfolio Average');
    var benchmarkTextThird = ref('IX Benchmark');
    var getAggregatedPortfolioReturn = useGetAggregatedEsgQuestionnaireResultQuery({});
    var secondData = computed(function () {
      var _getAggregatedPortfol;
      if (!getAggregatedPortfolioReturn.result.value) return null;
      return mapQuestionnaireResultsBySectionLabelFunction((_getAggregatedPortfol = getAggregatedPortfolioReturn.result.value) === null || _getAggregatedPortfol === void 0 ? void 0 : _getAggregatedPortfol.getAggregatedEsgQuestionnaireResult);
    });
    var getIxBenchmarkReturn = useGetAggregatedEsgQuestionnaireResultQuery({
      input: {
        scope: AggregatedQuestionnaireResultsVentureFilterType.Ix
      }
    });
    var thirdData = computed(function () {
      var _getIxBenchmarkReturn;
      if (!getIxBenchmarkReturn.result.value) return null;
      return mapQuestionnaireResultsBySectionLabelFunction((_getIxBenchmarkReturn = getIxBenchmarkReturn.result.value) === null || _getIxBenchmarkReturn === void 0 ? void 0 : _getIxBenchmarkReturn.getAggregatedEsgQuestionnaireResult);
    });
    var loading = computed(function () {
      return questionnaireQueryReturn.loading.value || questionnaireQueryForPreviousReturn.loading.value || questionnaireSummariesReturn.loading.value || getEsgComparisonReturn.loading.value || companyReturn.loading.value || getAggregatedPortfolioReturn.loading.value || getIxBenchmarkReturn.loading.value;
    }); // FIXME useQueryLoading is always false, so I manually added all loading states

    return {
      data: data,
      companyInfo: companyInfo,
      questionnaireSummaries: questionnaireSummaries,
      loading: loading,
      versionTextFirst: versionTextFirst,
      //
      versionTextSecond: versionTextSecond,
      //
      mapQuestionnaireResultsBySectionLabel: mapQuestionnaireResultsBySectionLabel,
      //
      mapQuestionnaireResultsBySectionLabelForPrevious: mapQuestionnaireResultsBySectionLabelForPrevious,
      //
      esgComparisonComments: esgComparisonComments,
      //
      esgSections: esgSections,
      //
      benchmarkTextSecond: benchmarkTextSecond,
      benchmarkTextThird: benchmarkTextThird,
      secondData: secondData,
      thirdData: thirdData,
      allCompletedEsgQuestionnaires: allCompletedEsgQuestionnaires,
      selectedQuestionnaireId: selectedQuestionnaireId,
      showComparison: showComparison,
      updatedAt: updatedAt
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    generateReport: function generateReport() {
      var _this$companyInfo,
        _this$companyInfo2,
        _this = this;
      var esgData = null;
      var ventureName = (_this$companyInfo = this.companyInfo) !== null && _this$companyInfo !== void 0 && _this$companyInfo.ventureName ? (_this$companyInfo2 = this.companyInfo) === null || _this$companyInfo2 === void 0 ? void 0 : _this$companyInfo2.ventureName : '';
      if (this.data) {
        esgData = this.data.map(function (e) {
          if (isDisclosure(e.sectionId)) {
            e.subsections.forEach(function (que) {
              que.violation = _this.getViolations(que);
            });
          }
          return e;
        });
      }
      new VentureDashboard(this.companyInfo, this.updatedAt, esgData, this.versionTextFirst, this.versionTextSecond, this.mapQuestionnaireResultsBySectionLabel, this.mapQuestionnaireResultsBySectionLabelForPrevious, this.esgSections, this.esgComparisonComments, this.showComparison, this.benchmarkTextSecond, this.benchmarkTextThird, this.secondData, this.thirdData, this.loggedInAccountType).download("".concat(ventureName, "_ESG profile.pdf"));
      this.$emit('close');
    },
    selectedOptions: function selectedOptions(section) {
      var result = this.mapQuestionnaireResultsBySectionLabel[section.label];
      if (!result) return [];
      return result.details.questionDetails.filter(function (e) {
        return e.hasRisk === true;
      }).flatMap(function (e) {
        return e.options;
      }).map(function (e) {
        return e.label;
      });
    },
    getViolations: function getViolations(section) {
      var result = this.mapQuestionnaireResultsBySectionLabel[section.label];
      if (result && result.details.hasRisk === true) {
        var counts = new Map();
        this.selectedOptions(section).forEach(function (x) {
          counts.set(x, (counts.get(x) || 0) + 1);
        });
        return Array.from(counts).map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            violation = _ref4[0],
            count = _ref4[1];
          return (count > 1 ? count + ' x ' : '') + violation;
        });
      }
      return ['Nothing to disclose']; // FIXME i18n
    },
    formatAsGermanDate: formatAsGermanDate
  }
});