import { render, staticRenderFns } from "./Esrs.vue?vue&type=template&id=78083522&scoped=true"
import script from "./Esrs.vue?vue&type=script&setup=true&lang=ts"
export * from "./Esrs.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Esrs.vue?vue&type=style&index=0&id=78083522&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78083522",
  null
  
)

/* custom blocks */
import block0 from "./Esrs.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSearchText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SearchText.vue').default,IxDataTable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/DataTable.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
