import { render, staticRenderFns } from "./search.vue?vue&type=template&id=bf993bf0"
import script from "./search.vue?vue&type=script&lang=ts"
export * from "./search.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxBtnBack: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/Back.vue').default,MetricsAssignmentBatchDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/AssignmentBatchDialog.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default,IxDataTableMetrics: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Metrics.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCardActions,VDialog,VDivider})
