import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { renderMarkdown } from '~/functions/markdown';
import { useListConceptsQuery, useGetUserQuery } from '~/types/types';
export default defineComponent({
  props: {
    annotation: {
      type: Object,
      default: null
    },
    insightId: {
      type: String,
      default: null
    }
  },
  setup: function setup(props) {
    var isDisabled = true;
    var _useGetUserQuery = useGetUserQuery(),
      getUserResult = _useGetUserQuery.result;
    var email = computed(function () {
      var _getUserResult$value$, _getUserResult$value, _getUserResult$value$2;
      return (_getUserResult$value$ = getUserResult === null || getUserResult === void 0 ? void 0 : (_getUserResult$value = getUserResult.value) === null || _getUserResult$value === void 0 ? void 0 : (_getUserResult$value$2 = _getUserResult$value.getUser) === null || _getUserResult$value$2 === void 0 ? void 0 : _getUserResult$value$2.email) !== null && _getUserResult$value$ !== void 0 ? _getUserResult$value$ : '';
    });
    if (props.annotation.created_by === email.value) {
      isDisabled = false;
    }
    if (props.annotation.type === 'REVIEW') {
      isDisabled = false;
    }
    var annotationEdit = _objectSpread({}, props.annotation);
    var _useListConceptsQuery = useListConceptsQuery({
        kind: 'COMPANY',
        limit: 2000,
        offset: 0
      }),
      result = _useListConceptsQuery.result,
      isLoadingCompany = _useListConceptsQuery.loading;
    var companyConcepts = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listConcepts;
    });
    return {
      isDisabled: isDisabled,
      annotationEdit: annotationEdit,
      email: email,
      isLoadingCompany: isLoadingCompany,
      companyConcepts: companyConcepts
    };
  },
  data: function data() {
    return {
      error: false,
      selectedContentOptions: [],
      selectedInsightQualityOptions: [],
      contentOptions: ['FEATURED', 'VISIBLE', 'HIDDEN'],
      insightQualityOptions: ['HAS_ARTIFACT', 'IS_INCOMPLETE', 'NO_RESULT', 'IMPROVEMENT_LEVER', 'TOO_MACRO'],
      impactConcepts: [],
      isLoadingImpact: false,
      companyConceptSearchModel: null,
      impactConceptSearchModel: null,
      impactConceptSearch: '',
      companyConceptSearch: '',
      toAddImpactConcepts: [],
      toAddCompanyConcepts: [],
      select: null,
      // eslint-disable-next-line vue/no-reserved-keys
      _searchTimerId: undefined
    };
  },
  watch: {
    companyConceptSearch: function companyConceptSearch(val) {
      if (!val) return;
      this.isLoadingCompany = true;
    },
    impactConceptSearch: function impactConceptSearch(val) {
      if (!val) return;
      this.isLoadingImpact = true;
      this.fetchEntriesDebounced(this.fetchImpactConcepts);
    }
  },
  methods: {
    fetchEntriesDebounced: function fetchEntriesDebounced(func) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(function () {
        func();
      }, 500);
    },
    fetchImpactConcepts: function fetchImpactConcepts() {
      var _this = this;
      var token = this.$auth.strategy.token.get();
      fetch("".concat(process.env.DOMAIN, "/concepts/impact?") + new URLSearchParams({
        label: this.impactConceptSearch
      }), {
        headers: {
          'Content-type': 'application/json',
          Authorization: token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (res) {
        _this.impactConcepts = res;
      }).catch(function (err) {
        console.log(err);
      }).finally(function () {
        return _this.isLoadingImpact = false;
      });
    },
    renderMarkdown: renderMarkdown,
    submit: function submit() {
      var _this2 = this;
      this.error = false;
      var body = {
        annotations: this.annotationEdit,
        type: this.annotationEdit.type
      };
      fetch("".concat(process.env.DOMAIN, "/impact_topic/annotate/").concat(this.insightId), {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          Authorization: this.$auth.strategy.token.get(),
          'Content-Type': 'application/json'
        }
      }).catch(function () {
        _this2.error = true;
      }).then(function (_) {
        ;
        _this2.$refs.submitButton.setSuccess();
      });
      this.$emit('submit');
    },
    updateCompanyConcepts: function updateCompanyConcepts(value) {
      this.annotationEdit.tasks.concepts.company_concepts = this.annotationEdit.tasks.concepts.company_concepts.filter(function (e) {
        return e !== value || e.label !== value.label;
      });
      delete this.annotationEdit.tasks.relations[value.label];
      return value;
    },
    updateImpactConcepts: function updateImpactConcepts(value) {
      this.annotationEdit.tasks.concepts.impact_concepts = this.annotationEdit.tasks.concepts.impact_concepts.filter(function (e) {
        return e !== value || e.label !== value.label;
      });
      delete this.annotationEdit.tasks.relations[value];
      return value;
    },
    addConceptFromModel: function addConceptFromModel(conceptType, model, conceptList) {
      if (typeof model === 'string') {
        model = {
          label: model
        };
      }
      if (model === null || conceptList.includes(model)) {
        return;
      } else {
        conceptList.push(model);
      }
      if (conceptType === 'company') {
        this.annotationEdit.tasks.relations[model.label] = {
          positive: [],
          negative: []
        };
      }
    },
    statusToColor: function statusToColor(status) {
      if (status === 'PROPOSED') {
        return 'info';
      } else if (status === 'GENERATED') {
        return 'warning';
      } else if (status === 'WIKIPEDIA' || status === 'VERIFIED') {
        return 'success';
      }
    }
  }
});