import Tooltip from '~/components/ix/info/Tooltip.vue';
import { getColor, getIcon, getTooltip } from '~/functions/esg';
import { ImplementationStatus } from '~/types/types';
export default defineComponent({
  components: {
    Tooltip: Tooltip
  },
  props: {
    implementationStatus: {
      type: String,
      default: ImplementationStatus.NotPlanned
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    additionalData: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showInfoButton: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var tooltipText = computed(function () {
      return $i18n.t(getTooltip(props.implementationStatus));
    });
    return {
      getIcon: getIcon,
      getColor: getColor,
      tooltipText: tooltipText
    };
  }
});