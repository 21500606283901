import { render, staticRenderFns } from "./VerticalSourceList.vue?vue&type=template&id=88b97e96&scoped=true"
import script from "./VerticalSourceList.vue?vue&type=script&lang=ts"
export * from "./VerticalSourceList.vue?vue&type=script&lang=ts"
import style0 from "./VerticalSourceList.vue?vue&type=style&index=0&id=88b97e96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88b97e96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SetupAddSource: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SetupAddSource.vue').default,SourceList: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SourceList.vue').default})
