import "core-js/modules/es6.number.constructor.js";
import { abbreviateNumber } from '~/assets/helper/functions';
export default defineComponent({
  props: {
    metric: {
      type: Object,
      required: true
    },
    completionStatus: {
      type: Object,
      required: true
    },
    actual: {
      type: [Number, String],
      required: true
    },
    metricNameOverride: {
      type: String,
      default: null
    },
    impactName: {
      type: String,
      required: true
    },
    unitName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var score = computed(function () {
      if (props.completionStatus.hasTarget) {
        var rate = props.completionStatus.completionRate;
        return rate * 100;
      } else return 0;
    });
    var calculationMessage = computed(function () {
      if (props.completionStatus.hasTarget) {
        var params = props.completionStatus.targetParameters;
        if (params.calculationType === 'sum') return "Sum of values from ".concat(params.startDate, " - ").concat(params.endDate);else if (params.calculationType === 'average') return "Average of values from ".concat(params.startDate, " - ").concat(params.endDate);else if (params.calculationType === 'most_recent_value') return "Most recent value from ".concat(params.startDate, " - ").concat(params.endDate);else return '';
      } else return '';
    });
    var target = computed(function () {
      if (props.completionStatus.hasTarget) return abbreviateNumber(props.completionStatus.targetParameters.target, $i18n.locale);else return 0;
    });
    return {
      score: score,
      calculationMessage: calculationMessage,
      target: target
    };
  },
  computed: {
    calculateActualPosition: function calculateActualPosition() {
      var actual = this.completionStatus.actual;
      var score = Math.round(this.score);
      if (score >= 0 && score <= 2 && actual !== 0) return 1;else if (score > 87 && score <= 100) return 86;
      return actual === 0 ? 0 : score - 2;
    }
  }
});