import { render, staticRenderFns } from "./stakeholders.vue?vue&type=template&id=1d231ed6"
import script from "./stakeholders.vue?vue&type=script&setup=true&lang=ts"
export * from "./stakeholders.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./stakeholders.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxBtnAddStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddStakeholder.vue').default,IxDataTableStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Stakeholder.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCol,VRow})
