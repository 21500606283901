import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import { GChart } from 'vue-google-charts';
import { geographies } from '~/assets/geographies';
export default defineComponent({
  components: {
    GChart: GChart
  },
  props: {
    impacts: Array,
    assessments: Array,
    stage: String,
    maxColor: String
  },
  data: function data() {
    var _this = this;
    return {
      mapRegionTab: 'world',
      mapRegions: {
        world: undefined,
        africa: '002',
        asia: '142',
        europe: '150',
        oceania: '009',
        'north america': '021',
        'south america': '005'
      },
      selected: {},
      chartEvents: {
        select: function select() {
          var chart = _this.$refs.geoChart.chartObject;
          var selection = chart.getSelection();
          if (selection.length > 0) {
            var country = _this.chartData[selection[0].row + 1][0];
            var impactIds = (_this.geographyImpactsMap.get(country) || []).map(function (item) {
              return item.impactId;
            });
            var impacts = _this.impacts.filter(function (impact) {
              return impactIds.includes(impact.id);
            });
            _this.$emit('selected', {
              country: country,
              impacts: impacts
            });
          }
        }
      }
    };
  },
  computed: {
    geographyItems: function geographyItems() {
      return geographies;
    },
    maxImpacts: function maxImpacts() {
      var max = 0;
      if (this.chartData) {
        var impactCounts = this.chartData.map(function (item) {
          return item[2];
        });
        max = Math.max.apply(Math, _toConsumableArray(impactCounts));
      }
      return Math.ceil(max);
    },
    chartData: function chartData() {
      var data = [['Region', 'Sum of impact scores', 'Number of impacts']];
      this.geographyImpactsMap.forEach(function (impacts, geography) {
        data.push([geography, Math.round(impacts.map(function (impact) {
          return 100 * impact.score;
        }).reduce(function (a, b) {
          return a + b;
        }, 0)), impacts.length]);
      });
      return data;
    },
    geographyImpactsMap: function geographyImpactsMap() {
      var _this2 = this;
      var data = new Map();
      var _iterator = _createForOfIteratorHelper(this.impacts),
        _step;
      try {
        var _loop = function _loop() {
          var impact = _step.value;
          _this2.findGeographyNames(impact.geographies).forEach(function (geography) {
            if (geography !== undefined) {
              var updatedImpacts = data.get(geography) || [];
              updatedImpacts.push({
                impactId: impact.id,
                score: impact.impactPotentialScore,
                isPositive: impact.isPositive,
                assessmentId: impact.assessment[0]
              });
              data.set(geography, updatedImpacts);
            }
          });
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return data;
    },
    optionsGeoImpacts: function optionsGeoImpacts() {
      return {
        region: this.mapRegions[this.mapRegionTab],
        colorAxis: {
          minValue: 0,
          colors: ['#f5f5f5', this.maxColor]
        },
        legend: {
          textStyle: {
            fontName: 'Roboto',
            fontSize: 14
          }
        }
      };
    }
  },
  methods: {
    findGeographyNames: function findGeographyNames(recordIds) {
      var _this3 = this;
      return recordIds.map(function (recordId) {
        var found = _this3.geographyItems.find(function (geography) {
          return geography.id === recordId;
        });
        return found ? found.name : undefined;
      }).filter(function (name) {
        return name !== undefined;
      });
    }
  }
});