import "core-js/modules/es6.function.name.js";
import { downloadExcel } from '~/functions/export';
import { useExportByPortfolioLazyQuery } from '~/types/types';
export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useExportByPortfolio = useExportByPortfolioLazyQuery(),
      onResult = _useExportByPortfolio.onResult,
      refetch = _useExportByPortfolio.refetch,
      loading = _useExportByPortfolio.loading,
      load = _useExportByPortfolio.load;
    onResult(function (res) {
      var _res$data;
      var file = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.exportByPortfolio.file;
      if (file) {
        downloadExcel(file, "".concat(props.portfolio.name, "_KPIs"));
        emit('finish');
      }
    });
    function triggerExcelExport() {
      load() || refetch();
    }
    return {
      triggerExcelExport: triggerExcelExport,
      loading: loading
    };
  }
});