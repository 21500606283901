import { RiskManagementScheme } from '~/types/types';
export default defineComponent({
  props: {
    scoringRule: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  setup: function setup() {},
  methods: {
    scheme: function scheme(_scheme) {
      if (_scheme === RiskManagementScheme.MonitoringEvaluation) return 'esg.scoring.monitoringEvaluation';else if (_scheme === RiskManagementScheme.Implementation) return 'esg.scoring.implementation';else return 'esg.scoring.additionalMeasures';
    },
    pointsPerOption: function pointsPerOption(categoryRule) {
      var pointsPerOption = categoryRule.max_value / categoryRule.threshold_num_questions_for_full_score;
      return parseFloat(pointsPerOption.toPrecision(2));
    }
  }
});