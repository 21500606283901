import { render, staticRenderFns } from "./esgList.vue?vue&type=template&id=5c64f8f8&scoped=true"
import script from "./esgList.vue?vue&type=script&lang=ts"
export * from "./esgList.vue?vue&type=script&lang=ts"
import style0 from "./esgList.vue?vue&type=style&index=0&id=5c64f8f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c64f8f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxToolbarSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/toolbarSection.vue').default,CompanyButton: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/CompanyButton.vue').default,IxScoreRemark: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/scoreRemark.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VDataTable,VIcon,VLayout,VRow,VTextarea})
