import { render, staticRenderFns } from "./ImpactSummary.vue?vue&type=template&id=d93c5e66&scoped=true"
import script from "./ImpactSummary.vue?vue&type=script&lang=ts"
export * from "./ImpactSummary.vue?vue&type=script&lang=ts"
import style0 from "./ImpactSummary.vue?vue&type=style&index=0&id=d93c5e66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d93c5e66",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
