import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  methods: {
    renderMarkdown: renderMarkdown
  }
});