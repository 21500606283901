import { render, staticRenderFns } from "./SdgGriRelevantExplanation.vue?vue&type=template&id=2b9e530b"
import script from "./SdgGriRelevantExplanation.vue?vue&type=script&lang=ts"
export * from "./SdgGriRelevantExplanation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSubSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionTitle.vue').default,IxSubSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionText.vue').default,IxMetricsSuggestionsBox: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/MetricsSuggestionsBox.vue').default,IxLeversBox: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/LeversBox.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VIcon,VLayout,VRow})
