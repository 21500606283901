import { totalPercentage } from '~/functions/esg';
export default defineComponent({
  props: {
    sectionResults: {
      type: Array,
      default: []
    }
  },
  setup: function setup() {
    return {
      totalPercentage: totalPercentage
    };
  },
  data: function data() {
    return {
      moderateThreshold: 34,
      goodThreshold: 67
    };
  },
  computed: {
    colorClass: function colorClass() {
      if (this.score < this.moderateThreshold) return 'danger';else if (this.score < this.goodThreshold) return 'info';else return 'success';
    }
  }
});