import "core-js/modules/es6.array.map.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    sectionDetails: {
      type: Object,
      required: true
    },
    questionnaireResult: {
      type: Object,
      default: function _default() {}
    },
    esgActionsActive: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var sdgs = computed(function () {
      var _props$sectionDetails, _props$sectionDetails2;
      return (_props$sectionDetails = props.sectionDetails) === null || _props$sectionDetails === void 0 ? void 0 : (_props$sectionDetails2 = _props$sectionDetails.sdgs) === null || _props$sectionDetails2 === void 0 ? void 0 : _props$sectionDetails2.map(function (id) {
        return {
          id: id,
          label: $i18n.t('esg.sectionDetails.sdgs.sdg_' + id)
        };
      });
    });
    var kpis = computed(function () {
      var _props$sectionDetails3, _props$sectionDetails4;
      return (_props$sectionDetails3 = props.sectionDetails) === null || _props$sectionDetails3 === void 0 ? void 0 : (_props$sectionDetails4 = _props$sectionDetails3.kpis) === null || _props$sectionDetails4 === void 0 ? void 0 : _props$sectionDetails4.map(function (_, index) {
        return $i18n.t('esg.sectionDetails.kpis.' + props.sectionDetails.id + '.kpi_' + (index + 1));
      });
    });
    var levers = computed(function () {
      var _props$sectionDetails5, _props$sectionDetails6;
      return (_props$sectionDetails5 = props.sectionDetails) === null || _props$sectionDetails5 === void 0 ? void 0 : (_props$sectionDetails6 = _props$sectionDetails5.levers) === null || _props$sectionDetails6 === void 0 ? void 0 : _props$sectionDetails6.map(function (e) {
        return {
          url: e.url,
          comment: $i18n.t(e.comment)
        };
      });
    });
    var gris = computed(function () {
      var _props$sectionDetails7, _props$sectionDetails8;
      return (_props$sectionDetails7 = props.sectionDetails) === null || _props$sectionDetails7 === void 0 ? void 0 : (_props$sectionDetails8 = _props$sectionDetails7.gris) === null || _props$sectionDetails8 === void 0 ? void 0 : _props$sectionDetails8.map(function (id) {
        return {
          id: id,
          label: $i18n.t('esg.sectionDetails.gris.gri_' + id)
        };
      });
    });
    return {
      sdgs: sdgs,
      kpis: kpis,
      levers: levers,
      gris: gris
    };
  },
  data: function data() {
    return {
      showInterestingReads: false
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});