import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { downloadExcel } from '~/functions/export';
import { useGetAccountQuery, Module, useExportByCompanyLazyQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    ventureName: {
      type: String,
      default: undefined
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var route = useRoute();
    // const doExportMetrics = ref(false)
    // const doExportEsgScores = ref(false)
    // const doExportEsgQuestions = ref(false)
    var ventureId = computed(function () {
      return route.params.venture || '';
    });
    var input = ref({
      doExportMetrics: false,
      doExportEsgScores: false,
      doExportEsgQuestions: false,
      ventureId: ventureId === null || ventureId === void 0 ? void 0 : ventureId.value
    });
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId === null || ventureId === void 0 ? void 0 : ventureId.value
      }),
      result = _useGetAccountQuery.result;
    var hasEsg = computed(function () {
      var _result$value, _result$value$getAcco, _result$value$getAcco2;
      return !!((_result$value = result.value) !== null && _result$value !== void 0 && (_result$value$getAcco = _result$value.getAccount) !== null && _result$value$getAcco !== void 0 && (_result$value$getAcco2 = _result$value$getAcco.bookedModules) !== null && _result$value$getAcco2 !== void 0 && _result$value$getAcco2.includes(Module.Esg));
    });
    var _useExportByCompanyLa = useExportByCompanyLazyQuery({
        input: input.value
      }),
      onResult = _useExportByCompanyLa.onResult,
      refetch = _useExportByCompanyLa.refetch,
      loading = _useExportByCompanyLa.loading,
      load = _useExportByCompanyLa.load;
    onResult(function (res) {
      var _res$data;
      var file = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.exportByCompany.file;
      if (file) {
        downloadExcel(file, "".concat(props.ventureName, ".xlsx"));
        emit('finish');
      }
    });
    function triggerExcelExport() {
      load(null, {
        input: input.value
      }) || refetch({
        input: input.value
      });
    }
    return {
      hasEsg: hasEsg,
      input: input,
      triggerExcelExport: triggerExcelExport,
      loading: loading
    };
  },
  data: function data() {
    return {};
  }
});