import { useDeleteAccountMutation, useGetAccountWithDetailQuery } from '~/types/types';
export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    dialog: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var inputConfirm = ref('');
    var _useGetAccountWithDet = useGetAccountWithDetailQuery({
        id: props.id
      }),
      result = _useGetAccountWithDet.result;
    var _useDeleteAccountMuta = useDeleteAccountMutation(),
      deleteAccount = _useDeleteAccountMuta.mutate,
      onDone = _useDeleteAccountMuta.onDone;
    onDone(function () {
      emit('done');
    });
    return {
      toDeleteAccount: computed(function () {
        var _result$value;
        return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {};
      }),
      deleteAccount: deleteAccount,
      inputConfirm: inputConfirm
    };
  }
});