import {
  maxTotalPoints,
  percentage,
  scoreByMax,
  scoreText,
  skipped,
  totalPercentage,
  totalPoints,
  totalSkipped,
} from '~/functions/esg'
import {
  handleUndefined,
  horizontalDividerSvg,
  replaceAndSpecialSymbol,
} from '../common'
import {
  calculateProgressBarWidth,
  getScoreDetail,
  handleDisclosureIcon,
} from './common'

let data: any

const getSubsectionResult = (key: string, questionIndex: number) => {
  return data[key]?.subsections[questionIndex]?.result
}

const cleanupLabel = (labelString: string) =>
  replaceAndSpecialSymbol(handleUndefined(labelString)) || 'N/A'

const getLabel = (key: string, questionIndex: number) => {
  const labelString = data[key]?.subsections[questionIndex]?.label
  return cleanupLabel(labelString)
}

export const largeProgressBar = (detail, percentageValue, scoreByMax) => `
<g transform="translate(0, 0)">
  <text fill="${detail.color}" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="36" font-weight="600" letter-spacing="0em">
    <tspan x="700" y="25">${percentageValue}%</tspan>
  </text>
  <text fill="${detail.color}" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="18" font-weight="bold" letter-spacing="0em">
    <tspan x="800" y="30">${detail.status}</tspan>
  </text>
  <text fill="#8FA5AF" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="16" font-weight="600" letter-spacing="0em">
    <tspan x="800" y="5">${scoreByMax}</tspan>
  </text>
  <line x1="780" y1="0" x2="780" y2="25" stroke="#8FA5AF"/>
  <rect opacity="0.25" x="0" y="0" width="680" height="23" rx="10" fill="#8FA5AF"/>
  <rect x="0" y="0" width="${calculateProgressBarWidth(680, percentageValue)}" height="21" rx="10" fill="${detail.color}"/>
</g>`

export const iconMapping = {
  environmental: `<path d="M30.482 42.4727C28.8375 42.4791 27.2104 42.8138 25.6935 43.4578C24.1766 44.1017 22.7997 45.0422 21.6414 46.2256C20.4831 47.4091 19.566 48.8122 18.9426 50.3549C18.3191 51.8976 18.0015 53.5497 18.0079 55.2169C19.6524 55.2105 21.2795 54.8757 22.7964 54.2318C24.3132 53.5878 25.6901 52.6473 26.8485 51.4639C28.0068 50.2805 28.9238 48.8774 29.5473 47.3346C30.1707 45.7919 30.4883 44.3928 30.482 42.7256V42.4727Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.2882 46.6034C22.1447 44.1031 22.2927 41.4095 21.7155 38.8282C21.1383 36.247 19.8589 33.8814 18.0227 32C16.1794 33.8887 14.8974 36.2651 14.3235 38.8575C13.7495 41.45 13.9066 44.1539 14.7768 46.6601" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.53525 42.4727C8.85588 42.4864 12.0352 43.8368 14.3738 46.2267C16.7125 48.6166 18.0191 51.8504 18.0063 55.2169C14.6856 55.2031 11.5064 53.8527 9.16768 51.4628C6.9767 49.2238 5.69156 46.2442 5.54853 43.1088C5.53889 42.8974 5.53444 42.6853 5.53525 42.4727Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.0078 57.8623L18.0078 55.707" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 66.4843C5.90709 62.7544 11.8749 60.7383 18.008 60.7383C24.1411 60.7383 30.1089 62.7544 35.016 66.4843" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  social: `<path d="M21.9 40.621C24.248 40.621 26.152 38.6911 26.152 36.3105C26.152 33.9299 24.248 32 21.9 32C19.552 32 17.648 33.9299 17.648 36.3105C17.648 38.6911 19.552 40.621 21.9 40.621Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.9 55.7088C24.248 55.7088 26.152 53.779 26.152 51.3984C26.152 49.0178 24.248 47.0879 21.9 47.0879C19.552 47.0879 17.648 49.0178 17.648 51.3984C17.648 53.779 19.552 55.7088 21.9 55.7088Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.142 51.3963C11.491 51.3963 13.394 49.4665 13.394 47.0859C13.394 44.7053 11.491 42.7754 9.142 42.7754C6.794 42.7754 4.891 44.7053 4.891 47.0859C4.891 49.4665 6.794 51.3963 9.142 51.3963Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.654 51.3963C37.002 51.3963 38.906 49.4665 38.906 47.0859C38.906 44.7053 37.002 42.7754 34.654 42.7754C32.306 42.7754 30.402 44.7053 30.402 47.0859C30.402 49.4665 32.306 51.3963 34.654 51.3963Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.762 45.6209C17.101 43.8699 19.282 42.7305 21.744 42.7305C24.287 42.7305 26.53 43.9461 27.856 45.7957" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.402 66.4843C30.402 61.723 26.595 57.8633 21.898 57.8633C17.202 57.8633 13.395 61.723 13.395 66.4843" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M.641 62.1717C.641 57.4105 4.448 53.5508 9.144 53.5508C12.818 53.5508 15.947 55.9121 17.136 59.2192" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.159 62.1717C43.159 57.4105 39.351 53.5508 34.655 53.5508C31.086 53.5508 28.031 55.7795 26.77 58.9389" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
  governance: `<path d="M0 66.4824H34.017" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.017 41.8527H0L15.694 32.3911C16.086 32.1357 16.542 32 17.008 32C17.474 32 17.93 32.1357 18.322 32.3911L34.017 41.8527Z" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.016 46.0078V63.2496H27.766V46.0078" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.404 46.0078V63.2496H15.152V46.0078" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.65 46.0078V63.2496H2.398V46.0078" stroke="#343F49" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>`,
}

// Main function
export const handleEsgReport = (updatedAt: string, tableData: any) => {
  data = tableData.reduce((obj, item) => {
    obj[item.key] = item
    return obj
  }, {})

  const calculateXWidthForScore = (xWidth: number, score: number) => {
    if (!score) return 0
    return score.toString().length === 3 ? xWidth + 20 : xWidth
  }
  const measureTextWidth = (
    text,
    fontSize = '15px',
    fontFamily = 'Barlow',
    fontWeight = '500',
  ) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = `${fontWeight} ${fontSize} ${fontFamily}`

    const metrics = context.measureText(text)
    const textWidth = metrics.width

    return textWidth
  }

  const calculateMargin = (
    containerWidth: number,
    content: string | number,
  ) => {
    const textWidth = measureTextWidth(content.toString())
    return containerWidth - textWidth
  }

  const ggetViolation = (items: any, i: number) => {
    const violations = items.subsections[i]?.violation || []
    return violations.map((label: string) => ({
      text: label,
      color: '#5B7185',
      fontSize: 8,
      margin: [0, 0, -20, 0],
    }))
  }

  const sections = ['environmental', 'social', 'governance']

  const scores = sections.map((key) => {
    const result = data[key]?.result
    return {
      key,
      sectionLabel: cleanupLabel(data[key]?.label),
      iconSvg: iconMapping[key],
      sectionScoreText: scoreByMax(result),
      sectionPercentage: percentage(result),
      sectionResult: result,
      sectionDetail: getScoreDetail(percentage(result), skipped(result)),
      subsectionResults: [0, 1, 2, 3, 4].map((index) =>
        getSubsectionResult(key, index),
      ),
    }
  })
  const sectionResults = sections.map((key) => data[key]?.result)
  const totalEsgPercentage = totalPercentage(sectionResults) || 0
  const totalEsgScoreByMax =
    totalPoints(sectionResults) + '/' + maxTotalPoints(sectionResults)
  const totalEsgDetail = getScoreDetail(
    totalEsgPercentage,
    totalSkipped(sectionResults),
  )

  const progressBar = (
    detail,
    percentageValue,
    scoreByMax,
    width = 350,
    height = 127,
    xMargin = measureTextWidth(percentageValue + '%', '24px', 'Barlow', '600') +
      5,
  ) => `
  <text fill="${detail.color}" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="24" font-weight="600" letter-spacing="0em">
    <tspan x="1" y="112">${percentageValue}%</tspan>
  </text>
  <text fill="${detail.color}" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="18" font-weight="600" letter-spacing="0em">
    <tspan x="${xMargin + 7}" y="${height - 17}">${detail.status}</tspan>
  </text>
  <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="15" font-weight="500" letter-spacing="0em">
    <tspan x="${calculateMargin(width, scoreByMax)}" y="${height - 17}">${scoreByMax}</tspan> <!-- Subtracted 5 pixels for space -->
  </text>
  <line x1="${xMargin}" y1="${height - 35}" x2="${xMargin}" y2="${height - 10}" stroke="#8FA5AF"/>
  <rect opacity="0.25" x="2.63672" y="${height}" width="353" height="6.03311" rx="3.01655" fill="#8FA5AF"/>
  <rect x="2.0625" y="${height}" width="${calculateProgressBarWidth(width, percentageValue)}" height="6" rx="3" fill="${detail.color}"/>
  `
  const svgBlocks = scores.map(
    ({
      key,
      sectionLabel,
      iconSvg,
      sectionPercentage,
      sectionScoreText,
      sectionDetail,
      subsectionResults,
    }) => {
      const subsectionText = subsectionResults
        .map((result, index) => {
          const content = scoreText(result)
          return `<tspan x="${calculateMargin(350, content)}" y="${170 + index * 30}">${content}</tspan>`
        })
        .join('')
      return {
        width: '33%',
        stack: [
          {
            svg: `<svg width="300%" height="310" viewBox="0 0 446 330" fill="none" xmlns="http://www.w3.org/2000/svg">
            ${iconSvg}
            <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="20" font-weight="600" letter-spacing="0em">
              <tspan x="66" y="56">${sectionLabel}</tspan>
            </text>
            ${progressBar(sectionDetail, sectionPercentage, sectionScoreText)}
            <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="15" font-weight="500" letter-spacing="0em">
              <tspan x="1" y="170">${getLabel(key, 0)}</tspan>
              <tspan x="1" y="200">${getLabel(key, 1)}</tspan>
              <tspan x="1" y="230">${getLabel(key, 2)}</tspan>
              <tspan x="1" y="260">${getLabel(key, 3)}</tspan>
              <tspan x="1" y="290">${getLabel(key, 4)}</tspan>
            </text>
            <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="15" font-weight="500" letter-spacing="0em">
              ${subsectionText}
            </text>
          </svg>`,
          },
        ],
      }
    },
  )

  return [
    {
      margin: [-5, -25, 0, 0],
      columns: [
        {
          svg: `<svg width="1377" height="119" viewBox="0 0 1377 119" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_5078_ 4819)">
              <g transform="translate(0, 70)">
              ${largeProgressBar(totalEsgDetail, totalEsgPercentage, totalEsgScoreByMax)}
              </g>
              <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="18" font-weight="600" letter-spacing="0em">
                <tspan x="989" y="42">Type of assessment</tspan>
              </text>
              <g clip-path="url(#clip0_5078_4819)"></g>
              <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="16" font-weight="500" letter-spacing="0em">
                <tspan x="990" y="70">Self-assessment</tspan>
              </text>
              <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="18" font-weight="600" letter-spacing="0em">
                <tspan x="1209" y="42">Last update</tspan>
              </text>
              <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="16" letter-spacing="0em">
                <tspan x="1209" y="70">${updatedAt}</tspan>
              </text>
            </g>
          </svg>`,
          fit: [770, 100],
        },
      ],
    },
    {
      svg: horizontalDividerSvg(10),
      width: 770,
      margin: [-5, 0, 0, 0],
    },
    {
      margin: [-8, -62, 0, 0],
      columns: svgBlocks,
    },
    {
      svg: horizontalDividerSvg(10),
      width: 770,
      margin: [-5, -53, 0, 0],
    },
    {
      text: data.disclosure?.label || '',
      style: ['Disclosures'],
      margin: [-5, 2, 0, 0],
    },
    {
      margin: [-5, 7, 0, 0],
      columns: [
        {
          columns: [
            handleDisclosureIcon(data.disclosure, 0),
            {
              text: getLabel('disclosure', 0),
              style: 'disclosureLabel',
              width: 140,
              margin: [-25, 0, 0, 0],
            },
          ],
          width: 165,
        },
        {
          columns: [
            handleDisclosureIcon(data.disclosure, 1),
            {
              text: getLabel('disclosure', 1),
              style: 'disclosureLabel',
              width: 140,
              margin: [-25, 0, 0, 0],
            },
          ],
          width: 165,
        },
        {
          columns: [
            handleDisclosureIcon(data.disclosure, 2),
            {
              text: getLabel('disclosure', 2),
              style: 'disclosureLabel',
              width: 140,
              margin: [-25, 0, 0, 0],
            },
          ],
          width: 165,
        },
        {
          columns: [
            handleDisclosureIcon(data.disclosure, 3),
            {
              text: getLabel('disclosure', 3),
              style: 'disclosureLabel',
              width: 140,
              margin: [-25, 0, 0, 0],
            },
          ],
          width: 165,
        },
      ],
    },
    {
      margin: [-5, 3, 0, 0],
      columns: [
        {
          stack: ggetViolation(data.disclosure, 0),
          width: 165,
        },
        {
          stack: ggetViolation(data.disclosure, 1),
          width: 165,
        },
        {
          stack: ggetViolation(data.disclosure, 2),
          width: 165,
        },
        {
          stack: ggetViolation(data.disclosure, 3),
          width: 165,
        },
      ],
    },
  ]
}

// Styles
export const esgReportStyle = {
  Disclosures: {
    alignment: 'left',
    margin: [-8, -50, 0, 0],
    fontSize: 14,
    color: '#343F49',
    bold: true,
  },
  disclosureLabel: {
    fontSize: 10,
    color: '#5B7185',
    wordWrap: 'break-word', // Ensure text wrapping
    columnGap: 0,
  },
  disclosureSubLabel: {
    fontSize: 8,
    color: '#5B7185',
    margin: [102, 5, 0, 0],
  },
}
