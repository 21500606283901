import { render, staticRenderFns } from "./GChart.vue?vue&type=template&id=53e578b7"
import script from "./GChart.vue?vue&type=script&lang=ts"
export * from "./GChart.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GChart: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/GChart.vue').default})
