import { renderMarkdown } from '~/functions/markdown'

export const cimIntroNotes = {
  welcome: {
    title: 'Climate Impact Model (CIM)',
    contents: [
      `Find the <strong>impact profile</strong> of your climate solution`,
      `Create a <strong>fair comparison</strong> with a reference scenario`,
      `Add the <strong>relevant emission sources</strong> to the scenarios`,
    ],
  },
  notes: {
    title: 'Climate Impact Model (CIM) - Important Notes',
    contents: [
      `This tool does <strong>not do carbon footprinting</strong>, but quantifies the <strong>planned GHG emission reductions</strong> triggered by your climate solution.`,
      `This tool does not perform an exact calculation, but results in a <strong>high-quality estimate</strong> of the GHG reduction potential.`,
      `It is of particular relevance that you make <strong>realistic and fair assumptions</strong> and always state them transparently.`,
    ],
  },
  fairComparison: {
    title: 'The fair comparison',
    seeExamples: '▼ See examples',
    comparativeUnit: renderMarkdown(`
### The comparative unit
In this step, you set up a reference scenario with which you can fairly
compare your climate solution.

The comparative unit: The comparison of your climate solution with the
reference scenario requires a comparative unit - sometimes called
functional unit. This unit must be measurable and also be able to be
forecasted by means of your business forecast. In the next step, the GHG
emissions are then always related to this comparative unit.
    `),
    guidanceForProfile: '▼ Guidance for your impact profile',
  },
}

// export type OptionType = {
//   __typename?: 'OptionType';
//   id?: Maybe<Scalars['Int']>;
//   ixId?: Maybe<Scalars['String']>;
//   label: Scalars['String'];
//   sublabel?: Maybe<Scalars['String']>;
//   tooltip?: Maybe<Scalars['String']>;
// };

export const impactProfiles = [
  {
    id: 'PRODUCT_APPLICATION',
    label: 'Product Application',
    value: 'PRODUCT_APPLICATION',
    sublabel:
      'By applying my climate solution, my customers reduce GHG emissions.',
    subTitle:
      'Development & sale of e-mobility, renewable energy systems or sustainable technologies and processes',
    tooltip:
      renderMarkdown(`A startup with the impact profile Circular Economy offers products or services climate solutions which due to the physical design or the use or service design enable the reduction of GHG emissions in the context of a Circular Economy The impact is characterized by for example improved recyclability or repairability reusability or extension of useful life All this can lead to the avoidance of emissionintensive activities such as multiple new purchases or new production or larger amounts of waste The relevant GHG emissions can take place inside and outside the startup as well as in all life phases of the climate or reference solution.\
    <br>
    <br>
    Example:
    <br>
    A start-up is developing a reusable deposit system for dry food. The start-up is responsible for the distribution and processing (cleaning) of the reusable containers. Existing deposit systems are to be used. The start-up does not produce the reusable containers itself, but handles the logistical processes and thus enables reusable use in a wide range of applications.`),
  },
  {
    id: 'CIRCULAR_ECONOMY',
    label: 'Circular Economy',
    value: 'CIRCULAR_ECONOMY',
    body: 'My climate solution is a product or service for the Circular Economy.',
    sublabel: 'reusable, recyclable, repairable, sharing',
    tooltip:
      renderMarkdown(`A startup with the impact profile Circular Economy offers products or services climate solutions which due to the physical design or the use or service design enable the reduction of GHG emissions in the context of a Circular Economy The impact is characterized by for example improved recyclability or repairability reusability or extension of useful life All this can lead to the avoidance of emissionintensive activities such as multiple new purchases or new production or larger amounts of waste The relevant GHG emissions can take place inside and outside the startup as well as in all life phases of the climate or reference solution.\
    <br>
    <br>
    Example:
    <br>
    A start-up is developing a reusable deposit system for dry food. The start-up is responsible for the distribution and processing (cleaning) of the reusable containers. Existing deposit systems are to be used. The start-up does not produce the reusable containers itself, but handles the logistical processes and thus enables reusable use in a wide range of applications.`),
  },
  {
    id: 'EXTRACTION_AND_MANUFACTURING',
    label: 'Raw Material Extraction and Manufacturing',
    value: 'EXTRACTION_AND_MANUFACTURING',
    sublabel:
      'My climate solution reduces GHG emissions in its manufacturing process or raw material extraction',
    subTitle:
      'use of climate friendly raw materials, efficient processes, renewable process energy',
    tooltip:
      renderMarkdown(`A startup with the impact profile "raw material extraction and manufacturing" offers a climate solution whose production reduces GHG emissions compared to a reference solution. The impact can be achieved by reducing raw materials and activities (efficiency) and / or by avoiding emission-intensive raw materials and activities (substitution). The startup directly displaces a more emission-intensive competing product or service with its climate solution.\
    <br>
    <br>
    Example:
    <br>
    A start-up offers serviced apartments as overnight accommodations that are designed to be energy-efficient and conserve resources. The apartments are refurbished and equipped with energy management systems to save energy. Electricity and heat are sourced sustainably.\
    <br>
    <br>
    A start-up produces clothing from discarded textile waste, primarily cotton bedding, thus reducing GHG emissions directly in the manufacturing process by avoiding new production of textile fibers.`),
  },
  {
    id: 'LOGISTICS',
    label: 'Transport and Logistics',
    value: 'LOGISTICS',
    sublabel:
      'My climate solution reduces GHG emissions in its transportation or logistics processes.',
    subTitle: 'use of climate friendly transportation, e-mobility, biking',
    tooltip:
      renderMarkdown(`A startup with the impact profile "Transport and Logistics" offers a climate solution in whose transport or logistics processes GHG emissions are reduced compared to a reference solution. The impact can be achieved by reducing transport routes (efficiency) and / or by using climate-friendly transport modes (substitution).  The startup directly displaces a more emission-intensive competing product or service with the climate solution.
      <br>
      <br>
      Example:
      <br>
      A start-up uses a digital platform to network food retailers with local farms in order to increase the supply of seasonal and regional products. The Start-up takes care of the environmentally friendly delivery of groceries by using e-transporters and short transport routes.
      `),
  },
  {
    id: 'CUSTOMER_EMPOWERMENT',
    label: 'Customer empowerment',
    value: 'CUSTOMER_EMPOWERMENT',
    sublabel:
      'My climate solution empowers my customers to implement climate-friendly measures.',
    subTitle:
      '(digital) consulting, software as a service, climate mitigation information',
    tooltip:
      renderMarkdown(`A startup with the impact profile "product application" provides its customers with a climate solution that enables them to directly reduce GHG emissions through its use or application. The climate solution can be both a product or a technology. The trigger of the impact (the application) thus has a temporal and spatial distance to the start-up, but is directly related to the climate solution. The effect is achieved primarily through frequent or permanent use and is spread over a longer period of time - i.e. it depends decisively on the usage behavior.
      <br>
      <br>
      Example:
      <br>
      A startup has developed software that enables companies to make their employee business travel more climate-friendly. By using the software and implementing corresponding climate-friendly advice, GHG emissions are reduced.
      <br>
      A start-up offers an web platform for monitoring systems in order to detect critical operating conditions. This enables customers to carry out predictive maintenance of CHP units, solar systems, air compressors, etc. and to improve the energy efficiency and profitability of the monitored systems. Due to the optimized running of the systems, GHG emissions can be reduced.`),
  },
]

export const fairComparisonsPerImpactProfile = {
  PRODUCT_APPLICATION: {
    scenario:
      renderMarkdown(`#### The comparison scenario \n You provide your customers with a climate solution that allows them to directly reduce GHG emissions through its application. For your climate solution to trigger this climate impact, it must help your customer to displace a competing solution or activity, that leads to more GHG emissions.
    <br>
    <br>
    To evaluate your climate mitigation potential, you have to set up a suitable and fair comparison scenario. This scenario should reflect the established situation without your climate solution. If there are several established solutions, choose the one with the best climate performance to avoid green-washing.
    <br>
    <br>
    In the case of your impact profile, think about what your customer would do or use without your climate solution to get the same function and which activities would be displaced by your climate solution.`),
    example:
      renderMarkdown(`Attention: This is not about the function of "climate protection / reduction of GHG emissions" but the function of the climate solution provided for your customers.
    <br>
    <br>
    Example 1: A startup has developed small wind turbines for renewable electricity production. With these turbines, customers can produce renewable electricity and displace the use of fossil energy. The basic comparable function is the production of electricity.
    <br>
    <br>
    Example 2: A start-up is developing a technology for the artificial production of meat from animal cells. Based on the plug-and-play principle, the technology is to be made available to the food industry for its own use, which can use it to produce climate-friendly meat. The basic comparable function is the production of meat.`),
    exampleComparativeUnit: renderMarkdown(`
With your impact profile, it usually makes sense to use a comparative unit that reflects what can be achieved with your climate solution.

Examples of comparative units:
- MWh of energy produced,
- 1 kg of meat produced
- MW installed solar panel capacity
- Packing of 100 pieces of product xy
- production of 160.000 kWh useful heat

<br>
<br>
  
*A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.*`),
  },
  CIRCULAR_ECONOMY: {
    scenario:
      renderMarkdown(`#### The comparison scenario \n Your climate solution enables a circular economy due to its physical, use or service design. The effect is characterised e.g. by a better recyclability or reparability, reusability or the extension of the lifespan, and thereby triggers the reduction of GHG emissions compared to other scenarios.
    <br>
    <br>
    To evaluate your climate mitigation potential, you have to set up a suitable and fair comparison scenario. This scenario should reflect the established situation without your climate solution. If there are several established solutions, choose the one with the best climate performance to avoid green-washing.
    <br>
    <br>
    For your impact profile, it is useful to compare your climate solution to a currently established product or process that provides the same function but is single-use only or is not repairable or recyclable. You can think about what your clients would use or do without your climate solution to get the same benefit or function.`),
    example:
      renderMarkdown(`Attention: This is not about the function of "reduction of GHG emissions" but the function of your climate solution provided for your customers.
    <br>
    <br>
    Example 1: A startup has developed a reusable deposit system for dry food. GHG emissions are saved by avoiding the single-use packaging of dry food products. The common function is the possibility to buy packaged dry foods. A possible comparison is a conventional single-use packaging, as this provides the same function.
    <br>
    <br>
    Example 2: A start-up produces and sells reusable glass straws, thus creating a long-lasting alternative to disposable products. The common function is the straw. A possible comparison is a conventional single-use straw, as this provides the same function.
    `),
    exampleComparativeUnit: renderMarkdown(`
With your impact profile, a sold or used quantity of the relevant product or activity that is becoming part of a circular economy is usually a good comparison unit.

Examples:
- 1 purchased dry food product 
- 1 use of a coffee cup
- 1 product sold

<br>
<br>

*In the next steps, you can make assumptions about how often something is reused or repaired or by how much the usage time changes.*

*A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.*
    
    `),
  },
  EXTRACTION_AND_MANUFACTURING: {
    scenario:
      renderMarkdown(`#### The comparison scenario \n You offer a climate solution whose production leads to fewer GHG emissions compared to a current solution on the market. For your climate solution to trigger this climate impact, it must displace this competing solution.
    <br>
    <br>
    To evaluate your climate mitigation potential, you have to set up a suitable and fair comparison scenario. This scenario should reflect the established situation without your climate solution. If there are several established solutions, choose the one with the best climate performance to avoid green-washing.
    <br>
    <br>
    For your impact profile, it is useful to compare your climate solution to a currently established product that provides the same function and would be displaced by your climate solution.
    `),
    example:
      renderMarkdown(`Attention: This is not about the function of "climate protection / reduction of GHG emissions" but the function of the climate solution provided for your customers.
    <br>
    <br>
    Example 1: A start-up offers sustainable accommodation in the form of serviced apartments. The basic function is that guests can stay overnight in these apartments. The common function would be: "Overnight stay of guests in an apartment". A possible comparison would be a conventional vacation apartment, as this provides the same function.
    <br>
    <br>
    Example 2: A start-up produces clothing from discarded textile waste, primarily cotton bedding. The function of this product is to provide clothing for everyday life and can be used as the common function. A useful comparison would be conventionally produced clothing with the same function (like T-Shirts).
    `),
    exampleComparativeUnit: renderMarkdown(`
With your impact profile, a "quantity of products sold" or "a quantity of goods produced" is usually a good choice.

Examples of comparative units:
- overnight stay of a person,
- product sold,
- MWh of energy produced,
- number of backpacks sold,
- production of 160.000 kWh thermal energy
    
<br>
<br>

*A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.*`),
  },
  LOGISTICS: {
    scenario: renderMarkdown(
      `You offer a climate solution whose provision leads to fewer GHG emissions compared to a current solution on the market, because of the use of climate friendly or efficient transportation and logistic processes. To trigger this climate imapct for your climate solution, it must displace a competing solution.
      <br>
      <br>
      To evaluate your climate mitigation potential, you have to set up a suitable and fair comparison scenario. This scenario should reflect the established situation without your climate solution. If there are several established solutions, choose the one with the best climate performance to avoid green-washing.
      <br>
      <br>
      For your impact profile, it is useful to compare your climate solution to a currently established product or service that provides the same function and would be displaced by your climate solution.`,
    ),
    example:
      renderMarkdown(`Attention: This is not about the function of "climate protection / reduction of GHG emissions" but the function of the climate solution provided for your customers.
    <br>
    <br>
    A start-up uses a digital platform to network food retailers with local farms in order to increase the supply of seasonal and regional products. They take care of the environmentally friendly delivery of goods by using e-transporters and short transport routes. The basic function is the supply of groceries to a supermarket. A possible comparison scenario would be the current supply system of supermarkets with groceries from distant locations and delivered with diesel transporter.
    `),
    exampleComparativeUnit: renderMarkdown(`
With your impact profile, a "quantity of goods transported" or "a distance travelled" is usually a good choice.

Examples of comparative units:
- meal delivered,
- transportation of a person over 100 km
- transportation of goods over 100 km
    
<br>
<br>
  
*A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.**`),
  },
  CUSTOMER_EMPOWERMENT: {
    scenario: renderMarkdown(
      `#### The comparison scenario \n For your impact profile, a "quantity of customers" is often a good comparative unit, but try to choose a more specific unit if possible.`,
    ),
    example: renderMarkdown(`customers advised / informed / empowered 
    product sold,<br>
    MWh of energy produced,<br>
    MW installed solar panel capacity build<br>
    MW of solar panels monitored <br>
    <br>
    <br>
    A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.
    `),
    exampleComparativeUnit: renderMarkdown(`
For your impact profile, a "quantity of customers" is often a good comparative unit, but try to choose a more specific unit if possible.

Examples of comparative units:
- customers advised / informed / empowered 
- product sold,
- MWh of energy produced,
- MW installed solar panel capacity build
- MW of solar panels monitored 
    
<br>
<br>

*A useful test for a good comparative unit is whether it allows a fair comparison with a third or fourth alternative szenario.*`),
  },
}

export const emissionSourceGuidelines = {
  introduction: renderMarkdown(
    `In this step, you compile the data and values for the calculation of the relevant ghg emissions of both scenarios. You have to provide your own, well-founded consumption data as well as reference values for the selected reference scenario. Guiding questions, examples and, references as well as links to external databases help you to compile all necessary data.`,
  ),
  instructions: renderMarkdown(
    `
<strong>Proceeding: Please read carefully!</strong><br>
To quantify your climate impact, you need to collect the relevant emissions for your climate solution and the reference scenario. Always on the basis of the comparative unit.
<br>
<br>
You have two options to do so:
<br>
<br>
<strong>A) You specify up to three most relevant emission sources per scenario and back them with data.</strong><br>
An emission source can be anything that is necessary to provide the function of the considered solutions and leads to GHG emissions. An emission source could be the consumption or production of energy, the need for transportation or the consumption of materials that leads to GHG emissions.
<br>
<br>
Guiding questions, examples and, references as well as links to external databases help you to compile all necessary values. But you have to provide own consumption data and reference values for the selected reference scenario as well.
<br>
<br>
<strong>B) You enter an estimated total amount of GHG emissions for the two scenarios.</strong> You can use this option if you have either already calculated a product carbon footprint, that fits to the comparative unit or you want to make an educated guess about total GHG emissions for each scenario. An educated guess significantly simplifies the calculation and often leads to a less accurate result and tends to be less convincing.
`,
  ),
}

export const sourcesOfEmissions = {
  title: 'Emission sources',
  body: renderMarkdown(
    `In order to <strong>quantify your reduction potential</strong>, the relevant emission data for your climate solution and the comparison scenario will be collected per comparative unit.`,
  ),
  instructions:
    renderMarkdown(`<strong>Proceeding: Please read carefully!</strong>
  <br>
  To quantify your climate impact, you need to collect the relevant emissions for your climate solution and the reference scenario. Always on the basis of the comparative unit.
  <br>
  <br>
  You have two options to do so:
  <br>
  <br>
  <strong>A) You specify up to three most relevant emission sources per scenario and back them with data.</strong>
  <br>
  An emission source can be anything that is necessary to provide the function of the considered solutions and leads to GHG emissions. An emission source could be the consumption or production of energy, the need for transportation or the consumption of materials that leads to GHG emissions.
  <br>
  <br>
  Guiding questions, examples and, references as well as links to external databases help you to compile all necessary values. But you have to provide own consumption data and reference values for the selected reference scenario as well.
  <br>
  <br>
  <strong>B) You enter an estimated total amount of GHG emissions for the two scenarios.</strong>
  <br>
  You can use this option if you have either already calculated a product carbon footprint, that fits to the comparative unit or you want to make an educated guess about total GHG emissions for each scenario. An educated guess significantly simplifies the calculation and often leads to a less accurate result and tends to be less convincing.`),
  importantNote: renderMarkdown(`
- You can add **up to three emission sources** - so focus on the most relevant ones.
- Always **refer to the comparative unit** and, if suitable, to a period of one year.
- Only include those emission sources for which the **comparative scenario differs from your climate solution**. This means you have to include all the required raw material, mobility and energy data that differ in the scenarios and are necessary to fulfil the function of the climate solution. You can **neglect emission sources that occur in both scenarios in the same quantity**. It may be that there are emission sources in your scenario that do not exist in the reference scenario and vice versa.
- For each emission source you need to indicate the difference between the two scenarios. The scenarios can differ as follows:
- An emission source requires a **smaller amount, does not exist** or is provided with a **different GHG emission factor**.
- Suppose an emission source is <strong>mobility performance</strong>. It requires 0.09 kg CO2e to transport one passenger for one kilometre in the average car.
  <br>
  <br>
  <strong>GHG emission factor:</strong> 0.09
  <br>
  <strong>Unit:</strong> kg CO2e/passenger-kilometre
  <br>
  <br>
  <strong>Change in consumption:</strong> If your solution reduces the need for car travel, the GHG factor remains the same, but the amount of the unit will change.
  <br>
  <strong>Alternative GHG factor:</strong> If your solution is a more efficient car, the unit amount will remain the same, but the GHG factor will change.`),
}
