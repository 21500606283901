import "core-js/modules/es6.array.map.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    },
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var selectedSdgs = computed(function () {
      if (props.impact && props.impact.sdgs) {
        return props.impact.sdgs.map(function (e) {
          return e.id;
        });
      }
      return [];
    });
    var selectedSdgTargets = computed(function () {
      if (props.impact && props.impact.sdgTargets) {
        return props.impact.sdgTargets.map(function (e) {
          return e.id;
        });
      }
      return [];
    });
    return {
      selectedSdgs: selectedSdgs,
      selectedSdgTargets: selectedSdgTargets
    };
  },
  data: function data() {
    return {
      selectedSdgTargetsDraft: []
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});