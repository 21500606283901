import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.regexp.replace.js";
export default defineComponent({
  props: {
    text: String,
    filter: String
  },
  setup: function setup(props) {
    var parts = computed(function () {
      var _props$filter, _props$text;
      var filterTrimmed = (_props$filter = props.filter) === null || _props$filter === void 0 ? void 0 : _props$filter.trim();
      if (!filterTrimmed) {
        return [props.text];
      }
      var escapedFilter = escapeRegex(filterTrimmed);
      var regex = new RegExp("(".concat(escapedFilter, ")"), 'ig');
      return (_props$text = props.text) === null || _props$text === void 0 ? void 0 : _props$text.split(regex);
    });
    function isMatch(index) {
      return index % 2 === 1;
    }
    function escapeRegex(text) {
      return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    return {
      parts: parts,
      isMatch: isMatch
    };
  }
});