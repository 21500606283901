import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    label: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props, context) {
    var _props$value;
    var items = [];
    var _iterator = _createForOfIteratorHelper(props === null || props === void 0 ? void 0 : (_props$value = props.value) === null || _props$value === void 0 ? void 0 : _props$value.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _props$label;
        var _step$value = _slicedToArray(_step.value, 2),
          i = _step$value[0],
          value = _step$value[1];
        items.push({
          model: ref(value),
          label: (_props$label = props.label) === null || _props$label === void 0 ? void 0 : _props$label[i],
          menu: ref(false)
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var save = function save() {
      items.forEach(function (item) {
        item.menu.value = false;
      });
      context.emit('input', items.map(function (item) {
        return item.model.value;
      }));
    };
    return {
      items: items,
      save: save
    };
  }
});