import "core-js/modules/es6.number.constructor.js";
import { useMutation, useQueryLoading } from '@vue/apollo-composable';
import upsertEsgComparison from '~/graphql/mutations/questionnaire/UpsertEsgComparison.gql';
import { logObserver } from '~/functions/helpers';
import { useGetEsgComparisonQuery } from '~/types/types';
export default defineComponent({
  props: {
    firstQuestionnaireId: {
      type: Number,
      required: true
    },
    secondQuestionnaireId: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetEsgComparisonQ = useGetEsgComparisonQuery({
        firstQuestionnaireId: props.firstQuestionnaireId,
        secondQuestionnaireId: props.secondQuestionnaireId
      }),
      getEsgComparisonResult = _useGetEsgComparisonQ.result,
      refetch = _useGetEsgComparisonQ.refetch;
    watch(function () {
      return [props.firstQuestionnaireId, props.secondQuestionnaireId];
    }, function (current, _) {
      refetch({
        firstQuestionnaireId: current[0],
        secondQuestionnaireId: current[1]
      });
    });
    var loading = useQueryLoading();
    var comments = computed(function () {
      if (getEsgComparisonResult.value && getEsgComparisonResult.value.getEsgComparison) {
        var data = getEsgComparisonResult.value.getEsgComparison;
        return {
          firstQuestionnaireId: data.firstQuestionnaireId,
          secondQuestionnaireId: data.secondQuestionnaireId,
          commentE: data.commentE,
          commentS: data.commentS,
          commentG: data.commentG
        };
      } else {
        return {
          firstQuestionnaireId: props.firstQuestionnaireId,
          secondQuestionnaireId: props.secondQuestionnaireId,
          commentE: null,
          commentS: null,
          commentG: null
        };
      }
    });
    var _useMutation = useMutation(upsertEsgComparison),
      mutate = _useMutation.mutate,
      onDone = _useMutation.onDone;
    return {
      comments: comments,
      mutate: mutate,
      onDone: onDone,
      refetch: refetch,
      loading: loading
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver,
    valueChange: function valueChange() {
      var _this = this;
      this.mutate({
        input: this.comments
      });
      this.onDone(function () {
        return _this.refetch();
      });
      this.$emit('close');
    }
  }
});