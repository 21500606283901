import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
export default defineComponent({
  name: 'ImpactSummaryWithStages',
  props: {
    assessment: Object,
    allowToggle: Boolean
  },
  data: function data() {
    return {
      impactDetailToggled: false,
      impactOverviewToggleOverride: null
    };
  },
  computed: {
    STAGE_DESCRIPTION: function (_STAGE_DESCRIPTION) {
      function STAGE_DESCRIPTION() {
        return _STAGE_DESCRIPTION.apply(this, arguments);
      }
      STAGE_DESCRIPTION.toString = function () {
        return _STAGE_DESCRIPTION.toString();
      };
      return STAGE_DESCRIPTION;
    }(function () {
      return STAGE_DESCRIPTION;
    }),
    assessmentStatus: function assessmentStatus() {
      if (this.assessment !== undefined && this.assessment.assessment !== undefined && this.assessment.assessment.status !== undefined) {
        return this.assessment.assessment.status;
      }
      return 'Outcome-selection input';
    },
    stages: function stages() {
      if (this.assessment && this.assessment.outcomesByStages) return this.assessment.outcomesByStages.map(function (item) {
        return item.stage;
      });
      return [];
    },
    outcomes: function outcomes() {
      var _this = this;
      if (this.assessment.outcomesByStages !== undefined && this.assessment.outcomesByStages.length > 0) {
        var outcomeIds = this.assessment.outcomesByStages[this.impactOverviewToggle].outcomes || [];
        return outcomeIds.map(function (id) {
          return _this.assessment.outcomes[id];
        });
      }
      return [];
    },
    impactOverviewToggle: function impactOverviewToggle() {
      if (this.impactOverviewToggleOverride !== null) {
        return this.impactOverviewToggleOverride;
      }
      if (this.assessment !== undefined) {
        return this.stages.length - 1;
      }
      return 0;
    },
    currentUserRole: function currentUserRole() {
      return this.assessment.assessment.currentUserRole;
    }
  },
  methods: {
    isAtOrAfterState: function (_isAtOrAfterState) {
      function isAtOrAfterState(_x) {
        return _isAtOrAfterState.apply(this, arguments);
      }
      isAtOrAfterState.toString = function () {
        return _isAtOrAfterState.toString();
      };
      return isAtOrAfterState;
    }(function (comparedState) {
      return isAtOrAfterState(this.assessmentStatus, comparedState);
    }),
    eliminateDuplicates: function eliminateDuplicates(list) {
      return _toConsumableArray(new Set(list));
    },
    setImpactOverviewToggle: function setImpactOverviewToggle(value) {
      this.impactOverviewToggleOverride = parseInt(value);
    }
  }
});