var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',{staticStyle:{"border-radius":"12px !important"}},[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.result.title',
    )))]),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',[_c('ix-data-table-metric-assignments',{attrs:{"items-per-page":-1,"filters":{
            limit: 1000,
            assigneeIds: [_vm.ventureId],
            ghgSavingPotentialId: _vm.impactSolution.ghgSavingPotentialId,
            metricNotionIds: ['aed99902406542e5b859d101d0a4021b'],
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }