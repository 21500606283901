import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    contentClass: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: [String, Number],
      default: 640
    },
    handleToggle: {
      type: Function,
      required: false
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false
    } // per default the ix-dialog has an x on the top right and can be closed by clicking outside or esc.
    // if the risk of data loss is high for a dialog component the "persistent" attribute should be used which prevents those closing mechanisms, so that only the action
    // buttons are left to control save or cancel
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _toRefs = toRefs(props),
      value = _toRefs.value;
    var internalValue = ref(value.value);
    watch(value, function (newVal) {
      internalValue.value = newVal;
    });
    var updateValue = function updateValue(newVal) {
      internalValue.value = newVal;
      emit('input', newVal);
    };
    var toggleDialog = function toggleDialog() {
      if (props.handleToggle != undefined) {
        props.handleToggle();
      } else {
        updateValue(!internalValue.value);
      }
      emit('dialog-closed');
    };
    return {
      internalValue: internalValue,
      toggleDialog: toggleDialog
    };
  }
});