import { render, staticRenderFns } from "./ImpactPillOutcomeScore.vue?vue&type=template&id=553ca97c&scoped=true"
import script from "./ImpactPillOutcomeScore.vue?vue&type=script&lang=ts"
export * from "./ImpactPillOutcomeScore.vue?vue&type=script&lang=ts"
import style0 from "./ImpactPillOutcomeScore.vue?vue&type=style&index=0&id=553ca97c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553ca97c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VIcon,VLayout})
