import { InMemoryCache, createHttpLink, split } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { createUploadLink } from 'apollo-upload-client'

export default (app: any, _route: any, _redirect: any, _ctx: any) => {
  const errorLink = onError((err) => {
    const { graphQLErrors, networkError, operation, response } = err
    if (networkError) {
      app.$sentry.captureException(networkError)
    }
    if (graphQLErrors) {
      app.$sentry.withScope((scope) => {
        // Annotate whether failing operation was query/mutation/subscription
        scope.setTag('kind', operation.operationName)
        // Log query and variables as extras
        // (make sure to strip out sensitive data!)
        scope.setExtra('query', JSON.stringify(operation.query))
        scope.setExtra('variables', operation.variables)
        graphQLErrors.map(({ message }) =>
          app.$sentry.captureException(message, err),
        )
      })

      window.$nuxt.$emit('userError', graphQLErrors?.[0])
    }
  })

  function generateAcceptLanguageString() {
    // TODO remove redundant languages
    let languages = navigator.languages
    // @ts-ignore
    if (window.$nuxt?.$i18n?.locale) {
      // @ts-ignore
      languages = [window.$nuxt?.$i18n.locale, ...navigator.languages]
    }
    let q = 1.0
    const qDecrement = 0.1 // Decrement for each subsequent language

    return languages
      .map((lang, index) => {
        // For the first language, we don't usually specify the q-value as it's assumed to be 1.0
        if (index === 0) {
          return lang
        }

        // Construct the language with its q-value, then decrement the q-value for the next language
        const langWithQ = `${lang};q=${q.toFixed(1)}`
        q = Math.max(q - qDecrement, 0.1) // Ensures q doesn't go below 0.1
        return langWithQ
      })
      .join(',')
  }

  const customFetch = async (_, options: any) => {
    options.headers['Accept-Language'] = generateAcceptLanguageString()
    if (sessionStorage.getItem('ix-account-id')) {
      options.headers['Account-Id'] = sessionStorage.getItem('ix-account-id')
    }
    if (sessionStorage.getItem('impersonation_user_id')) {
      options.headers['Impersonation-User-Id'] = sessionStorage.getItem(
        'impersonation_user_id',
      )
    }
    return fetch(`${process.env.DOMAIN || ''}/graphql`, options).catch(
      // eslint-disable-next-line no-console
      (error) => {
        console.log(error)
        app?.$sentry?.captureException(error)
      },
    )
  }
  const httpOptions = {
    fetch: customFetch,
  }
  const httpLink = split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink(httpOptions),
    createHttpLink(httpOptions),
  )
  const removeTypenameLink = removeTypenameFromVariables()

  const link = removeTypenameLink.concat(errorLink.concat(httpLink))

  // const mergeList = (existing, incoming) => {
  //   if (!existing) {
  //     return incoming
  //   }
  //   console.log('mergeList', existing)
  //   // debugger
  //   const merged = [...incoming]

  //   // Append unique items from array1 (those not present in array2)
  //   existing.forEach((item1) => {
  //     if (!merged.some((item2) => item2.__ref === item1.__ref)) {
  //       merged.push(item1)
  //     }
  //   })

  //   return merged
  // }
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // listMetricAggregations: {
          //   keyArgs: ['limit', 'offset'],
          //   merge: mergeList,
          // },
          // listMetricAssignments: {
          //   keyArgs: false,
          //   merge: mergeList,
          // },
        },
      },
      MetricAggregationType: {
        keyFields: ['id'],
        // Don't cache separate results based on
        // any of this field's arguments.
        // keyArgs: false,

        // Concatenate the incoming list items with
        // the existing list items.
        merge: true,
      },
      UserInputConceptLinkType: {
        keyFields: ['conceptId', 'userInputId'],
        // Don't cache separate results based on
        // any of this field's arguments.
        // keyArgs: false,

        // Concatenate the incoming list items with
        // the existing list items.
        merge: false,
      },
      Post: {
        merge: true,
      },
    },
  }) // { addTypename: false }

  return {
    link,
    cache,
    defaultHttpLink: false,
    getAuth: async () => {
      if (window.location.href?.includes('register')) {
        // those pages don't need auth
        return (app.$auth?.strategy as any).token?.get()
      }
      if (app.$auth.strategy?.token?.status?.()?.expired()) {
        app.$auth.$storage.setUniversal('redirect', app.route?.fullPath) // set redirect path so we land back here after login
        await app.$auth.refreshTokens()
      }
      if ((app.$auth?.strategy as any).token.get() === undefined) {
        throw new Error('User is not authenticated')
      }

      return (app.$auth?.strategy as any).token?.get()
    },
  }
}
