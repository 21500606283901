import { renderMarkdown } from '~/functions/markdown';
import { getClickableLink } from '~/functions/helpers';
export default defineComponent({
  props: {
    evidence: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialog: false
    };
  },
  methods: {
    getClickableLink: getClickableLink,
    renderMarkdown: renderMarkdown
  }
});