import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import { questionnaireVersionTextLong } from '~/functions/esg';
import { useGetQuestionnairesForVenturesAndLabelQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useGetQuestionnaires = useGetQuestionnairesForVenturesAndLabelQuery({
        ventureIds: [ventureId.value],
        label: 'ESG'
      }),
      allQuestionnairesResult = _useGetQuestionnaires.result,
      loading = _useGetQuestionnaires.loading;
    var allEsgQuestionnaires = computed(function () {
      var _allQuestionnairesRes;
      var data = (_allQuestionnairesRes = allQuestionnairesResult.value) === null || _allQuestionnairesRes === void 0 ? void 0 : _allQuestionnairesRes.getQuestionnairesForVenturesAndLabel;
      if (!data) return [];
      return data.map(function (item, index) {
        return _objectSpread(_objectSpread({}, item), {}, {
          text: questionnaireVersionTextLong(item, data.length - index)
        });
      });
    });
    var latestQuestionnaireId = computed(function () {
      var all = allEsgQuestionnaires.value;
      if (all && all.length) return all[0].id;else return null;
    });

    // const loading = useQueryLoading()

    return {
      allEsgQuestionnaires: allEsgQuestionnaires,
      latestQuestionnaireId: latestQuestionnaireId,
      loading: loading,
      ventureId: ventureId
    };
  },
  methods: {}
});