import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.filter.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { logObserver, isDisclosure } from '~/functions/helpers';
import { mapQuestionnaireResultsBySectionLabel, questionnaireVersionTextShort } from '~/functions/esg';
import { AggregatedQuestionnaireResultsVentureFilterType, useGetAggregatedEsgQuestionnaireResultQuery, useGetQuestionnaireForComparisonQuery } from '~/types/types';
export default defineComponent({
  props: {
    allEsgQuestionnaires: {
      type: Array,
      required: true
    },
    firstId: {
      type: Number,
      required: true
    },
    ventureName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    function isCompleted(questionnaire) {
      return questionnaire !== null && !questionnaire.isEditable;
    }
    var index = computed(function () {
      return props.allEsgQuestionnaires.findIndex(function (e) {
        return e.id === props.firstId;
      });
    });
    var first = computed(function () {
      return props.allEsgQuestionnaires[index.value] || null;
    });
    var _useGetQuestionnaireF = useGetQuestionnaireForComparisonQuery({
        id: first.value.id
      }),
      resultGetFirst = _useGetQuestionnaireF.result,
      refetchFirst = _useGetQuestionnaireF.refetch;
    var firstData = computed(function () {
      var _resultGetFirst$value, _resultGetFirst$value2;
      if (!resultGetFirst.value) return {};
      return mapQuestionnaireResultsBySectionLabel(((_resultGetFirst$value = resultGetFirst.value.getQuestionnaire) === null || _resultGetFirst$value === void 0 ? void 0 : (_resultGetFirst$value2 = _resultGetFirst$value.questionnaireAssignment) === null || _resultGetFirst$value2 === void 0 ? void 0 : _resultGetFirst$value2.questionnaireResults) || []);
    });
    var loading = useQueryLoading();
    var _useGetAggregatedEsgQ = useGetAggregatedEsgQuestionnaireResultQuery({
        input: {
          scope: AggregatedQuestionnaireResultsVentureFilterType.Ix
        }
      }),
      resultAggregatedAll = _useGetAggregatedEsgQ.result;
    var ixBenchmarkData = computed(function () {
      var _resultAggregatedAll$;
      if (!resultAggregatedAll.value) return null;
      return mapQuestionnaireResultsBySectionLabel((_resultAggregatedAll$ = resultAggregatedAll.value) === null || _resultAggregatedAll$ === void 0 ? void 0 : _resultAggregatedAll$.getAggregatedEsgQuestionnaireResult);
    });
    var esgSections = computed(function () {
      var _resultGetFirst$value3, _resultGetFirst$value4, _resultGetFirst$value5, _resultGetFirst$value6;
      return ((_resultGetFirst$value3 = resultGetFirst.value) === null || _resultGetFirst$value3 === void 0 ? void 0 : (_resultGetFirst$value4 = _resultGetFirst$value3.getQuestionnaire) === null || _resultGetFirst$value4 === void 0 ? void 0 : (_resultGetFirst$value5 = _resultGetFirst$value4.questionnaireTemplate) === null || _resultGetFirst$value5 === void 0 ? void 0 : (_resultGetFirst$value6 = _resultGetFirst$value5.sections) === null || _resultGetFirst$value6 === void 0 ? void 0 : _resultGetFirst$value6.filter(function (section) {
        return section && section.id && !isDisclosure(section.id);
      })) || [];
    });
    var firstVersionText = computed(function () {
      return questionnaireVersionTextShort(first.value);
    });
    return {
      loading: loading,
      isCompleted: isCompleted,
      first: first,
      ixBenchmarkData: ixBenchmarkData,
      firstData: firstData,
      firstVersionText: firstVersionText,
      esgSections: esgSections
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver
  }
});