import { render, staticRenderFns } from "./Question.vue?vue&type=template&id=2ce756ce"
import script from "./Question.vue?vue&type=script&lang=ts"
export * from "./Question.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyScoringRulesInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/ScoringRulesInfo.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default})
