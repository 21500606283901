import { filter } from 'lodash'
import {
  EsCharacteristicStrategy,
  EsCharacteristicsPromoted,
  FundType,
  SfdrStrategyType,
} from '~/types/types'

export const esCharacteristicMapping = {
  [EsCharacteristicStrategy.EsgIntegration]: {
    label:
      'The fund promotes E/S characteristics by ESG Integration into the investment strategy, e.g. if potential and current investments are required to conduct the ESG Assessment of ImpactNexus as part of the due diligence or regular monitorings.',
    sublabel: 'This approach is strongly recommended as minimum approach.',
    info: 'The explicit inclusion by asset managers of ESG risks and opportunities into traditional financial analysis and investment decisions based on a systematic process and appropriate research sources. This type covers explicit consideration of ESG factors alongside financial factors in the mainstream analysis of investments. The integration process focuses on the potential impact of ESG issues on company financials (positive and negative), which in turn may affect the investment decision.',
  },
  [EsCharacteristicStrategy.ExclusionCriteria]: {
    label:
      'The fund promotes E/S characteristics by the application of exclusion criteria, so called values-based screening.',
    sublabel: 'This approach is strongly recommended as minimum approach.',
    info: 'An approach that excludes specific investments or classes of investment from the investible universe such as companies, sectors, or countries. This approach systematically excludes companies, sectors, or countries from the permissible investment universe if involved in certain activities based on specific criteria. Common criteria include weapons, pornography, tobacco, and animal testing. Exclusions can be applied at an individual fund or mandate level, but increasingly also at an asset manager or an asset owner level, across the entire product range of assets.',
  },
  [EsCharacteristicStrategy.BestInClass]: {
    label: 'The fund promotes E/S characteristics by a Best-in-class approach.',
    sublabel: '',
    info: 'The fund promotes environmental and/or social characteristics by selecting or weighting the best-performing (in terms of the ESG criteria) companies or assets, within a defined investment universe. (e.g., only investments in companies with high human rights index).',
  },
  [EsCharacteristicStrategy.NormBasedScreening]: {
    label: 'The fund promotes E/S characteristics by a Norms-Based Screening.',
    sublabel: '',
    info: 'The fund excludes companies that violate internationally (or nationally) recognized norms and conventions. This approach involves the screening of investments based on international norms or combinations of norms covering ESG factors. International norms on ESG are those defined by international bodies such as the United Nations (e.g., Rio Declaration on Environment and Development, United Nations Convention against Corruption, etc.). Please note that Article 8 products shall, by default, align with the OECD Guidelines for Multinational Enterprises and the UN Guiding Principles on Business and Human Rights, regardless of the consideration of further norms and conventions. Therefore, if your fund is only aligned with these two international standards, do not mention norms-based screening as an additional ESG strategy your fund applies.',
  },
  [EsCharacteristicStrategy.SustainabilityLabel]: {
    label:
      'The fund promotes E/S characteristics by bearing a Sustainability Label.',
    sublabel: '',
    info: 'The fund is labeled as sustainable including being classified as Article 8 or 9 SFDR (e.g., when obtaining the FNG Siegel label).',
  },
}

export const esCharacteristicPromotedMapping = {
  [EsCharacteristicsPromoted.OnlyEnvironmental]: {
    label: 'Only Environmental characteristics',
    sublabel: '',
    info: '',
  },
  [EsCharacteristicsPromoted.OnlySocial]: {
    label: 'Only Social characteristics',
    sublabel: '',
    info: '',
  },
  [EsCharacteristicsPromoted.Both]: {
    label: 'Both Environmental and Social characteristics',
    sublabel: '',
    info: '',
  },
}

export const sustainabilityIndictatorDescription = `
Sustainability indicators serve to measure the attainment of
- the sustainable investments objectives of a fund (for article 9 funds)
- the environmental or social characteristics of a fund (for article 8 funds)
- the impact of individual sustainable investments (portfolio companies) in a fund (for article 8+ funds)

Please note that the sustainability indicators and the PAI indicators refer to different disclosures under the SFDR. However, it is recommended to choose the PAI indicators as sustainability indicators to measure the sustainability performance of a fund e.g. by showing improvements of the investments against those indicators over time.    `

export const sustainabilityBenchmarkTemplate1 = `
*Disclaimer: This statement on benchmark reference for VC and PE funds was created by ImpactNexus. It is intended to provide assistance and guidance. It is not an official statement of the SFDR regulation. ImpactNexus assumes no liability for the accuracy of the content of this statement.*

## General information
The underlying assets of (name of the fund) are committed to decarbonisation of the
economy and have a carbon emission reduction as their sustainable investment objective.
According to the rules set out in [Article 49, points (1a) and (1b) of the Sustainable Finance Disclosure Regulation (SFDR)](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32022R1288&from=ET#:~:text=1.%C2%A0%C2%A0%C2%A0In%20the%20website%20section%20%E2%80%98Attainment,of%20those%20benchmarks%20can%20be%20found), financial market participants holding a financial product with
the objective of a carbon emission reduction shall describe, which market index has been
designated as a reference benchmark and how this index is aligned with the sustainable
investment objective. The reference benchmark shall qualify as an EU Climate Transition
Benchmark or an EU Paris-aligned Benchmark as defined in Article 3, points (23a) and (23b),
of Regulation (EU) 2016/1011. As the asset class of (name of the fund) is (Private
Equity/Venture Capital/Other(specify)), there are currently no market indexes, which can
satisfy the above criteria for a reference benchmark.
In this case, under [Article 49, point (3) of the SFDR](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32022R1288&from=ET#:~:text=to%20that%20information.-,3.%C2%A0%C2%A0%C2%A0By%20way%20of%20derogation%20from%20paragraph%201,-%2C%20point%20(b)%2C%20where), the financial market participants
shall explain how the continued effort of attaining the objective of reducing carbon emissions
is ensured in view of achieving the objectives of the Paris Agreement. Namely, the extent to
which the financial product complies with the methodological requirements set out in
[Delegated Regulation (EU) 2020/1818](https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32020R1818)[^1]. The effort of (name of the fund) to satisfy the
methodological requirements is described in the statement below:`

export const sustainabilityBenchmarkTemplateStatement = `> The underlying assets of <em><strong>(name of the fund)</strong></em> are committed to decarbonisation of the economy
and have a carbon emission reduction as their sustainable investment objective. The fund’s
objective adheres to the 1,5 °C scenario, with no or limited overshoot, referred to in the Special
Report on Global Warming of 1,5 °C from the Intergovernmental Panel on Climate Change
(IPCC) as the reference temperature scenario. Because there are no market indexes, which
can be used as a reference benchmark for <em><strong>(name of the fund)</em></strong>, the present statement contains
the description of a continued effort of attaining the objective of reducing carbon emissions in
line with the requirements set out on the SFDR. Therefore, the weighted average GHG
intensity[^2] of the fund, including Scope 1, 2, and 3 GHG emissions, is <em><strong>_____ %</strong></em> lower than the GHG
intensity of the investable universe[^3]. Moreover, the fund maintains a <em><strong>_____ %</strong></em> reduction of GHG
intensity on average per annum[^4]. Finally, the fund applies the following exclusion criteria when
making investment decisions: <em><strong>_____</strong></em>. Therefore, the financial product <em><strong>(name of the fund)</strong></em>
complies/does not yet fully comply with the [methodological requirements set out in Delegated Regulation (EU) 2020/1818](https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32020R1818#:~:text=CHAPTER%20II,THE%20BENCHMARK%20METHODOLOGY). In case the fund does not yet comply with all the requirements,
please, explain the reasons for it and describe the measures taken with a view to ensuring
compliance with the methodological requirements.

[^1]: The original methodological requirements include the following:
The GHG intensity for EU Climate Transition Benchmarks, including Scope 1, 2, and 3 GHG emissions,
shall be at least 30 % (and at least 50% for EU Paris Aligned Benchmarks) lower than the GHG intensity
of the investable universe. Moreover, the issuers of the constituent securities shall ensure the annual
minimum 7 % reduction of GHG intensity for at least three consecutive years. The annual reduction of
GHG intensity for year 'n' shall be calculated based on the GHG intensity for the year n-1, in a geometric
progression from the base year. Finally, the exclusion criteria for EU Paris Aligned Benchmarks are set
out in [Article 12 of Delegated Regulation (EU) 2020/1818](https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32020R1818#:~:text=in%20Article%205.-,Article%2012,Exclusions%20for%20EU%20Paris%2Daligned%20Benchmarks,-1.%C2%A0%C2%A0%C2%A0Administrators%20of).. For EU Climate Transition Benchmarks, only
points (1a), (1b), (1c), and (2) from Article 12 are applicable.

[^2]: Absolute GHG emissions divided by millions of euros in enterprise value including cash.

[^3]: The set of all investable instruments in a given asset class or group of asset classes. Can be a broad
market index.

[^4]: The change in GHG intensity shall be calculated as the percentage change between, on the one hand,
the weighted average GHG intensity of all constituents of the fund at the end of year ‘n’ and, on the
other hand, the weighted average GHG intensity of all constituents of the benchmarks at the end of
year n-1.
`

export const sustainabilityBenchmarkTemplateStatementCopied = `The underlying assets of _________ (name of the fund) are committed to decarbonisation of the economy
and have a carbon emission reduction as their sustainable investment objective. The fund’s
objective adheres to the 1,5 °C scenario, with no or limited overshoot, referred to in the Special
Report on Global Warming of 1,5 °C from the Intergovernmental Panel on Climate Change
(IPCC) as the reference temperature scenario. Because there are no market indexes, which
can be used as a reference benchmark for _________ (name of the fund), the present statement contains
the description of a continued effort of attaining the objective of reducing carbon emissions in
line with the requirements set out on the SFDR. Therefore, the weighted average GHG
intensity of the fund, including Scope 1, 2, and 3 GHG emissions, is _________ % lower than the GHG
intensity of the investable universe. Moreover, the fund maintains a _________ % reduction of GHG
intensity on average per annum. Finally, the fund applies the following exclusion criteria when
making investment decisions: _________. Therefore, the financial product _________ (name of the fund)
complies/does not yet fully comply with the [methodological requirements set out in Delegated Regulation (EU) 2020/1818](https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32020R1818#:~:text=CHAPTER%20II,THE%20BENCHMARK%20METHODOLOGY). In case the fund does not yet comply with all the requirements,
please, explain the reasons for it and describe the measures taken with a view to ensuring
compliance with the methodological requirements.`

export function unAssignedVentureIds(fund: FundType): string[] {
  const ventureIdsInOtherObjective = fund.sustainableObjectives.flatMap(
    (e: SfdrStrategyType) => e.ventureIds,
  )
  return (
    fund.ventureIds?.filter(
      (e: string) => !ventureIdsInOtherObjective.includes(e),
    ) ?? []
  )
}

export function getSectionIdsRecursive(sections) {
  return filter(
    sections.map((section) => {
      if (section?.disabled || section.disabled) {
        return null
      }
      if (section.sections) {
        return getSectionIdsRecursive(section.sections)
      } else {
        return section.id
      }
    }),
    (sectionId) => sectionId !== null,
  )
}

export function findSectionRecursive(sections: any[], targetId) {
  for (const section of sections) {
    if (section.id === targetId) {
      return section
    }
    if (section.sections?.length > 0) {
      const foundInSection = findSectionRecursive(section.sections, targetId)
      if (foundInSection) {
        return foundInSection
      }
    }
  }
  return null
}

export function flattenSections(sections: any[]) {
  const flatSections: any[] = []
  for (const section of sections) {
    flatSections.push(section)
    if (section.sections?.length > 0) {
      flatSections.push(...flattenSections(section.sections))
    }
  }
  return flatSections
}

export function isAnswered(val: any): number {
  if (val === undefined || val === null || val === '') return 0
  if (Array.isArray(val) && val.length === 0) return 0
  return 1
}

export const getNestedProperty = (obj, path, defaultValue = '') => {
  if (!path) {
    return ''
  }
  return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), obj)
}

export const getEnvironmentalMetricNames = (environmentalMetrics) => {
  let metricNames = []
  if (environmentalMetrics) {
    metricNames = metricNames.concat(
      environmentalMetrics.map((metric) => metric.name),
    )
  }
  return metricNames
}

export const getSocialMetricNames = (socialMetrics) => {
  let metricNames = []
  if (socialMetrics) {
    metricNames = metricNames.concat(socialMetrics.map((metric) => metric.name))
  }
  return metricNames
}
