import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.regexp.replace.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    impactTopic: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var requestBody = {
      company_concept: props.impactTopic.company_concept,
      impact_concept: props.impactTopic.impact_concept,
      polarity: props.impactTopic.polarity
    };
    var allInsights = ref([]);
    var _useNuxtApp = useNuxtApp(),
      $axios = _useNuxtApp.$axios,
      $auth = _useNuxtApp.$auth,
      $store = _useNuxtApp.$store;

    /* API_URL -> DOMAIN here? */
    $axios.$post("".concat(process.env.DOMAIN, "/api/impact_topic"), requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: $auth.strategy.token.get()
      }
    }).catch(function (error) {
      console.log(error);
    }).then(function (response) {
      allInsights.value = response.documents;
    });
    var isAdmin = $store.state.assessment.venture.name === 'Admin';
    return {
      allInsights: allInsights,
      isAdmin: isAdmin
    };
  },
  data: function data() {
    return {
      tab: 'RESEARCH',
      // TODO translate labels to titles
      documentSources: {}
    };
  },
  methods: {
    renderMarkdown: renderMarkdown,
    highlightConcepts: function highlightConcepts(text) {
      var regexCompany = new RegExp("\\b(".concat(this.impactTopic.company_concept, ")\\b"), 'ig');
      var regexImpact = new RegExp("\\b(".concat(this.impactTopic.impact_concept, ")\\b"), 'ig');
      text = text.replace(regexCompany, '**$1**');
      text = text.replace(regexImpact, '**$1**');
      return text;
    }
  }
});