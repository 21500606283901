import { render, staticRenderFns } from "./VentureOverview.vue?vue&type=template&id=519d46da"
import script from "./VentureOverview.vue?vue&type=script&lang=ts"
export * from "./VentureOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,KeyValue: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/KeyValue.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,ValueChain: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ValueChain.vue').default,KeyInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/KeyInfo.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDivider,VIcon,VRow})
