var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section-text',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"value":_vm.latestQuestionnaireId,"outlined":"","items":_vm.allEsgQuestionnaires,"item-value":"id","hide-details":"","dense":""},on:{"change":_vm.updateSelection}})],1),_vm._v(" "),_c('v-col',[_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-action',_vm._g(_vm._b({},'ix-button-action',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-compare")]),_vm._v(" "),_c('span',{staticClass:"ml-2 body-1"},[_vm._v(_vm._s(_vm.$t('compare')))])],1)]}},{key:"default",fn:function(menuDialog){return [_c('v-list',[(
                  !_vm.loggedInAccountLoading &&
                  _vm.loggedInAccountType === 'PORTFOLIO'
                )?_c('v-list-item',[_c('ix-dialog',{attrs:{"max-width":1300},on:{"input":(val) => (menuDialog.value = val)},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t(
                        'compareEsgPerformanceWithPortfolioAndIXAverageListItem',
                      )))])]}},{key:"title",fn:function(){return [_vm._v("\n                    "+_vm._s(_vm.$t(
                        'compareEsgPerformanceWithPortfolioAverageDialogTitle',
                        {
                          ventureName: _vm.venture.name,
                        },
                      ))+"\n                  ")]},proxy:true},{key:"text",fn:function(){return [_c('esg-performance-comparison-to-portfolio',{attrs:{"venture-id":_vm.ventureId,"venture-name":_vm.venture.name}})]},proxy:true}],null,true)})],1):_c('v-list-item',[_c('ix-dialog',{attrs:{"max-width":1300},on:{"input":(val) => (menuDialog.value = val)},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t(
                          'compareEsgPerformanceWithPortfolioAndIXAverageListItem',
                        ))+"\n                    ")])]}},{key:"title",fn:function(){return [_vm._v("\n                    "+_vm._s(_vm.$t(
                        'compareEsgPerformanceWithPortfolioAverageDialogTitle',
                        {
                          ventureName: _vm.venture.name,
                        },
                      ))+"\n                  ")]},proxy:true},{key:"text",fn:function(){return [_c('esg-performance-comparison-to-ix-benchmark',{attrs:{"all-esg-questionnaires":_vm.allEsgQuestionnaires,"first-id":_vm.selectedQuestionnaireId,"venture-name":_vm.venture.name}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('v-list-item',[_c('ix-dialog',{attrs:{"max-width":1300},on:{"input":(val) => (menuDialog.value = val)},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v("\n                      "+_vm._s(_vm.$t('compareEsgPerformanceWithPreviousReportListItem'))+"\n                    ")])]}},{key:"title",fn:function(){return [_vm._v("\n                    "+_vm._s(_vm.$t('compareEsgPerformanceWithPreviousReportListItem', {
                        ventureName: _vm.venture.name,
                      }))+"\n                  ")]},proxy:true},{key:"text",fn:function({ toggleDialog }){return [_c('esg-performance-comparison-to-previous',{attrs:{"venture-id":_vm.ventureId,"all-esg-questionnaires":_vm.allEsgQuestionnaires,"first-id":_vm.selectedQuestionnaireId},on:{"close":toggleDialog}})]}}],null,true)})],1)],1)]}}])})],1)]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticStyle:{"color":"rgba(0, 0, 0, 0.6) !important"}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('div',{staticClass:"font-weight-medium caption"},[_vm._v("\n            "+_vm._s(_vm.$t('esg.header.type'))+"\n          ")]),_vm._v(" "),_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-bookmark-outline")])],1)],1)])],1),_vm._v(" "),(_vm.loading)?_c('ix-page-loading'):(!_vm.questionnaire)?_c('v-row',[_vm._v("unknown assessment")]):_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.isEditable)?_c('v-col',{attrs:{"cols":"12"}},[_c('esg-dashboard-esg',{attrs:{"questionnaire-assignment":_vm.questionnaire.questionnaireAssignment,"questionnaire-template":_vm.questionnaire.questionnaireTemplate}})],1):_c('v-col',[_c('ix-association',{staticClass:"mt-12"}),_vm._v(" "),_c('ix-sub-section',[_c('ix-sub-section-title',{staticClass:"justify-center"},[_vm._v("\n          "+_vm._s(_vm.$t('esg.header.notComplete'))+"\n        ")]),_vm._v(" "),_c('ix-sub-section-text'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"offset":"4","cols":"4"}},[_c('esg-venture-module-overview',{attrs:{"booked-modules":_vm.bookedModules,"value":{
                ..._vm.module,
                to: {
                  name: 'venture-esg-edit-questionnaire',
                  params: { questionnaire: _vm.selectedQuestionnaireId },
                },
              }}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }