export default defineComponent({
  name: 'EditableList',
  props: {
    items: Array,
    noBullets: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: 'text'
    },
    editable: Boolean
  }
});