import { camelCase } from '~/functions/helpers';
export default defineComponent({
  props: {
    category: Object,
    outcome: Object
  },
  setup: function setup(props, _ref) {
    var _this = this;
    var emit = _ref.emit;
    var complete = ref(false);
    var score = ref(0);
    var dimensionCardRefs = ref([]);
    var handleChangeInDimension = function handleChangeInDimension() {
      if (dimensionCardRefs.value) {
        complete.value = dimensionCardRefs.value.every(function (e) {
          return e.complete;
        });
        score.value = dimensionCardRefs.value.reduce(function (acc, dim) {
          return acc + +dim.score;
        }, 0) / dimensionCardRefs.value.length;
      }
    };
    watch(function () {
      return props.outcome;
    }, function () {
      handleChangeInDimension();
    });
    watch(complete, function () {
      emit('change');
    });
    onMounted(function () {
      handleChangeInDimension();
    });
    var outcomeEditingState = computed(function () {
      return props.outcome ? _this.$store.getters['assessment/getEditingState'](props.outcome.id) : null;
    });
    return {
      complete: complete,
      score: score,
      handleChangeInDimension: handleChangeInDimension,
      camelCase: camelCase,
      outcomeEditingState: outcomeEditingState,
      dimensionCardRefs: dimensionCardRefs
    };
  }
});