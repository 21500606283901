// migrate to composition api
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    termKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Array],
      required: true
    }
  },
  setup: function setup() {}
});