import "core-js/modules/es6.array.map.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { useGetImpactSdgsForVenturesQuery } from '~/types/types';
export default defineComponent({
  props: {
    impactGoalAssessments: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var impactGoalAssessmentIds = computed(function () {
      return props.impactGoalAssessments.map(function (e) {
        return e.ventureId;
      });
    });
    var showPositive = ref(true);
    var _useGetImpactSdgsForV = useGetImpactSdgsForVenturesQuery({
        ventureIds: impactGoalAssessmentIds,
        showPositive: showPositive
      }),
      sdgsResult = _useGetImpactSdgsForV.result;
    var sdgAggregations = computed(function () {
      var _sdgsResult$value$get, _sdgsResult$value;
      return (_sdgsResult$value$get = (_sdgsResult$value = sdgsResult.value) === null || _sdgsResult$value === void 0 ? void 0 : _sdgsResult$value.getImpactSdgsForVentures) !== null && _sdgsResult$value$get !== void 0 ? _sdgsResult$value$get : [];
    });
    var loading = useQueryLoading();
    return {
      sdgAggregations: sdgAggregations,
      loading: loading,
      showPositive: showPositive
    };
  }
});