export const moduleByKeys: any = {
  companyInfo: {
    title: '',
    id: 'company-info',
    header: 'modules.companyInfo.header',
    subModules: [
      {
        id: 'company-information',
        // title: 'Company information',
        title: 'modules.companyInfo.title',
        description: 'modules.companyInfo.description',
        // lastUpdated: '12.05.2021',
        // currentlyEditing: true,
        // duration: '~10 min',
        buttonContent: 'modules.companyInfo.buttonContent',
        to: {
          name: 'venture-company',
        },
        showForModules: ['ESG', 'IMPACT', 'METRICS'],
      },
    ],
  },
  esgAssessment: {
    id: 'esg-assessment',
    header: 'modules.esgAssessment.header',
    title: 'modules.esgAssessment.title',
    subModules: [
      // {
      //   title: 'modules.esgAssessment.adverseImpactScreening.title',
      //   description: 'modules.esgAssessment.adverseImpactScreening.description',
      //   duration: 'modules.esgAssessment.adverseImpactScreening.duration',
      //   buttonContent:
      //     'modules.esgAssessment.adverseImpactScreening.buttonContent',
      //   upcoming: true,
      //   upcomingText:
      //     'modules.esgAssessment.adverseImpactScreening.upcomingText',
      //   showForModules: ['ESG'],
      // },
      {
        id: 'esg',
        title: 'modules.esgAssessment.esgAssessment.title',
        description: 'modules.esgAssessment.esgAssessment.description',
        duration: 'modules.esgAssessment.esgAssessment.duration',
        buttonContent: 'modules.esgAssessment.esgAssessment.buttonContent',
        to: {
          name: 'venture-esg',
        },
        showForModules: ['ESG'],
      },
      {
        id: 'metrics',
        title: 'modules.esgAssessment.metrics.title',
        description: 'modules.esgAssessment.metrics.description',
        buttonContent: 'modules.esgAssessment.metrics.buttonContent',
        to: {
          name: 'venture-metrics',
        },
        showForModules: ['METRICS'],
      },
    ],
  },
  impactGoals: {
    id: 'impact',
    header: 'modules.impactGoals.header',
    title: 'modules.impactGoals.title',
    subModules: [
      {
        id: 'impact-goals',
        title: 'modules.impactGoals.impactGoals.title',
        description: 'modules.impactGoals.impactGoals.description',
        duration: 'modules.impactGoals.impactGoals.duration',
        buttonContent: 'modules.impactGoals.impactGoals.buttonContent',
        to: {
          name: 'venture-impact-edit-intro',
        },
        showForModules: ['IMPACT_GOAL'],
      },
      // {
      //   id: 'impact-scoring',
      //   title: 'modules.impactGoals.impactScoring.title',
      //   description:
      //     'modules.impactGoals.impactScoring.description',
      //   duration: 'modules.impactGoals.impactScoring.duration',
      //   //buttonContent: 'button_content_get_started',
      //   upcoming: true,
      //   buttonContent: 'modules.impactGoals.impactScoring.buttonContent',
      //   upcomingText: 'modules.impactGoals.impactScoring.upcomingText',
      //   showForModules: ['Impact goal'],
      // },
      // {
      //   id: 'ghg',
      //   title: 'modules.impactGoals.ghgSavingPotential.title',
      //   description:
      //     'modules.impactGoals.ghgSavingPotential.description',
      //   duration: 'modules.impactGoals.ghgSavingPotential.duration',
      //   buttonContent: 'modules.impactGoals.ghgSavingPotential.buttonContent',
      //   upcoming: true,
      //   upcomingText: 'modules.impactGoals.ghgSavingPotential.upcomingText',
      //   showForModules: ['Impact goal'],
      // },
    ],
  },
}
