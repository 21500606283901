var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-container', [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "solo": "",
      "label": "Search insights"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchInsights.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "clearable": "",
      "solo": "",
      "items": _vm.annotators,
      "label": "Annotator",
      "item-text": "annotator",
      "item-value": "annotator"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-content', [_c('v-list-item-title', [_c('span', [_vm._v(_vm._s(item.annotator))]), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('span', {
          staticClass: "blue-grey--text font-weight-regular"
        }, [_vm._v(_vm._s(item.count) + " annotations")])], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.selectedAnnotator,
      callback: function callback($$v) {
        _vm.selectedAnnotator = $$v;
      },
      expression: "selectedAnnotator"
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "solo": "",
      "multiple": "",
      "items": _vm.scrapers,
      "label": "Source"
    },
    model: {
      value: _vm.selectedScrapers,
      callback: function callback($$v) {
        _vm.selectedScrapers = $$v;
      },
      expression: "selectedScrapers"
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('ix-button-action', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.searchInsights
    }
  }, [_vm._v("Search")])], 1)], 1), _vm._v(" "), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.insightFilter,
      callback: function callback($$v) {
        _vm.insightFilter = $$v;
      },
      expression: "insightFilter"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "All",
      "value": "all"
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "Human annotated",
      "value": "humanAnnotated"
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "Annotated by others",
      "value": "othersAnnotated"
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "Reviewed",
      "value": "reviewed"
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "Only annotated by machine",
      "value": "machineAnnotated"
    }
  }), _vm._v(" "), _c('v-radio', {
    attrs: {
      "label": "Not annotated by human",
      "value": "noAnnotation"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.allInsights ? _c('v-row', [_c('v-col', [_c('v-data-iterator', {
    attrs: {
      "items": _vm.allInsights,
      "page": _vm.page,
      "items-per-page": _vm.itemsPerPage,
      "server-items-length": _vm.totalItems
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "pagination": _vm.searchInsights,
      "update:items-per-page": _vm.searchInsights
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var items = _ref2.items,
          isExpanded = _ref2.isExpanded,
          expand = _ref2.expand;
        return _vm._l(items, function (item) {
          return _c('v-card', {
            key: item.id,
            attrs: {
              "tile": "",
              "flat": ""
            }
          }, [_c('v-card-title', [_c('v-layout', {
            staticClass: "mb-2",
            attrs: {
              "align-center": ""
            }
          }, [_c('v-checkbox', {
            attrs: {
              "hide-details": "",
              "input-value": isExpanded(item),
              "on-icon": "mdi-chevron-up",
              "off-icon": "mdi-chevron-down"
            },
            on: {
              "change": function change(v) {
                return expand(item, v);
              }
            }
          }), _vm._v(" "), _c('a', {
            staticClass: "mx-2",
            attrs: {
              "href": item.url,
              "target": "_blank"
            }
          }, [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
            staticClass: "flex-grow-0 flex-shrink-0",
            attrs: {
              "text": ""
            },
            on: {
              "click": function click() {
                _vm.insightDialogOpen = true;
                _vm.currentInsight = item;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-pencil")]), _vm._v(" "), item.n_human_annotations > 0 ? _c('span', [item.to_review === false ? _c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-shield-check")]) : item.to_annotate === false ? _c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-check")]) : _vm._e(), _vm._v("\n                    " + _vm._s(item.n_human_annotations) + "\n                  ")], 1) : _vm._e()], 1), _vm._v(" "), !_vm.isAdmin && isExpanded(item) ? _c('v-btn-toggle', {
            attrs: {
              "color": "grey"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-thumb-up")])], 1), _vm._v(" "), _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-thumb-down")])], 1)], 1) : _vm._e()], 1), _vm._v(" "), item.highlight ? _vm._l(item.highlight.text, function (h, index) {
            return _c('div', {
              key: index,
              staticClass: "insight-snippet",
              domProps: {
                "innerHTML": _vm._s(h)
              }
            });
          }) : _vm._e()], 2), _vm._v(" "), isExpanded(item) ? _c('v-card-text', [_c('div', {
            staticClass: "markdown-field text-body-2 insight-text",
            domProps: {
              "innerHTML": _vm._s(_vm.renderMarkdownInline(item.text))
            }
          })]) : _vm._e()], 1);
        });
      }
    }], null, false, 3774468178)
  }), _vm._v(" "), _vm.isAdmin ? _c('v-dialog', {
    attrs: {
      "max-width": "90%"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(dialog) {
        return [_vm.currentInsight ? _c('impact-topic-insight', {
          key: _vm.currentInsight.id,
          attrs: {
            "insight": _vm.currentInsight
          },
          on: {
            "submit": function submit($event) {
              dialog.value = false;
            },
            "next": _vm.nextInsight,
            "prev": _vm.prevInsight
          }
        }) : _vm._e()];
      }
    }], null, false, 3914023301),
    model: {
      value: _vm.insightDialogOpen,
      callback: function callback($$v) {
        _vm.insightDialogOpen = $$v;
      },
      expression: "insightDialogOpen"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };