var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.outcome !== undefined)?_c('v-card',{key:_vm.category.name + 'card',staticClass:"subbox indicators-card",staticStyle:{"border-top":"1px solid #cfd8dc"},attrs:{"elevation":"0","tile":""}},[_c('v-card-title',[_c('v-row',{staticClass:"d-flex no-gutter algn-center flex-grow-1 flex-shrink-1",staticStyle:{"margin":"0 !important"},attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"12","md":"3","xl":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"width":"28px","height":"28px"},attrs:{"fab":_vm.outcomeEditingState,"icon":!_vm.outcomeEditingState,"dark":_vm.outcomeEditingState,"elevation":"0","color":!_vm.outcomeEditingState
                  ? 'rgba(0, 0, 0, 0.54)'
                  : _vm.complete
                  ? 'success'
                  : 'danger'},on:{"click":function($event){return _vm.$emit('toggleCategory', _vm.category.name)}}},'v-btn',attrs,false),on),[(_vm.category.toggle)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3582528545)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(!_vm.outcomeEditingState
              ? 'Show indicators'
              : _vm.complete
              ? 'All indicators are confirmed'
              : 'There are indicators left to confirm'))])]),_vm._v(" "),_c('div',{staticClass:"text-overline",staticStyle:{"display":"inline-block","font-size":"1rem !important","word-break":"normal !important"}},[_c('term',{attrs:{"term":_vm.category.name,"element":"Assessment"}},[_vm._v("\n            "+_vm._s(_vm.category.name)+"\n          ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"sm":"12","md":"3","xl":"3"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('score-bar',{staticStyle:{"width":"100%"},attrs:{"score":_vm.score,"is-positive":_vm.outcome.isPositive}})],1)])],1)],1),_vm._v(" "),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.category.toggle),expression:"category.toggle"}]},_vm._l((_vm.category.dimensions),function(dimension,dimensionIndex){return _c('imp-dimension-card',{key:dimensionIndex,ref:"dimensionCard",refInFor:true,staticClass:"imp-dimension",attrs:{"dimension":dimension,"outcome":_vm.outcome},on:{"change":_vm.handleChangeInDimension,"toggleDimension":function($event){return _vm.$emit('toggleDimension', _vm.category.name, dimension.name)}}})}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }