import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=7a618223&scoped=true"
import script from "./Section.vue?vue&type=script&lang=ts"
export * from "./Section.vue?vue&type=script&lang=ts"
import style0 from "./Section.vue?vue&type=style&index=0&id=7a618223&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a618223",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VProgressLinear})
