import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    formatedCompanyConcepts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    formatedAdditionalConcepts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    concepts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isCompanyConcept: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      selectedConcepts: this.concepts,
      chipClasses: '',
      selectedCompanyConcepts: this.concepts ? this.formatedCompanyConcepts : this.formatedAdditionalConcepts,
      isConcept: this.isCompanyConcept
    };
  },
  computed: {},
  watch: {
    formatedCompanyConcepts: function formatedCompanyConcepts(value) {
      this.selectedCompanyConcepts = value;
    },
    formatedAdditionalConcepts: function formatedAdditionalConcepts(value) {
      this.selectedCompanyConcepts = value;
    },
    concepts: function concepts(value) {
      this.selectedConcepts = value;
    },
    isCompanyConcept: function isCompanyConcept(value) {
      this.isConcept = value;
    }
  },
  methods: {
    setChipClass: function setChipClass(index) {
      this.selectedCompanyConcepts[index].selected = !this.selectedCompanyConcepts[index].selected;
      this.selectedConcepts = this.selectedCompanyConcepts.map(function (concept) {
        return concept.selected;
      }).reduce(function (out, bool, index) {
        return bool ? out.concat(index) : out;
      }, []);
    }
  }
});