export default defineComponent({
  props: {
    metricAssignment: {
      type: Object,
      default: null
    },
    fontFamily: {
      type: String,
      default: 'subtitle-1'
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    calculationType: function calculationType() {
      var _this$metricAssignmen, _this$metricAssignmen2;
      var calculationType = (_this$metricAssignmen = this.metricAssignment) === null || _this$metricAssignmen === void 0 ? void 0 : (_this$metricAssignmen2 = _this$metricAssignmen.completionStatus.targetParameters) === null || _this$metricAssignmen2 === void 0 ? void 0 : _this$metricAssignmen2.calculationType;
      if (calculationType === 'sum') return 'Sum';else if (calculationType === 'average') return 'Average';else if (calculationType === 'most_recent_value') return 'Most recent value';else return 'N/A';
    },
    portfolioName: function portfolioName() {
      var _this$metricAssignmen3;
      var name = (_this$metricAssignmen3 = this.metricAssignment.completionStatus.targetParameters) === null || _this$metricAssignmen3 === void 0 ? void 0 : _this$metricAssignmen3.portfolioName;
      return name || 'ImpactNexus default';
    }
  }
});