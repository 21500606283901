var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._g(_vm._b({attrs:{"locale":_vm.$i18n.locale,"loading":_setup.iroLoading,"dense":"","fixed-header":"","item-key":"id","headers":_setup.headers.map((header) => ({
      ...header,
      text: header.text || _vm.$t(header.value),
    })),"items":_setup.items,"sort-by":"name","server-items-length":_setup.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_setup.options.itemsPerPage,"options":_setup.options},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('NuxtLink',{attrs:{"to":`/csrd/iro/${item.id}/edit`}},[_c('ix-search-text',{attrs:{"text":item.name,"filter":_setup.search}})],1)]}},{key:`item.type`,fn:function({ item }){return [(item.type == _setup.EnumIroType.Impact)?_c('ix-search-text',{attrs:{"text":item.effectOfImpact}}):_c('ix-search-text',{attrs:{"text":item.type}})]}},{key:`item.activity`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.activity?.name,"filter":_setup.search}})]}},{key:`item.stakeholders`,fn:function({ item }){return [(
        !Array.isArray(item.stakeholderLinks) ||
        item.stakeholderLinks.length === 0
      )?_c('span',[_vm._v("\n      None\n    ")]):_vm._e(),_vm._v(" "),_vm._l((item.stakeholderLinks),function(stakeholder){return _c('NuxtLink',{key:stakeholder.stakeholder.id,attrs:{"to":`/csrd/stakeholder/${stakeholder.stakeholder.id}/edit`}},[_c('ix-search-text',{attrs:{"text":stakeholder.stakeholder.name,"filter":_setup.search}}),_vm._v(" "),_c('br')],1)})]}},{key:`item.sections`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":_setup.parseSections(item.sectionLinks)}})]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }