import type {
  ImpactSolutionType,
  ImpactType,
  MetricAssignmentType,
  MetricValueType,
} from '~/types/types'

import {
  abbreviateNumber,
  roundToNearestNonZeroFractionDigit,
} from '~/assets/helper/functions'

// dynamic rounding based on the size of the input val
export const roundValue = (val) => {
  if (Math.abs(val) < 1) return roundToNearestNonZeroFractionDigit(val) // Small numbers, until the first non-zero decimal place
  if (Math.abs(val) < 10) return Math.round(val) // Less than 10, round to integer
  if (Math.abs(val) < 1000) return Math.round(val / 10) * 10 // Less than 1000, round to nearest 10
  return Math.round(val / 100) * 100 // Over 1000, round to nearest 100
}

export function impactSolutionMetricValues(
  impactSolution: ImpactSolutionType,
  climateImpact: ImpactType,
) {
  return (
    climateImpact.metricAssignments
      .find(
        (metricAssignment: MetricAssignmentType) =>
          metricAssignment?.metric?.id === 1217 && // Metric id for "GHG emissions mitigated by impact solution"
          metricAssignment.ghgSavingPotentialId ===
            impactSolution.ghgSavingPotentialId,
      )
      ?.metricValues.filter(
        (metricValue: MetricValueType) => metricValue.target !== null,
      ) ?? []
  )
}

export function dateRange(metricValues: Array<MetricValueType>) {
  return metricValues.map((e) => e.period).sort()
}

export function totalGhgSavingsOfImpactSolution(
  metricValues: Array<MetricValueType>,
  locale: string,
) {
  if (!metricValues || !metricValues.length) return '0'

  let total = metricValues.reduce((acc, metricValue) => {
    return metricValue?.target ? acc + metricValue.target : acc
  }, 0)

  return abbreviateNumber(total, locale)
}
