import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export default defineComponent({
  props: {
    info: {
      type: Object,
      required: true
    },
    isEsg: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup() {},
  computed: {
    getDifferenceLength: function getDifferenceLength() {
      return Math.abs(this.info.difference).toString().length;
    },
    getClass: function getClass() {
      var fontSize = this.isEsg ? ' text-md-body-1 font-weight-bold' : ' text-caption';
      return this.getColorClass + fontSize;
    },
    getColorClass: function getColorClass() {
      var _this$info, _this$info2, _this$info3;
      if ((_this$info = this.info) !== null && _this$info !== void 0 && _this$info.hasValue) return 'blue-grey--text';
      if (((_this$info2 = this.info) === null || _this$info2 === void 0 ? void 0 : _this$info2.difference) > 0) return 'success--text';else if (((_this$info3 = this.info) === null || _this$info3 === void 0 ? void 0 : _this$info3.difference) < 0) return 'danger--text';else return 'color: #616161 ml-2';
    }
  }
});