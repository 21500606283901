import { render, staticRenderFns } from "./EvidenceForImpact.vue?vue&type=template&id=2a2b8132&scoped=true"
import script from "./EvidenceForImpact.vue?vue&type=script&lang=ts"
export * from "./EvidenceForImpact.vue?vue&type=script&lang=ts"
import style0 from "./EvidenceForImpact.vue?vue&type=style&index=0&id=2a2b8132&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2b8132",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,SearchActiveFilters: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/search/ActiveFilters.vue').default,FilterView: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/FilterView.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,SdgList: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SdgList.vue').default,KeyValue: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/KeyValue.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VDataTable,VDivider,VIcon,VLayout,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTextField})
