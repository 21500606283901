import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import VueMarkdown from 'vue-markdown-render';
import { hoverTexts } from '~/assets/hoverTexts';
export default defineComponent({
  name: 'Term',
  components: {
    VueMarkdown: VueMarkdown
  },
  props: {
    term: String,
    element: String,
    explanation: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props) {
    var explanation = ref(props.explanation);
    var explanationForTerm = function explanationForTerm(term) {
      var entry = hoverTexts.find(function (e) {
        return (props.element === undefined || e.Element === props.element) && e.Keyword.includes(term);
      });
      if (entry === undefined) return '';
      return entry['Text to show'];
    };
    onMounted(function () {
      if (explanation.value === '') {
        explanation.value = explanationForTerm(props.term);
      }
    });
    return {
      explanation: explanation,
      explanationForTerm: explanationForTerm
    };
  }
});