export default defineComponent({
  props: {
    selected: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {}
});