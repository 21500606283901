import "core-js/modules/es6.array.find.js";
import { moduleByKeys } from '~/assets/modules';
import { useVentureId } from '~/functions/accounts';
import { useGetAccountQuery, useGetImpactGoalQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var ventureId = useVentureId();
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId.value
      }),
      accountResult = _useGetAccountQuery.result;
    var venture = computed(function () {
      var _accountResult$value;
      return ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) || {
        modules: [],
        portfolioLinks: []
      };
    });
    var _useGetImpactGoalQuer = useGetImpactGoalQuery({
        ventureId: ventureId.value
      }),
      impactGoalResult = _useGetImpactGoalQuer.result,
      loading = _useGetImpactGoalQuer.loading;
    var impactGoal = computed(function () {
      var _impactGoalResult$val;
      return (_impactGoalResult$val = impactGoalResult.value) === null || _impactGoalResult$val === void 0 ? void 0 : _impactGoalResult$val.getImpactGoal;
    });
    var module = computed(function () {
      return moduleByKeys.impactGoals.subModules.find(function (e) {
        return e.id === 'impact-goals';
      }) || {};
    });
    // const loading = useQueryLoading()
    var bookedModules = computed(function () {
      var _venture$value;
      return ((_venture$value = venture.value) === null || _venture$value === void 0 ? void 0 : _venture$value.bookedModules) || [];
    });
    return {
      impactGoal: impactGoal,
      venture: venture,
      loading: loading,
      module: module,
      bookedModules: bookedModules
    };
  },
  data: function data() {
    return {
      currentTab: null
    };
  }
});