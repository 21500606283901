export const defaultFont = 'Roboto'

export const defaults = (profileName: string) => ({
  defaultStyle: {
    font: defaultFont,
    columnGap: 30,
  },
  pageMargins: [36, 30, 36, 63],
  pageOrientation: 'landscape',
  header() {
    return {
      // text: 'ImpactNexus',
      // margin: [0, 30, 0, 0],
      // alignment: 'center',
      // fontSize: 10,
    }
  },
  footer(currentPage: number, pageCount: number) {
    return [
      {
        columns: [
          {
            text: `${profileName} profile exported ${new Date()
              .toLocaleDateString()
              .replace(/\//g, '.')}`,
            style: ['date'],
          },
          {
            text: `${currentPage}/${pageCount}`,
            style: ['numberOfPage'],
          },
          {
            text: 'Copyright © ImpactNexus All Rights Reserved',
            style: ['footerHeading'],
          },
          {
            svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22" enable-background="new 0 0 22 22" xml:space="preserve">  <image id="image0" width="22" height="22" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABXFBMVEX///////7k8/qJx+hG
    pdocktIKic4PjM8ml9NZrt2k0+zq9Pr//v/t9vtxuuMAhMwAhMsZkNCVy+r+/v7H4/QmltREpdnv
    +Pu93/ITjdAvm9X0+v3w+PwilNNPqtz4+/5ruOGZzusfk9JFptp0vOOTy+klltOdz+vH5PQFh83X
    7PdcsN6bz+vP6PUol9Mml9Tz+fxtueHA4fIrmdR5v+QNi85OqtwDhc1tueJYr91CpNk5oNgEhs2G
    xeef0OtruOIQjM9Pq9wtmtXt9/uLx+h8wOVwuuIflNINi88wm9UwmtUgk9LC4vPE4/MGh81Jp9o4
    n9cjldP7/f79/v5uuuKq1u6/4PJ+weWh0uzy+PwmltNYrt7K5fQ7odjq9fvU6/Ypl9QAhcwBhcxR
    q9zp9fvv9/xyu+IIiM4Oi8+q1u/o9PqQyulMqdspmNQFhs0HiM0smdVesd+f0Oz6/f73eSsNAAAA
    AWJLR0QAiAUdSAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+YDHwweKzL0UN0AAAD4SURB
    VBjTY2AAAkYGJmYWVjZ2Dk4ubgYeBhjg5eOHAAF+QSFhmKiIKD8SEBOHiEpI8qMAKWmQqIwsPxqQ
    kwcKKwBNVFRSVuFXVYOJqzMwaGgCaS0lbR1+XT2YsL4BgyE/hrAAvxGDMaYwP78Jgyk2YTMGcwG4
    sIUlEFiBhK0ZbOCqbe1AwB4i7AAXRgKODE5QYWcNCHAB8V0Z3MDC7h6eXhDgDeL7MPj6gYT9A2AA
    pDswiCE4BEiHhsFBOJAbAQyTyCj0oIoGB2wMG78AsmhsHCNYPD4hESYuwJ+UnAKJBkaG1LR0mNKM
    THkGBMjKzsnNyy8oLCqG8AEB0jfiAnIbEQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wMy0zMVQw
    OTozMDo0MyswMzowMMUINK4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDMtMzFUMDk6MzA6NDMr
    MDM6MDC0VYwSAAAAAElFTkSuQmCC" />
    </svg>`,
            width: 15,
            style: ['logo'],
          },
        ],
      },
    ]
  },
  pageBreakBefore() {
    // if (currentNode.id === checkPageBreakId) {
    //   return currentNode.startPosition.top >= pageSize - moveToNextPage
    // }
    return false
  },
})

export const footerStyle = {
  date: {
    color: '#5B7185',
    fontSize: 10,
    alignment: 'left',
    margin: [30, 30, 0, 0],
  },
  numberOfPage: {
    color: '#5B7185',
    fontSize: 10,
    alignment: 'center',
    margin: [0, 30, 0, 0],
  },
  footerHeading: {
    color: '#5B7185',
    fontSize: 10,
    alignment: 'right',
    margin: [0, 30, 20, 0],
  },
  logo: {
    margin: [-40, 28, 0, 0],
  },
}
