var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._g(_vm._b({staticClass:"w-full",attrs:{"locale":_vm.$i18n.locale,"loading":_setup.loading,"dense":"","fixed-header":"","item-key":"id","headers":_setup.headers.map((header) => ({
      ...header,
      text: header.text || _vm.$t(header.value),
    })),"items":_setup.items,"sort-by":"createdAt","server-items-length":_setup.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_setup.options.itemsPerPage,"options":_setup.options},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([(_vm.$attrs.showTop)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},model:{value:(_setup.search),callback:function ($$v) {_setup.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _setup.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true}:null,{key:`item.name`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.name,"filter":_setup.search}})]}},{key:`item.impactMaterial`,fn:function({ item }){return [_c('v-checkbox',{attrs:{"input-value":item.impactMaterial,"label":``,"true-value":true,"false-value":false},on:{"change":(v) =>
          _setup.updateActivityStakeholderLink({
            input: { id: item.id, impactMaterial: !!v },
          })}})]}},{key:`item.financialMaterial`,fn:function({ item }){return [_c('v-checkbox',{attrs:{"input-value":item.financialMaterial,"label":``,"true-value":true,"false-value":false},on:{"change":(v) =>
          _setup.updateActivityStakeholderLink({
            input: { id: item.id, financialMaterial: !!v },
          })}})]}},{key:`item.actions`,fn:function(){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]},proxy:true},{key:"footer.prepend",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('ix-btn-add-iro-section-link',{attrs:{"iro-id":_vm.$attrs['iro-id']},on:{"done":() => _setup.refetch()}})],1),_vm._v(" "),_c('v-spacer')],1)]},proxy:true}],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }