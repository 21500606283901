import { render, staticRenderFns } from "./_venture.vue?vue&type=template&id=47b85086&scoped=true"
import script from "./_venture.vue?vue&type=script&lang=ts"
export * from "./_venture.vue?vue&type=script&lang=ts"
import style0 from "./_venture.vue?vue&type=style&index=0&id=47b85086&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b85086",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SectionTitle.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
