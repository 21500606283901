import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    totalAssesments: {
      type: Number,
      default: null
    },
    totalImpacts: {
      type: Number,
      default: null
    }
  },
  setup: function setup() {}
});