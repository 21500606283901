import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.object.freeze.js";
import "core-js/modules/es6.object.keys.js";
import { useUpsertMetricMutation, MetricReportingPeriodType, TypeMetricAssignedTo } from '~/types/types';
var template = Object.freeze({
  assignedTo: TypeMetricAssignedTo.Company,
  editable: true,
  name: '',
  description: '',
  defaultGoalType: 'none',
  defaultGoalTarget: 0,
  defaultReportingPeriodType: MetricReportingPeriodType.Annual
});
export default defineComponent({
  props: {
    metric: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(props, _ref) {
    var _props$metric2;
    var emit = _ref.emit;
    onUpdated(function () {
      var _props$metric;
      metricFormValue.value = (_props$metric = props.metric) !== null && _props$metric !== void 0 && _props$metric.id ? props.metric : _objectSpread({}, template);
      changes.value = {};
    });
    var metricFormValue = ref((_props$metric2 = props.metric) !== null && _props$metric2 !== void 0 && _props$metric2.id ? props.metric : _objectSpread({}, template));
    var _useUpsertMetricMutat = useUpsertMetricMutation(),
      mutate = _useUpsertMetricMutat.mutate;
    var changes = ref({});
    var onInput = function onInput(v, key) {
      changes.value = _objectSpread(_objectSpread({}, changes.value), {}, _defineProperty({}, key, v[key]));
    };
    var save = function save() {
      var _Object$keys;
      if ((_Object$keys = Object.keys(changes.value)) !== null && _Object$keys !== void 0 && _Object$keys.length) {
        var payload = {
          input: {}
        };
        if (props.metric.id) {
          payload.input = _objectSpread(_objectSpread({}, changes.value), {}, {
            id: props.metric.id
          });
        } else {
          // we are creating a new metric
          payload.input = _objectSpread(_objectSpread({}, template), changes.value);
          delete payload.input.editable;
        }
        mutate(payload).then(function (result) {
          var _result$data;
          window.$nuxt.$emit('userMsg');
          emit('update:metric', result === null || result === void 0 ? void 0 : (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.upsertMetric);
          dialog.value = false;
        });
      }
    };
    var disabled = computed(function () {
      var _Object$keys2;
      return !((_Object$keys2 = Object.keys(changes.value)) !== null && _Object$keys2 !== void 0 && _Object$keys2.length);
    });
    var dialog = ref(false);
    return {
      onInput: onInput,
      disabled: disabled,
      save: save,
      dialog: dialog,
      metricFormValue: metricFormValue,
      changes: changes
    };
  }
});