import "core-js/modules/es6.array.map.js";
import { TypeMetricAssignedTo, useGetAccountQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _route$query, _route$query$assigned, _route$query2;
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {};
    });
    var route = useRoute();
    var paramAssignedTos = (_route$query = route.query) !== null && _route$query !== void 0 && (_route$query$assigned = _route$query.assignedTos) !== null && _route$query$assigned !== void 0 && _route$query$assigned.length ? (_route$query2 = route.query) === null || _route$query2 === void 0 ? void 0 : _route$query2.assignedTos : [TypeMetricAssignedTo.FundMgmt, TypeMetricAssignedTo.FundCompany, TypeMetricAssignedTo.Company, TypeMetricAssignedTo.Fund, null];
    return {
      paramAssignedTos: paramAssignedTos === null || paramAssignedTos === void 0 ? void 0 : paramAssignedTos.map(function (item) {
        if (!item || item === '') {
          return null;
        }
        return item;
      }),
      account: account,
      TypeMetricAssignedTo: TypeMetricAssignedTo
    };
  },
  data: function data() {
    return {
      selectedMetrics: [],
      customMetricFormDialog: false
    };
  },
  methods: {
    addMetricAssignmentsFromTemplate: function addMetricAssignmentsFromTemplate(items) {
      this.customMetricFormDialog = true;
      this.selectedMetrics = items;
    }
  }
});