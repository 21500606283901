var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._b({staticClass:"fixed-layout",attrs:{"locale":_vm.$i18n.locale,"loading":_setup.loading,"dense":"","fixed-header":"","item-key":"id","headers":_setup.headers.map((header) => ({
      ...header,
      text: header.text || _vm.$t(header.value),
    })),"items":_setup.items,"sort-by":"createdAt","server-items-length":_setup.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_setup.options.itemsPerPage,"options":_setup.options},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([(_vm.$attrs.showTop)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},model:{value:(_setup.search),callback:function ($$v) {_setup.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _setup.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true}:null,{key:`item.description`,fn:function({ item }){return [_c('ix-text-field',{attrs:{"value":item.description},on:{"input":(v) => {
          _setup.updateEvidenceLink({
            input: { id: item.id, description: v },
          }).then(() => {
            _setup.refetch()
          })
        }}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","small":""}},'v-icon',attrs,false),on),[_vm._v("\n          mdi-delete\n        ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n            Are you sure you want to delete this?\n          ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":() => {
                  dialog.value = false
                }}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":() => {
                  _setup.delEvidenceLink({ id: item.id }).then(() => _setup.refetch())
                }}},[_vm._v("\n              DELETE\n            ")])],1)],1)]}}],null,true)})]}},{key:`item.evidence`,fn:function({ item }){return [(item.evidence)?_c('div',{staticClass:"flex justify-between group flex-col"},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"mb-0 font-bold"},[_vm._v(_vm._s(item.evidence.description))]),_vm._v(" "),(!!item.evidence?.internalUrl)?_c('p',{staticClass:"mb-0 truncate ... max-w-[400px]"},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":item.evidence?.internalUrl}},[_vm._v("\n            "+_vm._s(item.evidence?.filename)+"\n          ")])],1):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mb-0 truncate ... max-w-[400px]"},[_c('v-icon',[_vm._v("mdi-link")]),_vm._v(" "),(item?.evidence.websiteUrl)?_c('a',{attrs:{"target":"_blank","href":item.evidence.websiteUrl}},[_vm._v("\n            "+_vm._s(item.evidence.websiteUrl)+"\n          ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"ml-[10px] mt-2 max-w-[30px] items-center justify-center opacity-0 flex group-hover:opacity-100"},[_c('ix-dialog-edit-evidence',{attrs:{"value":item.evidence},on:{"done":() => {}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)}),_vm._v(" "),_c('v-icon',{staticClass:"ml-4",attrs:{"small":"","color":"danger"},on:{"click":() => {
              _setup.updateEvidenceLink({
                input: { id: item.id, evidenceId: null },
              }).then(() => {
                _setup.refetch()
              })
            }}},[_vm._v("\n          mdi-delete\n        ")])],1)]):_c('ix-dialog-table-evidence',{on:{"done":(value) => {
          _setup.updateEvidenceLink({
            input: { id: item.id, evidenceId: value },
          }).then(() => {
            _setup.refetch()
          })
        }},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',_vm._g(_vm._b({staticClass:"mr-4 text-[var(--v-primary-base)]",attrs:{"icon":"mdi-plus"}},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")]),_vm._v("\n              "+_vm._s(_vm.$t('addEvidenceButton'))+"\n            ")],1)])],1)]}}],null,true)})]}},{key:"footer.prepend",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-btn',{attrs:{"icon":"","color":"primary","size":"small"},on:{"click":() => {
              _setup.addEvidenceLink({
                input: {
                  description: 'New ' + _setup.descriptionHeader,
                  type: _setup.props.query?.type,
                  entityIntId: _setup.props.query?.entityIntIds?.[0],
                  entityUuidId: _setup.props.query?.entityUuidIds?.[0],
                  accountId: _setup.props.query?.accountId,
                },
              }).then((v) => {
                _setup.refetch()
              })
            }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_vm._v(" "),_c('v-spacer')],1)]},proxy:true}],null,true)},'ix-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }