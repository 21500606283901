import "core-js/modules/es6.array.find.js";
export default defineComponent({
  props: {
    ventureId: {
      required: true,
      type: String
    },
    impactGoal: {
      required: true,
      type: Object
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var impactId = computed(function () {
      return parseInt(route.params.impact);
    });
    var impact = computed(function () {
      var _props$impactGoal$imp;
      return (_props$impactGoal$imp = props.impactGoal.impacts) === null || _props$impactGoal$imp === void 0 ? void 0 : _props$impactGoal$imp.find(function (e) {
        return e.id === impactId.value;
      });
    });
    return {
      impact: impact
    };
  }
});