var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ix-section',[(_vm.isFirstAssessment)?_c('div',[_c('ix-section-title',[_vm._v("\n                  "+_vm._s(_vm.$t('esg.introduction.welcomeTitle'))+"\n                ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.renderMarkdown(_vm.$t('esg.introduction.welcomeText'))
                    )}})])],1):_c('div',[_c('ix-section-title',[_vm._v("\n                  "+_vm._s(_vm.$t('esg.introduction.welcomeBackTitle'))+"\n                ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.renderMarkdown(_vm.$t('esg.introduction.welcomeBackText'))
                    )}})])],1),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',{staticClass:"caption",attrs:{"align-self":"end"}},[_vm._v("\n                    "+_vm._s(_vm.$t('esg.introduction.caption'))+"\n                  ")]),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto"}},[_c('ix-button-action',{on:{"click":function($event){_vm.step = 2}}},[_vm._v("\n                      "+_vm._s(_vm.$t('general.navigation.continue'))+"\n                    ")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('ix-section',[_c('ix-section-title',[_vm._v("\n                "+_vm._s(_vm.$t('esg.whoIsItFor.title'))+"\n              ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.$t('esg.whoIsItFor.text')))}}),_vm._v(" "),_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-btn-back',{on:{"click":function($event){_vm.step = 1}}}),_vm._v(" "),_c('ix-button-action',{on:{"click":function($event){_vm.step = 3}}},[_vm._v("\n                    "+_vm._s(_vm.$t('general.navigation.continue'))+"\n                  ")])],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('ix-section',[_c('ix-section-title',[_vm._v("\n                "+_vm._s(_vm.$t('esg.questionStructure.title'))+"\n              ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.$t('esg.questionStructure.text')))}}),_vm._v(" "),_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-btn-back',{on:{"click":function($event){_vm.step = 2}}}),_vm._v(" "),_c('ix-button-action',{attrs:{"nuxt":"","to":{
                      name: 'venture-esg-edit-questionnaire',
                      params: { questionnaire: _vm.questionnaireId },
                    }},on:{"click":function($event){return _vm.$emit('change')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('general.navigation.getStarted'))+"\n                  ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }