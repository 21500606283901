import { render, staticRenderFns } from "./ChipWithImplementationStatus.vue?vue&type=template&id=ab90619a"
import script from "./ChipWithImplementationStatus.vue?vue&type=script&lang=ts"
export * from "./ChipWithImplementationStatus.vue?vue&type=script&lang=ts"
import style0 from "./ChipWithImplementationStatus.vue?vue&type=style&index=0&id=ab90619a&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxDialogConceptContent: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/ConceptContent.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAvatar,VChip,VDialog,VIcon})
