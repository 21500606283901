import { render, staticRenderFns } from "./_questionnaire.vue?vue&type=template&id=3879ceb7"
import script from "./_questionnaire.vue?vue&type=script&lang=ts"
export * from "./_questionnaire.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_questionnaire.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputTextMaster: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/TextMaster.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,CompanyLogo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/company/CompanyLogo.vue').default,IxFormInputSmallOptions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/SmallOptions.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default,EsgCompanySurveyExplanation: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/CompanySurveyExplanation.vue').default,IxButtonInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Info.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VIcon,VProgressLinear,VRow,VScrollYTransition,VTreeview})
