var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"text-decoration-underline cursor-pointer",attrs:{"role":"button"},on:{"click":_vm.openDialog}},[_vm._v("\n    "+_vm._s(_vm.$t(
        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.searchGhgText',
      ))+"\n  ")]),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"1500px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t(
          'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.title',
        )))]),_vm._v(" "),_c('v-card-text',[_c('impact-goal-edit-idemat-search-bar',{on:{"input":_vm.fetchData}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"no-data-text":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.noDataText',
            ),"height":"400px","return-object":"true","show-select":"","single-select":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')],1),_vm._v(" "),_c('ix-section-text',[_c('v-row',{staticClass:"justify-end"},[_c('ix-button-action',{staticClass:"mt-2 mr-2",on:{"click":_vm.closeModal}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.closeModal',
              ))+"\n          ")]),_vm._v(" "),_c('ix-button-action',{staticClass:"mt-2",on:{"click":_vm.addIdematGHG}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.addAndCloseModal',
              ))+"\n          ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }