import { percentage, scoreByMax, scoreText, skipped } from '~/functions/esg'
import { handleUndefined } from '../common'
import { getScoreDetail } from './common'
import { getSecondQuestionIfExists } from './environmental_info'
import { iconMapping, largeProgressBar } from './esg_report'

let labor: any
let laborFirstQuestion: any
let workplace: any
let workplaceFirstQuestion: any
let diversity: any
let diversityFirstQuestion: any
let harm: any
let harmFirstQuestion: any
let responsibility: any
let responsibilityFirstQuestion: any
export const socialSlideContent = (tableData: any) => {
  if (tableData) {
    const subsectionsMap = new Map(
      tableData.subsections.map((subsection) => [subsection.key, subsection]),
    )
    labor = subsectionsMap.get('labor')
    laborFirstQuestion = labor?.questionSummaries[0]
    workplace = subsectionsMap.get('workplace')
    workplaceFirstQuestion = workplace?.questionSummaries[0]

    diversity = subsectionsMap.get('diversityInclusion')
    diversityFirstQuestion = diversity?.questionSummaries[0]

    harm = subsectionsMap.get('societalHarm')
    harmFirstQuestion = harm?.questionSummaries[0]

    responsibility = subsectionsMap.get('socialResponsibility')
    responsibilityFirstQuestion = responsibility?.questionSummaries[0]

    const percentageValue = percentage(tableData.result)
    const label = tableData.label
    return [
      {
        svg: `<svg width="1377" height="65" viewBox="0 0 1377 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0, -50) scale(1.6, 1.6)">
        ${iconMapping.governance}
        </g>
        <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="30" font-weight="600" letter-spacing="0em">
        <tspan x="100" y="50">${label}</tspan>
        </text>
        <g transform="translate(440, 30)">
        ${largeProgressBar(
          getScoreDetail(percentageValue, skipped(tableData.result)),
          percentageValue,
          scoreByMax(tableData.result),
        )}
        </g>
        </svg>`,
        width: 770,
        margin: [0, -10, 0, 0],
      },
      tableData && table(),
    ]
  }
}
const table = () => {
  const scoreColor = (result) =>
    getScoreDetail(percentage(result), skipped(result)).color
  return {
    layout: 'noBorders',
    margin: [0, 10, 0, 0],
    table: {
      widths: [20, 210, 175, 175, 150],
      body: [
        [
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: 'Actions taken',
            style: ['header'],
          },
          {
            text: 'Planned in next 12 months',
            style: ['header'],
          },
          {
            text: 'Comments',
            style: ['header'],
          },
        ],
        [
          {
            svg: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7209 23.5V5.36148C12.7209 5.36148 10.1636 2.52086 1.54168 2.40628C1.47062 2.40558 1.40012 2.41903 1.33431 2.44586C1.26849 2.47268 1.20868 2.51234 1.15835 2.56253C1.05688 2.66513 0.999981 2.80363 1.00002 2.94794V20.0042C0.998862 20.1446 1.05271 20.2799 1.15005 20.3812C1.24739 20.4824 1.38048 20.5415 1.52085 20.5459C10.1615 20.6573 12.7209 23.5 12.7209 23.5Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.59587 12.099C7.82321 11.5027 5.98217 11.1335 4.1167 11" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.59587 16.4687C7.82283 15.8749 5.98186 15.5074 4.1167 15.375" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.8457 12.099C17.6184 11.5027 19.4594 11.1335 21.3249 11" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.8457 16.4687C17.6187 15.8749 19.4597 15.5074 21.3249 15.375" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.3249 2.53126C22.1165 2.46251 22.9728 2.41876 23.8999 2.40626C23.9706 2.40584 24.0407 2.41942 24.1061 2.44624C24.1716 2.47306 24.231 2.51258 24.2811 2.56251C24.3826 2.66512 24.4395 2.80362 24.4395 2.94793V20.0042C24.4406 20.1446 24.3868 20.2799 24.2894 20.3811C24.1921 20.4824 24.059 20.5415 23.9186 20.5458C15.2801 20.6573 12.7207 23.5 12.7207 23.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.7207 23.5V5.36143C12.7207 5.36143 13.9113 4.03852 17.4082 3.16248" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.3145 7.875V1C19.9979 1.10496 18.6918 1.31395 17.4082 1.625V7.875L19.3613 6.3125L21.3145 7.875Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              labor.label,
              {
                text: `   ${scoreText(labor.result)}\n`,
                color: scoreColor(labor.result),
                style: ['totalScore'],
              },
              {
                text: laborFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...laborFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...laborFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(laborFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(labor.questionSummaries),
        [
          {
            svg: `<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4994 23.5V19.7516L15.5049 19.9195C15.9346 19.9913 16.3747 19.9685 16.7946 19.8529C17.2146 19.7373 17.6043 19.5316 17.9367 19.2501C18.2691 18.9686 18.5362 18.6181 18.7193 18.2229C18.9025 17.8277 18.9974 17.3974 18.9974 16.9618V14.5039L19.932 14.271C20.1652 14.2127 20.381 14.0992 20.5611 13.9401C20.7413 13.781 20.8805 13.5809 20.9672 13.3567C21.0538 13.1325 21.0852 12.8908 21.0589 12.6519C21.0325 12.4129 20.9491 12.1839 20.8156 11.984L18.9974 9.25614V8.50647C18.9974 4.08337 14.5204 0.554897 9.18366 1.04568C7.28066 1.21689 5.48171 1.99001 4.04786 3.25286C2.614 4.51571 1.61984 6.2026 1.20962 8.06873C0.799406 9.93486 0.994482 11.8832 1.76654 13.6309C2.5386 15.3787 3.84748 16.835 5.50325 17.7885V23.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.2489 8.89327C15.2639 8.36084 15.0672 7.84417 14.7018 7.45664C14.3363 7.06911 13.8321 6.84236 13.2997 6.82616C12.9446 6.82791 12.5975 6.93203 12.3001 7.12604C12.3168 7.02688 12.3265 6.92667 12.3291 6.82616C12.3445 6.29356 12.1478 5.77664 11.7824 5.38889C11.4169 5.00114 10.9125 4.77425 10.38 4.75806C9.84738 4.77425 9.343 5.00114 8.97755 5.38889C8.6121 5.77664 8.41546 6.29356 8.4308 6.82616C8.43347 6.92667 8.44316 7.02688 8.45979 7.12604C8.16237 6.93203 7.81531 6.82791 7.46022 6.82616C6.92782 6.84236 6.42359 7.06911 6.05817 7.45664C5.69275 7.84417 5.49599 8.36084 5.51106 8.89327C5.49699 9.41656 5.68751 9.92472 6.04214 10.3098C6.39677 10.6948 6.88756 10.9264 7.41024 10.9554C7.19496 11.1532 7.02338 11.3939 6.90651 11.6619C6.78963 11.9299 6.73003 12.2193 6.73153 12.5117C6.71646 13.0441 6.91322 13.5608 7.27864 13.9483C7.64406 14.3359 8.14829 14.5626 8.68069 14.5788C9.0375 14.5732 9.38576 14.4688 9.6868 14.2771C9.98783 14.0855 10.2298 13.8142 10.386 13.4933C10.5419 13.8143 10.7839 14.0858 11.0849 14.2774C11.386 14.4691 11.7344 14.5734 12.0912 14.5788C12.6227 14.5613 13.1257 14.334 13.49 13.9466C13.8544 13.5592 14.0505 13.0433 14.0354 12.5117C14.0369 12.2193 13.9773 11.9299 13.8604 11.6619C13.7435 11.3939 13.572 11.1532 13.3567 10.9554C13.878 10.9244 14.3669 10.692 14.72 10.3072C15.0731 9.92237 15.2628 9.41535 15.2489 8.89327V8.89327Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6689 9.43506C10.7309 9.51277 10.7596 9.61195 10.7485 9.71078C10.7374 9.80961 10.6876 9.89999 10.6099 9.96206" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1421 9.37515C10.1806 9.34448 10.2248 9.32171 10.2722 9.30812C10.3195 9.29454 10.3691 9.29041 10.418 9.29599C10.467 9.30156 10.5143 9.31672 10.5574 9.34059C10.6005 9.36447 10.6384 9.3966 10.6691 9.43515" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.0834 9.90212C10.0214 9.82441 9.99273 9.72523 10.0038 9.6264C10.0149 9.52758 10.0647 9.43719 10.1424 9.37512" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.61 9.96186C10.5715 9.99253 10.5273 10.0153 10.4799 10.0289C10.4326 10.0425 10.383 10.0466 10.3341 10.041C10.2851 10.0355 10.2378 10.0203 10.1947 9.99641C10.1516 9.97254 10.1137 9.9404 10.083 9.90186" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              workplace.label,
              {
                text: `   ${scoreText(workplace.result)}\n`,
                color: scoreColor(workplace.result),
                style: ['totalScore'],
              },
              {
                text: workplaceFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...workplaceFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...workplaceFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(workplaceFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(workplace.questionSummaries),

        [
          {
            svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.625 19.75C5.07475 19.75 6.25 18.5747 6.25 17.125C6.25 15.6753 5.07475 14.5 3.625 14.5C2.17525 14.5 1 15.6753 1 17.125C1 18.5747 2.17525 19.75 3.625 19.75Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.875 19.75C22.3247 19.75 23.5 18.5747 23.5 17.125C23.5 15.6753 22.3247 14.5 20.875 14.5C19.4253 14.5 18.25 15.6753 18.25 17.125C18.25 18.5747 19.4253 19.75 20.875 19.75Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 19.75C13.6997 19.75 14.875 18.5747 14.875 17.125C14.875 15.6753 13.6997 14.5 12.25 14.5C10.8003 14.5 9.625 15.6753 9.625 17.125C9.625 18.5747 10.8003 19.75 12.25 19.75Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.938 23.5C7.69523 22.8409 7.30427 22.2464 6.79531 21.7623C6.28635 21.2783 5.67298 20.9177 5.00254 20.7083C4.3321 20.4989 3.62251 20.4463 2.92853 20.5546C2.23456 20.6629 1.57474 20.9292 1 21.333" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.563 23.5C16.8056 22.8409 17.1965 22.2464 17.7054 21.7624C18.2142 21.2783 18.8275 20.9177 19.4979 20.7083C20.1682 20.4989 20.8778 20.4463 21.5717 20.5546C22.2656 20.6629 22.9253 20.9292 23.5 21.333" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.563 23.5C16.2364 22.6193 15.6481 21.8598 14.8771 21.3233C14.106 20.7869 13.1893 20.4994 12.25 20.4994C11.3107 20.4994 10.394 20.7869 9.62296 21.3233C8.85193 21.8598 8.26359 22.6193 7.93701 23.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5 11.5C14.5 11.5 15.25 7.75 18.25 7" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 6.25H23.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 4.75V7.75" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 11.5C10 11.5 9.25 7.75 6.25 7" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 6.25H1" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 4.75V7.75" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.75 2.5H13.75" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 1V4" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 11.5V7" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              diversity.label,
              {
                text: `   ${scoreText(diversity.result)}\n`,
                color: scoreColor(diversity.result),
                style: ['totalScore'],
              },
              {
                text: diversityFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...diversityFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...diversityFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(diversityFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(diversity.questionSummaries),
        [
          {
            svg: `<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2002 11.5C15.0196 12.7495 14.3948 13.8922 13.4404 14.7186C12.4859 15.545 11.2657 15.9999 10.0032 15.9999C8.74064 15.9999 7.52039 15.545 6.56594 14.7186C5.61149 13.8922 4.98675 12.7495 4.80615 11.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 23.469C1.96968 21.9711 3.30459 20.7445 4.87908 19.9048C6.45356 19.0651 8.21583 18.6398 10 18.669C11.7842 18.6398 13.5464 19.0651 15.1209 19.9048C16.6954 20.7445 18.0303 21.9711 19 23.469" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 23.4999V18.6689" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 7C16.7977 5.61623 17.3079 4.08566 17.5 2.5C15.564 2.98321 13.5191 2.75872 11.734 1.867L10 1L8.266 1.867C6.48094 2.75872 4.436 2.98321 2.5 2.5C2.69295 4.08546 3.20308 5.61586 4 7H16Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.05371 7C4.41771 9.543 7.35371 10 9.99971 10C12.6457 10 15.5817 9.543 15.9457 7H4.05371Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 4C9.92583 4 9.85333 4.02199 9.79166 4.0632C9.72999 4.1044 9.68193 4.16297 9.65355 4.23149C9.62516 4.30002 9.61774 4.37542 9.63221 4.44816C9.64668 4.5209 9.68239 4.58772 9.73483 4.64016C9.78728 4.69261 9.8541 4.72833 9.92684 4.74279C9.99958 4.75726 10.075 4.74984 10.1435 4.72145C10.212 4.69307 10.2706 4.64501 10.3118 4.58334C10.353 4.52167 10.375 4.44917 10.375 4.375C10.375 4.27554 10.3355 4.18016 10.2652 4.10983C10.1948 4.03951 10.0995 4 10 4Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.75 23.5H15.25" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              harm.label,
              {
                text: `   ${scoreText(harm.result)}\n`,
                color: scoreColor(harm.result),
                style: ['totalScore'],
              },
              {
                text: harmFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...harmFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...harmFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(harmFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(harm.questionSummaries),
        [
          {
            svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 12.25C13.4926 12.25 14.5 11.2426 14.5 10C14.5 8.75736 13.4926 7.75 12.25 7.75C11.0074 7.75 10 8.75736 10 10C10 11.2426 11.0074 12.25 12.25 12.25Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 16C15.7771 15.1651 15.2928 14.4235 14.6181 13.8837C13.9433 13.3438 13.1135 13.0342 12.25 13C11.3865 13.0342 10.5567 13.3438 9.88193 13.8837C9.20715 14.4235 8.72288 15.1651 8.5 16" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 4.75C13.2855 4.75 14.125 3.91053 14.125 2.875C14.125 1.83947 13.2855 1 12.25 1C11.2145 1 10.375 1.83947 10.375 2.875C10.375 3.91053 11.2145 4.75 12.25 4.75Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.625 14.125C22.6605 14.125 23.5 13.2855 23.5 12.25C23.5 11.2145 22.6605 10.375 21.625 10.375C20.5895 10.375 19.75 11.2145 19.75 12.25C19.75 13.2855 20.5895 14.125 21.625 14.125Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 23.5C13.2855 23.5 14.125 22.6605 14.125 21.625C14.125 20.5895 13.2855 19.75 12.25 19.75C11.2145 19.75 10.375 20.5895 10.375 21.625C10.375 22.6605 11.2145 23.5 12.25 23.5Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.875 14.125C3.91053 14.125 4.75 13.2855 4.75 12.25C4.75 11.2145 3.91053 10.375 2.875 10.375C1.83947 10.375 1 11.2145 1 12.25C1 13.2855 1.83947 14.125 2.875 14.125Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.751 4.44995C18.1208 5.2422 19.2587 6.38011 20.051 7.74995" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.051 16.75C19.2587 18.1198 18.1208 19.2578 16.751 20.05" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75117 20.05C6.38133 19.2578 5.24342 18.1198 4.45117 16.75" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.45117 7.74995C5.24342 6.38011 6.38133 5.2422 7.75117 4.44995" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              responsibility.label,
              {
                text: `   ${scoreText(responsibility.result)}\n`,
                color: scoreColor(responsibility.result),
                style: ['totalScore'],
              },
              {
                text: responsibilityFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...responsibilityFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...responsibilityFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(responsibilityFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(responsibility.questionSummaries),
      ],
    },
  }
}
