import { render, staticRenderFns } from "./outcome-selection.vue?vue&type=template&id=9e518ac0"
import script from "./outcome-selection.vue?vue&type=script&lang=ts"
export * from "./outcome-selection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SetupOutcomeSelection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SetupOutcomeSelection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VContainer})
