import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import { useGetUserQuery, MetricValueKind, useAddMetricMetricLinkMutation, useListQuestionnairesQuery, useListSectionsQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser) || {
        locale: 'en-US',
        isAdmin: false
      };
    });
    var headers = [{
      value: 'id',
      cellClass: 'font-weight-bold',
      sortable: false // TODO
    }, {
      value: 'period.label'
    }, {
      value: 'venture.name',
      cellClass: 'font-weight-bold',
      sortable: false // TODO
    }, {
      value: 'sections'
    }, {
      value: 'createdAt'
    }, {
      text: $i18n.t('actions'),
      value: 'actions'
    }].map(function (col) {
      return _objectSpread(_objectSpread({}, col), {}, {
        sortable: col.sortable || false
      });
    });
    var options = ref({
      page: 1,
      itemsPerPage: 15
    });
    var filters = ref({
      ventures: []
    });
    var variables = computed(function () {
      return {
        filters: _objectSpread({
          label: ['ESG']
        }, filters.value),
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage
      };
    });
    var _useListQuestionnaire = useListQuestionnairesQuery(variables),
      result = _useListQuestionnaire.result,
      loading = _useListQuestionnaire.loading,
      refetch = _useListQuestionnaire.refetch;
    var items = computed(function () {
      var _result$value$listQue, _result$value;
      return (_result$value$listQue = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listQuestionnaires) !== null && _result$value$listQue !== void 0 ? _result$value$listQue : [];
    });
    var itemCount = computed(function () {
      var _result$value$countQu, _result$value2;
      return (_result$value$countQu = (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countQuestionnaires) !== null && _result$value$countQu !== void 0 ? _result$value$countQu : 0;
    });
    var selected = ref([]);
    var editedItem = ref({});
    var deleteDialog = ref(false);
    var deleteItem = function deleteItem(item) {
      editedItem.value = item;
    };
    var deleteItemConfirm = ref(false);
    var closeDelete = function closeDelete() {
      deleteDialog.value = false;
      deleteItemConfirm.value = false;
    };
    var searchModal = ref(false); // modal used to add children KPIs
    var _useAddMetricMetricLi = useAddMetricMetricLinkMutation(),
      addMetricMetricLink = _useAddMetricMetricLi.mutate;
    var editItemId = ref(0);
    var _useListSectionsQuery = useListSectionsQuery({
        filters: {
          ids: [40, 50, 60, 70]
        }
      }),
      listSectionsResult = _useListSectionsQuery.result;
    var esgSectionsV2 = computed(function () {
      var _listSectionsResult$v, _listSectionsResult$v2;
      return (_listSectionsResult$v = (_listSectionsResult$v2 = listSectionsResult.value) === null || _listSectionsResult$v2 === void 0 ? void 0 : _listSectionsResult$v2.listSections) !== null && _listSectionsResult$v !== void 0 ? _listSectionsResult$v : [];
    });
    var panel = ref(0);
    return {
      refetch: refetch,
      addMetricMetricLink: addMetricMetricLink,
      searchModal: searchModal,
      deleteItemConfirm: deleteItemConfirm,
      deleteDialog: deleteDialog,
      closeDelete: closeDelete,
      deleteItem: deleteItem,
      user: user,
      itemCount: itemCount,
      options: options,
      MetricValueKind: MetricValueKind,
      filters: filters,
      headers: headers,
      selected: selected,
      items: items,
      loading: loading,
      editItemId: editItemId,
      esgSectionsV2: esgSectionsV2,
      panel: panel
    };
  }
});