import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    isFirstAssessment: {
      type: Boolean
    }
  },
  setup: function setup() {
    var questionnaireId = computed(function () {
      return toNumber(useRoute().params.questionnaire);
    });
    return {
      questionnaireId: questionnaireId
    };
  },
  data: function data() {
    return {
      step: 1
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});