
import { defineComponent, Prop, computed } from 'vue'
import { GChart } from 'vue-google-charts'

export default defineComponent({
  name: 'VentureRelatedSdg',
  components: { GChart },
  props: {
    assessments: Array,
    impacts: Array,
    sdg: String,
    positive: Boolean,
    sdgNumber: String,
    stage: String,
  },
  setup(props) {
    const impactsForSdgAndSign = computed(() => {
      if (!props.impacts) return []
      return props.impacts.filter(
        (impact) =>
          impact.isPositive === props.positive &&
          ((impact.primarySDGs &&
            impact.primarySDGs.length &&
            impact.primarySDGs.includes(props.sdg)) ||
            (impact.secondarySDGs &&
              impact.secondarySDGs.length &&
              impact.secondarySDGs.includes(props.sdg))),
      )
    })

    const impactsForAssessment = (assessment: any) => {
      if (assessment === undefined || impactsForSdgAndSign.value === undefined)
        return []
      return impactsForSdgAndSign.value.filter(
        (impact: any) => impact.assessment[0] === assessment.id,
      )
    }

    const assessmentsForSelectedImpact = computed(() => {
      return props.assessments.filter((e) =>
        impactsForSdgAndSign.value.some((i) => i.assessment.includes(e.id)),
      )
    })

    const number = computed(() => {
      return parseInt((props.sdgNumber.match(/\d+/g) as string[])[0])
    })

    return {
      impactsForSdgAndSign,
      impactsForAssessment,
      assessmentsForSelectedImpact,
      number,
    }
  },
})
