var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_setup.alignedInvestmentsTreeview,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"treeview-label-markdown text-wrap mb-0 py-2 pl-2",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(item.name))}})],1)])]}}])})],1)],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center justify-center p-6 border border-gray-300 rounded-lg bg-lightPink-base mt-4 bg-[var(--v-lightPink-base)]"},[_c('p',{domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_setup.editableFundReportAdditionalInfo[3]))}}),_vm._v(" "),_c('v-row',[[_c('v-col',{staticClass:"center-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"chart-container"},[_c(_setup.GChart,{attrs:{"type":"BarChart","options":_setup.investmentsAlignedWithEuTaxonomyChartOptions(
                  'Taxonomy-alignment of investments excluding sovereign bonds*',
                ),"data":_vm.percentageOfInvestmentsAlignedWithEuTaxonomy?.excludingSovereignBonds ||
                []}})],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"legend-container"},_vm._l(([
                { label: 'Taxonomy aligned - fossil gas', color: '#809c7c' },
                { label: 'Taxonomy aligned - nuclear', color: '#50744c' },
                {
                  label: 'Taxonomy aligned (no gas and nuclear)',
                  color: '#083404',
                },
                { label: 'Other investments', color: '#e0dcdc' },
              ]),function(item,index){return _c('div',{key:index,staticClass:"legend-item"},[_c('div',{staticClass:"color-box",style:({ backgroundColor: item.color })}),_vm._v(" "),_c('div',{staticClass:"legend-label"},[_vm._v(_vm._s(item.label))])])}),0)])]],2),_vm._v(" "),_c('p',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_setup.editableFundReportAdditionalInfo[4]))}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }