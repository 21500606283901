import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.number.is-integer.js";
import { replaceCommaAndParseFloatOrNull } from '~/assets/helper/functions';
import { MetricValueKind } from '~/types/types';
export default defineComponent({
  props: {
    metric: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 400
    }
  },
  setup: function setup(props) {
    return {
      MetricValueKind: MetricValueKind,
      disabled: computed(function () {
        var _props$value;
        return props.disabled || !((_props$value = props.value) !== null && _props$value !== void 0 && _props$value.editable);
      }),
      valueType: computed(function () {
        return props.metric.valueType;
      })
    };
  },
  data: function data() {
    var _this = this;
    return {
      integerRules: [function (v) {
        return _this.isIntegerOrEmpty(v) || _this.$t('integerWarning') + _this.$t('notSaved');
      }],
      floatRules: [function (v) {
        return _this.isNumberOrEmpty(v) || _this.$t('numberWarning') + _this.$t('notSaved');
      }]
    };
  },
  methods: {
    stringToArray: function stringToArray(val) {
      if (typeof val === 'string') {
        return [val];
      } else if (Array.isArray(val)) {
        // value is stored as a {value: 'foo'}
        return val.map(function (e) {
          return (e === null || e === void 0 ? void 0 : e.value) || e;
        });
      } else if (val !== null && val !== void 0 && val.value) {
        return [val.value]; // single select
      }
      return val;
    },
    matchesNumberFormat: function matchesNumberFormat(str) {
      return /^[0-9]*[.,]?[0-9]*$/.test(str);
    },
    isNumberOrEmpty: function isNumberOrEmpty(str) {
      return !str || this.matchesNumberFormat(str);
    },
    isIntegerOrEmpty: function isIntegerOrEmpty(str) {
      return !str || this.matchesNumberFormat(str) && Number.isInteger(replaceCommaAndParseFloatOrNull(str));
    },
    handleInput: function handleInput(key, val) {
      var valid = this.$refs.form.validate();
      if (valid) {
        var input = {
          metricAssignmentId: this.value.metricAssignmentId,
          period: this.value.period
        };
        input[key] = val;
        this.$emit('input', input);
      }
      // if invalid we don't propagate the input, so on save this field will not be saved. propagating the "valid" status to the parents turned out to be fairly complex and ugly due to the different cases for reportingPeriodType and valueType, so preventing the input felt more maintainable
    },
    formatNumber: function formatNumber(val) {
      // this prevents the scientific notation like 1e-7 which is not compatible with our rules for numbers
      return val === null || val === void 0 ? void 0 : val.toLocaleString('en-US', {
        useGrouping: false,
        // Disables thousand separators
        minimumFractionDigits: 0,
        maximumFractionDigits: 20 // Adjust based on the precision needed
      });
    }
  }
});