var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._b({attrs:{"loading":_vm.loading,"value":_vm.selected,"dense":"","item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"items":_vm.items,"sort-by":"name","options":_vm.options,"server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [15, 30, 50, -1],
  },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.options.search,"append-icon":"mdi-magnify","label":_vm.$t('labelSearchVentures'),"single-line":"","hide-details":""},on:{"input":(v) => {
            _vm.options.search = v
            _vm.options.page = 1
          }}})],1)]},proxy:true},{key:`item.location`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.location_info?.city_name)+" "+_vm._s(item.location_info?.country_name)+"\n  ")]}},{key:`item.date`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(new Date(item.date).toString())+"\n  ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":""},on:{"click":() => {}}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }