import _ from 'lodash'
import Sugar from 'sugar'
import inactiveManagementAdvisoryBoardIcon from '~/assets/icons/board/grey.svg'
import activeManagementAdvisoryBoardIcon from '~/assets/icons/board/white.svg'
import inactiveRegulationsStandardsIcon from '~/assets/icons/certifications-accreditations/grey.svg'
import activeRegulationsStandardsIcon from '~/assets/icons/certifications-accreditations/white.svg'
import inactiveDataIcon from '~/assets/icons/data-policies/grey.svg'
import activeDataIcon from '~/assets/icons/data-policies/white.svg'
import inactiveDiversityInclusionIcon from '~/assets/icons/diversity-inclusion/grey.svg'
import activeDiversityInclusionIcon from '~/assets/icons/diversity-inclusion/white.svg'
import inactiveEnergyIcon from '~/assets/icons/energy/grey.svg'
import activeEnergyIcon from '~/assets/icons/energy/white.svg'
import environmentalIcon from '~/assets/icons/environmental.svg'
import inactiveSocietalHarmIcon from '~/assets/icons/ethics-code-of-conduct-alternate/grey.svg'
import activeSocietalHarmIcon from '~/assets/icons/ethics-code-of-conduct-alternate/white.svg'
import inactiveBusinessEthicsIcon from '~/assets/icons/ethics-code-of-conduct/grey.svg'
import activeBusinessEthicsIcon from '~/assets/icons/ethics-code-of-conduct/white.svg'
import inactiveGhgEmissionsIcon from '~/assets/icons/ghg-emissions/grey.svg'
import activeGhgEmissionsIcon from '~/assets/icons/ghg-emissions/white.svg'
import governanceIcon from '~/assets/icons/governance.svg'
import inactiveLaborIcon from '~/assets/icons/labor/grey.svg'
import activeLaborIcon from '~/assets/icons/labor/white.svg'
import inactivePollutionAndEnvironmentalHarmIcon from '~/assets/icons/pollution/grey.svg'
import activePollutionAndEnvironmentalHarmIcon from '~/assets/icons/pollution/white.svg'
import inactiveResourceConsumptionAndUsageIcon from '~/assets/icons/resource-management/grey.svg'
import activeResourceConsumptionAndUsageIcon from '~/assets/icons/resource-management/white.svg'
import inactiveSocialResponsibilityIcon from '~/assets/icons/social-responsibility/grey.svg'
import activeSocialResponsibilityIcon from '~/assets/icons/social-responsibility/white.svg'
import socialIcon from '~/assets/icons/social.svg'
import inactiveStakeholderEngagementIcon from '~/assets/icons/stakeholder-engagement/grey.svg'
import activeStakeholderEngagementIcon from '~/assets/icons/stakeholder-engagement/white.svg'
import inactiveTransportationIcon from '~/assets/icons/transportation/grey.svg'
import activeTransportationIcon from '~/assets/icons/transportation/white.svg'
import inactiveWorkplaceIcon from '~/assets/icons/workplace/grey.svg'
import activeWorkplaceIcon from '~/assets/icons/workplace/white.svg'

export function getSafe(fn: Function) {
  try {
    return fn()
  } catch (e) {}
}

export function arrayAverage(...arr) {
  if (arr.length === 0) return 0
  const avg = arr.reduce((p, c) => p + c, 0) / arr.length
  return avg
}

export function getClickableLink(link: String) {
  return link?.startsWith('http://') || link?.startsWith('https://')
    ? link
    : `http://${link}`
}

export function replaceBraces(name) {
  return name.replace(/{{|}}/g, '')
}

export function formatAsGermanDate(date: string) {
  const parsedDate = new Date(date)
  if (isNaN(parsedDate.getTime())) {
    return null
  }
  return parsedDate.toLocaleDateString('de-DE')
}

export function parseObserver(obj): Record<string, any> {
  return JSON.parse(JSON.stringify(obj))
}

export function logObserver(obj: Record<string, any>, ...logs: any): void {
  if (!obj) {
    console.log(...logs, obj)
  }
  console.log(...logs, parseObserver(obj))
}

export function camelCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function userDefined(
  createdBy: String,
  loggedInVentureId: String,
): Boolean {
  return _.isEmpty(createdBy) || createdBy === loggedInVentureId
}

export function mergeArrays(arr1, arr2) {
  // Convert arr1 to a standardized format with objects
  const formattedArr1 = arr1.map((item) =>
    typeof item === 'string' ? { value: item } : item,
  )

  // Create a map from arr1 for easy lookup and merging
  const map = new Map()
  formattedArr1.forEach((item) => map.set(item.value, item))

  // Merge items from arr2 into the map
  arr2.forEach((item) => {
    if (map.has(item.value)) {
      map.set(item.value, { ...map.get(item.value), ...item })
    } else {
      map.set(item.value, item)
    }
  })

  // Convert the map back to an array
  return Array.from(map.values())
}

export function portfolioCustomSort(
  items: any[],
  index: string,
  isDesc: boolean[],
) {
  items.sort((a: any, b: any) => {
    let aVal = a[index[0]]
    let bVal = b[index[0]]
    if (aVal === undefined || aVal === null) {
      return 1
    } else if (bVal === undefined || bVal === null) {
      return -1
    }
    if (index[0] === 'update') {
      if (!isDesc[0]) {
        return new Sugar.Date(aVal).isBefore(bVal).raw ? -1 : 1
      } else {
        return new Sugar.Date(bVal).isBefore(aVal).raw ? -1 : 1
      }
    } else if (index[0] === 'investment') {
      aVal = Number(aVal.replace(/[^\d]/g, ''))
      bVal = Number(bVal.replace(/[^\d]/g, ''))
    } else if (typeof aVal === 'string') {
      aVal = aVal.toLowerCase()
      bVal = bVal.toLowerCase()
    }

    if (!isDesc[0]) {
      return aVal > bVal ? -1 : 1
    } else {
      return aVal < bVal ? -1 : 1
    }
  })
  return items
}

export function urlDomain(url: string) {
  const a = document.createElement('a')
  a.href = url
  const items = a.hostname.split('.')
  return items.splice(items.length - 2).join('.')
}

const STATES: Record<string, number> = {
  'Outcome-selection input': 0,
  'Outcome-selection in review': 1,
  'Assessment input': 2,
  'Assessment in review': 3,
  'Assessment reviewed': 4,
  'Assessment review confirmed': 5,
}

export function isAfterState(
  currentState: string,
  comparedState: string,
): boolean {
  return STATES[currentState] > STATES[comparedState]
}

export function isAtState(
  currentState: string,
  comparedState: string,
): boolean {
  return STATES[currentState] === STATES[comparedState]
}

export function isAtOrAfterState(
  currentState: string,
  comparedState: string,
): boolean {
  return STATES[currentState] >= STATES[comparedState]
}

export const STAGE_DESCRIPTION: any = {
  'Outcome-selection venture': 'Step 1: Initial impacts proposed by venture',
  'Outcome-selection reviewer': 'Step 2: Initial impacts proposed by reviewer',
  'Assessment venture': 'Step 3: Company self-assessment of selected impacts',
  'Assessment reviewer': 'Step 4: Reviewer assessment of selected impacts',
}

export const STAGES = [
  'Outcome-selection venture',
  'Outcome-selection reviewer',
  'Assessment venture',
  'Assessment reviewer',
]

export const VENTURE_STEPS = [
  {
    number: 0,
    title: 'Impact selection',
    explanation: [
      'Provide basic information of your venture',
      'Describe the most relevant value chain elements',
      'Define the most important impacts you create and link them to your value chain',
    ],
    active: true,
    tasks: [
      {
        component: 'venture-progress-introduction-button',
      },
      {
        component: 'venture-progress-key-info-button',
      },
      {
        component: 'venture-progress-value-chain-button',
      },
      {
        component: 'venture-progress-edit-impacts-button',
      },
      {
        component: 'venture-progress-submit-impact-selection-for-review-button',
      },
    ],
  },
  {
    number: 1,
    title: 'Review of impact selection',
    explanation: ['Your impacts are reviewed and potentially modified'],
    active: false,
    tasks: [],
  },
  {
    number: 2,
    title: 'Impact scoring',
    explanation: [
      'Score the selected impacts',
      'Provide evidence for your selected scores',
    ],
    active: true,
    tasks: [
      {
        component: 'venture-progress-score-impacts-button',
      },
      {
        component: 'venture-progress-submit-impact-scoring-for-review-button',
      },
    ],
  },
  {
    number: 3,
    title: 'Review of impact scoring',
    explanation: ['Your scoring is reviewed and potentially modified'],
    active: false,
    tasks: [],
  },
  {
    title: 'Assessment complete',
    explanation: ['Obtain the results of your assessment'],
    active: true,
    tasks: [],
  },
]

export const REVIEWER_STEPS = [
  {
    number: 0,
    title: 'Impact selection',
    explanation: [
      'Company provides basic information of your venture',
      'Company describes the most relevant value chain elements',
      'Company defines the most important impacts they create and link them to their value chain',
    ],
    active: false,
    tasks: [],
  },
  {
    number: 1,
    title: 'Review of impact selection',
    explanation: [
      'Review, edit or delete the impacts defined by the venture',
      'You may also create new impacts',
    ],
    active: true,
    tasks: [
      {
        component: 'venture-progress-review-impacts-button',
      },
      {
        component: 'venture-progress-submit-for-scoring-button',
      },
    ],
  },
  {
    number: 2,
    title: 'Impact scoring',
    explanation: [
      'Company scores the selected impacts',
      'Company provides evidence for their selected scores',
    ],
    active: false,
    tasks: [],
  },
  {
    number: 3,
    title: 'Review of impact scoring',
    explanation: [
      'Review the scored impacts by the company and edit them',
      'Add, modify and delete evidence for the scores',
    ],
    active: true,
    tasks: [
      {
        component: 'venture-progress-review-scored-impacts-button',
      },
      {
        component: 'venture-progress-submit-reviewed-scoring-button',
      },
    ],
  },
  {
    title: 'Assessment complete',
    explanation: ['Obtain the results of your assessment'],
    active: false,
    tasks: [],
  },
]

export const esgSectionIdMapping = {
  1: 'company',
  2: 'environmental',
  3: 'social',
  6: 'basicInformation',
  7: 'additionalContextInformation',
  8: 'disclosure',
  9: 'ghgEmissions',
  10: 'energy',
  11: 'transportation',
  12: 'resourceConsumptionAndUsage',
  13: 'pollutionAndEnvironmentalHarm',
  14: 'labor',
  15: 'workplace',
  16: 'diversityInclusion',
  17: 'societalHarm',
  18: 'socialResponsibility',
  19: 'governance',
  20: 'data',
  21: 'stakeholderEngagement',
  23: 'businessEthics',
  24: 'managementAdvisoryBoard',
  25: 'regulationsStandards',
  26: 'unGlobalCompactPrinciples',
  27: 'controversialBusinessAreasActivities',
  28: 'publicOrLegalControversies',
  40: 'environmental',
  41: 'ghgEmissions',
  42: 'energy',
  43: 'transportation',
  44: 'resourceConsumptionAndUsage',
  45: 'pollutionAndEnvironmentalHarm',
  50: 'social',
  51: 'labor',
  52: 'workplace',
  53: 'diversityInclusion',
  54: 'societalHarm',
  55: 'socialResponsibility',
  60: 'governance',
  61: 'data',
  62: 'stakeholderEngagement',
  63: 'businessEthics',
  64: 'managementAdvisoryBoard',
  65: 'regulationsStandards',
  70: 'disclosure',
  71: 'oecdGuidelinesForMultinationalEnterprises',
  72: 'unGuidingPrinciplesOnBusinessAndHumanRights',
  73: 'controversialBusinessAreasActivities',
  74: 'publicOrLegalControversies',
  77: 'unGlobalCompactPrinciples',
}

export const esgSectionDetails = {
  ghgEmissions: {
    id: 'ghgEmissions',
    sdgs: [13],
    gris: [305],
    explanation: 'esg.sectionDetailsExplanations.ghgEmissions',
    // 'Climate change will damage economies, devastate populations, increase resource scarcity and dramatically impact the cost of doing business. So for both humanitarian and business reasons, it is imperative that companies of all sizes take action.',
    levers: [
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c1',
        url: 'https://ghgprotocol.org/sites/default/files/standards/ghg-protocol-revised.pdf',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c2',
        url: 'https://ghgprotocol.org/sites/default/files/standards/Corporate-Value-Chain-Accounting-Reporing-Standard_041613_2.pdf',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c3',
        url: 'https://ghgprotocol.org/sites/default/files/standards/Scope3_Calculation_Guidance_0.pdf',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c4',
        url: 'https://ghgprotocol.org/sites/default/files/standards/Scope%202%20Guidance_Final_Sept26.pdf',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c5',
        url: 'https://ghgprotocol.org/sites/default/files/standards/ghg_project_accounting.pdf',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c6',
        url: 'https://loopup.com/de/resource-center/blog/responsible-business-travel-9-steps-that-companies-can-take-to-reduce-their-carbon-footprint/',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c7',
        url: 'https://www.greenbiz.com/article/how-undertake-life-cycle-assessment-your-products-and-services',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c8',
        url: 'https://www.greenbiz.com/article/4-steps-companies-can-take-offset-carbon-emissions-and-protect-forests',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c9',
        url: 'https://solumesl.com/en/insights/how-can-factories-become-good-for-the-environment',
      },
      // {
      //   comment:
      //     'Guide to switching to renewable energy. How to choose the right renewable energy for your business.',
      //   url: 'https://bionic.co.uk/business-energy/guides/guide-to-renewable-energy-for-small-businesses/',
      // },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c10',
        url: 'https://www.nibusinessinfo.co.uk/content/how-select-sustainable-supplier',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c11', // 'How to select eco-conscious suppliers',
        url: 'https://makersrow.com/blog/2019/09/how-to-find-a-more-sustainable-manufacturer-and-supplier/',
      },
      {
        comment: 'esg.sectionDetails.ghgEmissions.leversComments.c12', // 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        url: 'https://www.supplychainbrain.com/blogs/1-think-tank/post/31677-how-to-choose-eco-conscious-suppliers-effectively',
      },
    ],
    kpis: [
      'Number of GHG emissions (ideally split into the GHG Protocol Scopes 1, 2 and 3)',
    ],
  },
  energy: {
    id: 'energy',
    sdgs: [7],
    gris: [302],
    explanation: 'esg.sectionDetailsExplanations.energy',
    // 'The earth is running out of non-renewable energy resources, such as coal, crude oil, and natural gas. So, it is becoming more important to conserve energy and to find renewable sources of energy. Moreover, fossil energy resources are a major driver of environmental problems e.g. caused by increasing GHG emissions.',
    levers: [
      {
        comment: 'esg.sectionDetails.energy.leversComments.c1', // 'Energy management system Guide for companies - from the German Federal Ministry for the Environment, Nature Conservation and Nuclear Safety (BMU)',
        url: 'https://www.adelphi.de/en/system/files/mediathek/bilder/energy-management-systems-in-practice_bmub-uba-adelphi.pdf',
      },
      {
        comment: 'esg.sectionDetails.energy.leversComments.c2', // 'Practical Guide for implementing an energy management system - from the United Nations Industrial Development Organization (UNIDO)',
        url: 'https://www.industrialenergyaccelerator.org/wp-content/uploads/IEE_EnMS-Practical-Guide.pdf',
      },
      {
        comment: 'esg.sectionDetails.energy.leversComments.c3', // '10 Strategies for Energy management',
        url: 'https://facilityexecutive.com/2015/12/10-strategies-for-energy-management/',
      },
      {
        comment: 'esg.sectionDetails.energy.leversComments.c4', // '7 Strategies To Make Your Business More Energy Efficient',
        url: 'https://www.buildings.com/articles/27420/7-strategies-make-your-business-more-energy-efficient',
      },
      {
        comment: 'esg.sectionDetails.energy.leversComments.c5', // 'How companies can conduct energy audit.',
        url: 'https://www.eonenergy.com/business/news-and-insights/your-business/how-to-conduct-your-own-business-energy-audit.html',
      },
      // {
      //   comment:
      //     'Guide to switching to renewable energy. How to choose the right renewable energy for your business.',
      //   url: 'https://bionic.co.uk/business-energy/guides/guide-to-renewable-energy-for-small-businesses/',
      // },
      // {
      //   comment: 'Sustainable buildings and workplace.',
      //   url: 'https://hasenstabinc.com/top-10-ways-to-incorporate-sustainability-into-building-design/',
      // },
    ],
    kpis: [
      'Overall energy consumption per year',
      'Amount/Percentage of renewable energy produced/utilized',
    ],
  },
  transportation: {
    id: 'transportation',
    sdgs: [13],
    gris: [302, 305],
    explanation: 'esg.sectionDetailsExplanations.transportation',
    // 'Making sustainable transportation choices reduces business expenses, reduces dependence on foreign oil, improves air quality, reduces GHG emissions, and promotes public health.',
    levers: [
      {
        comment: 'esg.sectionDetails.transportation.leversComments.c1', // 'Guide of how to implement a remote working strategy.',
        url: 'https://www.airswift.com/blog/remote-working-strategy',
      },
      {
        comment: 'esg.sectionDetails.transportation.leversComments.c2', // '9 steps companies can take to reduce business travel emissions',
        url: 'https://loopup.com/de/resource-center/blog/responsible-business-travel-9-steps-that-companies-can-take-to-reduce-their-carbon-footprint/',
      },

      {
        comment: 'esg.sectionDetails.transportation.leversComments.c3', // '7 top tips to travel more sustainable/greener for business',
        url: 'https://www.enterango.com/blog/sustainable-business-travel/7-tips-on-how-you-can-travel-more-sustainable-for-business/',
      },
      {
        comment: 'esg.sectionDetails.transportation.leversComments.c4', // 'How to select a sustainability-conscious supplier',
        url: 'https://www.nibusinessinfo.co.uk/content/how-select-sustainable-supplier',
      },
      {
        comment: 'esg.sectionDetails.transportation.leversComments.c5', // 'How to select eco-conscious suppliers',
        url: 'https://makersrow.com/blog/2019/09/how-to-find-a-more-sustainable-manufacturer-and-supplier/',
      },
      {
        comment: 'esg.sectionDetails.transportation.leversComments.c6', // 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        url: 'https://www.supplychainbrain.com/blogs/1-think-tank/post/31677-how-to-choose-eco-conscious-suppliers-effectively',
      },
    ],
    kpis: [
      'Number of trips by mode of transportation',
      'Amount/Percentage of renewable energy produced/utilizedTotal number of kms travelled/good transported for each mode of transportation',
    ],
  },
  resourceConsumptionAndUsage: {
    id: 'resourceConsumptionAndUsage',
    sdgs: [12],
    gris: [301, 306],
    explanation: 'esg.sectionDetailsExplanations.resourceConsumptionAndUsage',
    // 'Human activities have continuously put pressure on the existing resources and even caused the depletion of some. Sustainable management of resources is one of the best ways of ensuring the conservation of these invaluable resources for future generations.',

    levers: [
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c1', // 'How to Undertake a Life Cycle Assessment of Your Products and Services',
        url: 'https://www.greenbiz.com/article/how-undertake-life-cycle-assessment-your-products-and-services',
      },
      // {
      //   comment: 'Sustainable buildings and workplace.',
      //   url: 'https://hasenstabinc.com/top-10-ways-to-incorporate-sustainability-into-building-design/',
      // },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c2', // '6 Steps to More Effective Water Conservation for Businesses',
        url: 'https://www.pge.com/en/mybusiness/save/smbblog/article/6-steps-to-more-effective-water-conservation-for-businesses.page?redirect=yes',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c3', // 'How New Businesses Can Improve Their Waste Management',
        url: 'https://www.forbes.com/sites/serenitygibbons/2020/07/09/how-new-businesses-can-improve-their-waste-management/',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c4', // 'Top 20 tips to reduce waste in the workplace',
        url: 'https://www.edie.net/news/5/Top-20-tips-to-reduce-waste-in-the-workplace/',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c5', // 'Step-by-Step Guide to Sustainable Supply Chain Management from the German Federal Ministry for the Environment, Nature Conservation, Building and Nuclear Safety (BMUB)',
        url: 'https://www.bmuv.de/fileadmin/Daten_BMU/Pools/Broschueren/nachhaltige_lieferkette_en_bf.pdf',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c6', // 'A guide for business and policy makers to Life Cycle Thinking and Assessment from the EU Commission for Environment',
        url: 'https://eplca.jrc.ec.europa.eu/uploads/LCT-Making-sustainable-consumption-and-production-a-reality-A-guide-for-business-and-policy-makers-to-Life-Cycle-Thinking-and-Assessment.pdf',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c7', // 'How to select a sustainability-conscious supplier',
        url: 'https://www.nibusinessinfo.co.uk/content/how-select-sustainable-supplier',
      },
      {
        comment:
          'esg.sectionDetails.resourceConsumptionAndUsage.leversComments.c8', // 'How to select eco-conscious suppliers',
        url: 'https://makersrow.com/blog/2019/09/how-to-find-a-more-sustainable-manufacturer-and-supplier/',
      },
    ],

    kpis: [
      'Amount of waste created',
      'Amount of waste recycled',
      'Amount of reused material procured',
      'Amount of refurbished/recycled products procured',
    ],
  },
  pollutionAndEnvironmentalHarm: {
    id: 'pollutionAndEnvironmentalHarm',
    sdgs: [14, 15, 3],
    gris: [305, 306],
    explanation: 'esg.sectionDetailsExplanations.pollutionAndEnvironmentalHarm',
    // 'Business activities can be extremely harmful to the environment. For example, many production processes create pollutants that cause long-term damages to the environment and all beings in it (including humans). To mitigate these damages, it is crucial to monitor all types of pollutants associated with the business activities and to mitigate them wherever possible.',
    levers: [
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c1', // 'How to Undertake a Life Cycle Assessment of Your Products and Services',
        url: 'https://www.greenbiz.com/article/how-undertake-life-cycle-assessment-your-products-and-services',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c2', // 'Steps companies can take to offset carbon emissions.',
        url: 'https://www.greenbiz.com/article/4-steps-companies-can-take-offset-carbon-emissions-and-protect-forests',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c3', // 'A guide for business and policy makers to Life Cycle Thinking and Assessment from the EU Commission for Environment',
        url: 'https://eplca.jrc.ec.europa.eu/uploads/LCT-Making-sustainable-consumption-and-production-a-reality-A-guide-for-business-and-policy-makers-to-Life-Cycle-Thinking-and-Assessment.pdf',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c4', // 'How Businesses Can Improve Biodiversity',
        url: 'https://www.acerecology.co.uk/how-can-we-help-you/business-for-biodiversity/',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c5', // 'Here are 5 ways companies can clean up the air',
        url: 'https://www.greenbiz.com/article/here-are-5-ways-companies-can-clean-air',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c6', // 'How businesses can improve environmental impact consciousness',
        url: 'https://solve.co.uk/ethical-business/eco-friendly-business-tips/',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c7', // 'How businesses can become more eco-conscious',
        url: 'https://dotknowledge.uk/articles/view-article/9-practical-ways-your-business-can-be-more-eco-friendly',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c8', // 'How to green your business by preventing pollution',
        url: 'https://www.canada.ca/en/environment-climate-change/services/pollution-prevention/business.html',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c9', // '5 Easy Ways Manufacturers Can Reduce Air Pollution',
        url: 'https://ecowarriorprincess.net/2020/02/5-easy-ways-manufacturers-reduce-air-pollution/',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c10', // '7 recommendations for incorporating Indigenous perspectives into Biodiversity considerations.',
        url: 'https://ensia.com/notable/protecting-indigenous-conservation-practices/',
      },
      {
        comment:
          'esg.sectionDetails.pollutionAndEnvironmentalHarm.leversComments.c11', // 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        url: 'https://www.supplychainbrain.com/blogs/1-think-tank/post/31677-how-to-choose-eco-conscious-suppliers-effectively',
      },
    ],
    kpis: ['Amount of pollution generated for each type of pollutant'],
  },
  labor: {
    id: 'labor',
    sdgs: [16, 8],
    gris: [409],
    explanation: 'esg.sectionDetailsExplanations.labor',
    // 'Compliance with labor and human rights along the entire value chain is important from an ethical, business and legal perspective. In recent years, the expectations of customers, investors and regulators have increased, resulting in continuously increasing costs for misconduct (e.g. in the form of heavy fines and reputational damage.)',
    levers: [
      {
        comment: 'esg.sectionDetails.labor.leversComments.c1', // "The best OECD's practices regarding employment.",
        url: 'https://www.oecd.org/daf/inv/mne/48004323.pdf',
      },
      {
        comment: 'esg.sectionDetails.labor.leversComments.c2',
        // 'UN Guide of how companies should adress and respect human rights.',
        url: 'https://www.ohchr.org/documents/publications/guidingprinciplesbusinesshr_en.pdf',
      },
      {
        comment: 'esg.sectionDetails.labor.leversComments.c3', // 'Highlights the main international agreements regarding wages.',
        url: 'https://www.ethicaltrade.org/issues/living-wage-workers/living-wage-standards',
      },
      {
        comment: 'esg.sectionDetails.labor.leversComments.c4', // 'International Labor Organization guidelines about minimum wage, living wage and fair wage.',
        url: 'https://www.ilo.org/wcmsp5/groups/public/---ed_norm/---relconf/documents/meetingdocument/wcms_235287.pdf',
      },
    ],
    kpis: [
      'Percentage of suppliers screened',
      'Number of grievances filed, addressed and resolved',
    ],
  },
  workplace: {
    id: 'workplace',
    sdgs: [3, 8],
    gris: [403, 409],
    explanation: 'esg.sectionDetailsExplanations.workPlace',
    // 'Offering good and fair benefits packages and caring about the needs of your employees can improve employee morale. That, in turn, can improve employee motivation, increase retention and contribute to more productivity. On top of this, providing a good work environment can contribute significantly to a positive brand.',
    levers: [
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c1', // 'Guide of how to implement a remote working strategy.',
        url: 'https://www.airswift.com/blog/remote-working-strategy',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c2', // 'Interesting and basic tool that may help companies figure out the fair wage in a specific country.',
        url: 'https://www.fairwageguide.org/',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c3', // 'List of the best employee benefits package.',
        url: 'https://www.outbackteambuilding.com/blog/best-employee-benefits-packages-and-perks/',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c4', // 'A guide to employee benefits.',
        url: 'https://blog.vantagecircle.com/employee-benefits-compensation-ideas/',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c5', // 'Insteresting guideline of how to create a home office policy with tools, templates and samples.',
        url: 'https://snacknation.com/blog/work-from-home-policy/',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c6', // 'How to adress mental health issues at the workplace policy.',
        url: 'https://www.thehrdirector.com/updating-your-mental-health-policy/',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c7', // 'Fair pay must be determined for the specific context of a company (i.e. geography, sector, types of jobs, sector benchmarks)',
        url: 'https://smallbusiness.chron.com/determine-fair-market-value-employee-wages-39899.html',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c8', // 'Measuring employee engagement is crucial to staying up to date with your staff’s needs and this blog gives some insight into this',
        url: 'https://builtin.com/employee-engagement/how-to-improve-employee-engagement',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c9', // 'Highlights the main international agreements regarding wages.',
        url: 'https://www.ethicaltrade.org/issues/living-wage-workers/living-wage-standards',
      },
      {
        comment: 'esg.sectionDetails.workplace.leversComments.c10', // 'International Labor Organization guidelines about minimum wage, living wage and fair wage.',
        url: 'https://www.ilo.org/wcmsp5/groups/public/---ed_norm/---relconf/documents/meetingdocument/wcms_235287.pdf',
      },
    ],
    kpis: [
      'Number of flexible work arrangements broken down by types of arrangements',
      'Total value of social & health related benefits per employee',
      'Number of employees earning minimum wage',
      'Number of employees earning a living wage',
      'Wage gap between different employees of a company',
    ],
  },
  diversityInclusion: {
    id: 'diversityInclusion',
    sdgs: [5, 8],
    gris: [405],
    explanation: 'esg.sectionDetailsExplanations.diversityInclusion',
    //      'An organization that prioritizes diversity, equity, and inclusion creates an environment that respects and values individual differences along varying dimensions. Such a work environment leads to better cooperation between employees, increases retention and contributes to more productivity.',
    levers: [
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c1', // 'How to add Age in your diversity and inclusion policy',
        url: 'https://www.aarp.org/work/employers/info-2021/age-diversity-inclusion-guide.html',
      },
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c2', // 'Taking a closer look at diversity winners reveals what can drive real progress in the diversity aspects of your company.',
        url: 'https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-how-inclusion-matters',
      },
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c3', // 'A substantial diversity and inclusion strategy can your company to attain top talent, driving innovative outcomes.',
        url: 'https://firstup.io/blog/15-ways-to-improve-diversity-and-inclusion-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c4', // 'The main requirements to really have a diverse and inclusive culture in your company',
        url: 'https://www.gallup.com/workplace/242138/requirements-diverse-inclusive-culture.aspx',
      },
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c5', // 'How to have a disability inclusive workplace',
        url: 'https://www.betterup.com/blog/disability-inclusion',
      },
      {
        comment: 'esg.sectionDetails.diversityInclusion.leversComments.c6', // 'How to remove biases, apply fair paying and focus on the results and creating an inclusing hiring process to promote gender equality in the workplace.',
        url: 'https://www.shl.com/resources/by-type/blog/2021/5-ways-to-promote-gender-equality-in-the-workplace/',
      },
    ],
    kpis: [
      'Percentage of female/disabled/ethnic employees',
      'Ratio of female to male employees',
    ],
  },
  societalHarm: {
    id: 'societalHarm',
    sdgs: [8, 9, 17],
    explanation: 'esg.sectionDetailsExplanations.societalHarm',
    // 'Businesses with their products, technologies and business models can have adverse effects on their customers, users and society at large. Accordingly, it is crucial that businesses behave responsibly and adjust their practices to avoid risks and optimize societal welfare.',
    levers: [
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c1', // "Strategies to address 'Fake News' as a company.",
        url: 'https://www.forbes.com/sites/forbescommunicationscouncil/2020/08/25/16-business-strategies-for-dealing-with-fake-news/',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c2', // 'Understanding the benefits of personalized pricing and creating consumer welfare to your company.',
        url: 'https://www.nber.org/system/files/working_papers/w23775/w23775.pdf',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c3', // 'How to separate your market into discrete customer groups.',
        url: 'https://www.bain.com/insights/customer-experience-tools-customer-segmentation/',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c4', // 'A guide that explains why quality matter in business success.',
        url: 'https://www.powerreviews.com/blog/product-quality/',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c5', // 'Understanding how product quality helps companies earn customer loyalty, establish brand and manager their costs.',
        url: 'https://www.indeed.com/career-advice/career-development/product-quality',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c6', //  'Smart strategies of how to price your products to attract customers.',
        url: 'https://www.uschamber.com/co/co/run/finance/pricing-strategies-for-your-business',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c7', // 'A guide about fair trade price and the benefits of applying it.',
        url: 'https://www.investopedia.com/terms/f/fair-trade-price.asp',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c8', // 'Guide about how to fairly price your product.',
        url: 'https://www.inc.com/guides/price-your-products.html',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c9', // 'How and when a consumer contract is made, and how to be sure your transactions are legal and fair',
        url: 'https://www.businesscompanion.info/en/quick-guides/consumer-contracts',
      },
      {
        comment: 'esg.sectionDetails.societalHarm.leversComments.c10', // "How ethical sales can increase customer and team satisfaction and contribute to a company's growth.",
        url: 'https://www.pipedrive.com/en/blog/sales-ethics',
      },
    ],
    kpis: ['Net promoter score', 'Social value creation'],
  },
  socialResponsibility: {
    id: 'socialResponsibility',
    sdgs: [16, 17, 8],
    gris: [201],
    explanation: 'esg.sectionDetailsExplanations.socialResponsibility',
    // 'Social responsibility can help customers see your company as a positive force in society, shows accountability to investors and help attract & retain employees. The projects you undertake can help raise awareness for important causes.',
    levers: [
      {
        comment: 'esg.sectionDetails.socialResponsibility.leversComments.c1', // 'Reasons why Corporate Social Advocacy is good for your brand',
        url: 'https://painepublishing.com/measurementadvisor/6-reasons-why-a-csa-strategy-is-good-for-your-brand/',
      },
      {
        comment: 'esg.sectionDetails.socialResponsibility.leversComments.c2', // 'Four simple ways to support local communities.',
        url: 'https://www.forbes.com/sites/theyec/2020/05/22/four-simple-ways-your-business-can-support-its-local-community/',
      },
    ],
    kpis: [
      'Average time spent by employees for social responsibility initiatives',
      'Number of social responsibility initiatives broken down by type of initiative ',
    ],
  },
  data: {
    id: 'data',
    sdgs: [16],
    gris: [418],
    explanation: 'esg.sectionDetailsExplanations.data',
    // 'All companies have data, such as personnel files, customer data, product information, financial transactions, etc. Following proper data protection procedures is crucial to help prevent cybercrimes, data leaks, and data losses. On top of this, there are more and more fines for inadequate behaviors (e.g. related to the GDPR).',
    levers: [
      {
        comment: 'esg.sectionDetails.data.leversComments.c1', // 'Deeper understanding on data privacy and regulatory practices',
        url: 'https://www.eui.eu/documents/servicesadmin/deanofstudies/researchethics/guide-data-protection-research.pdf',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c2', // 'Easier to understand technical things like consensual data collection through blogs like this that simplify the information',
        url: 'https://medium.com/97-things/consensual-privacy-aware-data-collection-brought-to-you-by-data-engineers-30bba843b37d',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c3', // 'Understanding GDPR and its guidelines to ensure fair and lawful usage of data',
        url: 'https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/principles/lawfulness-fairness-and-transparency/',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c4', // 'What principles are used for fair usage and collection of data',
        url: 'https://iapp.org/resources/article/fair-information-practices/',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c5', // 'A guide on how to ensure restricted access to data',
        url: 'https://www.galaxkey.com/blog/four-ways-to-restrict-access-to-confidential-data/',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c6', // 'Blog on understanding how to protect data and restrict access',
        url: 'https://www.csoonline.com/article/3067715/3-ways-to-protect-data-and-control-access-to-it.html',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c7', // 'Helpful in understanding data security, protection and privacy for companies/organisations',
        url: 'https://auth0.com/blog/what-is-data-security/',
      },
      {
        comment: 'esg.sectionDetails.data.leversComments.c8', // 'Deeper understanding of GDPR, which is essential for all organisations, particularly in the EU',
        url: 'https://gdprinformer.com/gdpr-articles/data-protection-training-10-tips-staff',
      },
    ],
    kpis: [
      'Number of data losses/leaks identified',
      'Number of threats identified and resolved',
    ],
  },
  stakeholderEngagement: {
    id: 'stakeholderEngagement',
    sdgs: [16],
    gris: [102],
    explanation: 'esg.sectionDetailsExplanations.stakeholderEngagement',
    // "Stakeholder engagement helps organizations to proactively consider the needs and desires of anyone who has a stake in their organization, which can foster connections, trust, confidence, and buy-in for your organization's key initiatives",
    levers: [
      {
        comment: 'esg.sectionDetails.stakeholderEngagement.leversComments.c1', // 'Measuring employee engagement is crucial to staying up to date with your staff’s needs and this blog gives some insight into this',
        url: 'https://builtin.com/employee-engagement/how-to-improve-employee-engagement',
      },
      {
        comment: 'esg.sectionDetails.stakeholderEngagement.leversComments.c2', // 'Gives a comprehensive understanding of how effective community engagement can happen, what it entails and why stakeholder engagement is crucial',
        url: 'https://nextgeneration.co.za/how-stakeholder-engagement-improves-community-development-projects-and-programmes/',
      },
      {
        comment: 'esg.sectionDetails.stakeholderEngagement.leversComments.c3', // 'Helpful in getting a comprehensive understanding of what stakeholder management',
        url: 'https://www.researchgate.net/publication/317607551_Project_stakeholdersAnalysis_and_Management_Processes',
      },
      {
        comment: 'esg.sectionDetails.stakeholderEngagement.leversComments.c4', // 'Important to understand how to strengthen relationship with customers and users and what tools can be used',
        url: 'https://tribe.so/blog/customer-engagement/',
      },
    ],
    kpis: [
      'Number of stakeholder meetings arranged per year',
      'Number of stakeholder feedbacks obtained broken down by type of stakeholder',
    ],
  },
  businessEthics: {
    id: 'businessEthics',
    sdgs: [16],
    gris: [102, 205],
    explanation: 'esg.sectionDetailsExplanations.businessEthics',
    // 'Unethical behaviors can quickly translate into harm for businesses as well as society. Accordingly, it is crucial to enable all employees in a company to behave ethically. This can mean designing a clear code of conduct with guidelines for ethical decision-making, but more importantly, all employees must be empowered to behave adequately (e.g. through training and suitable company culture).',
    levers: [
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c1', // "How ethical sales can increase customer and team satisfaction and contribute to a company's growth.",
        url: 'https://www.pipedrive.com/en/blog/sales-ethics',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c2', // 'Understanding the Tax Management System and a guide to how to go about it',
        url: 'https://www.pkf-fasselt.de/en/services/tax/tax-compliance-management-system.html',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c3', // 'A detailed paper on what can be done to protect whistleblowers and what rights and benefits can be provided to them',
        url: 'https://www.whistleblowers.gov/sites/default/files/2016-11/WPAC_BPR_42115.pdf',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c4', // 'Blog on understanding sexual harassment, how it can be avoided and what mechanisms companies can have in place',
        url: 'https://vinciworks.com/blog/ten-steps-to-preventing-sexual-harassment-in-your-workplace/',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c5', // 'Blog on ways to prevent harassment at workplace',
        url: 'https://www.paychex.com/articles/human-resources/workplace-harassment-prevention',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c6', // 'Blog protecting whistleblowers from retaliation in a workplace',
        url: 'https://blog.whistleblowersecurity.com/blog/4-ways-to-protect-whistleblowers-from-retaliation',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c7', // 'A blog to explain different ways to protect intellectual property',
        url: 'https://www.copyrighted.com/blog/protect-intellectual-property',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c8', // 'Ways to protect intellectual property',
        url: 'https://abounaja.com/blogs/protect-intellectual-property',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c9', // 'Anti corruption measures and policies that companies can imbibe within their strategies and operations',
        url: 'https://www.unglobalcompact.org/take-action/action/anti-corruption-in-business',
      },
      {
        comment: 'esg.sectionDetails.businessEthics.leversComments.c10', // '10 ways to reduce the risks of corruption and bribery in organisations',
        url: 'https://www.skillcast.com/blog/reduce-bribery-corruption-risks',
      },
    ],
    kpis: [
      'Share of employees trained, broken down by relevant ethical domains in the code of conduct like non-discrimination, anti-bribery or whistleblowing',
    ],
  },
  managementAdvisoryBoard: {
    id: 'managementAdvisoryBoard',
    sdgs: [16],
    gris: [102],
    explanation: 'esg.sectionDetailsExplanations.managementAdvisoryBoard',
    // 'Having a diverse management team & advisory board provides the opportunity to make better business decisions. Diverse teams bring more experiences and perspectives to the table allowing for better identification of risks and more innovative ideas.',
    levers: [
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c1', // 'Tips to improve gender diversity in an organisation',
        url: 'https://www.lever.co/blog/how-to-work-toward-gender-diversity-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c2', // '5 key principles that should be the foundation of gender diversity strategies',
        url: 'https://www.europeanceo.com/business-and-management/top-5-ways-to-encourage-gender-diversity-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c3', // 'How to ensure cultural diversity in your organisation',
        url: 'https://www.diversityresources.com/cultural-diversity-workplace/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c4', // 'An article on why age diversity is important and how it can be ensured',
        url: 'https://www.forbes.com/sites/nextavenue/2020/07/19/the-diversity-employers-need-to-remember-age-diversity/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c5', //  'A report on managing age diversity and how organisations can go about it',
        url: 'https://www.cipd.co.uk/Images/managing-an-age-diverse-workforce_2015-what-employers-need-to-know_tcm18-10832.pdf',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c6', // 'Nine action steps the researchers suggest for employers to take to strengthen age diversity and inclusion in the workplace',
        url: 'https://www.mcknightsseniorliving.com/home/columns/editors-columns/9-steps-to-strengthen-age-diversity-and-inclusion-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c7', // 'Strategies to help organisations ensure cultural and ethnic diversity at workplace',
        url: 'https://firstup.io/blog/15-ways-to-improve-diversity-and-inclusion-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c8', // 'Ways to ensure and improve gender diversity in a workplace',
        url: 'https://www.weforum.org/agenda/2021/03/gender-equality-in-the-workplace-ways-to-improve/',
      },
      {
        comment: 'esg.sectionDetails.managementAdvisoryBoard.leversComments.c9', // 'Five strategies to promote diversity in workplace',
        url: 'https://www.hult.edu/blog/promoting-diversity-in-workplace/',
      },
    ],
    kpis: [
      'Share of management team or advisory board members by gender, cultural or ethnical background, age, disability or expertise',
      'Number of board meetings held per year',
    ],
  },
  regulationsStandards: {
    id: 'regulationsStandards',
    sdgs: [16],
    gris: [102],
    explanation: 'esg.sectionDetailsExplanations.regulationsStandards',
    // 'For the long-term success of a company, it is central to take relevant regulations and standards into account. As a first step, compliance with relevant regulation is of greatest relevance. However, in many industries it is almost equally important to comply with industry standards to win customers and investors. For regulation as well as standards, sustainability is becoming increasingly relevant. There are more and more sustainability regulation on issues like CO2, supply chain transparency or data processing. At the same time, customers and investors increasingly expect companies to establish a dedicated sustainability management and to distinguish themselves through recognized labels and certificates. ',
    levers: [
      {
        comment: 'esg.sectionDetails.regulationsStandards.leversComments.c1', // 'Why ESG is important for organisations',
        url: 'https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/Strategy%20and%20Corporate%20Finance/Our%20Insights/Five%20ways%20that%20ESG%20creates%20value/Five-ways-that-ESG-creates-value.ashx',
      },
      {
        comment: 'esg.sectionDetails.regulationsStandards.leversComments.c2', // 'A guide on integrating ESG in businesses',
        url: 'https://assets.kpmg/content/dam/kpmg/cn/pdf/en/2020/01/integrating-esg-into-your-business.pdf',
      },
      {
        comment: 'esg.sectionDetails.regulationsStandards.leversComments.c3', // 'A guide on how to identify regulations and laws that need to be complied with',
        url: 'https://www.beinformed.com/blog/5-steps-to-ensure-compliance/',
      },
      {
        comment: 'esg.sectionDetails.regulationsStandards.leversComments.c4', // 'How To Ensure Compliance In The Workplace: 9 Tips',
        url: 'https://genesishrsolutions.com/peo-blog/how-to-ensure-compliance-in-the-workplace/',
      },
      {
        comment: 'esg.sectionDetails.regulationsStandards.leversComments.c5', // "Steps to initiate a compliance program \n(Since this is a company page that is propagating its own services, I wasn't sure if they would be considered competition but it's helpful to see the steps they suggested anyway)",
        url: 'https://penneo.com/blog/regulatory-compliance/',
      },
    ],
    kpis: [
      'Number of lawsuits or legal complaints',
      'Type and number of quality and industry standards obtained',
      'Type and number of sustainability certifications obtained',
    ],
  },
  unGlobalCompactPrinciples: {
    id: 'unGlobalCompactPrinciples',
    showDisclosureScore: true,
    explanation: 'esg.sectionDetailsExplanations.unGlobalCompactPrinciples',
  },
  controversialBusinessAreasActivities: {
    id: 'controversialBusinessAreasActivities',
    showDisclosureScore: true,
    explanation:
      'esg.sectionDetailsExplanations.controversialBusinessAreasActivities',
  },
  publicOrLegalControversies: {
    id: 'publicOrLegalControversies',
    showDisclosureScore: true,
    explanation: 'esg.sectionDetailsExplanations.publicOrLegalControversies',
    // 'Being transparent about public complaints or legal actions is crucial to be considered a sustainable business. It is important to note that being involved in controversy does not automatically mean being guilty of misconduct. There can be false claims, which require rectification. If the controversies are justified it is important to clarify how future controversies will be avoided.',
  },
  oecdGuidelinesForMultinationalEnterprises: {
    id: 'oecdGuidelinesForMultinationalEnterprises',
    showDisclosureScore: true,

    explanation:
      'esg.sectionDetailsExplanations.oecdGuidelinesForMultinationalEnterprises',
  },
  unGuidingPrinciplesOnBusinessAndHumanRights: {
    id: 'unGuidingPrinciplesOnBusinessAndHumanRights',
    showDisclosureScore: true,

    explanation:
      'esg.sectionDetailsExplanations.unGuidingPrinciplesOnBusinessAndHumanRights',
  },
  'PAI indicators': {
    id: 'pai',
    showDisclosureScore: true,

    explanation: 'esg.sectionDetailsExplanations.paiIndicators',
  },
}

export const iconMapping = {
  environmental: environmentalIcon,
  social: socialIcon,
  governance: governanceIcon,
  ghgEmissions: {
    active: activeGhgEmissionsIcon,
    inactive: inactiveGhgEmissionsIcon,
  },
  energy: {
    active: activeEnergyIcon,
    inactive: inactiveEnergyIcon,
  },
  transportation: {
    active: activeTransportationIcon,
    inactive: inactiveTransportationIcon,
  },
  resourceConsumptionAndUsage: {
    active: activeResourceConsumptionAndUsageIcon,
    inactive: inactiveResourceConsumptionAndUsageIcon,
  },
  pollutionAndEnvironmentalHarm: {
    active: activePollutionAndEnvironmentalHarmIcon,
    inactive: inactivePollutionAndEnvironmentalHarmIcon,
  },
  labor: {
    active: activeLaborIcon,
    inactive: inactiveLaborIcon,
  },
  workplace: {
    active: activeWorkplaceIcon,
    inactive: inactiveWorkplaceIcon,
  },
  diversityInclusion: {
    active: activeDiversityInclusionIcon,
    inactive: inactiveDiversityInclusionIcon,
  },
  societalHarm: {
    active: activeSocietalHarmIcon,
    inactive: inactiveSocietalHarmIcon,
  },
  socialResponsibility: {
    active: activeSocialResponsibilityIcon,
    inactive: inactiveSocialResponsibilityIcon,
  },
  data: {
    active: activeDataIcon,
    inactive: inactiveDataIcon,
  },
  stakeholderEngagement: {
    active: activeStakeholderEngagementIcon,
    inactive: inactiveStakeholderEngagementIcon,
  },
  businessEthics: {
    active: activeBusinessEthicsIcon,
    inactive: inactiveBusinessEthicsIcon,
  },
  managementAdvisoryBoard: {
    active: activeManagementAdvisoryBoardIcon,
    inactive: inactiveManagementAdvisoryBoardIcon,
  },
  regulationsStandards: {
    active: activeRegulationsStandardsIcon,
    inactive: inactiveRegulationsStandardsIcon,
  },
}

export function getIcon(id: number) {
  const sectionString = esgSectionIdMapping[id]
  return sectionString ? iconMapping[sectionString] : null
}

export function getEsgSectionDetails(id: number) {
  const sectionString = esgSectionIdMapping[id]
  return sectionString ? esgSectionDetails[sectionString] : null
}

export function isEnvironmental(id: number) {
  return esgSectionIdMapping[id] === 'environmental'
}
export function isSocial(id: number) {
  return esgSectionIdMapping[id] === 'social'
}
export function isGovernance(id: number) {
  return esgSectionIdMapping[id] === 'governance'
}
export function isDisclosure(id: number) {
  return esgSectionIdMapping[id] === 'disclosure'
}
