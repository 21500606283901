import { render, staticRenderFns } from "./euTaxonomyEligibility.vue?vue&type=template&id=20a43db4"
import script from "./euTaxonomyEligibility.vue?vue&type=script&lang=ts"
export * from "./euTaxonomyEligibility.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactGoalEditEuTaxonomyEligibilityCheck: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/eu-taxonomy/eligibility/Check.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})
