import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { useListCategoriesQuery } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['name'];
      }
    },
    height: {
      type: String,
      default: function _default() {
        return 'calc(100vh - 200px)';
      }
    }
  },
  setup: function setup(props) {
    var headers = computed(function () {
      return [{
        value: 'id',
        sortable: true
      }, {
        value: 'label'
      }, {
        value: 'parent.id'
      }, {
        value: 'parent.label'
      }, {
        value: 'createdAt'
      }, {
        value: 'actions'
      }].filter(function (o) {
        var _props$columns;
        return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
      }).map(function (col) {
        return _objectSpread(_objectSpread({}, col), {}, {
          sortable: col.sortable || false
        });
      }) || [];
    });
    var options = ref({
      page: 1,
      itemsPerPage: 10,
      sortDesc: [true]
    });
    var search = ref('');
    var variables = computed(function () {
      return {
        input: {
          text: search.value
        },
        // TODO use type for input + search is not working properly
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage
      };
    });
    var dialog = ref(false);
    var selectedIds = ref([]);
    var _useListCategoriesQue = useListCategoriesQuery(variables),
      result = _useListCategoriesQue.result,
      refetch = _useListCategoriesQue.refetch,
      loading = _useListCategoriesQue.loading;
    var items = computed(function () {
      var _result$value$listCat, _result$value;
      return (_result$value$listCat = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listCategories) !== null && _result$value$listCat !== void 0 ? _result$value$listCat : [];
    });
    var itemCount = computed(function () {
      var _result$value$countCa, _result$value2;
      return (_result$value$countCa = (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countCategories) !== null && _result$value$countCa !== void 0 ? _result$value$countCa : 0;
    });
    return {
      selectedIds: selectedIds,
      dialog: dialog,
      itemCount: itemCount,
      loading: loading,
      items: items,
      refetch: refetch,
      search: search,
      headers: headers,
      options: options
    };
  }
});