export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    listItems: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {}
});