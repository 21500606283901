import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.find-index.js";
import { renderMarkdownInline } from '~/functions/markdown';
export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var annotators = ref([]);
    var _useNuxtApp = useNuxtApp(),
      $axios = _useNuxtApp.$axios,
      $store = _useNuxtApp.$store;
    $axios.$get("".concat(process.env.DOMAIN, "/api/annotation/annotators")).catch(function (error) {
      console.log(error);
    }).then(function (response) {
      annotators.value = response;
    });
    var route = useRoute();
    var router = useRouter();
    var query = ref(route.query.query ? route.query.query : '');
    var page = ref(route.query.page ? parseInt(route.query.page) : 1);
    var insightFilter = ref(route.query.insightFilter ? route.query.insightFilter : 'noAnnotation');
    var selectedScrapers = ref(route.query.selectedScrapers ? route.query.selectedScrapers : ['core']);
    var itemsPerPage = ref(route.query.itemsPerPage ? parseInt(route.query.itemsPerPage) : 10);
    var selectedAnnotator = ref(route.query.selectedAnnotator ? route.query.selectedAnnotator : null);
    watch(query, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: current,
          skip: (page.value * itemsPerPage.value).toString(),
          itemsPerPage: itemsPerPage.value.toString(),
          insightFilter: insightFilter.value,
          selectedScrapers: selectedScrapers.value,
          selectedAnnotator: selectedAnnotator.value
        }
      });
    });
    watch(itemsPerPage, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: query.value,
          skip: (current * itemsPerPage.value).toString(),
          itemsPerPage: current.toString(),
          insightFilter: insightFilter.value,
          selectedScrapers: selectedScrapers.value,
          selectedAnnotator: selectedAnnotator.value
        }
      });
    });
    watch(page, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: query.value,
          skip: (current * itemsPerPage.value).toString(),
          itemsPerPage: itemsPerPage.value.toString(),
          insightFilter: insightFilter.value,
          selectedScrapers: selectedScrapers.value,
          selectedAnnotator: selectedAnnotator.value
        }
      });
    });
    watch(insightFilter, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: query.value,
          skip: (page.value * itemsPerPage.value).toString(),
          itemsPerPage: itemsPerPage.value.toString(),
          insightFilter: current,
          selectedScrapers: selectedScrapers.value,
          selectedAnnotator: selectedAnnotator.value
        }
      });
    });
    watch(selectedScrapers, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: query.value,
          skip: (page.value * itemsPerPage.value).toString(),
          itemsPerPage: itemsPerPage.value.toString(),
          insightFilter: insightFilter.value,
          selectedScrapers: current,
          selectedAnnotator: selectedAnnotator.value
        }
      });
    });
    watch(selectedAnnotator, function (current, _) {
      router.push({
        path: '/portfolio/annotation/',
        query: {
          query: query.value,
          skip: (page.value * itemsPerPage.value).toString(),
          itemsPerPage: itemsPerPage.value.toString(),
          insightFilter: insightFilter.value,
          selectedScrapers: selectedScrapers.value,
          selectedAnnotator: current
        }
      });
    });
    var isAdmin = $store.state.assessment.venture.name === 'Admin';
    return {
      isAdmin: isAdmin,
      annotators: annotators,
      query: query,
      page: page,
      itemsPerPage: itemsPerPage,
      insightFilter: insightFilter,
      selectedScrapers: selectedScrapers,
      selectedAnnotator: selectedAnnotator
    };
  },
  data: function data() {
    return {
      scrapers: ['core', 'iea', 'eea', 'wikipedia', 'pik', 'ipcc', 'oecd', 'cambridge-core-sdg', 'wuppertal', 'eur-lex', 'hypothesis', 'ilo', 'oeko', 'twingly', 'webzio'],
      currentInsight: null,
      currentInsightIndex: 0,
      insightDialogOpen: false,
      allInsights: [],
      totalItems: 0
    };
  },
  mounted: function mounted() {
    this.searchInsights();
  },
  methods: {
    // TODO refactor so that prev and next only change index variable and watch sets the currInsight
    prevInsight: function prevInsight() {
      var _this = this;
      var currIndex = this.allInsights.findIndex(function (e) {
        return e === _this.currentInsight;
      });
      if (currIndex === -1) {
        this.insightDialogOpen = false;
      } else if (currIndex - 1 > 0) {
        this.currentInsight = this.allInsights[currIndex - 1];
      } else {
        this.insightDialogOpen = false;
      }
    },
    nextInsight: function nextInsight() {
      var _this2 = this;
      var currLength = this.allInsights.length;
      var currIndex = this.allInsights.findIndex(function (e) {
        return e === _this2.currentInsight;
      });
      if (currIndex === -1) {
        this.insightDialogOpen = false;
      } else if (currIndex < currLength - 1) {
        this.currentInsight = this.allInsights[currIndex + 1];
      } else {
        this.insightDialogOpen = false;
      }
    },
    renderMarkdownInline: renderMarkdownInline,
    searchInsights: function searchInsights() {
      var _this3 = this;
      this.$axios.$get("".concat(process.env.DOMAIN, "/api/annotation"), {
        params: {
          query: this.query,
          insightFilter: this.insightFilter,
          scrapers: this.selectedScrapers,
          annotatedBy: this.selectedAnnotator,
          limit: this.itemsPerPage,
          skip: this.page * this.itemsPerPage
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function (response) {
        _this3.allInsights = response.documents;
        _this3.totalItems = response.totalDocuments;
      });
    }
  }
});