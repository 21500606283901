import "core-js/modules/es6.array.map.js";
import { useGetImpactGeographiesForVenturesQuery } from '~/types/types';
export default defineComponent({
  props: {
    impactGoalAssessments: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var impactGoalAssessmentIds = computed(function () {
      return props.impactGoalAssessments.map(function (e) {
        return e.ventureId;
      });
    });
    var showPositive = ref(true);
    var _useGetImpactGeograph = useGetImpactGeographiesForVenturesQuery({
        ventureIds: impactGoalAssessmentIds.value,
        showPositive: showPositive.value
      }),
      geographiesResult = _useGetImpactGeograph.result,
      loading = _useGetImpactGeograph.loading;
    var geoAggregations = computed(function () {
      var _geographiesResult$va, _geographiesResult$va2;
      return (_geographiesResult$va = geographiesResult === null || geographiesResult === void 0 ? void 0 : (_geographiesResult$va2 = geographiesResult.value) === null || _geographiesResult$va2 === void 0 ? void 0 : _geographiesResult$va2.getImpactGeographiesForVentures) !== null && _geographiesResult$va !== void 0 ? _geographiesResult$va : [];
    });
    return {
      geoAggregations: geoAggregations,
      loading: loading,
      showPositive: showPositive
    };
  }
});