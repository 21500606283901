import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import { isAtOrAfterState } from '~/functions/helpers';
export default defineComponent({
  props: {
    assessment: Object,
    stage: String,
    impacts: Array,
    available: Boolean
  },
  computed: {
    positiveOutcomes: function positiveOutcomes() {
      return this.impacts.filter(function (e) {
        return e.isPositive === true;
      });
    },
    negativeOutcomes: function negativeOutcomes() {
      return this.impacts.filter(function (e) {
        return e.isPositive === false;
      });
    },
    potentialScoreField: function potentialScoreField() {
      if (['Outcome-selection venture', 'Outcome-selection reviewer'].includes(this.stage)) return 'impactPotentialPhase1';
      return 'impactPotentialScore';
    },
    overallScore: function overallScore() {
      var _this = this;
      var posSquaredSum = this.positiveOutcomes.map(function (e) {
        return Math.pow(e[_this.potentialScoreField], 2);
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var negSquaredSum = this.negativeOutcomes.map(function (e) {
        return Math.pow(e[_this.potentialScoreField], 2);
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      if (posSquaredSum >= negSquaredSum) return Math.sqrt(posSquaredSum - negSquaredSum);else return -Math.sqrt(-(posSquaredSum - negSquaredSum));
    }
  },
  methods: {
    isAtOrAfterState: isAtOrAfterState
  }
});