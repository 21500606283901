import { sdgs } from '~/assets/sdgs';
export default defineComponent({
  props: {
    value: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup: function setup() {
    return {
      sdgs: sdgs
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    isEmpty: function isEmpty(filter) {
      return !(filter.tags !== undefined && filter.tags.length > 0);
    }
  }
});