import { percentage, percentageText, color, skipped, getDifferenceInfo } from '~/functions/esg';
export default defineComponent({
  props: {
    esgSections: {
      type: Array,
      required: true
    },
    secondVersionText: {
      type: String,
      required: true
    },
    secondData: {
      type: Object,
      required: true
    },
    firstVersionText: {
      type: String,
      required: true
    },
    firstData: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var headers = computed(function () {
      return [{
        text: '',
        value: 'esgSection',
        sortable: false,
        align: 'start'
      }, {
        text: props.firstVersionText,
        value: 'first',
        sortable: false,
        width: '20%',
        align: 'center'
      }, {
        text: props.secondVersionText,
        value: 'second',
        sortable: false,
        width: '20%',
        align: 'center'
      }];
    });
    var everyComparisonEsgSectionHasResults = computed(function () {
      return props.esgSections.every(function (section) {
        return props.firstData[section.label];
      });
    });
    var everyReferenceEsgSectionHasResults = computed(function () {
      return props.esgSections.every(function (section) {
        return props.secondData[section.label];
      });
    });
    function getComparisonResult(label) {
      return props.firstData[label];
    }
    function getReferenceResult(label) {
      return props.secondData[label];
    }
    function getDifference(label) {
      return getDifferenceInfo(getComparisonResult(label), getReferenceResult(label));
    }
    function getDifferenceColor(label) {
      var info = getDifference(label);
      if (info.hasValue) return 'blue-grey';
      if (info.difference === 0) return 'primary';else if (info.difference < 0) return 'danger';else return 'success';
    }
    function colorClass(label) {
      var comparison = getComparisonResult(label);
      return color(percentage(comparison), skipped(comparison));
    }
    function getProgressBarData(label) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'esg';
      return {
        height: type === 'esg' ? '16' : '6',
        value: percentage(getComparisonResult(label)),
        color: colorClass(label)
      };
    }
    function getTargetStyle(label) {
      return {
        left: "".concat(percentage(getReferenceResult(label)), "%")
      };
    }
    function getProgressBarTooltipData(label) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'esg';
      return {
        color: getDifferenceColor(label),
        content: "".concat(props.secondVersionText, " : ").concat(percentageText(getReferenceResult(label))),
        style: {
          height: type === 'esg' ? '24px' : '13px',
          marginTop: type === 'esg' ? '-4px' : '6px'
        }
      };
    }
    return {
      headers: headers,
      everyComparisonEsgSectionHasResults: everyComparisonEsgSectionHasResults,
      everyReferenceEsgSectionHasResults: everyReferenceEsgSectionHasResults,
      getComparisonResult: getComparisonResult,
      getReferenceResult: getReferenceResult,
      getDifference: getDifference,
      getDifferenceColor: getDifferenceColor,
      percentageText: percentageText,
      colorClass: colorClass,
      getProgressBarData: getProgressBarData,
      getTargetStyle: getTargetStyle,
      getProgressBarTooltipData: getProgressBarTooltipData
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {}
});