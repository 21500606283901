import { taskModuleInfoForVenture } from '~/assets/task';
import { useVentureId } from '~/functions/accounts';
import { formatAsGermanDate } from '~/functions/helpers';
import { renderMarkdown } from '~/functions/markdown';
import { useDeleteTaskAssignmentsMutation, useListTaskAssignmentsQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var ventureId = useVentureId();
    var _useListTaskAssignmen = useListTaskAssignmentsQuery(computed(function () {
        return {
          ventureIds: [ventureId.value]
        };
      })),
      result = _useListTaskAssignmen.result;
    var taskAssignments = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listTaskAssignments) || [];
    });
    var _useDeleteTaskAssignm = useDeleteTaskAssignmentsMutation(),
      deleteTaskAssignments = _useDeleteTaskAssignm.mutate;
    return {
      taskAssignments: taskAssignments,
      deleteTaskAssignments: deleteTaskAssignments,
      ventureId: ventureId
    };
  },
  data: function data() {
    return {
      taskModuleInfo: taskModuleInfoForVenture,
      settingsMenu: {},
      search: '',
      headers: [
      // {
      //   text: 'Priority',
      //   value: 'task.prio',
      //   cellClass: 'align-top',
      // },
      {
        value: 'task.label',
        text: this.$t('companyPerspective.tasks.headers.task'),
        cellClass: 'align-top font-weight-bold subtitle-2'
      }, {
        value: 'task.goal',
        text: this.$t('companyPerspective.tasks.headers.goalWhy'),
        cellClass: 'align-top'
      }, {
        value: 'origin',
        text: this.$t('companyPerspective.tasks.headers.origin'),
        cellClass: 'align-top'
      }, {
        value: 'startDate',
        text: this.$t('companyPerspective.tasks.headers.startDate'),
        cellClass: 'align-top'
      }, {
        value: 'endDate',
        text: this.$t('companyPerspective.tasks.headers.dueDate'),
        cellClass: 'align-top'
      }, {
        value: 'status',
        text: this.$t('companyPerspective.tasks.headers.status'),
        cellClass: 'align-top'
      }, {
        value: 'comments',
        text: this.$t('companyPerspective.tasks.headers.comments'),
        cellClass: 'align-top'
      }, {
        value: 'updatedAt',
        text: this.$t('companyPerspective.tasks.headers.updatedAt'),
        cellClass: 'align-top'
      }, {
        value: 'action',
        text: this.$t('companyPerspective.tasks.headers.actions'),
        cellClass: 'align-top  subtitle-1 font-weight-bold'
      }],
      chipColors: {
        'Not started': 'danger',
        'In progress': 'info',
        Done: 'success',
        // more pastels: '#FFDAC1','#E2FOCB','#ffffba', #bae1ff
        Investor: 'primary lighten-4',
        // '#FFB7B2',
        Bookmark: 'primary lighten-3',
        // '#C7CEEA',
        'Self defined': 'primary lighten-2',
        // '#B5EAD7',
        'Auto task': 'primary lighten-1' // '#FF9AA2',
      }
    };
  },
  methods: {
    renderMarkdown: renderMarkdown,
    formatAsGermanDate: formatAsGermanDate,
    deleteTaskAssignment: function deleteTaskAssignment(taskAssignmentId) {
      this.deleteTaskAssignments({
        taskAssignmentIds: [taskAssignmentId]
      }, {
        update: function update(cache) {
          cache.evict({
            id: cache.identify({
              id: taskAssignmentId,
              __typename: 'MetricAssignmentType'
            })
          });
          cache.gc();
          window.$nuxt.$emit('userMsg');
        }
      });
    }
  }
});