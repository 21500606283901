import "core-js/modules/es6.array.filter.js";
export default defineComponent({
  props: {
    category: Object,
    outcome: Object
  },
  data: function data() {
    return {
      nConfirmedIndicators: 0,
      score: 0
    };
  },
  computed: {
    nIndicators: function nIndicators() {
      return this.indicators.length;
    },
    indicators: function indicators() {
      if (this.outcome !== undefined) {
        var indicators = this.getIndicators(this.outcome);
        return indicators.filter(function (e) {
          return e['IMP dimensions'] === 'Risk';
        }).sort(function (a, b) {
          return a.Indicator > b.Indicator ? 1 : -1;
        });
      }
      return [];
    },
    complete: function complete() {
      return this.nConfirmedIndicators === this.nIndicators;
    },
    outcomeEditingState: function outcomeEditingState() {
      return this.$store.getters['assessment/getEditingState'](this.outcome.id);
    }
  },
  watch: {
    outcome: {
      handler: 'handleChangeInIndicator',
      deep: true
    },
    complete: {
      handler: function handler() {
        this.$emit('change');
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.handleChangeInIndicator();
  },
  methods: {
    safeScore: function safeScore(score) {
      return parseFloat(score) || 0;
    },
    handleChangeInIndicator: function handleChangeInIndicator() {
      var _this = this;
      this.nConfirmedIndicators = this.indicators.reduce(function (acc, cVal) {
        return acc + +(cVal.Confirmed === true);
      }, 0);
      this.score = this.indicators.reduce(function (acc, cVal) {
        return acc + +(_this.safeScore(cVal.Score) * +cVal['Scoring weight']);
      }, 0);
    },
    getIndicators: function getIndicators(outcome) {
      return this.$store.getters['assessment/getIndicators'](outcome.id);
    }
  }
});