import "core-js/modules/es6.number.constructor.js";
import { toNumber } from 'lodash';
import { category as _category, color } from '~/functions/esg';
export default defineComponent({
  props: {
    score: {
      type: Number,
      default: function _default() {
        return null;
      }
    },
    diffToPrevious: {
      type: Number,
      default: 0
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    skipped: function skipped() {
      return this.score === null;
    },
    category: function category() {
      return _category(this.score, this.skipped);
    },
    colorClass: function colorClass() {
      return color(this.score, this.skipped);
    },
    textClass: function textClass() {
      return this.colorClass + '--text';
    },
    diff: function diff() {
      return toNumber(this.diffToPrevious);
    },
    textClassForDiff: function textClassForDiff() {
      if (this.diff > 0) {
        return 'success--text';
      } else if (this.diff < 0) {
        return 'danger--text';
      }
      return 'grey--text';
    }
  }
});