// import { handleUndefined } from '../common'
import { getDifferenceInfo, percentageText } from '~/functions/esg'
import { isEnvironmental, isGovernance, isSocial } from '~/functions/helpers'

import { type QuestionnaireComparisonType } from '~/types/types'

export const comparisonContent = (
  versionTextFirst: string,
  versionTextSecond: string,
  mappingFirst: any,
  mappingSecond: any,
  esgSections: any,
  esgComparisonComments: QuestionnaireComparisonType,
  header: string,
) => {
  if (mappingFirst && mappingSecond)
    return [
      {
        margin: [0, 0, 0, 0],
        columns: [
          [
            {
              text: header,
              style: ['esgHeader'],
              width: 'auto',
            },
          ],
        ],
      },
      table(
        versionTextFirst,
        versionTextSecond,
        mappingFirst,
        mappingSecond,
        esgSections,
        esgComparisonComments,
      ),
    ]
}
const table = (
  versionTextFirst: string,
  versionTextSecond: string,
  mappingFirst: any,
  mappingSecond: any,
  esgSections: any,
  esgComparisonComments: QuestionnaireComparisonType,
) => {
  return {
    layout: 'noBorders',
    margin: [0, 10, 0, 0],
    table: {
      widths: [200, 80, 80, 80, 290],
      headerRows: 1,
      body: [
        [
          {
            text: '',
          },
          {
            text: versionTextFirst,
            style: ['comparisonHeader'],
          },
          {
            text: versionTextSecond,
            style: ['comparisonHeader'],
          },
          {
            text: 'Difference',
            style: ['comparisonHeader'],
          },
          {
            text: 'Comments',
            style: ['comparisonHeader'],
          },
        ],
        ...getSubsectionRows(
          mappingFirst,
          mappingSecond,
          esgSections,
          esgComparisonComments,
        ),
      ],
    },
  }
}

const getComment = (
  sectionId: number,
  esgComparisonComments: QuestionnaireComparisonType,
) => {
  if (!esgComparisonComments) return ''
  let comment
  if (isEnvironmental(sectionId)) comment = esgComparisonComments.commentE
  else if (isSocial(sectionId)) comment = esgComparisonComments.commentS
  else if (isGovernance(sectionId)) comment = esgComparisonComments.commentG
  return comment ? comment : ''
}

export const getSubsectionRows = (
  mappingFirst: any,
  mappingSecond: any,
  esgSections: any,
  esgComparisonComments: QuestionnaireComparisonType,
) => {
  const rows: any[] = []
  for (const section of esgSections) {
    rows.push([
      {
        text: section.label,
        style: ['section'],
        alignment: 'left',
      },
      {
        text: percentageText(mappingFirst[section.label]),
        style: ['section'],
      },
      {
        text: percentageText(mappingSecond[section.label]),
        style: ['section'],
      },
      {
        text: getDifferenceInfo(
          mappingFirst[section.label],
          mappingSecond[section.label],
        )?.differenceText,
        style: ['section'],
      },
      {
        text: getComment(section.id, esgComparisonComments),
        style: ['text'],
        rowSpan: 3,
        colSpan: 1,
        margin: [20, 9, 0, 4],
      },
    ])
    for (const subsection of section.sections) {
      rows.push([
        {
          text: subsection.label,
          style: ['subsection'],
        },
        {
          text: percentageText(mappingFirst[subsection.label]),
          style: ['text'],
        },
        {
          text: percentageText(mappingSecond[subsection.label]),
          style: ['text'],
        },
        {
          text: getDifferenceInfo(
            mappingFirst[subsection.label],
            mappingSecond[subsection.label],
          )?.differenceText,
          style: ['text'],
        },
        {},
      ])
      rows.push([
        {
          text: '',
          style: ['text'],
          colSpan: 5,
        },
      ])
    }
  }

  return rows
}
export const comparisonStyle = {
  esgHeader: {
    fontSize: 15,
    color: '#5188ff ',
    bold: true,
    // margin: [-5, -35, 0, 0],
  },
  disclosureSubHeader: {
    color: '#5B7185',
    bold: true,
    alignment: 'left',
    fontSize: 10,
    margin: [0, 3, 20, 0],
  },
  comparisonHeader: {
    color: '#5B7185',
    bold: true,
    fontSize: 10,
    margin: [0, 0, 0, 8],
    alignment: 'right',
  },
  text: {
    color: '#5B7185',
    fontSize: 10,
    alignment: 'right',
    // margin: [0, 0, 0, 8],
  },
  subsection: {
    color: '#5B7185',
    fontSize: 10,
    // margin: [0, 0, 0, 8],
    // margin: [0, 8, 0, 0],
  },
  section: {
    color: '#5B7185',
    fontSize: 12,
    bold: true,
    margin: [0, 8, 0, 4],
    alignment: 'right',

    // margin: [0, 8, 0, 0],
  },
}
