import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
export default defineComponent({
  name: 'SDGList',
  props: {
    primarySdgs: Array,
    secondarySdgs: Array,
    shortList: Boolean,
    maxSDGs: Number
  },
  computed: {
    shownPrimarySdgs: function shownPrimarySdgs() {
      if (!this.primarySdgs) {
        return [];
      }
      var unique = _toConsumableArray(new Set(this.primarySdgs));
      if (this.shortList) {
        return unique.slice(0, 3);
      }
      return unique;
    },
    shownSecondarySdgs: function shownSecondarySdgs() {
      if (!this.secondarySdgs) {
        return [];
      }
      var unique = _toConsumableArray(new Set(this.secondarySdgs));
      if (this.shortList) {
        var max = Math.max(3 - this.primarySdgs.length, 0);
        return unique.slice(0, max);
      }
      return unique;
    }
  }
});