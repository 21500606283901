import { abbreviateNumber } from '../../assets/helper/functions'
// eslint-disable-next-line import/order
import {
  MetricAssignmentCompletionType,
  TargetParametersType,
} from '~/types/types'
import { handleUndefined } from '../common'
let years: any = []
let metricData: any = {}
export const handleMetricAssignmentInfo = (metricAssignments: any, locale) => {
  if (metricAssignments && metricAssignments.length) {
    // eslint-disable-next-line array-callback-return
    return metricAssignments.map((item: any) => {
      const { completionStatus } = item
      const { targetParameters } = completionStatus
      const score = computeProgressScore(completionStatus)
      const xPos = setPositionForProgressScore(String(score).length)
      const metricValues = item.metricValues.filter((metricValue) => {
        const actual = metricValue?.actual === 0 ? '0' : metricValue?.actual
        const target = metricValue?.target === 0 ? '0' : metricValue?.target
        if (actual || target) return metricValue
      })
      const yearsLength = getMetricValuesData(metricValues)
      const calculationMessage = getCalculationMessage(completionStatus)
      const target = calculateTarget(completionStatus, locale)
      const actual = calculateActual(completionStatus, locale)
      if (yearsLength > 1 && item.completionStatus?.hasTarget) {
        return [
          {
            margin: [15, 7, 0, 0],
            columns: [
              [
                {
                  columns: [
                    {
                      svg: `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.64062 8.42762L16.7195 1.99609" stroke="#8FA5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.9062 1L16.7253 1.9961L15.6764 4.64006" stroke="#8FA5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.3906 17H14.1126L14.1126 8.3918C14.1126 8.22873 14.1817 8.07233 14.3047 7.95702C14.4276 7.84171 14.5944 7.77693 14.7682 7.77693H16.735C16.9089 7.77693 17.0757 7.84171 17.1986 7.95702C17.3216 8.07233 17.3906 8.22873 17.3906 8.3918V17Z" stroke="#8FA5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.8359 17H7.55796L7.55796 10.2364C7.55796 10.0734 7.62703 9.91696 7.74998 9.80165C7.87292 9.68634 8.03968 9.62156 8.21355 9.62156H10.1803C10.3542 9.62156 10.521 9.68634 10.6439 9.80165C10.7669 9.91696 10.8359 10.0734 10.8359 10.2364V17Z" stroke="#8FA5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.28125 17H1.00321L1.00321 12.081C1.00321 11.9179 1.07228 11.7616 1.19523 11.6462C1.31818 11.5309 1.48494 11.4662 1.65882 11.4662H3.62564C3.79952 11.4662 3.96628 11.5309 4.08923 11.6462C4.21218 11.7616 4.28125 11.9179 4.28125 12.081L4.28125 17Z" stroke="#8FA5AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
                      width: 13,
                      height: 13,
                    },
                    {
                      text: handleUndefined(item?.metricNameOverride),
                      width: 200,
                      style: ['metricName'],
                    },
                  ],
                },
                {
                  text: handleUndefined(item?.unitName),
                  margin: [23, 0, 0, 0],
                  width: 200,
                  style: ['unitName'],
                },
                Object.keys(metricData).length
                  ? {
                      svg: `<svg width="370" height="78" viewBox="0 0 370 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.25" x="0" width="270" height="24" rx="10" fill="#8FA5AF"/>
                      <rect width="${calculateProgressBarWidth(
                        270,
                        score,
                      )}" x="1" y="1" height="22" rx="10" fill="url(#paint0_linear_8025_3485)"/>
                      ${
                        completionStatus?.actual !== 0 &&
                        `<text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-weight="bold" font-size="12" letter-spacing="0em"><tspan x="2" y="45.3">0</tspan></text>`
                      }

                      <text fill="#5188ff " xml:space="preserve" style="white-space: pre" font-family="Barlow" font-weight="bold" font-size="12" letter-spacing="0em"><tspan x="${calculateActualPosition(
                        score,
                        completionStatus?.actual,
                      )}" y="45.3">${actual}</tspan></text>
                      <text fill="#5B7185" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="12" letter-spacing="0em"><tspan x="240" y="45.3">
                        ${target} < /tspan></text >
                      ${
                        score !== 0 &&
                        `
                      <g clip-path="url(#clip0_8025_3485)">
                      <path d="M19 57H5C3.9 57 3 57.9 3 59V73C3 74.1 3.9 75 5 75H19C20.1 75 21 74.1 21 73V59C21 57.9 20.1 57 19 57ZM19 73H5V59H19V73Z" fill="#5B7185"/>
                      <path d="M11.25 61.7207H6.25V63.2207H11.25V61.7207Z" fill="#5B7185"/>
                      <path d="M18 69.75H13V71.25H18V69.75Z" fill="#5B7185"/>
                      <path d="M18 67.25H13V68.75H18V67.25Z" fill="#5B7185"/>
                      <path d="M8 72H9.5V70H11.5V68.5H9.5V66.5H8V68.5H6V70H8V72Z" fill="#5B7185"/>
                      <path d="M14.09 64.95L15.5 63.54L16.91 64.95L17.97 63.89L16.56 62.47L17.97 61.06L16.91 60L15.5 61.41L14.09 60L13.03 61.06L14.44 62.47L13.03 63.89L14.09 64.95Z" fill="#5B7185"/>
                      </g>
                      <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="12" letter-spacing="0em"><tspan x="27" y="70.8">${calculationMessage}</tspan></text>
                      `
                      }
                      <text fill="#5188ff " xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="20" font-weight="600" letter-spacing="0em"><tspan x="${
                        xPos.scoreX
                      }" y="20">${score}%</tspan></text>
                      <defs>
                      <linearGradient id="paint0_linear_8025_3485" x1="-110.628" y1="91.5723" x2="112.851" y2="-87.8664" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#00679E"/>
                      <stop offset="1" stop-color="#23A9F1"/>
                      </linearGradient>
                      <clipPath id="clip0_8025_3485">
                      <rect width="24" height="24" fill="white" transform="translate(0 54)"/>
                      </clipPath>
                      </defs>
                      </svg>`,
                      fit: [250, 100],
                      margin: [23, 0, 0, 0],
                    }
                  : '',
              ],
              [table(actual, target, metricValues, targetParameters, locale)],
            ],
          },
        ]
      }
    })
  }
}

const calculateActual = (
  completionStatus: MetricAssignmentCompletionType,
  locale,
) => {
  const actual = completionStatus?.actual || 0
  return abbreviateNumber(Number(actual), locale)
}

const calculateTarget = (
  completionStatus: MetricAssignmentCompletionType,
  locale,
) => {
  if (completionStatus.hasTarget) {
    const { target } = completionStatus.targetParameters as TargetParametersType
    return abbreviateNumber(Number(target), locale)
  } else return 0
}

const computeProgressScore = (
  completionStatus: MetricAssignmentCompletionType,
) => {
  if (completionStatus.hasTarget) {
    const rate = completionStatus.completionRate as number
    return Math.round(rate * 100)
  } else return 0
}

const getCalculationMessage = (
  completionStatus: MetricAssignmentCompletionType,
) => {
  if (completionStatus.hasTarget) {
    const params = completionStatus.targetParameters as TargetParametersType
    if (params.calculationType === 'sum')
      return `Sum of values from ${params.startDate} - ${params.endDate}`
    else if (params.calculationType === 'average')
      return `Average of values from ${params.startDate} - ${params.endDate}`
    else if (params.calculationType === 'most_recent_value')
      return `Most recent value from ${params.startDate} - ${params.endDate}`
    else return ''
  } else return ''
}

const setPositionForProgressScore = (scoreLength: number) => {
  if (scoreLength === 1) return { scoreX: 290, lineX: 315, finalScoreX: 320 }
  else if (scoreLength === 2)
    return { scoreX: 290, lineX: 318, finalScoreX: 323 }
  else return { scoreX: 290, lineX: 330, finalScoreX: 335 }
}

const calculateActualPosition = (progressValue: number, actual: number) => {
  const width = calculateProgressBarWidth(270, progressValue)
  if (progressValue >= 0 && progressValue <= 6 && actual !== 0) return 13
  else if (progressValue >= 80) return 208
  else return actual !== 0 ? width - 5 : 2
}

const table = (
  actual,
  target,
  metricValues,
  targetParameters,
  locale = 'en',
) => {
  let marginLeft
  if (years.length === 12) marginLeft = -110
  else if (metricValues.length > 9) marginLeft = -130
  else marginLeft = -110
  return {
    layout: 'noBorders',
    margin: [marginLeft, -5, 0, 0],
    table: {
      headerRows: 1,
      widths: calculateWidth(metricValues),
      body: [
        getYearsInfo(targetParameters),
        getActualInfo(actual, locale),
        getTargetInfo(target, locale),
      ],
    },
  }
}
const calculateWidth = (metricValues) => {
  const widths: any = ['auto']
  const width = years.length === 12 ? 'auto' : 32
  metricValues.forEach((_e) => widths.push(width))
  widths.push('auto')
  return widths
}

const getYearsInfo = (targetParameters) => {
  const { startDate, endDate } = targetParameters
  years.push(`${startDate}-${endDate}`)
  return years.map((year: string) => {
    return {
      text: year,
      style: ['tableHeader'],
    }
  })
}

const getActualInfo = (actual, locale) => {
  return years.map((year: string) => {
    if (year.includes('-')) {
      return {
        text: getMetricActualValue(actual, year, locale),
        style: ['actualInfo'],
      }
    } else
      return {
        text: year.length
          ? getMetricActualValue(actual, year, locale)
          : 'actual',
        style: ['actual'],
      }
  })
}

const getTargetInfo = (target: number, locale) => {
  return years.map((year: string) => {
    return {
      text: year.length ? getMetricTargetValue(target, year, locale) : 'target',
      style: ['target'],
    }
  })
}

export const calculateProgressBarWidth = (
  width: number,
  progressValue: number,
) => {
  if (progressValue > 100) progressValue = 100
  else if (progressValue === 1) progressValue = 1.6
  const percentageValue: number = progressValue ? progressValue / 100 : 0
  return width * percentageValue
}

const getMetricValuesData = (metricValues: any) => {
  years = []
  years.push('')
  if (metricValues.length) {
    metricData = {}
    metricValues.forEach((value: any) => {
      metricData[value.period] = {
        actual: value.actual,
        target: value.target,
      }
    })
    years.push(...Object.keys(metricData))
    return years.length
  }
}

const getMetricActualValue = (actual: number, key: string, locale = 'en') => {
  if (key.includes('-')) {
    return actual
  } else {
    let actualValue = metricData[key]?.actual
    actualValue = actualValue || 0
    return actualValue ? abbreviateNumber(Number(actualValue), locale) : '-'
  }
}

const getMetricTargetValue = (target: number, key, locale = 'en') => {
  if (key.includes('-')) {
    return target
  } else {
    key = Number(key)
    let targetValue = metricData[key]?.target
    targetValue = targetValue || 0
    return targetValue ? abbreviateNumber(Number(targetValue), locale) : 0
  }
}

export const buildTableSlideStyles = {
  tableHeader: {
    margin: [0, 5],
    bold: true,
    fontSize: 10,
    color: '#343F49',
    alignment: 'center',
  },
  actual: {
    color: '#343F49',
    bold: false,
    fontSize: 10,
    alignment: 'center',
  },
  target: {
    color: '#8FA5AF',
    alignment: 'center',
    fontSize: 10,
  },
  metricName: {
    fontSize: 10,
    color: '#5B7185',
    bold: true,
    margin: [-20, 0, 0, 0],
  },
  unitName: {
    color: '#5B7185',
    fontSize: 10,
  },
  actualInfo: {
    color: '#5188ff ',
    bold: true,
    fontSize: 10,
    alignment: 'center',
  },
}
