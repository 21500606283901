import "core-js/modules/es6.array.filter.js";
import { GChart } from 'vue-google-charts';
export default defineComponent({
  components: {
    GChart: GChart
  },
  props: {
    country: String,
    assessments: Array,
    allImpacts: Array,
    filteredImpacts: Array,
    stage: String,
    showPositive: Boolean
  },
  methods: {
    impactsForAssessment: function impactsForAssessment(assessment) {
      if (assessment === undefined || this.filteredImpacts === undefined) return [];
      return this.filteredImpacts.filter(function (impact) {
        return impact.assessment[0] === assessment.id;
      });
    }
  }
});