import { kpiModuleInfoVenturePaiIndicators, kpiModuleInfoVentureQuickTour } from '~/assets/metrics';
import { renderMarkdown } from '~/functions/markdown';
import { useVentureId } from '~/functions/accounts';
export default defineComponent({
  setup: function setup() {
    return {
      ventureId: useVentureId()
    };
  },
  data: function data() {
    return {
      renderMarkdown: renderMarkdown,
      infoKpiModuleQuickTour: kpiModuleInfoVentureQuickTour,
      infoKpiModulePaiIndicators: kpiModuleInfoVenturePaiIndicators
    };
  }
});