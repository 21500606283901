import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  data: function data() {
    return {
      showDetails: false
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});