import { render, staticRenderFns } from "./FilterView.vue?vue&type=template&id=31dec676&scoped=true"
import script from "./FilterView.vue?vue&type=script&lang=ts"
export * from "./FilterView.vue?vue&type=script&lang=ts"
import style0 from "./FilterView.vue?vue&type=style&index=0&id=31dec676&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31dec676",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default,SDG: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SDG.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VChip,VChipGroup,VIcon,VLayout,VSpacer})
