import "core-js/modules/es6.number.constructor.js";
import { color, totalPercentage as _totalPercentage } from '~/functions/esg';
export default defineComponent({
  props: {
    sectionResults: {
      type: Array,
      default: []
    },
    height: {
      type: Number,
      default: 6
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    totalPercentage: function totalPercentage() {
      return _totalPercentage(this.sectionResults);
    },
    colorClass: function colorClass() {
      return color(this.totalPercentage, false);
    }
  },
  methods: {}
});