export default defineComponent({
  props: {
    assessment: {
      type: Object,
      required: true
    },
    ventureDetailToggled: {
      type: Boolean,
      required: true
    }
  }
});