import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e013c12e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default,IxDataTableMetricAssignments: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/MetricAssignments.vue').default,IxIframe: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Iframe.vue').default,IxButtonInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Info.vue').default})
