var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ix-data-table-concepts',{attrs:{"query":{
    text: _vm.$route.query.text,
  },"esgConcepts":true,"height":'calc(100vh - 380px)',"columns":[
    'id',
    'labelI18n.en',
    'labelI18n.de',
    'description',
    'kind',
    'status',
    'scheme',
    'isMaterial',
    'isSubmeasure',
    'materialityExplanation',
    'additionalData.industries',
    'actions',
  ]},on:{"update:text":(v) => {
      _vm.$router.push({ query: { ..._vm.$route.query, text: v } })
    }}})
}
var staticRenderFns = []

export { render, staticRenderFns }