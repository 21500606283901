import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find.js";
import { moduleByKeys } from '~/assets/modules';
import { useGetQuestionnaireQuery, useGetAccountQuery } from '~/types/types';
export default defineComponent({
  props: {
    latestQuestionnaireId: {
      type: Number,
      required: true
    },
    allEsgQuestionnaires: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var ventureId = route === null || route === void 0 ? void 0 : route.params.venture;
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId || ''
      }),
      accountResult = _useGetAccountQuery.result;
    var _useGetAccountQuery2 = useGetAccountQuery(),
      loggedInAccountResult = _useGetAccountQuery2.result,
      loggedInAccountLoading = _useGetAccountQuery2.loading;
    var loggedInAccountType = computed(function () {
      var _loggedInAccountResul, _loggedInAccountResul2;
      return (_loggedInAccountResul = loggedInAccountResult.value) === null || _loggedInAccountResul === void 0 ? void 0 : (_loggedInAccountResul2 = _loggedInAccountResul.getAccount) === null || _loggedInAccountResul2 === void 0 ? void 0 : _loggedInAccountResul2.type;
    });
    var venture = computed(function () {
      var _accountResult$value;
      return ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) || {
        modules: [],
        portfolioLinks: []
      };
    });
    var questionnaireId = computed(function () {
      return props.latestQuestionnaireId;
    });
    var _useGetQuestionnaireQ = useGetQuestionnaireQuery({
        id: questionnaireId.value
      }),
      result = _useGetQuestionnaireQ.result,
      refetch = _useGetQuestionnaireQ.refetch,
      loading = _useGetQuestionnaireQ.loading;
    var questionnaire = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getQuestionnaire;
    });
    var module = computed(function () {
      var _moduleByKeys$esgAsse;
      return ((_moduleByKeys$esgAsse = moduleByKeys.esgAssessment.subModules) === null || _moduleByKeys$esgAsse === void 0 ? void 0 : _moduleByKeys$esgAsse.find(function (e) {
        return e.id === 'esg';
      })) || {};
    });
    var isEditable = computed(function () {
      var _result$value2, _result$value2$getQue, _result$value2$getQue2;
      return (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : (_result$value2$getQue = _result$value2.getQuestionnaire) === null || _result$value2$getQue === void 0 ? void 0 : (_result$value2$getQue2 = _result$value2$getQue.questionnaireAssignment) === null || _result$value2$getQue2 === void 0 ? void 0 : _result$value2$getQue2.isEditable;
    });
    // const loading = useQueryLoading()
    var bookedModules = computed(function () {
      var _venture$value;
      return ((_venture$value = venture.value) === null || _venture$value === void 0 ? void 0 : _venture$value.bookedModules) || [];
    });
    return {
      ventureId: ventureId,
      questionnaire: questionnaire,
      loading: loading,
      refetch: refetch,
      module: module,
      venture: venture,
      isEditable: isEditable,
      bookedModules: bookedModules,
      loggedInAccountResult: loggedInAccountResult,
      loggedInAccountLoading: loggedInAccountLoading,
      loggedInAccountType: loggedInAccountType
    };
  },
  data: function data() {
    return {
      selectedQuestionnaireId: this.latestQuestionnaireId
    };
  },
  methods: {
    updateSelection: function updateSelection(val) {
      this.refetch({
        id: val
      });
      this.selectedQuestionnaireId = val;
    }
  }
});