import "core-js/modules/es6.regexp.match.js";
import { sdgs } from '~/assets/sdgs';
export default defineComponent({
  name: 'SDG',
  props: {
    sdg: String,
    size: String,
    links: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var sdgsList = sdgs;
    var noSDGApplies = computed(function () {
      return props.sdg === 'Not relevant according to SDGs';
    });
    var number = computed(function () {
      return parseInt(props.sdg.match(/\d+/g)[0]);
    });
    return {
      sdgs: sdgs,
      sdgsList: sdgsList,
      noSDGApplies: noSDGApplies,
      number: number
    };
  }
});