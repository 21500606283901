import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es6.array.map.js";
import _ from 'lodash';
import { useListUsersQuery } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['name'];
      }
    },
    filter: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var headers = [{
      value: 'email'
    }, {
      value: 'firstName'
    }, {
      value: 'lastName'
    }, {
      value: 'actions'
    }].filter(function (o) {
      var _props$columns;
      return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
    });
    var options = ref({
      page: 1,
      itemsPerPage: 15,
      search: ''
    });
    var variables = computed(function () {
      return _objectSpread(_objectSpread({}, props.filter), {}, {
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        search: options.value.search || ''
      });
    });
    var _useListUsersQuery = useListUsersQuery(variables),
      result = _useListUsersQuery.result,
      refetch = _useListUsersQuery.refetch,
      loading = _useListUsersQuery.loading;
    var items = computed(function () {
      var _result$value$listUse, _result$value;
      return (_result$value$listUse = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listUsers) !== null && _result$value$listUse !== void 0 ? _result$value$listUse : [];
    });
    var selected = computed(function () {
      return items === null || items === void 0 ? void 0 : items.value.filter(function (user) {
        return (props.value || []).filter(Boolean).includes(user.id || '');
      });
    });
    var onInput = function onInput(val) {
      if (_.isEqual(val.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      }), props.value)) {
        // if the value is the same, don't fire the event
        return;
      }
      emit('input', val.map(function (_ref3) {
        var id = _ref3.id;
        return id;
      }));
    };
    return {
      loading: loading,
      items: items,
      refetch: refetch,
      selected: selected,
      headers: headers,
      onInput: onInput,
      options: options,
      itemCount: computed(function () {
        var _result$value2;
        return ((_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countUsers) || 0;
      })
    };
  }
});