import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import { GChart } from 'vue-google-charts';
import { formattedTooltipData, getTooltipData } from '~/assets/tooltipData';
import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  components: {
    GChart: GChart
  },
  props: {
    geoAggregations: {
      type: Array,
      required: true
    },
    maxColor: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var venturesMap = computed(function () {
      var _result$value, _result$value$getAcco, _result$value$getAcco2, _result$value$getAcco3;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getAcco = _result$value.getAccount) === null || _result$value$getAcco === void 0 ? void 0 : (_result$value$getAcco2 = _result$value$getAcco.ventureLinks) === null || _result$value$getAcco2 === void 0 ? void 0 : (_result$value$getAcco3 = _result$value$getAcco2.filter(function (v) {
        return typeof v.ventureId === 'string' && v.ventureId !== null && v.ventureId !== undefined;
      })) === null || _result$value$getAcco3 === void 0 ? void 0 : _result$value$getAcco3.reduce(function (map, v) {
        return map.set(v.ventureId, v);
      }, new Map())) || new Map();
    });
    var chartData = computed(function () {
      var data = [['Region', 'Number of companies affecting this geography', {
        role: 'tooltip',
        p: {
          html: true
        }
      }]];
      props.geoAggregations.forEach(function (e) {
        var assessmentsData = formattedTooltipData(venturesMap.value, e);
        data.push([{
          v: e.geography.externalId ? e.geography.externalId : e.geography.label,
          f: e.geography.label
        }, e.impacts.length, assessmentsData !== null && assessmentsData !== void 0 && assessmentsData.length ? "<div>\n            <h1 style=\"padding-bottom:5px\"><span style=\"font-weight:bold;padding-bottom:5px\">".concat(e.impacts.length, "</span> impacts affecting this geography</h1>\n            <table width=\"100%\" class=\"table\" style=\"\">\n                <thead>\n                  <tr>\n                    <th></th>\n                    <th style=\"padding-left:20px !important\">Company</th>\n                    <th style=\"padding-left:20px !important;width:auto\">Affected regions</th>\n                    <th style=\"padding-left:20px !important\">Outcome</th>\n                    <th style=\"padding-left:20px !important\">Stakeholder</th>\n                  </tr>\n                <tbody style=\"text-align:left !important;\">\n                ").concat(getTooltipData(assessmentsData, false), "\n                </tbody>\n                </thead>\n              </table>\n          </div>\n\n          ") : []]);
      });
      return data;
    });
    var optionsGeoImpacts = computed(function () {
      return {
        colorAxis: {
          minValue: 0,
          colors: ['#f5f5f5', props.maxColor]
        },
        legend: {
          textStyle: {
            fontName: 'Roboto',
            fontSize: 14
          }
        },
        height: 500,
        focusTarget: 'category',
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      };
    });
    return {
      optionsGeoImpacts: optionsGeoImpacts,
      chartData: chartData
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    createChart: function createChart(el, google) {
      if (el === undefined) {
        return;
      }
      return new google.visualization.GeoChart(el);
    }
  }
});