export const nace = [
  {
    postgres_id: 15000,
    id: 'A',
    sort: 1,
    label: 'AGRICULTURE, FORESTRY AND FISHING',
    children: [
      {
        postgres_id: 15001,
        id: '01',
        sort: 2,
        label:
          'Crop and animal production, hunting and related service activities',
        children: [
          {
            postgres_id: 15002,
            id: '01.1',
            sort: 3,
            label: 'Growing of non-perennial crops',
            children: [
              {
                postgres_id: 15003,
                id: '01.11',
                sort: 4,
                label:
                  'Growing of cereals (except rice), leguminous crops and oil seeds',
              },
              {
                postgres_id: 15004,
                id: '01.12',
                sort: 5,
                label: 'Growing of rice',
              },
              {
                postgres_id: 15005,
                id: '01.13',
                sort: 6,
                label: 'Growing of vegetables and melons, roots and tubers',
              },
              {
                postgres_id: 15006,
                id: '01.14',
                sort: 7,
                label: 'Growing of sugar cane',
              },
              {
                postgres_id: 15007,
                id: '01.15',
                sort: 8,
                label: 'Growing of tobacco',
              },
              {
                postgres_id: 15008,
                id: '01.16',
                sort: 9,
                label: 'Growing of fibre crops',
              },
              {
                postgres_id: 15009,
                id: '01.19',
                sort: 10,
                label: 'Growing of other non-perennial crops',
              },
            ],
          },
          {
            postgres_id: 15010,
            id: '01.2',
            sort: 11,
            label: 'Growing of perennial crops',
            children: [
              {
                postgres_id: 15011,
                id: '01.21',
                sort: 12,
                label: 'Growing of grapes',
              },
              {
                postgres_id: 15012,
                id: '01.22',
                sort: 13,
                label: 'Growing of tropical and subtropical fruits',
              },
              {
                postgres_id: 15013,
                id: '01.23',
                sort: 14,
                label: 'Growing of citrus fruits',
              },
              {
                postgres_id: 15014,
                id: '01.24',
                sort: 15,
                label: 'Growing of pome fruits and stone fruits',
              },
              {
                postgres_id: 15015,
                id: '01.25',
                sort: 16,
                label: 'Growing of other tree and bush fruits and nuts',
              },
              {
                postgres_id: 15016,
                id: '01.26',
                sort: 17,
                label: 'Growing of oleaginous fruits',
              },
              {
                postgres_id: 15017,
                id: '01.27',
                sort: 18,
                label: 'Growing of beverage crops',
              },
              {
                postgres_id: 15018,
                id: '01.28',
                sort: 19,
                label:
                  'Growing of spices, aromatic, drug and pharmaceutical crops',
              },
              {
                postgres_id: 15019,
                id: '01.29',
                sort: 20,
                label: 'Growing of other perennial crops',
              },
            ],
          },
          {
            postgres_id: 15020,
            id: '01.3',
            sort: 21,
            label: 'Plant propagation',
            children: [
              {
                postgres_id: 15021,
                id: '01.30',
                sort: 22,
                label: 'Plant propagation',
              },
            ],
          },
          {
            postgres_id: 15022,
            id: '01.4',
            sort: 23,
            label: 'Animal production',
            children: [
              {
                postgres_id: 15023,
                id: '01.41',
                sort: 24,
                label: 'Raising of dairy cattle',
              },
              {
                postgres_id: 15024,
                id: '01.42',
                sort: 25,
                label: 'Raising of other cattle and buffaloes',
              },
              {
                postgres_id: 15025,
                id: '01.43',
                sort: 26,
                label: 'Raising of horses and other equines',
              },
              {
                postgres_id: 15026,
                id: '01.44',
                sort: 27,
                label: 'Raising of camels and camelids',
              },
              {
                postgres_id: 15027,
                id: '01.45',
                sort: 28,
                label: 'Raising of sheep and goats',
              },
              {
                postgres_id: 15028,
                id: '01.46',
                sort: 29,
                label: 'Raising of swine/pigs',
              },
              {
                postgres_id: 15029,
                id: '01.47',
                sort: 30,
                label: 'Raising of poultry',
              },
              {
                postgres_id: 15030,
                id: '01.49',
                sort: 31,
                label: 'Raising of other animals',
              },
            ],
          },
          {
            postgres_id: 15031,
            id: '01.5',
            sort: 32,
            label: 'Mixed farming',
            children: [
              {
                postgres_id: 15032,
                id: '01.50',
                sort: 33,
                label: 'Mixed farming',
              },
            ],
          },
          {
            postgres_id: 15033,
            id: '01.6',
            sort: 34,
            label:
              'Support activities to agriculture and post-harvest crop activities',
            children: [
              {
                postgres_id: 15034,
                id: '01.61',
                sort: 35,
                label: 'Support activities for crop production',
              },
              {
                postgres_id: 15035,
                id: '01.62',
                sort: 36,
                label: 'Support activities for animal production',
              },
              {
                postgres_id: 15036,
                id: '01.63',
                sort: 37,
                label: 'Post-harvest crop activities',
              },
              {
                postgres_id: 15037,
                id: '01.64',
                sort: 38,
                label: 'Seed processing for propagation',
              },
            ],
          },
          {
            postgres_id: 15038,
            id: '01.7',
            sort: 39,
            label: 'Hunting, trapping and related service activities',
            children: [
              {
                postgres_id: 15039,
                id: '01.70',
                sort: 40,
                label: 'Hunting, trapping and related service activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15040,
        id: '02',
        sort: 41,
        label: 'Forestry and logging',
        children: [
          {
            postgres_id: 15041,
            id: '02.1',
            sort: 42,
            label: 'Silviculture and other forestry activities',
            children: [
              {
                postgres_id: 15042,
                id: '02.10',
                sort: 43,
                label: 'Silviculture and other forestry activities',
              },
            ],
          },
          {
            postgres_id: 15043,
            id: '02.2',
            sort: 44,
            label: 'Logging',
            children: [
              {
                postgres_id: 15044,
                id: '02.20',
                sort: 45,
                label: 'Logging',
              },
            ],
          },
          {
            postgres_id: 15045,
            id: '02.3',
            sort: 46,
            label: 'Gathering of wild growing non-wood products',
            children: [
              {
                postgres_id: 15046,
                id: '02.30',
                sort: 47,
                label: 'Gathering of wild growing non-wood products',
              },
            ],
          },
          {
            postgres_id: 15047,
            id: '02.4',
            sort: 48,
            label: 'Support services to forestry',
            children: [
              {
                postgres_id: 15048,
                id: '02.40',
                sort: 49,
                label: 'Support services to forestry',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15049,
        id: '03',
        sort: 50,
        label: 'Fishing and aquaculture',
        children: [
          {
            postgres_id: 15050,
            id: '03.1',
            sort: 51,
            label: 'Fishing',
            children: [
              {
                postgres_id: 15051,
                id: '03.11',
                sort: 52,
                label: 'Marine fishing',
              },
              {
                postgres_id: 15052,
                id: '03.12',
                sort: 53,
                label: 'Freshwater fishing',
              },
            ],
          },
          {
            postgres_id: 15053,
            id: '03.2',
            sort: 54,
            label: 'Aquaculture',
            children: [
              {
                postgres_id: 15054,
                id: '03.21',
                sort: 55,
                label: 'Marine aquaculture',
              },
              {
                postgres_id: 15055,
                id: '03.22',
                sort: 56,
                label: 'Freshwater aquaculture',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15056,
    id: 'B',
    sort: 57,
    label: 'MINING AND QUARRYING',
    children: [
      {
        postgres_id: 15057,
        id: '05',
        sort: 58,
        label: 'Mining of coal and lignite',
        children: [
          {
            postgres_id: 15058,
            id: '05.1',
            sort: 59,
            label: 'Mining of hard coal',
            children: [
              {
                postgres_id: 15059,
                id: '05.10',
                sort: 60,
                label: 'Mining of hard coal',
              },
            ],
          },
          {
            postgres_id: 15060,
            id: '05.2',
            sort: 61,
            label: 'Mining of lignite',
            children: [
              {
                postgres_id: 15061,
                id: '05.20',
                sort: 62,
                label: 'Mining of lignite',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15062,
        id: '06',
        sort: 63,
        label: 'Extraction of crude petroleum and natural gas',
        children: [
          {
            postgres_id: 15063,
            id: '06.1',
            sort: 64,
            label: 'Extraction of crude petroleum',
            children: [
              {
                postgres_id: 15064,
                id: '06.10',
                sort: 65,
                label: 'Extraction of crude petroleum',
              },
            ],
          },
          {
            postgres_id: 15065,
            id: '06.2',
            sort: 66,
            label: 'Extraction of natural gas',
            children: [
              {
                postgres_id: 15066,
                id: '06.20',
                sort: 67,
                label: 'Extraction of natural gas',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15067,
        id: '07',
        sort: 68,
        label: 'Mining of metal ores',
        children: [
          {
            postgres_id: 15068,
            id: '07.1',
            sort: 69,
            label: 'Mining of iron ores',
            children: [
              {
                postgres_id: 15069,
                id: '07.10',
                sort: 70,
                label: 'Mining of iron ores',
              },
            ],
          },
          {
            postgres_id: 15070,
            id: '07.2',
            sort: 71,
            label: 'Mining of non-ferrous metal ores',
            children: [
              {
                postgres_id: 15071,
                id: '07.21',
                sort: 72,
                label: 'Mining of uranium and thorium ores',
              },
              {
                postgres_id: 15072,
                id: '07.29',
                sort: 73,
                label: 'Mining of other non-ferrous metal ores',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15073,
        id: '08',
        sort: 74,
        label: 'Other mining and quarrying',
        children: [
          {
            postgres_id: 15074,
            id: '08.1',
            sort: 75,
            label: 'Quarrying of stone, sand and clay',
            children: [
              {
                postgres_id: 15075,
                id: '08.11',
                sort: 76,
                label:
                  'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
              },
              {
                postgres_id: 15076,
                id: '08.12',
                sort: 77,
                label:
                  'Operation of gravel and sand pits; mining of clays and kaolin',
              },
            ],
          },
          {
            postgres_id: 15077,
            id: '08.9',
            sort: 78,
            label: 'Mining and quarrying n.e.c.',
            children: [
              {
                postgres_id: 15078,
                id: '08.91',
                sort: 79,
                label: 'Mining of chemical and fertiliser minerals',
              },
              {
                postgres_id: 15079,
                id: '08.92',
                sort: 80,
                label: 'Extraction of peat',
              },
              {
                postgres_id: 15080,
                id: '08.93',
                sort: 81,
                label: 'Extraction of salt',
              },
              {
                postgres_id: 15081,
                id: '08.99',
                sort: 82,
                label: 'Other mining and quarrying n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15082,
        id: '09',
        sort: 83,
        label: 'Mining support service activities',
        children: [
          {
            postgres_id: 15083,
            id: '09.1',
            sort: 84,
            label:
              'Support activities for petroleum and natural gas extraction',
            children: [
              {
                postgres_id: 15084,
                id: '09.10',
                sort: 85,
                label:
                  'Support activities for petroleum and natural gas extraction',
              },
            ],
          },
          {
            postgres_id: 15085,
            id: '09.9',
            sort: 86,
            label: 'Support activities for other mining and quarrying',
            children: [
              {
                postgres_id: 15086,
                id: '09.90',
                sort: 87,
                label: 'Support activities for other mining and quarrying',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15087,
    id: 'C',
    sort: 88,
    label: 'MANUFACTURING',
    children: [
      {
        postgres_id: 15088,
        id: '10',
        sort: 89,
        label: 'Manufacture of food products',
        children: [
          {
            postgres_id: 15089,
            id: '10.1',
            sort: 90,
            label:
              'Processing and preserving of meat and production of meat products',
            children: [
              {
                postgres_id: 15090,
                id: '10.11',
                sort: 91,
                label: 'Processing and preserving of meat',
              },
              {
                postgres_id: 15091,
                id: '10.12',
                sort: 92,
                label: 'Processing and preserving of poultry meat',
              },
              {
                postgres_id: 15092,
                id: '10.13',
                sort: 93,
                label: 'Production of meat and poultry meat products',
              },
            ],
          },
          {
            postgres_id: 15093,
            id: '10.2',
            sort: 94,
            label:
              'Processing and preserving of fish, crustaceans and molluscs',
            children: [
              {
                postgres_id: 15094,
                id: '10.20',
                sort: 95,
                label:
                  'Processing and preserving of fish, crustaceans and molluscs',
              },
            ],
          },
          {
            postgres_id: 15095,
            id: '10.3',
            sort: 96,
            label: 'Processing and preserving of fruit and vegetables',
            children: [
              {
                postgres_id: 15096,
                id: '10.31',
                sort: 97,
                label: 'Processing and preserving of potatoes',
              },
              {
                postgres_id: 15097,
                id: '10.32',
                sort: 98,
                label: 'Manufacture of fruit and vegetable juice',
              },
              {
                postgres_id: 15098,
                id: '10.39',
                sort: 99,
                label:
                  'Other processing and preserving of fruit and vegetables',
              },
            ],
          },
          {
            postgres_id: 15099,
            id: '10.4',
            sort: 100,
            label: 'Manufacture of vegetable and animal oils and fats',
            children: [
              {
                postgres_id: 15100,
                id: '10.41',
                sort: 101,
                label: 'Manufacture of oils and fats',
              },
              {
                postgres_id: 15101,
                id: '10.42',
                sort: 102,
                label: 'Manufacture of margarine and similar edible fats',
              },
            ],
          },
          {
            postgres_id: 15102,
            id: '10.5',
            sort: 103,
            label: 'Manufacture of dairy products',
            children: [
              {
                postgres_id: 15103,
                id: '10.51',
                sort: 104,
                label: 'Operation of dairies and cheese making',
              },
              {
                postgres_id: 15104,
                id: '10.52',
                sort: 105,
                label: 'Manufacture of ice cream',
              },
            ],
          },
          {
            postgres_id: 15105,
            id: '10.6',
            sort: 106,
            label:
              'Manufacture of grain mill products, starches and starch products',
            children: [
              {
                postgres_id: 15106,
                id: '10.61',
                sort: 107,
                label: 'Manufacture of grain mill products',
              },
              {
                postgres_id: 15107,
                id: '10.62',
                sort: 108,
                label: 'Manufacture of starches and starch products',
              },
            ],
          },
          {
            postgres_id: 15108,
            id: '10.7',
            sort: 109,
            label: 'Manufacture of bakery and farinaceous products',
            children: [
              {
                postgres_id: 15109,
                id: '10.71',
                sort: 110,
                label:
                  'Manufacture of bread; manufacture of fresh pastry goods and cakes',
              },
              {
                postgres_id: 15110,
                id: '10.72',
                sort: 111,
                label:
                  'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
              },
              {
                postgres_id: 15111,
                id: '10.73',
                sort: 112,
                label:
                  'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
              },
            ],
          },
          {
            postgres_id: 15112,
            id: '10.8',
            sort: 113,
            label: 'Manufacture of other food products',
            children: [
              {
                postgres_id: 15113,
                id: '10.81',
                sort: 114,
                label: 'Manufacture of sugar',
              },
              {
                postgres_id: 15114,
                id: '10.82',
                sort: 115,
                label:
                  'Manufacture of cocoa, chocolate and sugar confectionery',
              },
              {
                postgres_id: 15115,
                id: '10.83',
                sort: 116,
                label: 'Processing of tea and coffee',
              },
              {
                postgres_id: 15116,
                id: '10.84',
                sort: 117,
                label: 'Manufacture of condiments and seasonings',
              },
              {
                postgres_id: 15117,
                id: '10.85',
                sort: 118,
                label: 'Manufacture of prepared meals and dishes',
              },
              {
                postgres_id: 15118,
                id: '10.86',
                sort: 119,
                label:
                  'Manufacture of homogenised food preparations and dietetic food',
              },
              {
                postgres_id: 15119,
                id: '10.89',
                sort: 120,
                label: 'Manufacture of other food products n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15120,
            id: '10.9',
            sort: 121,
            label: 'Manufacture of prepared animal feeds',
            children: [
              {
                postgres_id: 15121,
                id: '10.91',
                sort: 122,
                label: 'Manufacture of prepared feeds for farm animals',
              },
              {
                postgres_id: 15122,
                id: '10.92',
                sort: 123,
                label: 'Manufacture of prepared pet foods',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15123,
        id: '11',
        sort: 124,
        label: 'Manufacture of beverages',
        children: [
          {
            postgres_id: 15124,
            id: '11.0',
            sort: 125,
            label: 'Manufacture of beverages',
            children: [
              {
                postgres_id: 15125,
                id: '11.01',
                sort: 126,
                label: 'Distilling, rectifying and blending of spirits',
              },
              {
                postgres_id: 15126,
                id: '11.02',
                sort: 127,
                label: 'Manufacture of wine from grape',
              },
              {
                postgres_id: 15127,
                id: '11.03',
                sort: 128,
                label: 'Manufacture of cider and other fruit wines',
              },
              {
                postgres_id: 15128,
                id: '11.04',
                sort: 129,
                label: 'Manufacture of other non-distilled fermented beverages',
              },
              {
                postgres_id: 15129,
                id: '11.05',
                sort: 130,
                label: 'Manufacture of beer',
              },
              {
                postgres_id: 15130,
                id: '11.06',
                sort: 131,
                label: 'Manufacture of malt',
              },
              {
                postgres_id: 15131,
                id: '11.07',
                sort: 132,
                label:
                  'Manufacture of soft drinks; production of mineral waters and other bottled waters',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15132,
        id: '12',
        sort: 133,
        label: 'Manufacture of tobacco products',
        children: [
          {
            postgres_id: 15133,
            id: '12.0',
            sort: 134,
            label: 'Manufacture of tobacco products',
            children: [
              {
                postgres_id: 15134,
                id: '12.00',
                sort: 135,
                label: 'Manufacture of tobacco products',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15135,
        id: '13',
        sort: 136,
        label: 'Manufacture of textiles',
        children: [
          {
            postgres_id: 15136,
            id: '13.1',
            sort: 137,
            label: 'Preparation and spinning of textile fibres',
            children: [
              {
                postgres_id: 15137,
                id: '13.10',
                sort: 138,
                label: 'Preparation and spinning of textile fibres',
              },
            ],
          },
          {
            postgres_id: 15138,
            id: '13.2',
            sort: 139,
            label: 'Weaving of textiles',
            children: [
              {
                postgres_id: 15139,
                id: '13.20',
                sort: 140,
                label: 'Weaving of textiles',
              },
            ],
          },
          {
            postgres_id: 15140,
            id: '13.3',
            sort: 141,
            label: 'Finishing of textiles',
            children: [
              {
                postgres_id: 15141,
                id: '13.30',
                sort: 142,
                label: 'Finishing of textiles',
              },
            ],
          },
          {
            postgres_id: 15142,
            id: '13.9',
            sort: 143,
            label: 'Manufacture of other textiles',
            children: [
              {
                postgres_id: 15143,
                id: '13.91',
                sort: 144,
                label: 'Manufacture of knitted and crocheted fabrics',
              },
              {
                postgres_id: 15144,
                id: '13.92',
                sort: 145,
                label:
                  'Manufacture of made-up textile articles, except apparel',
              },
              {
                postgres_id: 15145,
                id: '13.93',
                sort: 146,
                label: 'Manufacture of carpets and rugs',
              },
              {
                postgres_id: 15146,
                id: '13.94',
                sort: 147,
                label: 'Manufacture of cordage, rope, twine and netting',
              },
              {
                postgres_id: 15147,
                id: '13.95',
                sort: 148,
                label:
                  'Manufacture of non-wovens and articles made from non-wovens, except apparel',
              },
              {
                postgres_id: 15148,
                id: '13.96',
                sort: 149,
                label: 'Manufacture of other technical and industrial textiles',
              },
              {
                postgres_id: 15149,
                id: '13.99',
                sort: 150,
                label: 'Manufacture of other textiles n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15150,
        id: '14',
        sort: 151,
        label: 'Manufacture of wearing apparel',
        children: [
          {
            postgres_id: 15151,
            id: '14.1',
            sort: 152,
            label: 'Manufacture of wearing apparel, except fur apparel',
            children: [
              {
                postgres_id: 15152,
                id: '14.11',
                sort: 153,
                label: 'Manufacture of leather clothes',
              },
              {
                postgres_id: 15153,
                id: '14.12',
                sort: 154,
                label: 'Manufacture of workwear',
              },
              {
                postgres_id: 15154,
                id: '14.13',
                sort: 155,
                label: 'Manufacture of other outerwear',
              },
              {
                postgres_id: 15155,
                id: '14.14',
                sort: 156,
                label: 'Manufacture of underwear',
              },
              {
                postgres_id: 15156,
                id: '14.19',
                sort: 157,
                label: 'Manufacture of other wearing apparel and accessories',
              },
            ],
          },
          {
            postgres_id: 15157,
            id: '14.2',
            sort: 158,
            label: 'Manufacture of articles of fur',
            children: [
              {
                postgres_id: 15158,
                id: '14.20',
                sort: 159,
                label: 'Manufacture of articles of fur',
              },
            ],
          },
          {
            postgres_id: 15159,
            id: '14.3',
            sort: 160,
            label: 'Manufacture of knitted and crocheted apparel',
            children: [
              {
                postgres_id: 15160,
                id: '14.31',
                sort: 161,
                label: 'Manufacture of knitted and crocheted hosiery',
              },
              {
                postgres_id: 15161,
                id: '14.39',
                sort: 162,
                label: 'Manufacture of other knitted and crocheted apparel',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15162,
        id: '15',
        sort: 163,
        label: 'Manufacture of leather and related products',
        children: [
          {
            postgres_id: 15163,
            id: '15.1',
            sort: 164,
            label:
              'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
            children: [
              {
                postgres_id: 15164,
                id: '15.11',
                sort: 165,
                label:
                  'Tanning and dressing of leather; dressing and dyeing of fur',
              },
              {
                postgres_id: 15165,
                id: '15.12',
                sort: 166,
                label:
                  'Manufacture of luggage, handbags and the like, saddlery and harness',
              },
            ],
          },
          {
            postgres_id: 15166,
            id: '15.2',
            sort: 167,
            label: 'Manufacture of footwear',
            children: [
              {
                postgres_id: 15167,
                id: '15.20',
                sort: 168,
                label: 'Manufacture of footwear',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15168,
        id: '16',
        sort: 169,
        label:
          'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
        children: [
          {
            postgres_id: 15169,
            id: '16.1',
            sort: 170,
            label: 'Sawmilling and planing of wood',
            children: [
              {
                postgres_id: 15170,
                id: '16.10',
                sort: 171,
                label: 'Sawmilling and planing of wood',
              },
            ],
          },
          {
            postgres_id: 15171,
            id: '16.2',
            sort: 172,
            label:
              'Manufacture of products of wood, cork, straw and plaiting materials',
            children: [
              {
                postgres_id: 15172,
                id: '16.21',
                sort: 173,
                label: 'Manufacture of veneer sheets and wood-based panels',
              },
              {
                postgres_id: 15173,
                id: '16.22',
                sort: 174,
                label: 'Manufacture of assembled parquet floors',
              },
              {
                postgres_id: 15174,
                id: '16.23',
                sort: 175,
                label: "Manufacture of other builders' carpentry and joinery",
              },
              {
                postgres_id: 15175,
                id: '16.24',
                sort: 176,
                label: 'Manufacture of wooden containers',
              },
              {
                postgres_id: 15176,
                id: '16.29',
                sort: 177,
                label:
                  'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15177,
        id: '17',
        sort: 178,
        label: 'Manufacture of paper and paper products',
        children: [
          {
            postgres_id: 15178,
            id: '17.1',
            sort: 179,
            label: 'Manufacture of pulp, paper and paperboard',
            children: [
              {
                postgres_id: 15179,
                id: '17.11',
                sort: 180,
                label: 'Manufacture of pulp',
              },
              {
                postgres_id: 15180,
                id: '17.12',
                sort: 181,
                label: 'Manufacture of paper and paperboard',
              },
            ],
          },
          {
            postgres_id: 15181,
            id: '17.2',
            sort: 182,
            label: 'Manufacture of articles of paper and paperboard ',
            children: [
              {
                postgres_id: 15182,
                id: '17.21',
                sort: 183,
                label:
                  'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
              },
              {
                postgres_id: 15183,
                id: '17.22',
                sort: 184,
                label:
                  'Manufacture of household and sanitary goods and of toilet requisites',
              },
              {
                postgres_id: 15184,
                id: '17.23',
                sort: 185,
                label: 'Manufacture of paper stationery',
              },
              {
                postgres_id: 15185,
                id: '17.24',
                sort: 186,
                label: 'Manufacture of wallpaper',
              },
              {
                postgres_id: 15186,
                id: '17.29',
                sort: 187,
                label: 'Manufacture of other articles of paper and paperboard',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15187,
        id: '18',
        sort: 188,
        label: 'Printing and reproduction of recorded media',
        children: [
          {
            postgres_id: 15188,
            id: '18.1',
            sort: 189,
            label: 'Printing and service activities related to printing',
            children: [
              {
                postgres_id: 15189,
                id: '18.11',
                sort: 190,
                label: 'Printing of newspapers',
              },
              {
                postgres_id: 15190,
                id: '18.12',
                sort: 191,
                label: 'Other printing',
              },
              {
                postgres_id: 15191,
                id: '18.13',
                sort: 192,
                label: 'Pre-press and pre-media services',
              },
              {
                postgres_id: 15192,
                id: '18.14',
                sort: 193,
                label: 'Binding and related services',
              },
            ],
          },
          {
            postgres_id: 15193,
            id: '18.2',
            sort: 194,
            label: 'Reproduction of recorded media',
            children: [
              {
                postgres_id: 15194,
                id: '18.20',
                sort: 195,
                label: 'Reproduction of recorded media',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15195,
        id: '19',
        sort: 196,
        label: 'Manufacture of coke and refined petroleum products',
        children: [
          {
            postgres_id: 15196,
            id: '19.1',
            sort: 197,
            label: 'Manufacture of coke oven products',
            children: [
              {
                postgres_id: 15197,
                id: '19.10',
                sort: 198,
                label: 'Manufacture of coke oven products',
              },
            ],
          },
          {
            postgres_id: 15198,
            id: '19.2',
            sort: 199,
            label: 'Manufacture of refined petroleum products',
            children: [
              {
                postgres_id: 15199,
                id: '19.20',
                sort: 200,
                label: 'Manufacture of refined petroleum products',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15200,
        id: '20',
        sort: 201,
        label: 'Manufacture of chemicals and chemical products',
        children: [
          {
            postgres_id: 15201,
            id: '20.1',
            sort: 202,
            label:
              'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
            children: [
              {
                postgres_id: 15202,
                id: '20.11',
                sort: 203,
                label: 'Manufacture of industrial gases',
              },
              {
                postgres_id: 15203,
                id: '20.12',
                sort: 204,
                label: 'Manufacture of dyes and pigments',
              },
              {
                postgres_id: 15204,
                id: '20.13',
                sort: 205,
                label: 'Manufacture of other inorganic basic chemicals',
              },
              {
                postgres_id: 15205,
                id: '20.14',
                sort: 206,
                label: 'Manufacture of other organic basic chemicals',
              },
              {
                postgres_id: 15206,
                id: '20.15',
                sort: 207,
                label: 'Manufacture of fertilisers and nitrogen compounds',
              },
              {
                postgres_id: 15207,
                id: '20.16',
                sort: 208,
                label: 'Manufacture of plastics in primary forms',
              },
              {
                postgres_id: 15208,
                id: '20.17',
                sort: 209,
                label: 'Manufacture of synthetic rubber in primary forms',
              },
            ],
          },
          {
            postgres_id: 15209,
            id: '20.2',
            sort: 210,
            label: 'Manufacture of pesticides and other agrochemical products',
            children: [
              {
                postgres_id: 15210,
                id: '20.20',
                sort: 211,
                label:
                  'Manufacture of pesticides and other agrochemical products',
              },
            ],
          },
          {
            postgres_id: 15211,
            id: '20.3',
            sort: 212,
            label:
              'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
            children: [
              {
                postgres_id: 15212,
                id: '20.30',
                sort: 213,
                label:
                  'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
              },
            ],
          },
          {
            postgres_id: 15213,
            id: '20.4',
            sort: 214,
            label:
              'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
            children: [
              {
                postgres_id: 15214,
                id: '20.41',
                sort: 215,
                label:
                  'Manufacture of soap and detergents, cleaning and polishing preparations',
              },
              {
                postgres_id: 15215,
                id: '20.42',
                sort: 216,
                label: 'Manufacture of perfumes and toilet preparations',
              },
            ],
          },
          {
            postgres_id: 15216,
            id: '20.5',
            sort: 217,
            label: 'Manufacture of other chemical products',
            children: [
              {
                postgres_id: 15217,
                id: '20.51',
                sort: 218,
                label: 'Manufacture of explosives',
              },
              {
                postgres_id: 15218,
                id: '20.52',
                sort: 219,
                label: 'Manufacture of glues',
              },
              {
                postgres_id: 15219,
                id: '20.53',
                sort: 220,
                label: 'Manufacture of essential oils',
              },
              {
                postgres_id: 15220,
                id: '20.59',
                sort: 221,
                label: 'Manufacture of other chemical products n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15221,
            id: '20.6',
            sort: 222,
            label: 'Manufacture of man-made fibres',
            children: [
              {
                postgres_id: 15222,
                id: '20.60',
                sort: 223,
                label: 'Manufacture of man-made fibres',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15223,
        id: '21',
        sort: 224,
        label:
          'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
        children: [
          {
            postgres_id: 15224,
            id: '21.1',
            sort: 225,
            label: 'Manufacture of basic pharmaceutical products',
            children: [
              {
                postgres_id: 15225,
                id: '21.10',
                sort: 226,
                label: 'Manufacture of basic pharmaceutical products',
              },
            ],
          },
          {
            postgres_id: 15226,
            id: '21.2',
            sort: 227,
            label: 'Manufacture of pharmaceutical preparations',
            children: [
              {
                postgres_id: 15227,
                id: '21.20',
                sort: 228,
                label: 'Manufacture of pharmaceutical preparations',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15228,
        id: '22',
        sort: 229,
        label: 'Manufacture of rubber and plastic products',
        children: [
          {
            postgres_id: 15229,
            id: '22.1',
            sort: 230,
            label: 'Manufacture of rubber products',
            children: [
              {
                postgres_id: 15230,
                id: '22.11',
                sort: 231,
                label:
                  'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
              },
              {
                postgres_id: 15231,
                id: '22.19',
                sort: 232,
                label: 'Manufacture of other rubber products',
              },
            ],
          },
          {
            postgres_id: 15232,
            id: '22.2',
            sort: 233,
            label: 'Manufacture of plastic products',
            children: [
              {
                postgres_id: 15233,
                id: '22.21',
                sort: 234,
                label:
                  'Manufacture of plastic plates, sheets, tubes and profiles',
              },
              {
                postgres_id: 15234,
                id: '22.22',
                sort: 235,
                label: 'Manufacture of plastic packing goods',
              },
              {
                postgres_id: 15235,
                id: '22.23',
                sort: 236,
                label: 'Manufacture of builders\u2019 ware of plastic',
              },
              {
                postgres_id: 15236,
                id: '22.29',
                sort: 237,
                label: 'Manufacture of other plastic products',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15237,
        id: '23',
        sort: 238,
        label: 'Manufacture of other non-metallic mineral products',
        children: [
          {
            postgres_id: 15238,
            id: '23.1',
            sort: 239,
            label: 'Manufacture of glass and glass products',
            children: [
              {
                postgres_id: 15239,
                id: '23.11',
                sort: 240,
                label: 'Manufacture of flat glass',
              },
              {
                postgres_id: 15240,
                id: '23.12',
                sort: 241,
                label: 'Shaping and processing of flat glass',
              },
              {
                postgres_id: 15241,
                id: '23.13',
                sort: 242,
                label: 'Manufacture of hollow glass',
              },
              {
                postgres_id: 15242,
                id: '23.14',
                sort: 243,
                label: 'Manufacture of glass fibres',
              },
              {
                postgres_id: 15243,
                id: '23.19',
                sort: 244,
                label:
                  'Manufacture and processing of other glass, including technical glassware',
              },
            ],
          },
          {
            postgres_id: 15244,
            id: '23.2',
            sort: 245,
            label: 'Manufacture of refractory products',
            children: [
              {
                postgres_id: 15245,
                id: '23.20',
                sort: 246,
                label: 'Manufacture of refractory products',
              },
            ],
          },
          {
            postgres_id: 15246,
            id: '23.3',
            sort: 247,
            label: 'Manufacture of clay building materials',
            children: [
              {
                postgres_id: 15247,
                id: '23.31',
                sort: 248,
                label: 'Manufacture of ceramic tiles and flags',
              },
              {
                postgres_id: 15248,
                id: '23.32',
                sort: 249,
                label:
                  'Manufacture of bricks, tiles and construction products, in baked clay',
              },
            ],
          },
          {
            postgres_id: 15249,
            id: '23.4',
            sort: 250,
            label: 'Manufacture of other porcelain and ceramic products',
            children: [
              {
                postgres_id: 15250,
                id: '23.41',
                sort: 251,
                label:
                  'Manufacture of ceramic household and ornamental articles',
              },
              {
                postgres_id: 15251,
                id: '23.42',
                sort: 252,
                label: 'Manufacture of ceramic sanitary fixtures',
              },
              {
                postgres_id: 15252,
                id: '23.43',
                sort: 253,
                label:
                  'Manufacture of ceramic insulators and insulating fittings',
              },
              {
                postgres_id: 15253,
                id: '23.44',
                sort: 254,
                label: 'Manufacture of other technical ceramic products',
              },
              {
                postgres_id: 15254,
                id: '23.49',
                sort: 255,
                label: 'Manufacture of other ceramic products',
              },
            ],
          },
          {
            postgres_id: 15255,
            id: '23.5',
            sort: 256,
            label: 'Manufacture of cement, lime and plaster',
            children: [
              {
                postgres_id: 15256,
                id: '23.51',
                sort: 257,
                label: 'Manufacture of cement',
              },
              {
                postgres_id: 15257,
                id: '23.52',
                sort: 258,
                label: 'Manufacture of lime and plaster',
              },
            ],
          },
          {
            postgres_id: 15258,
            id: '23.6',
            sort: 259,
            label: 'Manufacture of articles of concrete, cement and plaster',
            children: [
              {
                postgres_id: 15259,
                id: '23.61',
                sort: 260,
                label:
                  'Manufacture of concrete products for construction purposes',
              },
              {
                postgres_id: 15260,
                id: '23.62',
                sort: 261,
                label:
                  'Manufacture of plaster products for construction purposes',
              },
              {
                postgres_id: 15261,
                id: '23.63',
                sort: 262,
                label: 'Manufacture of ready-mixed concrete',
              },
              {
                postgres_id: 15262,
                id: '23.64',
                sort: 263,
                label: 'Manufacture of mortars',
              },
              {
                postgres_id: 15263,
                id: '23.65',
                sort: 264,
                label: 'Manufacture of fibre cement',
              },
              {
                postgres_id: 15264,
                id: '23.69',
                sort: 265,
                label:
                  'Manufacture of other articles of concrete, plaster and cement',
              },
            ],
          },
          {
            postgres_id: 15265,
            id: '23.7',
            sort: 266,
            label: 'Cutting, shaping and finishing of stone',
            children: [
              {
                postgres_id: 15266,
                id: '23.70',
                sort: 267,
                label: 'Cutting, shaping and finishing of stone',
              },
            ],
          },
          {
            postgres_id: 15267,
            id: '23.9',
            sort: 268,
            label:
              'Manufacture of abrasive products and non-metallic mineral products n.e.c.',
            children: [
              {
                postgres_id: 15268,
                id: '23.91',
                sort: 269,
                label: 'Production of abrasive products',
              },
              {
                postgres_id: 15269,
                id: '23.99',
                sort: 270,
                label:
                  'Manufacture of other non-metallic mineral products n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15270,
        id: '24',
        sort: 271,
        label: 'Manufacture of basic metals',
        children: [
          {
            postgres_id: 15271,
            id: '24.1',
            sort: 272,
            label: 'Manufacture of basic iron and steel and of ferro-alloys',
            children: [
              {
                postgres_id: 15272,
                id: '24.10',
                sort: 273,
                label:
                  'Manufacture of basic iron and steel and of ferro-alloys ',
              },
            ],
          },
          {
            postgres_id: 15273,
            id: '24.2',
            sort: 274,
            label:
              'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
            children: [
              {
                postgres_id: 15274,
                id: '24.20',
                sort: 275,
                label:
                  'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
              },
            ],
          },
          {
            postgres_id: 15275,
            id: '24.3',
            sort: 276,
            label: 'Manufacture of other products of first processing of steel',
            children: [
              {
                postgres_id: 15276,
                id: '24.31',
                sort: 277,
                label: 'Cold drawing of bars',
              },
              {
                postgres_id: 15277,
                id: '24.32',
                sort: 278,
                label: 'Cold rolling of narrow strip',
              },
              {
                postgres_id: 15278,
                id: '24.33',
                sort: 279,
                label: 'Cold forming or folding',
              },
              {
                postgres_id: 15279,
                id: '24.34',
                sort: 280,
                label: 'Cold drawing of wire',
              },
            ],
          },
          {
            postgres_id: 15280,
            id: '24.4',
            sort: 281,
            label: 'Manufacture of basic precious and other non-ferrous metals',
            children: [
              {
                postgres_id: 15281,
                id: '24.41',
                sort: 282,
                label: 'Precious metals production',
              },
              {
                postgres_id: 15282,
                id: '24.42',
                sort: 283,
                label: 'Aluminium production',
              },
              {
                postgres_id: 15283,
                id: '24.43',
                sort: 284,
                label: 'Lead, zinc and tin production',
              },
              {
                postgres_id: 15284,
                id: '24.44',
                sort: 285,
                label: 'Copper production',
              },
              {
                postgres_id: 15285,
                id: '24.45',
                sort: 286,
                label: 'Other non-ferrous metal production',
              },
              {
                postgres_id: 15286,
                id: '24.46',
                sort: 287,
                label: 'Processing of nuclear fuel ',
              },
            ],
          },
          {
            postgres_id: 15287,
            id: '24.5',
            sort: 288,
            label: 'Casting of metals',
            children: [
              {
                postgres_id: 15288,
                id: '24.51',
                sort: 289,
                label: 'Casting of iron',
              },
              {
                postgres_id: 15289,
                id: '24.52',
                sort: 290,
                label: 'Casting of steel',
              },
              {
                postgres_id: 15290,
                id: '24.53',
                sort: 291,
                label: 'Casting of light metals',
              },
              {
                postgres_id: 15291,
                id: '24.54',
                sort: 292,
                label: 'Casting of other non-ferrous metals',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15292,
        id: '25',
        sort: 293,
        label:
          'Manufacture of fabricated metal products, except machinery and equipment',
        children: [
          {
            postgres_id: 15293,
            id: '25.1',
            sort: 294,
            label: 'Manufacture of structural metal products',
            children: [
              {
                postgres_id: 15294,
                id: '25.11',
                sort: 295,
                label:
                  'Manufacture of metal structures and parts of structures',
              },
              {
                postgres_id: 15295,
                id: '25.12',
                sort: 296,
                label: 'Manufacture of doors and windows of metal',
              },
            ],
          },
          {
            postgres_id: 15296,
            id: '25.2',
            sort: 297,
            label: 'Manufacture of tanks, reservoirs and containers of metal',
            children: [
              {
                postgres_id: 15297,
                id: '25.21',
                sort: 298,
                label: 'Manufacture of central heating radiators and boilers',
              },
              {
                postgres_id: 15298,
                id: '25.29',
                sort: 299,
                label:
                  'Manufacture of other tanks, reservoirs and containers of metal',
              },
            ],
          },
          {
            postgres_id: 15299,
            id: '25.3',
            sort: 300,
            label:
              'Manufacture of steam generators, except central heating hot water boilers',
            children: [
              {
                postgres_id: 15300,
                id: '25.30',
                sort: 301,
                label:
                  'Manufacture of steam generators, except central heating hot water boilers',
              },
            ],
          },
          {
            postgres_id: 15301,
            id: '25.4',
            sort: 302,
            label: 'Manufacture of weapons and ammunition',
            children: [
              {
                postgres_id: 15302,
                id: '25.40',
                sort: 303,
                label: 'Manufacture of weapons and ammunition',
              },
            ],
          },
          {
            postgres_id: 15303,
            id: '25.5',
            sort: 304,
            label:
              'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
            children: [
              {
                postgres_id: 15304,
                id: '25.50',
                sort: 305,
                label:
                  'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
              },
            ],
          },
          {
            postgres_id: 15305,
            id: '25.6',
            sort: 306,
            label: 'Treatment and coating of metals; machining',
            children: [
              {
                postgres_id: 15306,
                id: '25.61',
                sort: 307,
                label: 'Treatment and coating of metals',
              },
              {
                postgres_id: 15307,
                id: '25.62',
                sort: 308,
                label: 'Machining',
              },
            ],
          },
          {
            postgres_id: 15308,
            id: '25.7',
            sort: 309,
            label: 'Manufacture of cutlery, tools and general hardware',
            children: [
              {
                postgres_id: 15309,
                id: '25.71',
                sort: 310,
                label: 'Manufacture of cutlery',
              },
              {
                postgres_id: 15310,
                id: '25.72',
                sort: 311,
                label: 'Manufacture of locks and hinges',
              },
              {
                postgres_id: 15311,
                id: '25.73',
                sort: 312,
                label: 'Manufacture of tools',
              },
            ],
          },
          {
            postgres_id: 15312,
            id: '25.9',
            sort: 313,
            label: 'Manufacture of other fabricated metal products',
            children: [
              {
                postgres_id: 15313,
                id: '25.91',
                sort: 314,
                label: 'Manufacture of steel drums and similar containers',
              },
              {
                postgres_id: 15314,
                id: '25.92',
                sort: 315,
                label: 'Manufacture of light metal packaging ',
              },
              {
                postgres_id: 15315,
                id: '25.93',
                sort: 316,
                label: 'Manufacture of wire products, chain and springs',
              },
              {
                postgres_id: 15316,
                id: '25.94',
                sort: 317,
                label: 'Manufacture of fasteners and screw machine products',
              },
              {
                postgres_id: 15317,
                id: '25.99',
                sort: 318,
                label: 'Manufacture of other fabricated metal products n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15318,
        id: '26',
        sort: 319,
        label: 'Manufacture of computer, electronic and optical products',
        children: [
          {
            postgres_id: 15319,
            id: '26.1',
            sort: 320,
            label: 'Manufacture of electronic components and boards',
            children: [
              {
                postgres_id: 15320,
                id: '26.11',
                sort: 321,
                label: 'Manufacture of electronic components',
              },
              {
                postgres_id: 15321,
                id: '26.12',
                sort: 322,
                label: 'Manufacture of loaded electronic boards',
              },
            ],
          },
          {
            postgres_id: 15322,
            id: '26.2',
            sort: 323,
            label: 'Manufacture of computers and peripheral equipment',
            children: [
              {
                postgres_id: 15323,
                id: '26.20',
                sort: 324,
                label: 'Manufacture of computers and peripheral equipment',
              },
            ],
          },
          {
            postgres_id: 15324,
            id: '26.3',
            sort: 325,
            label: 'Manufacture of communication equipment',
            children: [
              {
                postgres_id: 15325,
                id: '26.30',
                sort: 326,
                label: 'Manufacture of communication equipment',
              },
            ],
          },
          {
            postgres_id: 15326,
            id: '26.4',
            sort: 327,
            label: 'Manufacture of consumer electronics',
            children: [
              {
                postgres_id: 15327,
                id: '26.40',
                sort: 328,
                label: 'Manufacture of consumer electronics',
              },
            ],
          },
          {
            postgres_id: 15328,
            id: '26.5',
            sort: 329,
            label:
              'Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
            children: [
              {
                postgres_id: 15329,
                id: '26.51',
                sort: 330,
                label:
                  'Manufacture of instruments and appliances for measuring, testing and navigation',
              },
              {
                postgres_id: 15330,
                id: '26.52',
                sort: 331,
                label: 'Manufacture of watches and clocks',
              },
            ],
          },
          {
            postgres_id: 15331,
            id: '26.6',
            sort: 332,
            label:
              'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
            children: [
              {
                postgres_id: 15332,
                id: '26.60',
                sort: 333,
                label:
                  'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
              },
            ],
          },
          {
            postgres_id: 15333,
            id: '26.7',
            sort: 334,
            label:
              'Manufacture of optical instruments and photographic equipment',
            children: [
              {
                postgres_id: 15334,
                id: '26.70',
                sort: 335,
                label:
                  'Manufacture of optical instruments and photographic equipment',
              },
            ],
          },
          {
            postgres_id: 15335,
            id: '26.8',
            sort: 336,
            label: 'Manufacture of magnetic and optical media',
            children: [
              {
                postgres_id: 15336,
                id: '26.80',
                sort: 337,
                label: 'Manufacture of magnetic and optical media',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15337,
        id: '27',
        sort: 338,
        label: 'Manufacture of electrical equipment',
        children: [
          {
            postgres_id: 15338,
            id: '27.1',
            sort: 339,
            label:
              'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
            children: [
              {
                postgres_id: 15339,
                id: '27.11',
                sort: 340,
                label:
                  'Manufacture of electric motors, generators and transformers',
              },
              {
                postgres_id: 15340,
                id: '27.12',
                sort: 341,
                label:
                  'Manufacture of electricity distribution and control apparatus',
              },
            ],
          },
          {
            postgres_id: 15341,
            id: '27.2',
            sort: 342,
            label: 'Manufacture of batteries and accumulators',
            children: [
              {
                postgres_id: 15342,
                id: '27.20',
                sort: 343,
                label: 'Manufacture of batteries and accumulators',
              },
            ],
          },
          {
            postgres_id: 15343,
            id: '27.3',
            sort: 344,
            label: 'Manufacture of wiring and wiring devices',
            children: [
              {
                postgres_id: 15344,
                id: '27.31',
                sort: 345,
                label: 'Manufacture of fibre optic cables',
              },
              {
                postgres_id: 15345,
                id: '27.32',
                sort: 346,
                label:
                  'Manufacture of other electronic and electric wires and cables',
              },
              {
                postgres_id: 15346,
                id: '27.33',
                sort: 347,
                label: 'Manufacture of wiring devices',
              },
            ],
          },
          {
            postgres_id: 15347,
            id: '27.4',
            sort: 348,
            label: 'Manufacture of electric lighting equipment',
            children: [
              {
                postgres_id: 15348,
                id: '27.40',
                sort: 349,
                label: 'Manufacture of electric lighting equipment',
              },
            ],
          },
          {
            postgres_id: 15349,
            id: '27.5',
            sort: 350,
            label: 'Manufacture of domestic appliances',
            children: [
              {
                postgres_id: 15350,
                id: '27.51',
                sort: 351,
                label: 'Manufacture of electric domestic appliances',
              },
              {
                postgres_id: 15351,
                id: '27.52',
                sort: 352,
                label: 'Manufacture of non-electric domestic appliances',
              },
            ],
          },
          {
            postgres_id: 15352,
            id: '27.9',
            sort: 353,
            label: 'Manufacture of other electrical equipment',
            children: [
              {
                postgres_id: 15353,
                id: '27.90',
                sort: 354,
                label: 'Manufacture of other electrical equipment',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15354,
        id: '28',
        sort: 355,
        label: 'Manufacture of machinery and equipment n.e.c.',
        children: [
          {
            postgres_id: 15355,
            id: '28.1',
            sort: 356,
            label: 'Manufacture of general-purpose machinery',
            children: [
              {
                postgres_id: 15356,
                id: '28.11',
                sort: 357,
                label:
                  'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
              },
              {
                postgres_id: 15357,
                id: '28.12',
                sort: 358,
                label: 'Manufacture of fluid power equipment',
              },
              {
                postgres_id: 15358,
                id: '28.13',
                sort: 359,
                label: 'Manufacture of other pumps and compressors',
              },
              {
                postgres_id: 15359,
                id: '28.14',
                sort: 360,
                label: 'Manufacture of other taps and valves',
              },
              {
                postgres_id: 15360,
                id: '28.15',
                sort: 361,
                label:
                  'Manufacture of bearings, gears, gearing and driving elements',
              },
            ],
          },
          {
            postgres_id: 15361,
            id: '28.2',
            sort: 362,
            label: 'Manufacture of other general-purpose machinery',
            children: [
              {
                postgres_id: 15362,
                id: '28.21',
                sort: 363,
                label: 'Manufacture of ovens, furnaces and furnace burners',
              },
              {
                postgres_id: 15363,
                id: '28.22',
                sort: 364,
                label: 'Manufacture of lifting and handling equipment',
              },
              {
                postgres_id: 15364,
                id: '28.23',
                sort: 365,
                label:
                  'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
              },
              {
                postgres_id: 15365,
                id: '28.24',
                sort: 366,
                label: 'Manufacture of power-driven hand tools',
              },
              {
                postgres_id: 15366,
                id: '28.25',
                sort: 367,
                label:
                  'Manufacture of non-domestic cooling and ventilation equipment',
              },
              {
                postgres_id: 15367,
                id: '28.29',
                sort: 368,
                label: 'Manufacture of other general-purpose machinery n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15368,
            id: '28.3',
            sort: 369,
            label: 'Manufacture of agricultural and forestry machinery',
            children: [
              {
                postgres_id: 15369,
                id: '28.30',
                sort: 370,
                label: 'Manufacture of agricultural and forestry machinery',
              },
            ],
          },
          {
            postgres_id: 15370,
            id: '28.4',
            sort: 371,
            label: 'Manufacture of metal forming machinery and machine tools',
            children: [
              {
                postgres_id: 15371,
                id: '28.41',
                sort: 372,
                label: 'Manufacture of metal forming machinery',
              },
              {
                postgres_id: 15372,
                id: '28.49',
                sort: 373,
                label: 'Manufacture of other machine tools',
              },
            ],
          },
          {
            postgres_id: 15373,
            id: '28.9',
            sort: 374,
            label: 'Manufacture of other special-purpose machinery',
            children: [
              {
                postgres_id: 15374,
                id: '28.91',
                sort: 375,
                label: 'Manufacture of machinery for metallurgy',
              },
              {
                postgres_id: 15375,
                id: '28.92',
                sort: 376,
                label:
                  'Manufacture of machinery for mining, quarrying and construction',
              },
              {
                postgres_id: 15376,
                id: '28.93',
                sort: 377,
                label:
                  'Manufacture of machinery for food, beverage and tobacco processing',
              },
              {
                postgres_id: 15377,
                id: '28.94',
                sort: 378,
                label:
                  'Manufacture of machinery for textile, apparel and leather production',
              },
              {
                postgres_id: 15378,
                id: '28.95',
                sort: 379,
                label:
                  'Manufacture of machinery for paper and paperboard production',
              },
              {
                postgres_id: 15379,
                id: '28.96',
                sort: 380,
                label: 'Manufacture of plastics and rubber machinery',
              },
              {
                postgres_id: 15380,
                id: '28.99',
                sort: 381,
                label: 'Manufacture of other special-purpose machinery n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15381,
        id: '29',
        sort: 382,
        label: 'Manufacture of motor vehicles, trailers and semi-trailers',
        children: [
          {
            postgres_id: 15382,
            id: '29.1',
            sort: 383,
            label: 'Manufacture of motor vehicles',
            children: [
              {
                postgres_id: 15383,
                id: '29.10',
                sort: 384,
                label: 'Manufacture of motor vehicles',
              },
            ],
          },
          {
            postgres_id: 15384,
            id: '29.2',
            sort: 385,
            label:
              'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
            children: [
              {
                postgres_id: 15385,
                id: '29.20',
                sort: 386,
                label:
                  'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
              },
            ],
          },
          {
            postgres_id: 15386,
            id: '29.3',
            sort: 387,
            label: 'Manufacture of parts and accessories for motor vehicles',
            children: [
              {
                postgres_id: 15387,
                id: '29.31',
                sort: 388,
                label:
                  'Manufacture of electrical and electronic equipment for motor vehicles',
              },
              {
                postgres_id: 15388,
                id: '29.32',
                sort: 389,
                label:
                  'Manufacture of other parts and accessories for motor vehicles',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15389,
        id: '30',
        sort: 390,
        label: 'Manufacture of other transport equipment',
        children: [
          {
            postgres_id: 15390,
            id: '30.1',
            sort: 391,
            label: 'Building of ships and boats',
            children: [
              {
                postgres_id: 15391,
                id: '30.11',
                sort: 392,
                label: 'Building of ships and floating structures',
              },
              {
                postgres_id: 15392,
                id: '30.12',
                sort: 393,
                label: 'Building of pleasure and sporting boats',
              },
            ],
          },
          {
            postgres_id: 15393,
            id: '30.2',
            sort: 394,
            label: 'Manufacture of railway locomotives and rolling stock',
            children: [
              {
                postgres_id: 15394,
                id: '30.20',
                sort: 395,
                label: 'Manufacture of railway locomotives and rolling stock',
              },
            ],
          },
          {
            postgres_id: 15395,
            id: '30.3',
            sort: 396,
            label: 'Manufacture of air and spacecraft and related machinery',
            children: [
              {
                postgres_id: 15396,
                id: '30.30',
                sort: 397,
                label:
                  'Manufacture of air and spacecraft and related machinery',
              },
            ],
          },
          {
            postgres_id: 15397,
            id: '30.4',
            sort: 398,
            label: 'Manufacture of military fighting vehicles',
            children: [
              {
                postgres_id: 15398,
                id: '30.40',
                sort: 399,
                label: 'Manufacture of military fighting vehicles',
              },
            ],
          },
          {
            postgres_id: 15399,
            id: '30.9',
            sort: 400,
            label: 'Manufacture of transport equipment n.e.c.',
            children: [
              {
                postgres_id: 15400,
                id: '30.91',
                sort: 401,
                label: 'Manufacture of motorcycles',
              },
              {
                postgres_id: 15401,
                id: '30.92',
                sort: 402,
                label: 'Manufacture of bicycles and invalid carriages',
              },
              {
                postgres_id: 15402,
                id: '30.99',
                sort: 403,
                label: 'Manufacture of other transport equipment n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15403,
        id: '31',
        sort: 404,
        label: 'Manufacture of furniture',
        children: [
          {
            postgres_id: 15404,
            id: '31.0',
            sort: 405,
            label: 'Manufacture of furniture',
            children: [
              {
                postgres_id: 15405,
                id: '31.01',
                sort: 406,
                label: 'Manufacture of office and shop furniture',
              },
              {
                postgres_id: 15406,
                id: '31.02',
                sort: 407,
                label: 'Manufacture of kitchen furniture',
              },
              {
                postgres_id: 15407,
                id: '31.03',
                sort: 408,
                label: 'Manufacture of mattresses',
              },
              {
                postgres_id: 15408,
                id: '31.09',
                sort: 409,
                label: 'Manufacture of other furniture',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15409,
        id: '32',
        sort: 410,
        label: 'Other manufacturing',
        children: [
          {
            postgres_id: 15410,
            id: '32.1',
            sort: 411,
            label: 'Manufacture of jewellery, bijouterie and related articles',
            children: [
              {
                postgres_id: 15411,
                id: '32.11',
                sort: 412,
                label: 'Striking of coins',
              },
              {
                postgres_id: 15412,
                id: '32.12',
                sort: 413,
                label: 'Manufacture of jewellery and related articles',
              },
              {
                postgres_id: 15413,
                id: '32.13',
                sort: 414,
                label:
                  'Manufacture of imitation jewellery and related articles',
              },
            ],
          },
          {
            postgres_id: 15414,
            id: '32.2',
            sort: 415,
            label: 'Manufacture of musical instruments',
            children: [
              {
                postgres_id: 15415,
                id: '32.20',
                sort: 416,
                label: 'Manufacture of musical instruments',
              },
            ],
          },
          {
            postgres_id: 15416,
            id: '32.3',
            sort: 417,
            label: 'Manufacture of sports goods',
            children: [
              {
                postgres_id: 15417,
                id: '32.30',
                sort: 418,
                label: 'Manufacture of sports goods',
              },
            ],
          },
          {
            postgres_id: 15418,
            id: '32.4',
            sort: 419,
            label: 'Manufacture of games and toys',
            children: [
              {
                postgres_id: 15419,
                id: '32.40',
                sort: 420,
                label: 'Manufacture of games and toys',
              },
            ],
          },
          {
            postgres_id: 15420,
            id: '32.5',
            sort: 421,
            label: 'Manufacture of medical and dental instruments and supplies',
            children: [
              {
                postgres_id: 15421,
                id: '32.50',
                sort: 422,
                label:
                  'Manufacture of medical and dental instruments and supplies',
              },
            ],
          },
          {
            postgres_id: 15422,
            id: '32.9',
            sort: 423,
            label: 'Manufacturing n.e.c.',
            children: [
              {
                postgres_id: 15423,
                id: '32.91',
                sort: 424,
                label: 'Manufacture of brooms and brushes',
              },
              {
                postgres_id: 15424,
                id: '32.99',
                sort: 425,
                label: 'Other manufacturing n.e.c. ',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15425,
        id: '33',
        sort: 426,
        label: 'Repair and installation of machinery and equipment',
        children: [
          {
            postgres_id: 15426,
            id: '33.1',
            sort: 427,
            label:
              'Repair of fabricated metal products, machinery and equipment',
            children: [
              {
                postgres_id: 15427,
                id: '33.11',
                sort: 428,
                label: 'Repair of fabricated metal products',
              },
              {
                postgres_id: 15428,
                id: '33.12',
                sort: 429,
                label: 'Repair of machinery',
              },
              {
                postgres_id: 15429,
                id: '33.13',
                sort: 430,
                label: 'Repair of electronic and optical equipment',
              },
              {
                postgres_id: 15430,
                id: '33.14',
                sort: 431,
                label: 'Repair of electrical equipment',
              },
              {
                postgres_id: 15431,
                id: '33.15',
                sort: 432,
                label: 'Repair and maintenance of ships and boats',
              },
              {
                postgres_id: 15432,
                id: '33.16',
                sort: 433,
                label: 'Repair and maintenance of aircraft and spacecraft',
              },
              {
                postgres_id: 15433,
                id: '33.17',
                sort: 434,
                label: 'Repair and maintenance of other transport equipment',
              },
              {
                postgres_id: 15434,
                id: '33.19',
                sort: 435,
                label: 'Repair of other equipment',
              },
            ],
          },
          {
            postgres_id: 15435,
            id: '33.2',
            sort: 436,
            label: 'Installation of industrial machinery and equipment',
            children: [
              {
                postgres_id: 15436,
                id: '33.20',
                sort: 437,
                label: 'Installation of industrial machinery and equipment',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15437,
    id: 'D',
    sort: 438,
    label: 'ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY',
    children: [
      {
        postgres_id: 15438,
        id: '35',
        sort: 439,
        label: 'Electricity, gas, steam and air conditioning supply',
        children: [
          {
            postgres_id: 15439,
            id: '35.1',
            sort: 440,
            label: 'Electric power generation, transmission and distribution',
            children: [
              {
                postgres_id: 15440,
                id: '35.11',
                sort: 441,
                label: 'Production of electricity',
              },
              {
                postgres_id: 15441,
                id: '35.12',
                sort: 442,
                label: 'Transmission of electricity',
              },
              {
                postgres_id: 15442,
                id: '35.13',
                sort: 443,
                label: 'Distribution of electricity',
              },
              {
                postgres_id: 15443,
                id: '35.14',
                sort: 444,
                label: 'Trade of electricity',
              },
            ],
          },
          {
            postgres_id: 15444,
            id: '35.2',
            sort: 445,
            label:
              'Manufacture of gas; distribution of gaseous fuels through mains',
            children: [
              {
                postgres_id: 15445,
                id: '35.21',
                sort: 446,
                label: 'Manufacture of gas',
              },
              {
                postgres_id: 15446,
                id: '35.22',
                sort: 447,
                label: 'Distribution of gaseous fuels through mains',
              },
              {
                postgres_id: 15447,
                id: '35.23',
                sort: 448,
                label: 'Trade of gas through mains',
              },
            ],
          },
          {
            postgres_id: 15448,
            id: '35.3',
            sort: 449,
            label: 'Steam and air conditioning supply',
            children: [
              {
                postgres_id: 15449,
                id: '35.30',
                sort: 450,
                label: 'Steam and air conditioning supply',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15450,
    id: 'E',
    sort: 451,
    label:
      'WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
    children: [
      {
        postgres_id: 15451,
        id: '36',
        sort: 452,
        label: 'Water collection, treatment and supply',
        children: [
          {
            postgres_id: 15452,
            id: '36.0',
            sort: 453,
            label: 'Water collection, treatment and supply',
            children: [
              {
                postgres_id: 15453,
                id: '36.00',
                sort: 454,
                label: 'Water collection, treatment and supply',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15454,
        id: '37',
        sort: 455,
        label: 'Sewerage',
        children: [
          {
            postgres_id: 15455,
            id: '37.0',
            sort: 456,
            label: 'Sewerage',
            children: [
              {
                postgres_id: 15456,
                id: '37.00',
                sort: 457,
                label: 'Sewerage',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15457,
        id: '38',
        sort: 458,
        label:
          'Waste collection, treatment and disposal activities; materials recovery',
        children: [
          {
            postgres_id: 15458,
            id: '38.1',
            sort: 459,
            label: 'Waste collection',
            children: [
              {
                postgres_id: 15459,
                id: '38.11',
                sort: 460,
                label: 'Collection of non-hazardous waste',
              },
              {
                postgres_id: 15460,
                id: '38.12',
                sort: 461,
                label: 'Collection of hazardous waste',
              },
            ],
          },
          {
            postgres_id: 15461,
            id: '38.2',
            sort: 462,
            label: 'Waste treatment and disposal',
            children: [
              {
                postgres_id: 15462,
                id: '38.21',
                sort: 463,
                label: 'Treatment and disposal of non-hazardous waste',
              },
              {
                postgres_id: 15463,
                id: '38.22',
                sort: 464,
                label: 'Treatment and disposal of hazardous waste',
              },
            ],
          },
          {
            postgres_id: 15464,
            id: '38.3',
            sort: 465,
            label: 'Materials recovery',
            children: [
              {
                postgres_id: 15465,
                id: '38.31',
                sort: 466,
                label: 'Dismantling of wrecks',
              },
              {
                postgres_id: 15466,
                id: '38.32',
                sort: 467,
                label: 'Recovery of sorted materials',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15467,
        id: '39',
        sort: 468,
        label: 'Remediation activities and other waste management services',
        children: [
          {
            postgres_id: 15468,
            id: '39.0',
            sort: 469,
            label: 'Remediation activities and other waste management services',
            children: [
              {
                postgres_id: 15469,
                id: '39.00',
                sort: 470,
                label:
                  'Remediation activities and other waste management services',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15470,
    id: 'F',
    sort: 471,
    label: 'CONSTRUCTION',
    children: [
      {
        postgres_id: 15471,
        id: '41',
        sort: 472,
        label: 'Construction of buildings',
        children: [
          {
            postgres_id: 15472,
            id: '41.1',
            sort: 473,
            label: 'Development of building projects',
            children: [
              {
                postgres_id: 15473,
                id: '41.10',
                sort: 474,
                label: 'Development of building projects',
              },
            ],
          },
          {
            postgres_id: 15474,
            id: '41.2',
            sort: 475,
            label: 'Construction of residential and non-residential buildings',
            children: [
              {
                postgres_id: 15475,
                id: '41.20',
                sort: 476,
                label:
                  'Construction of residential and non-residential buildings',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15476,
        id: '42',
        sort: 477,
        label: 'Civil engineering',
        children: [
          {
            postgres_id: 15477,
            id: '42.1',
            sort: 478,
            label: 'Construction of roads and railways',
            children: [
              {
                postgres_id: 15478,
                id: '42.11',
                sort: 479,
                label: 'Construction of roads and motorways',
              },
              {
                postgres_id: 15479,
                id: '42.12',
                sort: 480,
                label: 'Construction of railways and underground railways',
              },
              {
                postgres_id: 15480,
                id: '42.13',
                sort: 481,
                label: 'Construction of bridges and tunnels',
              },
            ],
          },
          {
            postgres_id: 15481,
            id: '42.2',
            sort: 482,
            label: 'Construction of utility projects',
            children: [
              {
                postgres_id: 15482,
                id: '42.21',
                sort: 483,
                label: 'Construction of utility projects for fluids',
              },
              {
                postgres_id: 15483,
                id: '42.22',
                sort: 484,
                label:
                  'Construction of utility projects for electricity and telecommunications',
              },
            ],
          },
          {
            postgres_id: 15484,
            id: '42.9',
            sort: 485,
            label: 'Construction of other civil engineering projects',
            children: [
              {
                postgres_id: 15485,
                id: '42.91',
                sort: 486,
                label: 'Construction of water projects',
              },
              {
                postgres_id: 15486,
                id: '42.99',
                sort: 487,
                label:
                  'Construction of other civil engineering projects n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15487,
        id: '43',
        sort: 488,
        label: 'Specialised construction activities',
        children: [
          {
            postgres_id: 15488,
            id: '43.1',
            sort: 489,
            label: 'Demolition and site preparation',
            children: [
              {
                postgres_id: 15489,
                id: '43.11',
                sort: 490,
                label: 'Demolition',
              },
              {
                postgres_id: 15490,
                id: '43.12',
                sort: 491,
                label: 'Site preparation',
              },
              {
                postgres_id: 15491,
                id: '43.13',
                sort: 492,
                label: 'Test drilling and boring',
              },
            ],
          },
          {
            postgres_id: 15492,
            id: '43.2',
            sort: 493,
            label:
              'Electrical, plumbing and other construction installation activities',
            children: [
              {
                postgres_id: 15493,
                id: '43.21',
                sort: 494,
                label: 'Electrical installation',
              },
              {
                postgres_id: 15494,
                id: '43.22',
                sort: 495,
                label: 'Plumbing, heat and air-conditioning installation',
              },
              {
                postgres_id: 15495,
                id: '43.29',
                sort: 496,
                label: 'Other construction installation',
              },
            ],
          },
          {
            postgres_id: 15496,
            id: '43.3',
            sort: 497,
            label: 'Building completion and finishing',
            children: [
              {
                postgres_id: 15497,
                id: '43.31',
                sort: 498,
                label: 'Plastering',
              },
              {
                postgres_id: 15498,
                id: '43.32',
                sort: 499,
                label: 'Joinery installation',
              },
              {
                postgres_id: 15499,
                id: '43.33',
                sort: 500,
                label: 'Floor and wall covering',
              },
              {
                postgres_id: 15500,
                id: '43.34',
                sort: 501,
                label: 'Painting and glazing',
              },
              {
                postgres_id: 15501,
                id: '43.39',
                sort: 502,
                label: 'Other building completion and finishing',
              },
            ],
          },
          {
            postgres_id: 15502,
            id: '43.9',
            sort: 503,
            label: 'Other specialised construction activities',
            children: [
              {
                postgres_id: 15503,
                id: '43.91',
                sort: 504,
                label: 'Roofing activities',
              },
              {
                postgres_id: 15504,
                id: '43.99',
                sort: 505,
                label: 'Other specialised construction activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15505,
    id: 'G',
    sort: 506,
    label:
      'WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES',
    children: [
      {
        postgres_id: 15506,
        id: '45',
        sort: 507,
        label:
          'Wholesale and retail trade and repair of motor vehicles and motorcycles',
        children: [
          {
            postgres_id: 15507,
            id: '45.1',
            sort: 508,
            label: 'Sale of motor vehicles',
            children: [
              {
                postgres_id: 15508,
                id: '45.11',
                sort: 509,
                label: 'Sale of cars and light motor vehicles',
              },
              {
                postgres_id: 15509,
                id: '45.19',
                sort: 510,
                label: 'Sale of other motor vehicles',
              },
            ],
          },
          {
            postgres_id: 15510,
            id: '45.2',
            sort: 511,
            label: 'Maintenance and repair of motor vehicles',
            children: [
              {
                postgres_id: 15511,
                id: '45.20',
                sort: 512,
                label: 'Maintenance and repair of motor vehicles',
              },
            ],
          },
          {
            postgres_id: 15512,
            id: '45.3',
            sort: 513,
            label: 'Sale of motor vehicle parts and accessories',
            children: [
              {
                postgres_id: 15513,
                id: '45.31',
                sort: 514,
                label: 'Wholesale trade of motor vehicle parts and accessories',
              },
              {
                postgres_id: 15514,
                id: '45.32',
                sort: 515,
                label: 'Retail trade of motor vehicle parts and accessories',
              },
            ],
          },
          {
            postgres_id: 15515,
            id: '45.4',
            sort: 516,
            label:
              'Sale, maintenance and repair of motorcycles and related parts and accessories',
            children: [
              {
                postgres_id: 15516,
                id: '45.40',
                sort: 517,
                label:
                  'Sale, maintenance and repair of motorcycles and related parts and accessories',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15517,
        id: '46',
        sort: 518,
        label: 'Wholesale trade, except of motor vehicles and motorcycles',
        children: [
          {
            postgres_id: 15518,
            id: '46.1',
            sort: 519,
            label: 'Wholesale on a fee or contract basis',
            children: [
              {
                postgres_id: 15519,
                id: '46.11',
                sort: 520,
                label:
                  'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
              },
              {
                postgres_id: 15520,
                id: '46.12',
                sort: 521,
                label:
                  'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
              },
              {
                postgres_id: 15521,
                id: '46.13',
                sort: 522,
                label:
                  'Agents involved in the sale of timber and building materials',
              },
              {
                postgres_id: 15522,
                id: '46.14',
                sort: 523,
                label:
                  'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
              },
              {
                postgres_id: 15523,
                id: '46.15',
                sort: 524,
                label:
                  'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
              },
              {
                postgres_id: 15524,
                id: '46.16',
                sort: 525,
                label:
                  'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
              },
              {
                postgres_id: 15525,
                id: '46.17',
                sort: 526,
                label:
                  'Agents involved in the sale of food, beverages and tobacco',
              },
              {
                postgres_id: 15526,
                id: '46.18',
                sort: 527,
                label:
                  'Agents specialised in the sale of other particular products',
              },
              {
                postgres_id: 15527,
                id: '46.19',
                sort: 528,
                label: 'Agents involved in the sale of a variety of goods',
              },
            ],
          },
          {
            postgres_id: 15528,
            id: '46.2',
            sort: 529,
            label: 'Wholesale of agricultural raw materials and live animals',
            children: [
              {
                postgres_id: 15529,
                id: '46.21',
                sort: 530,
                label:
                  'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
              },
              {
                postgres_id: 15530,
                id: '46.22',
                sort: 531,
                label: 'Wholesale of flowers and plants',
              },
              {
                postgres_id: 15531,
                id: '46.23',
                sort: 532,
                label: 'Wholesale of live animals',
              },
              {
                postgres_id: 15532,
                id: '46.24',
                sort: 533,
                label: 'Wholesale of hides, skins and leather',
              },
            ],
          },
          {
            postgres_id: 15533,
            id: '46.3',
            sort: 534,
            label: 'Wholesale of food, beverages and tobacco',
            children: [
              {
                postgres_id: 15534,
                id: '46.31',
                sort: 535,
                label: 'Wholesale of fruit and vegetables',
              },
              {
                postgres_id: 15535,
                id: '46.32',
                sort: 536,
                label: 'Wholesale of meat and meat products',
              },
              {
                postgres_id: 15536,
                id: '46.33',
                sort: 537,
                label:
                  'Wholesale of dairy products, eggs and edible oils and fats',
              },
              {
                postgres_id: 15537,
                id: '46.34',
                sort: 538,
                label: 'Wholesale of beverages',
              },
              {
                postgres_id: 15538,
                id: '46.35',
                sort: 539,
                label: 'Wholesale of tobacco products',
              },
              {
                postgres_id: 15539,
                id: '46.36',
                sort: 540,
                label:
                  'Wholesale of sugar and chocolate and sugar confectionery',
              },
              {
                postgres_id: 15540,
                id: '46.37',
                sort: 541,
                label: 'Wholesale of coffee, tea, cocoa and spices',
              },
              {
                postgres_id: 15541,
                id: '46.38',
                sort: 542,
                label:
                  'Wholesale of other food, including fish, crustaceans and molluscs',
              },
              {
                postgres_id: 15542,
                id: '46.39',
                sort: 543,
                label:
                  'Non-specialised wholesale of food, beverages and tobacco',
              },
            ],
          },
          {
            postgres_id: 15543,
            id: '46.4',
            sort: 544,
            label: 'Wholesale of household goods',
            children: [
              {
                postgres_id: 15544,
                id: '46.41',
                sort: 545,
                label: 'Wholesale of textiles',
              },
              {
                postgres_id: 15545,
                id: '46.42',
                sort: 546,
                label: 'Wholesale of clothing and footwear',
              },
              {
                postgres_id: 15546,
                id: '46.43',
                sort: 547,
                label: 'Wholesale of electrical household appliances',
              },
              {
                postgres_id: 15547,
                id: '46.44',
                sort: 548,
                label:
                  'Wholesale of china and glassware and cleaning materials',
              },
              {
                postgres_id: 15548,
                id: '46.45',
                sort: 549,
                label: 'Wholesale of perfume and cosmetics',
              },
              {
                postgres_id: 15549,
                id: '46.46',
                sort: 550,
                label: 'Wholesale of pharmaceutical goods',
              },
              {
                postgres_id: 15550,
                id: '46.47',
                sort: 551,
                label: 'Wholesale of furniture, carpets and lighting equipment',
              },
              {
                postgres_id: 15551,
                id: '46.48',
                sort: 552,
                label: 'Wholesale of watches and jewellery',
              },
              {
                postgres_id: 15552,
                id: '46.49',
                sort: 553,
                label: 'Wholesale of other household goods',
              },
            ],
          },
          {
            postgres_id: 15553,
            id: '46.5',
            sort: 554,
            label: 'Wholesale of information and communication equipment',
            children: [
              {
                postgres_id: 15554,
                id: '46.51',
                sort: 555,
                label:
                  'Wholesale of computers, computer peripheral equipment and software',
              },
              {
                postgres_id: 15555,
                id: '46.52',
                sort: 556,
                label:
                  'Wholesale of electronic and telecommunications equipment and parts',
              },
            ],
          },
          {
            postgres_id: 15556,
            id: '46.6',
            sort: 557,
            label: 'Wholesale of other machinery, equipment and supplies',
            children: [
              {
                postgres_id: 15557,
                id: '46.61',
                sort: 558,
                label:
                  'Wholesale of agricultural machinery, equipment and supplies',
              },
              {
                postgres_id: 15558,
                id: '46.62',
                sort: 559,
                label: 'Wholesale of machine tools',
              },
              {
                postgres_id: 15559,
                id: '46.63',
                sort: 560,
                label:
                  'Wholesale of mining, construction and civil engineering machinery',
              },
              {
                postgres_id: 15560,
                id: '46.64',
                sort: 561,
                label:
                  'Wholesale of machinery for the textile industry and of sewing and knitting machines',
              },
              {
                postgres_id: 15561,
                id: '46.65',
                sort: 562,
                label: 'Wholesale of office furniture',
              },
              {
                postgres_id: 15562,
                id: '46.66',
                sort: 563,
                label: 'Wholesale of other office machinery and equipment',
              },
              {
                postgres_id: 15563,
                id: '46.69',
                sort: 564,
                label: 'Wholesale of other machinery and equipment',
              },
            ],
          },
          {
            postgres_id: 15564,
            id: '46.7',
            sort: 565,
            label: 'Other specialised wholesale',
            children: [
              {
                postgres_id: 15565,
                id: '46.71',
                sort: 566,
                label:
                  'Wholesale of solid, liquid and gaseous fuels and related products',
              },
              {
                postgres_id: 15566,
                id: '46.72',
                sort: 567,
                label: 'Wholesale of metals and metal ores',
              },
              {
                postgres_id: 15567,
                id: '46.73',
                sort: 568,
                label:
                  'Wholesale of wood, construction materials and sanitary equipment',
              },
              {
                postgres_id: 15568,
                id: '46.74',
                sort: 569,
                label:
                  'Wholesale of hardware, plumbing and heating equipment and supplies',
              },
              {
                postgres_id: 15569,
                id: '46.75',
                sort: 570,
                label: 'Wholesale of chemical products',
              },
              {
                postgres_id: 15570,
                id: '46.76',
                sort: 571,
                label: 'Wholesale of other intermediate products',
              },
              {
                postgres_id: 15571,
                id: '46.77',
                sort: 572,
                label: 'Wholesale of waste and scrap',
              },
            ],
          },
          {
            postgres_id: 15572,
            id: '46.9',
            sort: 573,
            label: 'Non-specialised wholesale trade',
            children: [
              {
                postgres_id: 15573,
                id: '46.90',
                sort: 574,
                label: 'Non-specialised wholesale trade',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15574,
        id: '47',
        sort: 575,
        label: 'Retail trade, except of motor vehicles and motorcycles',
        children: [
          {
            postgres_id: 15575,
            id: '47.1',
            sort: 576,
            label: 'Retail sale in non-specialised stores',
            children: [
              {
                postgres_id: 15576,
                id: '47.11',
                sort: 577,
                label:
                  'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
              },
              {
                postgres_id: 15577,
                id: '47.19',
                sort: 578,
                label: 'Other retail sale in non-specialised stores',
              },
            ],
          },
          {
            postgres_id: 15578,
            id: '47.2',
            sort: 579,
            label:
              'Retail sale of food, beverages and tobacco in specialised stores',
            children: [
              {
                postgres_id: 15579,
                id: '47.21',
                sort: 580,
                label:
                  'Retail sale of fruit and vegetables in specialised stores',
              },
              {
                postgres_id: 15580,
                id: '47.22',
                sort: 581,
                label:
                  'Retail sale of meat and meat products in specialised stores',
              },
              {
                postgres_id: 15581,
                id: '47.23',
                sort: 582,
                label:
                  'Retail sale of fish, crustaceans and molluscs in specialised stores',
              },
              {
                postgres_id: 15582,
                id: '47.24',
                sort: 583,
                label:
                  'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
              },
              {
                postgres_id: 15583,
                id: '47.25',
                sort: 584,
                label: 'Retail sale of beverages in specialised stores',
              },
              {
                postgres_id: 15584,
                id: '47.26',
                sort: 585,
                label: 'Retail sale of tobacco products in specialised stores',
              },
              {
                postgres_id: 15585,
                id: '47.29',
                sort: 586,
                label: 'Other retail sale of food in specialised stores',
              },
            ],
          },
          {
            postgres_id: 15586,
            id: '47.3',
            sort: 587,
            label: 'Retail sale of automotive fuel in specialised stores',
            children: [
              {
                postgres_id: 15587,
                id: '47.30',
                sort: 588,
                label: 'Retail sale of automotive fuel in specialised stores',
              },
            ],
          },
          {
            postgres_id: 15588,
            id: '47.4',
            sort: 589,
            label:
              'Retail sale of information and communication equipment in specialised stores',
            children: [
              {
                postgres_id: 15589,
                id: '47.41',
                sort: 590,
                label:
                  'Retail sale of computers, peripheral units and software in specialised stores',
              },
              {
                postgres_id: 15590,
                id: '47.42',
                sort: 591,
                label:
                  'Retail sale of telecommunications equipment in specialised stores',
              },
              {
                postgres_id: 15591,
                id: '47.43',
                sort: 592,
                label:
                  'Retail sale of audio and video equipment in specialised stores',
              },
            ],
          },
          {
            postgres_id: 15592,
            id: '47.5',
            sort: 593,
            label:
              'Retail sale of other household equipment in specialised stores',
            children: [
              {
                postgres_id: 15593,
                id: '47.51',
                sort: 594,
                label: 'Retail sale of textiles in specialised stores',
              },
              {
                postgres_id: 15594,
                id: '47.52',
                sort: 595,
                label:
                  'Retail sale of hardware, paints and glass in specialised stores',
              },
              {
                postgres_id: 15595,
                id: '47.53',
                sort: 596,
                label:
                  'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
              },
              {
                postgres_id: 15596,
                id: '47.54',
                sort: 597,
                label:
                  'Retail sale of electrical household appliances in specialised stores',
              },
              {
                postgres_id: 15597,
                id: '47.59',
                sort: 598,
                label:
                  'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
              },
            ],
          },
          {
            postgres_id: 15598,
            id: '47.6',
            sort: 599,
            label:
              'Retail sale of cultural and recreation goods in specialised stores',
            children: [
              {
                postgres_id: 15599,
                id: '47.61',
                sort: 600,
                label: 'Retail sale of books in specialised stores',
              },
              {
                postgres_id: 15600,
                id: '47.62',
                sort: 601,
                label:
                  'Retail sale of newspapers and stationery in specialised stores',
              },
              {
                postgres_id: 15601,
                id: '47.63',
                sort: 602,
                label:
                  'Retail sale of music and video recordings in specialised stores',
              },
              {
                postgres_id: 15602,
                id: '47.64',
                sort: 603,
                label:
                  'Retail sale of sporting equipment in specialised stores',
              },
              {
                postgres_id: 15603,
                id: '47.65',
                sort: 604,
                label: 'Retail sale of games and toys in specialised stores',
              },
            ],
          },
          {
            postgres_id: 15604,
            id: '47.7',
            sort: 605,
            label: 'Retail sale of other goods in specialised stores',
            children: [
              {
                postgres_id: 15605,
                id: '47.71',
                sort: 606,
                label: 'Retail sale of clothing in specialised stores',
              },
              {
                postgres_id: 15606,
                id: '47.72',
                sort: 607,
                label:
                  'Retail sale of footwear and leather goods in specialised stores',
              },
              {
                postgres_id: 15607,
                id: '47.73',
                sort: 608,
                label: 'Dispensing chemist in specialised stores',
              },
              {
                postgres_id: 15608,
                id: '47.74',
                sort: 609,
                label:
                  'Retail sale of medical and orthopaedic goods in specialised stores',
              },
              {
                postgres_id: 15609,
                id: '47.75',
                sort: 610,
                label:
                  'Retail sale of cosmetic and toilet articles in specialised stores',
              },
              {
                postgres_id: 15610,
                id: '47.76',
                sort: 611,
                label:
                  'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
              },
              {
                postgres_id: 15611,
                id: '47.77',
                sort: 612,
                label:
                  'Retail sale of watches and jewellery in specialised stores',
              },
              {
                postgres_id: 15612,
                id: '47.78',
                sort: 613,
                label: 'Other retail sale of new goods in specialised stores',
              },
              {
                postgres_id: 15613,
                id: '47.79',
                sort: 614,
                label: 'Retail sale of second-hand goods in stores',
              },
            ],
          },
          {
            postgres_id: 15614,
            id: '47.8',
            sort: 615,
            label: 'Retail sale via stalls and markets',
            children: [
              {
                postgres_id: 15615,
                id: '47.81',
                sort: 616,
                label:
                  'Retail sale via stalls and markets of food, beverages and tobacco products',
              },
              {
                postgres_id: 15616,
                id: '47.82',
                sort: 617,
                label:
                  'Retail sale via stalls and markets of textiles, clothing and footwear',
              },
              {
                postgres_id: 15617,
                id: '47.89',
                sort: 618,
                label: 'Retail sale via stalls and markets of other goods',
              },
            ],
          },
          {
            postgres_id: 15618,
            id: '47.9',
            sort: 619,
            label: 'Retail trade not in stores, stalls or markets',
            children: [
              {
                postgres_id: 15619,
                id: '47.91',
                sort: 620,
                label: 'Retail sale via mail order houses or via Internet',
              },
              {
                postgres_id: 15620,
                id: '47.99',
                sort: 621,
                label: 'Other retail sale not in stores, stalls or markets',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15621,
    id: 'H',
    sort: 622,
    label: 'TRANSPORTATION AND STORAGE',
    children: [
      {
        postgres_id: 15622,
        id: '49',
        sort: 623,
        label: 'Land transport and transport via pipelines',
        children: [
          {
            postgres_id: 15623,
            id: '49.1',
            sort: 624,
            label: 'Passenger rail transport, interurban',
            children: [
              {
                postgres_id: 15624,
                id: '49.10',
                sort: 625,
                label: 'Passenger rail transport, interurban',
              },
            ],
          },
          {
            postgres_id: 15625,
            id: '49.2',
            sort: 626,
            label: 'Freight rail transport',
            children: [
              {
                postgres_id: 15626,
                id: '49.20',
                sort: 627,
                label: 'Freight rail transport',
              },
            ],
          },
          {
            postgres_id: 15627,
            id: '49.3',
            sort: 628,
            label: 'Other passenger land transport ',
            children: [
              {
                postgres_id: 15628,
                id: '49.31',
                sort: 629,
                label: 'Urban and suburban passenger land transport',
              },
              {
                postgres_id: 15629,
                id: '49.32',
                sort: 630,
                label: 'Taxi operation',
              },
              {
                postgres_id: 15630,
                id: '49.39',
                sort: 631,
                label: 'Other passenger land transport n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15631,
            id: '49.4',
            sort: 632,
            label: 'Freight transport by road and removal services',
            children: [
              {
                postgres_id: 15632,
                id: '49.41',
                sort: 633,
                label: 'Freight transport by road',
              },
              {
                postgres_id: 15633,
                id: '49.42',
                sort: 634,
                label: 'Removal services',
              },
            ],
          },
          {
            postgres_id: 15634,
            id: '49.5',
            sort: 635,
            label: 'Transport via pipeline',
            children: [
              {
                postgres_id: 15635,
                id: '49.50',
                sort: 636,
                label: 'Transport via pipeline',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15636,
        id: '50',
        sort: 637,
        label: 'Water transport',
        children: [
          {
            postgres_id: 15637,
            id: '50.1',
            sort: 638,
            label: 'Sea and coastal passenger water transport',
            children: [
              {
                postgres_id: 15638,
                id: '50.10',
                sort: 639,
                label: 'Sea and coastal passenger water transport',
              },
            ],
          },
          {
            postgres_id: 15639,
            id: '50.2',
            sort: 640,
            label: 'Sea and coastal freight water transport',
            children: [
              {
                postgres_id: 15640,
                id: '50.20',
                sort: 641,
                label: 'Sea and coastal freight water transport',
              },
            ],
          },
          {
            postgres_id: 15641,
            id: '50.3',
            sort: 642,
            label: 'Inland passenger water transport',
            children: [
              {
                postgres_id: 15642,
                id: '50.30',
                sort: 643,
                label: 'Inland passenger water transport',
              },
            ],
          },
          {
            postgres_id: 15643,
            id: '50.4',
            sort: 644,
            label: 'Inland freight water transport',
            children: [
              {
                postgres_id: 15644,
                id: '50.40',
                sort: 645,
                label: 'Inland freight water transport',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15645,
        id: '51',
        sort: 646,
        label: 'Air transport',
        children: [
          {
            postgres_id: 15646,
            id: '51.1',
            sort: 647,
            label: 'Passenger air transport',
            children: [
              {
                postgres_id: 15647,
                id: '51.10',
                sort: 648,
                label: 'Passenger air transport',
              },
            ],
          },
          {
            postgres_id: 15648,
            id: '51.2',
            sort: 649,
            label: 'Freight air transport and space transport',
            children: [
              {
                postgres_id: 15649,
                id: '51.21',
                sort: 650,
                label: 'Freight air transport',
              },
              {
                postgres_id: 15650,
                id: '51.22',
                sort: 651,
                label: 'Space transport',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15651,
        id: '52',
        sort: 652,
        label: 'Warehousing and support activities for transportation',
        children: [
          {
            postgres_id: 15652,
            id: '52.1',
            sort: 653,
            label: 'Warehousing and storage',
            children: [
              {
                postgres_id: 15653,
                id: '52.10',
                sort: 654,
                label: 'Warehousing and storage',
              },
            ],
          },
          {
            postgres_id: 15654,
            id: '52.2',
            sort: 655,
            label: 'Support activities for transportation',
            children: [
              {
                postgres_id: 15655,
                id: '52.21',
                sort: 656,
                label: 'Service activities incidental to land transportation',
              },
              {
                postgres_id: 15656,
                id: '52.22',
                sort: 657,
                label: 'Service activities incidental to water transportation',
              },
              {
                postgres_id: 15657,
                id: '52.23',
                sort: 658,
                label: 'Service activities incidental to air transportation',
              },
              {
                postgres_id: 15658,
                id: '52.24',
                sort: 659,
                label: 'Cargo handling',
              },
              {
                postgres_id: 15659,
                id: '52.29',
                sort: 660,
                label: 'Other transportation support activities ',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15660,
        id: '53',
        sort: 661,
        label: 'Postal and courier activities',
        children: [
          {
            postgres_id: 15661,
            id: '53.1',
            sort: 662,
            label: 'Postal activities under universal service obligation',
            children: [
              {
                postgres_id: 15662,
                id: '53.10',
                sort: 663,
                label: 'Postal activities under universal service obligation',
              },
            ],
          },
          {
            postgres_id: 15663,
            id: '53.2',
            sort: 664,
            label: 'Other postal and courier activities',
            children: [
              {
                postgres_id: 15664,
                id: '53.20',
                sort: 665,
                label: 'Other postal and courier activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15665,
    id: 'I',
    sort: 666,
    label: 'ACCOMMODATION AND FOOD SERVICE ACTIVITIES',
    children: [
      {
        postgres_id: 15666,
        id: '55',
        sort: 667,
        label: 'Accommodation',
        children: [
          {
            postgres_id: 15667,
            id: '55.1',
            sort: 668,
            label: 'Hotels and similar accommodation',
            children: [
              {
                postgres_id: 15668,
                id: '55.10',
                sort: 669,
                label: 'Hotels and similar accommodation',
              },
            ],
          },
          {
            postgres_id: 15669,
            id: '55.2',
            sort: 670,
            label: 'Holiday and other short-stay accommodation',
            children: [
              {
                postgres_id: 15670,
                id: '55.20',
                sort: 671,
                label: 'Holiday and other short-stay accommodation',
              },
            ],
          },
          {
            postgres_id: 15671,
            id: '55.3',
            sort: 672,
            label:
              'Camping grounds, recreational vehicle parks and trailer parks',
            children: [
              {
                postgres_id: 15672,
                id: '55.30',
                sort: 673,
                label:
                  'Camping grounds, recreational vehicle parks and trailer parks',
              },
            ],
          },
          {
            postgres_id: 15673,
            id: '55.9',
            sort: 674,
            label: 'Other accommodation',
            children: [
              {
                postgres_id: 15674,
                id: '55.90',
                sort: 675,
                label: 'Other accommodation',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15675,
        id: '56',
        sort: 676,
        label: 'Food and beverage service activities',
        children: [
          {
            postgres_id: 15676,
            id: '56.1',
            sort: 677,
            label: 'Restaurants and mobile food service activities',
            children: [
              {
                postgres_id: 15677,
                id: '56.10',
                sort: 678,
                label: 'Restaurants and mobile food service activities',
              },
            ],
          },
          {
            postgres_id: 15678,
            id: '56.2',
            sort: 679,
            label: 'Event catering and other food service activities',
            children: [
              {
                postgres_id: 15679,
                id: '56.21',
                sort: 680,
                label: 'Event catering activities',
              },
              {
                postgres_id: 15680,
                id: '56.29',
                sort: 681,
                label: 'Other food service activities',
              },
            ],
          },
          {
            postgres_id: 15681,
            id: '56.3',
            sort: 682,
            label: 'Beverage serving activities',
            children: [
              {
                postgres_id: 15682,
                id: '56.30',
                sort: 683,
                label: 'Beverage serving activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15683,
    id: 'J',
    sort: 684,
    label: 'INFORMATION AND COMMUNICATION',
    children: [
      {
        postgres_id: 15684,
        id: '58',
        sort: 685,
        label: 'Publishing activities',
        children: [
          {
            postgres_id: 15685,
            id: '58.1',
            sort: 686,
            label:
              'Publishing of books, periodicals and other publishing activities',
            children: [
              {
                postgres_id: 15686,
                id: '58.11',
                sort: 687,
                label: 'Book publishing',
              },
              {
                postgres_id: 15687,
                id: '58.12',
                sort: 688,
                label: 'Publishing of directories and mailing lists',
              },
              {
                postgres_id: 15688,
                id: '58.13',
                sort: 689,
                label: 'Publishing of newspapers',
              },
              {
                postgres_id: 15689,
                id: '58.14',
                sort: 690,
                label: 'Publishing of journals and periodicals',
              },
              {
                postgres_id: 15690,
                id: '58.19',
                sort: 691,
                label: 'Other publishing activities',
              },
            ],
          },
          {
            postgres_id: 15691,
            id: '58.2',
            sort: 692,
            label: 'Software publishing',
            children: [
              {
                postgres_id: 15692,
                id: '58.21',
                sort: 693,
                label: 'Publishing of computer games',
              },
              {
                postgres_id: 15693,
                id: '58.29',
                sort: 694,
                label: 'Other software publishing',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15694,
        id: '59',
        sort: 695,
        label:
          'Motion picture, video and television programme production, sound recording and music publishing activities',
        children: [
          {
            postgres_id: 15695,
            id: '59.1',
            sort: 696,
            label: 'Motion picture, video and television programme activities',
            children: [
              {
                postgres_id: 15696,
                id: '59.11',
                sort: 697,
                label:
                  'Motion picture, video and television programme production activities',
              },
              {
                postgres_id: 15697,
                id: '59.12',
                sort: 698,
                label:
                  'Motion picture, video and television programme post-production activities',
              },
              {
                postgres_id: 15698,
                id: '59.13',
                sort: 699,
                label:
                  'Motion picture, video and television programme distribution activities',
              },
              {
                postgres_id: 15699,
                id: '59.14',
                sort: 700,
                label: 'Motion picture projection activities',
              },
            ],
          },
          {
            postgres_id: 15700,
            id: '59.2',
            sort: 701,
            label: 'Sound recording and music publishing activities',
            children: [
              {
                postgres_id: 15701,
                id: '59.20',
                sort: 702,
                label: 'Sound recording and music publishing activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15702,
        id: '60',
        sort: 703,
        label: 'Programming and broadcasting activities',
        children: [
          {
            postgres_id: 15703,
            id: '60.1',
            sort: 704,
            label: 'Radio broadcasting',
            children: [
              {
                postgres_id: 15704,
                id: '60.10',
                sort: 705,
                label: 'Radio broadcasting',
              },
            ],
          },
          {
            postgres_id: 15705,
            id: '60.2',
            sort: 706,
            label: 'Television programming and broadcasting activities',
            children: [
              {
                postgres_id: 15706,
                id: '60.20',
                sort: 707,
                label: 'Television programming and broadcasting activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15707,
        id: '61',
        sort: 708,
        label: 'Telecommunications',
        children: [
          {
            postgres_id: 15708,
            id: '61.1',
            sort: 709,
            label: 'Wired telecommunications activities',
            children: [
              {
                postgres_id: 15709,
                id: '61.10',
                sort: 710,
                label: 'Wired telecommunications activities',
              },
            ],
          },
          {
            postgres_id: 15710,
            id: '61.2',
            sort: 711,
            label: 'Wireless telecommunications activities',
            children: [
              {
                postgres_id: 15711,
                id: '61.20',
                sort: 712,
                label: 'Wireless telecommunications activities',
              },
            ],
          },
          {
            postgres_id: 15712,
            id: '61.3',
            sort: 713,
            label: 'Satellite telecommunications activities',
            children: [
              {
                postgres_id: 15713,
                id: '61.30',
                sort: 714,
                label: 'Satellite telecommunications activities',
              },
            ],
          },
          {
            postgres_id: 15714,
            id: '61.9',
            sort: 715,
            label: 'Other telecommunications activities',
            children: [
              {
                postgres_id: 15715,
                id: '61.90',
                sort: 716,
                label: 'Other telecommunications activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15716,
        id: '62',
        sort: 717,
        label: 'Computer programming, consultancy and related activities',
        children: [
          {
            postgres_id: 15717,
            id: '62.0',
            sort: 718,
            label: 'Computer programming, consultancy and related activities',
            children: [
              {
                postgres_id: 15718,
                id: '62.01',
                sort: 719,
                label: 'Computer programming activities',
              },
              {
                postgres_id: 15719,
                id: '62.02',
                sort: 720,
                label: 'Computer consultancy activities',
              },
              {
                postgres_id: 15720,
                id: '62.03',
                sort: 721,
                label: 'Computer facilities management activities',
              },
              {
                postgres_id: 15721,
                id: '62.09',
                sort: 722,
                label:
                  'Other information technology and computer service activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15722,
        id: '63',
        sort: 723,
        label: 'Information service activities',
        children: [
          {
            postgres_id: 15723,
            id: '63.1',
            sort: 724,
            label:
              'Data processing, hosting and related activities; web portals',
            children: [
              {
                postgres_id: 15724,
                id: '63.11',
                sort: 725,
                label: 'Data processing, hosting and related activities',
              },
              {
                postgres_id: 15725,
                id: '63.12',
                sort: 726,
                label: 'Web portals',
              },
            ],
          },
          {
            postgres_id: 15726,
            id: '63.9',
            sort: 727,
            label: 'Other information service activities',
            children: [
              {
                postgres_id: 15727,
                id: '63.91',
                sort: 728,
                label: 'News agency activities',
              },
              {
                postgres_id: 15728,
                id: '63.99',
                sort: 729,
                label: 'Other information service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15729,
    id: 'K',
    sort: 730,
    label: 'FINANCIAL AND INSURANCE ACTIVITIES',
    children: [
      {
        postgres_id: 15730,
        id: '64',
        sort: 731,
        label:
          'Financial service activities, except insurance and pension funding',
        children: [
          {
            postgres_id: 15731,
            id: '64.1',
            sort: 732,
            label: 'Monetary intermediation',
            children: [
              {
                postgres_id: 15732,
                id: '64.11',
                sort: 733,
                label: 'Central banking',
              },
              {
                postgres_id: 15733,
                id: '64.19',
                sort: 734,
                label: 'Other monetary intermediation',
              },
            ],
          },
          {
            postgres_id: 15734,
            id: '64.2',
            sort: 735,
            label: 'Activities of holding companies',
            children: [
              {
                postgres_id: 15735,
                id: '64.20',
                sort: 736,
                label: 'Activities of holding companies',
              },
            ],
          },
          {
            postgres_id: 15736,
            id: '64.3',
            sort: 737,
            label: 'Trusts, funds and similar financial entities',
            children: [
              {
                postgres_id: 15737,
                id: '64.30',
                sort: 738,
                label: 'Trusts, funds and similar financial entities',
              },
            ],
          },
          {
            postgres_id: 15738,
            id: '64.9',
            sort: 739,
            label:
              'Other financial service activities, except insurance and pension funding',
            children: [
              {
                postgres_id: 15739,
                id: '64.91',
                sort: 740,
                label: 'Financial leasing',
              },
              {
                postgres_id: 15740,
                id: '64.92',
                sort: 741,
                label: 'Other credit granting',
              },
              {
                postgres_id: 15741,
                id: '64.99',
                sort: 742,
                label:
                  'Other financial service activities, except insurance and pension funding n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15742,
        id: '65',
        sort: 743,
        label:
          'Insurance, reinsurance and pension funding, except compulsory social security',
        children: [
          {
            postgres_id: 15743,
            id: '65.1',
            sort: 744,
            label: 'Insurance',
            children: [
              {
                postgres_id: 15744,
                id: '65.11',
                sort: 745,
                label: 'Life insurance',
              },
              {
                postgres_id: 15745,
                id: '65.12',
                sort: 746,
                label: 'Non-life insurance',
              },
            ],
          },
          {
            postgres_id: 15746,
            id: '65.2',
            sort: 747,
            label: 'Reinsurance',
            children: [
              {
                postgres_id: 15747,
                id: '65.20',
                sort: 748,
                label: 'Reinsurance',
              },
            ],
          },
          {
            postgres_id: 15748,
            id: '65.3',
            sort: 749,
            label: 'Pension funding',
            children: [
              {
                postgres_id: 15749,
                id: '65.30',
                sort: 750,
                label: 'Pension funding',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15750,
        id: '66',
        sort: 751,
        label:
          'Activities auxiliary to financial services and insurance activities',
        children: [
          {
            postgres_id: 15751,
            id: '66.1',
            sort: 752,
            label:
              'Activities auxiliary to financial services, except insurance and pension funding',
            children: [
              {
                postgres_id: 15752,
                id: '66.11',
                sort: 753,
                label: 'Administration of financial markets',
              },
              {
                postgres_id: 15753,
                id: '66.12',
                sort: 754,
                label: 'Security and commodity contracts brokerage',
              },
              {
                postgres_id: 15754,
                id: '66.19',
                sort: 755,
                label:
                  'Other activities auxiliary to financial services, except insurance and pension funding',
              },
            ],
          },
          {
            postgres_id: 15755,
            id: '66.2',
            sort: 756,
            label: 'Activities auxiliary to insurance and pension funding',
            children: [
              {
                postgres_id: 15756,
                id: '66.21',
                sort: 757,
                label: 'Risk and damage evaluation',
              },
              {
                postgres_id: 15757,
                id: '66.22',
                sort: 758,
                label: 'Activities of insurance agents and brokers',
              },
              {
                postgres_id: 15758,
                id: '66.29',
                sort: 759,
                label:
                  'Other activities auxiliary to insurance and pension funding',
              },
            ],
          },
          {
            postgres_id: 15759,
            id: '66.3',
            sort: 760,
            label: 'Fund management activities',
            children: [
              {
                postgres_id: 15760,
                id: '66.30',
                sort: 761,
                label: 'Fund management activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15761,
    id: 'L',
    sort: 762,
    label: 'REAL ESTATE ACTIVITIES',
    children: [
      {
        postgres_id: 15762,
        id: '68',
        sort: 763,
        label: 'Real estate activities',
        children: [
          {
            postgres_id: 15763,
            id: '68.1',
            sort: 764,
            label: 'Buying and selling of own real estate',
            children: [
              {
                postgres_id: 15764,
                id: '68.10',
                sort: 765,
                label: 'Buying and selling of own real estate',
              },
            ],
          },
          {
            postgres_id: 15765,
            id: '68.2',
            sort: 766,
            label: 'Rental and operating of own or leased real estate',
            children: [
              {
                postgres_id: 15766,
                id: '68.20',
                sort: 767,
                label: 'Rental and operating of own or leased real estate',
              },
            ],
          },
          {
            postgres_id: 15767,
            id: '68.3',
            sort: 768,
            label: 'Real estate activities on a fee or contract basis',
            children: [
              {
                postgres_id: 15768,
                id: '68.31',
                sort: 769,
                label: 'Real estate agencies',
              },
              {
                postgres_id: 15769,
                id: '68.32',
                sort: 770,
                label: 'Management of real estate on a fee or contract basis',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15770,
    id: 'M',
    sort: 771,
    label: 'PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES',
    children: [
      {
        postgres_id: 15771,
        id: '69',
        sort: 772,
        label: 'Legal and accounting activities',
        children: [
          {
            postgres_id: 15772,
            id: '69.1',
            sort: 773,
            label: 'Legal activities',
            children: [
              {
                postgres_id: 15773,
                id: '69.10',
                sort: 774,
                label: 'Legal activities',
              },
            ],
          },
          {
            postgres_id: 15774,
            id: '69.2',
            sort: 775,
            label:
              'Accounting, bookkeeping and auditing activities; tax consultancy',
            children: [
              {
                postgres_id: 15775,
                id: '69.20',
                sort: 776,
                label:
                  'Accounting, bookkeeping and auditing activities; tax consultancy',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15776,
        id: '70',
        sort: 777,
        label: 'Activities of head offices; management consultancy activities',
        children: [
          {
            postgres_id: 15777,
            id: '70.1',
            sort: 778,
            label: 'Activities of head offices',
            children: [
              {
                postgres_id: 15778,
                id: '70.10',
                sort: 779,
                label: 'Activities of head offices',
              },
            ],
          },
          {
            postgres_id: 15779,
            id: '70.2',
            sort: 780,
            label: 'Management consultancy activities',
            children: [
              {
                postgres_id: 15780,
                id: '70.21',
                sort: 781,
                label: 'Public relations and communication activities',
              },
              {
                postgres_id: 15781,
                id: '70.22',
                sort: 782,
                label: 'Business and other management consultancy activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15782,
        id: '71',
        sort: 783,
        label:
          'Architectural and engineering activities; technical testing and analysis',
        children: [
          {
            postgres_id: 15783,
            id: '71.1',
            sort: 784,
            label:
              'Architectural and engineering activities and related technical consultancy',
            children: [
              {
                postgres_id: 15784,
                id: '71.11',
                sort: 785,
                label: 'Architectural activities ',
              },
              {
                postgres_id: 15785,
                id: '71.12',
                sort: 786,
                label:
                  'Engineering activities and related technical consultancy',
              },
            ],
          },
          {
            postgres_id: 15786,
            id: '71.2',
            sort: 787,
            label: 'Technical testing and analysis',
            children: [
              {
                postgres_id: 15787,
                id: '71.20',
                sort: 788,
                label: 'Technical testing and analysis',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15788,
        id: '72',
        sort: 789,
        label: 'Scientific research and development ',
        children: [
          {
            postgres_id: 15789,
            id: '72.1',
            sort: 790,
            label:
              'Research and experimental development on natural sciences and engineering',
            children: [
              {
                postgres_id: 15790,
                id: '72.11',
                sort: 791,
                label: 'Research and experimental development on biotechnology',
              },
              {
                postgres_id: 15791,
                id: '72.19',
                sort: 792,
                label:
                  'Other research and experimental development on natural sciences and engineering',
              },
            ],
          },
          {
            postgres_id: 15792,
            id: '72.2',
            sort: 793,
            label:
              'Research and experimental development on social sciences and humanities',
            children: [
              {
                postgres_id: 15793,
                id: '72.20',
                sort: 794,
                label:
                  'Research and experimental development on social sciences and humanities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15794,
        id: '73',
        sort: 795,
        label: 'Advertising and market research',
        children: [
          {
            postgres_id: 15795,
            id: '73.1',
            sort: 796,
            label: 'Advertising',
            children: [
              {
                postgres_id: 15796,
                id: '73.11',
                sort: 797,
                label: 'Advertising agencies',
              },
              {
                postgres_id: 15797,
                id: '73.12',
                sort: 798,
                label: 'Media representation',
              },
            ],
          },
          {
            postgres_id: 15798,
            id: '73.2',
            sort: 799,
            label: 'Market research and public opinion polling',
            children: [
              {
                postgres_id: 15799,
                id: '73.20',
                sort: 800,
                label: 'Market research and public opinion polling',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15800,
        id: '74',
        sort: 801,
        label: 'Other professional, scientific and technical activities',
        children: [
          {
            postgres_id: 15801,
            id: '74.1',
            sort: 802,
            label: 'Specialised design activities',
            children: [
              {
                postgres_id: 15802,
                id: '74.10',
                sort: 803,
                label: 'Specialised design activities',
              },
            ],
          },
          {
            postgres_id: 15803,
            id: '74.2',
            sort: 804,
            label: 'Photographic activities',
            children: [
              {
                postgres_id: 15804,
                id: '74.20',
                sort: 805,
                label: 'Photographic activities',
              },
            ],
          },
          {
            postgres_id: 15805,
            id: '74.3',
            sort: 806,
            label: 'Translation and interpretation activities',
            children: [
              {
                postgres_id: 15806,
                id: '74.30',
                sort: 807,
                label: 'Translation and interpretation activities',
              },
            ],
          },
          {
            postgres_id: 15807,
            id: '74.9',
            sort: 808,
            label:
              'Other professional, scientific and technical activities n.e.c.',
            children: [
              {
                postgres_id: 15808,
                id: '74.90',
                sort: 809,
                label:
                  'Other professional, scientific and technical activities n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15809,
        id: '75',
        sort: 810,
        label: 'Veterinary activities',
        children: [
          {
            postgres_id: 15810,
            id: '75.0',
            sort: 811,
            label: 'Veterinary activities',
            children: [
              {
                postgres_id: 15811,
                id: '75.00',
                sort: 812,
                label: 'Veterinary activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15812,
    id: 'N',
    sort: 813,
    label: 'ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES',
    children: [
      {
        postgres_id: 15813,
        id: '77',
        sort: 814,
        label: 'Rental and leasing activities',
        children: [
          {
            postgres_id: 15814,
            id: '77.1',
            sort: 815,
            label: 'Rental and leasing of motor vehicles',
            children: [
              {
                postgres_id: 15815,
                id: '77.11',
                sort: 816,
                label: 'Rental and leasing of cars and light motor vehicles',
              },
              {
                postgres_id: 15816,
                id: '77.12',
                sort: 817,
                label: 'Rental and leasing of trucks',
              },
            ],
          },
          {
            postgres_id: 15817,
            id: '77.2',
            sort: 818,
            label: 'Rental and leasing of personal and household goods',
            children: [
              {
                postgres_id: 15818,
                id: '77.21',
                sort: 819,
                label: 'Rental and leasing of recreational and sports goods',
              },
              {
                postgres_id: 15819,
                id: '77.22',
                sort: 820,
                label: 'Rental of video tapes and disks',
              },
              {
                postgres_id: 15820,
                id: '77.29',
                sort: 821,
                label:
                  'Rental and leasing of other personal and household goods',
              },
            ],
          },
          {
            postgres_id: 15821,
            id: '77.3',
            sort: 822,
            label:
              'Rental and leasing of other machinery, equipment and tangible goods',
            children: [
              {
                postgres_id: 15822,
                id: '77.31',
                sort: 823,
                label:
                  'Rental and leasing of agricultural machinery and equipment',
              },
              {
                postgres_id: 15823,
                id: '77.32',
                sort: 824,
                label:
                  'Rental and leasing of construction and civil engineering machinery and equipment',
              },
              {
                postgres_id: 15824,
                id: '77.33',
                sort: 825,
                label:
                  'Rental and leasing of office machinery and equipment (including computers)',
              },
              {
                postgres_id: 15825,
                id: '77.34',
                sort: 826,
                label: 'Rental and leasing of water transport equipment',
              },
              {
                postgres_id: 15826,
                id: '77.35',
                sort: 827,
                label: 'Rental and leasing of air transport equipment',
              },
              {
                postgres_id: 15827,
                id: '77.39',
                sort: 828,
                label:
                  'Rental and leasing of other machinery, equipment and tangible goods n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15828,
            id: '77.4',
            sort: 829,
            label:
              'Leasing of intellectual property and similar products, except copyrighted works',
            children: [
              {
                postgres_id: 15829,
                id: '77.40',
                sort: 830,
                label:
                  'Leasing of intellectual property and similar products, except copyrighted works',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15830,
        id: '78',
        sort: 831,
        label: 'Employment activities',
        children: [
          {
            postgres_id: 15831,
            id: '78.1',
            sort: 832,
            label: 'Activities of employment placement agencies',
            children: [
              {
                postgres_id: 15832,
                id: '78.10',
                sort: 833,
                label: 'Activities of employment placement agencies',
              },
            ],
          },
          {
            postgres_id: 15833,
            id: '78.2',
            sort: 834,
            label: 'Temporary employment agency activities',
            children: [
              {
                postgres_id: 15834,
                id: '78.20',
                sort: 835,
                label: 'Temporary employment agency activities',
              },
            ],
          },
          {
            postgres_id: 15835,
            id: '78.3',
            sort: 836,
            label: 'Other human resources provision',
            children: [
              {
                postgres_id: 15836,
                id: '78.30',
                sort: 837,
                label: 'Other human resources provision',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15837,
        id: '79',
        sort: 838,
        label:
          'Travel agency, tour operator and other reservation service and related activities',
        children: [
          {
            postgres_id: 15838,
            id: '79.1',
            sort: 839,
            label: 'Travel agency and tour operator activities',
            children: [
              {
                postgres_id: 15839,
                id: '79.11',
                sort: 840,
                label: 'Travel agency activities',
              },
              {
                postgres_id: 15840,
                id: '79.12',
                sort: 841,
                label: 'Tour operator activities',
              },
            ],
          },
          {
            postgres_id: 15841,
            id: '79.9',
            sort: 842,
            label: 'Other reservation service and related activities',
            children: [
              {
                postgres_id: 15842,
                id: '79.90',
                sort: 843,
                label: 'Other reservation service and related activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15843,
        id: '80',
        sort: 844,
        label: 'Security and investigation activities',
        children: [
          {
            postgres_id: 15844,
            id: '80.1',
            sort: 845,
            label: 'Private security activities',
            children: [
              {
                postgres_id: 15845,
                id: '80.10',
                sort: 846,
                label: 'Private security activities',
              },
            ],
          },
          {
            postgres_id: 15846,
            id: '80.2',
            sort: 847,
            label: 'Security systems service activities',
            children: [
              {
                postgres_id: 15847,
                id: '80.20',
                sort: 848,
                label: 'Security systems service activities',
              },
            ],
          },
          {
            postgres_id: 15848,
            id: '80.3',
            sort: 849,
            label: 'Investigation activities',
            children: [
              {
                postgres_id: 15849,
                id: '80.30',
                sort: 850,
                label: 'Investigation activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15850,
        id: '81',
        sort: 851,
        label: 'Services to buildings and landscape activities',
        children: [
          {
            postgres_id: 15851,
            id: '81.1',
            sort: 852,
            label: 'Combined facilities support activities',
            children: [
              {
                postgres_id: 15852,
                id: '81.10',
                sort: 853,
                label: 'Combined facilities support activities',
              },
            ],
          },
          {
            postgres_id: 15853,
            id: '81.2',
            sort: 854,
            label: 'Cleaning activities',
            children: [
              {
                postgres_id: 15854,
                id: '81.21',
                sort: 855,
                label: 'General cleaning of buildings',
              },
              {
                postgres_id: 15855,
                id: '81.22',
                sort: 856,
                label: 'Other building and industrial cleaning activities',
              },
              {
                postgres_id: 15856,
                id: '81.29',
                sort: 857,
                label: 'Other cleaning activities',
              },
            ],
          },
          {
            postgres_id: 15857,
            id: '81.3',
            sort: 858,
            label: 'Landscape service activities',
            children: [
              {
                postgres_id: 15858,
                id: '81.30',
                sort: 859,
                label: 'Landscape service activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15859,
        id: '82',
        sort: 860,
        label:
          'Office administrative, office support and other business support activities',
        children: [
          {
            postgres_id: 15860,
            id: '82.1',
            sort: 861,
            label: 'Office administrative and support activities',
            children: [
              {
                postgres_id: 15861,
                id: '82.11',
                sort: 862,
                label: 'Combined office administrative service activities',
              },
              {
                postgres_id: 15862,
                id: '82.19',
                sort: 863,
                label:
                  'Photocopying, document preparation and other specialised office support activities',
              },
            ],
          },
          {
            postgres_id: 15863,
            id: '82.2',
            sort: 864,
            label: 'Activities of call centres',
            children: [
              {
                postgres_id: 15864,
                id: '82.20',
                sort: 865,
                label: 'Activities of call centres',
              },
            ],
          },
          {
            postgres_id: 15865,
            id: '82.3',
            sort: 866,
            label: 'Organisation of conventions and trade shows',
            children: [
              {
                postgres_id: 15866,
                id: '82.30',
                sort: 867,
                label: 'Organisation of conventions and trade shows',
              },
            ],
          },
          {
            postgres_id: 15867,
            id: '82.9',
            sort: 868,
            label: 'Business support service activities n.e.c.',
            children: [
              {
                postgres_id: 15868,
                id: '82.91',
                sort: 869,
                label: 'Activities of collection agencies and credit bureaus',
              },
              {
                postgres_id: 15869,
                id: '82.92',
                sort: 870,
                label: 'Packaging activities',
              },
              {
                postgres_id: 15870,
                id: '82.99',
                sort: 871,
                label: 'Other business support service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15871,
    id: 'O',
    sort: 872,
    label: 'PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY',
    children: [
      {
        postgres_id: 15872,
        id: '84',
        sort: 873,
        label: 'Public administration and defence; compulsory social security',
        children: [
          {
            postgres_id: 15873,
            id: '84.1',
            sort: 874,
            label:
              'Administration of the State and the economic and social policy of the community',
            children: [
              {
                postgres_id: 15874,
                id: '84.11',
                sort: 875,
                label: 'General public administration activities',
              },
              {
                postgres_id: 15875,
                id: '84.12',
                sort: 876,
                label:
                  'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
              },
              {
                postgres_id: 15876,
                id: '84.13',
                sort: 877,
                label:
                  'Regulation of and contribution to more efficient operation of businesses',
              },
            ],
          },
          {
            postgres_id: 15877,
            id: '84.2',
            sort: 878,
            label: 'Provision of services to the community as a whole',
            children: [
              {
                postgres_id: 15878,
                id: '84.21',
                sort: 879,
                label: 'Foreign affairs',
              },
              {
                postgres_id: 15879,
                id: '84.22',
                sort: 880,
                label: 'Defence activities',
              },
              {
                postgres_id: 15880,
                id: '84.23',
                sort: 881,
                label: 'Justice and judicial activities',
              },
              {
                postgres_id: 15881,
                id: '84.24',
                sort: 882,
                label: 'Public order and safety activities',
              },
              {
                postgres_id: 15882,
                id: '84.25',
                sort: 883,
                label: 'Fire service activities',
              },
            ],
          },
          {
            postgres_id: 15883,
            id: '84.3',
            sort: 884,
            label: 'Compulsory social security activities',
            children: [
              {
                postgres_id: 15884,
                id: '84.30',
                sort: 885,
                label: 'Compulsory social security activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15885,
    id: 'P',
    sort: 886,
    label: 'EDUCATION',
    children: [
      {
        postgres_id: 15886,
        id: '85',
        sort: 887,
        label: 'Education',
        children: [
          {
            postgres_id: 15887,
            id: '85.1',
            sort: 888,
            label: 'Pre-primary education',
            children: [
              {
                postgres_id: 15888,
                id: '85.10',
                sort: 889,
                label: 'Pre-primary education ',
              },
            ],
          },
          {
            postgres_id: 15889,
            id: '85.2',
            sort: 890,
            label: 'Primary education',
            children: [
              {
                postgres_id: 15890,
                id: '85.20',
                sort: 891,
                label: 'Primary education ',
              },
            ],
          },
          {
            postgres_id: 15891,
            id: '85.3',
            sort: 892,
            label: 'Secondary education',
            children: [
              {
                postgres_id: 15892,
                id: '85.31',
                sort: 893,
                label: 'General secondary education ',
              },
              {
                postgres_id: 15893,
                id: '85.32',
                sort: 894,
                label: 'Technical and vocational secondary education ',
              },
            ],
          },
          {
            postgres_id: 15894,
            id: '85.4',
            sort: 895,
            label: 'Higher education',
            children: [
              {
                postgres_id: 15895,
                id: '85.41',
                sort: 896,
                label: 'Post-secondary non-tertiary education',
              },
              {
                postgres_id: 15896,
                id: '85.42',
                sort: 897,
                label: 'Tertiary education',
              },
            ],
          },
          {
            postgres_id: 15897,
            id: '85.5',
            sort: 898,
            label: 'Other education',
            children: [
              {
                postgres_id: 15898,
                id: '85.51',
                sort: 899,
                label: 'Sports and recreation education',
              },
              {
                postgres_id: 15899,
                id: '85.52',
                sort: 900,
                label: 'Cultural education',
              },
              {
                postgres_id: 15900,
                id: '85.53',
                sort: 901,
                label: 'Driving school activities',
              },
              {
                postgres_id: 15901,
                id: '85.59',
                sort: 902,
                label: 'Other education n.e.c.',
              },
            ],
          },
          {
            postgres_id: 15902,
            id: '85.6',
            sort: 903,
            label: 'Educational support activities',
            children: [
              {
                postgres_id: 15903,
                id: '85.60',
                sort: 904,
                label: 'Educational support activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15904,
    id: 'Q',
    sort: 905,
    label: 'HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
    children: [
      {
        postgres_id: 15905,
        id: '86',
        sort: 906,
        label: 'Human health activities',
        children: [
          {
            postgres_id: 15906,
            id: '86.1',
            sort: 907,
            label: 'Hospital activities',
            children: [
              {
                postgres_id: 15907,
                id: '86.10',
                sort: 908,
                label: 'Hospital activities',
              },
            ],
          },
          {
            postgres_id: 15908,
            id: '86.2',
            sort: 909,
            label: 'Medical and dental practice activities',
            children: [
              {
                postgres_id: 15909,
                id: '86.21',
                sort: 910,
                label: 'General medical practice activities',
              },
              {
                postgres_id: 15910,
                id: '86.22',
                sort: 911,
                label: 'Specialist medical practice activities',
              },
              {
                postgres_id: 15911,
                id: '86.23',
                sort: 912,
                label: 'Dental practice activities',
              },
            ],
          },
          {
            postgres_id: 15912,
            id: '86.9',
            sort: 913,
            label: 'Other human health activities',
            children: [
              {
                postgres_id: 15913,
                id: '86.90',
                sort: 914,
                label: 'Other human health activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15914,
        id: '87',
        sort: 915,
        label: 'Residential care activities',
        children: [
          {
            postgres_id: 15915,
            id: '87.1',
            sort: 916,
            label: 'Residential nursing care activities',
            children: [
              {
                postgres_id: 15916,
                id: '87.10',
                sort: 917,
                label: 'Residential nursing care activities',
              },
            ],
          },
          {
            postgres_id: 15917,
            id: '87.2',
            sort: 918,
            label:
              'Residential care activities for mental retardation, mental health and substance abuse',
            children: [
              {
                postgres_id: 15918,
                id: '87.20',
                sort: 919,
                label:
                  'Residential care activities for mental retardation, mental health and substance abuse',
              },
            ],
          },
          {
            postgres_id: 15919,
            id: '87.3',
            sort: 920,
            label: 'Residential care activities for the elderly and disabled',
            children: [
              {
                postgres_id: 15920,
                id: '87.30',
                sort: 921,
                label:
                  'Residential care activities for the elderly and disabled',
              },
            ],
          },
          {
            postgres_id: 15921,
            id: '87.9',
            sort: 922,
            label: 'Other residential care activities',
            children: [
              {
                postgres_id: 15922,
                id: '87.90',
                sort: 923,
                label: 'Other residential care activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15923,
        id: '88',
        sort: 924,
        label: 'Social work activities without accommodation',
        children: [
          {
            postgres_id: 15924,
            id: '88.1',
            sort: 925,
            label:
              'Social work activities without accommodation for the elderly and disabled',
            children: [
              {
                postgres_id: 15925,
                id: '88.10',
                sort: 926,
                label:
                  'Social work activities without accommodation for the elderly and disabled',
              },
            ],
          },
          {
            postgres_id: 15926,
            id: '88.9',
            sort: 927,
            label: 'Other social work activities without accommodation',
            children: [
              {
                postgres_id: 15927,
                id: '88.91',
                sort: 928,
                label: 'Child day-care activities',
              },
              {
                postgres_id: 15928,
                id: '88.99',
                sort: 929,
                label:
                  'Other social work activities without accommodation n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15929,
    id: 'R',
    sort: 930,
    label: 'ARTS, ENTERTAINMENT AND RECREATION',
    children: [
      {
        postgres_id: 15930,
        id: '90',
        sort: 931,
        label: 'Creative, arts and entertainment activities',
        children: [
          {
            postgres_id: 15931,
            id: '90.0',
            sort: 932,
            label: 'Creative, arts and entertainment activities',
            children: [
              {
                postgres_id: 15932,
                id: '90.01',
                sort: 933,
                label: 'Performing arts',
              },
              {
                postgres_id: 15933,
                id: '90.02',
                sort: 934,
                label: 'Support activities to performing arts',
              },
              {
                postgres_id: 15934,
                id: '90.03',
                sort: 935,
                label: 'Artistic creation',
              },
              {
                postgres_id: 15935,
                id: '90.04',
                sort: 936,
                label: 'Operation of arts facilities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15936,
        id: '91',
        sort: 937,
        label: 'Libraries, archives, museums and other cultural activities',
        children: [
          {
            postgres_id: 15937,
            id: '91.0',
            sort: 938,
            label: 'Libraries, archives, museums and other cultural activities',
            children: [
              {
                postgres_id: 15938,
                id: '91.01',
                sort: 939,
                label: 'Library and archives activities',
              },
              {
                postgres_id: 15939,
                id: '91.02',
                sort: 940,
                label: 'Museums activities',
              },
              {
                postgres_id: 15940,
                id: '91.03',
                sort: 941,
                label:
                  'Operation of historical sites and buildings and similar visitor attractions',
              },
              {
                postgres_id: 15941,
                id: '91.04',
                sort: 942,
                label:
                  'Botanical and zoological gardens and nature reserves activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15942,
        id: '92',
        sort: 943,
        label: 'Gambling and betting activities',
        children: [
          {
            postgres_id: 15943,
            id: '92.0',
            sort: 944,
            label: 'Gambling and betting activities',
            children: [
              {
                postgres_id: 15944,
                id: '92.00',
                sort: 945,
                label: 'Gambling and betting activities',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15945,
        id: '93',
        sort: 946,
        label: 'Sports activities and amusement and recreation activities',
        children: [
          {
            postgres_id: 15946,
            id: '93.1',
            sort: 947,
            label: 'Sports activities',
            children: [
              {
                postgres_id: 15947,
                id: '93.11',
                sort: 948,
                label: 'Operation of sports facilities',
              },
              {
                postgres_id: 15948,
                id: '93.12',
                sort: 949,
                label: 'Activities of sports clubs',
              },
              {
                postgres_id: 15949,
                id: '93.13',
                sort: 950,
                label: 'Fitness facilities',
              },
              {
                postgres_id: 15950,
                id: '93.19',
                sort: 951,
                label: 'Other sports activities',
              },
            ],
          },
          {
            postgres_id: 15951,
            id: '93.2',
            sort: 952,
            label: 'Amusement and recreation activities',
            children: [
              {
                postgres_id: 15952,
                id: '93.21',
                sort: 953,
                label: 'Activities of amusement parks and theme parks',
              },
              {
                postgres_id: 15953,
                id: '93.29',
                sort: 954,
                label: 'Other amusement and recreation activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15954,
    id: 'S',
    sort: 955,
    label: 'OTHER SERVICE ACTIVITIES',
    children: [
      {
        postgres_id: 15955,
        id: '94',
        sort: 956,
        label: 'Activities of membership organisations',
        children: [
          {
            postgres_id: 15956,
            id: '94.1',
            sort: 957,
            label:
              'Activities of business, employers and professional membership organisations',
            children: [
              {
                postgres_id: 15957,
                id: '94.11',
                sort: 958,
                label:
                  'Activities of business and employers membership organisations',
              },
              {
                postgres_id: 15958,
                id: '94.12',
                sort: 959,
                label: 'Activities of professional membership organisations',
              },
            ],
          },
          {
            postgres_id: 15959,
            id: '94.2',
            sort: 960,
            label: 'Activities of trade unions',
            children: [
              {
                postgres_id: 15960,
                id: '94.20',
                sort: 961,
                label: 'Activities of trade unions',
              },
            ],
          },
          {
            postgres_id: 15961,
            id: '94.9',
            sort: 962,
            label: 'Activities of other membership organisations',
            children: [
              {
                postgres_id: 15962,
                id: '94.91',
                sort: 963,
                label: 'Activities of religious organisations',
              },
              {
                postgres_id: 15963,
                id: '94.92',
                sort: 964,
                label: 'Activities of political organisations',
              },
              {
                postgres_id: 15964,
                id: '94.99',
                sort: 965,
                label: 'Activities of other membership organisations n.e.c.',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15965,
        id: '95',
        sort: 966,
        label: 'Repair of computers and personal and household goods',
        children: [
          {
            postgres_id: 15966,
            id: '95.1',
            sort: 967,
            label: 'Repair of computers and communication equipment',
            children: [
              {
                postgres_id: 15967,
                id: '95.11',
                sort: 968,
                label: 'Repair of computers and peripheral equipment',
              },
              {
                postgres_id: 15968,
                id: '95.12',
                sort: 969,
                label: 'Repair of communication equipment',
              },
            ],
          },
          {
            postgres_id: 15969,
            id: '95.2',
            sort: 970,
            label: 'Repair of personal and household goods',
            children: [
              {
                postgres_id: 15970,
                id: '95.21',
                sort: 971,
                label: 'Repair of consumer electronics',
              },
              {
                postgres_id: 15971,
                id: '95.22',
                sort: 972,
                label:
                  'Repair of household appliances and home and garden equipment',
              },
              {
                postgres_id: 15972,
                id: '95.23',
                sort: 973,
                label: 'Repair of footwear and leather goods',
              },
              {
                postgres_id: 15973,
                id: '95.24',
                sort: 974,
                label: 'Repair of furniture and home furnishings',
              },
              {
                postgres_id: 15974,
                id: '95.25',
                sort: 975,
                label: 'Repair of watches, clocks and jewellery',
              },
              {
                postgres_id: 15975,
                id: '95.29',
                sort: 976,
                label: 'Repair of other personal and household goods',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15976,
        id: '96',
        sort: 977,
        label: 'Other personal service activities',
        children: [
          {
            postgres_id: 15977,
            id: '96.0',
            sort: 978,
            label: 'Other personal service activities',
            children: [
              {
                postgres_id: 15978,
                id: '96.01',
                sort: 979,
                label: 'Washing and (dry-)cleaning of textile and fur products',
              },
              {
                postgres_id: 15979,
                id: '96.02',
                sort: 980,
                label: 'Hairdressing and other beauty treatment',
              },
              {
                postgres_id: 15980,
                id: '96.03',
                sort: 981,
                label: 'Funeral and related activities',
              },
              {
                postgres_id: 15981,
                id: '96.04',
                sort: 982,
                label: 'Physical well-being activities',
              },
              {
                postgres_id: 15982,
                id: '96.09',
                sort: 983,
                label: 'Other personal service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15983,
    id: 'T',
    sort: 984,
    label:
      'ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
    children: [
      {
        postgres_id: 15984,
        id: '97',
        sort: 985,
        label: 'Activities of households as employers of domestic personnel',
        children: [
          {
            postgres_id: 15985,
            id: '97.0',
            sort: 986,
            label:
              'Activities of households as employers of domestic personnel',
            children: [
              {
                postgres_id: 15986,
                id: '97.00',
                sort: 987,
                label:
                  'Activities of households as employers of domestic personnel',
              },
            ],
          },
        ],
      },
      {
        postgres_id: 15987,
        id: '98',
        sort: 988,
        label:
          'Undifferentiated goods- and services-producing activities of private households for own use',
        children: [
          {
            postgres_id: 15988,
            id: '98.1',
            sort: 989,
            label:
              'Undifferentiated goods-producing activities of private households for own use',
            children: [
              {
                postgres_id: 15989,
                id: '98.10',
                sort: 990,
                label:
                  'Undifferentiated goods-producing activities of private households for own use',
              },
            ],
          },
          {
            postgres_id: 15990,
            id: '98.2',
            sort: 991,
            label:
              'Undifferentiated service-producing activities of private households for own use',
            children: [
              {
                postgres_id: 15991,
                id: '98.20',
                sort: 992,
                label:
                  'Undifferentiated service-producing activities of private households for own use',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    postgres_id: 15992,
    id: 'U',
    sort: 993,
    label: 'ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES',
    children: [
      {
        postgres_id: 15993,
        id: '99',
        sort: 994,
        label: 'Activities of extraterritorial organisations and bodies',
        children: [
          {
            postgres_id: 15994,
            id: '99.0',
            sort: 995,
            label: 'Activities of extraterritorial organisations and bodies',
            children: [
              {
                postgres_id: 15995,
                id: '99.00',
                sort: 996,
                label:
                  'Activities of extraterritorial organisations and bodies',
              },
            ],
          },
        ],
      },
    ],
  },
]
