import { render, staticRenderFns } from "./SectionOrQuestion.vue?vue&type=template&id=0989e88e"
import script from "./SectionOrQuestion.vue?vue&type=script&setup=true&lang=ts"
export * from "./SectionOrQuestion.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputTextMaster: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/TextMaster.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,SfdrTopInvestments: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/TopInvestments.vue').default,SfdrFossilOrNuclear: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/fossilOrNuclear.vue').default,SfdrInvestmentsCategorizationChartData: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/sfdr/investmentsCategorizationChartData.vue').default,IxSectionOrQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionOrQuestion.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VRow,VSimpleCheckbox,VTreeview})
