import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.find.js";
export default defineComponent({
  props: {
    sections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup(props) {
    var active = ref([]);
    var selected = computed(function () {
      var _props$sections;
      if (!active.value.length) return undefined;
      var selectedId = active.value[0];
      var flatSections = ((_props$sections = props.sections) === null || _props$sections === void 0 ? void 0 : _props$sections.reduce(function (merged, section) {
        return [].concat(_toConsumableArray(merged), [section], _toConsumableArray(section.sections));
      }, [])) || [];
      return flatSections.find(function (_ref) {
        var id = _ref.id;
        return selectedId === id;
      });
    });
    return {
      active: active,
      selected: selected
    };
  }
});