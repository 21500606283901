export default defineComponent({
  props: {
    error: {
      default: false,
      type: Boolean
    },
    icon: {
      default: undefined,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data: function data() {
    return {
      buttonColor: 'primary',
      success: false
    };
  },
  methods: {
    setSuccess: function setSuccess() {
      var _this = this;
      this.success = true;
      this.buttonColor = 'success';
      setTimeout(function () {
        _this.buttonColor = 'primary';
        _this.success = false;
      }, 2000);
    }
  }
});