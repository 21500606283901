import { cimIntroNotes } from '~/assets/impactSolution';
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  setup: function setup() {
    return {
      cimIntroNotes: cimIntroNotes
    };
  },
  data: function data() {
    return {
      showBenefitsQuantifyingGhg: false
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});