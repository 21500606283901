import { geographies } from '~/assets/nestedGeographies'
import { GeographyType } from '~/types/types'

export const getTooltipData = (assessments: any, isSdgChart = true) => {
  return assessments
    .map((assessment: any) => {
      return `
          <tr>
            <td style="padding-top:10px!important;">
              ${
                assessment.company.logo
                  ? `<img src="${assessment.company.logo}" style="width:auto !important;min-width: 30px !important;height:20px !important; "
                />`
                  : ''
              }
            </td>
            <td style="padding:10px 0 0 20px !important;">
            <a href='${process.env.DOMAIN}/${assessment.company.id}/impact'>${
              assessment.company.name
            }</a>

            </td>
            ${
              !isSdgChart
                ? ` <td style="padding:10px 0 0 20px !important; width:max-content !important">
            ${assessment.impact.geographies
              .map((e: GeographyType) => e.label)
              .join(', ')}
          </td>`
                : ''
            }
           
            <td style="padding:10px 0 0 20px !important; width:max-content !important">${assessment
              .impact.concept?.label}</td>
            <td style="padding:10px 0 0 20px !important;"><span>
            ${assessment.impact.stakeholder}</span></td>
          </tr>`
    })
    .join(' ')
}

export const getGeographiesLabel = (impact: any, region: string) => {
  if (!impact || !impact.geographies || !impact.geographies.length) return ''
  const regionInfo: any = {}
  if (region === 'Wordwide') region = 'World'
  geographies.forEach((world: any) => {
    if (world.label === region) regionInfo.child = region
    else {
      world.children.forEach((parent: any) => {
        if (parent.label === region) {
          regionInfo.parent = 'World'
          regionInfo.child = region
        } else {
          parent.children.forEach((subParent: any) => {
            if (subParent.label === region) {
              regionInfo.parent = parent.label
              regionInfo.child = region
            } else {
              subParent.children.forEach((child: any) => {
                if (child.label === region) {
                  regionInfo.parent = parent.label
                  regionInfo.subParent = subParent.label
                  regionInfo.child = region
                }
              })
            }
          })
        }
      })
    }
  })

  if (Object.values(regionInfo).length) {
    const relatedRegionGeo = impact.geographies.filter((geography: any) => {
      return Object.values(regionInfo).includes(geography.label)
    })
    if (region === 'World' && regionInfo.child === region) return region
    else if (relatedRegionGeo.some((g: any) => g.label === regionInfo.parent)) {
      return regionInfo.parent
    } else if (
      relatedRegionGeo.some((g: any) => g.label === regionInfo.subParent)
    ) {
      return `${(regionInfo.subParent, regionInfo.subParent)}`
    } else return Object.values(regionInfo).join(', ')
  } else return ''
}

// eslint-disable-next-line prettier/prettier
export const formattedTooltipData = (venturesMap: Map<any, any>, e: any) => {
  const impacts: any = [...new Set(e.impacts)]
  return impacts.map((impact: any) => {
    const id = impact.impactGoal.ventureId
    const venture = venturesMap.get(id)
    const result = {
      company: {
        id: id,
        name: venture?.venture?.name,
        logo: venture?.venture?.logo,
      },
      impact,
    }
    return result
  })
}
