var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.metricAssignments),function(item){return _c('v-row',{key:item.id,staticClass:"align-center"},[_vm._l((item.metricValues.filter((val) => {
        return _vm.periods.includes(parseInt(val.period))
      })),function(metricValue){return _c('v-col',{key:metricValue.id,attrs:{"cols":"3"}},[_c('ix-form-input-metric-single-value',{attrs:{"value":metricValue,"type":"target","metric":item.metric,"label":metricValue.period,"disabled":item.isFrozen,"timeout":1000},on:{"input":(val) => _vm.updateMetricValue(val)}})],1)}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-textarea',{attrs:{"value":item.comment,"label":_vm.$t(
            'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.explanationTitle',
          ),"placeholder":_vm.$t(
            'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.explanationPlaceholder',
          )},on:{"input":(comment) => {
            _vm.handleCommentUpdate(item, comment)
          }}})],1)],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }