import { render, staticRenderFns } from "./PerformanceComparisonFirstToSecond.vue?vue&type=template&id=520698ee"
import script from "./PerformanceComparisonFirstToSecond.vue?vue&type=script&lang=ts"
export * from "./PerformanceComparisonFirstToSecond.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EsgPerformanceComparisonSingle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PerformanceComparisonSingle.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,EsgComparisonComments: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/EsgComparisonComments.vue').default})
