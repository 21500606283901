import { percentage, percentageText, color, getDifferenceInfo } from '~/functions/esg';
export default defineComponent({
  props: {
    esgSections: {
      type: Array,
      required: true
    },
    thirdVersionText: {
      type: String,
      required: false,
      default: null
    },
    thirdData: {
      type: Object,
      required: false,
      default: null
    },
    secondVersionText: {
      type: String,
      required: true
    },
    secondData: {
      type: Object,
      required: true
    },
    firstVersionText: {
      type: String,
      required: true
    },
    firstData: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var headers = ref([{
      text: '',
      value: 'esgSection',
      sortable: false,
      align: 'start'
    }, {
      text: props.firstVersionText,
      value: 'first',
      sortable: false,
      width: '15%',
      align: 'center'
    }, {
      text: props.secondVersionText,
      value: 'second',
      sortable: false,
      width: '15%',
      align: 'center'
    }, {
      text: props.thirdVersionText,
      value: 'third',
      sortable: false,
      width: '15%',
      align: 'center'
    }]);
    var everyComparisonEsgSectionHasResults = computed(function () {
      return props.esgSections.every(function (section) {
        return props.firstData[section.label];
      });
    });
    var everyReferenceEsgSectionHasResults = computed(function () {
      return props.esgSections.every(function (section) {
        return props.secondData[section.label];
      });
    });
    function getComparisonResult(label) {
      return props.firstData[label];
    }
    function getReferenceResult(label, column) {
      var data = column === 'third' ? props.thirdData : props.secondData;
      return data[label];
    }
    function getDifference(label, column) {
      return getDifferenceInfo(getComparisonResult(label), getReferenceResult(label, column));
    }
    function getDifferenceColor(label, column) {
      var info = getDifference(label, column);
      if (info.hasValue) return 'blue-grey';
      if (info.difference === 0) return 'primary';else if (info.difference < 0) return 'danger';else return 'success';
    }
    function colorClass(label) {
      return color(percentage(getComparisonResult(label)), false);
    }
    function getProgressBarData(label) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'esg';
      return {
        height: type === 'esg' ? '16' : '6',
        value: percentage(getComparisonResult(label)),
        color: colorClass(label)
      };
    }
    function getTargetStyle(label, column) {
      return {
        left: "".concat(percentage(getReferenceResult(label, column)), "%")
      };
    }
    function getProgressBarTooltipData(label, column) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'esg';
      var isEsgType = type === 'esg';
      var text = '';
      var marginTop = '';
      switch (column) {
        case 'second':
          text = props.secondVersionText;
          marginTop = isEsgType ? '8px' : '20px';
          break;
        case 'third':
          text = props.thirdVersionText;
          marginTop = isEsgType ? '-4px' : '7px';
          break;
      }
      return {
        color: getDifferenceColor(label, column),
        content: "".concat(text, ": ").concat(percentage(getReferenceResult(label, column)), "%"),
        style: {
          height: isEsgType ? '24px' : '13px',
          marginTop: marginTop
        }
      };
    }
    return {
      headers: headers,
      everyComparisonEsgSectionHasResults: everyComparisonEsgSectionHasResults,
      everyReferenceEsgSectionHasResults: everyReferenceEsgSectionHasResults,
      getComparisonResult: getComparisonResult,
      getReferenceResult: getReferenceResult,
      getDifference: getDifference,
      getDifferenceColor: getDifferenceColor,
      percentageText: percentageText,
      colorClass: colorClass,
      getProgressBarData: getProgressBarData,
      getTargetStyle: getTargetStyle,
      getProgressBarTooltipData: getProgressBarTooltipData
    };
  }
});