import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    value: {
      type: Object,
      required: true
    },
    bookedModules: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      iconMapping: {
        'Company information': {
          active: require('~/assets/icons/company-info/blue.svg/'),
          inactive: require('~/assets/icons/company-info/grey.svg')
        },
        'ESG assessment': {
          active: require('~/assets/icons/esg-assessment/blue.svg'),
          inactive: require('~/assets/icons/esg-assessment/grey.svg')
        },
        'KPI reporting': {
          active: require('~/assets/icons/metrics/blue.svg'),
          inactive: require('~/assets/icons/metrics/grey.svg')
        },
        'Impact goals': {
          active: require('~/assets/icons/impact-goals/blue.svg'),
          inactive: require('~/assets/icons/impact-goals/grey.svg')
        },
        'Impact scoring': {
          active: require('~/assets/icons/impact-goals/blue.svg'),
          inactive: require('~/assets/icons/impact-goals/grey.svg')
        },
        'GHG reduction potential': {
          active: require('~/assets/icons/ghg-emissions/blue.svg/'),
          inactive: require('~/assets/icons/ghg-emissions/grey.svg')
        }
      }
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.value.upcoming || !this.isBooked;
    },
    isBooked: function isBooked() {
      var _this$bookedModules,
        _this = this;
      return (_this$bookedModules = this.bookedModules) === null || _this$bookedModules === void 0 ? void 0 : _this$bookedModules.some(function (m) {
        var _this$value, _this$value$showForMo;
        return (_this$value = _this.value) === null || _this$value === void 0 ? void 0 : (_this$value$showForMo = _this$value.showForModules) === null || _this$value$showForMo === void 0 ? void 0 : _this$value$showForMo.includes(m);
      });
    }
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});