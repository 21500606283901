import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { renderMarkdown } from '~/functions/markdown';
import { useGetUserQuery } from '~/types/types';
export default defineComponent({
  props: {
    insight: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var newInsight = ref();
    newInsight.value = _objectSpread({}, props.insight);
    var annotations = ref();
    annotations.value = newInsight.value.annotations;
    watch(function () {
      return props.insight;
    }, function (selection, _) {
      newInsight.value = selection;
      annotations.value = selection.annotations;
    });
    var _useGetUserQuery = useGetUserQuery(),
      result = _useGetUserQuery.result;
    var email = computed(function () {
      var _result$value$getUser, _result$value, _result$value$getUser2;
      return (_result$value$getUser = result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getUser2 = _result$value.getUser) === null || _result$value$getUser2 === void 0 ? void 0 : _result$value$getUser2.email) !== null && _result$value$getUser !== void 0 ? _result$value$getUser : '';
    });
    return {
      newInsight: newInsight,
      email: email,
      annotations: annotations
    };
  },
  data: function data() {
    return {
      annotationTab: null,
      descriptionLimit: 60,
      select: null,
      showOthers: false,
      // eslint-disable-next-line vue/no-reserved-keys
      _searchTimerId: undefined
    };
  },
  computed: {
    filteredAnnotations: function filteredAnnotations() {
      var _this = this;
      return this.annotations.map(function (e, i) {
        return _objectSpread(_objectSpread({}, e), {}, {
          index: i
        });
      }).filter(function (e) {
        return e.type === 'REVIEW' || e.created_by === _this.email || _this.showOthers;
      });
    }
  },
  methods: {
    renderMarkdown: renderMarkdown,
    highlightConcepts: function highlightConcepts(text) {
      var impactConcepts = [];
      var companyConcepts = [];
      if (this.newInsight.annotations.concepts) {
        impactConcepts = this.newInsight.annotations.concepts.impact_concepts.map(function (e) {
          return e.label;
        });
        companyConcepts = this.newInsight.annotations.concepts.company_concepts.map(function (e) {
          return e.label;
        });
      }
      if (companyConcepts.length > 0) {
        var regexCompany = new RegExp("\\b(".concat(companyConcepts.join('|'), ")\\b"), 'ig');
        text = text.replace(regexCompany, '**$1**');
      }
      if (impactConcepts.length > 0) {
        var regexImpact = new RegExp("\\b(".concat(impactConcepts.join('|'), ")\\b"), 'ig');
        text = text.replace(regexImpact, '**$1**');
      }
      return text;
    },
    handleConceptInput: function handleConceptInput(val) {
      if (!this.newInsight.annotations.concepts.annotation.company_concepts.includes(val)) {
        this.newInsight.annotations.concepts.annotation.company_concepts.push(val);
      }
      this.newInsight.annotations.relations.annotation[val.label] = {
        positive: [],
        negative: []
      };
    },
    handleImpactConceptInput: function handleImpactConceptInput(val) {
      if (!this.newInsight.annotations.concepts.annotation.impact_concepts.includes(val)) {
        this.newInsight.annotations.concepts.annotation.impact_concepts.push(val);
      }
    }
  }
});