import { render, staticRenderFns } from "./CompanySurveyExplanation.vue?vue&type=template&id=ffdbe5b2&scoped=true"
import script from "./CompanySurveyExplanation.vue?vue&type=script&lang=js"
export * from "./CompanySurveyExplanation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffdbe5b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})
