import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var account = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {};
    });
    var tab = ref(0);
    return {
      account: account,
      tab: tab
    };
  }
});