var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('p',[_vm._v("\n    Next, we want to check if your activities are making a substantial\n    contribution while at the same time not causing any significant harm.\n  ")]),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":"Assign alignment check","tooltip":_vm.tooltip}}),_vm._v(" "),(_vm.impactGoal.isEuTaxonomyAlignmentCheckInSync === false)?_c('div',[_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v("\n      Have you finished selecting all activities in the dropdown that apply to\n      you? If yes, click the 'Submit' button and we will assign an EU taxonomy\n      alignment check. If you removed an activity, this will delete the\n      alignment check for this activity with all your answers.\n      "),_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('ix-button-action',{attrs:{"loading":_vm.syncLoading},on:{"click":_vm.triggerSyncEuTaxonomyActivities}},[_vm._v("Submit")])],1)],1)],1)],1):(
      _vm.impactGoal.isEuTaxonomyAlignmentCheckInSync === true &&
      _vm.impactGoal.businessActivities.length === 0
    )?_c('div',[_vm._v("\n    You did not enter any activities yet. If you don't pursue any activities\n    covered by the EU taxonomy you can proceed to the next section, otherwise\n    select one from the dropdown above and then click the submit button.\n  ")]):(
      _vm.impactGoal.isEuTaxonomyAlignmentCheckInSync === true &&
      _vm.impactGoal.businessActivities.length > 0
    )?_c('div',[_vm._v("\n    The alignment check has been created.\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }