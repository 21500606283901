import { render, staticRenderFns } from "./GrowthFactorAssignments.vue?vue&type=template&id=7b12291b"
import script from "./GrowthFactorAssignments.vue?vue&type=script&lang=ts"
export * from "./GrowthFactorAssignments.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputMetricSingleValue: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/metric/SingleValue.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
