import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.search.js";
import { AccountCategory, Module, UsageType, useAddAccountMutation, useGetUserQuery, useListAccountsQuery, useUpdateAccountMutation } from '~/types/types';
export default defineComponent({
  props: {},
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var headers = [{
      value: 'id',
      cellClass: 'font-weight-bold',
      sortable: false
    }, {
      value: 'name',
      cellClass: 'font-weight-bold',
      sortable: true
    }, {
      value: 'type'
    }, {
      value: 'usageType'
    }, {
      value: 'modules',
      cellClass: 'overflow-auto'
    }, {
      value: 'users',
      cellClass: 'overflow-auto'
    }, {
      value: 'createdAt',
      sortable: true
    }, {
      text: $i18n.t('actions'),
      value: 'actions'
    }].map(function (col) {
      return _objectSpread(_objectSpread({}, col), {}, {
        sortable: col.sortable || false
      });
    });
    var options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    });
    var usageTypes = [{
      value: UsageType.Internal,
      text: 'INTERNAL'
    }, {
      value: UsageType.ActualCustomer,
      text: 'ACTUAL_CUSTOMER'
    }, {
      value: UsageType.TrialCustomer,
      text: 'TRIAL_CUSTOMER'
    }, {
      value: UsageType.Other,
      text: 'OTHER'
    }, {
      value: UsageType.PendingReview,
      text: 'PENDING_REVIEW'
    }];
    var filters = ref({
      type: [AccountCategory.Portfolio, AccountCategory.Venture],
      usageType: usageTypes.map(function (e) {
        return e.value;
      })
    });
    var route = useRoute();
    var variables = computed(function () {
      return {
        filters: _objectSpread(_objectSpread({}, filters.value), {}, {
          keyword: route.query.search
        }),
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        sort: options.value.sortBy.map(function (item, index) {
          return {
            field: item,
            sortDesc: options.value.sortDesc[index]
          };
        })
      };
    });
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser) || {};
    });
    var _useListAccountsQuery = useListAccountsQuery(variables),
      result = _useListAccountsQuery.result,
      refetch = _useListAccountsQuery.refetch,
      loading = _useListAccountsQuery.loading;
    var items = computed(function () {
      var _result$value$listAcc, _result$value;
      return (_result$value$listAcc = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listAccounts) !== null && _result$value$listAcc !== void 0 ? _result$value$listAcc : [];
    });
    var itemCount = computed(function () {
      var _result$value$countAc, _result$value2;
      return (_result$value$countAc = (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countAccounts) !== null && _result$value$countAc !== void 0 ? _result$value$countAc : 0;
    });
    var selected = ref([]);
    var editedItem = ref(null);
    var editName = ref('');
    var editDialog = ref(false);
    var _useAddAccountMutatio = useAddAccountMutation(),
      addAccount = _useAddAccountMutatio.mutate;
    var _useUpdateAccountMuta = useUpdateAccountMutation(),
      updateAccount = _useUpdateAccountMuta.mutate,
      onUpdateDone = _useUpdateAccountMuta.onDone;
    onUpdateDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var editItem = function editItem(item) {
      editedItem.value = _objectSpread({}, item);
      editDialog.value = true;
    };
    var email = ref('');
    var password = ref('');
    var close = function close() {
      return editDialog.value = false;
    };
    var userDialog = ref(false);
    var moduleByKey = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Module.Esg, 'ESG'), Module.Metrics, 'KPI'), Module.Sfdr, 'SFDR(FUND)'), Module.Task, 'Task'), Module.Csrd, 'CSRD'), Module.ImpactGoal, 'Impact'), Module.Annotation, 'Annotation'), Module.Impact, 'Deprecated Impact');
    var modules = [{
      value: Module.Esg,
      text: 'ESG'
    }, {
      value: Module.Metrics,
      text: 'KPI'
    }, {
      value: Module.Sfdr,
      text: 'SFDR(FUND)'
    }, {
      value: Module.Csrd,
      text: 'CSRD'
    }, {
      value: Module.Task,
      text: 'Task'
    }, {
      value: Module.ImpactGoal,
      text: 'Impact'
    }];
    var selectedUsers = ref([]);
    var impersonate = function impersonate(id) {
      sessionStorage.setItem('impersonation_user_id', id);
      window.location.href = '/';
    };
    return {
      impersonate: impersonate,
      selectedUsers: selectedUsers,
      filters: filters,
      refetch: refetch,
      AccountCategory: AccountCategory,
      moduleByKey: moduleByKey,
      email: email,
      password: password,
      updateAccount: updateAccount,
      modules: modules,
      usageTypes: usageTypes,
      options: options,
      close: close,
      addAccount: addAccount,
      editDialog: editDialog,
      editItem: editItem,
      itemCount: itemCount,
      headers: headers,
      selected: selected,
      items: items,
      loading: loading,
      editedItem: editedItem,
      user: user,
      userDialog: userDialog,
      editName: editName
    };
  }
});