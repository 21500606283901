import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export default defineNuxtPlugin(function (_ref) {
  var $auth = _ref.$auth,
    $axios = _ref.$axios;
  $axios.onRequest(function (config) {
    if (config.url !== 'https://impactnexus.eu.auth0.com/oauth/token') {
      // we should not add any common when refresh token
      if (!config.headers.common) {
        config.headers.common = {};
      }
    } // patch $axios's bug
    var token = $auth.strategy.token.get();
    if (token) {
      // TODO we might want to add domain check here so we won't leak tokens to other domains
      config.headers.Authorization = "Bearer ".concat(token);
    }
  }); // FIXME this is a ugly patch to make sure @nuxtjs/auth works, it apparently uses an old version of axios
  if (!$auth.loggedIn) {
    return;
  }
  $axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    window.$nuxt.$emit('userError', _objectSpread({
      color: 'red'
    }, error));
  });
});