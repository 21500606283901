import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3d20bce9"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxBtnAddValueChain: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddValueChain.vue').default,IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default,IxDialogDelValueChain: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/DelValueChain.vue').default,IxBtnAddActivity: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddActivity.vue').default,IxCardActivity: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/card/Activity.vue').default,IxCardIro: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/card/Iro.vue').default,IxBtnAddIro: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddIro.vue').default,IxDialogDelStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/DelStakeholder.vue').default,IxBtnAddStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddStakeholder.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VDivider,VIcon,VRow})
