import { render, staticRenderFns } from "./precontractual.vue?vue&type=template&id=53d6ab2e&scoped=true"
import script from "./precontractual.vue?vue&type=script&lang=ts"
export * from "./precontractual.vue?vue&type=script&lang=ts"
import style0 from "./precontractual.vue?vue&type=style&index=0&id=53d6ab2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d6ab2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default,IxTextField: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/TextField.vue').default,IxSurveyQuestionGroup: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/QuestionGroup.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default,IxInfoCopyable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/info/Copyable.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default,IxFormInputSmallOptions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/SmallOptions.vue').default,GChart: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/GChart.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VProgressLinear,VRow,VSimpleCheckbox,VTextField,VTreeview})
