import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find.js";
import { useGetUserQuery, MetricValueKind, useGetQuestionnaireV2Query, useAddQuestionnaireSectionLinkMutation, useDelQuestionnaireSectionLinkMutation } from '~/types/types';
export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser) || {
        locale: 'en-US',
        isAdmin: false
      };
    });
    var variable = computed(function () {
      return {
        id: props.id || 0
      };
    });
    var _useGetQuestionnaireV = useGetQuestionnaireV2Query(variable),
      result = _useGetQuestionnaireV.result,
      loading = _useGetQuestionnaireV.loading,
      refetch = _useGetQuestionnaireV.refetch;
    var sections = computed(function () {
      var _result$value, _result$value$getQues;
      return result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getQues = _result$value.getQuestionnaire) === null || _result$value$getQues === void 0 ? void 0 : _result$value$getQues.sections;
    });
    var active = ref([]);
    var selected = computed(function () {
      var _sections$value;
      if (!active.value.length) return undefined;
      var selectedId = active.value[0];
      var flatSections = (sections === null || sections === void 0 ? void 0 : (_sections$value = sections.value) === null || _sections$value === void 0 ? void 0 : _sections$value.reduce(function (merged, section) {
        return [].concat(_toConsumableArray(merged), [section], _toConsumableArray(section.sections));
      }, [])) || [];
      return flatSections.find(function (_ref) {
        var id = _ref.id;
        return selectedId === id;
      });
    });
    var _useAddQuestionnaireS = useAddQuestionnaireSectionLinkMutation(),
      addLink = _useAddQuestionnaireS.mutate;
    var _useDelQuestionnaireS = useDelQuestionnaireSectionLinkMutation(),
      delLink = _useDelQuestionnaireS.mutate;
    var searchModal = ref(false); // modal used to add children KPIs
    return {
      delLink: delLink,
      questionnaire: computed(function () {
        var _result$value2;
        return ((_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.getQuestionnaire) || {};
      }),
      loading: loading,
      addLink: addLink,
      active: active,
      refetch: refetch,
      searchModal: searchModal,
      user: user,
      sections: sections,
      MetricValueKind: MetricValueKind,
      selected: selected
    };
  }
});