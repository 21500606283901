import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.user.isAdmin ? _c('div', [_c('ix-header-portfolio'), _vm._v(" "), _c('v-expansion-panels', {
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("\n        " + _vm._s(_vm.$t('esgTemplate')) + "\n      ")]), _vm._v(" "), _c('v-expansion-panel-content', [_c('ix-esg-questions-edit', {
    attrs: {
      "sections": _vm.esgSectionsV2,
      "loading": _vm.loading
    }
  })], 1)], 1), _vm._v(" "), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("\n        " + _vm._s(_vm.$t('questionnairesForCompanies')) + "\n      ")]), _vm._v(" "), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "flex-grow-1",
    attrs: {
      "multi-sort": "",
      "fixed-header": "",
      "height": 'calc(100vh - 210px)',
      "locale": _vm.$i18n.locale,
      "loading": _vm.loading,
      "items": _vm.items,
      "headers": _vm.headers.map(function (h) {
        return _objectSpread(_objectSpread({}, h), {}, {
          text: _vm.$t(h.value)
        });
      }),
      "options": _vm.options,
      "server-items-length": _vm.itemCount,
      "sort-by": "name",
      "footer-props": {
        'items-per-page-options': [15, 30, 50, -1]
      },
      "items-per-page": _vm.options.itemsPerPage,
      "item-key": "id",
      "show-select": "",
      "show-expand": false
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header.venture.name",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_vm._v("\n            " + _vm._s(header.text) + "\n            "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [_vm.filters.ventures && _vm.filters.ventures.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _vm.filters.ventures.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('ix-data-table-accounts', {
          attrs: {
            "items-per-page": 10
          },
          model: {
            value: _vm.filters.ventures,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "ventures", $$v);
            },
            expression: "filters.ventures"
          }
        })], 1)];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('v-text-field', {
          staticClass: "mx-4",
          attrs: {
            "disabled": "",
            "append-icon": "mdi-magnify",
            "label": "Search",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.filters.label,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "label", $$v);
            },
            expression: "filters.label"
          }
        }), _vm._v(" "), _c('v-dialog', {
          attrs: {
            "value": _vm.editItemId != 0,
            "max-width": "80%"
          },
          on: {
            "input": function input(v) {
              if (v === false) {
                _vm.editItemId = 0;
              }
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "dark": ""
                },
                on: {
                  "click": function click() {
                    _vm.editItemId = -1;
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v("\n                    " + _vm._s(_vm.$t('add')) + "\n                  ")])];
            }
          }], null, false, 611978923)
        }, [_vm._v(" "), _c('ix-esg-edit', {
          attrs: {
            "id": _vm.editItemId
          },
          on: {
            "input": function input(v) {
              return _vm.editItemId = v;
            }
          }
        })], 1), _vm._v(" "), _c('v-btn', {
          staticClass: "mb-2",
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _vm.refetch
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t('refresh')) + "\n              ")]), _vm._v(" "), _c('v-dialog', {
          attrs: {
            "value": false,
            "max-width": "500px"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v("\n                    Are you sure you want to delete this item?\n                  ")]), _vm._v(" "), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.closeDelete
          }
        }, [_vm._v("\n                      Cancel\n                    ")]), _vm._v(" "), _c('v-btn', {
          attrs: {
            "disabled": "",
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.deleteItemConfirm
          }
        }, [_vm._v("\n                      OK\n                    ")]), _vm._v(" "), _c('v-spacer')], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(_vm._s(item.id))];
      }
    }, {
      key: "item.sections",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return _vm._l(item.sections || [], function (section) {
          return _c('v-chip', {
            key: section.id,
            attrs: {
              "outlined": "",
              "label": ""
            },
            on: {
              "click": function click() {
                _vm.editItemId = item.id;
              }
            }
          }, [_vm._v("\n              " + _vm._s(section.label) + "(" + _vm._s(section.id) + ")\n            ")]);
        });
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-layout', {
          staticClass: "flex-nowrap pa-4",
          attrs: {
            "row": ""
          }
        }, [item.external_url !== undefined ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                attrs = _ref7.attrs;
              return [_c('ix-button-white', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "icon": "",
                  "href": item.external_url,
                  "target": "_blank"
                }
              }, 'ix-button-white', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": "blue-grey"
                }
              }, [_vm._v("mdi-information-variant\n                    ")])], 1)];
            }
          }], null, true)
        }, [_vm._v("\n                Go to detailed information at " + _vm._s(item.source.name) + ".\n              ")]) : _vm._e(), _vm._v(" "), _c('v-btn', {
          staticClass: "mb-2 custom-border-box",
          attrs: {
            "outlined": "",
            "icon": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-close ")])], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "disabled": "",
            "small": ""
          },
          on: {
            "click": function click() {
              _vm.editItemId = item.id;
            }
          }
        }, [_vm._v("\n              mdi-pencil\n            ")]), _vm._v(" "), _c('v-icon', {
          attrs: {
            "small": "",
            "disabled": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v("\n              mdi-delete\n            ")])];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1), _vm._v(" "), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("\n        " + _vm._s(_vm.$t('measures')) + "\n      ")]), _vm._v(" "), _c('v-expansion-panel-content', [_c('ix-esg-measures')], 1)], 1)], 1)], 1) : !_vm.loading ? _c('div', [_vm._v("404")]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };