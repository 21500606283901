import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find-index.js";
import { defineComponent } from '@vue/composition-api';
import { MetricReportingPeriodType } from '~/types/types';
export default defineComponent({
  props: {
    type: {
      type: String,
      required: true
    },
    metric: {
      type: Object,
      required: true
    },
    metricValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    inputs: {
      type: Array,
      required: true
    },
    skipped: {
      type: Boolean,
      default: false
    },
    isFrozen: {
      type: Boolean,
      default: false
    },
    clearTargets: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    reportingPeriodType: {
      type: String,
      required: true
    }
  },
  setup: function setup(props, context) {
    watch(function () {
      return props.metricValues;
    }, function (newValue) {
      return filteredMetricValues.value = filterForReportingPeriodTypeAndSort(newValue);
    });
    var createInputMap = function createInputMap(attribute) {
      // use metricValues as base and update with uncommitted data from inputs
      var inputMap = new Map();
      var toCheck = [].concat(_toConsumableArray(props.metricValues), _toConsumableArray(props.inputs));
      toCheck.forEach(function (e) {
        var key = "".concat(e.metricAssignmentId, "-").concat(e.period);
        var val = {
          metricAssignmentId: e.metricAssignmentId,
          period: e.period
        };
        val[attribute] = e[attribute];
        inputMap.set(key, val);
      });
      return inputMap;
    };
    watch(function () {
      return props.skipped;
    }, function (doSkip) {
      var inputMap = createInputMap('skipped');
      inputMap.forEach(function (val, key) {
        if (val.skipped === doSkip) {
          inputMap.delete(key); // no update necessary
        } else {
          val.skipped = doSkip;
          if (doSkip) {
            var toClear = {
              actual: null,
              text: null,
              options: null
            };
            val = _objectSpread(_objectSpread({}, val), toClear);
          }
          inputMap.set(key, val);
        }
      });
      onInput(Array.from(inputMap.values()));
    });
    watch(function () {
      return props.clearTargets;
    }, function (doClear) {
      if (!doClear) return;
      var inputMap = createInputMap('target');
      inputMap.forEach(function (val, key) {
        if (val.target === null) {
          inputMap.delete(key); // no update necessary
        } else {
          val = _objectSpread(_objectSpread({}, val), {}, {
            target: null
          });
          inputMap.set(key, val);
        }
      });
      onInput(Array.from(inputMap.values()));
    });
    var filterForReportingPeriodTypeAndSort = function filterForReportingPeriodTypeAndSort(metricValues) {
      var _metricValues$filter;
      return (metricValues === null || metricValues === void 0 ? void 0 : (_metricValues$filter = metricValues.filter(function (val) {
        return val.period.includes(props.reportingPeriodType === MetricReportingPeriodType.Quarterly ? 'Q' : 'M');
      })) === null || _metricValues$filter === void 0 ? void 0 : _metricValues$filter.sort(function (a, b) {
        if (props.reportingPeriodType === MetricReportingPeriodType.Monthly) {
          return parseInt(a.period.split('M')[1]) - parseInt(b.period.split('M')[1]);
        }
        return a.period.localeCompare(b.period);
      })) || [];
    };
    var filteredMetricValues = ref(filterForReportingPeriodTypeAndSort(props.metricValues));
    var onInput = function onInput(vals) {
      var newInputs = _toConsumableArray(props.inputs);
      var _iterator = _createForOfIteratorHelper(vals),
        _step;
      try {
        var _loop = function _loop() {
          var val = _step.value;
          // When ever the users make a change, we store it in the local state so cancelling the MetricValueDialog properly resets the form
          filteredMetricValues.value = filteredMetricValues.value.map(function (item) {
            return item.metricAssignmentId === val.metricAssignmentId && item.period === val.period ? _objectSpread(_objectSpread({}, item), val) : item;
          });

          // update inputs
          var index = newInputs.findIndex(function (i) {
            return i.metricAssignmentId === val.metricAssignmentId && i.period === val.period;
          });
          var toAdd;
          if (index > -1) {
            toAdd = _objectSpread(_objectSpread({}, newInputs[index]), val);
            newInputs.splice(index, 1); // remove the obj the value
          } else {
            toAdd = val;
          }
          newInputs.push(toAdd);
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      context.emit('input', newInputs);
    };
    var periodToLabel = function periodToLabel(period) {
      return {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Otc',
        '11': 'Nov',
        '12': 'Dec'
      }[period.split('M')[1]];
    };
    var disabled = computed(function () {
      return props.skipped || props.isFrozen;
    });
    return {
      onInput: onInput,
      MetricReportingPeriodType: MetricReportingPeriodType,
      filteredMetricValues: filteredMetricValues,
      periodToLabel: periodToLabel,
      disabled: disabled
    };
  }
});