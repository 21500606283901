import {
  handleUndefined,
  handleUndefinedArray,
  horizontalDividerSvg,
} from '../common'

export const handleCompanyInfo = (companyInfo: any) => {
  return [
    {
      text: handleUndefined(companyInfo?.ventureName),
      style: ['ventureName'],
      width: 'auto',
    },
    {
      margin: [-5, 10, 0, 0],
      columns: [
        [
          {
            text: handleUndefined(companyInfo?.website),
            link: handleUndefined(companyInfo?.website),
            style: ['website'],
            width: 300,
          },
          {
            text: handleUndefined(companyInfo?.pitchLine),
            style: ['pitchLine'],
            margin: [0, 5, 0, 10],
            width: 350,
          },
        ],
        [
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 12V14.0156H13.9844V12H16ZM16 8.01562V9.98438H13.9844V8.01562H16ZM18.0156 15.9844V6H10V8.01562H12.0156V9.98438H10V12H12.0156V14.0156H10V15.9844H18.0156ZM7.98438 3.98438V2.01562H6.01562V3.98438H7.98438ZM7.98438 8.01562V6H6.01562V8.01562H7.98438ZM7.98438 12V9.98438H6.01562V12H7.98438ZM7.98438 15.9844V14.0156H6.01562V15.9844H7.98438ZM4 3.98438V2.01562H1.98438V3.98438H4ZM4 8.01562V6H1.98438V8.01562H4ZM4 12V9.98438H1.98438V12H4ZM4 15.9844V14.0156H1.98438V15.9844H4ZM10 3.98438H19.9844V18H0.015625V0H10V3.98438Z" fill="#8FA5AF"/>
                </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.industries?.length
                  ? handleUndefinedArray(companyInfo?.industries).join(', ')
                  : 'N/A',
                style: ['industries'],
                width: 270,
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6094 16.6094C16.0156 16.2031 16.4844 16 17.0156 16C17.5469 16 18 16.2031 18.375 16.6094C18.7812 17.0156 18.9844 17.4844 18.9844 18.0156C18.9844 18.5469 18.7812 19.0156 18.375 19.4219C18 19.7969 17.5469 19.9844 17.0156 19.9844C16.4844 19.9844 16.0156 19.7969 15.6094 19.4219C15.2031 19.0156 15 18.5469 15 18.0156C15 17.4844 15.2031 17.0156 15.6094 16.6094ZM0.984375 0.015625H4.26562L5.20312 1.98438H20.0156C20.2969 1.98438 20.5312 2.09375 20.7188 2.3125C20.9062 2.5 21 2.73438 21 3.01562C21 3.04688 20.9531 3.20312 20.8594 3.48438L17.2969 9.95312C16.9219 10.6406 16.3438 10.9844 15.5625 10.9844H8.10938L7.21875 12.625L7.17188 12.7656C7.17188 12.9219 7.25 13 7.40625 13H18.9844V15.0156H6.98438C6.45312 15.0156 5.98438 14.8125 5.57812 14.4062C5.20312 14 5.01562 13.5312 5.01562 13C5.01562 12.6875 5.09375 12.375 5.25 12.0625L6.60938 9.57812L3 1.98438H0.984375V0.015625ZM5.57812 16.6094C5.98438 16.2031 6.45312 16 6.98438 16C7.51562 16 7.98438 16.2031 8.39062 16.6094C8.79688 17.0156 9 17.4844 9 18.0156C9 18.5469 8.79688 19.0156 8.39062 19.4219C7.98438 19.7969 7.51562 19.9844 6.98438 19.9844C6.45312 19.9844 5.98438 19.7969 5.57812 19.4219C5.20312 19.0156 5.01562 18.5469 5.01562 18.0156C5.01562 17.4844 5.20312 17.0156 5.57812 16.6094Z" fill="#8FA5AF"/>
                </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.products?.length
                  ? handleUndefinedArray(companyInfo?.products).join(', ')
                  : 'N/A',
                style: ['products'],
                width: 270,
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 11.5C16 10.12 17.12 9 18.5 9C19.88 9 21 10.12 21 11.5C21 12.88 19.88 14 18.5 14C17.12 14 16 12.88 16 11.5ZM13 3V20H24V3H13ZM22 16C20.9 16 20 16.9 20 18H17C17 16.9 16.11 16 15 16V7C16.11 7 17 6.11 17 5H20C20 6.11 20.9 7 22 7V16ZM7 6C8.1 6 9 6.9 9 8C9 9.1 8.1 10 7 10C5.9 10 5 9.1 5 8C5 6.9 5.9 6 7 6ZM7 4C4.79 4 3 5.79 3 8C3 10.21 4.79 12 7 12C9.21 12 11 10.21 11 8C11 5.79 9.21 4 7 4ZM7 14C3.13 14 0 15.79 0 18V20H11V18H2C2 17.42 3.75 16 7 16C8.83 16 10.17 16.45 11 16.95V14.72C9.87 14.27 8.5 14 7 14Z" fill="#8FA5AF"/>
                </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.incomeStream?.length
                  ? handleUndefinedArray(companyInfo?.incomeStream).join(', ')
                  : 'N/A',
                style: ['incomeStream'],
                width: 270,
              },
            ],
          },
        ],
        [
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.21875 8.78125C5.71875 9.25 6.3125 9.48438 7 9.48438C7.6875 9.48438 8.26562 9.25 8.73438 8.78125C9.23438 8.28125 9.48438 7.6875 9.48438 7C9.48438 6.3125 9.23438 5.73438 8.73438 5.26562C8.26562 4.76562 7.6875 4.51562 7 4.51562C6.3125 4.51562 5.71875 4.76562 5.21875 5.26562C4.75 5.73438 4.51562 6.3125 4.51562 7C4.51562 7.6875 4.75 8.28125 5.21875 8.78125ZM2.03125 2.07812C3.40625 0.703125 5.0625 0.015625 7 0.015625C8.9375 0.015625 10.5781 0.703125 11.9219 2.07812C13.2969 3.42188 13.9844 5.0625 13.9844 7C13.9844 7.96875 13.7344 9.07812 13.2344 10.3281C12.7656 11.5781 12.1875 12.75 11.5 13.8438C10.8125 14.9375 10.125 15.9688 9.4375 16.9375C8.78125 17.875 8.21875 18.625 7.75 19.1875L7 19.9844C6.8125 19.7656 6.5625 19.4844 6.25 19.1406C5.9375 18.7656 5.375 18.0469 4.5625 16.9844C3.75 15.8906 3.03125 14.8438 2.40625 13.8438C1.8125 12.8125 1.26562 11.6562 0.765625 10.375C0.265625 9.09375 0.015625 7.96875 0.015625 7C0.015625 5.0625 0.6875 3.42188 2.03125 2.07812Z" fill="#8FA5AF"/>
                </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.headquarter
                  ? handleUndefinedArray(companyInfo?.headquarter)
                  : 'N/A',
                style: ['headquarter'],
                width: 55,
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9844 7.98438C16.8594 7.98438 17.8125 8.10938 18.8438 8.35938C19.875 8.60938 20.8281 9.01562 21.7031 9.57812C22.5781 10.1406 23.0156 10.7812 23.0156 11.5V13.9844H17.0156V11.5C17.0156 10.125 16.3594 8.96875 15.0469 8.03125C15.2656 8 15.5781 7.98438 15.9844 7.98438ZM5.15625 8.35938C6.1875 8.10938 7.14062 7.98438 8.01562 7.98438C8.89062 7.98438 9.84375 8.10938 10.875 8.35938C11.9062 8.60938 12.8438 9.01562 13.6875 9.57812C14.5625 10.1406 15 10.7812 15 11.5V13.9844H0.984375V11.5C0.984375 10.7812 1.42188 10.1406 2.29688 9.57812C3.17188 9.01562 4.125 8.60938 5.15625 8.35938ZM10.0781 5.125C9.51562 5.71875 8.82812 6.01562 8.01562 6.01562C7.20312 6.01562 6.5 5.71875 5.90625 5.125C5.3125 4.53125 5.01562 3.82812 5.01562 3.01562C5.01562 2.20313 5.3125 1.5 5.90625 0.90625C6.5 0.3125 7.20312 0.015625 8.01562 0.015625C8.82812 0.015625 9.51562 0.3125 10.0781 0.90625C10.6719 1.5 10.9688 2.20313 10.9688 3.01562C10.9688 3.82812 10.6719 4.53125 10.0781 5.125ZM18.0938 5.125C17.5 5.71875 16.7969 6.01562 15.9844 6.01562C15.1719 6.01562 14.4688 5.71875 13.875 5.125C13.2812 4.53125 12.9844 3.82812 12.9844 3.01562C12.9844 2.20313 13.2812 1.5 13.875 0.90625C14.4688 0.3125 15.1719 0.015625 15.9844 0.015625C16.7969 0.015625 17.5 0.3125 18.0938 0.90625C18.6875 1.5 18.9844 2.20313 18.9844 3.01562C18.9844 3.82812 18.6875 4.53125 18.0938 5.125Z" fill="#8FA5AF"/>
                  </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.employeeHeadcount
                  ? handleUndefined(companyInfo?.employeeHeadcount)
                  : 'N/A',
                style: ['employeeHeadcount'],
                width: 55,
              },
            ],
          },
          {
            margin: [0, 0, 0, 10],
            columns: [
              {
                svg: `<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.8125 7.92188C6.5 8.10938 7.0625 8.29688 7.5 8.48438C7.96875 8.67188 8.45312 8.9375 8.95312 9.28125C9.45312 9.59375 9.82812 10 10.0781 10.5C10.3594 11 10.5 11.5938 10.5 12.2812C10.5 13.25 10.1719 14.0469 9.51562 14.6719C8.89062 15.2656 8.04688 15.6562 6.98438 15.8438V18H3.98438V15.8438C2.95312 15.625 2.09375 15.1875 1.40625 14.5312C0.75 13.875 0.390625 13.0312 0.328125 12H2.53125C2.65625 13.4062 3.64062 14.1094 5.48438 14.1094C6.45312 14.1094 7.14062 13.9375 7.54688 13.5938C7.98438 13.2188 8.20312 12.7969 8.20312 12.3281C8.20312 11.2031 7.20312 10.3906 5.20312 9.89062C2.07812 9.17188 0.515625 7.79688 0.515625 5.76562C0.515625 4.85938 0.84375 4.09375 1.5 3.46875C2.15625 2.8125 2.98438 2.375 3.98438 2.15625V0H6.98438V2.20312C8.01562 2.45312 8.79688 2.92187 9.32812 3.60938C9.89062 4.29688 10.1875 5.09375 10.2188 6H8.01562C7.95312 4.59375 7.10938 3.89062 5.48438 3.89062C4.67188 3.89062 4.01562 4.0625 3.51562 4.40625C3.04688 4.75 2.8125 5.20312 2.8125 5.76562C2.8125 6.67188 3.8125 7.39062 5.8125 7.92188Z" fill="#8FA5AF"/>
                  </svg>`,
                width: 15,
                height: 15,
                margin: [10, 0, 0, 0],
              },
              {
                text: companyInfo?.fundingStage
                  ? handleUndefined(companyInfo?.fundingStage)
                  : 'N/A',
                style: ['fundingStage'],
                width: 55,
              },
            ],
          },
        ],
      ],
    },
    {
      svg: horizontalDividerSvg(30),
      width: 770,
      margin: [-5, 0, 0, 0],
    },
  ]
}

export const companyInfoStyle = {
  pitchLine: {
    fontSize: 10,
    alignment: 'left',
    color: '#5B7185',
  },
  industries: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 2, 0, 0],
  },
  incomeStream: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 0, 0, 0],
  },
  ventureName: {
    color: '#5188ff ',
    bold: true,
    fontSize: 30,
    margin: [-7, -10, 10, 0],
  },
  website: {
    color: '#5188ff ',
    bold: false,
    fontSize: 10,
  },
  products: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 0, 0, 0],
  },
  headquarter: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 2, 0, 0],
  },
  employeeHeadcount: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 2, 0, 0],
  },
  fundingStage: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-10, 2, 0, 0],
  },
}
