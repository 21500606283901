import { renderMarkdown } from '~/functions/markdown';
import { dateRange, impactSolutionMetricValues, totalGhgSavingsOfImpactSolution } from '~/functions/ghg';
export default defineComponent({
  name: 'ImpactSolutionDetail',
  props: {
    impact: {
      type: Object,
      default: null
    },
    impactName: {
      type: String,
      default: ''
    },
    solution: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      renderMarkdown: renderMarkdown
    };
  },
  computed: {
    kind: function kind() {
      var _this$solution;
      return (_this$solution = this.solution) === null || _this$solution === void 0 ? void 0 : _this$solution.kind;
    },
    kindIcon: function kindIcon() {
      var _this$solution2;
      var iconMap = {
        HYBRID: 'mdi-sync',
        PRODUCT: 'mdi-cube-outline',
        SERVICE: 'mdi-account-cog-outline'
      };
      var kind = (_this$solution2 = this.solution) === null || _this$solution2 === void 0 ? void 0 : _this$solution2.kind;
      return this.kind ? iconMap[this.kind] : '';
    },
    kindText: function kindText() {
      return this.kind ? this.$t('impactGoal.overview.ourSolutions.' + this.kind) : '';
    },
    impactProfile: function impactProfile() {
      var _this$solution3, _this$solution3$ghgSa;
      return (_this$solution3 = this.solution) === null || _this$solution3 === void 0 ? void 0 : (_this$solution3$ghgSa = _this$solution3.ghgSavingPotential) === null || _this$solution3$ghgSa === void 0 ? void 0 : _this$solution3$ghgSa.impactProfileKind;
    },
    // impactProfileIcon() {
    //   const iconMap = {
    //     PRODUCT_APPLICATION: 'mdi-wrench',
    //     CIRCULAR_ECONOMY: 'mdi-all-inclusive',
    //     EXTRACTION_AND_MANUFACTURING: 'mdi-nut',
    //     LOGISTICS: 'mdi-car-pickup',
    //     CUSTOMER_EMPOWERMENT: 'mdi-compass-outline',
    //   }
    //   return this.impactProfile ? iconMap[this.impactProfile] : ''
    // },
    impactProfileText: function impactProfileText() {
      return this.impactProfile ? this.$t('impactGoal.overview.ourSolutions.' + this.impactProfile) : '';
    },
    kindAndProfileText: function kindAndProfileText() {
      return this.kindText + (this.impactProfile ? " / ".concat(this.impactProfileText) : '');
    },
    plannedSavingsText: function plannedSavingsText() {
      var metricValues = impactSolutionMetricValues(this.solution, this.impact);
      var range = dateRange(metricValues);
      return this.solution.ghgSavingPotential ? this.$t('impactGoal.overview.ourSolutions.plannedSavings', {
        savingsAmount: totalGhgSavingsOfImpactSolution(metricValues, 'DE-de'),
        startYear: range.at(0),
        endYear: range.at(-1)
      }) : '';
    },
    solutionsInfoRow: function solutionsInfoRow() {
      var _this$solution4;
      return [{
        icon: this.kindIcon,
        text: this.kindAndProfileText
      }, {
        icon: 'mdi-account-group-outline',
        text: ((_this$solution4 = this.solution) === null || _this$solution4 === void 0 ? void 0 : _this$solution4.targetGroup) || 'N/A'
      }, {
        icon: 'mdi-bullseye-arrow',
        text: this.impactName
      }, {
        icon: 'mdi-earth',
        text: this.plannedSavingsText,
        hideItem: !this.solution.ghgSavingPotential
      }];
    }
  }
});