import { render, staticRenderFns } from "./RecommendedActivitiesInput.vue?vue&type=template&id=a170ae52"
import script from "./RecommendedActivitiesInput.vue?vue&type=script&lang=ts"
export * from "./RecommendedActivitiesInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonPrimary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Primary.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCol,VDataTable,VIcon,VRow,VSpacer})
