import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { downloadCSV } from '~/functions/export';
import { FileFormat, useExportKfwLazyQuery, useListFundsQuery } from '~/types/types';
export default defineComponent({
  props: {
    isPortfolioExport: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(_, _ref) {
    var _route$params;
    var emit = _ref.emit;
    var route = useRoute();
    var _useListFundsQuery = useListFundsQuery(),
      result = _useListFundsQuery.result,
      listFundsLoading = _useListFundsQuery.loading;
    var funds = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listFunds) || [];
    });
    var periods = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    var lastYear = (new Date().getFullYear() - 1).toString();
    var initialInput = {
      period: lastYear,
      exportGp: false,
      exportFundIds: [],
      exportCompanyIds: route !== null && route !== void 0 && (_route$params = route.params) !== null && _route$params !== void 0 && _route$params.venture ? [route === null || route === void 0 ? void 0 : route.params.venture] : []
    };
    var input = ref(_objectSpread({}, initialInput));
    var _useExportKfwLazyQuer = useExportKfwLazyQuery({
        input: input.value
      }),
      load = _useExportKfwLazyQuer.load,
      exportKfwLoading = _useExportKfwLazyQuer.loading,
      refetch = _useExportKfwLazyQuer.refetch;
    function downloadAndFinish(data, toggleDialogCallback) {
      var files = data === null || data === void 0 ? void 0 : data.exportKfw.files;
      files === null || files === void 0 ? void 0 : files.forEach(function (file) {
        if (file && file.format === FileFormat.Csv) {
          downloadCSV(file.base64String, file.fileName);
        }
      });
      toggleDialogCallback();
      emit('finish');
      input.value = _objectSpread({}, initialInput);
    }
    function triggerExport(toggleDialogCallback) {
      var _refetch;
      var loadResult = load(null, {
        input: input.value
      });
      if (loadResult instanceof Promise) loadResult.then(function (res) {
        downloadAndFinish(res, toggleDialogCallback);
      });else if (loadResult === false) (_refetch = refetch({
        input: input.value
      })) === null || _refetch === void 0 ? void 0 : _refetch.then(function (res) {
        return downloadAndFinish(res === null || res === void 0 ? void 0 : res.data, toggleDialogCallback);
      });
    }
    return {
      input: input,
      triggerExport: triggerExport,
      listFundsLoading: listFundsLoading,
      exportKfwLoading: exportKfwLoading,
      periods: periods,
      funds: funds
    };
  },
  data: function data() {
    return {};
  }
});