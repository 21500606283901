import "core-js/modules/es6.array.filter.js";
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  computed: {
    impactsWithAlignmentQuestionnaires: function impactsWithAlignmentQuestionnaires() {
      var _this$impactGoal, _this$impactGoal$impa;
      return ((_this$impactGoal = this.impactGoal) === null || _this$impactGoal === void 0 ? void 0 : (_this$impactGoal$impa = _this$impactGoal.impacts) === null || _this$impactGoal$impa === void 0 ? void 0 : _this$impactGoal$impa.filter(function (e) {
        return e.euTaxonomyAlignmentQuestionnaires.length !== 0;
      })) || [];
    }
  }
});