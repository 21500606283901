var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.toEditEmissionSource)?_c('ix-section',{staticClass:"h-full"},[_c('v-col',[_c('ix-section-title',{staticClass:"flex justify-between items-center"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.toEditEmissionSource.sourceOfEmission)+" -\n        "+_vm._s(_vm.$t(
            'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.title',
          ))+"\n      ")])]),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.guidance',
              ))+"\n          ")])])],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{staticClass:"mt-0 ml-8",attrs:{"true-value":true,"false-value":false,"label":_vm.$t(
                        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.consumptionCheckBoxLabel',
                      )},model:{value:(_vm.emissionSource.solutionReducesConsumption),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionReducesConsumption", $$v)},expression:"emissionSource.solutionReducesConsumption"}}),_vm._v(" "),_c('v-checkbox',{staticClass:"mt-0 ml-8",attrs:{"true-value":true,"false-value":false,"label":_vm.$t(
                        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.ghgCheckBoxLabel',
                      )},model:{value:(_vm.emissionSource.solutionReducesGhgFactor),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionReducesGhgFactor", $$v)},expression:"emissionSource.solutionReducesGhgFactor"}}),_vm._v(" "),_c('v-checkbox',{staticClass:"mt-0 ml-8",attrs:{"true-value":true,"false-value":false,"label":_vm.$t(
                        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.bothCheckBoxLabel',
                      )},model:{value:(_vm.bothCheckbox),callback:function ($$v) {_vm.bothCheckbox=$$v},expression:"bothCheckbox"}})],1)])],1)],1)])],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('ix-textarea',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.howSolutionWorksLabel',
              )},model:{value:(_vm.emissionSource.howSolutionWorks),callback:function ($$v) {_vm.$set(_vm.emissionSource, "howSolutionWorks", $$v)},expression:"emissionSource.howSolutionWorks"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.assumptionTitle',
              ))+"\n          ")])])],1),_vm._v(" "),_c('v-row',{staticClass:"row clickable-texts"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.emissionSource.solutionReducesGhgFactor)?_c('v-dialog',{attrs:{"max-width":"1500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer",attrs:{"role":"button"}},'span',attrs,false),on),[_vm._v("\n                "+_vm._s(_vm.$t(
                    'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.ownGhgText',
                  ))+"\n              ")]),_vm._v("\n              "+_vm._s(' '.repeat(4)))]}},{key:"default",fn:function(dialog){return [_c('custom-comparison-modal',{attrs:{"initial-data":_vm.toEditEmissionSource,"solution":true},on:{"selectItem":(val) => {
                    _vm.handleCustomSelectedSolutionFactor(val, dialog)
                  },"closeModal":() => {
                    dialog.value = false
                  }}})]}}],null,false,1325171072)}):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"2"}},[(_vm.emissionSource.solutionReducesGhgFactor)?_c('idemat-comparison-modal',{on:{"selectItem":_vm.handleSelectedSolutionFactor}}):_vm._e()],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.conceptLabel',
              ),"disabled":""},model:{value:(_vm.emissionSource.solutionConceptName),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionConceptName", $$v)},expression:"emissionSource.solutionConceptName"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"value":_vm.getFactorText(_vm.emissionSource),"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.ghgFactorLabel',
              ),"disabled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--v-primary-base)"}},'v-icon',attrs,false),on),[_vm._v("\n                    mdi-information\n                  ")])]}}],null,false,240782798)},[_vm._v(" "),_c('div',{staticStyle:{"max-width":"400px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.renderMarkdown(
                        _vm.$t(
                          `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.emissionSourceFormsTooltips.ghgFactorTooltip`,
                        ),
                      )
                    )}})])])]},proxy:true}],null,false,2234312161)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.customEvidenceLabel',
              ),"disabled":""},model:{value:(_vm.emissionSource.solutionEvidence),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionEvidence", $$v)},expression:"emissionSource.solutionEvidence"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.consumptionAmountLabel',
              ),"rules":[_vm.rules.required],"disabled":_vm.amountDisabled,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--v-primary-base)"}},'v-icon',attrs,false),on),[_vm._v("\n                    mdi-information\n                  ")])]}}],null,false,240782798)},[_vm._v(" "),_c('div',{staticStyle:{"max-width":"400px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.renderMarkdown(
                        _vm.$t(
                          `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.emissionSourceFormsTooltips.tooltipPerImpactProfile.${[
                            _vm.impactProfile,
                          ]}.consumptionAmountTooltip`,
                        ),
                      )
                    )}})])])]},proxy:true}],null,false,3309273271),model:{value:(_vm.emissionSource.solutionConsumption),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionConsumption", $$v)},expression:"emissionSource.solutionConsumption"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.unitLabel',
              ),"disabled":"","value":_vm.emissionSource.unitName}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-textarea',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.solution.assumptionLabel',
              )},model:{value:(_vm.emissionSource.solutionAssumption),callback:function ($$v) {_vm.$set(_vm.emissionSource, "solutionAssumption", $$v)},expression:"emissionSource.solutionAssumption"}})],1)],1)],1),_vm._v(" "),_c('ix-section-text',[_c('v-row',{staticClass:"justify-end"},[_c('ix-button-action',{staticClass:"mt-2 mr-2",on:{"click":_vm.cancel}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.cancelModal',
            ))+"\n        ")]),_vm._v(" "),_c('ix-button-action',{staticClass:"mt-2",attrs:{"disabled":_vm.disableSaveButton},on:{"click":_vm.saveAndClose}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.yourSolutionEmissionSourceForm.closeModal',
            ))+"\n        ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }