import _debounce from 'lodash/debounce';
export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    },
    showCopyButton: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var internalValue = ref(props.value);
    var touched = ref(false);
    var isClicked = ref(false);
    var updateValue = _debounce(function (value) {
      touched.value = false;
      emit('input', value);
      emit('update:value', value);
    }, 400);
    var onCopy = function onCopy() {
      isClicked.value = true;
      setTimeout(function () {
        return isClicked.value = false;
      }, 200);
      if (!navigator.clipboard) {
        console.error('Clipboard not available');
        return;
      }
      navigator.clipboard.writeText(props.value).then(function () {
        console.log('Text copied successfully!');
      }).catch(function (err) {
        console.error('Failed to copy text: ', err);
      });
    };
    watch(function () {
      return props.value;
    }, function (value) {
      if (!touched.value) internalValue.value = value;
    });
    var updateInternalValue = function updateInternalValue(value) {
      touched.value = true;
      if (value === null) {
        value = ''; // Prevent null value. Stawberry converts null to None but it is not UNSET, so mandatory fields will return error when set to None.
      }
      updateValue(value);
    };
    onBeforeUnmount(function () {
      updateValue.flush();
    });
    return {
      internalValue: internalValue,
      updateInternalValue: updateInternalValue,
      isClicked: isClicked,
      onCopy: onCopy
    };
  }
});