import { render, staticRenderFns } from "./esgs.vue?vue&type=template&id=5c1a0ce0"
import script from "./esgs.vue?vue&type=script&lang=ts"
export * from "./esgs.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./esgs.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxHeaderPortfolio: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/HeaderPortfolio.vue').default,IxEsgQuestionsEdit: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/esgQuestionsEdit.vue').default,IxDataTableAccounts: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Accounts.vue').default,IxEsgEdit: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/esgEdit.vue').default,IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,IxEsgMeasures: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/esgMeasures.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VCardActions,VCardTitle,VChip,VDataTable,VDialog,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VLayout,VMenu,VSpacer,VTextField,VToolbar,VTooltip})
