import { render, staticRenderFns } from "./SdgImpactsView.vue?vue&type=template&id=cae717c6&scoped=true"
import script from "./SdgImpactsView.vue?vue&type=script&lang=ts"
export * from "./SdgImpactsView.vue?vue&type=script&lang=ts"
import style0 from "./SdgImpactsView.vue?vue&type=style&index=0&id=cae717c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cae717c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,PortfolioScreeningSdgimpactSdgDistribution: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio-screening/sdgimpact/SdgDistribution.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
installComponents(component, {VBtnToggle})
