import CharacterCount from '@tiptap/extension-character-count';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import MentionList from './tiptapMentionList.vue';
export default defineComponent({
  components: {
    EditorContent: EditorContent
  },
  props: {
    mentionItems: {
      type: Function,
      required: false,
      default: function _default() {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    percentage: function percentage() {
      return Math.round(100 / this.limit * this.editor.storage.characterCount.characters());
    }
  },
  setup: function setup(props, _ref) {
    var _this = this;
    var emit = _ref.emit;
    var editor = ref(null);
    var limit = ref(200);
    watch(function () {
      return props.value;
    }, function () {
      var _editor$value, _editor$value$command;
      (_editor$value = editor.value) === null || _editor$value === void 0 ? void 0 : (_editor$value$command = _editor$value.commands) === null || _editor$value$command === void 0 ? void 0 : _editor$value$command.setContent(props.value);
    });
    onMounted(function () {
      editor.value = new Editor({
        onCreate: function onCreate(_ref2) {
          var _editor$commands;
          var editor = _ref2.editor;
          (_editor$commands = editor.commands) === null || _editor$commands === void 0 ? void 0 : _editor$commands.setContent(props.value);
        },
        onUpdate: function onUpdate(_ref3) {
          var editor = _ref3.editor;
          // The content has changed.
          emit('input', editor.getHTML());
        },
        onBlur: function onBlur(_ref4) {
          var editor = _ref4.editor;
          emit('blur', editor.getHTML());
        },
        extensions: [Document, Paragraph, Text, CharacterCount.configure({
          limit: limit.value
        }), Mention.configure({
          // HTMLAttributes: {
          //   class: 'mention',
          // },
          renderHTML: function renderHTML(_ref5) {
            var _node$attrs$label;
            var options = _ref5.options,
              node = _ref5.node;
            return ['span', {
              'data-id': node.attrs.id,
              class: 'mention'
            }, "".concat(options.suggestion.char).concat((_node$attrs$label = node.attrs.label) !== null && _node$attrs$label !== void 0 ? _node$attrs$label : node.attrs.id)];
          },
          suggestion: {
            allowSpaces: true,
            char: '$',
            items: props.mentionItems,
            render: function render() {
              var component;
              var popup;
              return {
                onStart: function onStart(props) {
                  component = new VueRenderer(MentionList, {
                    // using vue 2:
                    parent: _this,
                    propsData: props
                    // using vue 3:
                    // props,
                    // editor: props.editor,
                  });
                  if (!props.clientRect) {
                    return;
                  }
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: function appendTo() {
                      return document.body;
                    },
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                  });
                },
                onUpdate: function onUpdate(props) {
                  var _component;
                  (_component = component) === null || _component === void 0 ? void 0 : _component.updateProps(props);
                  if (!props.clientRect) {
                    return;
                  }
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                  });
                },
                onKeyDown: function onKeyDown(props) {
                  var _component2, _component2$ref;
                  if (props.event.key === 'Escape') {
                    popup[0].hide();
                    return true;
                  }
                  return (_component2 = component) === null || _component2 === void 0 ? void 0 : (_component2$ref = _component2.ref) === null || _component2$ref === void 0 ? void 0 : _component2$ref.onKeyDown(props);
                },
                onExit: function onExit() {
                  popup[0].destroy();
                  component.destroy();
                }
              };
            }
          }
        })],
        content: "\n        <p>\n          What do you all think about the new <span data-type=\"mention\" data-id=\"KPIs\"></span>?\n        </p>\n        <p></p>\n        <code>$1 + $2</code>\n      "
      });
    });
    onBeforeUnmount(function () {
      editor.value.destroy();
    });
    return {
      editor: editor,
      limit: limit
    };
  }
});