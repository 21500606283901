import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { useQueryLoading } from '@vue/apollo-composable';
import * as _ from 'lodash';
import { highlightPlaceholders } from '~/assets/helper/functions';
import { useListQuestionsQuery } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['id', 'ixId', 'label', 'sublabel', 'tooltip', 'component', 'isMandatory', 'isMultiple', 'scoringRules', 'sort', 'actions'];
      }
    },
    defaultFilters: {
      type: Object,
      required: false,
      default: null
    },
    showSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    mergeTemplateTexts: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var headers = computed(function () {
      return [{
        value: 'id',
        sortable: false
      }, {
        value: 'label',
        cellClass: 'max-w-[200px]'
      }, {
        value: 'sublabel',
        cellClass: 'max-w-[200px]'
      }, {
        value: 'tooltip',
        cellClass: 'max-w-[400px]'
      }, {
        value: 'isMandatory'
      }, {
        value: 'isMultiple'
      }, {
        value: 'templateTexts',
        cellClass: 'max-w-[400px]'
      }, {
        value: 'scoringRules',
        cellClass: 'max-w-[400px]'
      }, {
        value: 'sort'
      }, {
        value: 'actions'
      }].filter(function (o) {
        var _props$columns;
        return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
      }).map(function (col) {
        return _objectSpread(_objectSpread({}, col), {}, {
          sortable: col.sortable || false
        });
      }) || [];
    });
    var options = ref({
      page: 1,
      itemsPerPage: 10,
      sortDesc: [true]
    });
    var filters = ref(_objectSpread({}, props.defaultFilters));
    var variables = computed(function () {
      return {
        filters: filters.value,
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage
      };
    });
    var _useListQuestionsQuer = useListQuestionsQuery(variables),
      result = _useListQuestionsQuer.result,
      refetch = _useListQuestionsQuer.refetch;
    var items = computed(function () {
      var _result$value$listQue, _result$value;
      return (_result$value$listQue = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listQuestions) !== null && _result$value$listQue !== void 0 ? _result$value$listQue : [];
    });
    var selected = computed(function () {
      return items === null || items === void 0 ? void 0 : items.value.filter(function (question) {
        return (props.value || []).filter(Boolean).includes(question.id);
      });
    });
    var search = ref('');
    var onInput = function onInput(val) {
      if (_.isEqual(val.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      }), props.value)) {
        // if the value is the same, don't fire the event
        return;
      }
      emit('input', val.map(function (_ref3) {
        var id = _ref3.id;
        return id;
      }));
    };
    var loading = useQueryLoading();
    var itemCount = computed(function () {
      var _result$value2;
      return (result === null || result === void 0 ? void 0 : (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.countQuestions) || 0;
    });
    var editedItem = ref({});
    return {
      filters: filters,
      itemCount: itemCount,
      loading: loading,
      items: items,
      refetch: refetch,
      selected: selected,
      search: search,
      headers: headers,
      onInput: onInput,
      options: options,
      editedItem: editedItem,
      highlightPlaceholders: highlightPlaceholders
    };
  }
});