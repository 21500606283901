import { category as _category, color, percentage as _percentage, skipped as _skipped, score } from '~/functions/esg';
export default defineComponent({
  props: {
    questionnaireResult: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    skipped: function skipped() {
      return _skipped(this.questionnaireResult);
    },
    points: function points() {
      return score(this.questionnaireResult);
    },
    percentage: function percentage() {
      return _percentage(this.questionnaireResult);
    },
    category: function category() {
      return _category(this.percentage, this.skipped);
    },
    colorClass: function colorClass() {
      return color(this.percentage, this.skipped);
    },
    textClass: function textClass() {
      return this.colorClass + '--text';
    }
  },
  methods: {}
});