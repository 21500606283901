import { handleCompanyInfo } from '../Impact/company_Info'
import { handleEsgReport } from './esg_report'

export const esgSlideContent = (
  companyInfo: any,
  updatedAt: string,
  esgData: any,
) => {
  const esgLength = esgData && Object.keys(esgData).length
  return [
    companyInfo && handleCompanyInfo(companyInfo),
    {
      text: 'ESG performance report',
      style: ['esgHeader'],
      width: 'auto',
      margin: [-3, -7, 0, 0],
    },
    {
      text: 'How well does the company perform with regard to taking action on ESG?',
      style: ['esgSubHeader'],
      width: 'auto',
    },
    esgLength ? handleEsgReport(updatedAt, esgData) : '',
  ]
}

export const esgDashboardStyle = {
  esgHeader: {
    fontSize: 15,
    color: '#5188ff',
    bold: true,
  },
  esgSubHeader: {
    fontSize: 10,
    color: '#5B7185',
    margin: [-5, 3, 0, 0],
  },
}
