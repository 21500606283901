import "core-js/modules/es6.array.filter.js";
import { MetricCalculationType } from '~/types/types';
import { useMetricValueAggregationQuery } from '~/types/types';
export default defineComponent({
  props: {},
  setup: function setup() {
    var currentPeriod = ref(new Date().getFullYear() - 1 + '');
    var _useMetricValueAggreg = useMetricValueAggregationQuery({
        period: currentPeriod // we are store period as string in the backend, let's make it consistent
      }),
      result = _useMetricValueAggreg.result;
    var items = computed(function () {
      var _result$value, _result$value$metricV, _result$value$metricV2;
      return result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$metricV = _result$value.metricValueAggregation) === null || _result$value$metricV === void 0 ? void 0 : (_result$value$metricV2 = _result$value$metricV.filter) === null || _result$value$metricV2 === void 0 ? void 0 : _result$value$metricV2.call(_result$value$metricV, function (m) {
        return m.calculationType != MetricCalculationType.Predefined && m.targetSum !== null && m.actualSum !== null;
      });
    });
    return {
      currentPeriod: currentPeriod,
      items: items
    };
  },
  data: function data() {
    return {
      periods: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030']
    };
  }
});