import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    transition: {
      type: String,
      required: false,
      default: 'v-fade-transition'
    },
    top: {
      type: Boolean,
      required: false,
      default: false
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false
    },
    right: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'span'
    },
    maxWidth: {
      type: String | Number,
      required: false,
      default: 300
    },
    openDelay: {
      type: String | Number,
      required: false,
      default: 200
    },
    closeDelay: {
      type: String | Number,
      required: false,
      default: 200
    },
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    zIndex: {
      type: String,
      required: false
    },
    offsetOverflow: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});