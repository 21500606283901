import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.replace.js";
import { formatAsGermanDate } from '~/functions/helpers';
import { useDeleteTaskAssignmentsMutation, useListTaskAssignmentsQuery } from '~/types/types';
export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _props$portfolio$vent;
    var _useListTaskAssignmen = useListTaskAssignmentsQuery({
        ventureIds: ((_props$portfolio$vent = props.portfolio.ventureLinks) === null || _props$portfolio$vent === void 0 ? void 0 : _props$portfolio$vent.map(function (venture) {
          return venture.ventureId;
        })) || []
      }),
      result = _useListTaskAssignmen.result,
      refetch = _useListTaskAssignmen.refetch,
      loading = _useListTaskAssignmen.loading;
    var getVentureLink = function getVentureLink(id) {
      var _props$portfolio$vent2;
      return ((_props$portfolio$vent2 = props.portfolio.ventureLinks) === null || _props$portfolio$vent2 === void 0 ? void 0 : _props$portfolio$vent2.find(function (link) {
        return link.ventureId === id;
      })) || {
        venture: null
      };
    };
    var getVenture = function getVenture(id) {
      return getVentureLink(id).venture || {};
    };
    var items = computed(function () {
      var _result$value, _result$value$listTas, _result$value$listTas2;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$listTas = _result$value.listTaskAssignments) === null || _result$value$listTas === void 0 ? void 0 : (_result$value$listTas2 = _result$value$listTas.filter(function (e) {
        return getVentureLink(e.ventureId) !== undefined;
      })) === null || _result$value$listTas2 === void 0 ? void 0 : _result$value$listTas2.map(function (task) {
        var ventureLink = getVentureLink(task.ventureId);
        return _objectSpread(_objectSpread({}, task), {}, {
          venture: ventureLink.venture
        });
      })) || [];
    });
    var taskAssignmentFormRef = ref(null);
    var ventureLinks = computed(function () {
      var _props$portfolio;
      return (_props$portfolio = props.portfolio) === null || _props$portfolio === void 0 ? void 0 : _props$portfolio.ventureLinks;
    });
    var ventures = computed(function () {
      var _props$portfolio2, _props$portfolio2$ven;
      return (_props$portfolio2 = props.portfolio) === null || _props$portfolio2 === void 0 ? void 0 : (_props$portfolio2$ven = _props$portfolio2.ventureLinks) === null || _props$portfolio2$ven === void 0 ? void 0 : _props$portfolio2$ven.map(function (e) {
        return e.venture;
      });
    });
    var _useDeleteTaskAssignm = useDeleteTaskAssignmentsMutation(),
      deleteTaskAssignments = _useDeleteTaskAssignm.mutate;

    // const loading = useQueryLoading()
    var deleteTask = function deleteTask(taskAssignmentId) {
      deleteTaskAssignments({
        taskAssignmentIds: [taskAssignmentId]
      }, {
        update: function update(cache) {
          cache.evict({
            id: cache.identify({
              id: taskAssignmentId,
              __typename: 'MetricAssignmentType'
            })
          });
          cache.gc();
          window.$nuxt.$emit('userMsg');
        }
      });
    };
    return {
      taskAssignmentFormRef: taskAssignmentFormRef,
      loading: loading,
      ventureLinks: ventureLinks,
      result: result,
      formatAsGermanDate: formatAsGermanDate,
      deleteTask: deleteTask,
      items: items,
      getVenture: getVenture,
      ventures: ventures,
      refetch: refetch
    };
  },
  data: function data() {
    var _this = this;
    return {
      taskFormDialog: false,
      search: '',
      groupBy: 'task.label',
      headers: [{
        value: 'venture.name',
        cellClass: 'align-top font-weight-bold subtitle-2'
      }, {
        value: 'task.label',
        cellClass: 'align-top font-weight-bold subtitle-2'
      }, {
        value: 'task.goal'
      }, {
        value: 'origin'
      }, {
        value: 'startDate'
      }, {
        value: 'endDate'
      }, {
        value: 'status'
      }, {
        value: 'comments'
      }, {
        value: 'updatedAt'
      }, {
        value: 'actions',
        sortable: false,
        width: '30px',
        cellClass: 'align-top subtitle-1 font-weight-bold'
      }].map(function (e) {
        var _e$value;
        return _objectSpread({
          cellClass: 'align-top',
          text: _this.$t('companyPerspective.tasks.headers.' + ((_e$value = e.value) === null || _e$value === void 0 ? void 0 : _e$value.replace('.', '_')) // FIXME: this is a hack
          )
        }, e);
      }),
      chipColors: {
        'Not started': 'danger',
        'In progress': 'info',
        Done: 'success',
        // more pastels: '#FFDAC1','#E2FOCB','#ffffba', #bae1ff
        Investor: 'primary lighten-4',
        // '#FFB7B2',
        Bookmark: 'primary lighten-3',
        // '#C7CEEA',
        'Self defined': 'primary lighten-2',
        // '#B5EAD7',
        'Auto task': 'primary lighten-1' // '#FF9AA2',
      }
    };
  }
});