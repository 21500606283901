import { render, staticRenderFns } from "./OutcomeSelectionSummary.vue?vue&type=template&id=cbafd79c"
import script from "./OutcomeSelectionSummary.vue?vue&type=script&lang=ts"
export * from "./OutcomeSelectionSummary.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SetupAddOutcome: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SetupAddOutcome.vue').default,ImpactSummary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactSummary.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCardActions,VCardText,VDialog,VIcon,VSpacer})
