import { render, staticRenderFns } from "./KfwExport.vue?vue&type=template&id=46a70d21"
import script from "./KfwExport.vue?vue&type=script&lang=ts"
export * from "./KfwExport.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./KfwExport.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxDataTableAccounts: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Accounts.vue').default,IxButtonPrimary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Primary.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VCol,VListItemTitle,VRow,VSelect})
