export const hoverTexts = [
  {
    Element: 'Main impacts',
    Keyword: 'Main impacts',
    'Text to show':
      'Overview of the most material impacts a company is likely to create',
    Comment: '',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Main social & environmental outcomes',
    'Text to show': '',
    Comment:
      '[renamed to "Main outcomes"] & Text from "Theory of change (short)" in the outcome selection',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Affected stakeholders',
    'Text to show': '',
    Comment: 'Text from "Theory of change (short)" in the outcome selection',
  },
  {
    Element: 'Main impacts, Company',
    Keyword: 'Impact class',
    'Text to show':
      'A classification system to describe the overall impact of a company as well as individual impacts. It is an extended version of the impact management project.',
    Comment: '',
  },
  {
    Element: 'Company',
    Keyword: 'Does cause harm',
    'Text to show':
      'The company creates negative outcomes for people and planet',
    Comment: '',
  },
  {
    Element: 'Company',
    Keyword: 'May cause harm',
    'Text to show':
      'The company may create negative outcomes for people and planet',
    Comment: '',
  },
  {
    Element: 'Company',
    Keyword: 'Avoids harm',
    'Text to show':
      'The company prevents or reduces significant effects on important\nnegative outcomes for people and planet',
    Comment: '',
  },
  {
    Element: 'Company',
    Keyword: 'Benefits stakeholders',
    'Text to show':
      'The company not only acts to avoid harm, but also generates\nvarious effects on positive outcomes for people and the planet',
    Comment: '',
  },
  {
    Element: 'Company',
    Keyword: 'Contributes to solution',
    'Text to show':
      'The company not only acts to avoid harm, but also generates\none or more significant effect(s) on positive outcomes for otherwise underserved people and the planet',
    Comment: '',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Does cause harm',
    'Text to show':
      'The company contributes to a negative outcome for people and planet (clear evidence)',
    Comment:
      'For the specific impact classes taken out from Outcome selection "Explanation of impact class for customer',
  },
  {
    Element: 'Main impacts',
    Keyword: 'May cause harm',
    'Text to show':
      'The company may contribute to a negative outcome for people and planet (only the risk of harm)',
    Comment:
      'For the specific impact classes taken out from Outcome selection "Explanation of impact class for customer',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Avoid harm',
    'Text to show':
      'The company prevents or reduces an important negative outcomes for people and planet',
    Comment:
      'For the specific impact classes taken out from Outcome selection "Explanation of impact class for customer',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Benefits stakeholders',
    'Text to show':
      'The company generates  a positive outcomes for people and the planet',
    Comment:
      'For the specific impact classes taken out from Outcome selection "Explanation of impact class for customer',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Contributes to solution',
    'Text to show':
      'The company generates a significant a positive outcome for otherwise underserved people and the planet',
    Comment:
      'For the specific impact classes taken out from Outcome selection "Explanation of impact class for customer',
  },
  {
    Element: '',
    Keyword: 'Impact potential score - Total',
    'Text to show':
      'Is the company likely to have a strong positive or negative effect across its most material impacts (i.e. average across the main impacts)',
    Comment: '',
  },
  {
    Element: 'Main impacts',
    Keyword: 'Impact potential score - per impact:',
    'Text to show':
      'Is the company likely to have a strong positive or negative effect on a specific outcome-stakeholder combination. The score is calculated as the product of the "Relevance of impact" and the "Degree of change".',
    Comment: '',
  },
  {
    Element: 'Details, Main impacts',
    Keyword: 'Impact management risk',
    'Text to show': 'Is the company likely to optimize its impact',
    Comment: '',
  },
  {
    Element: 'Details, Main impacts',
    Keyword: 'Confidence',
    'Text to show':
      'Confidence level: An indication for the uncertainty of the assessment results ranging from 5 high confidence to 1 low confidence',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'What',
    'Text to show':
      'Indicates how important the outcome is from a stakeholder perspective and a societal perspective. The score is calculated as weighted average of the indicators in this dimension ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'Who',
    'Text to show':
      'Indicates whether the outcome targets a significant number of stakeholders in need. The score is calculated as weighted average of the indicators in this dimension ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'How much',
    'Text to show':
      'Indicates how much of an outcome a company creates. The score is calculated as weighted average of the indicators in this dimension ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'Contribution',
    'Text to show':
      'Indicates how much of the change would have happened anyway. The score is calculated as weighted average of the indicators in this dimension ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'Degree of change',
    'Text to show':
      'Indicates whether the company creates a significant change of the outcome.  The score is calculated as average of the IMP dimensions "How much" and "Contribution"  ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'Relevance of impact',
    'Text to show':
      'Indicates whether the assessed outcome can be considered as important in the context of the company (e.g. in the countries and markets a company is active in). The score is calculated as average of the IMP dimensions "What" and "Who" ranging from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Assessment',
    Keyword: 'Impact potential score',
    'Text to show':
      'Indicates the potential of a company to create a positive or negative impact on people or the planet for one outcome-stakeholder combination. The score is calculated by multiplying the "Relevance of impact" with the "Degree of change" at it ranges from 0 to 100',
    Comment: '',
  },
  {
    Element: 'Add source',
    Keyword: 'Public',
    'Text to show':
      '* Public sources will be visible to potential viewers of the assessment (e.g. investors, customers, partners) so they can understand your reasoning.\n* Public sources will be added to our evidence database (without any association to your company)',
    Comment: '',
  },
  {
    Element: 'Add source',
    Keyword: 'Confidential',
    'Text to show':
      '* Confidential material will neither be shared nor shown to potential viewers of the assessment (e.g. investors, customers, partners)\n* It will only be visible by reviewers of your assessment (e.g. analysts of ImpactNexus)\n* Should be used for confidential pitch-decks or internal documents',
    Comment: '',
  },
  {
    Element: 'Value chain',
    Keyword: 'Inputs',
    'Text to show':
      'The financial, human, and material resources used for your business activities, e.g. if you procure organic food, fair trade materials, but you might also depend on conflict minerals or exploit very low wages abroad.',
    Comment: '',
  },
  {
    Element: 'Value chain',
    Keyword: 'Activities',
    'Text to show':
      'Actions taken or work performed through which inputs are mobilised to produce specific outputs, e.g. employing people with a handicap, consuming less energy, but you can also emit a lot of CO2 emissions or create health risks for your employees.',
    Comment: '',
  },
  {
    Element: 'Value chain',
    Keyword: 'Outputs',
    'Text to show':
      'The products and services which result from your business activities, e.g providing education through a software, selling tools for renewable power generation, but your outputs can also create a lot of waste or health risks to users.',
    Comment: '',
  },
  {
    Element: 'Value chain',
    Keyword: 'Value chain',
    'Text to show':
      'The value chain describes what inputs your company needs and what activities it conducts to create products and/or services for its customers and users. All the social and environmental impacts of your company can be linked to either the inputs, your activities and/or the products and/or services. To analyze your impactit is crucial to get a good understanding about the key elements of your value chain.',
    Comment: '',
  },
]
