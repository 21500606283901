import { render, staticRenderFns } from "./impactVentureInformation.vue?vue&type=template&id=e8861a50&scoped=true"
import script from "./impactVentureInformation.vue?vue&type=script&lang=ts"
export * from "./impactVentureInformation.vue?vue&type=script&lang=ts"
import style0 from "./impactVentureInformation.vue?vue&type=style&index=0&id=e8861a50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8861a50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,ImpactGoalProgressSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/ProgressSection.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default,ClimateImpactModuleSummary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ClimateImpactModuleSummary.vue').default,ImpactSolutionDetail: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactSolutionDetail.vue').default,ImpactGoalListLayout: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/ListLayout.vue').default,MetricsAssignmentSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/AssignmentSection.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VIcon,VLayout,VRow,VSpacer})
