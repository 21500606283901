import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    // const { i18n } = useNuxtApp() as any
    // const sectionName = i18n.t(`database.section.${props.section.ixId}.label`)
    var img = computed(function () {
      if (['environmental_v2', 'environmental'].includes(props.section.ixId)) {
        return require('@/assets/icons/environmental.svg');
      } else if (['social_v2', 'social'].includes(props.section.ixId)) {
        return require('@/assets/icons/social.svg');
      } else if (['governance_v2', 'governance'].includes(props.section.ixId)) {
        return require('@/assets/icons/governance.svg');
      }
    });
    return {
      img: img
    };
  }
});