import * as katex from '@iktakahiro/markdown-it-katex'
import markdown from 'markdown-it'
import footnote from 'markdown-it-footnote'
import inline from 'markdown-it-for-inline'
export const md = markdown({ linkify: true, html: true })
  .use(
    inline,
    'url_new_win',
    'link_open',
    function (tokens: any[], idx: number) {
      const [, href] = tokens[idx].attrs.find(
        (attr: string[]) => attr[0] === 'href',
      )

      if (href && !href.startsWith('/') && !href.startsWith('#')) {
        tokens[idx].attrPush(['target', '_blank'])
        tokens[idx].attrPush(['rel', 'noopener noreferrer'])
      }
    },
  )
  .use(katex)
  .use(footnote)

// md.configure({
//   components: {
//     core: {
//       rules: ['normalize', 'block', 'inline', 'text_join'],
//     },

//     block: {
//       rules: [
//         'blockquote',
//         // 'code',
//         'fence',
//         'heading',
//         'hr',
//         'html_block',
//         'lheading',
//         'list',
//         'reference',
//         'paragraph',
//       ],
//     },

//     inline: {
//       rules: [
//         'autolink',
//         'backticks',
//         'emphasis',
//         'entity',
//         'escape',
//         'html_inline',
//         'image',
//         'link',
//         'newline',
//         'text',
//       ],
//       rules2: ['balance_pairs', 'emphasis', 'fragments_join'],
//     },
//   },
// })
export function renderMarkdown(text: string) {
  if (text === undefined) {
    text = ''
  }
  const html = md.render(text)
  return html
}

export function renderMarkdownInline(text: string) {
  if (text === undefined) {
    text = ''
  }
  return md.renderInline(text)
}
