import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import { Module, useGetAccountQuery, useGetUserQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $auth = _useNuxtApp.$auth;
    var _useGetAccountQuery = useGetAccountQuery({}, computed(function () {
        return {
          enabled: $auth.loggedIn
        };
      })),
      result = _useGetAccountQuery.result,
      loading = _useGetAccountQuery.loading;
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return (_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser;
    });
    var account = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {
        name: ''
      };
    });
    var hasModule = function hasModule(moduleName) {
      var _result$value2, _result$value2$getAcc, _result$value2$getAcc2;
      return !!((_result$value2 = result.value) !== null && _result$value2 !== void 0 && (_result$value2$getAcc = _result$value2.getAccount) !== null && _result$value2$getAcc !== void 0 && (_result$value2$getAcc2 = _result$value2$getAcc.modules) !== null && _result$value2$getAcc2 !== void 0 && _result$value2$getAcc2.includes(moduleName));
    };
    var accounts = computed(function () {
      var _user$value;
      return (user === null || user === void 0 ? void 0 : (_user$value = user.value) === null || _user$value === void 0 ? void 0 : _user$value.accounts) || [];
    });
    var showAccountSelection = computed(function () {
      var _accounts$value, _user$value2;
      return (accounts === null || accounts === void 0 ? void 0 : (_accounts$value = accounts.value) === null || _accounts$value === void 0 ? void 0 : _accounts$value.length) > 1 && !((_user$value2 = user.value) !== null && _user$value2 !== void 0 && _user$value2.isAdmin);
    });
    var headers = computed(function () {
      var _user$value3, _user$value4, _user$value5, _user$value6, _user$value7, _user$value8, _user$value9, _user$value10, _user$value11, _user$value12, _user$value13, _user$value14, _user$value15, _user$value16, _user$value17, _user$value18, _user$value19, _user$value20, _user$value21, _user$value22, _user$value23, _user$value24, _user$value25;
      return [{
        label: 'Dashboard',
        link: 'portfolio-dashboard',
        showForModules: hasModule(Module.Esg)
      }, {
        label: 'ESG',
        link: 'portfolio-esgList',
        showForModules: hasModule(Module.Esg)
      },
      // {
      //   label: 'Impacts',
      //   link: 'portfolio-impactList',
      //   showForModules: hasModule(Module.Impact),
      // },
      {
        label: 'Impact',
        link: 'portfolio-impactGoalList',
        showForModules: hasModule(Module.ImpactGoal)
      },
      // {
      //   label: 'Impacts by SDG',
      //   link: 'portfolio-impactSdg',
      //   showForModules: ['Impact'],
      // },
      // {
      //   label: 'Impacts by geography',
      //   link: 'portfolio-impactGeo',
      //   showForModules: ['Impact'],
      // },
      // {
      //   label: 'Impact comparison',
      //   link: 'portfolio-impactComparison',
      //   showForModules: hasModule(Module.Impact),
      // },
      {
        label: 'KPIs',
        link: 'portfolio-metrics',
        showForModules: hasModule(Module.Metrics)
      }, {
        label: 'Tasks',
        link: 'portfolio-task',
        showForModules: hasModule(Module.Task)
      }, {
        label: 'Annotation',
        link: 'portfolio-annotation',
        showForModules: hasModule(Module.Annotation)
      },
      // {
      //   label: 'Impact screening',
      //   link: 'portfolio-impactScreening',
      //   showForModules: hasModule(Module.ImpactScreening),
      // },
      {
        label: 'Funds',
        link: 'portfolio-funds',
        showForModules: hasModule(Module.Sfdr)
      }, {
        label: 'Accounts',
        link: 'admin-accounts',
        showForModules: ((_user$value3 = user.value) === null || _user$value3 === void 0 ? void 0 : _user$value3.isAdmin) && !((_user$value4 = user.value) !== null && _user$value4 !== void 0 && _user$value4.isImpersonated),
        isAdmin: true
      }, {
        label: 'Users',
        link: 'admin-users',
        showForModules: ((_user$value5 = user.value) === null || _user$value5 === void 0 ? void 0 : _user$value5.isAdmin) && !((_user$value6 = user.value) !== null && _user$value6 !== void 0 && _user$value6.isImpersonated),
        isAdmin: true
      }, {
        label: 'KPIs MGM',
        link: 'admin-kpis',
        showForModules: ((_user$value7 = user.value) === null || _user$value7 === void 0 ? void 0 : _user$value7.isAdmin) && !((_user$value8 = user.value) !== null && _user$value8 !== void 0 && _user$value8.isImpersonated),
        isAdmin: true,
        children: [{
          label: 'Datapoint',
          link: 'admin-kpis-datapoint',
          showForModules: ((_user$value9 = user.value) === null || _user$value9 === void 0 ? void 0 : _user$value9.isAdmin) && !((_user$value10 = user.value) !== null && _user$value10 !== void 0 && _user$value10.isImpersonated),
          isAdmin: true
        }, {
          label: 'Aggregated',
          link: 'admin-kpis-aggregated',
          showForModules: ((_user$value11 = user.value) === null || _user$value11 === void 0 ? void 0 : _user$value11.isAdmin) && !((_user$value12 = user.value) !== null && _user$value12 !== void 0 && _user$value12.isImpersonated),
          isAdmin: true
        }]
      }, {
        label: 'Categories',
        link: 'admin-categories',
        showForModules: ((_user$value13 = user.value) === null || _user$value13 === void 0 ? void 0 : _user$value13.isAdmin) && !((_user$value14 = user.value) !== null && _user$value14 !== void 0 && _user$value14.isImpersonated),
        isAdmin: (_user$value15 = user.value) === null || _user$value15 === void 0 ? void 0 : _user$value15.isAdmin
      }, {
        label: 'ESG MGM',
        link: 'admin-esgs',
        showForModules: ((_user$value16 = user.value) === null || _user$value16 === void 0 ? void 0 : _user$value16.isAdmin) && !((_user$value17 = user.value) !== null && _user$value17 !== void 0 && _user$value17.isImpersonated),
        isAdmin: true
      }, {
        label: 'Section MGM',
        link: 'admin-sections',
        showForModules: ((_user$value18 = user.value) === null || _user$value18 === void 0 ? void 0 : _user$value18.isAdmin) && !((_user$value19 = user.value) !== null && _user$value19 !== void 0 && _user$value19.isImpersonated),
        isAdmin: true
      }, {
        label: 'Fund Reports',
        link: 'admin-fundreports',
        showForModules: ((_user$value20 = user.value) === null || _user$value20 === void 0 ? void 0 : _user$value20.isAdmin) && !((_user$value21 = user.value) !== null && _user$value21 !== void 0 && _user$value21.isImpersonated),
        isAdmin: true
      }, {
        label: 'Fund MGM',
        link: 'admin-funds',
        showForModules: ((_user$value22 = user.value) === null || _user$value22 === void 0 ? void 0 : _user$value22.isAdmin) && !((_user$value23 = user.value) !== null && _user$value23 !== void 0 && _user$value23.isImpersonated),
        isAdmin: true
      }, {
        label: 'Audit',
        link: 'admin-audit',
        showForModules: ((_user$value24 = user.value) === null || _user$value24 === void 0 ? void 0 : _user$value24.isAdmin) && !((_user$value25 = user.value) !== null && _user$value25 !== void 0 && _user$value25.isImpersonated),
        isAdmin: true
      }].filter(function (header) {
        return header.showForModules;
      });
    });
    var activeTab = ref('portfolio-dashboard');
    var onInput = function onInput(v) {
      sessionStorage.setItem('ix-account-id', v);
      window.location.href = '/';
    };
    var selectedAccount = computed(function () {
      return sessionStorage.getItem('ix-account-id');
    });
    return {
      loading: loading,
      user: user,
      account: account,
      activeTab: activeTab,
      headers: headers,
      accounts: accounts,
      onInput: onInput,
      selectedAccount: selectedAccount,
      showAccountSelection: showAccountSelection
    };
  }
});