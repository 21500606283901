import { render, staticRenderFns } from "./QuestionarieResult.vue?vue&type=template&id=8ad41d84&scoped=true"
import script from "./QuestionarieResult.vue?vue&type=script&lang=js"
export * from "./QuestionarieResult.vue?vue&type=script&lang=js"
import style0 from "./QuestionarieResult.vue?vue&type=style&index=0&id=8ad41d84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ad41d84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyDisclosureSubsectionScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/DisclosureSubsectionScore.vue').default,IxSurveyEsgSubsectionScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgSubsectionScore.vue').default,IxSubSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionTitle.vue').default,IxSubSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionText.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default,IxSurveySdgGriRelevantExplanation: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/SdgGriRelevantExplanation.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCol,VDivider,VLayout,VRow,VSpacer,VStepper,VStepperContent,VStepperHeader,VStepperStep,VTab,VTabs})
