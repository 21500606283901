import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=ad549cc0"
import script from "./edit.vue?vue&type=script&setup=true&lang=ts"
export * from "./edit.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./edit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxBtnBack: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/Back.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxTextField: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/TextField.vue').default,IxAutocompleteStakeholderType: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/autocomplete/StakeholderType.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default,IxDataTableIroSectionLink: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/IroSectionLink.vue').default,IxBtnAddActivity: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddActivity.vue').default,IxDataTableActivity: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Activity.vue').default,IxBtnAddIro: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddIro.vue').default,IxDataTableIro: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Iro.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardTitle,VCol,VDialog,VIcon,VRow,VSpacer,VToolbar,VToolbarTitle})
