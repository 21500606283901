import { formatAsGermanDate } from '~/functions/helpers';
export default defineComponent({
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: String,
      default: 'add'
    },
    ventureId: {
      type: String,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      rules: {
        startDateShouldBeBeforeDueDate: function startDateShouldBeBeforeDueDate(value) {
          var isValid = true;
          if (value.startDate && value.endDate) {
            isValid = new Date(value.startDate) <= new Date(value.endDate);
          }
          return isValid || 'The start date should be before (or the same as) the due date';
        }
      }
    };
  },
  computed: {
    form: function form() {
      return this.$refs.form;
    },
    isEditable: function isEditable() {
      return this.type === 'add' || this.type === 'edit' && this.value.task.createdBy === this.ventureId;
    }
  },
  methods: {
    formatAsGermanDate: formatAsGermanDate,
    dateText: function dateText(date) {
      return date ? formatAsGermanDate(date) : '';
    },
    validate: function validate() {
      return this.form.validate();
    }
  }
});