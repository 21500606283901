export default defineComponent({
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    // const { result } = useListMetricAggregationsQuery({})
    // const hasFundOrSfdrStrategy = computed(
    //   () =>
    //     result?.value?.listMetricAggregations.some(
    //       (e: any) => e?.sfdrGroups?.length,
    //     ),
    // )
    // const { i18n } = useNuxtApp()
    // const headers = computed(() => {
    //   const defaultHeaders = getPortfolioMetricAggregationTableHeaders(
    //     true,
    //     i18n,
    //   )
    //   if (hasFundOrSfdrStrategy) return defaultHeaders
    //   else return defaultHeaders.filter((e) => e.value !== 'sfdrGroups')
    // })
    // return { headers }
  }
});