import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMutation } from '@vue/apollo-composable';
import upsertReferencePeriodMutation from '~/graphql/mutations/UpsertReferencePeriod.gql';
export default defineComponent({
  props: {
    twoSelectors: {
      type: Boolean,
      required: false,
      default: false
    },
    accountId: {
      type: String,
      required: true
    },
    period: {
      type: Object,
      required: false,
      default: function _default() {}
    }
  },
  setup: function setup() {
    var _useMutation = useMutation(upsertReferencePeriodMutation),
      upsertReferencePeriod = _useMutation.mutate,
      onDone = _useMutation.onDone;
    return {
      upsertReferencePeriod: upsertReferencePeriod,
      onDone: onDone
    };
  },
  data: function data() {
    return {
      dates: this.period ? [this.period.startDate, this.period.endDate] : ['', '']
    };
  },
  methods: {
    handleDatesChange: function handleDatesChange(val) {
      var _this = this;
      if (val.length === 2 && val[0] && val[1]) {
        var input;
        if (this.period) {
          input = _objectSpread({}, this.period);
          delete input.__typename;
        } else {
          input = {
            userDefined: true,
            label: '',
            createdBy: this.accountId
          };
        }
        input.startDate = val[0];
        input.endDate = val[1];
        this.upsertReferencePeriod({
          input: input
        }).then(function (result) {
          var _result$data;
          var period = result === null || result === void 0 ? void 0 : (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.upsertReferencePeriod;
          if (!period) {
            console.log('no period');
            return;
          }
          _this.$emit('input', period);
        });
      }
    }
  }
});