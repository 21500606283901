import { render, staticRenderFns } from "./Check.vue?vue&type=template&id=dc9f3718"
import script from "./Check.vue?vue&type=script&lang=ts"
export * from "./Check.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactGoalEditEuTaxonomyEligibilityActivitiesInput: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/eu-taxonomy/eligibility/ActivitiesInput.vue').default,ImpactGoalEditEuTaxonomyAlignmentAssignment: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/eu-taxonomy/alignment/Assignment.vue').default,ImpactGoalEditEuTaxonomyAlignmentListCheck: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/eu-taxonomy/alignment/ListCheck.vue').default,ImpactGoalEditEuTaxonomyEligibilityCheckExplanation: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/eu-taxonomy/eligibility/CheckExplanation.vue').default,IxButtonInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Info.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
