import { TypeMetricAssignedTo } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    return {
      TypeMetricAssignedTo: TypeMetricAssignedTo
    };
  },
  data: function data() {
    return {
      selectedMetrics: [],
      customMetricFormDialog: false
    };
  },
  methods: {
    addMetricAssignmentsFromTemplate: function addMetricAssignmentsFromTemplate(items) {
      this.customMetricFormDialog = true;
      this.selectedMetrics = items;
    }
  }
});