import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import { useAddPortfolioVentureLinkMutation, useDeletePortfolioVentureLinkMutation, useListAccountsQuery } from '~/types/types';
export default defineComponent({
  props: {
    type: {
      type: String,
      default: function _default() {
        return 'table';
      }
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    additionalHeaders: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['name'];
      }
    },
    showSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    portfolioId: {
      type: String,
      default: '',
      required: false
    },
    showAdd: {
      type: Boolean,
      default: false,
      required: false
    },
    question: {
      type: Object,
      default: function _default() {},
      required: false
    },
    filters: {
      type: Object,
      default: function _default() {},
      required: false
    }
  },
  setup: function setup(props) {
    var headers = [{
      text: 'name',
      value: 'name',
      sortable: true
    }, {
      text: 'actions',
      value: 'actions'
    }].concat(_toConsumableArray(props.additionalHeaders)).filter(function (o) {
      var _props$columns;
      return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
    });
    var options = ref({
      page: 1,
      itemsPerPage: props.type === 'table' ? 10 : -1,
      sortBy: ['name'],
      sortDesc: [false]
    });
    var internalFilters = ref(_objectSpread({
      keyword: ''
    }, props.filters));
    var variables = computed(function () {
      return {
        filters: internalFilters.value,
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        sort: options.value.sortBy.map(function (item, index) {
          return {
            field: item,
            sortDesc: options.value.sortDesc[index]
          };
        })
      };
    });
    var _useListAccountsQuery = useListAccountsQuery(variables),
      result = _useListAccountsQuery.result,
      refetch = _useListAccountsQuery.refetch,
      loading = _useListAccountsQuery.loading;
    var itemCount = computed(function () {
      var _result$value$countAc, _result$value;
      return (_result$value$countAc = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.countAccounts) !== null && _result$value$countAc !== void 0 ? _result$value$countAc : 0;
    });
    var items = computed(function () {
      var _result$value2;
      return ((_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.listAccounts) || [];
    });
    var toDeleteVentureId = ref(null);
    var _useDeletePortfolioVe = useDeletePortfolioVentureLinkMutation(),
      deletePortfolioVentureLink = _useDeletePortfolioVe.mutate;
    var toAddVentureIds = ref(null);
    var _useAddPortfolioVentu = useAddPortfolioVentureLinkMutation(),
      addPortfolioVentureLink = _useAddPortfolioVentu.mutate;
    return {
      loading: loading,
      items: items,
      refetch: refetch,
      internalFilters: internalFilters,
      headers: headers,
      options: options,
      itemCount: itemCount,
      toDeleteVentureId: toDeleteVentureId,
      deletePortfolioVentureLink: deletePortfolioVentureLink,
      toAddVentureIds: toAddVentureIds,
      addPortfolioVentureLink: addPortfolioVentureLink
    };
  }
});