import _typeof from "@babel/runtime/helpers/esm/typeof";
import _ from 'lodash';
export default defineComponent({
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    options: function options() {
      return _.isEmpty(this.items) ? [{
        header: this.$t('general.formInput.combobox.header.base') + ' ' + (this.multiple ? this.$t('general.formInput.combobox.header.multiple') + ' ' : '') + this.$t('general.formInput.combobox.header.delete')
      }] : this.items;
    }
  },
  methods: {
    getItemText: function getItemText(item) {
      if (typeof item === 'string') return item;
      if (_typeof(item) === 'object' && this.$attrs['item-text']) return item[this.$attrs['item-text']];
      return item;
    }
  }
});