function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import * as Diff from 'diff';
import LIST_METRICS_QUERY from '~/graphql/queries/ListMetrics.gql';
import { MetricAggregationMethod, MetricValueKind, TypeMetricAssignedTo, useAddMetricMetricLinkMutation, useChatgptTranslateLazyQuery, useListSectionsQuery, useUpsertMetricMutation, useValidateFormulaMutation } from '~/types/types';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _nuxt2Context$app, _nuxt2Context$app$apo;
    var editedItem = ref(structuredClone(props.item));
    watch(function () {
      return props.item;
    }, function (value) {
      // sync prop changes.
      editedItem.value = structuredClone(value);
      validatedFormula.value = {};
    });

    // const { result: taxonomyResult } = useListTaxonomiesQuery({
    //   input: { label: '' },
    //   offset: 0,
    //   limit: 100,
    // })
    // const taxonomies = computed(
    //   () => taxonomyResult.value?.listTaxonomies ?? [],
    // )

    var _useUpsertMetricMutat = useUpsertMetricMutation(),
      mutate = _useUpsertMetricMutat.mutate,
      onDone = _useUpsertMetricMutat.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var isJsonString = function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };
    var save = function save() {
      var item = editedItem.value;
      // TODO fix this(when user chose TEXT in the editor, it will return a STRING)
      // const formula = isJsonString(item.formula)
      //   ? JSON.parse(item.formula)
      //   : item.formula

      var input = {
        id: item.id
      };
      for (var _i = 0, _Object$keys = Object.keys(changesByKey.value); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        if (['conditions', 'externalData'].includes(key)) {
          input[key] = isJsonString(item[key]) ? JSON.parse(item[key]) : item[key];
          // FIXME this is another place we have to do a special handling, I haven't figure out the best way yet with JSON-Editor because sometime is returns JSON sometimes STRING
        } else if (key === 'sections') {
          input.sectionIds = (item.sections || []).map(function (s) {
            return s.id;
          });
        } else {
          input[key] = item[key];
        }
      }
      mutate({
        input: input
      }).then(function () {
        window.$nuxt.$emit('userMsg');
      });
    };
    var _useAddMetricMetricLi = useAddMetricMetricLinkMutation(),
      addMetricMetricLink = _useAddMetricMetricLi.mutate;
    var _useListSectionsQuery = useListSectionsQuery({
        filters: {
          linkedToMetrics: true
        },
        limit: 1000 // we have only around 90 "indicator sets"
      }),
      sectionResult = _useListSectionsQuery.result;
    var allSections = computed(function () {
      var _sectionResult$value$, _sectionResult$value;
      return (_sectionResult$value$ = (_sectionResult$value = sectionResult.value) === null || _sectionResult$value === void 0 ? void 0 : _sectionResult$value.listSections) !== null && _sectionResult$value$ !== void 0 ? _sectionResult$value$ : [];
    });
    var _useChatgptTranslateL = useChatgptTranslateLazyQuery({
        input: {}
      }),
      translatedResult = _useChatgptTranslateL.result,
      loading = _useChatgptTranslateL.loading,
      translate = _useChatgptTranslateL.load,
      onResult = _useChatgptTranslateL.onResult;
    onResult(function () {
      // when translation API returns, show the popover
      showTranslationPopover.value = true;
    });
    var showTranslationPopover = ref(false);
    var formulaInHtml = computed(function () {
      var _editedItem$value;
      var content = "<!-- ".concat(editedItem.value.id, " -->");
      var _iterator = _createForOfIteratorHelper(((_editedItem$value = editedItem.value) === null || _editedItem$value === void 0 ? void 0 : _editedItem$value.humanReadableDirectFormula) || []),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var op = _step.value;
          if (op.type === 'VARIABLE') {
            content += "<span data-type=\"mention\" data-label=\"".concat(formatKPIName(op.variable), "\" data-id=\"").concat(op.variable.id, "\"></span>");
          } else {
            content += " ".concat(op.value, " ");
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return content;
    });
    var formulaErr = ref();
    var _useValidateFormulaMu = useValidateFormulaMutation(),
      validate = _useValidateFormulaMu.mutate;
    var validatedFormula = ref({
      formula: null,
      humanReadable: [],
      error: ''
    });
    var onFormulaInput = function onFormulaInput(expr) {
      if (!expr || expr === ' ') {
        return;
      }
      validate({
        expression: expr
      }).then(function (resp) {
        var _resp$data, _resp$data$validateFo, _data$validateFormula;
        var data = resp === null || resp === void 0 ? void 0 : resp.data;
        if (!data) {
          return;
        }
        formulaErr.value = (_resp$data = resp.data) === null || _resp$data === void 0 ? void 0 : (_resp$data$validateFo = _resp$data.validateFormula) === null || _resp$data$validateFo === void 0 ? void 0 : _resp$data$validateFo.error;
        validatedFormula.value = (data === null || data === void 0 ? void 0 : data.validateFormula) || {
          formula: null,
          humanReadable: [],
          error: ''
        };
        editedItem.value.formula = (_data$validateFormula = data.validateFormula) === null || _data$validateFormula === void 0 ? void 0 : _data$validateFormula.formula;
      });
    };
    var isCalculatedWithoutFormula = computed(function () {
      var _editedItem$value2, _editedItem$value3;
      return ((_editedItem$value2 = editedItem.value) === null || _editedItem$value2 === void 0 ? void 0 : _editedItem$value2.isComputed) && !((_editedItem$value3 = editedItem.value) !== null && _editedItem$value3 !== void 0 && _editedItem$value3.formula);
    });
    var changesByKey = computed(function () {
      var obj = {};
      for (var _i2 = 0, _Object$keys2 = Object.keys(editedItem.value); _i2 < _Object$keys2.length; _i2++) {
        var key = _Object$keys2[_i2];
        var changes = [];
        if (key === 'sections') {
          var _props$item$sections, _editedItem$value$key;
          // FIXME this is the only place we need a special handler
          // because I want to ONLY render "section.name" in the changes sections
          // it should still works without this but it will render a huge text block of sections.
          changes = Diff.diffJson(((_props$item$sections = props.item.sections) === null || _props$item$sections === void 0 ? void 0 : _props$item$sections.map(function (s) {
            return s.label;
          })) || '', ((_editedItem$value$key = editedItem.value[key]) === null || _editedItem$value$key === void 0 ? void 0 : _editedItem$value$key.map(function (s) {
            return s.label;
          })) || '').filter(function (c) {
            return c.added || c.removed;
          });
        } else {
          changes = Diff.diffJson(props.item[key] || '', editedItem.value[key] || '').filter(function (c) {
            return c.added || c.removed;
          });
        }
        if (changes.length) {
          obj[key] = changes;
        }
      }
      return obj;
    });
    var _useNuxtApp = useNuxtApp(),
      nuxt2Context = _useNuxtApp.nuxt2Context;
    var apolloClient = nuxt2Context === null || nuxt2Context === void 0 ? void 0 : (_nuxt2Context$app = nuxt2Context.app) === null || _nuxt2Context$app === void 0 ? void 0 : (_nuxt2Context$app$apo = _nuxt2Context$app.apolloProvider) === null || _nuxt2Context$app$apo === void 0 ? void 0 : _nuxt2Context$app$apo.defaultClient;
    var formatKPIName = function formatKPIName(item) {
      var _item$assignedTo, _item$name, _item$name2;
      return "".concat(item.id, " ").concat(item.isComputed ? '∑ ' : '📝', " (").concat((_item$assignedTo = item.assignedTo) !== null && _item$assignedTo !== void 0 ? _item$assignedTo : '', ") - ").concat((_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.slice(0, 30)).concat(((_item$name2 = item.name) === null || _item$name2 === void 0 ? void 0 : _item$name2.length) > 30 ? '...' : '');
    };
    var mentionItems = function mentionItems(_ref) {
      var query = _ref.query;
      return apolloClient.query({
        query: LIST_METRICS_QUERY,
        variables: {
          input: {
            text: query
          },
          limit: 10
        }
      }).then(function (_ref2) {
        var _data$listMetrics;
        var data = _ref2.data;
        return (data === null || data === void 0 ? void 0 : (_data$listMetrics = data.listMetrics) === null || _data$listMetrics === void 0 ? void 0 : _data$listMetrics.map(function (item) {
          return {
            id: item.id,
            name: formatKPIName(item)
          };
        })) || [];
      }).catch(function (error) {
        // Handle or throw the error as needed
        throw error;
      });
    };
    return {
      mentionItems: mentionItems,
      formulaInHtml: formulaInHtml,
      changesByKey: changesByKey,
      formulaErr: formulaErr,
      validatedFormula: validatedFormula,
      isCalculatedWithoutFormula: isCalculatedWithoutFormula,
      onFormulaInput: onFormulaInput,
      showTranslationPopover: showTranslationPopover,
      translate: translate,
      loading: loading,
      translatedResult: computed(function () {
        var _translatedResult$val, _translatedResult$val2, _translatedResult$val3;
        return (_translatedResult$val = translatedResult.value) === null || _translatedResult$val === void 0 ? void 0 : (_translatedResult$val2 = _translatedResult$val.chatgptTranslate) === null || _translatedResult$val2 === void 0 ? void 0 : (_translatedResult$val3 = _translatedResult$val2[0]) === null || _translatedResult$val3 === void 0 ? void 0 : _translatedResult$val3.translatedText;
      }),
      allSections: allSections,
      addMetricMetricLink: addMetricMetricLink,
      save: save,
      MetricValueKind: MetricValueKind,
      editedItem: editedItem,
      TypeMetricAssignedTo: TypeMetricAssignedTo,
      MetricAggregationMethod: MetricAggregationMethod
    };
  }
});