var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","no-gutters":""}},[_c('v-stepper',{staticClass:"mt-16 pa-10 pb-7 h-fit max-w-2xl",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('esg.outro.titleSubmit')))]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.renderMarkdown(_vm.$t('esg.outro.haveYouFinished'))
                )}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialogOn, attrs: dialogAttrs }){return [_c('v-tooltip',{attrs:{"disabled":_vm.isEditable && _vm.allQuestionsAnswered,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn, attrs: tooltipAttrs }){return [_c('span',_vm._g({staticStyle:{"width":"fit-content"}},{ ...tooltipOn, ...dialogOn }),[_c('ix-button-action',_vm._b({attrs:{"disabled":(_vm.isEditable && !_vm.allQuestionsAnswered) ||
                                !_vm.isEditable}},'ix-button-action',{ ...tooltipAttrs, ...dialogAttrs },false),[_vm._v("\n                              "+_vm._s(_vm.$t('esg.outro.submitButton'))+"\n                            ")])],1)]}}],null,true)},[_vm._v(" "),(_vm.isEditable && !_vm.allQuestionsAnswered)?_c('span',{staticStyle:{"width":"300px"}},[_vm._v("\n                          "+_vm._s(_vm.$t('esg.outro.youHaveNotAnswered'))+"\n                          {}\n                        ")]):_vm._e(),_vm._v(" "),(!_vm.isEditable)?_c('span',{staticStyle:{"width":"fit-content"}},[_vm._v("\n                          "+_vm._s(_vm.$t('esg.outro.answersAreAlreadySubmitted'))+"\n                        ")]):_vm._e()])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v("\n                          "+_vm._s(_vm.$t('esg.outro.submitYourResults')))]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                          "+_vm._s(_vm.$t('esg.outro.areYouSureYouWantTo'))+"\n                        ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("\n                            "+_vm._s(_vm.$t('esg.outro.cancelButton'))+"\n                          ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":() => {
                                _vm.submitQuestionnaire({
                                  questionnaireId: _vm.questionnaireAssignment.id,
                                  isEditable: false,
                                })
                                dialog.value = false
                                _vm.step = 2
                              }}},[_vm._v("\n                            "+_vm._s(_vm.$t('esg.outro.submit_v2'))+"\n                          ")])],1)],1)]}}])})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('esg.outro.congratulations')))]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.renderMarkdown(_vm.$t('esg.outro.congratulationsText'))
                )}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-button-action',{on:{"click":function($event){_vm.step = 3}}},[_vm._v(_vm._s(_vm.$t('esg.outro.continueButton')))])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('esg.outro.beforeYouGo')))]),_vm._v(" "),_c('ix-section-text',[_vm._v("\n              "+_vm._s(_vm.$t('esg.outro.helpUsImprove'))+"\n            ")]),_vm._v(" "),_c('ix-section-text',[_c('ix-form-input-question',{attrs:{"title":_vm.$t('esg.outro.howHappyWereYou'),"is-mandatory":""}}),_vm._v(" "),_c('v-card-actions',{staticClass:"pa-4"},[_c('span',{staticClass:"grey--text text--lighten text-caption mr-2"},[_vm._v("\n                  ("+_vm._s(_vm.$t(
                      'impactGoal.outro.afterSubmit.feedback.rating.descriptions',
                    )[_vm.feedback.rating])+")\n                ")]),_vm._v(" "),_c('v-rating',{staticClass:"ma-2 text-center",attrs:{"item-label-position":"top","large":""},model:{value:(_vm.feedback.rating),callback:function ($$v) {_vm.$set(_vm.feedback, "rating", $$v)},expression:"feedback.rating"}})],1),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":_vm.$t('esg.outro.weLoveFeedback'),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"label":_vm.$t('esg.outro.shareYourFeedback'),"outlined":"","auto-grow":"","row-height":"2","hide-details":""},model:{value:(_vm.feedback.description),callback:function ($$v) {_vm.$set(_vm.feedback, "description", $$v)},expression:"feedback.description"}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"text-right mt-5",attrs:{"cols":"12"}},[_c('ix-btn-back',{on:{"click":function($event){_vm.step = 1}}}),_vm._v(" "),_c('ix-button-action',{attrs:{"disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.submitFeedback}},[_vm._v("\n                    "+_vm._s(_vm.$t('esg.outro.submitFeedback'))+"\n                  ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }