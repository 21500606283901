import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    component: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    isIcon: {
      type: Boolean,
      required: true
    },
    imageContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    getStyle: function getStyle() {
      return {
        minWidth: this.imageContent.width,
        width: this.imageContent.width
      };
    }
  }
});