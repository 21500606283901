import { render, staticRenderFns } from "./ImpactScreening.vue?vue&type=template&id=08a6abc3&scoped=true"
import script from "./ImpactScreening.vue?vue&type=script&lang=ts"
export * from "./ImpactScreening.vue?vue&type=script&lang=ts"
import style0 from "./ImpactScreening.vue?vue&type=style&index=0&id=08a6abc3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a6abc3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxGridChipGroup: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/GridChipGroup.vue').default,ImpactCategoryRelevancy: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactCategoryRelevancy.vue').default,ImpactTopicCompanyConceptEntry: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-topic/CompanyConceptEntry.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VChip,VCol,VCombobox,VContainer,VDataIterator,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VLayout,VNavigationDrawer,VRow,VSnackbar})
