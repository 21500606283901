var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[(!_vm.value.editable)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('partsOfThisFormCannotBeEdited'))+"\n    ")])],1):_vm._e(),_vm._v(" "),_c('v-col',[_c('ix-form-input-question',{attrs:{"title":_vm.$t('nameColumn')}}),_vm._v(" "),_c('v-text-field',{attrs:{"value":_vm.value.name,"hide-details":"","outlined":"","clearable":"","disabled":!_vm.value.editable},on:{"input":(val) => {
          _vm.$emit('input', { ..._vm.value, name: val }, 'name')
        }}})],1),_vm._v(" "),_c('v-col',[_c('ix-form-input-question',{attrs:{"title":_vm.$t('unitColumn')}}),_vm._v(" "),_c('v-combobox',{attrs:{"value":_vm.value.unitName,"outlined":"","hide-details":"","small-chips":"","disabled":!_vm.value.editable,"items":_vm.units},on:{"input":(val) => _vm.$emit('input', { ..._vm.value, unitName: val }, 'unitName')}})],1),_vm._v(" "),_c('v-col',[_c('ix-form-input-question',{attrs:{"title":_vm.$t('typeColumn')}}),_vm._v(" "),_c('v-select',{attrs:{"value":_vm.value.valueType,"outlined":"","disabled":!_vm.value.editable,"items":[
          _vm.MetricValueKind.Binary,
          _vm.MetricValueKind.Float,
          _vm.MetricValueKind.String,
          _vm.MetricValueKind.Select,
          _vm.MetricValueKind.SingleSelect,
        ].map((v) => ({
          text: _vm.$t(`type.${v}`),
          value: v,
        }))},on:{"input":(val) => _vm.$emit('input', { ..._vm.value, valueType: val }, 'valueType')}})],1),_vm._v(" "),(
      [_vm.MetricValueKind.SingleSelect, _vm.MetricValueKind.Select].includes(
        _vm.valueType,
      )
    )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"value":_vm.valueOptions,"items":_vm.valueOptions,"search-input":_vm.optionSearch,"disabled":!_vm.value.editable,"hide-selected":"","label":_vm.$t('addPotentialCategories'),"multiple":"","persistent-hint":"","small-chips":""},on:{"update:searchInput":function($event){_vm.optionSearch=$event},"update:search-input":function($event){_vm.optionSearch=$event},"input":(options) =>
          _vm.$emit(
            'input',
            {
              ..._vm.value,
              valueOptions: { ..._vm.value.valueOptions, options: options },
            },
            'valueOptions',
          )},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n              Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create the category.\n            ")])],1)],1)]},proxy:true}],null,false,1485277235)})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('description'),"is-mandatory":false}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.value.description,"outlined":"","hide-details":"","disabled":!_vm.value.editable,"auto-grow":"","clearable":""},on:{"input":(val) => _vm.$emit('input', { ..._vm.value, description: val }, 'description')}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('goal'),"is-mandatory":false}}),_vm._v(" "),_c('v-btn-toggle',{staticClass:"pr-4 mt-2",attrs:{"value":_vm.value.defaultGoalType,"color":"primary","mandatory":"","dense":""},on:{"change":(val) =>
          _vm.$emit(
            'input',
            { ..._vm.value, defaultGoalType: val },
            'defaultGoalType',
          )}},[_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"none"}},[_vm._v("\n        "+_vm._s(_vm.$t('goalNone'))+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"reduce"}},[_vm._v("\n        "+_vm._s(_vm.$t('goalReduce'))+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"increase"}},[_vm._v("\n        "+_vm._s(_vm.$t('goalIncrease'))+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"approximate"}},[_vm._v("\n        "+_vm._s(_vm.$t('goalApproximate'))+"\n      ")])],1),_vm._v(" "),(_vm.value.defaultGoalType === 'approximate')?_c('v-text-field',{staticClass:"mt-4",attrs:{"value":_vm.value.defaultGoalTarget,"label":"Target","disabled":!_vm.value.editable,"dense":"","rules":[
        (v) =>
          _vm.value.defaultGoalType !== 'approximate' ||
          v === 0 ||
          !!v ||
          'Target is required',
      ],"outlined":"","clearable":"","type":"number"},on:{"input":(val) =>
          _vm.$emit(
            'input',
            { ..._vm.value, defaultGoalTarget: parseFloat(val) },
            'defaultGoalTarget',
          )}}):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('labelReportingPeriod')}}),_vm._v(" "),_c('v-btn-toggle',{staticClass:"pr-4 mt-2",attrs:{"disable":!_vm.value.editable,"value":_vm.value.defaultReportingPeriodType,"color":"primary","mandatory":"","dense":""},on:{"change":(val) =>
          _vm.$emit(
            'input',
            { ..._vm.value, defaultReportingPeriodType: val },
            'defaultReportingPeriodType',
          )}},[_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"ANNUAL"}},[_vm._v("\n        "+_vm._s(_vm.$t('labelAnnually'))+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"QUARTERLY"}},[_vm._v("\n        "+_vm._s(_vm.$t('labelQuarterly'))+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!_vm.value.editable,"value":"MONTHLY"}},[_vm._v("\n        "+_vm._s(_vm.$t('labelMonthly'))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }