import { render, staticRenderFns } from "./SdgDistribution.vue?vue&type=template&id=0cc17aca&scoped=true"
import script from "./SdgDistribution.vue?vue&type=script&lang=ts"
export * from "./SdgDistribution.vue?vue&type=script&lang=ts"
import style0 from "./SdgDistribution.vue?vue&type=style&index=0&id=0cc17aca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc17aca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PortfolioScreeningVenturesRelatedSdg: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio-screening/VenturesRelatedSdg.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDivider,VRow})
