export default defineComponent({
  props: {
    hasRisk: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      moderateThreshold: 7,
      goodThreshold: 14
    };
  },
  computed: {
    category: function category() {
      if (this.hasRisk) return 'esg.surveyPages.critical';else return 'esg.surveyPages.good';
    },
    textClass: function textClass() {
      if (this.hasRisk) return 'danger--text';else return 'success--text';
    }
  },
  methods: {
    getImgUrl: function getImgUrl(hasRisk) {
      if (hasRisk) return require('~/assets/icons/' + 'danger-flag.svg');
      return require('~/assets/icons/' + 'success-check.svg');
    }
  }
});