import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.math.sign.js";
export default defineComponent({
  props: {
    target: {
      type: Number,
      default: 0
    },
    actual: {
      type: Number,
      default: 0
    },
    goalType: {
      type: String,
      default: 'none'
    }
  },
  computed: {
    percentageDiff: function percentageDiff() {
      var actual = this.actual;
      var target = this.target;
      if (target === 0) return undefined;
      var percentage = (actual - target) / target * 100;
      return Math.abs(percentage).toFixed(0);
    },
    getThermometerConfig: function getThermometerConfig() {
      var target = this.target;
      var absTarget = Math.abs(target);
      var actual = this.actual;
      var absActual = Math.abs(actual);
      var actualHeight;
      var targetHeight;
      var zeroHeight;
      var thermometerHeight;
      var rotate;
      if (Math.sign(actual) !== Math.sign(target)) {
        thermometerHeight = absActual + 2 * absTarget;
        actualHeight = absActual / thermometerHeight;
        zeroHeight = absActual / thermometerHeight;
        targetHeight = (absActual + absTarget) / thermometerHeight;
        rotate = true;
      } else if (absActual < 2 * absTarget) {
        thermometerHeight = 2 * absTarget;
        actualHeight = absActual / thermometerHeight;
        zeroHeight = 0;
        targetHeight = absTarget / thermometerHeight;
        rotate = false;
      } else {
        thermometerHeight = absActual;
        actualHeight = 1;
        zeroHeight = 0;
        targetHeight = absTarget / thermometerHeight;
        rotate = false;
      }
      return {
        targetHeight: 100 * targetHeight,
        actualHeight: 100 * actualHeight,
        zeroHeight: 100 * zeroHeight,
        rotate: rotate
      };
    },
    getClass: function getClass() {
      if (this.target >= 0) return 'target-positive';
      return 'target-negative';
    },
    getFillingColor: function getFillingColor() {
      var target = this.target;
      var actual = this.actual;
      if (this.goalType === 'reduce') {
        if (actual > target) {
          return 'color-danger';
        } else {
          return 'color-success';
        }
      } else if (this.goalType === 'increase') {
        if (actual < target) {
          return 'color-danger';
        } else {
          return 'color-success';
        }
      } else if (this.goalType === 'approximate') {
        if (actual === target) {
          return 'color-success';
        } else {
          return 'color-danger';
        }
      } else {
        return 'color-neutral';
      }
    }
  }
});