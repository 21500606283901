import { handleUndefined, handleUndefinedArray } from '../common'
import { getIrisCategoryIcon, getSdgIcon } from './getIcon'
import { handleMetricAssignmentInfo } from './metric_assignment_info'
export const handleImpactContentInfo = (impacts: any, locale) => {
  if (impacts && impacts.length) {
    // eslint-disable-next-line array-callback-return
    const withMetricData = impacts.filter((impact: any) => {
      if (impact?.metricAssignments?.length) return impact
    })
    // eslint-disable-next-line array-callback-return
    const withOutMetricData = impacts.filter((impact: any) => {
      if (!impact?.metricAssignments?.length) return impact
    })

    withMetricData.push(...withOutMetricData)
    return withMetricData.map((impact: any) => {
      const geographies = getGeographiesLabel(
        handleUndefinedArray(impact?.geographies),
      ).join(', ')
      const irisCategories = irisCategoriesLabel(
        handleUndefinedArray(impact?.irisCategories),
      ).join(', ')
      return [
        {
          svg: `<svg width="1284" height="79" viewBox="0 0 1284 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="30" y1="0.5" x2="1284" y2="1.50011" stroke="#E8EEF1"/>
        </svg>`,
          fit: [770, 100],
          margin: [0, 10, 0, 0],
        },
        {
          margin: [12, -40, 0, 0],
          columns: [
            {
              svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0C10.4241 0 8.86371 0.310389 7.4078 0.913446C5.95189 1.5165 4.62902 2.40042 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C4.62902 21.5996 5.95189 22.4835 7.4078 23.0866C8.86371 23.6896 10.4241 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 10.608 23.748 9.228 23.268 7.932L21.348 9.852C21.516 10.56 21.6 11.28 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6C9.45392 21.6 7.01212 20.5886 5.21178 18.7882C3.41143 16.9879 2.4 14.5461 2.4 12C2.4 9.45392 3.41143 7.01212 5.21178 5.21178C7.01212 3.41143 9.45392 2.4 12 2.4C12.72 2.4 13.44 2.484 14.148 2.652L16.08 0.72C14.772 0.252 13.392 0 12 0ZM20.4 0L15.6 4.8V6.6L12.54 9.66C12.36 9.6 12.18 9.6 12 9.6C11.3635 9.6 10.753 9.85286 10.3029 10.3029C9.85286 10.753 9.6 11.3635 9.6 12C9.6 12.6365 9.85286 13.247 10.3029 13.6971C10.753 14.1471 11.3635 14.4 12 14.4C12.6365 14.4 13.247 14.1471 13.6971 13.6971C14.1471 13.247 14.4 12.6365 14.4 12C14.4 11.82 14.4 11.64 14.34 11.46L17.4 8.4H19.2L24 3.6H20.4V0ZM12 4.8C10.0904 4.8 8.25909 5.55857 6.90883 6.90883C5.55857 8.25909 4.8 10.0904 4.8 12C4.8 13.9096 5.55857 15.7409 6.90883 17.0912C8.25909 18.4414 10.0904 19.2 12 19.2C13.9096 19.2 15.7409 18.4414 17.0912 17.0912C18.4414 15.7409 19.2 13.9096 19.2 12H16.8C16.8 13.273 16.2943 14.4939 15.3941 15.3941C14.4939 16.2943 13.273 16.8 12 16.8C10.727 16.8 9.50606 16.2943 8.60589 15.3941C7.70571 14.4939 7.2 13.273 7.2 12C7.2 10.727 7.70571 9.50606 8.60589 8.60589C9.50606 7.70571 10.727 7.2 12 7.2V4.8Z" fill="#5188ff "/>
              </svg>`,
              width: 20,
              height: 20,
            },
            {
              text: handleUndefined(impact.concept ? impact.concept.label : ''),
              style: ['outcome'],
            },
          ],
        },
        {
          margin: [0, 8, 0, 0],
          columns: [
            [
              {
                columns: [
                  {
                    svg: `<svg width="57" height="49" viewBox="0 0 57 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5781 23.0938C15.2969 23.375 14.9375 23.5156 14.5 23.5156C14.0625 23.5156 13.7031 23.375 13.4219 23.0938C13.1406 22.7812 13 22.4219 13 22.0156C13 21.6094 13.1406 21.2656 13.4219 20.9844C13.7031 20.6719 14.0625 20.5156 14.5 20.5156C14.9375 20.5156 15.2969 20.6719 15.5781 20.9844C15.8594 21.2656 16 21.6094 16 22.0156C16 22.4219 15.8594 22.7812 15.5781 23.0938ZM32.1719 21.6875C32.1719 23.4062 31.5625 24.9375 30.3438 26.2812C29.1562 27.625 27.7031 28.4062 25.9844 28.625V32.5156H28.9844V34.4844H13V29.5156H12.0156V25.4844C12.0156 25.2031 12.1094 24.9688 12.2969 24.7812C12.4844 24.5938 12.7188 24.5 13 24.5H16C16.2812 24.5 16.5156 24.5938 16.7031 24.7812C16.8906 24.9688 16.9844 25.2031 16.9844 25.4844V29.5156H16V32.5156H24.0156V28.5781C22.3906 28.3281 21 27.5312 19.8438 26.1875C18.7188 24.8438 18.1562 23.3438 18.1562 21.6875C18.1562 19.75 18.8438 18.0938 20.2188 16.7188C21.5938 15.3438 23.25 14.6562 25.1875 14.6562C27.125 14.6562 28.7656 15.3438 30.1094 16.7188C31.4844 18.0938 32.1719 19.75 32.1719 21.6875Z" fill="#8FA5AF"/>
                    </svg>`,
                    width: 40,
                    height: 40,
                    margin: [6, -10, 0, 0],
                  },
                  {
                    text: handleUndefined(impact?.stakeholder),
                    style: ['stakeholder'],
                  },
                ],
              },
            ],
            [
              {
                columns: [
                  {
                    svg: `<svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.9987 11.4173C8.22515 11.4173 7.48329 11.11 6.9363 10.563C6.38932 10.0161 6.08203 9.2742 6.08203 8.50065C6.08203 7.7271 6.38932 6.98524 6.9363 6.43826C7.48329 5.89128 8.22515 5.58398 8.9987 5.58398C9.77225 5.58398 10.5141 5.89128 11.0611 6.43826C11.6081 6.98524 11.9154 7.7271 11.9154 8.50065C11.9154 8.88367 11.8399 9.26295 11.6933 9.61681C11.5468 9.97068 11.3319 10.2922 11.0611 10.563C10.7903 10.8339 10.4687 11.0487 10.1149 11.1953C9.76099 11.3419 9.38172 11.4173 8.9987 11.4173V11.4173ZM8.9987 0.333984C6.83276 0.333984 4.75554 1.1944 3.22399 2.72595C1.69245 4.25749 0.832031 6.33472 0.832031 8.50065C0.832031 14.6257 8.9987 23.6673 8.9987 23.6673C8.9987 23.6673 17.1654 14.6257 17.1654 8.50065C17.1654 6.33472 16.305 4.25749 14.7734 2.72595C13.2419 1.1944 11.1646 0.333984 8.9987 0.333984V0.333984Z" fill="#8FA5AF"/>
                    </svg>`,
                    width: 15,
                    height: 15,
                    margin: [0, 3, 0, 0],
                  },
                  {
                    text: geographies.length ? geographies : 'N/A',
                    style: ['geographies'],
                    margin: [-20, 7, 0, 0],
                  },
                ],
              },
            ],
            [
              {
                columns: [
                  getIrisCategoryIcon(),
                  {
                    text: irisCategories.length ? irisCategories : 'N/A',
                    style: ['irisCategories'],
                    margin: [-20, 6, 0, 0],
                  },
                ],
              },
            ],
            [
              {
                margin: [-20, 0, 0, 0],
                columns: [getSdgIcon(), getSdgImages(impact?.sdgs)],
              },
            ],
          ],
        },
        {
          margin: [15, 5, 0, 0],
          columns: [
            {
              svg: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path fill="#8FA5AF" d="M12 2a9 9 0 0 0-9 9a8.99 8.99 0 0 0 4 7.47V22h2v-3h2v3h2v-3h2v3h2v-3.54c2.47-1.65 4-4.46 4-7.46a9 9 0 0 0-9-9m-4 9a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m8 0a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-4 3l1.5 3h-3l1.5-3Z"/></svg>`,
              width: 15,
              height: 15,
            },
            {
              text: impact?.problem ? handleUndefined(impact?.problem) : 'N/A',
              margin: [-20, 3, 0, 0],
              style: ['theoryOfChange'],
            },
          ],
        },
        // {
        //   margin: [15, 5, 0, 0],
        //   columns: [
        //     {
        //       // lightbulb
        //       svg: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M20.8991 11.4696H24.1719V13.6515H20.8991V11.4696ZM0.171875 11.4696H3.4446V13.6515H0.171875V11.4696ZM13.2628 0.560547V3.83327H11.081V0.560547H13.2628ZM4.44824 3.28782L6.77188 5.62236L5.22278 7.16055L2.89915 4.84782L4.44824 3.28782ZM17.5719 5.61146L19.8846 3.28782L21.4446 4.84782L19.121 7.16055L17.5719 5.61146ZM12.1719 6.01509C13.9078 6.01509 15.5727 6.7047 16.8002 7.93221C18.0277 9.15972 18.7173 10.8246 18.7173 12.5605C18.7173 14.9824 17.3973 17.0987 15.4446 18.2333V20.1969C15.4446 20.4862 15.3297 20.7637 15.1251 20.9683C14.9205 21.1729 14.643 21.2878 14.3537 21.2878H9.99006C9.70073 21.2878 9.42325 21.1729 9.21867 20.9683C9.01408 20.7637 8.89915 20.4862 8.89915 20.1969V18.2333C6.94642 17.0987 5.62642 14.9824 5.62642 12.5605C5.62642 10.8246 6.31603 9.15972 7.54354 7.93221C8.77105 6.7047 10.4359 6.01509 12.1719 6.01509ZM14.3537 22.3787V23.4696C14.3537 23.759 14.2388 24.0364 14.0342 24.241C13.8296 24.4456 13.5521 24.5605 13.2628 24.5605H11.081C10.7916 24.5605 10.5142 24.4456 10.3096 24.241C10.105 24.0364 9.99006 23.759 9.99006 23.4696V22.3787H14.3537ZM11.081 19.106H13.2628V16.7824C15.1501 16.3024 16.5355 14.5896 16.5355 12.5605C16.5355 11.4032 16.0758 10.2933 15.2574 9.47499C14.4391 8.65665 13.3292 8.19691 12.1719 8.19691C11.0146 8.19691 9.90466 8.65665 9.08632 9.47499C8.26798 10.2933 7.80824 11.4032 7.80824 12.5605C7.80824 14.5896 9.19369 16.3024 11.081 16.7824V19.106Z" fill="#8FA5AF"/>
        //     </svg>`,
        //       width: 15,
        //       height: 15,
        //     },
        //     {
        //       text: impact?.theoryOfChange
        //         ? handleUndefined(impact?.theoryOfChange)
        //         : 'N/A',
        //       margin: [-20, 3, 0, 0],
        //       style: ['theoryOfChange'],
        //     },
        //   ],
        // },
        handleMetricAssignmentInfo(impact?.metricAssignments, locale),
      ]
    })
  }
}

const getGeographiesLabel = (geographies: any) => {
  return geographies.map((geography: any) => {
    return geography?.label
  })
}

const irisCategoriesLabel = (irisCategories: any) => {
  return irisCategories.map((irisCategory: any) => {
    return irisCategory?.label
  })
}

const getSdgImages = (sdgs: any) => {
  const sdgImages: any = []
  if (!sdgs || !sdgs.length)
    return { text: 'N/A', style: ['irisCategories'], margin: [-10, 5, 0, 0] }

  if (sdgs.length > 3) {
    const reducedSdgs = sdgs.slice(0, 2)
    sdgImages.push(reducedSdgs, [
      sdgs[2],
      {
        text: '...',
        bold: true,
        fontSize: 18,
        margin: [7, 0, 0, 0],
      },
    ])
  } else sdgImages.push(sdgs)

  return sdgImages.map((sdgImage: any) => {
    return sdgSectionTableLayout(sdgImage)
  })
}

const sdgSectionTableLayout = (sdgs: any) => {
  const sdgImages = sdgs.map((sdg: any) => {
    if (sdg.text) return sdg
    else
      return {
        image: sdg.dataURL,
        width: 30,
        height: 30,
      }
  })
  return {
    layout: 'noBorders',
    margin: [-20, 0, 0, 0],
    table: {
      widths: ['*'],
      body: [[{ columns: sdgImages, columnGap: 3 }]],
    },
  }
}

export const impactContentStyle = {
  outcome: {
    color: '#5188ff ',
    fontSize: 14,
    alignment: 'left',
    margin: [-10, 0, 0, 0],
  },
  theoryOfChange: {
    color: '#5B7185',
    fontSize: 10,
  },
  stakeholder: {
    fontSize: 10,
    color: '#5B7185',
    alignment: 'left',
    margin: [-30, 7, 0, 0],
  },
  geographies: {
    fontSize: 10,
    color: '#5B7185',
  },
  irisCategories: {
    fontSize: 10,
    color: '#5B7185',
  },
}
