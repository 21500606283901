import "core-js/modules/es6.number.constructor.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: Number,
      required: false,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    ixId: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    scoringRule: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    isMandatory: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      renderMarkdown: renderMarkdown
    };
  },
  computed: {
    translatedTitle: function translatedTitle() {
      if (this.ixId) return this.$t('database.question.' + this.ixId + '.label');
      return this.title;
    },
    translatedSubtitle: function translatedSubtitle() {
      if (this.ixId) return this.$t('database.question.' + this.ixId + '.sublabel');
      return this.subtitle;
    },
    translatedTooltip: function translatedTooltip() {
      if (this.ixId) return this.$t('database.question.' + this.ixId + '.tooltip');
      return this.tooltip;
    },
    showScoringRuleDialog: function showScoringRuleDialog() {
      var _ref, _this$scoringRule;
      return (_ref = ((_this$scoringRule = this.scoringRule) === null || _this$scoringRule === void 0 ? void 0 : _this$scoringRule.rule) === 'concept-scores-by-scheme') !== null && _ref !== void 0 ? _ref : false;
    },
    tooltipTitle: function tooltipTitle() {
      return this.showScoringRuleDialog ? this.$t('esg.surveyPages.questionScoringRules') : '';
    }
  },
  methods: {}
});