var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('v-card-text',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('description')))]),_vm._v(" "),_c('span',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_vm.description))}})]),_vm._v(" "),(
        !_vm.loading &&
        (_setup.howCanYou?.narrowerConcepts?.length || _setup.howDoOthers?.length)
      )?_c('div',{staticClass:"mt-6"},[_c('v-expansion-panels',{attrs:{"accordion":"","focusable":""}},[(_setup.howCanYou?.narrowerConcepts)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"purple"}},[_vm._v(" mdi-creation ")]),_vm._v("\n              "+_vm._s(_vm.$t('howCanYou'))+"\n            ")],1)]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"pt-4"},_vm._l((_setup.howCanYou?.narrowerConcepts),function(gptqaInsight){return _c('div',{key:gptqaInsight.label},[_c('h6',[_vm._v(_vm._s(gptqaInsight.label))]),_vm._v(" "),_c('ul',_vm._l((gptqaInsight?.narrowerConcepts),function(narrowerConcept){return _c('li',{key:narrowerConcept.label},[_vm._v("\n                  "+_vm._s(narrowerConcept.label)+"\n                  "),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"purple"}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-information-outline\n                      ")])]}}],null,true)},[_vm._v(" "),_c('v-list-item',{staticClass:"white-button pa-4 align-center"},[_c('v-list-item-icon',{staticClass:"mr-2 my-0 align-self-center"},[_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":"blue-grey lighten-3"}},[_vm._v("\n                            mdi-comment-quote-outline\n                          ")])],1)],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pb-0 pt-0"},[_c('v-list-item-title',{staticClass:"text-wrap pb-2",staticStyle:{"line-height":"1"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                              _setup.renderMarkdown(narrowerConcept.insight.text)
                            )}})]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"blue-grey--text text--lighten-3"},[_vm._v("\n                          Source:\n                          "),_c('a',{attrs:{"rel":"noopener","target":"_blank","href":_setup.getClickableLink(narrowerConcept.insight.url)}},[_vm._v("\n                            "+_vm._s(narrowerConcept.insight.url)+"\n                          ")])])],1)],1)],1)],1)}),0)])}),0)],1):_vm._e(),_vm._v(" "),(_setup.howDoOthers?.length)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('span',[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"purple"}},[_vm._v("mdi-creation")]),_vm._v("\n              "+_vm._s(_vm.$t('howDidOthers'))+"\n            ")],1)]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"pt-4"},[_c('v-carousel',{attrs:{"hide-delimiters":"","height":"150"}},_vm._l((_setup.howDoOthers),function(snippet,index){return _c('v-carousel-item',{key:index},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('impact-goal-evidence-card-list-item',{attrs:{"evidence":{
                        comment: snippet.text,
                        url: snippet.url,
                      },"is-editable":false}})],1)],1)],1)}),1)],1)],1):_vm._e()],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }