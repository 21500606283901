var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.type == 'select')?_c('v-select',{attrs:{"loading":_vm.loading,"multiple":"","items":_vm.items,"hide-details":"","item-text":"name","value":_vm.value,"item-value":"id"},on:{"input":(v) => _vm.$emit('input', v)}}):_c('div',[(_vm.question && _vm.question.title !== '')?_c('ix-form-input-question',{attrs:{"title":_vm.question.title,"subtitle":_vm.question.subtitle,"tooltip-title":_vm.question.tooltipTitle,"is-mandatory":_vm.question.isMandatory}}):_vm._e(),_vm._v(" "),_c('ix-data-table',{attrs:{"value":_vm.value,"dense":"","item-key":"id","headers":_vm.headers.map((header) => ({
        text: _vm.$t(header.text),
        value: header.value,
      })),"show-select":_vm.showSelect,"items":_vm.items,"sort-by":"name","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemCount,"footer-props":{
      'items-per-page-options': [10, 20, 30, -1],
    }},on:{"update:options":function($event){_vm.options=$event},"input":(v) => _vm.$emit('input', v)},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.internalFilters.keyword,"append-icon":"mdi-magnify","label":_vm.$t('labelSearchVentures'),"single-line":"","hide-details":""},on:{"input":(v) => {
              _vm.internalFilters.keyword = v
              _vm.options.page = 1
            }}}),_vm._v(" "),(_vm.showAdd)?_c('v-dialog',{attrs:{"scrollable":"","value":_vm.toAddVentureIds != null,"max-width":"650px"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs }){return [_c('v-btn',_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => (_vm.toAddVentureIds = [])}},'v-btn',attrs,false),[_vm._v("\n              Add\n            ")])]}}],null,false,892014296)},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add existing companies to portfolio")])]),_vm._v(" "),_c('v-card-text',[_c('ix-data-table-accounts',{staticClass:"max-h-[670px] overflow-scroll",attrs:{"filter":({ ventureId }) => {
                    return !_vm.items.map(({ id }) => id).includes(ventureId) // filter out companies already in this portfolio
                  }},model:{value:(_vm.toAddVentureIds),callback:function ($$v) {_vm.toAddVentureIds=$$v},expression:"toAddVentureIds"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":() => (_vm.toAddVentureIds = null)}},[_vm._v("\n                Close\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":() => {
                    _vm.addPortfolioVentureLink({
                      portfolioId: _vm.portfolioId,
                      ventureIds: _vm.toAddVentureIds,
                    }).then(() => {
                      _vm.toAddVentureIds = null // close the dialog
                      _vm.refetch()
                    })
                  }}},[_vm._v("\n                Save\n              ")])],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-dialog',{attrs:{"value":_vm.toDeleteVentureId != null,"max-width":"500px"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n            "+_vm._s(_vm.$t('removeTitle'))+"\n          ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.toDeleteVentureId = null}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":() =>
                  _vm.deletePortfolioVentureLink({
                    portfolioId: _vm.portfolioId,
                    ventureId: _vm.toDeleteVentureId,
                  }).then(() => {
                    _vm.toDeleteVentureId = null
                    _vm.refetch()
                  })}},[_vm._v("\n              OK\n            ")])],1)],1)],1)]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.name,"filter":_vm.internalFilters.keyword}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":""},on:{"click":function($event){_vm.toDeleteVentureId = item.id}}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }