var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-header-portfolio'),_vm._v(" "),_c('ix-data-table-audit-logs',{attrs:{"columns":[
      'diff',
      'tableName',
      'dataId',
      'operationType',
      'createdBy',
      'createdAt',
    ],"default-filters":{}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }