import { handleUndefined } from '../common'
import { getScoreDetail } from './common'
// eslint-disable-next-line import/namespace
import { percentage, scoreByMax, scoreText, skipped } from '~/functions/esg'
import { getSecondQuestionIfExists } from './environmental_info'
import { iconMapping, largeProgressBar } from './esg_report'

let data: any
let dataFirstQuestion: any
let stakeholder: any
let stakeholderFirstQuestion: any
let business: any
let businessFirstQuestion: any
let management: any
let managementFirstQuestion: any
let regulations: any
let regulationsFirstQuestion: any
export const governanceSlideContent = (tableData: any) => {
  if (tableData) {
    const subsectionsMap = new Map(
      tableData.subsections.map((subsection) => [subsection.key, subsection]),
    )

    data = subsectionsMap.get('data')
    dataFirstQuestion = data?.questionSummaries[0]

    stakeholder = subsectionsMap.get('stakeholderEngagement')
    stakeholderFirstQuestion = stakeholder?.questionSummaries[0]

    business = subsectionsMap.get('businessEthics')
    businessFirstQuestion = business?.questionSummaries[0]

    management = subsectionsMap.get('managementAdvisoryBoard')
    managementFirstQuestion = management?.questionSummaries[0]

    regulations = subsectionsMap.get('regulationsStandards')
    regulationsFirstQuestion = regulations?.questionSummaries[0]

    const percentageValue = percentage(tableData.result)
    const label = tableData.label
    return [
      {
        svg: `<svg width="1377" height="65" viewBox="0 0 1377 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0, -50) scale(1.6, 1.6)">
        ${iconMapping.governance}
        </g>
        <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="30" font-weight="600" letter-spacing="0em">
        <tspan x="100" y="50">${label}</tspan>
        </text>
        <g transform="translate(440, 30)">
        ${largeProgressBar(
          getScoreDetail(percentageValue, skipped(tableData.result)),
          percentageValue,
          scoreByMax(tableData.result),
        )}
        </g>
        </svg>`,
        width: 770,
        margin: [0, -10, 0, 0],
      },
      tableData && table(),
    ]
  }
}
const table = () => {
  const scoreColor = (result) =>
    getScoreDetail(percentage(result), skipped(result)).color
  return {
    layout: 'noBorders',
    margin: [0, 10, 0, 0],
    table: {
      widths: [20, 210, 175, 175, 150],
      body: [
        [
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: 'Actions taken',
            style: ['header'],
          },
          {
            text: 'Planned in next 12 months',
            style: ['header'],
          },
          {
            text: 'Comments',
            style: ['header'],
          },
        ],
        [
          {
            svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00001 6.252V1.979C6.99948 1.85096 7.02418 1.72407 7.07269 1.60558C7.12121 1.48708 7.19258 1.37931 7.28275 1.2884C7.37291 1.19749 7.4801 1.12523 7.5982 1.07575C7.71629 1.02626 7.84297 1.00052 7.97101 1H17.671L23.495 6.87V22.522C23.4958 22.7804 23.394 23.0286 23.2119 23.212C23.0298 23.3953 22.7824 23.4989 22.524 23.5H16" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 1V6C17.5 6.26522 17.6054 6.51957 17.7929 6.70711C17.9804 6.89464 18.2348 7 18.5 7H23.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.054 10.75H13" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.054 19.75H18.25" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.054 15.25H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 20.5L1.75 23.5H12.25L13 20.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 17.5H8.5V14.834C9.07193 14.5038 9.51891 13.9941 9.77164 13.384C10.0244 12.7739 10.0687 12.0974 9.89778 11.4595C9.72685 10.8216 9.35022 10.2579 8.82628 9.8559C8.30235 9.45387 7.6604 9.23596 7 9.23596C6.3396 9.23596 5.69765 9.45387 5.17372 9.8559C4.64978 10.2579 4.27315 10.8216 4.10222 11.4595C3.9313 12.0974 3.97564 12.7739 4.22836 13.384C4.48109 13.9941 4.92807 14.5038 5.5 14.834V17.5H4C3.20435 17.5 2.44129 17.8161 1.87868 18.3787C1.31607 18.9413 1 19.7044 1 20.5H13C13 19.7044 12.6839 18.9413 12.1213 18.3787C11.5587 17.8161 10.7956 17.5 10 17.5V17.5Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              data.label,
              {
                text: `   ${scoreText(data.result)}\n`,
                color: scoreColor(data.result),
                style: ['totalScore'],
              },
              {
                text: dataFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...dataFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...dataFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(dataFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(data.questionSummaries),
        [
          {
            svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 8.5H10L5.5 13V8.5H2.5C2.10218 8.5 1.72064 8.34196 1.43934 8.06066C1.15804 7.77936 1 7.39782 1 7V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H22C22.3978 1 22.7794 1.15804 23.0607 1.43934C23.342 1.72064 23.5 2.10218 23.5 2.5V7C23.5 7.39782 23.342 7.77936 23.0607 8.06066C22.7794 8.34196 22.3978 8.5 22 8.5Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 11.5C2.10218 11.5 1.72064 11.658 1.43934 11.9393C1.15804 12.2206 1 12.6022 1 13V17.5C1 17.8978 1.15804 18.2794 1.43934 18.5607C1.72064 18.842 2.10218 19 2.5 19H14.5L19 23.5V19H22C22.3978 19 22.7794 18.842 23.0607 18.5607C23.342 18.2794 23.5 17.8978 23.5 17.5V13C23.5 12.6022 23.342 12.2206 23.0607 11.9393C22.7794 11.658 22.3978 11.5 22 11.5H11.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              stakeholder.label,
              {
                text: `   ${scoreText(stakeholder.result)}\n`,
                color: scoreColor(stakeholder.result),
                style: ['totalScore'],
              },
              {
                text: stakeholderFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...stakeholderFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...stakeholderFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(stakeholderFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(stakeholder.questionSummaries),
        [
          {
            svg: `<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9508 9.82392L10.9508 15.8047C10.9508 16.0569 10.8506 16.2987 10.6723 16.477C10.494 16.6554 10.2522 16.7555 9.99997 16.7555C9.74779 16.7555 9.50594 16.6554 9.32762 16.477C9.1493 16.2987 9.04912 16.0569 9.04912 15.8047L9.04912 9.82392" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.04976 6.02193L9.04976 5.07108C9.04757 4.94488 9.07053 4.81951 9.1173 4.70228C9.16408 4.58505 9.23374 4.47831 9.32221 4.38829C9.41068 4.29827 9.51619 4.22677 9.63259 4.17796C9.74899 4.12916 9.87394 4.10402 10.0002 4.10402C10.1264 4.10402 10.2513 4.12916 10.3677 4.17796C10.4841 4.22677 10.5896 4.29827 10.6781 4.38829C10.7666 4.47831 10.8362 4.58505 10.883 4.70228C10.9298 4.81951 10.9528 4.94488 10.9506 5.07108L10.9506 6.02193" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.9788 9.82397L7.021 9.82397" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.02148 6.02185L12.9784 6.02185" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.7527 9.34851C14.7528 9.47344 14.7284 9.59716 14.6807 9.71262C14.633 9.82809 14.563 9.93302 14.4747 10.0214C14.3865 10.1099 14.2817 10.18 14.1663 10.2279C14.0509 10.2759 13.9273 10.3006 13.8023 10.3007C13.6774 10.3008 13.5537 10.2763 13.4382 10.2286C13.3228 10.1809 13.2178 10.111 13.1294 10.0227C13.041 9.93445 12.9708 9.82965 12.9229 9.71428C12.875 9.59891 12.8503 9.47523 12.8501 9.3503L12.8501 6.49866C12.85 6.37373 12.8745 6.25001 12.9222 6.13454C12.9699 6.01908 13.0399 5.91415 13.1281 5.82573C13.2164 5.73731 13.3212 5.66714 13.4366 5.61922C13.5519 5.57131 13.6756 5.54658 13.8005 5.54647C13.9255 5.54635 14.0492 5.57084 14.1646 5.61854C14.2801 5.66623 14.385 5.73621 14.4735 5.82446C14.5619 5.91271 14.6321 6.01752 14.68 6.13289C14.7279 6.24826 14.7526 6.37194 14.7527 6.49687L14.7527 9.34851Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.1499 9.35026C7.14978 9.47518 7.12506 9.59886 7.07714 9.71423C7.02922 9.8296 6.95905 9.93441 6.87063 10.0227C6.78221 10.1109 6.67728 10.1809 6.56182 10.2286C6.44636 10.2763 6.32263 10.3008 6.1977 10.3007C6.07278 10.3005 5.9491 10.2758 5.83373 10.2279C5.71836 10.18 5.61355 10.1098 5.5253 10.0214C5.34707 9.84282 5.24707 9.60076 5.2473 9.34846L5.24731 6.49682C5.24733 6.37198 5.27195 6.24837 5.31975 6.13305C5.36755 6.01772 5.4376 5.91294 5.52589 5.82469C5.61419 5.73644 5.719 5.66644 5.83435 5.6187C5.94969 5.57095 6.07331 5.54639 6.19815 5.54642C6.32299 5.54645 6.4466 5.57107 6.56192 5.61887C6.67725 5.66667 6.78203 5.73671 6.87028 5.82501C6.95853 5.9133 7.02853 6.01812 7.07628 6.13346C7.12402 6.24881 7.14858 6.37243 7.14855 6.49727L7.1499 9.35026Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19 23.5H3.25C2.65326 23.5 2.08097 23.2629 1.65901 22.841C1.23705 22.419 1 21.8467 1 21.25" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 1C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V21.25C1 20.6533 1.23705 20.081 1.65901 19.659C2.08097 19.2371 2.65326 19 3.25 19H18.25C18.4489 19 18.6397 18.921 18.7803 18.7803C18.921 18.6397 19 18.4489 19 18.25V1.75C19 1.55109 18.921 1.36032 18.7803 1.21967C18.6397 1.07902 18.4489 1 18.25 1H4Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 23.5V19" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              business.label,
              {
                text: `   ${scoreText(business.result)}\n`,
                color: scoreColor(business.result),
                style: ['totalScore'],
              },
              {
                text: businessFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...businessFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...businessFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(businessFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(business.questionSummaries),
        [
          {
            svg: `<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0713 18.6786H22.9416C23.3221 18.6785 23.6903 18.8133 23.9806 19.0591C24.271 19.305 24.4647 19.6458 24.5274 20.0211L25.0856 21.6282C25.124 21.8585 25.1118 22.0944 25.0498 22.3196C24.9878 22.5447 24.8776 22.7536 24.7266 22.9317C24.5757 23.1099 24.3878 23.253 24.1759 23.3511C23.9641 23.4493 23.7334 23.5001 23.4999 23.5H17.8749" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.20893 18.6786V17.5C3.20932 16.0286 3.54058 14.5762 4.1782 13.2501C4.81582 11.9241 5.74347 10.7584 6.8925 9.83929H2.56178C2.25282 9.839 1.95086 9.74717 1.69406 9.57538C1.43725 9.40359 1.23711 9.15956 1.11891 8.87409C1.00071 8.58863 0.969761 8.27454 1.02996 7.9715C1.09016 7.66845 1.23881 7.39004 1.45714 7.17143L5.42143 3.20714V1L5.81143 1.08679C7.57988 1.48065 9.16105 2.46605 10.2937 3.88017C11.4263 5.29429 12.0426 7.0525 12.0407 8.86429V9.83929C8.35821 13.5229 12.78 18.7 12.78 18.7" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.3664 9.29395L15.2446 15.4643" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.8927 18.6785L20.187 9.29602" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2767 9.83932C20.2738 9.83932 21.8928 8.22035 21.8928 6.22325C21.8928 4.22615 20.2738 2.60718 18.2767 2.60718C16.2796 2.60718 14.6606 4.22615 14.6606 6.22325C14.6606 8.22035 16.2796 9.83932 18.2767 9.83932Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5781 21.385L14.0424 19.7779C13.9357 19.4578 13.7311 19.1794 13.4575 18.9821C13.1838 18.7848 12.8551 18.6786 12.5177 18.6786H3.14271C2.80536 18.6786 2.47657 18.7848 2.20293 18.9821C1.92929 19.1794 1.72467 19.4578 1.61807 19.7779L1.08235 21.385C1.00189 21.6266 0.979982 21.8838 1.01842 22.1355C1.05686 22.3872 1.15454 22.6261 1.30343 22.8327C1.45232 23.0392 1.64815 23.2074 1.87479 23.3235C2.10142 23.4395 2.35238 23.5 2.60699 23.5H13.0534C13.308 23.5 13.559 23.4395 13.7856 23.3235C14.0123 23.2074 14.2081 23.0392 14.357 22.8327C14.5059 22.6261 14.6036 22.3872 14.642 22.1355C14.6804 21.8838 14.6585 21.6266 14.5781 21.385V21.385Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              management.label,
              {
                text: `   ${scoreText(management.result)}\n`,
                color: scoreColor(management.result),
                style: ['totalScore'],
              },
              {
                text: managementFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...managementFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...managementFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(managementFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(management.questionSummaries),
        [
          {
            svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5 7C22.1569 7 23.5 5.65685 23.5 4C23.5 2.34315 22.1569 1 20.5 1C18.8431 1 17.5 2.34315 17.5 4C17.5 5.65685 18.8431 7 20.5 7Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.06409 1.26807C5.10497 1.18756 5.16735 1.11995 5.2443 1.07272C5.32126 1.02549 5.40979 1.00049 5.50009 1.00049C5.59038 1.00049 5.67891 1.02549 5.75587 1.07272C5.83282 1.11995 5.8952 1.18756 5.93609 1.26807L6.95009 3.31007C6.98516 3.38013 7.03668 3.44065 7.10025 3.48645C7.16381 3.53226 7.23753 3.56198 7.31509 3.57307L9.58809 3.90107C9.67681 3.91382 9.76026 3.95095 9.82912 4.00834C9.89798 4.06572 9.94955 4.1411 9.97809 4.22607C10.0059 4.31071 10.0093 4.40149 9.98792 4.48798C9.96652 4.57447 9.92118 4.65318 9.85709 4.71507L8.20709 6.31507C8.15167 6.3688 8.11016 6.4352 8.08612 6.50854C8.06208 6.58188 8.05623 6.65997 8.06909 6.73607L8.45809 8.99107C8.47321 9.07944 8.46302 9.17028 8.42869 9.2531C8.39436 9.33591 8.33729 9.40732 8.26409 9.45907C8.19033 9.51132 8.10356 9.54214 8.01338 9.54814C7.9232 9.55414 7.83311 9.53508 7.75309 9.49307L5.72709 8.43507C5.65713 8.39812 5.5792 8.3788 5.50009 8.3788C5.42097 8.3788 5.34304 8.39812 5.27309 8.43507L3.25009 9.49307C3.17006 9.53508 3.07997 9.55414 2.98979 9.54814C2.89961 9.54214 2.81284 9.51132 2.73909 9.45907C2.66588 9.40732 2.60881 9.33591 2.57448 9.2531C2.54015 9.17028 2.52996 9.07944 2.54509 8.99107L2.93409 6.73607C2.94694 6.65997 2.94109 6.58188 2.91705 6.50854C2.89301 6.4352 2.8515 6.3688 2.79609 6.31507L1.15009 4.71507C1.086 4.65318 1.04065 4.57447 1.01925 4.48798C0.997847 4.40149 1.00126 4.31071 1.02909 4.22607C1.05752 4.14104 1.10906 4.0656 1.17794 4.0082C1.24682 3.9508 1.33032 3.91371 1.41909 3.90107L3.69109 3.57307C3.76757 3.56107 3.84007 3.53093 3.90253 3.48518C3.96499 3.43942 4.01558 3.37938 4.05009 3.31007L5.06409 1.26807Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 1H10" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 4V19C17.5 20.1935 17.0259 21.3381 16.182 22.182C15.3381 23.0259 14.1935 23.5 13 23.5H1C2.19347 23.5 3.33807 23.0259 4.18198 22.182C5.02589 21.3381 5.5 20.1935 5.5 19V13" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 17.5H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 14.5H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 11.5H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 8.5H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
            width: 15,
            height: 15,
          },
          {
            text: [
              regulations.label,
              {
                text: `   ${scoreText(regulations.result)}\n`,
                color: scoreColor(regulations.result),
                style: ['totalScore'],
              },
              {
                text: regulationsFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...regulationsFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...regulationsFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(regulationsFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(regulations.questionSummaries),
      ],
    },
  }
}
