var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-data-table',_vm._b({attrs:{"multi-sort":"","height":_vm.height,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemCount,"footer-props":{
      'items-per-page-options': [10, 25, 50, -1],
    },"items-per-page":_vm.options.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"fixed-header":"","color":"primary","single-select":"","single-expand":"","show-expand":"","expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_vm._v("loading...")]),_vm._v(" "),_c('ix-data-table-metric-assignments',{attrs:{"fixed-header":"","show-creation":"","show-search":"","items-per-page":10,"height":'500px',"filters":{
            aggregationIds: [item.id],
          },"headers":[
            'assigneeId',
            'expandToggle',
            'metric.name',
            'unitName',
            'comment',
          ],"comment-hint":_vm.$t('addCommentHint')}})],1)]}},{key:`header.id`,fn:function({ header }){return [_vm._v("\n      "+_vm._s(header.text)),_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-lock")])]}},{key:`header.metric.id`,fn:function({ header }){return [_vm._v("\n      "+_vm._s(header.text)),_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-lock")])]}},{key:`item.metric.name`,fn:function({ item }){return [_c('metrics-cells-metric-name',{attrs:{"id":item.metric.id,"calculation-type":item.calculationType,"badge":item.calculationType,"name":item.metric.name,"search":_vm.search,"is-computed":true,"is-portfolio":""}})]}},{key:`item.metric.commaSeparatedTaxonomies`,fn:function({ item }){return [_vm._v("\n      "+_vm._s(item.metric.commaSeparatedTaxonomies)+"\n    ")]}},{key:`item.group`,fn:function({ item }){return [_vm._v("\n      "+_vm._s(_vm.groupName(item))+"\n    ")]}},{key:`item.unitName`,fn:function({ item }){return [_vm._v("\n      "+_vm._s(_vm.getSafe(() => item.metric.unitName))+"\n    ")]}},{key:`item.valueLegend`,fn:function({ item }){return [_c('metrics-cells-value-legend',{attrs:{"metric-assignment":item}})]}},_vm._l(([
        2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
      ]),function(period){return {key:`item.`+period,fn:function({ item }){return [(item.metricValuesByPeriod && item.metricValuesByPeriod[period])?_c('metrics-metric-value',{key:period,attrs:{"metric":item.metric,"type":item.metric?.defaultReportingPeriodType,"metric-value":item.metricValuesByPeriod[period] || {},"use-target":_vm.filters?.isPaiResultsView ? false : true}}):_vm._e()]}}}),{key:`item.showPeriodOption`,fn:function(){return [_c('metrics-cells-show-periods',{model:{value:(_vm.expandPeriods),callback:function ($$v) {_vm.expandPeriods=$$v},expression:"expandPeriods"}})]},proxy:true},{key:`item.action`,fn:function({ item }){return [(
          [_vm.TypeAggregationGroup.PortfolioVentureLink].includes(item.groupType)
        )?_c('v-menu',{attrs:{"offset-y":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-white',_vm._g(_vm._b({staticClass:"relative",attrs:{"small":"","value":item.change,"color":"#23A9F1"}},'ix-button-white',attrs,false),on),[_c('img',{attrs:{"width":"14","src":require('~/assets/icons/settings.svg')}})])]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('ix-dialog-metric-aggregation',{attrs:{"value":item},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm.$t('editKPIAndAssign'))+"\n                ")])],1)]}}],null,true)}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm.$t('deleteKPI'))+"\n                ")])],1)]}},{key:"default",fn:function(deleteDialog){return [_c('v-card',{staticClass:"subbox"},[_c('v-card-title',[_vm._v("\n                  "+_vm._s(_vm.$t('deleteOutcome'))+"\n                ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                  "+_vm._s(_vm.$t('deleteOutcomeText', {
                      name: item.metric.name,
                    }))+"\n                ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){deleteDialog.value = false}}},[_vm._v("\n                    "+_vm._s(_vm.$t('cancel'))+"\n                  ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":function($event){_vm.deleteMetricAggregation(
                        {
                          metricAggregationId: item.id,
                        },
                        {
                          update(cache) {
                            cache.evict({
                              id: cache.identify({
                                id: item.id,
                                __typename: 'MetricAggregationType',
                              }),
                            })
                            cache.gc()
                            _vm.$nuxt.$emit('userMsg')
                          },
                        },
                      )
                      deleteDialog.value = false}}},[_vm._v("\n                    "+_vm._s(_vm.$t('delete'))+"\n                  ")])],1)],1)]}}],null,true)})],1)],1):_vm._e()]}},{key:`item.comment`,fn:function({ item }){return [_c('v-textarea',{staticClass:"text-body-2",attrs:{"value":item.comment,"rows":"2","outlined":"","full-width":"","placeholder":_vm.$t('addCommentAggregationPlaceholder')},on:{"change":(v) =>
            _vm.updateMetricAggregation({
              input: {
                id: item.id,
                comment: String(v),
              },
            })}})]}}],null,true)},'ix-data-table',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }