export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    },
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {}
});