import "core-js/modules/es6.array.filter.js";
import { logErrorMessages } from '@vue/apollo-util';
import { logObserver, isDisclosure } from '~/functions/helpers';
import { mapQuestionnaireResultsBySectionLabel } from '~/functions/esg';
import { useGetAggregatedEsgQuestionnaireResultQuery, useGetMostRecentQuestionnaireForVentureAndLabelQuery, AggregatedQuestionnaireResultsVentureFilterType } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    ventureName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetMostRecentQues = useGetMostRecentQuestionnaireForVentureAndLabelQuery({
        ventureId: props.ventureId,
        label: 'ESG'
      }),
      resultMostRecent = _useGetMostRecentQues.result,
      onErrorMostRecent = _useGetMostRecentQues.onError,
      loadingMostRecent = _useGetMostRecentQues.loading;
    var mostRecentData = computed(function () {
      var _resultMostRecent$val, _resultMostRecent$val2;
      return (_resultMostRecent$val = (_resultMostRecent$val2 = resultMostRecent.value) === null || _resultMostRecent$val2 === void 0 ? void 0 : _resultMostRecent$val2.getMostRecentQuestionnaireForVentureAndLabel) !== null && _resultMostRecent$val !== void 0 ? _resultMostRecent$val : null;
    });
    var isQuestionnaireCompleted = computed(function () {
      var _mostRecentData$value, _mostRecentData$value2, _mostRecentData$value3, _mostRecentData$value4;
      return !((_mostRecentData$value = mostRecentData.value) !== null && _mostRecentData$value !== void 0 && (_mostRecentData$value2 = _mostRecentData$value.questionnaireAssignment) !== null && _mostRecentData$value2 !== void 0 && _mostRecentData$value2.isEditable) && !!((_mostRecentData$value3 = mostRecentData.value) !== null && _mostRecentData$value3 !== void 0 && (_mostRecentData$value4 = _mostRecentData$value3.questionnaireAssignment) !== null && _mostRecentData$value4 !== void 0 && _mostRecentData$value4.questionnaireResults.length);
    });
    var firstData = computed(function () {
      var _mostRecentData$value5, _mostRecentData$value6;
      if (!mostRecentData.value) return null;
      return mapQuestionnaireResultsBySectionLabel(((_mostRecentData$value5 = mostRecentData.value) === null || _mostRecentData$value5 === void 0 ? void 0 : (_mostRecentData$value6 = _mostRecentData$value5.questionnaireAssignment) === null || _mostRecentData$value6 === void 0 ? void 0 : _mostRecentData$value6.questionnaireResults) || []);
    });
    var esgSections = computed(function () {
      var _mostRecentData$value7, _mostRecentData$value8, _mostRecentData$value9, _mostRecentData$value10;
      return (_mostRecentData$value7 = (_mostRecentData$value8 = mostRecentData.value) === null || _mostRecentData$value8 === void 0 ? void 0 : (_mostRecentData$value9 = _mostRecentData$value8.questionnaireTemplate) === null || _mostRecentData$value9 === void 0 ? void 0 : (_mostRecentData$value10 = _mostRecentData$value9.sections) === null || _mostRecentData$value10 === void 0 ? void 0 : _mostRecentData$value10.filter(function (section) {
        return section && section.id && !isDisclosure(section.id);
      })) !== null && _mostRecentData$value7 !== void 0 ? _mostRecentData$value7 : [];
    });
    onErrorMostRecent(function (e) {
      if (process.env.NODE_ENV !== 'production') {
        logErrorMessages(e);
      }
    });
    var _useGetAggregatedEsgQ = useGetAggregatedEsgQuestionnaireResultQuery({}),
      resultAggregated = _useGetAggregatedEsgQ.result,
      loadingAggregatedPortfolio = _useGetAggregatedEsgQ.loading;
    var secondData = computed(function () {
      var _resultAggregated$val;
      if (!resultAggregated.value) return null;
      return mapQuestionnaireResultsBySectionLabel((_resultAggregated$val = resultAggregated.value) === null || _resultAggregated$val === void 0 ? void 0 : _resultAggregated$val.getAggregatedEsgQuestionnaireResult);
    });
    var _useGetAggregatedEsgQ2 = useGetAggregatedEsgQuestionnaireResultQuery({
        input: {
          scope: AggregatedQuestionnaireResultsVentureFilterType.Ix
        }
      }),
      resultAggregatedAll = _useGetAggregatedEsgQ2.result,
      loadingAggregatedBenchmark = _useGetAggregatedEsgQ2.loading;
    var thirdData = computed(function () {
      var _resultAggregatedAll$;
      if (!resultAggregatedAll.value) return null;
      return mapQuestionnaireResultsBySectionLabel((_resultAggregatedAll$ = resultAggregatedAll.value) === null || _resultAggregatedAll$ === void 0 ? void 0 : _resultAggregatedAll$.getAggregatedEsgQuestionnaireResult);
    });
    // TODO-benchmark pass all data and extend table automatically instead of using first-second-third data
    // const allData = computed(() => {
    //   return {
    //     baseData: { data: firstData, text: props.ventureName },
    //     comparisonData: [
    //       { data: secondData, text: 'Portfolio average' },
    //       { data: thirdData, text: 'ImpactNexus ESG benchmark' },
    //     ],
    //   }
    // })

    var loading = loadingMostRecent || loadingAggregatedPortfolio || loadingAggregatedBenchmark;
    return {
      loading: loading,
      esgSections: esgSections,
      firstData: firstData,
      secondData: secondData,
      thirdData: thirdData,
      isQuestionnaireCompleted: isQuestionnaireCompleted,
      loadingMostRecent: loadingMostRecent
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver
  }
});