import { scoreText } from '~/functions/esg';
export default defineComponent({
  props: {
    sections: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    return {
      scoreText: scoreText
    };
  }
});