export default defineComponent({
  props: {
    impactGoalAssessments: {
      type: Array,
      required: true
    }
  },
  setup: function setup() {
    return {};
  },
  data: function data() {
    return {};
  }
});