import { render, staticRenderFns } from "./DashboardEsg.vue?vue&type=template&id=ad8d72d2"
import script from "./DashboardEsg.vue?vue&type=script&lang=js"
export * from "./DashboardEsg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./DashboardEsg.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyEsgSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgSectionTitle.vue').default,IxSurveyEsgScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgScore.vue').default,IxSurveyEsgScoreSectionDetails: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/EsgScoreSectionDetails.vue').default,IxDisclosure: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Disclosure.vue').default,EsgQuestionarieResult: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/QuestionarieResult.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardText,VCol,VDivider,VExpandTransition,VIcon,VLayout,VRow})
