function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import { useUpsertGroupMutation, useDeleteGroupMutation, useGetAccountQuery, useListPortfolioGroupsQuery, useAssignVenturesToGroupMutation } from '~/types/types';
export default defineComponent({
  props: {
    selected: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props, _ref) {
    var _accountResult$value;
    var emit = _ref.emit;
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var account = (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount;
    var portfolioId = computed(function () {
      return account === null || account === void 0 ? void 0 : account.id;
    });
    var _useListPortfolioGrou = useListPortfolioGroupsQuery(),
      result = _useListPortfolioGrou.result,
      refetch = _useListPortfolioGrou.refetch,
      loading = _useListPortfolioGrou.loading;
    var groups = computed(function () {
      var _result$value$listPor, _result$value;
      return (_result$value$listPor = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listPortfolioGroups) !== null && _result$value$listPor !== void 0 ? _result$value$listPor : [];
    });
    var _useUpsertGroupMutati = useUpsertGroupMutation(),
      addGroup = _useUpsertGroupMutati.mutate;
    var _useDeleteGroupMutati = useDeleteGroupMutation(),
      removeGroup = _useDeleteGroupMutati.mutate;
    var _useAssignVenturesToG = useAssignVenturesToGroupMutation(),
      mutate = _useAssignVenturesToG.mutate,
      onAssignDone = _useAssignVenturesToG.onDone;
    onAssignDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var assign = function assign() {
      var _props$selected;
      if (props !== null && props !== void 0 && (_props$selected = props.selected) !== null && _props$selected !== void 0 && _props$selected.length && selectedGroups) {
        var groupAssignments = Object.keys(selectedGroups.value).map(function (groupId) {
          return {
            groupId: parseInt(groupId),
            ventureIds: selectedGroups.value[groupId]
          };
        });
        mutate({
          groupId: 1,
          groupAssignments: groupAssignments,
          ventureIds: props.selected.map(function (s) {
            return s.ventureId;
          }),
          deleteOldVentureIds: false
        }).then(function () {
          emit('close');
        });
      }
    };
    var groupName = ref('');
    var selectedGroup = ref(0);
    var selectedGroups = ref({});
    var selectedVentures = props.selected.map(function (s) {
      return s.ventureId;
    });
    var groupsWithStatus = computed(function () {
      var _result$value$listPor2, _result$value2;
      var statusMap = {};
      var groupsList = (_result$value$listPor2 = (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : _result$value2.listPortfolioGroups) !== null && _result$value$listPor2 !== void 0 ? _result$value$listPor2 : [];
      var tempSelectedGroups = {};

      // status: This is to determine initial status of checkboxes
      // If all selected ventures already assigned to group -> show checkbox as Checked
      // If some ventures already assigned to group -> show checkbox as Indeterminate
      // If all selected ventures not assigned to group -> show checkbox as not Checked
      var determineVentureStatus = function determineVentureStatus(venturesLength, selectedLength) {
        if (venturesLength === selectedLength) {
          return 'AllAssigned';
        } else if (venturesLength > 0) {
          return 'Indeterminate';
        } else {
          return 'NoneAssigned';
        }
      };
      var _iterator = _createForOfIteratorHelper(groupsList),
        _step;
      try {
        var _loop = function _loop() {
          var item = _step.value;
          var ventures = props.selected.filter(function (selected) {
            var _selected$groupIds;
            return (_selected$groupIds = selected.groupIds) === null || _selected$groupIds === void 0 ? void 0 : _selected$groupIds.includes(item.id);
          });
          var listOfVentureIds = ventures.map(function (venture) {
            return venture.ventureId;
          });
          statusMap[item.id] = {
            group: item,
            ventures: ventures,
            status: determineVentureStatus(ventures.length, props.selected.length)
          };
          tempSelectedGroups[item.id] = listOfVentureIds;
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      selectedGroups.value = tempSelectedGroups;
      return statusMap;
    });
    var handleChange = function handleChange(groupId, value) {
      if (value === true) {
        selectedGroups.value[groupId] = selectedVentures;
      } else if (value === false) {
        selectedGroups.value[groupId] = [];
      } else {
        console.log('Not handled.');
      }
    };
    var form = ref();
    var upsertGroup = function upsertGroup(insert) {
      var input = ref();
      if (insert === true) {
        input.value = {
          label: groupName.value
        };
      } else {
        input.value = {
          id: selectedToEdit.value,
          label: selectedToEditLabel.value
        };
      }
      addGroup({
        input: input.value
      }).then(function () {
        isAddGroup.value = false;
        isEditGroup.value = false;
        refetch();
      });
    };
    var deleteGroup = function deleteGroup() {
      removeGroup({
        groupId: selectedToDelete.value
      }).then(function () {
        isDeleteGroup.value = false;
        refetch();
        emit('close');
      });
    };
    var isAddGroup = ref(false);
    var isDeleteGroup = ref(false);
    var isEditGroup = ref(false);
    var selectedToDelete = ref(0);
    var selectedToDeleteLabel = ref('');
    var selectedToEdit = ref(0);
    var selectedToEditLabel = ref('');
    return {
      loading: loading,
      isAddGroup: isAddGroup,
      isDeleteGroup: isDeleteGroup,
      selectedToDelete: selectedToDelete,
      selectedToDeleteLabel: selectedToDeleteLabel,
      selectedGroup: selectedGroup,
      selectedGroups: selectedGroups,
      groupsWithStatus: groupsWithStatus,
      upsertGroup: upsertGroup,
      deleteGroup: deleteGroup,
      handleChange: handleChange,
      selectedVentures: selectedVentures,
      selectedToEdit: selectedToEdit,
      isEditGroup: isEditGroup,
      selectedToEditLabel: selectedToEditLabel,
      portfolioId: portfolioId,
      groups: groups,
      addGroup: addGroup,
      assign: assign,
      groupName: groupName,
      form: form
    };
  }
});