import { render, staticRenderFns } from "./RegisterComponent.vue?vue&type=template&id=7214b056&scoped=true"
import script from "./RegisterComponent.vue?vue&type=script&lang=ts"
export * from "./RegisterComponent.vue?vue&type=script&lang=ts"
import style0 from "./RegisterComponent.vue?vue&type=style&index=0&id=7214b056&prod&lang=scss"
import style1 from "./RegisterComponent.vue?vue&type=style&index=1&id=7214b056&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7214b056",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TermsAndConditions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/TermsAndConditions.vue').default,Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VCheckbox,VChip,VChipGroup,VCol,VContainer,VDialog,VFileInput,VForm,VIcon,VOverlay,VProgressCircular,VRow,VTextField,VToolbar})
