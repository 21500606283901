import { render, staticRenderFns } from "./index.vue?vue&type=template&id=45638703"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=45638703&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSubSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionTitle.vue').default,IxSubSectionSubtitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionSubtitle.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default,PortfolioEsgPerformance: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/EsgPerformance.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardActions,VChip,VCol,VDivider,VIcon,VListItem,VRow,VSimpleTable})
