var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t('impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.title'),"tooltip":_vm.$t('impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.tooltip'),"subtitle":_vm.renderMarkdown(
        _vm.$t(
          'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.subtitle',
        ),
      ),"is-mandatory":true}}),_vm._v(" "),_c('div',{staticClass:"box-images"},[_c('v-item-group',{staticClass:"flex-wrap flex-row d-flex",staticStyle:{"gap":"32px"},attrs:{"value":_vm.selectedSdgs,"multiple":""},on:{"change":(val) => _vm.triggerUpdateImpact('sdgTargets', val)}},_vm._l((_vm.$t(
          'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.sdgs',
        )),function(sdg,index){return _c('div',{key:index,staticClass:"flex-shrink-1 flex-grow-0"},[_c('v-dialog',{attrs:{"width":"70%","disabled":!_vm.impactGoal.isEditable},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialogOn, active, attrs }){return [_c('v-item',{key:index,attrs:{"value":sdg.id},scopedSlots:_vm._u([{key:"default",fn:function({ active: sdgActive }){return [_c('div',{staticClass:"cursor-pointer flex-shrink-1 flex-grow-0",class:{
                  'sdg-card-active': sdgActive || active,
                }},[_c('term',{attrs:{"explanation":`${sdg.id}: ${sdg.short}`}},[_c('v-avatar',_vm._g(_vm._b({attrs:{"rounded":"","size":"72px"}},'v-avatar',attrs,false),dialogOn),[_c('img',{attrs:{"contain":"","src":require(`~/assets/img/sdgs/${sdg.id}.png`)}})])],1)],1)]}}],null,true)})]}},{key:"default",fn:function(dialog){return [_c('ix-section',[_c('ix-section-title',{staticClass:"align-start justify-space-between"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('SDG',{staticClass:"mr-4",attrs:{"sdg":sdg.id.toString(),"size":"164px"}}),_vm._v(" "),_c('div',[_c('p',[_vm._v("\n                          "+_vm._s(sdg.short)+"\n                        ")]),_vm._v(" "),(sdg.title && sdg.title !== '')?_c('span',{staticClass:"text-subtitle-1",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(sdg.title))}}):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"#DEEEF7"},on:{"click":function($event){_vm.selectedSdgTargetsDraft = _vm.selectedSdgTargets
                        dialog.value = false}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_vm._v(" "),_c('ix-section-text',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(
                  _vm.renderMarkdown(
                    _vm.$t(
                      'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.description.prefix',
                    ) +
                      `* For more details visit: https://sdgs.un.org/goals/goal${sdg.id}.\n` +
                      _vm.$t(
                        'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.description.suffix',
                      ),
                  )
                )}}),_vm._v(" "),_c('ix-section-text',{staticClass:"py-0"},[_c('div',{staticClass:"text-subtitle-1 d-inline-block"},[_vm._v("\n                  Relevant SDG Targets\n                ")]),_vm._v(" "),_c('v-item-group',{attrs:{"value":_vm.selectedSdgTargets,"multiple":""},on:{"change":(value) => {
                      _vm.selectedSdgTargetsDraft = value
                      _vm.$emit('updateImpact', _vm.selectedSdgTargetsDraft)
                    }}},_vm._l((_vm.$t(
                      'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.sdgs',
                    )[sdg.id].targets),function(target){return _c('div',{key:target.id,staticClass:"dialog-box-wrapper"},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"pa-2"},[(target.id)?_c('div',{attrs:{"value":target.id}},[_vm._v("\n                              "+_vm._s(target.sdgId)+"."+_vm._s(target.externalId)+"\n                            ")]):_vm._e()]),_vm._v(" "),_c('td',{staticClass:"pa-2",attrs:{"align":"top"}},[_c('v-item',{key:index,attrs:{"value":target.id},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-checkbox',{staticClass:"checkbox-label mt-0 pt-0",attrs:{"input-value":active,"hide-details":"","color":"primary"},on:{"click":toggle},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(target.label))}})]},proxy:true}],null,true)})]}}],null,true)})],1)])])])])}),0)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"bg-primary-gradient mb-5",attrs:{"text":""},on:{"click":function($event){_vm.$emit('updateImpact', _vm.selectedSdgTargetsDraft)
                    _vm.selectedSdgTargetsDraft = _vm.selectedSdgTargets
                    dialog.value = false}}},[_vm._v(_vm._s(_vm.$t(
                      'impactGoal.impacts.defineImpacts.classifyImpact.sdgInput.confirm',
                    ))+"\n                ")])],1)],1)]}}],null,true)})],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }