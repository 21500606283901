import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["metricAssignments"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMutation } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import DELETE_EMISSION_SOURCE from '~/graphql/mutations/impact-goal/DeleteEmissionSource.gql';
import UPSERT_EMISSION_SOURCE from '~/graphql/mutations/impact-goal/UpsertEmissionSource.gql';
import UPSERT_GHG_SAVING_POTENTIAL from '~/graphql/mutations/impact-goal/UpsertGhgSavingPotential.gql';
import { abbreviateNumber } from '~/assets/helper/functions';
export default defineComponent({
  props: {
    impactGoal: {
      required: true,
      type: Object
    },
    impactSolution: {
      required: true,
      type: Object
    },
    impact: {
      required: true,
      type: Object
    }
  },
  setup: function setup(props) {
    var _props$impact, _props$impact$impactS, _props$impact$impactS2, _props$impact$impactS3, _props$impact$impactS6, _props$impact4, _props$impact4$impact, _props$impact4$impact2, _props$impact4$impact3, _props$impact$impactS7, _props$impact5, _props$impact5$impact, _props$impact5$impact2, _props$impact5$impact3, _props$impact$impactS8, _props$impact6, _props$impact6$impact, _props$impact6$impact2, _props$impact6$impact3;
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useMutation = useMutation(UPSERT_EMISSION_SOURCE),
      upsertEmissionSourceMutation = _useMutation.mutate;
    var _useMutation2 = useMutation(DELETE_EMISSION_SOURCE),
      deleteEmissionSource = _useMutation2.mutate;
    var _useMutation3 = useMutation(UPSERT_GHG_SAVING_POTENTIAL),
      upsertGhgSavingPotential = _useMutation3.mutate;
    var impactSolutionId = computed(function () {
      return toNumber(route.params.solution);
    });
    var ghgSavingPotentialId = ref((_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$impactS = _props$impact.impactSolutions) === null || _props$impact$impactS === void 0 ? void 0 : (_props$impact$impactS2 = _props$impact$impactS[impactSolutionId.value]) === null || _props$impact$impactS2 === void 0 ? void 0 : (_props$impact$impactS3 = _props$impact$impactS2.ghgSavingPotential) === null || _props$impact$impactS3 === void 0 ? void 0 : _props$impact$impactS3.id);
    var preComputedBaselineGhg = computed(function () {
      var _props$impact$impactS4, _props$impact2, _props$impact2$impact, _props$impact2$impact2, _props$impact2$impact3;
      return (_props$impact$impactS4 = (_props$impact2 = props.impact) === null || _props$impact2 === void 0 ? void 0 : (_props$impact2$impact = _props$impact2.impactSolutions) === null || _props$impact2$impact === void 0 ? void 0 : (_props$impact2$impact2 = _props$impact2$impact[impactSolutionId.value]) === null || _props$impact2$impact2 === void 0 ? void 0 : (_props$impact2$impact3 = _props$impact2$impact2.ghgSavingPotential) === null || _props$impact2$impact3 === void 0 ? void 0 : _props$impact2$impact3.preComputedBaselineGhg) !== null && _props$impact$impactS4 !== void 0 ? _props$impact$impactS4 : 0.0;
    });
    var preComputedSolutionGhg = computed(function () {
      var _props$impact$impactS5, _props$impact3, _props$impact3$impact, _props$impact3$impact2, _props$impact3$impact3;
      return (_props$impact$impactS5 = (_props$impact3 = props.impact) === null || _props$impact3 === void 0 ? void 0 : (_props$impact3$impact = _props$impact3.impactSolutions) === null || _props$impact3$impact === void 0 ? void 0 : (_props$impact3$impact2 = _props$impact3$impact[impactSolutionId.value]) === null || _props$impact3$impact2 === void 0 ? void 0 : (_props$impact3$impact3 = _props$impact3$impact2.ghgSavingPotential) === null || _props$impact3$impact3 === void 0 ? void 0 : _props$impact3$impact3.preComputedSolutionGhg) !== null && _props$impact$impactS5 !== void 0 ? _props$impact$impactS5 : 0.0;
    });
    var isScenarioA = ref((_props$impact$impactS6 = (_props$impact4 = props.impact) === null || _props$impact4 === void 0 ? void 0 : (_props$impact4$impact = _props$impact4.impactSolutions) === null || _props$impact4$impact === void 0 ? void 0 : (_props$impact4$impact2 = _props$impact4$impact[impactSolutionId.value]) === null || _props$impact4$impact2 === void 0 ? void 0 : (_props$impact4$impact3 = _props$impact4$impact2.ghgSavingPotential) === null || _props$impact4$impact3 === void 0 ? void 0 : _props$impact4$impact3.isScenarioA) !== null && _props$impact$impactS6 !== void 0 ? _props$impact$impactS6 : true);
    var isScenarioB = ref(!isScenarioA.value);
    var scenarioBComments = ref((_props$impact$impactS7 = (_props$impact5 = props.impact) === null || _props$impact5 === void 0 ? void 0 : (_props$impact5$impact = _props$impact5.impactSolutions) === null || _props$impact5$impact === void 0 ? void 0 : (_props$impact5$impact2 = _props$impact5$impact[impactSolutionId.value]) === null || _props$impact5$impact2 === void 0 ? void 0 : (_props$impact5$impact3 = _props$impact5$impact2.ghgSavingPotential) === null || _props$impact5$impact3 === void 0 ? void 0 : _props$impact5$impact3.scenarioBComments) !== null && _props$impact$impactS7 !== void 0 ? _props$impact$impactS7 : '');
    var emissionSourceLimitations = ref((_props$impact$impactS8 = (_props$impact6 = props.impact) === null || _props$impact6 === void 0 ? void 0 : (_props$impact6$impact = _props$impact6.impactSolutions) === null || _props$impact6$impact === void 0 ? void 0 : (_props$impact6$impact2 = _props$impact6$impact[impactSolutionId.value]) === null || _props$impact6$impact2 === void 0 ? void 0 : (_props$impact6$impact3 = _props$impact6$impact2.ghgSavingPotential) === null || _props$impact6$impact3 === void 0 ? void 0 : _props$impact6$impact3.emissionSourceLimitations) !== null && _props$impact$impactS8 !== void 0 ? _props$impact$impactS8 : '');
    var updateGhgSavingPotential = function updateGhgSavingPotential(value) {
      upsertGhgSavingPotential({
        input: _objectSpread({
          id: props.impactSolution.ghgSavingPotentialId,
          ventureId: ventureId.value
        }, value)
      });
    };
    var upsertEmissionSource = function upsertEmissionSource(values) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var _values$fields = values.fields,
        metricAssignments = _values$fields.metricAssignments,
        fieldsWithoutMetricAssignments = _objectWithoutProperties(_values$fields, _excluded);
      var input = _objectSpread(_objectSpread({
        ghgSavingPotentialId: props.impactSolution.ghgSavingPotentialId,
        ventureId: ventureId.value
      }, fieldsWithoutMetricAssignments), {}, {
        sourceOfEmission: values.sourceOfEmission
      });
      upsertEmissionSourceMutation({
        input: input
      });
    };
    var selectedScenario = computed(function () {
      return isScenarioA.value ? 'A' : 'B';
    });
    var scenarioChanged = function scenarioChanged(newScenario) {
      var isA = newScenario === 'A';
      isScenarioA.value = isA;
      isScenarioB.value = !isA;
      updateGhgSavingPotential({
        isScenarioA: isA
      });
    };
    var impactProfile = computed(function () {
      var _props$impact7, _props$impact7$impact, _props$impact7$impact2;
      return (_props$impact7 = props.impact) === null || _props$impact7 === void 0 ? void 0 : (_props$impact7$impact = _props$impact7.impactSolutions[impactSolutionId.value]) === null || _props$impact7$impact === void 0 ? void 0 : (_props$impact7$impact2 = _props$impact7$impact.ghgSavingPotential) === null || _props$impact7$impact2 === void 0 ? void 0 : _props$impact7$impact2.impactProfileKind;
    });
    var ghgEmissionsCheckWarning = computed(function () {
      var baselineSum = props.impactSolution.ghgSavingPotential.emissionSources.reduce(function (acc, source) {
        return acc + source.baselineConsumption * source.baselineGhgFactor;
      }, 0);
      var solutionSum = props.impactSolution.ghgSavingPotential.emissionSources.reduce(function (acc, source) {
        return acc + source.solutionConsumption * source.solutionGhgFactor;
      }, 0);
      return baselineSum < solutionSum;
    });
    var dialogId = ref(null);
    var comparativeDialogId = ref(null);
    var solutionDialogId = ref(null);
    var resetSolutionDialog = ref(0);
    var resetComparativeDialog = ref(0);
    var openSolutionDialog = function openSolutionDialog(itemId) {
      solutionDialogId.value = itemId;
      resetSolutionDialog.value = Date.now();
    };
    var openComparativeDialog = function openComparativeDialog(itemId) {
      comparativeDialogId.value = itemId;
      resetComparativeDialog.value = Date.now();
    };
    var closeSolutionDialog = function closeSolutionDialog() {
      solutionDialogId.value = null;
    };
    var closeComparativeDialog = function closeComparativeDialog() {
      comparativeDialogId.value = null;
    };
    var handleSolutionDialogInput = function handleSolutionDialogInput(value) {
      if (!value) {
        closeSolutionDialog();
      }
    };
    var handleComparativeDialogInput = function handleComparativeDialogInput(value) {
      if (!value) {
        closeComparativeDialog();
      }
    };
    var roundedConsumption = function roundedConsumption(item, type, decimals) {
      var val = 0;
      if (type === 'baseline') {
        val = item.baselineConsumption * item.baselineGhgFactor;
      }
      if (type === 'solution') {
        val = item.solutionConsumption * item.solutionGhgFactor;
      }
      var locale = $i18n.locale;
      return abbreviateNumber(val, locale);
    };
    return {
      ventureId: ventureId,
      upsertEmissionSourceMutation: upsertEmissionSourceMutation,
      deleteEmissionSource: deleteEmissionSource,
      upsertGhgSavingPotential: upsertGhgSavingPotential,
      roundedConsumption: roundedConsumption,
      ghgSavingPotentialId: ghgSavingPotentialId,
      isScenarioA: isScenarioA,
      isScenarioB: isScenarioB,
      preComputedBaselineGhg: preComputedBaselineGhg,
      preComputedSolutionGhg: preComputedSolutionGhg,
      scenarioBComments: scenarioBComments,
      emissionSourceLimitations: emissionSourceLimitations,
      dialogId: dialogId,
      comparativeDialogId: comparativeDialogId,
      solutionDialogId: solutionDialogId,
      resetSolutionDialog: resetSolutionDialog,
      resetComparativeDialog: resetComparativeDialog,
      selectedScenario: selectedScenario,
      scenarioChanged: scenarioChanged,
      updateGhgSavingPotential: updateGhgSavingPotential,
      impactProfile: impactProfile,
      upsertEmissionSource: upsertEmissionSource,
      ghgEmissionsCheckWarning: ghgEmissionsCheckWarning,
      openSolutionDialog: openSolutionDialog,
      openComparativeDialog: openComparativeDialog,
      closeSolutionDialog: closeSolutionDialog,
      closeComparativeDialog: closeComparativeDialog,
      handleSolutionDialogInput: handleSolutionDialogInput,
      handleComparativeDialogInput: handleComparativeDialogInput
    };
  },
  data: function data() {
    return {
      showInstructions: false,
      showGuidanceForLimitations: false,
      showOptionAInstructions: false
    };
  },
  methods: {
    addEmissionSource: function addEmissionSource() {
      this.upsertEmissionSourceMutation({
        input: {
          ventureId: this.ventureId,
          ghgSavingPotentialId: this.impactSolution.ghgSavingPotentialId
        }
      });
    },
    renderMarkdown: renderMarkdown
  }
});