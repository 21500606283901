var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.questionnaireId)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-section',[_c('ix-section-title'),_vm._v(" "),_c('ix-section-text',[_vm._v(_vm._s(_vm.$t(
              'impactGoal.euTaxonomy.alignmentQuestionnaire.noQuestionnaireId',
            ))+"\n          "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('ix-button-secondary',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('impactGoal.euTaxonomy.alignmentQuestionnaire.leaveButton')))])],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.questionnaireId && _vm.isEmpty(_vm.sectionMapping))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-section',[_c('ix-section-title'),_vm._v(" "),_c('ix-section-text',[_vm._v(_vm._s(_vm.$t(
              'impactGoal.euTaxonomy.alignmentQuestionnaire.noneOfTheActivities',
            ))+"\n          "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('ix-button-secondary',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('impactGoal.euTaxonomy.alignmentQuestionnaire.leaveButton')))])],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),(
      !_vm.isEmpty(_vm.sections) &&
      !_vm.isEmpty(_vm.sectionMapping) &&
      !_vm.isEmpty(_vm.sectionCompletionMapping) &&
      _vm.questionnaireTemplate &&
      _vm.questionnaireAssignment
    )?_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-treeview',{staticClass:"pl-0",attrs:{"active":_vm.active,"open":_vm.open,"items":_vm.sections,"loading":_vm.loading,"activatable":"","open-on-click":"","transition":"","item-children":"sections","item-text":"label","expand-icon":""},on:{"update:active":[function($event){_vm.active=$event},(val) => _vm.updateNavigationViaSidebar(val)],"update:open":[function($event){_vm.open=$event},(val) => _vm.updateOpen(val)]},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(_vm.iconMapping[item.label])?_c('div',{staticClass:"esg-section-icon"},[_c('img',{attrs:{"src":_vm.iconMapping[item.label].inactive}})]):_vm._e()]}},{key:"append",fn:function({ item }){return [(!['intro', 'outro'].includes(item.id))?_c('div',{staticClass:"barlow font-weight-medium body-1",staticStyle:{"width":"30px"}},[(_vm.sectionCompletionMapping[item.id].isCompleted)?_c('v-icon',[_vm._v("mdi-check")]):(item.sections)?_c('v-progress-linear',{attrs:{"value":_vm.sectionCompletionMapping[item.id].percentageCompleted * 100}}):_vm._e()],1):_vm._e()]}}],null,false,1468568800)})],1),_vm._v(" "),_c('v-divider',{attrs:{"vertical":""}}),_vm._v(" "),(_vm.selectedId)?_c('v-col',{staticStyle:{"margin-left":"1px"},attrs:{"cols":"9"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(_vm.selectedId === 'intro')?_c('impact-goal-edit-eu-taxonomy-alignment-intro',{on:{"nextSection":function($event){return _vm.updateNavigationViaButton(
              _vm.navigation.currentSection,
              _vm.navigation.currentSubsection,
            )}}}):(_vm.selectedId === 'outro')?_c('impact-goal-edit-eu-taxonomy-alignment-outro',{attrs:{"questionnaire-assignment":_vm.questionnaireAssignment,"questionnaire-template":_vm.questionnaireTemplate},on:{"previousSection":function($event){return _vm.updateNavigationViaButton(
              _vm.navigation.currentSection,
              _vm.navigation.currentSubsection,
            )}}}):(_vm.subsection)?_c('ix-section',[_c('v-row',{staticClass:"px-12 py-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.subsection.label))]),_vm._v(" "),_c('p',{staticClass:"text-body-1 mb-0"},[_vm._v("\n                "+_vm._s(_vm.subsection.description)+"\n              ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[(
                  _vm.esgSectionDetails[_vm.subsection.label] &&
                  _vm.esgSectionDetails[_vm.subsection.label]['explanation']
                )?_c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                      _vm.esgSectionDetails[_vm.subsection.label] &&
                      _vm.esgSectionDetails[_vm.subsection.label]['explanation']
                    )?_c('v-btn',_vm._g(_vm._b({attrs:{"right":"","text":"","plain":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Why you should care\n                    "),_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-information-outline")])],1):_vm._e()]}}],null,false,1043544741)},[_vm._v(" "),_c('ix-sub-section',[_c('ix-sub-section-text',[(
                        _vm.esgSectionDetails[_vm.subsection.label] &&
                        _vm.esgSectionDetails[_vm.subsection.label]['explanation']
                      )?_c('span',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(
                        _vm.renderMarkdown(
                          _vm.esgSectionDetails[_vm.subsection.label]['explanation'],
                        )
                      )}}):_vm._e()])],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-row',{staticClass:"px-12 py-4",attrs:{"no-gutters":""}},[_vm._l((_vm.subsection.questions),function(question){return [(
                  question.id &&
                  _vm.userInputMapping[question.id] &&
                  _vm.userInputMapping[question.id].isActive
                )?_c('v-col',{key:question.id,staticClass:"mb-4",attrs:{"cols":"12"}},[_c('ix-section',[_c(question.component,_vm._b({tag:"component",attrs:{"user-input":_vm.userInputMapping[question.id],"user-input-mapping":_vm.userInputMapping,"is-editable":_vm.questionnaireAssignment.isEditable}},'component',question,false))],1)],1):_vm._e()]}),_vm._v(" "),(_vm.navigationSteps[_vm.selectedId])?_c('ix-survey-navigation-buttons',{attrs:{"previous-step":_vm.navigationSteps[_vm.selectedId]['prev'],"next-step":_vm.navigationSteps[_vm.selectedId]['next'],"is-completed":_vm.sectionCompletionMapping[_vm.selectedId].isCompleted},on:{"change":(value) =>
                  _vm.updateNavigationViaButton(value.section, value.subsection)}}):_vm._e()],2)],1):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ix-button-info',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Welcome to the EU taxonomy alignment check!")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.explanation))}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }