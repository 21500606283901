import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
// eslint-disable-next-line import/namespace
import { companyInfoStyle } from '../Impact/company_Info.ts'
import { impactContentStyle } from '../Impact/impact_content'
import { impactOverviewStyle } from '../Impact/impact_overview'
import { buildTableSlideStyles } from '../Impact/metric_assignment_info'
import { defaults, footerStyle } from '../shared/default'
import { ImpactGoaSlideContent } from './impact_goal_slide'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    medium: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    mediumitalics: 'Roboto-MediumItalic.ttf',
    bold: 'Roboto-Medium.ttf',
  },
}

export const docDefinition = (impact, companyInfo, locale = 'en') => {
  return {
    pageSize: 'A4',
    ...defaults('Impact'),
    content: [ImpactGoaSlideContent(impact, companyInfo, locale)],
    ...{
      styles: {
        ...companyInfoStyle,
        ...impactOverviewStyle,
        ...impactContentStyle,
        ...buildTableSlideStyles,
        ...footerStyle,
      },
    },
  }
}

export default class ImpactDashboard {
  constructor(impact, companyInfo, locale) {
    // eslint-disable-next-line no-console
    pdfMake
      .createPdf(docDefinition(impact, companyInfo, locale))
      .download(`${companyInfo?.ventureName}_Impact profile`)
  }
}
