import { formatAsGermanDate } from '~/functions/helpers';
export default defineComponent({
  props: {
    date: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      menu: false
    };
  },
  computed: {
    dateFormatted: function dateFormatted() {
      return this.formatDate(this.date);
    }
  },
  methods: {
    handleInput: function handleInput(value) {
      this.menu = false;
      this.$emit('handleDateChange', value);
    },
    formatDate: function formatDate(date) {
      if (!date) return null;
      return formatAsGermanDate(date);
    }
  }
});