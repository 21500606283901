import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5f1552e6"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=5f1552e6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,TaskCompanyAssignmentForm: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/task/CompanyAssignmentForm.vue').default,IxSubSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionTitle.vue').default,IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,IxSubSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionText.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default,IxIframe: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Iframe.vue').default,IxButtonInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Info.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VDataTable,VDialog,VExpandTransition,VList,VListItem,VListItemTitle,VMenu,VSpacer,VTextField})
