export default defineComponent({
  props: {
    sections: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {},
  data: function data() {
    return {};
  }
});