import { render, staticRenderFns } from "./PerformanceComparisonToIxBenchmark.vue?vue&type=template&id=cfe38316"
import script from "./PerformanceComparisonToIxBenchmark.vue?vue&type=script&lang=ts"
export * from "./PerformanceComparisonToIxBenchmark.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PerformanceComparisonToIxBenchmark.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,EsgPerformanceComparisonSingle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PerformanceComparisonSingle.vue').default})
