var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"background-color-custom",attrs:{"fluid":"","fill-height":""}},[_c('v-card',{staticClass:"mx-auto max-w-screen-md"},[_c('v-card-title',{staticClass:"justify-center"},[_c('img',{attrs:{"id":"img","src":require('../assets/img/logo.png'),"height":"50"}})]),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v("\n      "+_vm._s(_vm.$t('companyPerspective.registrationPage.impactIntelligence'))+"\n    ")]),_vm._v(" "),_c('v-card-text',[(!_vm.showThankYouMessage)?_c('v-row',[(_vm.loading)?_c('v-col',{attrs:{"sm":"12"}},[_vm._v("\n          "+_vm._s(_vm.$t('companyPerspective.registrationPage.checkingValidityOfUrls'))+"\n        ")]):(!_vm.tokenValid)?_c('v-col',{attrs:{"sm":"12"},domProps:{"innerHTML":_vm._s(_vm.$t('companyPerspective.registrationPage.youDidNotPass'))}}):[_c('v-col',[(!_vm.isTeamMemberRegistration)?_c('div',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  _vm.selfRegister
                    ? 'companyPerspective.registrationPage.selfRegister'
                    : 'companyPerspective.registrationPage.portfolioOwnerHasRequestedYou',
                  { company: _vm.company },
                )
              )}}):_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t('companyPerspective.registrationPage.teamHasInvitedYou', {
                  inviter: _vm.company,
                })
              )}})]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isTeamMemberRegistration)?_c('span',[_c('v-chip-group',_vm._l((_vm.modules),function(mod){return _c('v-chip',{key:mod},[_vm._v("\n                  "+_vm._s(mod)+"\n                ")])}),1),_vm._v(" "),(
                  _vm.tokenStatus.isEsgTokenValid === false &&
                  _vm.$route.query.esgToken
                )?_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.theToken'))+"\n                "+_vm._s(_vm.$route.query.esgToken)+"\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.esgDidNotMatch'))+"\n              ")]):_vm._e(),_vm._v(" "),(
                  _vm.tokenStatus.isImpactGoalTokenValid === false &&
                  _vm.$route.query.impactGoalToken
                )?_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.theToken'))+"\n                "+_vm._s(_vm.$route.query.impactGoalToken)+"\n                "+_vm._s(_vm.$t(
                    'companyPerspective.registrationPage.impactGoalDidNotMatch',
                  ))+"\n              ")]):_vm._e(),_vm._v(" "),(
                  _vm.tokenStatus.isMetricsTokenValid === false &&
                  _vm.$route.query.metricsToken
                )?_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.theToken'))+"\n                "+_vm._s(_vm.$route.query.metricsToken)+"\n                "+_vm._s(_vm.$t(
                    'companyPerspective.registrationPage.metricsModuleDidNotMatch',
                  ))+"\n              ")]):_vm._e(),_vm._v(" "),(
                  _vm.tokenStatus.isImpactTokenValid === false &&
                  _vm.$route.query.impactToken
                )?_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.theImpactToken'))+"\n                "+_vm._s(_vm.$route.query.impactToken)+"\n                "+_vm._s(_vm.$t(
                    'companyPerspective.registrationPage.impactModuleDidNotMatch',
                  ))+"\n              ")]):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"h-[85px]",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"text","label":_vm.$t('companyPerspective.registrationPage.firstName'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterFirstName'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-rename-box","rules":[
                      (v) =>
                        !!v ||
                        _vm.$t(
                          'companyPerspective.registrationPage.firstNameIsRequired',
                        ),
                      (v) =>
                        (v && v.length <= 25) ||
                        _vm.$t(
                          'companyPerspective.registrationPage.firstNameMustBeLessThan',
                        ),
                    ]},model:{value:(_vm.register.firstName),callback:function ($$v) {_vm.$set(_vm.register, "firstName", $$v)},expression:"register.firstName"}})],1),_vm._v(" "),_c('v-col',{staticClass:"h-[85px]",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"text","label":_vm.$t('companyPerspective.registrationPage.lastName'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterLastName'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-rename-box","rules":[
                      (v) =>
                        !!v ||
                        _vm.$t(
                          'companyPerspective.registrationPage.lastNameIsRequired',
                        ),
                      (v) =>
                        (v && v.length <= 25) ||
                        _vm.$t(
                          'companyPerspective.registrationPage.lastNameMustBeLessThan',
                        ),
                    ]},model:{value:(_vm.register.lastName),callback:function ($$v) {_vm.$set(_vm.register, "lastName", $$v)},expression:"register.lastName"}})],1),_vm._v(" "),_c('v-col',{staticClass:"h-[85px]",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"text","label":_vm.$t('companyPerspective.registrationPage.email'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterEmail'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-account","autocomplete":"new","rules":[
                      (v) =>
                        !!v ||
                        _vm.$t(
                          'companyPerspective.registrationPage.emailIsRequired',
                        ),
                      (v) =>
                        /.+@.+\..+/.test(v) ||
                        _vm.$t(
                          'companyPerspective.registrationPage.emailMustBeValid',
                        ),
                    ]},model:{value:(_vm.register.email),callback:function ($$v) {_vm.$set(_vm.register, "email", $$v)},expression:"register.email"}})],1),_vm._v(" "),_c('v-col',{staticClass:"h-[85px]",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"password","label":_vm.$t('companyPerspective.registrationPage.password'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterPassword'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-lock","rules":[
                      (v) =>
                        !!v ||
                        _vm.$t(
                          'companyPerspective.registrationPage.passwordIsRequired',
                        ),
                      (v) =>
                        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) ||
                        _vm.$t(
                          'companyPerspective.registrationPage.passwordMustContain',
                        ),
                    ]},model:{value:(_vm.register.password),callback:function ($$v) {_vm.$set(_vm.register, "password", $$v)},expression:"register.password"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"password","label":_vm.$t(
                        'companyPerspective.registrationPage.confirmPassword',
                      ),"placeholder":_vm.$t(
                        'companyPerspective.registrationPage.confirmPasswordPlaceholder',
                      ),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-lock","rules":[
                      (value) =>
                        !!value ||
                        _vm.$t(
                          'companyPerspective.registrationPage.confirmNewPassword',
                        ),
                      (value) =>
                        value === _vm.register.password ||
                        _vm.$t(
                          'companyPerspective.registrationPage.theTwoPasswordsDoNotMatch',
                        ),
                    ]},model:{value:(_vm.register.confirmPassword),callback:function ($$v) {_vm.$set(_vm.register, "confirmPassword", $$v)},expression:"register.confirmPassword"}})],1)],1),_vm._v(" "),(!_vm.isTeamMemberRegistration)?_c('div',[_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"text","label":_vm.$t('companyPerspective.registrationPage.companyName'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterCompanyName'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-rename-box","rules":[
                    (v) =>
                      !!v ||
                      _vm.$t(
                        'companyPerspective.registrationPage.companyNameIsRequired',
                      ),
                    (v) =>
                      (v && v.length <= 25) ||
                      _vm.$t(
                        'companyPerspective.registrationPage.companyNameMustBeLessThan',
                      ),
                  ]},model:{value:(_vm.register.ventureName),callback:function ($$v) {_vm.$set(_vm.register, "ventureName", $$v)},expression:"register.ventureName"}}),_vm._v(" "),_c('v-text-field',{staticClass:"text-field-icon",attrs:{"type":"text","label":_vm.$t('companyPerspective.registrationPage.companyUrl'),"placeholder":_vm.$t('companyPerspective.registrationPage.enterCompanyUrl'),"persistent-placeholder":"","outlined":"","prepend-inner-icon":"mdi-link-variant","rules":[
                    (v) =>
                      !v ||
                      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
                        v,
                      ) ||
                      _vm.$t('companyPerspective.registrationPage.urlIsNotValid'),
                  ]},model:{value:(_vm.register.website),callback:function ($$v) {_vm.$set(_vm.register, "website", $$v)},expression:"register.website"}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-file-input',{staticClass:"text-field-icon",attrs:{"color":"primary accent-4","label":_vm.$t('companyPerspective.registrationPage.companyLogo'),"single":"","placeholder":_vm.$t(
                          'companyPerspective.registrationPage.selectCompanyImages',
                        ),"persistent-placeholder":"","prepend-inner-icon":"mdi-paperclip","prepend-icon":"","outlined":"","accept":".jpg,.jpeg,.png","truncate-length":"10"},on:{"change":_vm.logoUpload},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 2)?_c('v-chip',{attrs:{"color":"primary accent-4","dark":"","label":""}},[_vm._v("\n                          "+_vm._s(text)+"\n                        ")]):_vm._e()]}}],null,false,3048978137)})],1),_vm._v(" "),(_vm.register.logo)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('img',{staticClass:"h-20",attrs:{"src":_vm.register.logo,"alt":"logo preview"}})]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"mt-0"},[_c('v-checkbox',{staticClass:"pl-2",attrs:{"rules":[
                    (v) =>
                      !!v ||
                      _vm.$t(
                        'companyPerspective.registrationPage.agreeToContinue',
                      ),
                  ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                    "+_vm._s(_vm.$t(
                        'companyPerspective.registrationPage.agreeTermsAndConditions',
                      ))+"\n                     \n                    "),_c('v-dialog',{attrs:{"max-width":"65vw","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{attrs:{"icon":"","depressed":"","ripple":false}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-link")])],1)]}}],null,false,2941944404),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{staticClass:"h-full"},[_c('terms-and-conditions'),_vm._v(" "),_c('v-toolbar',{staticClass:"sticky-footer",attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"pa-2 ml-auto"},[_c('v-btn',{attrs:{"color":"black","depressed":"","ripple":false,"dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                              "+_vm._s(_vm.$t('general.navigation.close'))+"\n                              "),_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)]},proxy:true}],null,false,284293071),model:{value:(_vm.impactCheckbox),callback:function ($$v) {_vm.impactCheckbox=$$v},expression:"impactCheckbox"}})],1),_vm._v(" "),(!_vm.isTeamMemberRegistration)?_c('v-row',[_c('v-checkbox',{staticClass:"pl-2",attrs:{"dense":"","rules":[
                    (v) =>
                      !!v ||
                      _vm.$t(
                        'companyPerspective.registrationPage.agreeToContinue',
                      ),
                  ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('term',{scopedSlots:_vm._u([{key:"explanation",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.$t(
                              'companyPerspective.registrationPage.byAccepting',
                              { company: _vm.company },
                            )
                          )}})]},proxy:true}],null,false,3151903541)},[_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.$t(
                            'companyPerspective.registrationPage.iAcceptSharingData',
                            { company: _vm.company },
                          )
                        )}})])]},proxy:true}],null,false,3355814945),model:{value:(_vm.portfolioCheckbox),callback:function ($$v) {_vm.portfolioCheckbox=$$v},expression:"portfolioCheckbox"}})],1):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"pl-5 pr-5 text-uppercase mt-4",attrs:{"type":"submit","loading":_vm.addUserLoading || _vm.addVentureAndUserLoading,"color":"primary","depressed":"","block":"","x-large":"","elevation":"2"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"size":"20"}},[_vm._v("mdi-account-check")]),_vm._v("\n                "+_vm._s(_vm.$t('companyPerspective.registrationPage.register'))+"\n              ")],1)],1)],1)]],2):_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-card',{attrs:{"height":"100%","flat":""}},[_c('v-card-text',[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center mt-3"},[_c('h3',{staticClass:"mt-2 font-weight-bold"},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'companyPerspective.registrationPage.thankYouForRegisetring',
                        ))+"\n                    ")])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"grey--text"},[_c('p',[_vm._v("\n                        "+_vm._s(_vm.$t(
                            'companyPerspective.registrationPage.itMightTakeFewMinutes',
                          ))+"\n                      ")])])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","depressed":"","to":"/"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"size":"20"}},[_vm._v("mdi-home")]),_vm._v("\n                      "+_vm._s(_vm.$t('companyPerspective.registrationPage.backMainPage'))+"\n                    ")],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }