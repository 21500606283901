import { render, staticRenderFns } from "./GeographicalView.vue?vue&type=template&id=05b9a6ec&scoped=true"
import script from "./GeographicalView.vue?vue&type=script&lang=ts"
export * from "./GeographicalView.vue?vue&type=script&lang=ts"
import style0 from "./GeographicalView.vue?vue&type=style&index=0&id=05b9a6ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b9a6ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,PortfolioScreeningGeoimpactsGeoImpactChart: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio-screening/geoimpacts/GeoImpactChart.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,PortfolioScreeningGeoimpactsGeoDetails: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio-screening/geoimpacts/GeoDetails.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCol,VDivider,VRow})
