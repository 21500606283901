import { render, staticRenderFns } from "./IdematComparisonModal.vue?vue&type=template&id=5950955e&scoped=true"
import script from "./IdematComparisonModal.vue?vue&type=script&lang=ts"
export * from "./IdematComparisonModal.vue?vue&type=script&lang=ts"
import style0 from "./IdematComparisonModal.vue?vue&type=style&index=0&id=5950955e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5950955e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactGoalEditIdematSearchBar: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/IdematSearchBar.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VDataTable,VDialog,VRow})
