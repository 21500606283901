import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
// import VueMarkdown from 'vue-markdown-render'
import colors from 'vuetify/lib/util/colors';
import { isAfterState } from '~/functions/helpers';
export default defineComponent({
  // components: { VueMarkdown },
  setup: function setup(props, _ref) {
    var _this = this;
    var refs = _ref.refs,
      root = _ref.root;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var route = useRoute();
    var snackbar = ref(true);
    var showDetails = ref(false);
    var complete = ref(false);
    var categories = ref([{
      name: 'Relevance of impact',
      toggle: true,
      dimensions: [{
        name: 'What',
        toggle: true
      }, {
        name: 'Who',
        toggle: true
      }]
    }, {
      name: 'Degree of change',
      toggle: true,
      dimensions: [{
        name: 'How much',
        toggle: true
      }, {
        name: 'Contribution',
        toggle: true
      }]
    }, {
      name: 'Impact risks',
      toggle: true
    }]);
    var assessment = computed(function () {
      return $store.getters['assessment/getAssessment'];
    });
    var assessmentStatus = computed(function () {
      return assessment.value.assessment.status;
    });
    var currentUserRole = computed(function () {
      return assessment.value.assessment.currentUserRole;
    });
    var isEditable = computed(function () {
      if (currentUserRole.value === 'venture') {
        var _outcome$value;
        return ((_outcome$value = outcome.value) === null || _outcome$value === void 0 ? void 0 : _outcome$value.stage) === 'Assessment venture' && assessmentStatus.value === 'Assessment input';
      }
      if (currentUserRole.value === 'reviewer') {
        var _outcome$value2;
        return ((_outcome$value2 = outcome.value) === null || _outcome$value2 === void 0 ? void 0 : _outcome$value2.stage) === 'Assessment reviewer' && assessmentStatus.value === 'Assessment in review';
      }
      return false;
    });
    var outcomeId = computed(function () {
      return route.params.assessment;
    });
    var outcome = computed(function () {
      var _assessment$value$out;
      return (_assessment$value$out = assessment.value.outcomes) === null || _assessment$value$out === void 0 ? void 0 : _assessment$value$out[outcomeId.value];
    });
    var universalToggle = computed(function () {
      return categories.value.every(function (c) {
        if (c.dimensions) {
          return c.toggle && c.dimensions.every(function (d) {
            return d.toggle;
          });
        } else {
          return c.toggle;
        }
      });
    });
    function setUniversalToggle() {
      var valueToSet = !universalToggle.value;
      categories.value.forEach(function (c) {
        c.toggle = valueToSet;
        if (c.dimensions) {
          c.dimensions.forEach(function (d) {
            return d.toggle = valueToSet;
          });
        }
      });
    }
    function toggleCategory(name) {
      var category = categories.value.find(function (e) {
        return e.name === name;
      });
      if (category) {
        category.toggle = !category.toggle;
      }
    }
    function toggleDimension(categoryName, dimensionName) {
      var category = categories.value.find(function (e) {
        return e.name === categoryName;
      });
      if (category && category.dimensions) {
        var dimension = category.dimensions.find(function (d) {
          return d.name === dimensionName;
        });
        if (dimension) {
          dimension.toggle = !dimension.toggle;
        }
      }
    }
    watch(refs, function () {
      // Implement the logic from setComplete and setComplete2 here, adapted for the Composition API
      // Note: Direct refs access might need adjustments based on actual usage within the template
    }, {
      deep: true,
      immediate: true
    });
    watch(function () {
      return _this.route.path;
    }, function () {
      // Similar logic to watch for route changes
    });
    return {
      snackbar: snackbar,
      colors: colors,
      showDetails: showDetails,
      complete: complete,
      categories: categories,
      assessment: assessment,
      assessmentStatus: assessmentStatus,
      currentUserRole: currentUserRole,
      isEditable: isEditable,
      universalToggle: universalToggle,
      setUniversalToggle: setUniversalToggle,
      toggleCategory: toggleCategory,
      toggleDimension: toggleDimension,
      outcomeId: outcomeId,
      outcome: outcome,
      isAfterState: isAfterState // Assuming isAfterState can be used directly within setup
    };
  }
});