import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=6e3ce312&scoped=true"
import script from "./edit.vue?vue&type=script&lang=ts"
export * from "./edit.vue?vue&type=script&lang=ts"
import style0 from "./edit.vue?vue&type=style&index=0&id=6e3ce312&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3ce312",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonSecondary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Secondary.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCardActions,VCol,VIcon,VProgressLinear,VRow,VSpacer,VTreeview})
