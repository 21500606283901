import { handleCompanyInfo } from './company_Info'
import { handleImpactContentInfo } from './impact_content'
import { handleImpactOverviewInfo } from './impact_overview'
export const ImpactGoaSlideContent = (data: any, companyInfo: any, locale) => {
  return [
    handleCompanyInfo(companyInfo),
    handleImpactOverviewInfo(data),
    handleImpactContentInfo(data?.impacts, locale),
  ]
}
