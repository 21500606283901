export default defineComponent({
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var venture = computed(function () {
      return $store.state.assessment.venture;
    });
    return {
      venture: venture
    };
  }
});