import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    score: [Object, Number],
    type: String,
    filled: Boolean,
    isPositive: Boolean,
    total: Number,
    attrs: Object
  },
  computed: {
    isFilled: function isFilled() {
      return this.filled === undefined ? false : this.filled;
    },
    color: function color() {
      if (this.type === 'risk' || this.type === 'risk-sub') {
        return 'purple';
      } else if (this.type === 'confidence') {
        return 'blue-grey';
      } else if (this.isFilled) {
        return this.isPositive ? 'success' : 'danger';
      } else {
        return 'white';
      }
    },
    explanation: function explanation() {
      if (this.type === 'confidence') {
        if (this.score > 4) {
          return 'High confidence in assessment';
        } else if (this.score > 3) {
          return 'Fairly high confidence in assessment';
        } else if (this.score > 2) {
          return 'Fairly low confidence in assessment';
        } else {
          return 'Low confidence in assessment';
        }
      } else if (this.type === 'risk') {
        if (this.score < 0.3333) {
          return 'Low impact management risk';
        } else if (this.score < 0.6666) {
          return 'Moderate impact management risk';
        } else {
          return 'High impact management risk';
        }
      }
    },
    noScore: function noScore() {
      return this.score === undefined || this.score.specialValue === 'NaN';
    }
  },
  methods: {
    formatScore: function formatScore(score) {
      if (this.noScore) {
        return '—&nbsp;';
      }
      if (this.type === 'confidence') {
        return score.toFixed(1);
      } else {
        return (score * 100).toFixed(0);
      }
    }
  }
});