import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _, { toNumber } from 'lodash';
import { esgSectionDetails, iconMapping } from '~/functions/helpers';
import { renderMarkdown } from '~/functions/markdown';
import { useGetAccountQuery, useGetQuestionnaireQuery, useGetQuestionnairesForVenturesAndLabelQuery, useUpdateQuestionnaireNavigationMutation } from '~/types/types';
import { esgModuleInfoForVenture } from '~/assets/esg';
export default defineComponent({
  setup: function setup() {
    var _route$query, _route$query2, _route$query3, _route$query4;
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var questionnaireId = computed(function () {
      return toNumber(route.params.questionnaire);
    });
    var _useGetQuestionnaires = useGetQuestionnairesForVenturesAndLabelQuery({
        ventureIds: [ventureId.value],
        label: 'ESG'
      }),
      allQuestionnairesResult = _useGetQuestionnaires.result;
    var isFirstAssessment = computed(function () {
      var _allQuestionnairesRes;
      var all = (_allQuestionnairesRes = allQuestionnairesResult.value) === null || _allQuestionnairesRes === void 0 ? void 0 : _allQuestionnairesRes.getQuestionnairesForVenturesAndLabel;
      if (all && all.length === 1) return true;
      return false;
    });
    var _useGetQuestionnaireQ = useGetQuestionnaireQuery({
        id: questionnaireId.value
      }),
      result = _useGetQuestionnaireQ.result,
      loading = _useGetQuestionnaireQ.loading;
    var questionnaire = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getQuestionnaire;
    });
    var _useUpdateQuestionnai = useUpdateQuestionnaireNavigationMutation(),
      mutateQuestionnaireNavigation = _useUpdateQuestionnai.mutate;
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId
      }),
      accountResult = _useGetAccountQuery.result;
    var account = computed(function () {
      var _accountResult$value;
      return (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount;
    });
    var esgActionsActive = computed(function () {
      return account.value.esgActionsActive === true;
    });
    var open = ref((_route$query = route.query) !== null && _route$query !== void 0 && _route$query.currentSection ? [(_route$query2 = route.query) === null || _route$query2 === void 0 ? void 0 : _route$query2.currentSection] : []);
    var active = ref((_route$query3 = route.query) !== null && _route$query3 !== void 0 && _route$query3.currentSubsection ? [(_route$query4 = route.query) === null || _route$query4 === void 0 ? void 0 : _route$query4.currentSubsection] : ['intro']);
    return {
      questionnaireId: questionnaireId,
      questionnaire: questionnaire,
      loading: loading,
      mutateQuestionnaireNavigation: mutateQuestionnaireNavigation,
      active: active,
      open: open,
      isFirstAssessment: isFirstAssessment,
      esgActionsActive: esgActionsActive
    };
  },
  data: function data() {
    return {
      esgModuleInfo: esgModuleInfoForVenture,
      esgSectionDetails: esgSectionDetails,
      iconMapping: iconMapping
    };
  },
  computed: {
    selectedId: function selectedId() {
      return this.active[0];
    },
    subsection: function subsection() {
      var _this$sectionMapping$;
      return (_this$sectionMapping$ = this.sectionMapping[this.selectedId]) === null || _this$sectionMapping$ === void 0 ? void 0 : _this$sectionMapping$.subsection;
    },
    navigation: function navigation() {
      var _this$questionnaire;
      return ((_this$questionnaire = this.questionnaire) === null || _this$questionnaire === void 0 ? void 0 : _this$questionnaire.questionnaireNavigation) || {
        currentSection: null,
        currentSubsection: null
      };
    },
    questionnaireAssignment: function questionnaireAssignment() {
      var _this$questionnaire2;
      return ((_this$questionnaire2 = this.questionnaire) === null || _this$questionnaire2 === void 0 ? void 0 : _this$questionnaire2.questionnaireAssignment) || {
        userInputs: [],
        sectionCompletion: [],
        isEditable: false
      };
    },
    questionnaireTemplate: function questionnaireTemplate() {
      var _this$questionnaire3;
      return (_this$questionnaire3 = this.questionnaire) === null || _this$questionnaire3 === void 0 ? void 0 : _this$questionnaire3.questionnaireTemplate;
    },
    sections: function sections() {
      var _this$questionnaireTe;
      if (!this.questionnaireTemplate) return [];
      return [{
        label: this.$t('introduction'),
        id: 'intro'
      }].concat(_toConsumableArray(((_this$questionnaireTe = this.questionnaireTemplate) === null || _this$questionnaireTe === void 0 ? void 0 : _this$questionnaireTe.sections) || []), [{
        label: this.$t('nextSteps'),
        id: 'outro'
      }]);
    },
    userInputMapping: function userInputMapping() {
      if (!this.questionnaireAssignment) return {};
      return Object.assign.apply(Object, [{}].concat(_toConsumableArray(this.questionnaireAssignment.userInputs.map(function (x) {
        return _defineProperty({}, x.question.id, x);
      }))));
    },
    sectionCompletionMapping: function sectionCompletionMapping() {
      if (!this.questionnaireAssignment) return {};
      return Object.assign.apply(Object, [{}].concat(_toConsumableArray(this.questionnaireAssignment.sectionCompletion.map(function (item) {
        return _defineProperty({}, item.sectionId, item);
      }))));
    },
    sectionMapping: function sectionMapping() {
      var _this$questionnaireTe2, _this$questionnaireTe3;
      if (!this.questionnaireTemplate) return {};
      var result = {};
      (_this$questionnaireTe2 = this.questionnaireTemplate) === null || _this$questionnaireTe2 === void 0 ? void 0 : (_this$questionnaireTe3 = _this$questionnaireTe2.sections) === null || _this$questionnaireTe3 === void 0 ? void 0 : _this$questionnaireTe3.forEach(function (section) {
        return section.sections.forEach(function (subsection) {
          result[subsection.id] = {
            subsection: subsection,
            parent_id: section.id
          };
        });
      });
      return result;
    },
    orderedNavigation: function orderedNavigation() {
      var _this$questionnaireTe4, _this$questionnaireTe5;
      if (!this.questionnaireTemplate) return [];
      var result = [];
      (_this$questionnaireTe4 = this.questionnaireTemplate) === null || _this$questionnaireTe4 === void 0 ? void 0 : (_this$questionnaireTe5 = _this$questionnaireTe4.sections) === null || _this$questionnaireTe5 === void 0 ? void 0 : _this$questionnaireTe5.forEach(function (section) {
        return section.sections.forEach(function (subsection) {
          result.push({
            section: section.id,
            subsection: subsection.id
          });
        });
      });
      return result;
    },
    navigationSteps: function navigationSteps() {
      var _this = this;
      if (!this.orderedNavigation) return {};
      var result = {};
      this.orderedNavigation.forEach(function (value, index) {
        result[value.subsection] = {
          prev: _this.orderedNavigation[index - 1] || {
            section: null,
            subsection: 'intro'
          },
          next: _this.orderedNavigation[index + 1] || {
            section: null,
            subsection: 'outro'
          }
        };
      });
      return result;
    },
    allQuestionsAnswered: function allQuestionsAnswered() {
      if (!this.questionnaireAssignment) return false;
      return this.questionnaireAssignment.sectionCompletion.every(function (item) {
        return item.isCompleted;
      });
    },
    isOldEsg: function isOldEsg() {
      var _this$questionnaireTe6, _this$questionnaireTe7, _this$questionnaireTe8;
      return [2, 3, 8, 19].includes((_this$questionnaireTe6 = this.questionnaireTemplate) === null || _this$questionnaireTe6 === void 0 ? void 0 : (_this$questionnaireTe7 = _this$questionnaireTe6.sections) === null || _this$questionnaireTe7 === void 0 ? void 0 : (_this$questionnaireTe8 = _this$questionnaireTe7[0]) === null || _this$questionnaireTe8 === void 0 ? void 0 : _this$questionnaireTe8.id);
    }
  },
  methods: {
    isEmpty: function isEmpty(item) {
      return _.isEmpty(item);
    },
    renderMarkdown: renderMarkdown,
    updateNavigationViaSidebar: function updateNavigationViaSidebar(active) {
      var _this$sectionMapping, _this$sectionMapping$2;
      if (this.isEmpty(active)) return;
      this.active = active;
      var subsectionId = active[0];
      if (['intro', 'outro'].includes(subsectionId)) return;
      var sectionId = (_this$sectionMapping = this.sectionMapping) === null || _this$sectionMapping === void 0 ? void 0 : (_this$sectionMapping$2 = _this$sectionMapping[subsectionId]) === null || _this$sectionMapping$2 === void 0 ? void 0 : _this$sectionMapping$2.parent_id;
      if (this.questionnaireId) {
        this.mutateQuestionnaireNavigation({
          input: {
            id: this.questionnaireId,
            currentSection: sectionId,
            currentSubsection: subsectionId
          }
        }); // FIXME: I don't think this is necessary plus this wont' support multiple clients
        this.$router.push({
          query: _objectSpread(_objectSpread({}, this.$route.query), {}, {
            currentSection: sectionId,
            currentSubsection: subsectionId
          })
        });
      }
    },
    updateNavigationViaButton: function updateNavigationViaButton(sectionId, subsectionId) {
      this.active = [subsectionId];
      this.open = [sectionId];
      if (['intro', 'outro'].includes(subsectionId)) return;
      if (this.questionnaireId) this.mutateQuestionnaireNavigation({
        input: {
          id: this.questionnaireId,
          currentSection: sectionId,
          currentSubsection: subsectionId
        }
      });
    },
    updateOpen: function updateOpen(val) {
      if (val.length > 1) this.open = [val.pop()];
    }
  }
});