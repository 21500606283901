var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.assessmentsToCompare &&
    _vm.assessmentsToCompare.length > 0 &&
    _vm.assessmentsToCompare[0].outcomesByStages !== undefined
  )?_c('v-container',{staticClass:"px-0 pt-0",attrs:{"fluid":""}},[_c('section-title',{attrs:{"title":"Comparison between ventures"}}),_vm._v(" "),_c('v-card',{staticClass:"subbox",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Impact assessment results ")]),_vm._v(" "),_c('v-card-text',[_c('p',[_vm._v("\n        You can switch between different stages of the impact assessment to\n        see the version history of the ventures.\n      ")]),_vm._v(" "),_c('v-btn-toggle',{attrs:{"color":"primary","dense":"","mandatory":""},model:{value:(_vm.stageToggle),callback:function ($$v) {_vm.stageToggle=$$v},expression:"stageToggle"}},_vm._l((_vm.stages),function(stage){return _c('v-btn',{key:stage,staticClass:"multiline-button",staticStyle:{"height":"100% !important"},attrs:{"disabled":_vm.assessmentsToCompare.every(
              (e) =>
                e.outcomesByStages.find(
                  (e) => e.stage === _vm.STAGE_DESCRIPTION[stage],
                ) !== undefined,
            ),"depressed":""}},[_vm._v("\n          "+_vm._s(_vm.STAGE_DESCRIPTION[stage])+"\n        ")])}),1)],1),_vm._v(" "),_c('v-card-text',[_c('venture-summary',{attrs:{"stage-to-show":_vm.stageToggle,"assessments":_vm.assessmentsToCompare,"stages":_vm.stages,"show-all":""}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }