import { GChart } from 'vue-google-charts';
export default defineComponent({
  components: {
    GChart: GChart
  },
  props: {
    chartType: {
      type: String,
      default: ''
    },
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    chartOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
});