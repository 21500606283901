import "core-js/modules/es6.array.find.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var climateImpact = computed(function () {
      var _props$impactGoal;
      return (_props$impactGoal = props.impactGoal) === null || _props$impactGoal === void 0 ? void 0 : _props$impactGoal.impacts.find(function (impact) {
        return impact.conceptId === 13613;
      });
    });
    var usingGhgTool = computed(function () {
      var _props$impactGoal2;
      return (_props$impactGoal2 = props.impactGoal) === null || _props$impactGoal2 === void 0 ? void 0 : _props$impactGoal2.impacts.some(function (impact) {
        var _impact$concept;
        return ((_impact$concept = impact.concept) === null || _impact$concept === void 0 ? void 0 : _impact$concept.id) === 13613 && impact.impactSolutions.some(function (solution) {
          return solution.useGhgSavingPotentialTool;
        });
      });
    });
    var showImpactProgress = computed(function () {
      var _props$impactGoal3, _props$impactGoal3$ta, _props$impactGoal4, _props$impactGoal4$ta;
      return !!((_props$impactGoal3 = props.impactGoal) !== null && _props$impactGoal3 !== void 0 && (_props$impactGoal3$ta = _props$impactGoal3.targetProgress) !== null && _props$impactGoal3$ta !== void 0 && _props$impactGoal3$ta.hasTarget) && (((_props$impactGoal4 = props.impactGoal) === null || _props$impactGoal4 === void 0 ? void 0 : (_props$impactGoal4$ta = _props$impactGoal4.targetProgress) === null || _props$impactGoal4$ta === void 0 ? void 0 : _props$impactGoal4$ta.completionRate) || 0) > 0;
    });
    var impactSolutions = computed(function () {
      var _props$impactGoal5, _props$impactGoal5$im;
      return (_props$impactGoal5 = props.impactGoal) === null || _props$impactGoal5 === void 0 ? void 0 : (_props$impactGoal5$im = _props$impactGoal5.impacts) === null || _props$impactGoal5$im === void 0 ? void 0 : _props$impactGoal5$im.flatMap(function (impact, index) {
        return impact.impactSolutions.map(function (solution) {
          return {
            solution: solution,
            impact: impact,
            impactIndex: index
          };
        });
      });
    });
    var metricAssignments = computed(function () {
      var _props$impactGoal6, _props$impactGoal6$im;
      return (_props$impactGoal6 = props.impactGoal) === null || _props$impactGoal6 === void 0 ? void 0 : (_props$impactGoal6$im = _props$impactGoal6.impacts) === null || _props$impactGoal6$im === void 0 ? void 0 : _props$impactGoal6$im.flatMap(function (impact, index) {
        return impact.metricAssignments.map(function (assignment) {
          return {
            metricAssignment: assignment,
            impactName: impactName(impact, index)
          };
        });
      });
    });
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var impactName = function impactName(impact, index) {
      var _impact$concept2, _impact$concept3;
      return impact !== null && impact !== void 0 && (_impact$concept2 = impact.concept) !== null && _impact$concept2 !== void 0 && _impact$concept2.label ? impact === null || impact === void 0 ? void 0 : (_impact$concept3 = impact.concept) === null || _impact$concept3 === void 0 ? void 0 : _impact$concept3.label : "".concat($i18n.t('impactGoal.navigation.impactNumber.label'), " ").concat(index + 1);
    };
    var impactSolutionName = function impactSolutionName(solution, index) {
      var _solution$concept;
      return solution !== null && solution !== void 0 && solution.concept ? solution === null || solution === void 0 ? void 0 : (_solution$concept = solution.concept) === null || _solution$concept === void 0 ? void 0 : _solution$concept.label : "".concat($i18n.t('impactGoal.overview.ourSolutions.solution'), " ").concat(index + 1);
    };
    return {
      climateImpact: climateImpact,
      usingGhgTool: usingGhgTool,
      showImpactProgress: showImpactProgress,
      impactSolutions: impactSolutions,
      impactName: impactName,
      impactSolutionName: impactSolutionName,
      metricAssignments: metricAssignments
    };
  },
  data: function data() {
    return {
      solutionRow: [{
        component: 'newimpact-cell-impact-stakeholder',
        isIcon: true,
        icon: 'mdi-nature-people'
      }],
      impactRow: [{
        component: 'newimpact-cell-impact-stakeholder',
        isIcon: true,
        icon: 'mdi-nature-people'
      }, {
        component: 'newimpact-cell-impact-geographies',
        isIcon: true,
        icon: 'mdi-map-marker'
      }, {
        component: 'newimpact-cell-iris-impact-categories',
        isIcon: false,
        imageContent: {
          name: 'iris_category.png',
          width: '40px',
          height: '40px'
        }
      }, {
        component: 'newimpact-cell-impact-sdgs',
        isIcon: false,
        imageContent: {
          name: 'sdg.png',
          width: '40px',
          height: '40px'
        }
      }, {
        component: 'newimpact-cell-impact-solution',
        isIcon: true,
        icon: 'mdi-lightbulb-on-outline'
      }],
      kpiRow: [
        // {
        //   component: 'newimpact-cell-impact-problem',
        //   isIcon: true,
        //   icon: 'mdi-skull',
        // },
        // {
        //   component: 'newimpact-cell-impact-sources',
        //   isIcon: true,
        //   icon: 'mdi-book-open-variant',
        // },
        // {
        //   component: 'newimpact-cell-impact-theory-of-change',
        //   isIcon: true,
        //   icon: 'mdi-lightbulb-on-outline',
        // },
        // TODO should we migrate existing data?
        // {
        //   component: 'newimpact-cell-impact-targets',
        //   isIcon: false,
        //   imageContent: {
        //     name: 'grow_up.png',
        //     width: '16px',
        //   },
        // },
      ]
    };
  },
  computed: {
    editImpactLink: function editImpactLink() {
      return {
        name: 'venture-impact-edit-intro'
      };
    }
  }
});