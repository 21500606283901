import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import ScoreBar from '@/components/ScoreBar.vue';
export default defineComponent({
  components: {
    ScoreBar: ScoreBar
  },
  setup: function setup(_, _ref) {
    var root = _ref.root;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var state = reactive({
      toggled: false,
      leftBasicInfoHeaders: [{
        text: 'Pitch line',
        value: 'pitchline'
      }, {
        text: 'Founders',
        value: 'founders'
      }, {
        text: 'Headquarter',
        value: 'headquarter'
      }, {
        text: 'Country',
        value: 'country'
      }, {
        text: 'Employee headcount',
        value: 'employeeHeadcount'
      }],
      rightBasicInfoHeaders: [{
        text: 'Founding year',
        value: 'foundingYear'
      }, {
        text: 'Legal entity',
        value: 'legalEntity'
      }, {
        text: 'Growth stage',
        value: 'growthStage'
      }, {
        text: 'Turnover (planned for this year)',
        value: 'turnOver'
      }],
      leftContextInfoHeaders: [{
        text: 'Business model',
        value: 'businessModel'
      }, {
        text: 'Main income stream',
        value: 'mainIncomeStreams'
      }, {
        text: 'Products/Services',
        value: 'productsServices'
      }, {
        text: 'Industries',
        value: 'industry'
      }, {
        text: 'Sub-Industries',
        value: 'subIndustry'
      }],
      rightContextInfoHeaders: [{
        text: 'Technologies',
        value: 'technologies'
      }, {
        text: 'Controversial business areas',
        value: 'controversialBusinessAreas'
      }, {
        text: 'Customer group',
        value: 'payingCustomer'
      }]
    });
    var assessment = computed(function () {
      return $store.getters['assessment/getAssessment'] || undefined;
    });
    var keyinfos = function keyinfos(headers) {
      if (assessment.value !== undefined) {
        return headers.map(function (h) {
          return _objectSpread(_objectSpread({}, h), {}, {
            value: assessment.value.keyinfo[h.value] || ''
          });
        });
      } else {
        return [];
      }
    };
    var showOtherValueIfExists = function showOtherValueIfExists(value, fieldName) {
      if (value === 'Other') {
        return assessment.value.keyinfo[fieldName];
      }
      if (value instanceof Array && value.includes('Other')) {
        var newValue = value.filter(function (f) {
          return f !== 'Other';
        });
        newValue.push(assessment.value.keyinfo[fieldName]);
        return newValue;
      }
      return value;
    };
    var preprocessValue = function preprocessValue(keyinfoItem) {
      if (keyinfoItem.value === undefined) return undefined;
      // The logic for processing values based on the text
      // Similar to the original preprocessValue method
    };
    return _objectSpread(_objectSpread({}, toRefs(state)), {}, {
      assessment: assessment,
      keyinfos: keyinfos,
      showOtherValueIfExists: showOtherValueIfExists,
      preprocessValue: preprocessValue
    });
  }
});