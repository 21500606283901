import { category as _category, color, totalPoints as _totalPoints, maxTotalPoints as _maxTotalPoints, totalSkipped, totalPercentage as _totalPercentage } from '~/functions/esg';
export default defineComponent({
  props: {
    sectionResults: {
      type: Array,
      default: []
    },
    fontSize: {
      type: Object,
      require: true,
      default: function _default() {
        return {
          score: 'prose-2xl',
          category: 'subtitle-1',
          totalScore: 'subtitle',
          numberSize: 'font-size: x-large'
        };
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    totalPercentage: function totalPercentage() {
      return _totalPercentage(this.sectionResults);
    },
    totalPoints: function totalPoints() {
      return _totalPoints(this.sectionResults);
    },
    maxTotalPoints: function maxTotalPoints() {
      return _maxTotalPoints(this.sectionResults);
    },
    category: function category() {
      return _category(this.totalPercentage, totalSkipped(this.sectionResults));
    },
    colorClass: function colorClass() {
      return color(this.totalPercentage, totalSkipped(this.sectionResults));
    },
    textClass: function textClass() {
      return this.colorClass + '--text';
    }
  },
  methods: {}
});