import { useMutation } from '@vue/apollo-composable';
import { useUpsertMetricValuesMutation } from '~/types/types';
import UPDATE_METRIC_ASSIGNMENT_MUTATION from '~/graphql/mutations/UpdateMetricAssignment.gql';
import { replaceCommaAndParseFloatOrNull } from '~/assets/helper/functions';
export default defineComponent({
  props: {
    metricAssignments: {
      default: function _default() {
        return [];
      },
      type: Array
    },
    simpleHeaders: {
      default: false,
      type: Boolean
    },
    ventureId: {
      required: true,
      type: String
    }
  },
  setup: function setup() {
    var _useUpsertMetricValue = useUpsertMetricValuesMutation(),
      upsertMetricValuesMutation = _useUpsertMetricValue.mutate;
    var _useMutation = useMutation(UPDATE_METRIC_ASSIGNMENT_MUTATION),
      updateMetricAssignmentMutation = _useMutation.mutate;
    var periods = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
    return {
      periods: periods,
      upsertMetricValuesMutation: upsertMetricValuesMutation,
      updateMetricAssignmentMutation: updateMetricAssignmentMutation
    };
  },
  methods: {
    updateMetricValue: function updateMetricValue(value) {
      this.upsertMetricValuesMutation({
        inputs: [{
          target: replaceCommaAndParseFloatOrNull(value.target),
          metricAssignmentId: value.metricAssignmentId,
          period: value.period
        }]
      });
    },
    handleCommentUpdate: function handleCommentUpdate(metricAssignment, comment) {
      var input = {
        id: metricAssignment.id,
        comment: comment
      };
      this.updateMetricAssignmentMutation({
        input: input
      });
    }
  }
});