var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-data-table',_vm._g(_vm._b({attrs:{"locale":_vm.$i18n.locale,"loading":_setup.loading,"dense":"","fixed-header":"","item-key":"id","headers":_setup.headers.map((header) => ({
        ...header,
        text: _vm.$t(header.value),
      })),"items":_setup.items,"sort-by":"id","server-items-length":_setup.itemCount,"footer-props":{
      'items-per-page-options': [10, 20, 30, -1],
    },"items-per-page":_setup.options.itemsPerPage,"options":_setup.options},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.label,"filter":_setup.search}})]}},{key:`item.impacts`,fn:function({ item }){return [(_setup.parseIros(item.iroLinks, 'IMPACT').visibleNames.length > 0)?_c('span',[_c('div',_vm._l((_setup.parseIros(item.iroLinks, 'IMPACT')
              .visibleNames),function(name,index){return _c('div',{key:`visible-${index}`},[_vm._v("\n            "+_vm._s(name)),_c('br')])}),0),_vm._v(" "),(_setup.parseIros(item.iroLinks, 'IMPACT').hiddenCount > 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer"},'span',attrs,false),on),[_vm._v("+"+_vm._s(_setup.parseIros(item.iroLinks, 'IMPACT').hiddenCount)+" more\n                impacts")])]}}],null,true)},[_vm._v(" "),_c('span',[_c('div',_vm._l((_setup.parseIros(item.iroLinks, 'IMPACT')
                    .hiddenNames),function(name,index){return _c('div',{key:`hidden-${index}`},[_vm._v("\n                  "+_vm._s(name)),_c('br')])}),0)])])],1):_vm._e()]):_vm._e()]}},{key:`item.risks`,fn:function({ item }){return [(_setup.parseIros(item.iroLinks, 'RISK').visibleNames.length > 0)?_c('span',[_c('div',_vm._l((_setup.parseIros(item.iroLinks, 'RISK')
              .visibleNames),function(name,index){return _c('div',{key:`visible-${index}`},[_vm._v("\n            "+_vm._s(name)),_c('br')])}),0),_vm._v(" "),(_setup.parseIros(item.iroLinks, 'RISK').hiddenCount > 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer"},'span',attrs,false),on),[_vm._v("+"+_vm._s(_setup.parseIros(item.iroLinks, 'RISK').hiddenCount)+" more\n                risks")])]}}],null,true)},[_vm._v(" "),_c('span',[_c('div',_vm._l((_setup.parseIros(item.iroLinks, 'RISK')
                    .hiddenNames),function(name,index){return _c('div',{key:`hidden-${index}`},[_vm._v("\n                  "+_vm._s(name)),_c('br')])}),0)])])],1):_vm._e()]):_vm._e()]}},{key:`item.opportunities`,fn:function({ item }){return [(
          _setup.parseIros(item.iroLinks, 'OPPORTUNITY').visibleNames.length > 0
        )?_c('span',[_c('div',_vm._l((_setup.parseIros(item.iroLinks, 'OPPORTUNITY')
              .visibleNames),function(name,index){return _c('div',{key:`visible-${index}`},[_vm._v("\n            "+_vm._s(name)),_c('br')])}),0),_vm._v(" "),(_setup.parseIros(item.iroLinks, 'OPPORTUNITY').hiddenCount > 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer"},'span',attrs,false),on),[_vm._v("+"+_vm._s(_setup.parseIros(item.iroLinks, 'OPPORTUNITY').hiddenCount)+"\n                more opportunities")])]}}],null,true)},[_vm._v(" "),_c('span',[_c('div',_vm._l((_setup.parseIros(
                    item.iroLinks,
                    'OPPORTUNITY',
                  ).hiddenNames),function(name,index){return _c('div',{key:`hidden-${index}`},[_vm._v("\n                  "+_vm._s(name)),_c('br')])}),0)])])],1):_vm._e()]):_vm._e()]}}],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }