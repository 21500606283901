export default defineComponent({
  props: {
    targetProgress: {
      type: Object,
      required: true
    },
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(props) {
    var score = computed(function () {
      if (props.targetProgress.completionRate) return props.targetProgress.completionRate * 100;else return 0;
    });
    return {
      score: score
    };
  }
});