var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.outcome !== undefined)?_c('span',[_c('v-card-title',[_c('v-row',{staticClass:"d-flex align-center flex-grow-1 flex-shrink-1",attrs:{"no-gutter":""}},[_c('v-col',{attrs:{"sm":"12","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"width":"28px","height":"28px"},attrs:{"fab":_vm.outcomeEditingState,"icon":!_vm.outcomeEditingState,"dark":_vm.outcomeEditingState,"elevation":"0","color":!_vm.outcomeEditingState
                  ? 'rgba(0, 0, 0, 0.54)'
                  : _vm.complete
                  ? 'success'
                  : 'danger'},on:{"click":function($event){return _vm.$emit('toggleCategory', _vm.category.name)}}},'v-btn',attrs,false),on),[(_vm.category.toggle)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3582528545)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(!_vm.outcomeEditingState
              ? 'Show indicators'
              : _vm.complete
              ? 'All indicators are confirmed'
              : 'There are indicators left to confirm'))])]),_vm._v(" "),_c('term',{attrs:{"term":"Impact risks","element":"Assessment"}},[_c('span',{staticClass:"text-overline font-weight-bold",staticStyle:{"font-size":"1rem !important"}},[_vm._v("Impact risks")])])],1),_vm._v(" "),_c('v-col',{attrs:{"sm":"12","md":"3"}},[_c('div',{staticClass:"text-overline"},[_vm._v("Impact management risk score")]),_vm._v(" "),_c('div',[_c('v-layout',{staticClass:"flex-wrap"},[_c('score-pill',{attrs:{"filled":"","score":_vm.score,"type":"risk","total":100}})],1)],1)])],1)],1),_vm._v(" "),(_vm.category.toggle)?_c('v-card-text',{staticClass:"ml-3 pr-0"},[_c('v-row',{staticStyle:{"margin":"0 !important"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"6","xl":"6"}},[(_vm.category.toggle)?_c('v-card-text',{staticClass:"py-0 pr-3",attrs:{"id":_vm.category.name + 'Table'}},_vm._l((_vm.indicators),function(indicator){return _c('indicator-row',{key:indicator.id,staticClass:"ml-20",staticStyle:{"padding-left":"50px !important","padding-right":"0 !important"},attrs:{"indicator-id":indicator.id,"indicator":indicator,"dimension":_vm.category.name,"is-positive":_vm.outcome.isPositive},on:{"change":_vm.handleChangeInIndicator}})}),1):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"pl-5 pt-0 pb-0 pr-0 flex-column d-flex",attrs:{"lg":"6","xl":"6"}},[_c('vertical-source-list',{staticStyle:{"flex-basis":"0px","flex-grow":"1","overflow-y":"auto"},attrs:{"id":_vm.category.name + 'Sources',"outcome":_vm.outcome,"indicators":_vm.indicators,"editable":true}})],1)],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }