import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import { logObserver } from '~/functions/helpers';
import { renderMarkdown } from '~/functions/markdown';
import { TypeMetricAssignedTo, useGetAccountQuery, useGetUserQuery, useListMetricsQuery, useListSectionsQuery } from '~/types/types';
export default defineComponent({
  props: {
    preSelectedMetrics: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: String,
      default: function _default() {
        return 'calc(100vh - 320px)';
      }
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    allowCustom: {
      type: Boolean,
      default: true
    },
    showFilters: {
      type: Boolean,
      default: true
    },
    showSelected: {
      type: Boolean,
      default: true
    },
    showAction: {
      type: Boolean,
      default: true
    },
    query: {
      type: Object,
      // filters applied on API(it should replace preSelectedFilters in the long run)
      default: function _default() {
        return {
          text: '',
          sections: [90, 117, 967, 989, 998]
        };
      }
      /*
          +-----+------------------------+
          | id  | ?column?               |
          |-----+------------------------|
          | 90  | PAI company data       |
          | 117 | IRIS Indicators        |
          | 967 | ImpactNexus Indicators |
          | 989 | EU taxonomy            |
          | 998 | EDCI Metrics           |
          +-----+------------------------+
      */
    }
  },
  setup: function setup(props, _ref) {
    var _props$query;
    var emit = _ref.emit;
    function findLeaves(section) {
      if (!section.sections || !section.sections.length) return [section.id];
      return section.sections.flatMap(function (subsection) {
        return findLeaves(subsection);
      });
    }
    var options = ref({
      page: 1,
      itemsPerPage: 15,
      sortBy: ['name'],
      sortDesc: [false]
    });
    var input = ref(_objectSpread(_objectSpread({}, props.query), {}, {
      sections: [-1].concat(_toConsumableArray(((_props$query = props.query) === null || _props$query === void 0 ? void 0 : _props$query.sections) || [])) // always include Other(id=-1)
    }));
    var variables = computed(function () {
      emit('update:query', input.value);
      return {
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        input: _objectSpread(_objectSpread({}, input.value), {}, {
          sort: options.value.sortBy.map(function (item, index) {
            return {
              field: item,
              sortDesc: options.value.sortDesc[index]
            };
          })
        })
      };
    });
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var account = computed(function () {
      var _accountResult$value;
      return ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) || {
        id: ''
      };
    });
    var sectionVariables = computed(function () {
      var _props$query2;
      return {
        filters: {
          ids: ((_props$query2 = props.query) === null || _props$query2 === void 0 ? void 0 : _props$query2.sections) || [],
          text: ''
        }
      };
    });
    var _useListSectionsQuery = useListSectionsQuery(sectionVariables, {
        enabled: props.showFilters
      }),
      sectionResult = _useListSectionsQuery.result,
      onResult = _useListSectionsQuery.onResult; // FIXME replace with new one

    onResult(function (result) {
      var _result$data;
      var sections = result === null || result === void 0 ? void 0 : (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.listSections;
      if (sections) {
        var leafIds = sections.flatMap(function (section) {
          return findLeaves(section);
        });
        input.value.sections = _toConsumableArray(new Set([].concat(_toConsumableArray(input.value.sections || []), _toConsumableArray(leafIds))));
      }
    });
    var _useListMetricsQuery = useListMetricsQuery(variables),
      metricsResult = _useListMetricsQuery.result,
      loading = _useListMetricsQuery.loading;
    var open = ref([0]);
    var sectionItems = computed(function () {
      var _sectionResult$value;
      return [{
        active: true,
        id: 0,
        label: '',
        sections: [].concat(_toConsumableArray(((_sectionResult$value = sectionResult.value) === null || _sectionResult$value === void 0 ? void 0 : _sectionResult$value.listSections) || []), _toConsumableArray(props.allowCustom ? [{
          active: true,
          leaf: true,
          description: '',
          label: '',
          id: -1,
          sections: []
        }] : []))
      }];
    });

    // metrics contains all metrics that are ONLY the selected sections
    var metrics = computed(function () {
      var _metricsResult$value$, _metricsResult$value;
      return (_metricsResult$value$ = (_metricsResult$value = metricsResult.value) === null || _metricsResult$value === void 0 ? void 0 : _metricsResult$value.listMetrics) !== null && _metricsResult$value$ !== void 0 ? _metricsResult$value$ : [];
    });
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var showDebugging = computed(function () {
      try {
        var _userResult$value, _userResult$value$get, _JSON$parse;
        return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : (_userResult$value$get = _userResult$value.getUser) === null || _userResult$value$get === void 0 ? void 0 : _userResult$value$get.isImpersonated) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging'));
      } catch (_unused) {}
    });
    var headers = computed(function () {
      var base = [];
      if (showDebugging.value) {
        base.push({
          value: 'id',
          sortable: true
        });
      }
      base.push({
        value: 'name',
        cellClass: 'font-weight-bold',
        sortable: true
      }, {
        value: 'unitName',
        sortable: true
      }, {
        value: 'assignedTo',
        sortable: true
      }, {
        value: 'type'
      }, {
        value: 'taxonomy',
        cellClass: 'overflow-auto'
      }
      // TODO implement external url for metrics
      // { text: 'Actions', value: 'actions' },
      );
      if (props.allowEdit && props.allowCustom) {
        base.push({
          value: 'edit'
        });
      }
      return base;
    });
    var localPreSelectedMetrics = ref(_toConsumableArray(props.preSelectedMetrics));
    var handleChange = function handleChange(ids) {
      var newMetrics = metrics.value.filter(function (metric) {
        return ids.includes(metric.id);
      });
      localPreSelectedMetrics.value = newMetrics;
    };
    // const loading = useQueryLoading()

    return {
      open: open,
      headers: headers,
      account: account,
      metrics: metrics,
      loading: loading,
      sectionItems: sectionItems,
      localPreSelectedMetrics: localPreSelectedMetrics,
      handleChange: handleChange,
      TypeMetricAssignedTo: TypeMetricAssignedTo,
      options: options,
      input: input,
      itemCount: computed(function () {
        var _metricsResult$value2;
        return ((_metricsResult$value2 = metricsResult.value) === null || _metricsResult$value2 === void 0 ? void 0 : _metricsResult$value2.countMetrics) || 0;
      })
    };
  },
  data: function data() {
    return {
      getMetricsQueryInput: {
        text: '',
        sections: []
      },
      logObserver: logObserver,
      searched: false,
      renderMarkdown: renderMarkdown,
      expanded: []
    };
  }
});