import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { renderMarkdown } from '~/functions/markdown';
import { useGetMetricLazyQuery, useGetUserQuery } from '~/types/types';
export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true
    },
    isComputed: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    metricAssignment: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    calculationType: {
      // ONLY used for metricAggregation
      type: String,
      required: false,
      default: ''
    },
    isPortfolio: {
      type: Boolean,
      required: false
    },
    search: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    },
    badge: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    }
  },
  setup: function setup(props) {
    // FIXME this component is really bad Q

    var _useGetMetricLazyQuer = useGetMetricLazyQuery(computed(function () {
        return {
          id: props.id
        };
      })),
      result = _useGetMetricLazyQuer.result,
      load = _useGetMetricLazyQuer.load,
      refetch = _useGetMetricLazyQuer.refetch;
    var onInput = function onInput(v) {
      if (v) {
        load() || refetch();
      }
    };
    var metric = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getMetric) || {};
    });
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var showDebugging = computed(function () {
      try {
        var _userResult$value, _userResult$value$get, _JSON$parse;
        return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : (_userResult$value$get = _userResult$value.getUser) === null || _userResult$value$get === void 0 ? void 0 : _userResult$value$get.isImpersonated) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging'));
      } catch (_unused) {}
    });
    return {
      showDebugging: showDebugging,
      metric: metric,
      onInput: onInput
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});