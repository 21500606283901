import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
export default defineComponent({
  components: {},
  props: {
    metricAssignment: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartOptions: function chartOptions() {
      var _this$metricAssignmen, _this$metricAssignmen2;
      return {
        chart: {
          type: 'line',
          stacked: false,
          animations: {
            enabled: false
          },
          id: 'vuechart-example',
          height: 300
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          type: 'numeric',
          title: {
            style: {
              fontSize: '14px'
            },
            text: (_this$metricAssignmen = this.metricAssignment) === null || _this$metricAssignmen === void 0 ? void 0 : (_this$metricAssignmen2 = _this$metricAssignmen.metric) === null || _this$metricAssignmen2 === void 0 ? void 0 : _this$metricAssignmen2.unitName
          }
        },
        colors: ['#ababab', '#5188ff'],
        tooltip: {
          shared: true,
          followCursor: false,
          marker: {
            show: true
          },
          fixed: {
            // enabled: false,
            position: 'topLeft'
            // offsetX: 0,
            // offsetY: 0,
          }
        },
        markers: {
          size: 4,
          colors: ['#ababab', '#5188ff'],
          strokeWidth: 2,
          strokeColors: '#FFFFFF',
          strokeOpacity: 1,
          hover: {
            sizeOffset: 2
          }
        },
        stroke: {
          width: 3
        },
        theme: {
          mode: 'light'
        }
      };
    },
    hasValues: function hasValues() {
      var _this$metricAssignmen3, _this$metricAssignmen4;
      return (_this$metricAssignmen3 = this.metricAssignment) === null || _this$metricAssignmen3 === void 0 ? void 0 : (_this$metricAssignmen4 = _this$metricAssignmen3.metricValues) === null || _this$metricAssignmen4 === void 0 ? void 0 : _this$metricAssignmen4.some(function (e) {
        return e.target !== null || e.actual !== null;
      });
    },
    series: function series() {
      var _this$metricAssignmen5, _this$metricAssignmen6, _this$metricAssignmen7, _this$metricAssignmen8, _this$metricAssignmen9, _this$metricAssignmen10;
      if (!this.metricAssignment) {
        return [];
      }
      return [{
        name: 'Target',
        data: (_this$metricAssignmen5 = this.metricAssignment) === null || _this$metricAssignmen5 === void 0 ? void 0 : (_this$metricAssignmen6 = _this$metricAssignmen5.metricValues) === null || _this$metricAssignmen6 === void 0 ? void 0 : (_this$metricAssignmen7 = _this$metricAssignmen6.filter(function (v) {
          return v.target || v.actual;
        })) === null || _this$metricAssignmen7 === void 0 ? void 0 : _this$metricAssignmen7.map(function (e) {
          return {
            x: e.period,
            y: e.target
          };
        })
      }, {
        name: 'Actual',
        data: (_this$metricAssignmen8 = this.metricAssignment) === null || _this$metricAssignmen8 === void 0 ? void 0 : (_this$metricAssignmen9 = _this$metricAssignmen8.metricValues) === null || _this$metricAssignmen9 === void 0 ? void 0 : (_this$metricAssignmen10 = _this$metricAssignmen9.filter(function (v) {
          return v.target || v.actual;
        })) === null || _this$metricAssignmen10 === void 0 ? void 0 : _this$metricAssignmen10.map(function (e) {
          return {
            x: e.period,
            y: e.actual
          };
        })
      }];
    }
  }
});