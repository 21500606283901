import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  computed: {
    questionnaire: function questionnaire() {
      var _this$impact;
      var questionnaires = (_this$impact = this.impact) === null || _this$impact === void 0 ? void 0 : _this$impact.euTaxonomyAlignmentQuestionnaires;
      if (questionnaires && questionnaires.length) return questionnaires[0];else return null;
    },
    questionnaireId: function questionnaireId() {
      var _this$questionnaire, _this$questionnaire$q;
      return (_this$questionnaire = this.questionnaire) === null || _this$questionnaire === void 0 ? void 0 : (_this$questionnaire$q = _this$questionnaire.questionnaireAssignment) === null || _this$questionnaire$q === void 0 ? void 0 : _this$questionnaire$q.id;
    },
    questionnaireLink: function questionnaireLink() {
      return {
        name: 'venture-impact-eu-taxonomy-questionnaire',
        params: {
          questionnaire: this.questionnaireId
        }
      };
    },
    results: function results() {
      var _questionnaire$questi, _questionnaire$questi2, _questionnaire$questi3, _questionnaire$questi4;
      var questionnaire = this.questionnaire;
      var sectionIds = (_questionnaire$questi = questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$questi2 = questionnaire.questionnaireTemplate) === null || _questionnaire$questi2 === void 0 ? void 0 : (_questionnaire$questi3 = _questionnaire$questi2.sections) === null || _questionnaire$questi3 === void 0 ? void 0 : _questionnaire$questi3.map(function (e) {
        return e.id;
      })) !== null && _questionnaire$questi !== void 0 ? _questionnaire$questi : [];
      return questionnaire === null || questionnaire === void 0 ? void 0 : (_questionnaire$questi4 = questionnaire.questionnaireAssignment) === null || _questionnaire$questi4 === void 0 ? void 0 : _questionnaire$questi4.questionnaireResults.filter(function (e) {
        return sectionIds.includes(e.sectionId);
      });
    }
  }
});