import { render, staticRenderFns } from "./impactGoalList.vue?vue&type=template&id=2b5a9bff&scoped=true"
import script from "./impactGoalList.vue?vue&type=script&lang=ts"
export * from "./impactGoalList.vue?vue&type=script&lang=ts"
import style0 from "./impactGoalList.vue?vue&type=style&index=0&id=2b5a9bff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5a9bff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxToolbarSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/toolbarSection.vue').default,CompanyButton: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/CompanyButton.vue').default,ImpactGoalProgressSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/ProgressSection.vue').default,ImpactPill: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactPill.vue').default,SdgList: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SdgList.vue').default,IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,ExportsImpactGoalExport: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/exports/ImpactGoalExport.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VDataTable,VDialog,VDivider,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VTextarea})
