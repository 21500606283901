import { useMutation } from '@vue/apollo-composable';
import SYNC_EU_TAXONOMY_ACTIVITIES from '~/graphql/mutations/impact-goal/SyncEuTaxonomyActivities.gql';
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var _useMutation = useMutation(SYNC_EU_TAXONOMY_ACTIVITIES),
      syncEuTaxonomyActivities = _useMutation.mutate,
      syncLoading = _useMutation.loading;
    return {
      syncEuTaxonomyActivities: syncEuTaxonomyActivities,
      syncLoading: syncLoading
    };
  },
  data: function data() {
    return {
      tooltip: 'The EU taxonomy currently lacks detailed elaboration on four out of the six environmental objectives, namely biodiversity conservation, pollution prevention and control, transition to a circular economy, and sustainable use and protection of water and marine resources. Therefore, it is not possible to perform an alignment check for these aspects in relation to the taxonomy.'
    };
  },
  computed: {},
  methods: {
    triggerSyncEuTaxonomyActivities: function triggerSyncEuTaxonomyActivities() {
      this.syncEuTaxonomyActivities({
        ventureId: this.impactGoal.ventureId
      });
    }
  }
});