var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._g(_vm._b({attrs:{"value":_vm.value,"locale":_vm.$i18n.locale,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.height,"item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"items":_vm.items,"sort-by":"id","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":(v) => {
      _vm.selectedIds = v
      _vm.$emit('input', v)
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ix-data-table-metrics',{attrs:{"height":'300px',"show-selected":false,"show-filters":false,"allow-edit":false,"allow-custom":false,"show-action":false,"query":{
              categoryIds: [item.id],
              sections: [
                // TODO-category without sending sections it shoud return expected result
                117, // 'IRIS Indicators',
                90, // 'PAI company data'
                967, // 'ImpactNexus Indicators',
                989, // 'EU taxonomy',
                998, // 'EDCI Metrics',
                82, // 'SFDR: PAI Indicators',
                1101, // 'KfW Capital: Indicators',
              ],
            }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-data-table-audit-logs',{staticClass:"max-h-[400px] overflow-scroll",attrs:{"columns":['diff', 'operationType', 'createdBy', 'createdAt'],"default-filters":{
              tableName: ['category'],
              dataId:
                '00000000-0000-0000-0000-' +
                item.id.toString(16).padStart(12, '0'),
            }}})],1)],1)],1)]}},{key:`item.label`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.label,"filter":_vm.search}})]}},{key:`item.actions`,fn:function(){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]},proxy:true}],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }