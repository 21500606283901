import { render, staticRenderFns } from "./IndicatorRow.vue?vue&type=template&id=8875155a&scoped=true"
import script from "./IndicatorRow.vue?vue&type=script&lang=ts"
export * from "./IndicatorRow.vue?vue&type=script&lang=ts"
import style0 from "./IndicatorRow.vue?vue&type=style&index=0&id=8875155a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8875155a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default,ScorePill: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ScorePill.vue').default,ScoreBarDiscrete: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ScoreBarDiscrete.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VDialog,VForm,VLayout,VRow,VTextarea})
