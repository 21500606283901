import { render, staticRenderFns } from "./categories.vue?vue&type=template&id=3b8655d4"
import script from "./categories.vue?vue&type=script&setup=true&lang=ts"
export * from "./categories.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxHeaderPortfolio: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/HeaderPortfolio.vue').default,IxDataTableCategories: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Categories.vue').default})
