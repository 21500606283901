import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.filter.js";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { useMutation } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { GChart } from 'vue-google-charts';
import { renderMarkdown } from '~/functions/markdown';
import { editablePrecontractualReportAdditionalInfo, editablePrecontractualReportForm } from '~/assets/fund/precontractualReport';
import { getEnvironmentalMetricNames, getNestedProperty, getSocialMetricNames } from '~/functions/sfdr';
import updateFundMutation from '~/graphql/mutations/account/UpdateFund.gql';
import updatePrecontractualReportMutation from '~/graphql/mutations/account/UpdatePrecontractualReport.gql';
import { useGetFundQuery, useUpdateAccountMutation, useGetFundReportQuery } from '~/types/types';
export default defineComponent({
  components: {
    GChart: GChart
  },
  setup: function setup() {
    var route = useRoute();
    var _useGetFundQuery = useGetFundQuery({
        id: toNumber(route.params.fund)
      }),
      result = _useGetFundQuery.result;
    var fund = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getFund;
    });
    var getFundReportQueryVariables = computed(function () {
      var _fund$value$id, _fund$value, _route$query;
      return {
        input: {
          id: (_fund$value$id = fund === null || fund === void 0 ? void 0 : (_fund$value = fund.value) === null || _fund$value === void 0 ? void 0 : _fund$value.id) !== null && _fund$value$id !== void 0 ? _fund$value$id : 0,
          referencePeriod: toNumber((_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.referencePeriod)
        }
      };
    });
    var _useGetFundReportQuer = useGetFundReportQuery(getFundReportQueryVariables, computed(function () {
        var _route$query2;
        return {
          enabled: !!((_route$query2 = route.query) !== null && _route$query2 !== void 0 && _route$query2.referencePeriod)
        };
      })),
      calculatedSfdrReportResult = _useGetFundReportQuer.result;
    var calculatedSfdrReport = computed(function () {
      var _calculatedSfdrReport;
      return (calculatedSfdrReportResult === null || calculatedSfdrReportResult === void 0 ? void 0 : (_calculatedSfdrReport = calculatedSfdrReportResult.value) === null || _calculatedSfdrReport === void 0 ? void 0 : _calculatedSfdrReport.getFundReport) || {
        nameOfFinancialMarketParticipant: '',
        legalEntityIdentifier: ''
      };
    });
    var taxonomyAlignedFossil = computed(function () {
      var _fund$value2, _fund$value2$precontr;
      return (_fund$value2 = fund.value) === null || _fund$value2 === void 0 ? void 0 : (_fund$value2$precontr = _fund$value2.precontractualReport) === null || _fund$value2$precontr === void 0 ? void 0 : _fund$value2$precontr.taxonomyAlignedFossil;
    });
    var taxonomyAlignedNuclear = computed(function () {
      var _fund$value3, _fund$value3$precontr;
      return (_fund$value3 = fund.value) === null || _fund$value3 === void 0 ? void 0 : (_fund$value3$precontr = _fund$value3.precontractualReport) === null || _fund$value3$precontr === void 0 ? void 0 : _fund$value3$precontr.taxonomyAlignedNuclear;
    });
    var taxonomyAlignedNoFossilNoNuclear = computed(function () {
      var _fund$value4, _fund$value4$precontr;
      return (_fund$value4 = fund.value) === null || _fund$value4 === void 0 ? void 0 : (_fund$value4$precontr = _fund$value4.precontractualReport) === null || _fund$value4$precontr === void 0 ? void 0 : _fund$value4$precontr.taxonomyAlignedNoFossilNoNuclear;
    });
    var otherInvestments = computed(function () {
      var _fund$value5, _fund$value5$precontr;
      return (_fund$value5 = fund.value) === null || _fund$value5 === void 0 ? void 0 : (_fund$value5$precontr = _fund$value5.precontractualReport) === null || _fund$value5$precontr === void 0 ? void 0 : _fund$value5$precontr.otherInvestments;
    });
    var getPieChartData = computed(function () {
      var rawData = [['Investment Type', 'Amount'], ['Fossil', taxonomyAlignedFossil.value], ['Nuclear', taxonomyAlignedNuclear.value], ['No Fossil No Nuclear', taxonomyAlignedNoFossilNoNuclear.value], ['Other Investments', otherInvestments.value]];
      var filteredData = rawData.filter(function (row) {
        return row[1] !== null && row[1] !== 0;
      }); // Filter null data
      if (filteredData.length <= 1) {
        return [['Investment Type', 'Amount'], ['No Data', 1]];
      }
      return filteredData;
    });
    var _useMutation = useMutation(updatePrecontractualReportMutation),
      updatePrecontractualReport = _useMutation.mutate;
    var _useMutation2 = useMutation(updateFundMutation),
      updateFund = _useMutation2.mutate,
      updateFundLoading = _useMutation2.loading;
    var calculatedPercentageQuestionKeys = computed(function () {
      if (calculatedSfdrReport.value.isArticle9) {
        return [{
          key: 'environmentalObjectiveCalculated',
          percentage: calculatedSfdrReport.value.percentSustainableInvestmentsWithEnvironmentalObjective
        }, {
          key: 'socialObjectiveCalculated',
          percentage: calculatedSfdrReport.value.percentSustainableInvestmentsWithSocialObjective
        }];
      } else {
        return [{
          key: 'esCharacteristicCalculated',
          percentage: calculatedSfdrReport.value.percentSustainableInvestmentsWhilePromotingEsCharacteristics
        }];
      }
    });
    var investments = ref({
      selected: [],
      headers: [{
        text: 'Largest investments',
        value: 'investment',
        sortable: true,
        align: 'center'
      }, {
        text: 'Sector',
        value: 'sector',
        sortable: true,
        align: 'center'
      }, {
        text: '% Assets',
        value: 'percentAssets',
        sortable: true,
        align: 'center'
      }, {
        text: 'Country',
        value: 'country',
        sortable: true,
        align: 'center'
      }]
    });
    var showCalculatedPercentage = ref(false);
    var toggleShowCalculatedPercentage = function toggleShowCalculatedPercentage() {
      showCalculatedPercentage.value = !showCalculatedPercentage.value;
    };
    var checkIfPercentagesSum = function checkIfPercentagesSum() {
      var _taxonomyAlignedFossi, _taxonomyAlignedNucle, _taxonomyAlignedNoFos, _otherInvestments$val;
      var sum = ((_taxonomyAlignedFossi = taxonomyAlignedFossil.value) !== null && _taxonomyAlignedFossi !== void 0 ? _taxonomyAlignedFossi : 0) + ((_taxonomyAlignedNucle = taxonomyAlignedNuclear.value) !== null && _taxonomyAlignedNucle !== void 0 ? _taxonomyAlignedNucle : 0) + ((_taxonomyAlignedNoFos = taxonomyAlignedNoFossilNoNuclear.value) !== null && _taxonomyAlignedNoFos !== void 0 ? _taxonomyAlignedNoFos : 0) + ((_otherInvestments$val = otherInvestments.value) !== null && _otherInvestments$val !== void 0 ? _otherInvestments$val : 0);
      return sum === 100;
    };
    var isConsideringPais = computed(function () {
      var _fund$value$isReporti, _fund$value6;
      return (_fund$value$isReporti = fund === null || fund === void 0 ? void 0 : (_fund$value6 = fund.value) === null || _fund$value6 === void 0 ? void 0 : _fund$value6.isReportingPais) !== null && _fund$value$isReporti !== void 0 ? _fund$value$isReporti : false;
    });
    var alignedInvestmentsTreeview = computed(function () {
      var _calculatedSfdrReport2;
      return [{
        id: 'hasFossilOrNuclearActivities',
        name: 'Yes',
        disabled: !((_calculatedSfdrReport2 = calculatedSfdrReport.value) !== null && _calculatedSfdrReport2 !== void 0 && _calculatedSfdrReport2.hasAlignedEnvironmentalInvestments),
        selected: calculatedSfdrReport.value.hasFossilOrNuclearActivities,
        children: [{
          id: 'hasFossilActivities',
          name: 'In fossil gas',
          disabled: !calculatedSfdrReport.value.hasAlignedEnvironmentalInvestments,
          selected: calculatedSfdrReport.value.hasFossilActivities
        }, {
          id: 'hasNuclearActivites',
          name: 'In nuclear energy',
          disabled: !calculatedSfdrReport.value.hasAlignedEnvironmentalInvestments,
          selected: calculatedSfdrReport.value.hasNuclearActivities
        }]
      }, {
        id: 'hasNoFossilNorNuclearActivities',
        name: 'No',
        disabled: !calculatedSfdrReport.value.hasAlignedEnvironmentalInvestments,
        selected: calculatedSfdrReport.value.hasNoFossilNorNuclearActivities
      }];
    });
    var formTreeviews = computed(function () {
      var _fund$value7, _fund$value7$precontr, _fund$value8, _fund$value8$precontr, _fund$value9, _fund$value9$precontr;
      var trees = {
        hasSustainableInvestmentObjective: [{
          id: 'percentSustainableInvestmentsWithEnvironmentalObjective',
          name: "It will make a minimum of **sustainable investments with an environmental objective**: ",
          dynamicValue: ((_fund$value7 = fund.value) === null || _fund$value7 === void 0 ? void 0 : (_fund$value7$precontr = _fund$value7.precontractualReport) === null || _fund$value7$precontr === void 0 ? void 0 : _fund$value7$precontr.percentSustainableInvestmentsWithEnvironmentalObjective) || null,
          disabled: !calculatedSfdrReport.value.isArticle9,
          selected: calculatedSfdrReport.value.hasEnvironmentalInvestments && calculatedSfdrReport.value.isArticle9,
          children: [{
            id: 'hasEnvironmentalObjectiveEnvironmentallySustainableUnderEuTaxonomy',
            name: 'in economic activities that qualify as environmentally sustainable under the EU Taxonomy',
            disabled: false,
            selected: calculatedSfdrReport.value.hasAlignedEnvironmentalInvestments && calculatedSfdrReport.value.isArticle9
          }, {
            id: 'hasEnvironmentalObjectiveNonEnvironmentallySustainableUnderEuTaxonomy',
            name: 'in economic activities that do not qualify as environmentally sustainable under the EU Taxonomy',
            disabled: false,
            selected: calculatedSfdrReport.value.hasNonAlignedEnvironmentalInvestments && calculatedSfdrReport.value.isArticle9
          }]
        }, {
          id: 'percentSustainableInvestmentsWithSocialObjective',
          name: "It will make a minimum of **sustainable investments with a social objective**: ",
          dynamicValue: ((_fund$value8 = fund.value) === null || _fund$value8 === void 0 ? void 0 : (_fund$value8$precontr = _fund$value8.precontractualReport) === null || _fund$value8$precontr === void 0 ? void 0 : _fund$value8$precontr.percentSustainableInvestmentsWithSocialObjective) || null,
          disabled: !calculatedSfdrReport.value.isArticle9,
          selected: calculatedSfdrReport.value.hasSocialObjective
        }],
        hasNoSustainableInvestmentObjective: [{
          id: 'hasEsCharacteristics',
          name: "It **promotes Environmental/Social (E/S) characteristics** and while it does not have as its objective a sustainable\n                investment, it will have a minimum proportion sustainable investments of: ",
          dynamicValue: ((_fund$value9 = fund.value) === null || _fund$value9 === void 0 ? void 0 : (_fund$value9$precontr = _fund$value9.precontractualReport) === null || _fund$value9$precontr === void 0 ? void 0 : _fund$value9$precontr.percentSustainableInvestmentsWhilePromotingEsCharacteristics) || null,
          disabled: calculatedSfdrReport.value.isArticle9,
          selected: calculatedSfdrReport.value.isArticle8Plus,
          children: [{
            id: 'hasEsCharacteristicsAndEnvironmentalObjectiveSustainableUnderEuTaxonomy',
            name: 'with an environmental objective in economic activities that qualify as environmentally sustainable under the EU Taxonomy',
            disabled: calculatedSfdrReport.value.isArticle9,
            selected: calculatedSfdrReport.value.isArticle8PlusWithAlignedEnvironmentalInvestments
          }, {
            id: 'hasEsCharacteristicsAndEnvironmentalObjectiveNonSustainableUnderEuTaxonomy',
            name: 'with an environmental objective in economic activities that do not qualify as environmentally sustainable under the EU Taxonomy',
            disabled: calculatedSfdrReport.value.isArticle9,
            selected: calculatedSfdrReport.value.isArticle8PlusWithNonAlignedEnvironmentalInvestments
          }, {
            id: 'hasEsCharacteristicsAndSocialObjective',
            name: 'with a social objective',
            disabled: calculatedSfdrReport.value.isArticle9,
            selected: calculatedSfdrReport.value.isArticle8PlusWithSocialObjective
          }]
        }, {
          id: 'hasEsCharacteristicsWithoutSustainableInvestment',
          name: "It promotes E/S characteristics, but\n                **will not make any sustainable investments**",
          disabled: calculatedSfdrReport.value.isArticle9,
          selected: calculatedSfdrReport.value.isArticle8
        }]
      };
      return trees;
    });
    var _useUpdateAccountMuta = useUpdateAccountMutation(),
      updateAccount = _useUpdateAccountMuta.mutate;
    var triggerUpdateAccount = function triggerUpdateAccount(key, val) {
      var _fund$value10, _fund$value10$portfol;
      var input = {
        id: (_fund$value10 = fund.value) === null || _fund$value10 === void 0 ? void 0 : (_fund$value10$portfol = _fund$value10.portfolio) === null || _fund$value10$portfol === void 0 ? void 0 : _fund$value10$portfol.id
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateAccount({
        input: input
      });
    };
    var triggerUpdateFund = function triggerUpdateFund(key, val) {
      var _fund$value11;
      var input = {
        id: (_fund$value11 = fund.value) === null || _fund$value11 === void 0 ? void 0 : _fund$value11.id
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateFund({
        input: input
      });
    };
    var triggerUpdatePrecontractualReport = function triggerUpdatePrecontractualReport(key, val) {
      var _fund$value12;
      var input = {
        id: (_fund$value12 = fund.value) === null || _fund$value12 === void 0 ? void 0 : _fund$value12.id,
        precontractualReport: _defineProperty({}, key, val === undefined || val === '' ? null : val)
      };
      updatePrecontractualReport({
        input: input
      });
    };
    return {
      fund: fund,
      calculatedSfdrReport: calculatedSfdrReport,
      triggerUpdateAccount: triggerUpdateAccount,
      triggerUpdateFund: triggerUpdateFund,
      triggerUpdatePrecontractualReport: triggerUpdatePrecontractualReport,
      updateFundLoading: updateFundLoading,
      calculatedPercentageQuestionKeys: calculatedPercentageQuestionKeys,
      investments: investments,
      showCalculatedPercentage: showCalculatedPercentage,
      toggleShowCalculatedPercentage: toggleShowCalculatedPercentage,
      getPieChartData: getPieChartData,
      taxonomyAlignedFossil: taxonomyAlignedFossil,
      taxonomyAlignedNuclear: taxonomyAlignedNuclear,
      taxonomyAlignedNoFossilNoNuclear: taxonomyAlignedNoFossilNoNuclear,
      otherInvestments: otherInvestments,
      checkIfPercentagesSum: checkIfPercentagesSum,
      isConsideringPais: isConsideringPais,
      getNestedProperty: getNestedProperty,
      getEnvironmentalMetricNames: getEnvironmentalMetricNames,
      getSocialMetricNames: getSocialMetricNames,
      form: editablePrecontractualReportForm,
      additionalInformation: editablePrecontractualReportAdditionalInfo,
      investmentsAlignedWithEuTaxonomyChartOptions: function investmentsAlignedWithEuTaxonomyChartOptions(title) {
        var options = {
          title: title,
          legend: true,
          colors: ['#809c7c', '#50744c', '#083404', '#e0dcdc']
        };
        return options;
      },
      investmentsCategorisationChartOptions: {
        packages: ['orgchart'],
        width: 800,
        height: 600
      },
      alignedInvestmentsTreeview: alignedInvestmentsTreeview,
      formTreeviews: formTreeviews,
      renderMarkdown: renderMarkdown
    };
  }
});