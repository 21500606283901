import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.number.constructor.js";
import { abbreviateNumber } from '~/assets/helper/functions';
export default defineComponent({
  props: {
    metricAssignment: {
      type: Object,
      required: true
    },
    impactName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var _periods$value;
    var targetParameters = computed(function () {
      return props.metricAssignment.completionStatus.targetParameters;
    });
    var periods = computed(function () {
      var _targetParameters$val, _targetParameters$val2, _targetParameters$val3, _targetParameters$val4;
      var start = (_targetParameters$val = targetParameters.value) !== null && _targetParameters$val !== void 0 && _targetParameters$val.startDate ? (_targetParameters$val2 = targetParameters.value) === null || _targetParameters$val2 === void 0 ? void 0 : _targetParameters$val2.startDate : 2020;
      var end = (_targetParameters$val3 = targetParameters.value) !== null && _targetParameters$val3 !== void 0 && _targetParameters$val3.endDate ? (_targetParameters$val4 = targetParameters.value) === null || _targetParameters$val4 === void 0 ? void 0 : _targetParameters$val4.endDate : 2030;
      return Array.from({
        length: end - start + 1
      }, function (_, i) {
        return start + i;
      });
    });
    var headers = [{
      text: '',
      value: 'progressBar',
      cellClass: 'align-center',
      sortable: false,
      width: '30%'
    }, {
      text: 'Values',
      value: 'valueLegend',
      cellClass: 'align-center',
      sortable: false,
      align: 'center'
    }].concat(_toConsumableArray((_periods$value = periods.value) === null || _periods$value === void 0 ? void 0 : _periods$value.map(function (e) {
      return {
        text: e,
        value: e,
        cellClass: 'align-center primary--text text-center',
        sortable: false,
        align: 'center'
      };
    })));
    return {
      headers: headers,
      periods: periods
    };
  },
  computed: {},
  methods: {
    calculateActual: function calculateActual(completionStatus) {
      var locale = this.$i18n.locale;
      var actual = completionStatus.actual;
      actual = actual ? Number(actual.toFixed(0)) : 0;
      return abbreviateNumber(actual, locale);
    },
    calculateTarget: function calculateTarget(completionStatus) {
      var _this$$i18n, _completionStatus$tar;
      var locale = (_this$$i18n = this.$i18n) === null || _this$$i18n === void 0 ? void 0 : _this$$i18n.locale;
      var target = (completionStatus === null || completionStatus === void 0 ? void 0 : (_completionStatus$tar = completionStatus.targetParameters) === null || _completionStatus$tar === void 0 ? void 0 : _completionStatus$tar.target) || 0;
      return abbreviateNumber(target, locale);
    }
  }
});