var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"locale":_vm.$i18n.locale,"value":_vm.selected,"loading":_vm.loading,"dense":"","fixed-header":"","height":'calc(100vh - 200px)',"item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"search":_vm.search,"show-select":_vm.showSelect,"items":_vm.items,"sort-by":"createdAt","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.search,"disabled":"","append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},on:{"input":(v) => {
            _vm.search = v
          }}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true},{key:"header.tableName",fn:function({ header }){return [_vm._v("\n    "+_vm._s(header.text)+"\n    "),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[(_vm.filters?.tableName?.length)?_c('v-badge',{attrs:{"color":"green","content":_vm.filters.tableName.length}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.tableName),callback:function ($$v) {_vm.$set(_vm.filters, "tableName", $$v)},expression:"filters.tableName"}},_vm._l(([
              'metric',
              'metricassignment',
              'metricaggregation',
              'account',
              'ix_tbl_user',
              'section',
              'concept',
              'metricvalue',
              'fund',
              'question',
            ]),function(item){return _c('v-list-item',{key:item,attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]}}],null,true)})}),1)],1)],1)]}},{key:"header.operationType",fn:function({ header }){return [_vm._v("\n    "+_vm._s(header.text)+"\n    "),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[(
              _vm.filters && _vm.filters.operationType && _vm.filters.operationType.length
            )?_c('v-badge',{attrs:{"color":"green","content":_vm.filters.operationType.length}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.operationType),callback:function ($$v) {_vm.$set(_vm.filters, "operationType", $$v)},expression:"filters.operationType"}},_vm._l((['INSERT', 'UPDATE']),function(item){return _c('v-list-item',{key:item,attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]}}],null,true)})}),1)],1)],1)]}},{key:"header.createdBy",fn:function({ header }){return [_vm._v("\n    "+_vm._s(header.text)+"\n    "),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[(_vm.filters?.createdById?.length)?_c('v-badge',{attrs:{"color":"green","content":_vm.filters.createdById.length}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('ix-data-table-users',{staticClass:"max-h-[670px] overflow-scroll",attrs:{"columns":['email', 'firstName', 'lastName']},model:{value:(_vm.filters.createdById),callback:function ($$v) {_vm.$set(_vm.filters, "createdById", $$v)},expression:"filters.createdById"}})],1)],1)]}},{key:`item.diff`,fn:function({ item }){return _vm._l((_vm.renderDiff(item)),function(change){return _c('span',{key:change.value,class:change.added
          ? 'text-green-500'
          : change.removed
            ? 'text-red-600 line-through'
            : 'text-slate-600'},[_vm._v("\n      "+_vm._s(change.value)+"\n    ")])})}},{key:`item.tableName`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.tableName)+"\n  ")]}},{key:`item.dataId`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.dataId.startsWith('00000000-')
        ? parseInt(item.dataId.split('-')[4], 16)
        : item.dataId)+"\n  ")]}},{key:`item.operationType`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.operationType)+"\n  ")]}},{key:`item.createdBy`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.createdBy && item.createdBy.email)+"\n  ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(new Date(item.createdAt + 'Z').toString())+"\n  ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":""},on:{"click":function($event){_vm.toDeleteVentureId = item.id}}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }