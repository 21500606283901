export default defineComponent({
  props: {
    impactConcept: {
      type: Object,
      default: null
    },
    companyConcept: {
      type: Object,
      default: null
    },
    impactTopics: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      tab: 0
    };
  },
  methods: {}
});