import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a1127c16"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MetricsTableForPortfolio: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/TableForPortfolio.vue').default,IxButtonInfo: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Info.vue').default})
