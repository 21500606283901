var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t('impactGoal.impactSolutions.impactSolution.solutionEvidence.title'),"subtitle":_vm.$t(
        'impactGoal.impactSolutions.impactSolution.solutionEvidence.subtitle',
      ),"is-mandatory":""}}),_vm._v(" "),_c('ix-data-table-evidence-link',{attrs:{"headers":[
      { value: 'actions', width: '30px' },
      { value: 'description', text: 'Key Insights' },
      { value: 'evidence', width: '200px' },
    ],"query":{
      entityIntIds: [_vm.impactSolution.id],
      type: 'impact-solution',
      accountId: _vm.ventureId,
    }}}),_vm._v(" "),_c('v-spacer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }