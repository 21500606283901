import { render, staticRenderFns } from "./PerformanceComparisonProgressBarDouble.vue?vue&type=template&id=b30109c4&scoped=true"
import script from "./PerformanceComparisonProgressBarDouble.vue?vue&type=script&lang=js"
export * from "./PerformanceComparisonProgressBarDouble.vue?vue&type=script&lang=js"
import style0 from "./PerformanceComparisonProgressBarDouble.vue?vue&type=style&index=0&id=b30109c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b30109c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VProgressLinear,VTooltip})
