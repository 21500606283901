import "core-js/modules/es6.array.map.js";
import IxDialog from '~/components/ix/Dialog.vue';
import IxFormInputDatePicker from '~/components/ix/form-input/DatePicker.vue';
import IxFormInputDateRangePicker from '~/components/ix/form-input/DateRangePicker.vue';
// import CREATE_ESG_TASK_AND_QUESTIONNAIRE_FOR_VENTURE_IDS from '~/graphql/mutations/questionnaire/CreateEsgTaskAndQuestionnaireForVentureIds.gql'

import { useCreateEsgTaskAndQuestionnaireForVentureIdsMutation, useGetAccountQuery, useGetEsgQuestionnaireResultsForVenturesQuery } from '~/types/types';
export default defineComponent({
  components: {
    IxDialog: IxDialog,
    IxFormInputDatePicker: IxFormInputDatePicker,
    IxFormInputDateRangePicker: IxFormInputDateRangePicker
  },
  props: {
    selected: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result;
    var portfolioId = computed(function () {
      var _result$value, _result$value$getAcco;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getAcco = _result$value.getAccount) === null || _result$value$getAcco === void 0 ? void 0 : _result$value$getAcco.id;
    });
    // const {
    //   mutate: createEsgTaskAndQuestionnaireForVentureIds,
    //   onDone: onDoneCreateEsgTaskAndQuestionnaireForVentureIds,
    // } = useMutation(CREATE_ESG_TASK_AND_QUESTIONNAIRE_FOR_VENTURE_IDS)
    var _useCreateEsgTaskAndQ = useCreateEsgTaskAndQuestionnaireForVentureIdsMutation(),
      createEsgTaskAndQuestionnaireForVentureIds = _useCreateEsgTaskAndQ.mutate,
      onDoneCreateEsgTaskAndQuestionnaireForVentureIds = _useCreateEsgTaskAndQ.onDone;
    var _useGetEsgQuestionnai = useGetEsgQuestionnaireResultsForVenturesQuery({
        ventureIds: []
      }),
      refetchEsgQuestionnaireResults = _useGetEsgQuestionnai.refetch;
    return {
      portfolioId: portfolioId,
      createEsgTaskAndQuestionnaireForVentureIds: createEsgTaskAndQuestionnaireForVentureIds,
      onDoneCreateEsgTaskAndQuestionnaireForVentureIds: onDoneCreateEsgTaskAndQuestionnaireForVentureIds,
      refetchEsgQuestionnaireResults: refetchEsgQuestionnaireResults
    };
  },
  data: function data() {
    return {
      referencePeriod: {
        standard: null,
        isCustom: false,
        dueDate: null,
        custom: []
      }
    };
  },
  methods: {
    createNewQuestionnaireRequest: function createNewQuestionnaireRequest(ventureIds) {
      var _this = this;
      var assignedBy = this.portfolioId;
      var input = null;
      if (this.referencePeriod.isCustom && this.referencePeriod.custom.length === 2) {
        input = {
          startDate: this.referencePeriod.custom[0],
          endDate: this.referencePeriod.custom[1],
          userDefined: true
        };
      } else if (this.referencePeriod.standard) {
        var standard = this.referencePeriod.standard;
        input = {
          id: standard.id,
          label: standard.label,
          startDate: standard.startDate,
          endDate: standard.endDate,
          userDefined: standard.userDefined
        };
      }
      this.createEsgTaskAndQuestionnaireForVentureIds({
        ventureIds: ventureIds,
        dueDate: this.referencePeriod.dueDate || null,
        assignedBy: assignedBy,
        referencePeriodInput: input
      });
      this.onDoneCreateEsgTaskAndQuestionnaireForVentureIds(function (_) {
        return _this.refetchEsgQuestionnaireResults({
          ventureIds: ventureIds
        });
      });
    },
    handleSubmitReferencePeriodRequest: function handleSubmitReferencePeriodRequest(selected) {
      var ventureIds = selected.map(function (item) {
        return item.ventureId;
      });
      this.createNewQuestionnaireRequest(ventureIds);
    },
    handleResetReferencePeriodInput: function handleResetReferencePeriodInput(val) {
      if (val === false) {
        this.referencePeriod.dueDate = null;
        this.referencePeriod.standard = null;
        this.referencePeriod.isCustom = false;
        this.referencePeriod.custom = [];
      }
    }
  }
});