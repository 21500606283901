export const state = () => ({
  activities: {},
})

const SET_RECOMMENDED_ACTIVITIES = 'SET_RECOMMENDED_ACTIVITIES'

export const getters = {
  getRecommendedBusinessActivities: (state: any) => (ventureId: number) => {
    return state.activities[ventureId] ?? []
  },
}

export const mutations = {
  [SET_RECOMMENDED_ACTIVITIES](state: { activities: any[] }, payload: any) {
    const { ventureId, activity } = payload
    if (state.activities[ventureId] === undefined) {
      state.activities[ventureId] = [activity]
    } else {
      const activityLabels = state.activities[ventureId].map(
        (storedActivity) => {
          return storedActivity.label
        }
      )
      if (!activityLabels.includes(activity.label)) {
        state.activities[ventureId].push(activity)
      }
    }
  },
}

export const actions = {
  setRecommendedBusinessActivities({ commit }, payload: any) {
    commit(SET_RECOMMENDED_ACTIVITIES, payload)
  },
}
