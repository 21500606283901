import DOMPurify from 'dompurify'

export const replaceCommaAndParseFloatOrNull = (
  value: String,
): number | null => {
  return value === null || value === ''
    ? null
    : parseFloat(value.replace(',', '.'))
}

// round until the nearest non-zero decimal place, e.g. 0.000001234567890 to 0.000001
export const roundToNearestNonZeroFractionDigit = (num) => {
  if (!num) return '0'
  if (Math.abs(num) >= 1) return num

  const decimalPart = Math.abs(num).toString().split('.')[1] || ''
  let precision = 0

  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] !== '0') {
      precision = i + 1
      break
    }
  }

  const power = Math.pow(10, precision)
  num = Math.round(num * power) / power

  return num.toString().replace(/\.?0+$/, '')
}

export const abbreviateNumber = (number: any | Number | bigint, locale) => {
  const scales = {
    en: [
      { value: 1e15, symbol: 'Qd' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'B' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'K' },
    ],
    de: [
      { value: 1e15, symbol: 'Brd' },
      { value: 1e12, symbol: 'Bio' },
      { value: 1e9, symbol: 'Mrd' },
      { value: 1e6, symbol: 'Mio' },
      { value: 1e3, symbol: 'T' },
    ],
  }

  const defaultLocale = 'en'
  const currentScales = scales[locale] || scales[defaultLocale]

  for (let i = 0; i < currentScales.length; i++) {
    const scale = currentScales[i]
    if (number >= scale.value) {
      const scaledNumber = number / scale.value
      return (
        new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(
          scaledNumber,
        ) +
        ' ' +
        scale.symbol
      )
    }
  }

  const rounded =
    Math.abs(number) < 1
      ? roundToNearestNonZeroFractionDigit(number)
      : Math.round(number * 10) / 10
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 3 }).format(
    rounded,
  )
}

export const highlightPlaceholders = (text) => {
  const regex = /{{\s*[^}]+\s*}}/g
  return text.replace(
    regex,
    (match) =>
      `<span style="color: #e91e63; font-weight: bold;">${DOMPurify.sanitize(match)}</span>`,
  )
}

// console.log(abbreviateNumber(123456, 'en-US'));      // Outputs: "123.5 T"
// console.log(abbreviateNumber(12345678, 'en-US'));    // Outputs: "12.3 M"
// console.log(abbreviateNumber(1234567890, 'en-US'));  // Outputs: "1.2 B"

// console.log(abbreviateNumber(123456, 'de-DE'));      // Outputs: "123,5 K"
// console.log(abbreviateNumber(12345678, 'de-DE'));    // Outputs: "12,3 Mio"
// console.log(abbreviateNumber(1234567890, 'de-DE'));  // Outputs: "1,2 Mrd"

// console.log(abbreviateNumber(1.234567890, 'de-DE'));  // Outputs: "1,2"
// console.log(abbreviateNumber(0.000001234567890, 'de-DE'));  // Outputs: "0,000001"
