var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.allInsights, function (sourceInsights, label) {
    return _c('v-tab', {
      key: label,
      attrs: {
        "disabled": sourceInsights.length === 0
      }
    }, [_vm._v(_vm._s(label) + " (" + _vm._s(sourceInsights.length) + ")")]);
  }), 1), _vm._v(" "), _vm.allInsights ? _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.allInsights, function (sourceInsights, label) {
    return _c('v-tab-item', {
      key: label,
      staticClass: "overflow-y-auto"
    }, [_c('v-expansion-panels', {
      attrs: {
        "flat": ""
      }
    }, _vm._l(sourceInsights, function (insight) {
      return _c('v-expansion-panel', {
        key: insight.id
      }, [_c('v-expansion-panel-header', {
        attrs: {
          "hide-actions": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref) {
            var open = _ref.open;
            return [_c('div', {
              staticClass: "flex-shrink-10",
              staticStyle: {
                "color": "var(--v-primary-base)"
              }
            }, [insight.highlight ? _c('div', [_c('v-layout', {
              staticClass: "mb-2",
              attrs: {
                "align-center": ""
              }
            }, [insight.labels && insight.labels.insight_quality.annotation ? _c('v-icon', {
              attrs: {
                "color": "primary"
              }
            }, [_vm._v("mdi-shield-check")]) : _c('v-icon', [_vm._v("mdi-shield-outline ")]), _vm._v(" "), _c('a', {
              staticClass: "mx-2",
              attrs: {
                "href": insight.url,
                "target": "_blank"
              }
            }, [_vm._v(_vm._s(insight.title))]), _vm._v(" "), _vm.isAdmin ? _c('v-dialog', {
              attrs: {
                "max-width": "90%"
              },
              scopedSlots: _vm._u([{
                key: "activator",
                fn: function fn(_ref2) {
                  var on = _ref2.on,
                    attrs = _ref2.attrs;
                  return [_c('v-btn', _vm._g(_vm._b({
                    staticClass: "flex-grow-0 flex-shrink-0",
                    attrs: {
                      "icon": "",
                      "small": ""
                    }
                  }, 'v-btn', attrs, false), on), [_c('v-icon', {
                    attrs: {
                      "small": ""
                    }
                  }, [_vm._v("mdi-pencil")])], 1)];
                }
              }, {
                key: "default",
                fn: function fn(dialog) {
                  return [_c('impact-topic-insight', {
                    attrs: {
                      "insight": insight
                    },
                    on: {
                      "submit": function submit($event) {
                        dialog.value = false;
                      }
                    }
                  })];
                }
              }], null, true)
            }) : _vm._e(), _vm._v(" "), !_vm.isAdmin && open ? _c('v-btn-toggle', {
              attrs: {
                "color": "grey"
              },
              model: {
                value: _vm.feedback,
                callback: function callback($$v) {
                  _vm.feedback = $$v;
                },
                expression: "feedback"
              }
            }, [_c('v-btn', {
              attrs: {
                "icon": "",
                "small": ""
              }
            }, [_c('v-icon', {
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-thumb-up")])], 1), _vm._v(" "), _c('v-btn', {
              attrs: {
                "icon": "",
                "small": ""
              }
            }, [_c('v-icon', {
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-thumb-down")])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _vm._l(insight.highlight.text, function (h, index) {
              return _c('div', {
                key: index,
                staticClass: "insight-snippet",
                domProps: {
                  "innerHTML": _vm._s(h)
                }
              });
            })], 2) : _c('div', [_c('a', {
              attrs: {
                "href": insight.url,
                "target": "_blank"
              }
            }, [_vm._v(_vm._s(insight.url))]), _vm._v(" "), _c('div', {
              staticStyle: {
                "color": "black"
              },
              domProps: {
                "innerHTML": _vm._s(_vm.renderMarkdown(_vm.highlightConcepts(insight.text)))
              }
            })])])];
          }
        }], null, true)
      }), _vm._v(" "), _c('v-expansion-panel-content', {
        key: insight.id,
        staticClass: "px-10"
      }, [_c('div', {
        staticClass: "markdown-field text-body-2 insight-text",
        domProps: {
          "innerHTML": _vm._s(_vm.renderMarkdown(_vm.highlightConcepts(insight.text)))
        }
      })])], 1);
    }), 1)], 1);
  }), 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };