import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { translate } from '../../functions/translate'
import { companyInfoStyle } from '../Impact/company_Info'
import { defaults, footerStyle } from '../shared/default'
import { benchmarkContent } from './benchmark'
import { comparisonContent, comparisonStyle } from './comparison'
import { disclosureSlideContent, disclosureStyle } from './disclosure_info'
import {
  environmentalSlideContent,
  environmentalStyle,
} from './environmental_info'
import { esgDashboardStyle, esgSlideContent } from './esg_profile'
import { esgReportStyle } from './esg_report'
import { governanceSlideContent } from './governance_info'
import { socialSlideContent } from './social_info'

pdfMake.vfs = pdfFonts.pdfMake.vfs

pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    medium: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    mediumitalics: 'Roboto-MediumItalic.ttf',
    bold: 'Roboto-Medium.ttf',
  },
}
const pageBreak = { text: '', pageBreak: 'before' }

export const docDefinition = (
  companyInfo,
  updatedAt,
  esgData,
  versionTextFirst,
  versionTextSecond,
  mapQuestionnaireResultsBySectionLabel,
  mapQuestionnaireResultsBySectionLabelForPrevious,
  esgSections,
  esgComparisonComments,
  showComparison,
  benchmarkTextSecond,
  benchmarkTextThird,
  secondData,
  thirdData,
  loggedInAccountType,
) => {
  return {
    ...defaults('ESG'),
    content: [
      esgSlideContent(companyInfo, updatedAt, esgData),
      handlePageBreak(esgData[0]),
      environmentalSlideContent(esgData[0]),
      handlePageBreak(esgData[1]),
      socialSlideContent(esgData[1]),
      handlePageBreak(esgData[2]),
      governanceSlideContent(esgData[2]),
      handlePageBreak(esgData[3]),
      disclosureSlideContent(esgData[3]),
      showComparison ? pageBreak : '',
      showComparison
        ? comparisonContent(
            `${translate('esg.comparison.current')}: ${versionTextFirst}`,
            `${translate('esg.comparison.previous')}: ${versionTextSecond}`,
            mapQuestionnaireResultsBySectionLabel,
            mapQuestionnaireResultsBySectionLabelForPrevious,
            esgSections,
            esgComparisonComments,
            translate('esg.comparison.latestPerformanceComparedToPrevious'),
          )
        : '',
      showComparison && loggedInAccountType === 'VENTURE' ? pageBreak : '',
      showComparison && loggedInAccountType === 'VENTURE'
        ? comparisonContent(
            companyInfo?.ventureName,
            benchmarkTextThird,
            mapQuestionnaireResultsBySectionLabel,
            thirdData,
            esgSections,
            esgComparisonComments,
            translate('esg.comparison.latestPerformanceComparedToIxBenchmark'),
          )
        : '',
      showComparison && loggedInAccountType === 'PORTFOLIO' ? pageBreak : '',
      showComparison && loggedInAccountType === 'PORTFOLIO'
        ? benchmarkContent(
            companyInfo?.ventureName,
            benchmarkTextSecond,
            benchmarkTextThird,
            mapQuestionnaireResultsBySectionLabel, // mapping first
            secondData,
            thirdData,
            esgSections,
          )
        : '',
    ],
    ...{
      styles: {
        ...companyInfoStyle,
        ...esgDashboardStyle,
        ...esgReportStyle,
        ...environmentalStyle,
        ...disclosureStyle,
        ...comparisonStyle,
        ...footerStyle,
      },
    },
  }
}
const handlePageBreak = (esgData) => {
  return !!(esgData && esgData.subsections && esgData.subsections.length)
    ? pageBreak
    : ''
}
export default class VentureDashboard {
  constructor(
    companyInfo,
    updatedAt,
    esgData,
    versionTextFirst,
    versionTextSecond,
    mapQuestionnaireResultsBySectionLabel,
    mapQuestionnaireResultsBySectionLabelForPrevious,
    esgSections,
    esgComparisonComments,
    showComparison,
    benchmarkTextSecond,
    bversionTextThird,
    secondData,
    thirdData,
    loggedInAccountType,
  ) {
    return pdfMake.createPdf(
      docDefinition(
        companyInfo,
        updatedAt,
        esgData,
        versionTextFirst,
        versionTextSecond,
        mapQuestionnaireResultsBySectionLabel,
        mapQuestionnaireResultsBySectionLabelForPrevious,
        esgSections,
        esgComparisonComments,
        showComparison,
        benchmarkTextSecond,
        bversionTextThird,
        secondData,
        thirdData,
        loggedInAccountType,
      ),
    )
  }
}
