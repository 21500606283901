import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  props: {
    assessment: Object,
    allImpacts: Array,
    impactsForAssessment: Array,
    stage: String,
    filteredBy: String,
    showPositive: Boolean
  },
  data: function data() {
    return {
      type: 'impactpotential',
      showDetails: false
    };
  },
  computed: {
    score: function score() {
      if (['Outcome-selection venture', 'Outcome-selection reviewer'].includes(this.stage)) return 'impactPotentialPhase1';
      return 'impactPotentialScore';
    }
  },
  watch: {
    assessment: {
      handler: function handler() {
        this.showDetails = false;
      },
      deep: true
    }
  }
});