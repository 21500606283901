export default defineComponent({
  props: {
    ventureId: {
      required: true,
      type: String
    },
    impactGoal: {
      required: true,
      type: Object
    },
    impact: {
      required: true,
      type: Object
    }
  },
  setup: function setup() {
    var route = useRoute();
    var solutionIndex = computed(function () {
      return parseInt(route.params.solution);
    });
    return {
      solutionIndex: solutionIndex
    };
  }
});