var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',{staticStyle:{"border-radius":"12px !important"}},[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.title',
    )))]),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.body`,
              ),
            )
          )}})])],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{on:{"click":function($event){_vm.showIntroductionImpactProfile = !_vm.showIntroductionImpactProfile}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showIntroductionImpactProfile
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.introductionImpactProfile',
              ))+"\n          ")],1)]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.showIntroductionImpactProfile)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.introductionImpactProfileContent`,
              ),
            )
          )}}):_vm._e()],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.optionsHeader`,
              ),
            )
          )}}),_vm._v(" "),_c('ix-form-input-large-options',{attrs:{"options":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.impactProfile.impactProfiles',
            ),"value":_vm.selectedImpactProfiles,"is-editable":true,"is-multiple":false},on:{"input":_vm.valueChange}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }