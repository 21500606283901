
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      dialog: false,
      valueChain: {
        inputs: [],
        activities: [],
        outputs: [],
      },
      defaultItems: [
        {
          header:
            'Press "Enter" after typing to create a new item. You can create multiple items. You can delete items by pressing the "Del" key.',
        },
      ],
      rules: {
        requiredRuleSelect: [
          (v: any) => v.length > 0 || 'At least one item is required',
        ],
      },
      valid: false,
    }
  },
  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }
    },
    assessment() {
      return this.$store.getters['assessment/getAssessment']
    },
    keyInfos() {
      if (this.assessment !== undefined) {
        return { ...this.valueChain, ...this.assessment.keyinfo }
      }
      return this.valueChain
    },
  },
  methods: {
    async update(doRedirect: boolean) {
      if (this.form.validate()) {
        const id = this.keyInfos.id
        const fields = {
          assessment: [this.assessment.id],
          ...this.keyInfos,
        }
        fields.id = undefined
        await this.$store.dispatch('assessment/updateKeyInfo', {
          id,
          fields,
        })
        if (doRedirect === true) {
          this.$router.push(`/${this.assessment.id}/open-tasks`)
        }
      }
    },
  },
})
