import _ from 'lodash';
import { formatAsGermanDate } from '~/functions/helpers';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    label: {
      type: String,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      menu: false
    };
  },
  computed: {
    datesFormatted: function datesFormatted() {
      if (_.isEmpty(this.value)) return '';
      var unfiltered = [this.formatDate(this.value[0]), this.formatDate(this.value[1])];
      var dates = [];
      unfiltered.forEach(function (date) {
        if (date !== null) {
          dates.push(date);
        }
      });
      return dates.join(' - ');
    }
  },
  methods: {
    handleInput: function handleInput(value) {
      if (value.length === 2) {
        this.menu = false;
      }
      this.$emit('input', value);
    },
    formatDate: function formatDate(date) {
      if (!date) return null;
      return formatAsGermanDate(date);
    }
  }
});