import axios from 'axios'
import Sugar from 'sugar'
import Vue from 'vue'
import { glossary } from '~/assets/glossary'
import { hoverTexts } from '~/assets/hoverTexts'
import { AirtableResponse, SourceRecord } from '~/interfaces'

// enhance the original axios adapter with cache
// https://github.com/kuitos/axios-extensions#cacheadapterenhancer
const http = axios.create({
  baseURL: process.env.DOMAIN,
  headers: { 'Cache-Control': 'no-cache' },
})

const SET_ASSESSMENTS = 'SET_ASSESSMENTS'
const SET_ASSESSMENT = 'SET_ASSESSMENT'
const SET_VENTURE = 'SET_VENTURE'
const SET_GUEST_ASSESSMENT = 'SET_GUEST_ASSESSMENT'
const SET_IMPACTS = 'SET_IMPACTS'
const EDIT_ASSESSMENT = 'EDIT_ASSESSMENT'
const EDIT_ASSESSMENTS = 'EDIT_ASSESSMENTS'
const SET_ASSESSMENTRID = 'SET_ASSESSMENTRID'
const SET_LOADING = 'SET_LOADING'
const ADD_INDICATOR = 'ADD_INDICATOR'
const UPDATE_INDICATOR = 'UPDATE_INDICATOR'
const DELETE_INDICATOR = 'DELETE_INDICATOR'
const ADD_SOURCE = 'ADD_SOURCE'
const EDIT_SOURCE = 'EDIT_SOURCE'
const ADD_OUTCOME = 'ADD_OUTCOME'
const EDIT_OUTCOME = 'EDIT_OUTCOME'
const DELETE_OUTCOME = 'DELETE_OUTCOME'
const SET_KEY_INFO = 'SET_KEY_INFO'

export const state = () => ({
  venture: undefined,
  currentAssessment: '',
  assessments: [] as any[],
  loading: false,
  impacts: [] as AirtableResponse[],
  geographies: [] as any[],
  assessmentsWhereUserIsUnderAssessment: [],
  hoverText: hoverTexts,
  glossary,
})

export const getters = {
  getVenture: (state: { venture: any }) => {
    if (state.venture) return state.venture
    return undefined
  },
  getVentureId: (state: { venture: any }) => {
    if (state.venture && state.venture.id) return state.venture.id
    return undefined
  },
  getAssessment: (state: { assessments: any[]; currentAssessment: string }) => {
    return state.assessments.find((e: any) => {
      return e.id === state.currentAssessment
    })
  },
  getSelectedAssessmentHasImpactModule: (_state: any, getters: any) => {
    const assessment: any = getters.getAssessment
    if (assessment && assessment.assessment && assessment.assessment.modules)
      return assessment.assessment.modules.includes('Impact')
    return false
  },
  getBookedModulesForAllAssessments: (state: { assessments: any[] }) => {
    if (state.assessments && state.assessments.length) {
      return state.assessments.map((e: any) => e.assessment.modules)
    }
    return []
  },
  getAssessments: (state: { assessments: any[] }) => {
    return state.assessments
  },
  getAssessmentsWithImpactModule: (state: { assessments: any[] }) => {
    if (state.assessments && state.assessments.length) {
      return state.assessments.filter(
        (e: any) =>
          e.assessment.modules && e.assessment.modules.includes('Impact'),
      )
    }
    return []
  },
  getSomeAssessmentHasModule:
    (_state: any, getters: any) => (module: string) => {
      const bookedModules: any = getters.getBookedModulesForAllAssessments
      return bookedModules.some((modulesForVenture: Array<String>) =>
        modulesForVenture.includes(module),
      )
    },
  getAssessmentsToCompare:
    (state: { assessments: any[] }) => (ids: string[]) => {
      return ids
        .map((e) => state.assessments.find((r) => r.id === e))
        .filter((e) => e !== undefined)
    },
  getSourceForIndicator: (state: { sources: any }) => (id: string) => {
    return state.sources[id]
  },
  getEditingState: (_state: any, getters: any) => (impactId: number) => {
    const assessment: any = getters.getAssessment
    if (
      assessment.assessment.currentUserRole === 'venture' &&
      assessment.assessment.status === 'Assessment input' &&
      assessment.outcomesByStages.find(
        (item: any) => item.stage === 'Assessment venture',
      ).outcomes.length > 0 &&
      assessment.outcomes[impactId].stage === 'Assessment venture' &&
      assessment.outcomes[impactId].confirmedForNextStage !== 'confirmed'
    ) {
      return true
    } else if (
      assessment.assessment.currentUserRole === 'reviewer' &&
      assessment.assessment.status === 'Assessment in review' &&
      assessment.outcomesByStages.find(
        (item: any) => item.stage === 'Assessment reviewer',
      ).outcomes.length > 0 &&
      assessment.outcomes[impactId].stage === 'Assessment reviewer' &&
      assessment.outcomes[impactId].confirmedForNextStage !== 'confirmed'
    ) {
      return true
    } else {
      return false
    }
  },
  getHighlightSources: (_: any, getters: any) => {
    const assessment = getters.getAssessment
    if (assessment === undefined) {
      return []
    }

    return (
      assessment?.sources?.filter(
        (e: any) =>
          e.comment !== undefined &&
          e.hypothesisTags &&
          e.hypothesisTags.match('"Highlight"'),
      ) || []
    )
  },

  getSourcesForOutcomeAndIndicatorName:
    (_: any, getters: any) => (outcomeId: string, indicatorName: string) => {
      const assessment = getters.getAssessment
      if (assessment === undefined && assessment.indicators === undefined) {
        return []
      }
      const indicatorsForOutcome = assessment.indicators
        .filter((e: any) => e['Outcome Selection/RID'] === outcomeId)
        .filter((e: any) => e.Indicator === indicatorName)
        .map((indicator: any) => indicator.id)

      return assessment.sources.filter((source: any) =>
        source.indicatorLinks.some((indicatorId: string) =>
          indicatorsForOutcome.includes(indicatorId),
        ),
      )
    },

  getIndicators: (_state: any, getters: any) => (outcomeId: string) => {
    if (outcomeId === undefined) return []
    const assessment = getters.getAssessment
    const indicators = assessment.indicators.filter(
      (e: any) => e['Outcome Selection/RID'] === outcomeId,
    )
    return indicators || []
  },
}

export const mutations = {
  [SET_ASSESSMENTRID](
    state: { currentAssessment: string },
    payload: { assessmentRId: string },
  ) {
    if (payload.assessmentRId !== state.currentAssessment) {
      state.currentAssessment = payload.assessmentRId
    }
  },
  [SET_VENTURE](state: { venture: any[] }, payload: any[]) {
    state.venture = payload
  },
  [SET_ASSESSMENTS](state: { assessments: any[] }, payload: any[]) {
    state.assessments = payload?.map((e) => {
      const detailedAssessment = state.assessments.find(
        (detailedAssessment) => detailedAssessment.assessment.id === e.id,
      )
      if (detailedAssessment) {
        return detailedAssessment
      }
      return {
        assessment: {
          ...e,
          statusUpdateTime: new Sugar.Date(e.statusUpdateTime),
        },
        id: e.id,
      }
    })
  },
  [SET_IMPACTS](state: { impacts: any[] }, payload: AirtableResponse[]) {
    state.impacts = payload
  },
  [SET_ASSESSMENT](
    state: { assessments: any[] },
    payload: { rid: string; data: any },
  ) {
    const assessment = payload.data

    assessment.assessment.statusUpdateTime = new Sugar.Date(
      assessment.assessment.statusUpdateTime,
    )
    let assessmentUpdated = false
    const assessments = state.assessments.map((e: any) => {
      if (e.assessment.id === payload.rid) {
        assessmentUpdated = true
        return { id: payload.rid, ...assessment }
      }
      return e
    })
    if (!assessmentUpdated) {
      state.assessments.push({ id: payload.rid, ...assessment })
    } else {
      Vue.set(state, 'assessments', [...assessments])
    }
  },
  [SET_GUEST_ASSESSMENT](
    state: { assessments: any[] },
    payload: { rid: string; data: any },
  ) {
    const assessment = payload.data

    assessment.assessment.statusUpdateTime = new Sugar.Date(
      assessment.assessment.statusUpdateTime,
    )
    let assessmentUpdated = false
    const assessments = state.assessments.map((e: any) => {
      if (e.assessment.id === payload.rid) {
        assessmentUpdated = true
        return { id: payload.rid, ...assessment }
      }
      return e
    })
    if (!assessmentUpdated) {
      state.assessments.push({ id: payload.rid, ...assessment })
    } else {
      Vue.set(state, 'assessments', [...assessments])
    }
  },
  [EDIT_ASSESSMENT](state: any, payload: any) {
    state.assessments = state.assessments.map((e: any) => {
      if (e.assessment.id === payload.id) {
        return { ...e, assessment: payload }
      }
      return e
    })
  },
  [EDIT_ASSESSMENTS](state: any, payloads: any) {
    payloads.forEach((payload: any) => {
      const index = state.assessments.findIndex(
        (a: any) => a.assessment.id === payload.id,
      )
      state.assessments[index].assessment = payload
    })
  },
  [SET_LOADING](state, payload: boolean) {
    state.loading = payload
  },
  // Workflow
  //   Indicator
  [ADD_INDICATOR](state: any, payload: any) {
    const index: any = state.assessments.findIndex((e: any) => {
      return e.assessment.id === state.currentAssessment
    })
    const indicators = [...state.assessments[index].indicators]
    indicators.push(payload)
    Vue.set(state.assessments[index], 'indicators', indicators)
  },
  [UPDATE_INDICATOR](state: any, payload: any) {
    const assessment: any = getters.getAssessment(state)
    const indicator = assessment.indicators.find(
      (e: any) => e.id === payload.indicatorId,
    )
    Object.assign(indicator, payload.payload)
  },
  [DELETE_INDICATOR](state: any, payload: any) {
    const index: any = state.assessments.findIndex((e: any) => {
      return e.assessment.id === state.currentAssessment
    })
    const indicators = state.assessments[index].indicators.filter(
      (e: any) => e.id !== payload.id,
    )
    Vue.set(state.assessments[index], 'indicators', indicators)
  },
  //   Sources
  [ADD_SOURCE](state: any, payload: any) {
    getters.getAssessment(state).sources.push(payload)
  },
  [EDIT_SOURCE](state: any, payload: any) {
    const assessment = getters.getAssessment(state)
    const indicatorIds = assessment.indicators.map((e: any) => e.id)
    let newSources = []
    if (
      payload.indicatorLinks &&
      payload.indicatorLinks.some((id: string) => indicatorIds.includes(id))
    ) {
      newSources = assessment.sources.map((e: SourceRecord) =>
        e.id === payload.id ? payload : e,
      )
    } else {
      newSources = assessment.sources.filter(
        (e: SourceRecord) => e.id !== payload.id,
      )
    }
    Vue.set(assessment, 'sources', newSources)
  },
  //   Outcome
  [ADD_OUTCOME](state: any, payload: { stage: string; outcome: any }) {
    const assessment = getters.getAssessment(state)
    const index = assessment.outcomesByStages.findIndex(
      (item: any) => item.stage === payload.stage,
    )
    assessment.outcomesByStages[index].outcomes.push(payload.outcome.id)
    const outcomes = { ...assessment.outcomes }
    outcomes[payload.outcome.id] = payload.outcome
    assessment.outcomes = outcomes
  },
  [EDIT_OUTCOME](state: any, payload: { outcome: any }) {
    const assessment = getters.getAssessment(state)
    const outcomes = { ...assessment.outcomes }
    outcomes[payload.outcome.id] = payload.outcome
    assessment.outcomes = outcomes
  },
  [DELETE_OUTCOME](state: any, payload: { stage: string; id: string }) {
    const assessment = getters.getAssessment(state)
    const index = assessment.outcomesByStages.findIndex(
      (item: any) => item.stage === payload.stage,
    )
    assessment.outcomesByStages[index].outcomes = assessment.outcomesByStages[
      index
    ].outcomes.filter((id: string) => id !== payload.id)
    delete assessment.outcomes[payload.id]
  },
  [SET_KEY_INFO](state: any, payload: boolean) {
    const assessment = getters.getAssessment(state)
    assessment.keyinfo = payload
  },
}

export const actions = {
  initialise() {},
  async fetchAssessments({ commit }: any) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .get('/api/assessments', {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then((r) => {
        commit(SET_ASSESSMENTS, r?.data || [])
      })
    commit(SET_LOADING, false)
  },
  async fetchImpacts({ commit }: any, payload: { token: any }) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .get('/api/portfolio/impacts', {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then((r) => {
        commit(SET_IMPACTS, r.data)
        commit(SET_LOADING, false)
      })
  },
  async fetchAssessment({ commit }: any, payload: { token: any; rid: string }) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .get(`/api/assessment/${payload.rid}`, {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then((r) => {
        console.log('after fetch single', r, payload.rid)
        commit(SET_ASSESSMENT, { data: r.data, rid: payload.rid })
        commit(SET_LOADING, false)
      })
  },
  async fetchGuestAssessment(
    { commit }: any,
    payload: { rid: string; hash: string },
  ) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .get(`/api/guest/assessment/${payload.rid}?hash=${payload.hash}`)
      .then((r) => {
        commit(SET_GUEST_ASSESSMENT, { data: r.data, rid: payload.rid })
        commit(SET_LOADING, false)
      })
  },
  async updateAssessment(
    { commit }: any,
    payload: { id: string; fields: any; token: string },
  ) {
    await window.$nuxt.$axios
      .patch(`/api/assessment/${payload.id}`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res) => {
        commit(EDIT_ASSESSMENT, res.data)
      })
  },
  async updateBulkAssessment(
    { commit }: any,
    payload: { records: any; token: string },
  ) {
    await window.$nuxt.$axios
      .patch('/groups', payload.records, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res) => {
        commit(EDIT_ASSESSMENTS, res.data)
      })
  },
  // eslint-disable-next-line no-empty-pattern
  async submitIndicator(
    { commit }: any,
    payload: { fields: any; token: string },
  ) {
    commit(SET_LOADING, true)
    const response = await window.$nuxt.$axios
      .post(`/api/assessment/workflow`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(ADD_INDICATOR, res.data)
        commit(SET_LOADING, false)
        return res.data
      })
    return response
  },
  async deleteIndicator(
    { commit }: any,
    payload: { outcomeId: string; indicatorId: string; token: any },
  ) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .delete(`/api/assessment/workflow/${payload.indicatorId}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((_: any) => {
        commit(DELETE_INDICATOR, {
          outcomeId: payload.outcomeId,
          indicatorId: payload.indicatorId,
        })
        commit(SET_LOADING, false)
      })
  },
  async updateIndicator(
    { commit }: any,
    payload: { token: any; rid: string; fields: any },
  ) {
    await window.$nuxt.$axios
      .patch(`/api/assessment/workflow/${payload.rid}`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(UPDATE_INDICATOR, res.data)
      })
  },
  async submitSource({ commit }: any, payload: { fields: any; token: string }) {
    await window.$nuxt.$axios
      .post(`/api/assessment/sources`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(ADD_SOURCE, res.data)
      })
  },
  async updateSource(
    { commit }: any,
    payload: { id: string; fields: any; token: string },
  ) {
    await window.$nuxt.$axios
      .patch(`/api/assessment/sources/${payload.id}`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(EDIT_SOURCE, res.data)
      })
  },
  // Workflow
  async submitOutcome(
    { commit }: any,
    payload: { stage: string; fields: any; token: string },
  ) {
    commit(SET_LOADING, true)
    const response = await window.$nuxt.$axios
      .post(`/api/assessment/outcomes`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(ADD_OUTCOME, { stage: payload.stage, outcome: res.data })
        commit(SET_LOADING, false)
        return res.data
      })
    return response
  },
  async updateOutcome(
    { commit }: any,
    payload: { id: string; fields: any; token: string },
  ) {
    await window.$nuxt.$axios
      .patch(`/api/assessment/outcomes/${payload.id}`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(EDIT_OUTCOME, { outcome: res.data })
      })
  },
  async deleteOutcome(
    { commit }: any,
    payload: { id: string; stage: string; token: string },
  ) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .delete(`/api/assessment/outcomes/${payload.id}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((_: any) => {
        commit(DELETE_OUTCOME, { id: payload.id, stage: payload.stage })
        commit(SET_LOADING, false)
      })
  },
  async updateKeyInfo(
    { commit }: any,
    payload: { id: string; fields: any; token: string },
  ) {
    commit(SET_LOADING, true)
    await window.$nuxt.$axios
      .patch(`/api/assessment/keyinfos/${payload.id}`, payload.fields, {
        headers: {
          'Content-type': 'application/json',
          Authorization: payload.token,
        },
      })
      .then((res: any) => {
        commit(SET_KEY_INFO, res.data)
        commit(SET_LOADING, false)
      })
  },
}
