import { render, staticRenderFns } from "./open-tasks.vue?vue&type=template&id=dd99cd48"
import script from "./open-tasks.vue?vue&type=script&lang=ts"
export * from "./open-tasks.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,VentureProgress: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/VentureProgress.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})
