import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.array.slice.js";
export default defineComponent({
  setup: function setup(_, _ref) {
    var root = _ref.root;
    var search = ref('');
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var glossary = computed(function () {
      return $store.state.assessment.glossary;
    });
    var headers = computed(function () {
      return [{
        text: 'Term',
        align: 'start',
        value: 'Title',
        sortable: false
      }, {
        text: 'Explanation',
        value: 'Explanation',
        sortable: false
      }];
    });
    var hyperlinkNames = computed(function () {
      return glossary.value.map(function (entry) {
        return (entry['Source names'].match(/\[(.*?)\]/g) || []).map(function (name) {
          return name.slice(1, -1);
        });
      });
    });
    var hyperlinkURLs = computed(function () {
      return glossary.value.map(function (entry) {
        return entry['Source URLs'].match(/[^,]+/g) || [];
      });
    });
    return {
      search: search,
      headers: headers,
      glossary: glossary,
      hyperlinkNames: hyperlinkNames,
      hyperlinkURLs: hyperlinkURLs
    };
  }
});