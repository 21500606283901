import { filter } from 'lodash'
import { keysMetricInput, keysMetricValueInput } from '~/types/keys'
import { type MetricType, type SectionType } from '~/types/types'

export function parseValueAsInput(item: any, keysList: readonly string[]) {
  const input: any = {}
  const keys = new Set(keysList)

  for (const [key, value] of Object.entries(item)) {
    if (keys.has(key)) input[key] = value
  }
  return input
}

export const parseMetricForBackend = (item: MetricType) =>
  parseValueAsInput(item, keysMetricInput)

export const parseMetricValueAsInput = (item: any) =>
  parseValueAsInput(item, keysMetricValueInput)

// TODO deprecated
export function extractTaxonomiesAsString(
  sections: Array<SectionType> | undefined,
) {
  const taxonomies = [...new Set(sections?.map((e) => e?.taxonomy?.label))]
  return taxonomies.sort().join(', ')
}

export function extractMetricIds(items): number[] {
  return (
    items?.map((item) => parseInt(item.id, 10))?.filter((id) => !isNaN(id)) ||
    []
  )
}

export function getAssessment(assessments: any[], recordId: String) {
  return assessments.find((item: any) => item.id === recordId)?.assessment
}

export function makeSearchMetricFiltersFromSections(sections) {
  const filters = sections?.map((section) => ({
    active: true,
    leaf: true,
    description: section.description,
    label: section.label,
    id: section.id,
    sections: makeSearchMetricFiltersFromSections(section.sections),
  }))
  return filters
}

export function parseSectionsForGetMetricsQuery(selectedFilters: any[]) {
  return selectedFilters.map((filter) => {
    return {
      id: filter.id,
      label: filter.label,
    }
  })
}

export function filterMetrics(metrics, selectedSectionIds) {
  return filter(metrics, (metric) => {
    return metric.sections.some((section) =>
      selectedSectionIds.includes(section.id),
    )
  })
}

export function parseValueInput(value: Maybe<String | Number>): Maybe<Number> {
  if (value === null || value === undefined) {
    return null
  }
  if (value instanceof Number) {
    return value
  }
  let cleanValue: Maybe<number> = parseFloat(value as string)
  if (Number.isNaN(cleanValue)) {
    cleanValue = null
  }
  return cleanValue
}

export function byMetricNameComparator(
  a: MetricAssignmentType,
  b: MetricAssignmentType,
) {
  const nameA = a.metric && a.metric.name ? a.metric.name : ''
  const nameB = b.metric && b.metric.name ? b.metric.name : ''
  return nameA.localeCompare(nameB)
}

export function disableAggregationType(item) {
  if (item.label === 'Predefined') {
    return true
  } else {
    return false
  }
}
