export function downloadExcel(base64String, fileName) {
  // Convert the base64-encoded string to a Blob
  const binaryData = atob(base64String)
  const arrayBuffer = new ArrayBuffer(binaryData.length)
  const view = new Uint8Array(arrayBuffer)

  for (let i = 0; i < binaryData.length; i++) {
    view[i] = binaryData.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob)

  // Create an anchor element with the download attribute
  const link = document.createElement('a')
  link.href = url
  link.download = fileName

  // Append the anchor element to the DOM, trigger a click event, and remove it
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  // Release the object URL
  URL.revokeObjectURL(url)
}

export function downloadWord(base64String, fileName) {
  // Convert the base64-encoded string to a Blob
  const binaryData = atob(base64String)
  const arrayBuffer = new ArrayBuffer(binaryData.length)
  const view = new Uint8Array(arrayBuffer)

  for (let i = 0; i < binaryData.length; i++) {
    view[i] = binaryData.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  })

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob)

  // Create an anchor element with the download attribute
  const link = document.createElement('a')
  link.href = url
  link.download = fileName

  // Append the anchor element to the DOM, trigger a click event, and remove it
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  // Release the object URL
  URL.revokeObjectURL(url)
}

export function downloadCSV(base64String, fileName) {
  const binaryString = window.atob(base64String)
  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([bytes], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = fileName

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  window.URL.revokeObjectURL(url)
}
