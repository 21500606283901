var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.title',
    )))]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
        _vm.renderMarkdown(
          _vm.$t(
            `impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.introParagraph`,
          ),
        )
      )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showIxMethodology = !_vm.showIxMethodology}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showIxMethodology ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.ixMethodologyTitle',
          ))+"\n      ")],1)]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.showIxMethodology)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
        _vm.renderMarkdown(
          _vm.$t(
            `impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.ixMethodology`,
          ),
        )
      )}}):_vm._e(),_vm._v(" "),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.outcome.title',
          ),"subtitle":_vm.renderMarkdown(
            _vm.$t(
              'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.outcome.subtitle',
            ),
          ),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.outcomeConceptLabel,"disabled":_vm.impact.hasFrozenOutcome || !_vm.impactGoal.isEditable,"outlined":"","auto-grow":"","rows":"2","required":"","label":"","persistent-placeholder":"","rules":[
          (v) => !!v || _vm.$t('general.validationErrors.requiredError'),
          (v) =>
            (v && v.length <= 100) ||
            _vm.$t('general.validationErrors.lengthError.prefix') +
              ' 100 ' +
              _vm.$t('general.validationErrors.lengthError.suffix'),
        ]},on:{"change":(val) => _vm.handleConceptInput(val, _vm.conceptId)}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.stakeholder.title',
          ),"subtitle":_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.stakeholder.subtitle',
          ),"is-mandatory":""}}),_vm._v(" "),_c('v-select',{attrs:{"value":_vm.impact.stakeholderCategory,"disabled":_vm.impact.hasFrozenOutcome || !_vm.impactGoal.isEditable,"items":_vm.stakeholderItems,"outlined":"","auto-grow":"","rows":"2","required":"","persistent-placeholder":""},on:{"change":(val) => _vm.triggerUpdateImpact('stakeholderCategory', val)}}),_vm._v(" "),_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.affectedRegions.title',
          ),"subtitle":_vm.$t(
            'impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.affectedRegions.subtitle',
          ),"is-mandatory":""}}),_vm._v(" "),_c('treeselect',{attrs:{"value":_vm.geographyIds,"multiple":true,"options":_vm.geographies,"disabled":_vm.impact.hasFrozenOutcome || !_vm.impactGoal.isEditable,"required":"","disable-fuzzy-matching":""},on:{"input":(val) => _vm.triggerUpdateImpact('geographies', val)}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('impact-goal-edit-impact-source-assignments-input',{attrs:{"impact-goal":_vm.impactGoal,"impact":_vm.impact}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }