import { translate } from './translate'
import { formatAsGermanDate } from '~/functions/helpers'
import { ImplementationStatusUnion } from '~/types/non_generated_types'
import {
  type AggregatedQuestionnaireResultType,
  ImplementationStatus,
  type QuestionnaireAssignmentType,
  type QuestionnaireResultType,
  type UserInputType,
} from '~/types/types'

const MODERATE_THRESHOLD = 34
const GOOD_THRESHOLD = 67

export function mapQuestionnaireResultsBySectionLabel(
  sections: QuestionnaireResultType[] | AggregatedQuestionnaireResultType[],
) {
  if (!sections || !sections.length) {
    console.error('mapQuestionnaireResultsBySectionLabel: found no section')
    return {}
  }
  return Object.assign(
    {},
    ...sections.map((section) => ({
      [section.sectionLabel]: section,
    })),
  )
}

export function incrementImplementationStatus(currentStatus) {
  switch (currentStatus) {
    case ImplementationStatus.NotPlanned:
      return ImplementationStatus.In_12Months
    case ImplementationStatus.In_12Months:
      return ImplementationStatus.Doing
    case ImplementationStatus.Doing:
      return ImplementationStatus.NotPlanned
    default:
      console.error('error', `Unknown ImplementationStatus: ${currentStatus}`)
      return ImplementationStatus.NotPlanned
  }
}

export function getImplementationStatus(
  conceptId: Number | undefined | null,
  userInput: UserInputType,
) {
  const impStatus = userInput.selectedConcepts.find(
    (link) => link.concept?.id === conceptId,
  )?.implementationStatus
  if (!conceptId || !impStatus) return ImplementationStatus.NotPlanned
  return impStatus
}

export function getColor(implementationStatus: ImplementationStatusUnion) {
  if (implementationStatus === ImplementationStatus.Doing) return 'primary'
  if (implementationStatus === ImplementationStatus.In_12Months)
    return 'primary lighten-2'
  if (implementationStatus === ImplementationStatus.NotPlanned) return 'default'
}

export function getIcon(implementationStatus: ImplementationStatusUnion) {
  if (implementationStatus === ImplementationStatus.NotPlanned)
    return 'mdi-lightbulb-on-outline'
  else if (implementationStatus === ImplementationStatus.In_12Months)
    return 'mdi-calendar-clock'
  else if (implementationStatus === ImplementationStatus.Doing)
    return 'mdi-checkbox-marked-outline'
  else return ''
}

export function getTooltip(
  implementationStatus: ImplementationStatusUnion,
): string {
  if (implementationStatus === ImplementationStatus.NotPlanned)
    return 'general.notPlanned'
  else if (implementationStatus === ImplementationStatus.In_12Months)
    return 'general.plannedIn12Months'
  else if (implementationStatus === ImplementationStatus.Doing)
    return 'general.doingIt'
  else return 'general.notPlanned'
}

export function questionnaireVersionTextLong(
  questionnaire: QuestionnaireAssignmentType,
  versionNumber: Number,
) {
  let longText = `Version ${versionNumber}`
  if (questionnaire.period) {
    longText += ' - Reference period '
  } else if (questionnaire.updatedAt) {
    longText += ' - '
  }
  const shortText = questionnaireVersionTextShort(questionnaire)
  return longText + shortText
}

export function questionnaireVersionTextShort(
  questionnaire: QuestionnaireAssignmentType,
) {
  let shortText = ''
  if (questionnaire.period) {
    if (questionnaire.period.label) shortText = questionnaire.period.label
    else if (questionnaire.period.startDate && questionnaire.period.endDate) {
      shortText = `${formatAsGermanDate(
        questionnaire.period.startDate,
      )} - ${formatAsGermanDate(questionnaire.period.endDate)}`
    }
  } else if (questionnaire.updatedAt) {
    shortText = `Last updated ${formatAsGermanDate(questionnaire.updatedAt)}`
  }
  return shortText
}

export function totalPercentage(
  questionnaireResults: Array<
    QuestionnaireResultType | AggregatedQuestionnaireResultType
  >,
) {
  let actualScore = 0
  let maxScore = 0
  questionnaireResults
    .filter((result) => result?.details?.skipped !== true)
    .forEach((result) => {
      actualScore += result?.score || 0
      maxScore += result?.details?.maxScore || 0
    })
  if (!maxScore) return null
  return Math.round((100 * actualScore) / maxScore)
}

export function totalPoints(
  sectionResults: Array<
    QuestionnaireResultType | AggregatedQuestionnaireResultType
  >,
) {
  return (
    sectionResults
      .map((e) => e.score)
      .filter((e) => typeof e === 'number') as number[]
  ).reduce((a, b) => a + b, 0)
}

export function maxTotalPoints(
  sectionResults: Array<
    QuestionnaireResultType | AggregatedQuestionnaireResultType
  >,
) {
  return sectionResults
    .map((e) => e.details?.maxScore)
    .reduce((a, b) => a + b, 0)
}

export function totalSkipped(
  sectionResults: Array<
    QuestionnaireResultType | AggregatedQuestionnaireResultType
  >,
) {
  return sectionResults.every((e) => e.details?.skipped)
}

export const score = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) => result?.score || 0

const maxScore = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) => result?.details?.maxScore || 0

export const skipped = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) => result?.details?.skipped || false

export const scoreByMax = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) => Math.round(score(result)) + '/' + Math.round(maxScore(result))

export const scoreText = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) =>
  skipped(result) ? translate('esg.surveyPages.skipped') : scoreByMax(result)

export const percentageText = (
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) =>
  skipped(result)
    ? translate('esg.surveyPages.skipped')
    : percentage(result) + '%'

export function percentage(
  result: QuestionnaireResultType | AggregatedQuestionnaireResultType,
) {
  const max = maxScore(result)
  if (skipped(result) || !max) return 0
  return Math.round((100 * score(result)) / max)
}

export function color(percentage: null | number, skipped: boolean) {
  if (skipped || percentage === null) return 'blue-grey'
  if (percentage < MODERATE_THRESHOLD) return 'danger'
  else if (percentage < GOOD_THRESHOLD) return 'info'
  else return 'success'
}

export function category(percentage: null | number, skipped: boolean) {
  if (skipped) return 'esg.surveyPages.skipped'
  if (percentage === null) return 'N/A'
  if (percentage < MODERATE_THRESHOLD) return 'esg.surveyPages.critical'
  else if (percentage < GOOD_THRESHOLD) return 'esg.surveyPages.moderate'
  else return 'esg.surveyPages.good'
}

function signSymbol(num: number | undefined) {
  const plusSign = '\u002B'
  const minusSign = '\u2212'

  if (num) {
    const sign = Math.sign(num)
    if (sign > 0) return plusSign
    if (sign < 0) return minusSign
  }
  return ''
}

export function getDifferenceInfo(toCompare, reference) {
  const percentageToCompare = percentage(toCompare)
  const percentageReference = percentage(reference)
  if (
    skipped(toCompare) ||
    skipped(reference) ||
    percentageToCompare === null ||
    percentageReference === null
  )
    return {
      hasValue: true,
      difference: 0,
      differenceText: translate('esg.surveyPages.notAvailable'),
    }
  const diff = percentageToCompare - percentageReference
  return {
    hasValue: false,
    difference: diff,
    differenceText: signSymbol(diff) + Math.abs(diff) + '%',
  }
}
