import IxDialog from '~/components/ix/Dialog.vue';
import IxButtonAction from '~/components/ix/button/Action.vue';
export default defineComponent({
  components: {
    IxDialog: IxDialog,
    IxButtonAction: IxButtonAction
  },
  props: {
    nextStep: {
      type: Object
    },
    previousStep: {
      type: Object
    },
    isOutro: {
      type: Boolean
    },
    isCompleted: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  setup: function setup() {},
  methods: {
    handleNextStep: function handleNextStep(nextStep) {
      this.$emit('change', nextStep);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
});