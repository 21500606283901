export function getPeriods(expanded?: boolean, periods?: number[]): number[] {
  if (expanded) {
    return [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
  } else if (periods?.length) {
    return periods
  } else {
    return [2020, 2021, 2022, 2023, 2024]
  }
}

export const kpiModuleInfoVentureQuickTour =
  'companyPerspective.kpis.tour.kpiModuleInfoVentureQuickTour'

export const kpiModuleInfoVenturePaiIndicators =
  'companyPerspective.kpis.tour.paiIndicators'

export const kpiModuleInfoPortfolio = `This module aims to give you an overview of all KPIs, which your portfolio companies must report. In this module, you can edit the existing KPIs, assign new KPIs to your companies, and delete them. You can also monitor the progress of the portfolio companies in reporting their sustainability performance. Once you have assigned any KPIs to your portfolio companies in the Fund Setup Module, these KPIs will get reflected here. If a KPI is deleted later on, the KPI remains in the KPI module of the company.

You can choose between 2 views: **Portfolio-assigned** and **Company-assigned**. You can switch the views by moving the slider to the left/right on the top of the page.

## Portfolio-assigned

The view allows you to see the aggregated, portfolio-level KPIs calculated for you based on the information provided by the portfolio companies. By clicking on the arrow next to the name of a KPI, you can check which companies provide data for each KPI. Or click on “**Toggle detailed KPIs**” to roll out company names below the KPIs.

By default, the data is aggregated on a portfolio level by summing up individual impacts of the portfolio companies. Alternatively, you can switch the “Portfolio aggregation type” to “Average” to see the weighted average impact of your portfolio.

The view also shows you the official framework for each KPI. Therefore, once you have assigned a task to the portfolio companies in the Fund Setup Module, you will see the KPIs prescribed to each company in this table. As the companies progress in reporting, the aggregated KPIs will be getting calculated. If you click on the data values for a year you are able to see all comments that have been given by the company to explain the target or actual values. In the end, an aggregated portfolio value is automatically calculated for each KPI. The data input of all companies is required for this. As long as data input from individual companies is missing, no aggregation takes place, i.e. no portfolio value is displayed for the respective KPI.

The table also includes the KPI unit and its target and actual values, which you can edit in the “Company-assigned” view. If you want to edit the KPI, assign more companies to it, or delete it, click on “Settings” in the column “Actions”. Finally, you can leave comments on the portfolio aggregation or a company KPI in the last column of the table. The view also provides for grouping and filtering functions and *adding new KPIs*. To get guidance on these, please, hover over the corresponding buttons: “+ Custom KPIs” and “+ Predefined KPIs”.

- **+ Predefined KPIs:** In this section, you can choose KPIs from different Indicator Sets. Namely, IRIS Indicators referring to the Impact Module and PAI Indicators. Tick the relevant settings on the left-hand side of the page. For each set, you can roll out the subsets of KPIs by clicking on the arrow next to the set's name.
  For PAI Indicators you can see the aggregated (portfolio-level) metrics under the tab “PAI Indicators” and the company-level metrics under the tab “PAI company data”.
- **+ Custom KPIs:** In case you would like to assign a specific custom KPI to your portfolio companies, click on this button and provide a name, a unit, a description, and an aggregation level for the KPI. Additionally, you can leave some comments and set a goal for the custom KPI. As a last step, tick the companies which should provide the data for this KPI.

## Company-assigned

In this view, you can see the KPIs that companies defined for themselves in addition to those assigned by the portfolio. You can group the entries by KPI/company/impacts/groups of KPIs. For instance, by grouping the entries by company, you will see all KPIs assigned to a specific company. By clicking on the “Settings” sign, you can edit the target values for the KPIs or make them immutable by clicking on the button “Freeze target values”. The last column of the tables provides you with some space for your comments.
`
