import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    metricValue: {
      type: Object,
      default: function _default() {
        return {
          actualAvg: 0,
          targetAvg: 0,
          actualSum: 0,
          targetSum: 0
        };
      }
    },
    metricName: {
      type: String,
      default: ''
    },
    units: {
      type: String,
      default: ''
    },
    goalType: {
      type: String,
      default: 'none'
    },
    calculationType: {
      type: String,
      default: 'SUM'
    },
    metricAssignmentsSize: {
      type: Number,
      default: 0
    },
    metricAssignmentsWithValueSize: {
      type: Number,
      default: 0
    }
  },
  setup: function setup() {},
  computed: {
    target: function target() {
      if (this.calculationType === 'SUM') {
        var _this$metricValue;
        return ((_this$metricValue = this.metricValue) === null || _this$metricValue === void 0 ? void 0 : _this$metricValue.targetSum) || 0;
      } else if (this.calculationType === 'AVG') {
        var _this$metricValue2;
        return ((_this$metricValue2 = this.metricValue) === null || _this$metricValue2 === void 0 ? void 0 : _this$metricValue2.targetAvg) || 0;
      }
      return 0;
    },
    actual: function actual() {
      if (this.calculationType === 'SUM') {
        var _this$metricValue3;
        return ((_this$metricValue3 = this.metricValue) === null || _this$metricValue3 === void 0 ? void 0 : _this$metricValue3.actualSum) || 0;
      } else if (this.calculationType === 'AVG') {
        var _this$metricValue4;
        return ((_this$metricValue4 = this.metricValue) === null || _this$metricValue4 === void 0 ? void 0 : _this$metricValue4.actualAvg) || 0;
      }
      return 0;
    },
    percentageDiff: function percentageDiff() {
      var actual = this.actual;
      var target = this.target;
      if (target === 0) return undefined;
      var percentage = (actual - target) / target * 100;
      return Math.abs(percentage).toFixed(0);
    },
    getCalculationMethod: function getCalculationMethod() {
      if (this.calculationType === 'SUM') {
        return {
          name: 'Sum',
          svgName: 'calculation-method_sum'
        };
      } else if (this.calculationType === 'AVG') {
        return {
          name: 'Average',
          svgName: 'calculation-method_average'
        };
      } else {
        return {
          name: 'No aggregation type',
          svgName: 'calculation-method_average'
        };
      }
    },
    targetText: function targetText() {
      var target = this.target;
      var actual = this.actual;
      if (actual < target) {
        return 'below target';
      } else if (actual > target) {
        return 'above target';
      } else if (actual === target) {
        return 'at target';
      } else {
        return '';
      }
    },
    getColorForText: function getColorForText() {
      var target = this.target;
      var actual = this.actual;
      if (this.goalType === 'reduce') {
        if (actual > target) {
          return 'danger--text';
        } else {
          return 'success--text';
        }
      } else if (this.goalType === 'increase') {
        if (actual < target) {
          return 'danger--text';
        } else {
          return 'success--text';
        }
      } else if (this.goalType === 'approximate') {
        if (actual === target) {
          return 'success--text';
        } else {
          return 'danger--text';
        }
      } else {
        return 'text-[#282876]';
      }
    },
    getGoalIcons: function getGoalIcons() {
      if (this.goalType === 'reduce') {
        return 'goal-target_reduce';
      } else if (this.goalType === 'increase') {
        return 'goal-target_increase';
      } else if (this.goalType === 'approximate') {
        return 'goal-target_approximate';
      } else {
        return 'goal-target_none';
      }
    }
  }
});