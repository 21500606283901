import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { sdgs } from '~/assets/sdgs';
import { formattedTooltipData, getTooltipData } from '~/assets/tooltipData';
import { useGetAccountQuery } from '~/types/types';
var apexTooltipRef = document.getElementsByClassName('apexcharts-tooltip');
export default defineComponent({
  props: {
    sdgAggregations: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var tooltipIndex = {
      seriesIndex: 0,
      dataPointIndex: 0
    };
    var series = computed(function () {
      var _accountResult$value, _accountResult$value$, _accountResult$value$2, _accountResult$value$3;
      var venturesMap = ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : (_accountResult$value$ = _accountResult$value.getAccount) === null || _accountResult$value$ === void 0 ? void 0 : (_accountResult$value$2 = _accountResult$value$.ventureLinks) === null || _accountResult$value$2 === void 0 ? void 0 : (_accountResult$value$3 = _accountResult$value$2.filter(function (v) {
        return typeof v.ventureId === 'string' && v.ventureId !== null && v.ventureId !== undefined;
      })) === null || _accountResult$value$3 === void 0 ? void 0 : _accountResult$value$3.reduce(function (map, v) {
        return map.set(v.ventureId, v);
      }, new Map())) || new Map();
      return [{
        name: 'Number of impacts contributing to the SDG',
        data: props.sdgAggregations.map(function (e) {
          return _objectSpread({
            x: e.sdgNumber,
            y: e.impacts.length,
            assessmentsData: formattedTooltipData(venturesMap, e)
          }, e);
        })
      }];
    });
    var categories = computed(function () {
      return series.value[0].data.map(function (_e, index) {
        return "SDG ".concat(index + 1);
      }) || [];
    });
    var chartOptions = computed(function () {
      return {
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: {
            show: false
          },
          events: {
            mouseLeave: function mouseLeave() {
              // apexTooltipRef[0].style.setProperty(
              //   '--apex-tooltip-display',
              //   'none',
              // )
              // apexTooltipRef[0].style.setProperty('--apex-tooltip-opacity', '0')
              // apexTooltipRef[0].style.setProperty('--apex-tooltip-height', '0')
            },
            dataPointMouseEnter: function dataPointMouseEnter(_event, _chartContext, config) {
              tooltipIndex.dataPointIndex = config.dataPointIndex;
              tooltipIndex.seriesIndex = config.seriesIndex;
              apexTooltipRef[0].style.setProperty('--apex-tooltip-display', 'none');
            },
            dataPointSelection: function dataPointSelection(event, _chartContext, config) {
              apexTooltipRef[0].style.setProperty('--apex-tooltip-display', 'block');
              apexTooltipRef[0].style.setProperty('--apex-tooltip-left', "".concat(event.x));
              config.w.config.tooltip.shared = false;
              config.w.config.tooltip.custom = function () {
                var data = config.w.globals.initialSeries[tooltipIndex.seriesIndex].data[tooltipIndex.dataPointIndex];
                var path = require("~/assets/img/sdgs/".concat(data.sdgNumber, ".png"));
                return '<div style="background-color: white;border-radius: 12px;padding: 12px !important;width:100%">' + '<div style="align-items: center; display: flex;"+>' + "<img src=\"".concat(path, "\" alt=\"").concat(path, "\" width=\"35\" height=\"35\" style=\"border-radius: 5px\"/>") + '<span class="font-weight-bold subtitle-1 ml-1">' + 'SDG ' + data.sdgNumber + ' - ' + sdgs[data.sdgNumber].short + '</span>' + '</div>' + '<div class="mt-2">' + '<span class="mr-1 font-weight-medium subtitle-2">' + data.y + '</span><span class="mr-1">' + 'impacts contributing to SDG' + '</span>' + '</div>' + "\n                  ".concat(data.assessmentsData && data.assessmentsData.length ? '<table width="100%" class="table" style="">' + '<thead style="text-align:left">' + '<tr>' + '<th></th>' + '<th style="padding-left:20px !important">Company</th>' + '<th style="padding-left:20px !important">Outcome</th>' + '<th style="padding-left:20px !important">Stakeholder</th>' + '</tr>' + '</thead>' + '<tbody style="text-align:left !important;">' + getTooltipData(data.assessmentsData) + '</tbody>' + '</table>' : 'No data found', "\n\n                ") + '</span>';
              };
            }
          },
          id: 'vuechart-example'
        },
        dataLabels: {
          enabled: true
        },
        colors: ['#E5233B', '#DDA83A', '#4C9F38', '#C5192D', '#FF3A21', '#26BDE2', '#FCC30B', '#A21942', '#FD6925', '#DD1367', '#FD9D24', '#BF8B2E', '#3F7E44', '#0A97D9', '#56C02B', '#00689D', '#19486A'],
        grid: {
          show: false
        },
        legend: {
          show: false
        },
        xaxis: {
          type: 'category',
          tickAmount: 0,
          tooltip: {
            enabled: false
          },
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            minHeight: 100,
            maxHeight: 180,
            style: {
              fontWeight: 'bold'
            }
          },
          axisTicks: {
            show: true
          },
          categories: categories,
          tickPlacement: 'on'
        },
        yaxis: {
          labels: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            distributed: true
          }
        },
        tooltip: {
          x: {
            show: false
          }
        }
      };
    });
    return {
      series: series,
      categories: categories,
      chartOptions: chartOptions
    };
  },
  computed: {},
  methods: {}
});