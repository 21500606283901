var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$attrs)?_c('div',{staticClass:"pl-4"},[(_vm.$attrs.__typename == 'QuestionSectionLinkType')?_c('ix-form-input-text-master',_vm._b({key:_vm.$attrs.ixId || _vm.$attrs.question?.ixId,staticClass:"pl-0",attrs:{"id":_vm.$attrs.id || _vm.$attrs.question?.id,"use-text-field":false,"title":_vm.$attrs.label || _vm.$attrs.question?.label,"subtitle":_vm.$attrs.sublabel || _vm.$attrs.question?.sublabel,"expanded":_setup.show},on:{"expand":() => {
        _setup.show = !_setup.show
      }}},'ix-form-input-text-master',{ ..._vm.$attrs.question, ..._vm.$attrs },false)):_c('ix-form-input-question',_vm._b({key:_vm.$attrs.ixId || _vm.$attrs.question?.ixId,attrs:{"id":_vm.$attrs.id || _vm.$attrs.question?.id,"title":_vm.$attrs.label || _vm.$attrs.question?.label,"subtitle":_vm.$attrs.sublabel || _vm.$attrs.question?.sublabel,"tooltip":_vm.$attrs.tooltip || _vm.$attrs.question?.tooltip,"expanded":_setup.show},on:{"expand":() => {
        _setup.show = !_setup.show
      }}},'ix-form-input-question',_vm.$attrs,false)),_vm._v(" "),((_vm.$attrs.ixId || _vm.$attrs.question?.ixId) === 'topInvestments')?_c('sfdr-top-investments',{directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}]}):_vm._e(),_vm._v(" "),((_vm.$attrs.ixId || _vm.$attrs.question?.ixId) === 'fossilOrNuclear')?_c('sfdr-fossil-or-nuclear',{directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}],attrs:{"percentage-of-investments-aligned-with-eu-taxonomy":_setup.calculatedSfdrReport.percentageOfInvestmentsAlignedWithEuTaxonomy,"has-aligned-environmental-investments":_setup.calculatedSfdrReport.hasAlignedEnvironmentalInvestments,"has-fossil-or-nuclear-activities":_setup.calculatedSfdrReport.hasFossilOrNuclearActivities,"has-fossil-activities":_setup.calculatedSfdrReport.hasFossilActivities,"has-nuclear-activities":_setup.calculatedSfdrReport.hasNuclearActivities,"has-no-fossil-nor-nuclear-activities":_setup.calculatedSfdrReport.hasNoFossilNorNuclearActivities}}):_vm._e(),_vm._v(" "),(
      (_vm.$attrs.ixId || _vm.$attrs.question?.ixId) ===
      'proportionSustainabilityRelatedInvestments'
    )?_c('sfdr-investments-categorization-chart-data',{directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}],attrs:{"investments-categorization-chart-data":_setup.calculatedSfdrReport?.investmentsCategorizationChartData}}):_vm._e(),_vm._v(" "),(
      _vm.$attrs.question?.ixId === 'sustainableObjectiveTree' &&
      _setup.formTreeviews?.hasSustainableInvestmentObjective
    )?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}]},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_setup.formTreeviews.hasSustainableInvestmentObjective,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"treeview-label-markdown text-wrap mb-0 py-2 pl-2",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(item.name))}})],1)])]}}],null,false,1485712422)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_setup.formTreeviews.hasNoSustainableInvestmentObjective,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"treeview-label-markdown text-wrap mb-0 py-2 pl-2",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(item.name))}})],1)])]}}],null,false,1485712422)})],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.$attrs.children),function(child){return _c('ix-section-or-question',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_setup.show),expression:"show"}],key:child.id || child.ixId},'ix-section-or-question',child,false))})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }