import { useMutation } from '@vue/apollo-composable';
import _ from 'lodash';
import UPDATE_TASK from '~/graphql/mutations/task/UpdateTask.gql';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  setup: function setup(props) {
    var editedTask = ref(_.cloneDeep(props.task));
    watch(function () {
      return _.cloneDeep(props.task);
    }, function (newVal, _) {
      editedTask.value = newVal;
    });
    var _useMutation = useMutation(UPDATE_TASK),
      updateTask = _useMutation.mutate;
    return {
      updateTask: updateTask,
      editedTask: editedTask
    };
  },
  data: function data() {
    return {
      isError: false,
      rules: {
        requiredRule: function requiredRule(v) {
          return !!v || 'This field is required';
        }
      }
    };
  },
  computed: {},
  methods: {
    submit: function submit() {
      if (this.$refs.taskForm !== undefined && this.$refs.taskForm.validate()) {
        var input = _.cloneDeep(this.editedTask);
        delete input.__typename;
        if (this.type === 'edit') {
          this.updateTask({
            input: input
          });
        }
        this.$emit('submit');
        this.resetForm();
      }
    },
    resetForm: function resetForm() {
      this.editedTask = _.cloneDeep(this.task);
    }
  }
});