export const editablePaiReportForm = {
  pretext: `**Statement on principal adverse impacts of investment decisions on sustainability factors**
    <br>
    <br>
    All information in this view is required for the PAI reporting. Therefore, it is essential that you carefully review that all the information and data provided is accurate and complete.

<strong style="color: var(--v-primary-base)">Please carefully read the following introduction on how to use the template.</strong>

**Using the Templates:**
Our system generated texts automatically based on data you’ve provided in the Fund Setup and your portfolio companies provided as part of the PAI reporting. These texts can be easily transferred to the input field by clicking on the “Replace” button.

**Reviewing and Editing the Template:**
It’s crucial to carefully review the questions and the automatically generated sentences, and if needed, add any data that is missing to make sure all information has been included.

**Personalizing the Responses:**
The templates serve as guidelines. You have the flexibility to use the provided texts in their entirety, write your own response, or combine the two approaches to complete the questionnaire. Most of the fields can be edited to provide a more accurate or detailed response. However, please note that some fields are locked and cannot be edited. Regardless, a review is always necessary to ensure accuracy and completeness.

Once ready, your “PAI statement” should be published on your website in the SFDR section “Sustainability-related disclosures” as a subsection titled: ‘Statement on principal adverse impacts of investment decisions on sustainability factors’.`,
  fundDetails: {
    fundName: 'What is the name of the fund?',
    fundNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalName: 'What is the name of the entity?',
    legalNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalEntityIdentifier: 'What is the legal entity identifier (LEI code)?',
    legalEntityIdentifierTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
  },
  title: {
    article8: 'Environmental and/or social characteristics',
    article8Tooltip: `This field is populated automatically, using the details you've provided in the Fund Setting Module. Modifications cannot be made directly in this field.`,
    article9: 'Sustainable Investment Objective',
    article9Tooltip: ``,
  },
  questions: {
    sustainableObjectiveTree: {
      title:
        'Did this financial product have a sustainable investment objective?',
      // subtitle:
      //   'tick and fill in as relevant, the percentage figure represents the minimum commitment to sustainable investments',
      tooltip:
        'Sustainable investment means an investment in an economic activity that contributes to an environmental or social objective, provided that the investment is compliant with the “do no significant harm” principle (for which disclosure of the PAI indicators is required), and that the investee companies follow good governance practice',
    },
    sustainabilityIndicatorsPerformanceDescription: {
      title: 'How did the sustainability indicators perform?',
      subtitle: {
        article8:
          'List the environmental and/or social characteristics promoted by the financial product. For the financial products referred to in Article 6, first paragraph, of Regulation (EU) 2020/852, in respect of sustainable investments with environmental objectives, list the environmental objectives set out in Article 9 of that Regulation to which the sustainable investment underlying the financial product contributed. For financial products that made sustainable investments with social objectives, list the social objectives',
        article9:
          'List the sustainable investment objective of this financial product, and describe how the sustainable investments contributed to the sustainable investment objective. For the financial products referred to in Article 5, first paragraph, of Regulation (EU) 2020/852, in respect of sustainable investments with environmental objectives, indicate to which environmental objectives set out in Article 9 of Regulation (EU) 2020/852 to the investment underlying the financial product contributed to. For the financial products referred to in Article 9(3) of Regulation (EU) 2019/2088, indicate how the objective of a reduction in carbon emissions was aligned with the Paris Agreement',
      },
      tooltip: `Sustainability indicators measure how the environmental or social characteristics promoted by the fund are attained.`,
    },
    sustainabilityIndicatorsPerformanceDescriptionComparedToPrevious: {
      title: 'And compared to previous periods?',
      subtitle:
        'include for financial products where at least one previous periodic report was provided',
    },
    accountOnPais: {
      title:
        'How were the indicators for adverse impacts on sustainability factors taken into account?',
      tooltip:
        'Principal adverse impacts are the most significant negative impacts of investment decisions on sustainability factors relating to environmental, social and employee matters, respect for human right, anti-corruption and anti-bribery matters. For a fund to be considered Article 8+ or Article 9, the information on the Principal Adverse Impacts (PAIs) of the underlying investments should be provided.',
    },
    howDidProductConsiderPais: {
      title:
        'How did this financial product consider principal adverse impacts on sustainability factors?',
      subtitle:
        'Include section if the financial product considered principal adverse impacts on sustainability factors',
    },
    paisTable: {
      title:
        'For each indicator enter an explanation and actions planned/targets set for the next reference period.',
      tooltip: `The percentage alongside each indicator value indicates the <strong>coverage</strong>. If one out of 11 companies assigned to this fund have completed that indicator, the coverage would be 9%.
      <br>
      <br>
      <strong>Please note</strong> that where information relating to any of the indicators used is not readily available, you shall provide details of the best efforts used to obtain the information either directly from investee companies, or by carrying out additional research, cooperating with third party data providers or external experts or making reasonable assumptions. This should be done in the section ‘Description of policies to identify and prioritize principal adverse impacts on sustainability factors’, according to <strong>Article 6(2) of COMMISSION DELEGATED REGULATION (EU) 2022/1288.</strong>`,
    },
    considerationOfPaisOnSustainabilityDescription: {
      title:
        'Description of policies to identify and prioritise principal adverse impacts on sustainability factors',
      subtitle: 'Information referred to in Article 7.',
      tooltip: `Where information relating to any PAI indicator used is not readily available, please provide details of the best efforts used to obtain the information either directly from investee companies, or by carrying out additional research, cooperating with third party data providers or external experts or making reasonable assumptions and describe them in this field.
      <br>
      <br>
      Key elements of description based on Article 7:
      <br>
      <ul>
  <li>Policy approval date by the governing body.</li>
  <li>Allocation of responsibility for policy implementation within organizational strategies and procedures.</li>
  <li>Methodologies for selecting indicators, and identifying and assessing principal adverse impacts, taking into account their probability, severity, and potentially irremediable nature.</li>
  <li>Any associated margin of error within the methodologies and an explanation for it.</li>
  <li>Data sources used.</li>
</ul>`,
    },
    considerationOfPaisOnSustainabilityEngagementPolicies: {
      title: 'Engagement policies',
      subtitle: 'Information referred to in Article 8',
    },
    considerationOfPaisOnSustainabilityInternationalStandards: {
      title: 'References to international standards',
      subtitle: 'Information referred to in Article 9',
    },
    considerationOfPaisOnSustainabilityHistoricalComparison: {
      title: 'Historical comparison',
      subtitle: 'Information referred to in Article 10',
    },
    summaryOfPais: {
      title: 'Summary of PAI report',
      tooltip: `Please make sure that this field contains the following information:
      1. the name of the entity to which the adverse sustainability impacts statement relates;
      2. the fact that principal adverse impacts on sustainability factors are considered by the entity;
      3. the reference period of the statement;
      4. a summary of the principal adverse impacts.`,
    },
  },
}
