import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import { isDisclosure } from '~/functions/helpers';
import { useGetAggregatedEsgQuestionnaireResultQuery, useGetMostRecentQuestionnaireForVentureAndLabelQuery } from '~/types/types';
export default defineComponent({
  props: {
    esgAssessments: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var _props$esgAssessments;
    var esgVentureIds = computed(function () {
      return props.esgAssessments.map(function (e) {
        return e.ventureId;
      });
    });
    var firstVenture = ((_props$esgAssessments = props.esgAssessments) === null || _props$esgAssessments === void 0 ? void 0 : _props$esgAssessments[0]) || {
      ventureId: ''
    }; // FIXME I still dont understand why we are use the id of the first venture

    var _useGetMostRecentQues = useGetMostRecentQuestionnaireForVentureAndLabelQuery({
        ventureId: firstVenture.ventureId || '',
        label: 'ESG'
      }, {
        enabled: !!firstVenture.ventureId
      }),
      questionnaireResult = _useGetMostRecentQues.result;
    var esgQuestionnaireTemplate = computed(function () {
      var _questionnaireResult$, _questionnaireResult$2;
      return questionnaireResult === null || questionnaireResult === void 0 ? void 0 : (_questionnaireResult$ = questionnaireResult.value) === null || _questionnaireResult$ === void 0 ? void 0 : (_questionnaireResult$2 = _questionnaireResult$.getMostRecentQuestionnaireForVentureAndLabel) === null || _questionnaireResult$2 === void 0 ? void 0 : _questionnaireResult$2.questionnaireTemplate;
    });
    var disclosureSections = computed(function () {
      var _esgQuestionnaireTemp, _esgQuestionnaireTemp2, _esgQuestionnaireTemp3;
      return ((_esgQuestionnaireTemp = esgQuestionnaireTemplate.value) === null || _esgQuestionnaireTemp === void 0 ? void 0 : (_esgQuestionnaireTemp2 = _esgQuestionnaireTemp.sections) === null || _esgQuestionnaireTemp2 === void 0 ? void 0 : (_esgQuestionnaireTemp3 = _esgQuestionnaireTemp2[3]) === null || _esgQuestionnaireTemp3 === void 0 ? void 0 : _esgQuestionnaireTemp3.sections) || [];
    });
    var _useGetAggregatedEsgQ = useGetAggregatedEsgQuestionnaireResultQuery({}),
      result = _useGetAggregatedEsgQ.result;

    // FIXME move the logic to the backend
    var questionnaireResultMapping = computed(function () {
      var _result$value;
      return ((result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAggregatedEsgQuestionnaireResult) || []).reduce(function (x, current) {
        return _objectSpread(_objectSpread({}, x), {}, _defineProperty({}, current.sectionLabel, current));
      }, {});
    });
    var esgSections = computed(function () {
      var _esgQuestionnaireTemp4, _esgQuestionnaireTemp5, _esgQuestionnaireTemp6;
      return esgQuestionnaireTemplate === null || esgQuestionnaireTemplate === void 0 ? void 0 : (_esgQuestionnaireTemp4 = esgQuestionnaireTemplate.value) === null || _esgQuestionnaireTemp4 === void 0 ? void 0 : (_esgQuestionnaireTemp5 = _esgQuestionnaireTemp4.sections) === null || _esgQuestionnaireTemp5 === void 0 ? void 0 : (_esgQuestionnaireTemp6 = _esgQuestionnaireTemp5.filter(function (_ref) {
        var id = _ref.id;
        return id && !isDisclosure(id);
      })) === null || _esgQuestionnaireTemp6 === void 0 ? void 0 : _esgQuestionnaireTemp6.map(function (section) {
        var _questionnaireResultM;
        return _objectSpread(_objectSpread({}, section), {}, {
          result: (_questionnaireResultM = questionnaireResultMapping.value) === null || _questionnaireResultM === void 0 ? void 0 : _questionnaireResultM[section.label],
          sections: section.sections.map(function (section) {
            var _questionnaireResultM2;
            return _objectSpread(_objectSpread({}, section), {}, {
              result: (_questionnaireResultM2 = questionnaireResultMapping.value) === null || _questionnaireResultM2 === void 0 ? void 0 : _questionnaireResultM2[section.label]
            });
          })
        });
      });
    });
    return {
      questionnaireResultMapping: questionnaireResultMapping,
      disclosureSections: disclosureSections,
      esgSections: esgSections
    };
  },
  data: function data() {
    return {
      selectedYear: [],
      yearList: [{
        id: 1,
        label: '2021'
      }, {
        id: 2,
        label: '2020'
      }]
    };
  }
});