export default defineComponent({
  props: {
    parentRelevancy: {
      type: Object,
      default: null
    },
    relevancy: {
      type: Object,
      default: null
    }
  },
  computed: {
    negativeMask: function negativeMask() {
      if (!this.parentRelevancy) {
        return 0;
      }
      if (this.parentRelevancy.negative_relevancy) {
        return 100 - this.roundRelevancy(this.parentRelevancy.negative_relevancy.occurance_ratio);
      } else {
        return 100;
      }
    },
    positiveMask: function positiveMask() {
      if (!this.parentRelevancy) {
        return 0;
      }
      if (this.parentRelevancy.positive_relevancy) {
        return 100 - this.roundRelevancy(this.parentRelevancy.positive_relevancy.occurance_ratio);
      } else {
        return 100;
      }
    },
    negativeOccuranceRatio: function negativeOccuranceRatio() {
      if (this.relevancy.negative_relevancy) {
        return this.roundRelevancy(this.relevancy.negative_relevancy.occurance_ratio);
      } else {
        return 0;
      }
    },
    positiveOccuranceRatio: function positiveOccuranceRatio() {
      if (this.relevancy.positive_relevancy) {
        return this.roundRelevancy(this.relevancy.positive_relevancy.occurance_ratio);
      } else {
        return 0;
      }
    }
  },
  methods: {
    roundRelevancy: function roundRelevancy(value) {
      return parseInt((value * 100).toFixed(1));
    }
  }
});