import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.function.name.js";
import { useMutation } from '@vue/apollo-composable';
import { computed, defineComponent, ref, watch } from 'vue';
import CustomComparisonModal from '~/components/impact-goal/edit/CustomComparisonModal.vue';
import IdematComparisonModal from '~/components/impact-goal/edit/IdematComparisonModal.vue';
import UPSERT_EMISSION_SOURCE from '~/graphql/mutations/impact-goal/UpsertEmissionSource.gql';
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  name: 'YourSolutionEmissionSourceForm',
  components: {
    CustomComparisonModal: CustomComparisonModal,
    IdematComparisonModal: IdematComparisonModal
  },
  props: {
    toEditEmissionSource: {
      type: Object,
      required: true
    },
    impactSolution: {
      type: Object,
      required: true
    },
    resetSolutionDialogForm: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup: function setup(props, context) {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useMutation = useMutation(UPSERT_EMISSION_SOURCE),
      upsertEmissionSourceMutation = _useMutation.mutate;
    var emissionSource = ref(_objectSpread({}, props.toEditEmissionSource));

    // To reset the form to the original state
    var resetSolutionForm = function resetSolutionForm() {
      emissionSource.value = _objectSpread({}, props.toEditEmissionSource);
      nextTick(function () {
        // Any code here will execute after the emissionSource.value updates have been
        // reacted to by Vue's reactivity system and the DOM has been updated accordingly.
        // This is used because, if checkbox changes trigger other changes in emissionSource,
        // those changes will have taken effect by the time the code here runs.
        emissionSource.value.solutionConceptName = props.toEditEmissionSource.solutionConceptName;
        emissionSource.value.solutionEvidence = props.toEditEmissionSource.solutionEvidence;
        emissionSource.value.solutionGhgFactor = props.toEditEmissionSource.solutionGhgFactor;
        emissionSource.value.solutionConsumption = props.toEditEmissionSource.solutionConsumption;
        emissionSource.value.solutionConsumption = props.toEditEmissionSource.solutionConsumption;
      });
    };

    // const bothCheckbox = ref(false)
    var bothCheckbox = ref(emissionSource.value.solutionReducesConsumption && emissionSource.value.solutionReducesGhgFactor);
    var amountDisabled = ref(true);
    if (emissionSource.value.solutionReducesConsumption === true) {
      amountDisabled.value = false;
    }

    /// Watch for single boxes (not Both)
    watch(function () {
      return [emissionSource.value.solutionReducesConsumption, emissionSource.value.solutionReducesGhgFactor];
    }, function (newValues) {
      var allSelected = newValues.every(function (value) {
        return value === true;
      });
      if (allSelected) {
        bothCheckbox.value = true;
        emissionSource.value.solutionConceptName = '';
        emissionSource.value.solutionEvidence = '';
        emissionSource.value.solutionConsumption = null;
        emissionSource.value.solutionGhgFactor = null;
        amountDisabled.value = false;
      } else {
        bothCheckbox.value = false;
        // newValues[0]:Change in Consumption
        // newValues[1]:Alternative GHG Emission Factor
        if (newValues[0] === true && newValues[1] === false) {
          emissionSource.value.solutionConceptName = emissionSource.value.baselineConceptName;
          emissionSource.value.solutionEvidence = emissionSource.value.baselineEvidence;
          emissionSource.value.solutionGhgFactor = emissionSource.value.baselineGhgFactor;
          amountDisabled.value = false;
        } else if (newValues[0] === false && newValues[1] === false) {
          emissionSource.value.solutionConceptName = '';
          emissionSource.value.solutionEvidence = '';
          emissionSource.value.solutionConsumption = null;
          emissionSource.value.solutionGhgFactor = null;
          amountDisabled.value = true;
        } else if (newValues[0] === false && newValues[1] === true) {
          emissionSource.value.solutionConceptName = '';
          emissionSource.value.solutionEvidence = '';
          emissionSource.value.solutionConsumption = emissionSource.value.baselineConsumption;
          emissionSource.value.solutionGhgFactor = null;
          amountDisabled.value = true;
        }
      }
    });

    /// Watch for Both checkbox
    watch(bothCheckbox, function (newValue) {
      if (newValue === true) {
        emissionSource.value.solutionReducesConsumption = true;
        emissionSource.value.solutionReducesGhgFactor = true;
      } else if (newValue === false) {
        if (emissionSource.value.solutionReducesConsumption === true && emissionSource.value.solutionReducesGhgFactor === true) {
          emissionSource.value.solutionReducesConsumption = false;
          emissionSource.value.solutionReducesGhgFactor = false;
        }
      }
    });

    // Convert prop to a reactive ref
    var resetSolutionDialogFormRef = toRef(props, 'resetSolutionDialogForm');

    // Watch the reactive reference of resetSolutionDialogForm
    watch(resetSolutionDialogFormRef, function () {
      resetSolutionForm();
    });
    var handleSelectedSolutionFactor = function handleSelectedSolutionFactor(item) {
      emissionSource.value.solutionGhgFactor = item.factor;
      emissionSource.value.unitName = item.unit;
      emissionSource.value.solutionEvidence = item.evidence;
      emissionSource.value.solutionConceptName = item.name;
    };
    var handleCustomSelectedSolutionFactor = function handleCustomSelectedSolutionFactor(item, dialog) {
      emissionSource.value.solutionGhgFactor = item.factor;
      emissionSource.value.unitName = item.unit;
      emissionSource.value.solutionEvidence = item.evidence;
      emissionSource.value.solutionConceptName = item.name;
      dialog.value = false;
    };
    var upsertEmissionSource = function upsertEmissionSource(fields) {
      upsertEmissionSourceMutation({
        input: _objectSpread({
          ghgSavingPotentialId: props.impactSolution.ghgSavingPotentialId,
          ventureId: ventureId.value
        }, fields)
      });
    };
    var getFactorText = function getFactorText(emissionSource) {
      if (emissionSource.solutionGhgFactor) {
        return emissionSource.solutionGhgFactor + ' kg CO2e / ' + emissionSource.unitName;
      } else {
        return '';
      }
    };
    var cancel = function cancel() {
      resetSolutionForm();
      context.emit('close');
    };
    var saveAndClose = function saveAndClose() {
      var upsertDocument = {
        id: emissionSource.value.id,
        unitName: emissionSource.value.unitName,
        customBaselineConcept: false,
        howSolutionWorks: emissionSource.value.howSolutionWorks,
        solutionConceptName: emissionSource.value.solutionConceptName,
        solutionConsumption: Number(emissionSource.value.solutionConsumption),
        solutionGhgFactor: Number(emissionSource.value.solutionGhgFactor),
        solutionReducesGhgFactor: emissionSource.value.solutionReducesGhgFactor,
        solutionEvidence: emissionSource.value.solutionEvidence,
        solutionReducesConsumption: emissionSource.value.solutionReducesConsumption,
        solutionAssumption: emissionSource.value.solutionAssumption
      };

      // eslint-disable-next-line no-prototype-builtins
      if (emissionSource.value.hasOwnProperty('customBaselineConcept')) {
        upsertDocument.customBaselineConcept = emissionSource.value.customBaselineConcept || false;
      }
      upsertEmissionSource(_objectSpread({}, upsertDocument));
      context.emit('close');
    };
    var impactProfile = computed(function () {
      var _props$impactSolution, _props$impactSolution2;
      return (_props$impactSolution = props.impactSolution) === null || _props$impactSolution === void 0 ? void 0 : (_props$impactSolution2 = _props$impactSolution.ghgSavingPotential) === null || _props$impactSolution2 === void 0 ? void 0 : _props$impactSolution2.impactProfileKind;
    });
    var disableSaveButton = computed(function () {
      var consumption = emissionSource.value.solutionConsumption;
      return !(consumption || consumption === 0);
    });
    var rules = {
      required: function required(value) {
        return !!value || 'Field is required';
      }
    };
    return {
      upsertEmissionSourceMutation: upsertEmissionSourceMutation,
      renderMarkdown: renderMarkdown,
      handleSelectedSolutionFactor: handleSelectedSolutionFactor,
      handleCustomSelectedSolutionFactor: handleCustomSelectedSolutionFactor,
      saveAndClose: saveAndClose,
      getFactorText: getFactorText,
      cancel: cancel,
      ventureId: ventureId,
      emissionSource: emissionSource,
      bothCheckbox: bothCheckbox,
      impactProfile: impactProfile,
      amountDisabled: amountDisabled,
      rules: rules,
      disableSaveButton: disableSaveButton
    };
  }
});