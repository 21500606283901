import { moduleByKeys } from '~/assets/modules';
import { renderMarkdown } from '~/functions/markdown';
import { useGetAccountQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var ventureId = route === null || route === void 0 ? void 0 : route.params.venture;
    var _useGetAccountQuery = useGetAccountQuery({
        id: ventureId
      }),
      accountResult = _useGetAccountQuery.result;
    var venture = computed(function () {
      var _accountResult$value;
      return ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) || {
        modules: [],
        portfolioLinks: []
      };
    });
    var bookedModules = computed(function () {
      var _venture$value;
      return ((_venture$value = venture.value) === null || _venture$value === void 0 ? void 0 : _venture$value.bookedModules) || [];
    });
    return {
      venture: venture,
      moduleByKeys: moduleByKeys,
      bookedModules: bookedModules
    };
  },
  computed: {},
  methods: {
    renderMarkdown: renderMarkdown
  }
});