import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es7.object.values.js";
import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import { getNestedProperty } from '~/functions/sfdr';
import { useGetFundQuery, useGetDisclosureSummaryQuery, useGetSectionWithQuestionsQuery, ReportType } from '~/types/types';
export default defineComponent({
  props: {
    currentReferencePeriod: {
      type: Number,
      required: true
    },
    reportType: {
      type: String,
      required: true,
      validator: function validator(value) {
        return Object.values(ReportType).includes(value);
      },
      default: ReportType.SfdrFundWebsiteDisclosure
    },
    sectionId: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var _useGetFundQuery = useGetFundQuery({
        id: toNumber(route.params.fund)
      }),
      result = _useGetFundQuery.result;
    var fund = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getFund;
    });
    var _useGetDisclosureSumm = useGetDisclosureSummaryQuery({
        fundId: toNumber(route.params.fund),
        referencePeriod: props === null || props === void 0 ? void 0 : props.currentReferencePeriod,
        reportType: props.reportType
      }),
      disclosureSummaryResult = _useGetDisclosureSumm.result,
      summaryLoading = _useGetDisclosureSumm.loading;
    var disclosureSummary = computed(function () {
      var _disclosureSummaryRes, _disclosureSummaryRes2;
      return (_disclosureSummaryRes = disclosureSummaryResult.value) === null || _disclosureSummaryRes === void 0 ? void 0 : (_disclosureSummaryRes2 = _disclosureSummaryRes.getDisclosureSummary) === null || _disclosureSummaryRes2 === void 0 ? void 0 : _disclosureSummaryRes2.summary;
    });
    var _useGetSectionWithQue = useGetSectionWithQuestionsQuery(computed(function () {
        var input = {
          id: props.sectionId,
          fundId: toNumber(route.params.fund),
          referencePeriod: toNumber(route.query.referencePeriod)
        };
        return input;
      }), computed(function () {
        var _route$query;
        return {
          enabled: !!((_route$query = route.query) !== null && _route$query !== void 0 && _route$query.referencePeriod)
        };
      })),
      sectionResult = _useGetSectionWithQue.result,
      getSectionLoading = _useGetSectionWithQue.loading;
    var section = computed(function () {
      var _sectionResult$value;
      return ((_sectionResult$value = sectionResult.value) === null || _sectionResult$value === void 0 ? void 0 : _sectionResult$value.getSection) || [{
        ixId: '',
        templateTexts: [{
          text: ''
        }]
      }];
    });
    return {
      section: section,
      fund: fund,
      // items,

      renderMarkdown: renderMarkdown,
      getNestedProperty: getNestedProperty,
      getSectionLoading: getSectionLoading,
      // listQuestionsLoading,
      summaryLoading: summaryLoading,
      disclosureSummary: disclosureSummary
    };
  }
});