import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.number.constructor.js";
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { editablePaiReportForm } from '~/assets/fund/paiReport';
import { renderMarkdown } from '~/functions/markdown';
import { getNestedProperty } from '~/functions/sfdr';
import { ReportType, TypeAggregationGroup, useGetAccountQuery, useGetFundQuery, useGetPaiReportQuery, useUpdateAccountMutation, useUpdateFundMutation, useUpdateReportAnswerMutation } from '~/types/types';
export default defineComponent({
  props: {
    currentReferencePeriod: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var _useGetFundQuery = useGetFundQuery({
        id: toNumber(route.params.fund)
      }),
      result = _useGetFundQuery.result;
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var fund = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getFund;
    });
    var account = computed(function () {
      var _accountResult$value;
      return (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount;
    });
    var paiMetricIds = computed(function () {
      var _fund$value$socialMet, _fund$value, _fund$value$environme, _fund$value2;
      var socialMetrics = (_fund$value$socialMet = (_fund$value = fund.value) === null || _fund$value === void 0 ? void 0 : _fund$value.socialMetricIds) !== null && _fund$value$socialMet !== void 0 ? _fund$value$socialMet : [];
      var environmentalMetrics = (_fund$value$environme = (_fund$value2 = fund.value) === null || _fund$value2 === void 0 ? void 0 : _fund$value2.environmentalMetricIds) !== null && _fund$value$environme !== void 0 ? _fund$value$environme : [];
      return [].concat(_toConsumableArray(socialMetrics), _toConsumableArray(environmentalMetrics));
    });
    var _useUpdateReportAnswe = useUpdateReportAnswerMutation(),
      updateReportAnswer = _useUpdateReportAnswe.mutate;
    var _useUpdateFundMutatio = useUpdateFundMutation(),
      updateFund = _useUpdateFundMutatio.mutate;
    var _useUpdateAccountMuta = useUpdateAccountMutation(),
      updateAccount = _useUpdateAccountMuta.mutate;
    var currentUserInputObject = computed(function () {
      var _fund$value3;
      return (_fund$value3 = fund.value) === null || _fund$value3 === void 0 ? void 0 : _fund$value3.paiAnswers[props.currentReferencePeriod];
    });
    var previousUserInputObject = computed(function () {
      var _fund$value$paiAnswer, _fund$value4, _fund$value4$paiAnswe;
      return (_fund$value$paiAnswer = (_fund$value4 = fund.value) === null || _fund$value4 === void 0 ? void 0 : (_fund$value4$paiAnswe = _fund$value4.paiAnswers) === null || _fund$value4$paiAnswe === void 0 ? void 0 : _fund$value4$paiAnswe[props.currentReferencePeriod - 1]) !== null && _fund$value$paiAnswer !== void 0 ? _fund$value$paiAnswer : {};
    });
    var triggerUpdateReportAnswer = function triggerUpdateReportAnswer(key, val) {
      var _fund$value5;
      var input = {
        fundId: (_fund$value5 = fund.value) === null || _fund$value5 === void 0 ? void 0 : _fund$value5.id,
        referencePeriod: Number(props.currentReferencePeriod),
        reportType: ReportType.SfdrPai,
        reportData: _defineProperty({}, key, val === undefined || val === '' ? null : val)
      };
      updateReportAnswer({
        input: input
      });
    };
    var updatePaiInfoItem = function updatePaiInfoItem(key, val, metricId) {
      var update = {};
      update[metricId] = _defineProperty({}, key, val === undefined || val === '' ? null : val);
      triggerUpdateReportAnswer('principalAdverseImpactsExplanationsAndActions', update);
    };
    var triggerUpdateFund = function triggerUpdateFund(key, val) {
      var _fund$value6;
      var input = {
        id: (_fund$value6 = fund.value) === null || _fund$value6 === void 0 ? void 0 : _fund$value6.id
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateFund({
        input: input
      });
    };
    var triggerUpdateAccount = function triggerUpdateAccount(key, val) {
      var _fund$value7, _fund$value7$portfoli;
      var input = {
        id: (_fund$value7 = fund.value) === null || _fund$value7 === void 0 ? void 0 : (_fund$value7$portfoli = _fund$value7.portfolio) === null || _fund$value7$portfoli === void 0 ? void 0 : _fund$value7$portfoli.id
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateAccount({
        input: input
      });
    };
    var _useGetPaiReportQuery = useGetPaiReportQuery(computed(function () {
        return {
          input: {
            id: toNumber(route.params.fund),
            referencePeriod: toNumber(route.query.referencePeriod)
          }
        };
      }), computed(function () {
        return {
          enabled: Boolean(toNumber(route.params.fund)) && Boolean(toNumber(route.query.referencePeriod))
        };
      })),
      calculatedPaiReportResult = _useGetPaiReportQuery.result;
    var calculatedPaiReport = computed(function () {
      var _calculatedPaiReportR;
      return ((_calculatedPaiReportR = calculatedPaiReportResult.value) === null || _calculatedPaiReportR === void 0 ? void 0 : _calculatedPaiReportR.getPaiReport) || null;
    });
    var loading = useGlobalQueryLoading();
    return {
      calculatedPaiReport: calculatedPaiReport,
      previousUserInputObject: previousUserInputObject,
      fund: fund,
      paiMetricIds: paiMetricIds,
      account: account,
      currentUserInputObject: currentUserInputObject,
      TypeAggregationGroup: TypeAggregationGroup,
      triggerUpdateReportAnswer: triggerUpdateReportAnswer,
      updatePaiInfoItem: updatePaiInfoItem,
      triggerUpdateFund: triggerUpdateFund,
      triggerUpdateAccount: triggerUpdateAccount,
      renderMarkdown: renderMarkdown,
      getNestedProperty: getNestedProperty,
      form: editablePaiReportForm,
      loading: loading
    };
  }
});