import { render, staticRenderFns } from "./assessment.vue?vue&type=template&id=08bc598e&scoped=true"
import script from "./assessment.vue?vue&type=script&lang=ts"
export * from "./assessment.vue?vue&type=script&lang=ts"
import style0 from "./assessment.vue?vue&type=style&index=0&id=08bc598e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08bc598e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,ImpactPill: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactPill.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VSlideGroup,VSlideItem,VSpacer})
