import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useGetAccountQuery, Module } from '~/types/types';
import { useVentureId } from '~/functions/accounts';
export default defineComponent({
  setup: function setup() {
    var _ref = useNuxtApp(),
      $i18n = _ref.$i18n;
    var ventureId = useVentureId();
    var _useGetAccountQuery = useGetAccountQuery(computed(function () {
        return {
          id: ventureId.value
        };
      })),
      result = _useGetAccountQuery.result,
      loading = _useGetAccountQuery.loading;
    var venture = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount) || {
        modules: [],
        portfolioLinks: []
      };
    });

    // TODO please move this and merge with modules once we have a clearer ideas of module vs tabs
    var headers = computed(function () {
      var _venture$value, _venture$value$booked, _venture$value2, _venture$value2$booke, _venture$value4, _venture$value4$booke, _venture$value5, _venture$value5$booke, _venture$value6, _venture$value6$booke, _venture$value7, _venture$value7$booke, _venture$value8, _venture$value8$booke, _venture$value9, _venture$value9$booke, _venture$value10, _venture$value10$book, _venture$value11, _venture$value11$book;
      return [{
        label: $i18n.t('general.headers.modules'),
        link: 'venture-modules',
        showForModules: (_venture$value = venture.value) === null || _venture$value === void 0 ? void 0 : (_venture$value$booked = _venture$value.bookedModules) === null || _venture$value$booked === void 0 ? void 0 : _venture$value$booked.some(function (module) {
          return [Module.Esg, Module.Metrics, Module.ImpactGoal].includes(module);
        })
      }, {
        label: $i18n.t('general.headers.companyInfo'),
        link: 'venture-company',
        showForModules: (_venture$value2 = venture.value) === null || _venture$value2 === void 0 ? void 0 : (_venture$value2$booke = _venture$value2.bookedModules) === null || _venture$value2$booke === void 0 ? void 0 : _venture$value2$booke.some(function (module) {
          var _venture$value3, _venture$value3$portf;
          return [Module.Esg].includes(module) && ((_venture$value3 = venture.value) === null || _venture$value3 === void 0 ? void 0 : (_venture$value3$portf = _venture$value3.portfolioLinks) === null || _venture$value3$portf === void 0 ? void 0 : _venture$value3$portf.some(function (link) {
            return link.flagCompanyInfo;
          }));
        })
      }, {
        label: $i18n.t('general.headers.esg'),
        link: 'venture-esg',
        showForModules: (_venture$value4 = venture.value) === null || _venture$value4 === void 0 ? void 0 : (_venture$value4$booke = _venture$value4.bookedModules) === null || _venture$value4$booke === void 0 ? void 0 : _venture$value4$booke.includes(Module.Esg)
      }, {
        label: $i18n.t('general.headers.impactGoal'),
        link: 'venture-impact',
        showForModules: (_venture$value5 = venture.value) === null || _venture$value5 === void 0 ? void 0 : (_venture$value5$booke = _venture$value5.bookedModules) === null || _venture$value5$booke === void 0 ? void 0 : _venture$value5$booke.includes(Module.ImpactGoal)
      }, {
        label: $i18n.t('general.headers.kpis'),
        link: 'venture-metrics',
        showForModules: (_venture$value6 = venture.value) === null || _venture$value6 === void 0 ? void 0 : (_venture$value6$booke = _venture$value6.bookedModules) === null || _venture$value6$booke === void 0 ? void 0 : _venture$value6$booke.includes(Module.Metrics)
      }, {
        label: $i18n.t('general.headers.impact'),
        link: 'venture-overview',
        showForModules: (_venture$value7 = venture.value) === null || _venture$value7 === void 0 ? void 0 : (_venture$value7$booke = _venture$value7.bookedModules) === null || _venture$value7$booke === void 0 ? void 0 : _venture$value7$booke.includes(Module.Impact)
      }, {
        label: $i18n.t('general.headers.openTasks'),
        link: 'venture-open-tasks',
        showForModules: (_venture$value8 = venture.value) === null || _venture$value8 === void 0 ? void 0 : (_venture$value8$booke = _venture$value8.bookedModules) === null || _venture$value8$booke === void 0 ? void 0 : _venture$value8$booke.some(function (module) {
          return [Module.Impact].includes(module);
        })
      }, {
        label: $i18n.t('general.headers.impactScreening'),
        link: 'venture-impactScreening',
        showForModules: (_venture$value9 = venture.value) === null || _venture$value9 === void 0 ? void 0 : (_venture$value9$booke = _venture$value9.bookedModules) === null || _venture$value9$booke === void 0 ? void 0 : _venture$value9$booke.includes(Module.ImpactScreening)
      }, {
        label: $i18n.t('general.headers.impactAssessment'),
        link: 'venture-impact-assessment',
        showForModules: (_venture$value10 = venture.value) === null || _venture$value10 === void 0 ? void 0 : (_venture$value10$book = _venture$value10.bookedModules) === null || _venture$value10$book === void 0 ? void 0 : _venture$value10$book.some(function (module) {
          return [Module.Impact].includes(module);
        })
      }, {
        label: $i18n.t('general.headers.tasks'),
        link: 'venture-task',
        showForModules: (_venture$value11 = venture.value) === null || _venture$value11 === void 0 ? void 0 : (_venture$value11$book = _venture$value11.bookedModules) === null || _venture$value11$book === void 0 ? void 0 : _venture$value11$book.includes(Module.Task)
      }].filter(function (header) {
        return header.showForModules;
      });
    });
    var activeTab = ref('venture-modules');
    return {
      loading: loading,
      headers: headers,
      activeTab: activeTab,
      venture: venture
    };
  },
  computed: {
    currentTitle: function currentTitle() {
      if (this.$route.path.includes('modules')) {
        return 'Available modules';
      }
      return null;
    }
  }
});