var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-dialog',{attrs:{"max-width":675},on:{"input":(val) => {
      _vm.handleResetReferencePeriodInput(val)
      _vm.$emit('input', val)
    }},scopedSlots:_vm._u([{key:"default",fn:function({ attrs, on }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v("\n      Request new ESG report\n    ")])]}},{key:"title",fn:function(){return [_vm._v("Request new ESG report")]},proxy:true},{key:"text",fn:function(){return [_c('div',[_c('h5',{staticClass:"primary--text mb-6"},[_vm._v("\n        "+_vm._s(_vm.selected.map((item) => item.venture.name).join(', '))+"\n      ")]),_vm._v(" "),_c('section',{staticClass:"mb-4"},[_c('h3',[_vm._v("\n          Would you like to assign a reference period to the ESG questionnaire\n          (recommended)?\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          You can select a standard reference period from the dropdown or\n          define a custom one.\n        ")]),_vm._v(" "),_c('ix-form-input-standard-reference-period-select',{attrs:{"disabled":_vm.referencePeriod.isCustom},on:{"input":function($event){_vm.referencePeriod.isCustom = false
            _vm.referencePeriod.custom = []}},model:{value:(_vm.referencePeriod.standard),callback:function ($$v) {_vm.$set(_vm.referencePeriod, "standard", $$v)},expression:"referencePeriod.standard"}}),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-1",staticStyle:{"margin-top":"-0.5rem"},attrs:{"label":"Use a custom reference period"},on:{"change":function($event){_vm.referencePeriod.standard = null
                _vm.referencePeriod.custom = []}},model:{value:(_vm.referencePeriod.isCustom),callback:function ($$v) {_vm.$set(_vm.referencePeriod, "isCustom", $$v)},expression:"referencePeriod.isCustom"}})],1),_vm._v(" "),_c('v-col')],1),_vm._v(" "),(_vm.referencePeriod.isCustom)?_c('ix-form-input-date-range-picker',{attrs:{"label":"Custom reference period"},model:{value:(_vm.referencePeriod.custom),callback:function ($$v) {_vm.$set(_vm.referencePeriod, "custom", $$v)},expression:"referencePeriod.custom"}}):_vm._e()],1),_vm._v(" "),_c('h3',[_vm._v("Would you like to set a due date?")]),_vm._v(" "),_c('p',[_vm._v("\n        Your portfolio companies will get a task to complete the ESG\n        assessment and an email notification.\n      ")]),_vm._v(" "),_c('ix-form-input-date-picker',{attrs:{"date":_vm.referencePeriod.dueDate,"label":"Due Date"},on:{"handleDateChange":(value) => (_vm.referencePeriod.dueDate = value)}})],1)]},proxy:true},{key:"actions",fn:function({ toggleDialog }){return [_c('v-btn',{attrs:{"depressed":"","ripple":false},on:{"click":() => toggleDialog()}},[_vm._v("Cancel")]),_vm._v(" "),_c('ix-button-action',{on:{"click":function($event){_vm.handleSubmitReferencePeriodRequest(_vm.selected)
        toggleDialog()}}},[_vm._v("Send request")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }