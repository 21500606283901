import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.function.name.js";
import { GChart } from 'vue-google-charts';
export default defineComponent({
  components: {
    GChart: GChart
  },
  props: {
    impacts: Array,
    assessments: Array,
    stage: String
  },
  data: function data() {
    return {
      sdgTab: 'positive',
      filteredColors: [],
      sdgColors: ['#E5233B', '#DDA83A', '#4C9F38', '#C5192D', '#FF3A21', '#26BDE2', '#FCC30B', '#A21942', '#FD6925', '#DD1367', '#FD9D24', '#BF8B2E', '#3F7E44', '#0A97D9', '#56C02B', '#00689D', '#19486A'],
      sdgNumbers: [{
        number: '1',
        name: 'SDG 1 - No poverty'
      }, {
        number: '2',
        name: 'SDG 2 - Zero hunger'
      }, {
        number: '3',
        name: 'SDG 3 - Good health & well-being'
      }, {
        number: '4',
        name: 'SDG 4 - Quality education'
      }, {
        number: '5',
        name: 'SDG 5 - Gender equality'
      }, {
        number: '6',
        name: 'SDG 6 - Clean water & sanitation'
      }, {
        number: '7',
        name: 'SDG 7 - Affordable & clean energy'
      }, {
        number: '8',
        name: 'SDG 8 - Decent work & economic growth'
      }, {
        number: '9',
        name: 'SDG 9 - Industry, Innovation & Infrastructure'
      }, {
        number: '10',
        name: 'SDG 10 - Reduced inequalities'
      }, {
        number: '11',
        name: 'SDG 11 - Sustainable cities & communities'
      }, {
        number: '12',
        name: 'SDG 12 - Responsible consumption & production'
      }, {
        number: '13',
        name: 'SDG 13 - Climate action'
      }, {
        number: '14',
        name: 'SDG 14 - Life below water'
      }, {
        number: '15',
        name: 'SDG 15 - Life on land'
      }, {
        number: '16',
        name: 'SDG 16 - Peace, Justice & Strong Institutions'
      }, {
        number: '17',
        name: 'SDG 17 - Partnerships for the Goals'
      }]
    };
  },
  computed: {
    sdgCounts: function sdgCounts() {
      var sdgs = this.sdgNumbers.map(function (sdg) {
        return _objectSpread(_objectSpread({}, sdg), {}, {
          positivePrimary: 0,
          positiveSecondary: 0,
          negativePrimary: 0,
          negativeSecondary: 0,
          positiveImpacts: [],
          negativeImpacts: []
        });
      });
      this.impacts.forEach(function (impact) {
        var _impact$primarySDGs, _impact$secondarySDGs;
        var incrementCounts = function incrementCounts(sdg, isPositive, isPrimary) {
          var index = parseInt(sdg.match(/\d+/)[0], 10) - 1;
          var countType = isPositive ? 'positive' : 'negative' + (isPrimary ? 'Primary' : 'Secondary');
          sdgs[index][countType]++;
          sdgs[index][isPositive ? 'positiveImpacts' : 'negativeImpacts'].push(impact.id);
        };
        (_impact$primarySDGs = impact.primarySDGs) === null || _impact$primarySDGs === void 0 ? void 0 : _impact$primarySDGs.forEach(function (sdg) {
          if (!/Not relevant according to SDGs/.test(sdg)) {
            incrementCounts(sdg, impact.isPositive, true);
          }
        });
        (_impact$secondarySDGs = impact.secondarySDGs) === null || _impact$secondarySDGs === void 0 ? void 0 : _impact$secondarySDGs.forEach(function (sdg) {
          if (!/Not relevant according to SDGs/.test(sdg)) {
            incrementCounts(sdg, impact.isPositive, false);
          }
        });
      });
      return sdgs;
    },
    maxSDG: function maxSDG() {
      return Math.max.apply(Math, _toConsumableArray(this.sdgCounts.map(function (sdg) {
        return sdg.positivePrimary + sdg.positiveSecondary + sdg.negativePrimary + sdg.negativeSecondary;
      })));
    },
    dataSeries: function dataSeries() {
      var _this = this;
      var sdgArray = this.sdgCounts.map(function (sdg) {
        return {
          x: '',
          y: _this.sdgTab === 'positive' ? sdg.positivePrimary + sdg.positiveSecondary : sdg.negativePrimary + sdg.negativeSecondary,
          primary: _this.sdgTab === 'positive' ? sdg.positivePrimary : sdg.negativePrimary,
          secondary: _this.sdgTab === 'positive' ? sdg.positiveSecondary : sdg.negativeSecondary,
          sdgNumber: sdg.number,
          sdgName: sdg.name
        };
      });
      this.filteredColors = this.sdgNumbers.map(function (sdg) {
        return _this.sdgColors[parseInt(sdg.number) - 1];
      });
      return [{
        name: 'SDG',
        data: sdgArray
      }];
    },
    xAxisCategories: function xAxisCategories() {
      return this.sdgCounts.map(function (sdg) {
        return "SDG ".concat(sdg.number);
      });
    },
    options: function options() {
      var _this2 = this;
      return {
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: {
            show: false
          },
          id: 'vuechart-example',
          events: {
            dataPointSelection: function dataPointSelection(event, chartContext, config) {
              var selectedSDG = config.w.config.series[config.seriesIndex].data[config.dataPointIndex];
              _this2.$emit('selected-sdg', {
                name: selectedSDG.sdgName,
                number: selectedSDG.sdgNumber,
                positive: _this2.sdgTab === 'positive'
              });
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        colors: this.filteredColors,
        grid: {
          show: false
        },
        legend: {
          show: false
        },
        xaxis: {
          tickAmount: 1,
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          categories: this.xAxisCategories
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
            distributed: true
          }
        },
        tooltip: {
          custom: function custom(_ref) {
            var seriesIndex = _ref.seriesIndex,
              dataPointIndex = _ref.dataPointIndex,
              w = _ref.w;
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return "<div class=\"tooltip-custom\">\n                      <img src=\"/path/to/sdg/".concat(data.sdgNumber, ".png\" alt=\"SDG ").concat(data.sdgNumber, "\" style=\"width: 35px; height: 35px;\">\n                      <b>").concat(data.sdgName, "</b>: ").concat(data.y, " (Direct: ").concat(data.primary, ", Indirect: ").concat(data.secondary, ")\n                    </div>");
          }
        }
      };
    }
  }
});