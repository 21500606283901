import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3e51e78e"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxButtonOutlined: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Outlined.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VCol,VDataIterator,VList,VListItem,VListItemContent,VRow})
