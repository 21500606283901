var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"760"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",staticStyle:{"border-radius":"12px"},attrs:{"icon":"","small":"","tile":"","elevation":"0","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)],1)],1)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticStyle:{"border-bottom":"0px !important"}},[_c('div',[_vm._v(_vm._s(_vm.$t('title')))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","small":"","color":"#DEEEF7"},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',{staticClass:"font-weight-bold",attrs:{"small":"","color":"primary"}},[_vm._v("\n            mdi-close\n          ")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pb-0"},[_c('ix-data-table-evidence',{attrs:{"single-select":"","height":`500px`,"headers":[
            'websiteUrl',
            'comment',
            'countEvidenceLinks',
            'actions',
          ]},model:{value:(_setup.selectedEvidence),callback:function ($$v) {_setup.selectedEvidence=$$v},expression:"selectedEvidence"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('cancel'))+"\n        ")]),_vm._v(" "),_c('ix-button-action',{staticClass:"mr-4",on:{"click":() => {
              dialog.value = false
              _vm.$emit('done', _setup.selectedEvidence?.[0])
            }}},[_vm._v("\n          "+_vm._s(_vm.$t('saveEvidenceButton'))+"\n        ")])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }