import "core-js/modules/es6.function.name.js";
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import { useListIdematsLazyQuery } from '~/types/types';
import { renderMarkdown } from '~/functions/markdown';
export default {
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var tableHeaders = [{
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.name'),
      value: 'name'
    }, {
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.category'),
      value: 'categoryFull'
    }, {
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.ghgFactor'),
      value: 'ghgFactor'
    }, {
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.unit'),
      value: 'unit'
    }, {
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.notes'),
      value: 'notes'
    }, {
      text: $i18n.t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.tableHeaders.source'),
      value: 'source'
    }];
    var itemsPerPage = 10;
    var selected = ref([{}]);
    var input = ref({
      keyword: '',
      categories: [],
      units: []
    });
    var _useListIdematsLazyQu = useListIdematsLazyQuery({
        input: input
      }),
      idematSearchResult = _useListIdematsLazyQu.result,
      idematSearchLoad = _useListIdematsLazyQu.load;
    var loading = useGlobalQueryLoading();
    var fetchData = function fetchData(val) {
      if (val.keyword === null) {
        val.keyword = '';
      }
      input.value = val;
      idematSearchLoad();
    };
    var tableData = computed(function () {
      if (idematSearchResult.value && idematSearchResult.value.listIdemats) {
        var data = idematSearchResult.value.listIdemats;
        if (data.length === 0) {
          return [];
        } else {
          return data;
        }
      } else {
        return [];
      }
    });
    var dialog = ref(false);
    var openDialog = function openDialog() {
      dialog.value = true;
    };
    var closeModal = function closeModal() {
      dialog.value = false;
      emit('closeModal');
    };
    var addIdematGHG = function addIdematGHG() {
      if (selected.value.length > 0 && selected.value[0].id) {
        emit('selectItem', {
          name: selected.value[0].name,
          factor: selected.value[0].ghgFactor,
          unit: selected.value[0].unit,
          evidence: selected.value[0].source + ' (' + selected.value[0].idematId + ')',
          customBaselineConcept: false
        });
        closeModal();
      } else {
        // Nothing selected, do nothing
      }
    };
    return {
      tableHeaders: tableHeaders,
      loading: loading,
      itemsPerPage: itemsPerPage,
      tableData: tableData,
      selected: selected,
      dialog: dialog,
      fetchData: fetchData,
      renderMarkdown: renderMarkdown,
      addIdematGHG: addIdematGHG,
      openDialog: openDialog,
      closeModal: closeModal
    };
  }
};