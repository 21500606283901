var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._g(_vm._b({attrs:{"value":_vm.value,"locale":_vm.$i18n.locale,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.height,"item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"items":_vm.items,"sort-by":"createdAt","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":(v) => {
      _vm.selectedIds = v
      _vm.$emit('input', v)
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")]),_vm._v(" "),(_vm.showDelete)?_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"danger","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t('delete'))+"\n          ")])]}}],null,false,3877610208),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n            Are you sure you want to delete those funds? It will delete ALL\n            the data linked to those funds and it's irreversible!!\n          ")]),_vm._v(" "),_vm._l((_vm.items?.filter((item) =>
              _vm.selectedIds?.includes?.(item?.id),
            )),function(item){return _c('v-list-item',{key:item.key,staticClass:"ml-4"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(item.name)+" - "+_vm._s(item.portfolio.name)+"\n              ")])],1)],1)}),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"type 'CONFIRM' to delete"},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":() => {
                  _vm.confirm = ''
                  _vm.dialog = false
                }}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":"","disabled":_vm.confirm != 'CONFIRM'},on:{"click":() => {
                  _vm.delFunds({ ids: _vm.selectedIds })
                }}},[_vm._v("\n              DELETE\n            ")])],1)],2)],1):_vm._e()],1)]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-data-table-audit-logs',{staticClass:"max-h-[400px] overflow-scroll",attrs:{"columns":['diff', 'operationType', 'createdBy', 'createdAt'],"default-filters":{
            tableName: ['fund'],
            dataId:
              '00000000-0000-0000-0000-' +
              item.id.toString(16).padStart(12, '0'),
          }}})],1)],1)]}},{key:`item.name`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.name,"filter":_vm.search}})]}},{key:`item.actions`,fn:function(){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]},proxy:true}],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }