var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{domProps:{"innerHTML":_vm._s(
      _vm.renderMarkdown(
        _vm.$t(
          'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.searchInfo',
        ),
      )
    )}}),_vm._v(" "),_c('v-row',{staticClass:"mb-4 align-top"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t(
            'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.idematComparisonModal.keywordLabel',
          ),"hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('input', _vm.input)}},model:{value:(_vm.input.keyword),callback:function ($$v) {_vm.$set(_vm.input, "keyword", $$v)},expression:"input.keyword"}})],1),_vm._v(" "),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('input', _vm.input)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[[_c('v-combobox',{attrs:{"items":_vm.categoryOptions,"direction":"vertical","label":"Category","prepend-icon":"mdi-filter-variant","chips":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item, select, selected, close }){return [_c('v-chip',_vm._b({staticClass:"ma-2",attrs:{"color":"primary","closable":"","small":""},on:{"click":select}},'v-chip',attrs,false),[_vm._v("\n              "+_vm._s(item)+"\n            ")])]}}]),model:{value:(_vm.input.categories),callback:function ($$v) {_vm.$set(_vm.input, "categories", $$v)},expression:"input.categories"}})]],2),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[[_c('v-combobox',{attrs:{"items":_vm.unitOptions,"label":"Unit","prepend-icon":"mdi-filter-variant","variant":"solo","chips":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item, select, selected }){return [_c('v-chip',_vm._b({staticClass:"ma-2",attrs:{"model-value":selected,"color":"primary","closable":"","small":""},on:{"click":select}},'v-chip',attrs,false),[_vm._v("\n              "+_vm._s(item)+"\n            ")])]}}]),model:{value:(_vm.input.units),callback:function ($$v) {_vm.$set(_vm.input, "units", $$v)},expression:"input.units"}})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }