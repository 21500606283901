var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","size":"small"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"min-width":"300"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Name"},model:{value:(_setup.name),callback:function ($$v) {_setup.name=$$v},expression:"name"}}),_vm._v(" "),_c('ix-treeview-sections',{attrs:{"query":{ parentIds: [1300] },"add-all":false}}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"variant":"text"},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cancel ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","variant":"text","loading":_setup.loading},on:{"click":() => {
              // addIroSectionLink({
              //   sectionId: 1 // TODO
              //   iroId: $attrs['iro-id'],
              // }).then(() => {
              //   dialog.value = false
              //   $emit('done')
              // })
            }}},[_vm._v("\n          Save\n        ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }