import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b7cd1d40"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText,VCardTitle})
