var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.outcome !== undefined)?_c('v-card',{staticClass:"subbox indicators-card",staticStyle:{"border-top":"1px solid #eceff1"},attrs:{"elevation":"0","tile":""}},[_c('v-row',{staticClass:"no-gutter",staticStyle:{"margin":"0 !important"}},[_c('v-col',{attrs:{"md":"6","xl":"6"}},[_c('v-card-title',{staticClass:"indented"},[_c('v-row',{staticClass:"d-flex align-center flex-grow-1 flex-shrink-1"},[_c('v-col',{attrs:{"sm":"12","md":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"width":"28px","height":"28px"},attrs:{"fab":_vm.outcomeEditingState,"icon":!_vm.outcomeEditingState,"dark":_vm.outcomeEditingState,"elevation":"0","color":!_vm.outcomeEditingState
                      ? 'rgba(0, 0, 0, 0.54)'
                      : _vm.complete
                      ? 'success'
                      : 'danger'},on:{"click":function($event){return _vm.$emit('toggleDimension', _vm.dimension)}}},'v-btn',attrs,false),on),[(_vm.dimension.toggle)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,2092464104)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(!_vm.outcomeEditingState
                  ? 'Show indicators'
                  : _vm.complete
                  ? 'All indicators are confirmed'
                  : 'There are indicators left to confirm'))])]),_vm._v(" "),_c('v-avatar',{staticClass:"mr-2",attrs:{"tile":"","size":"2em"}},[_c('img',{staticClass:"pl-0 ml-0",staticStyle:{"mix-blend-mode":"darken"},attrs:{"src":require(`~/assets/img/imp/${_vm.dimension.name}.png`)}})]),_vm._v(" "),_c('term',{attrs:{"term":_vm.dimension.name,"element":"Assessment"}},[_c('span',{staticClass:"text-overline",staticStyle:{"font-size":"0.875rem !important"}},[_vm._v(_vm._s(_vm.dimension.name))])])],1),_vm._v(" "),_c('v-col',{attrs:{"sm":"12","md":"6","xl":"6"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('score-bar',{staticStyle:{"width":"100%"},attrs:{"score":_vm.score,"is-positive":_vm.outcome.isPositive}})],1)])],1)],1),_vm._v(" "),(_vm.dimension.toggle)?_c('v-card-text',{staticClass:"py-0",attrs:{"id":_vm.dimension.name + 'Table'}},_vm._l((_vm.indicators),function(indicator){return _c('indicator-row',{key:indicator.id,staticClass:"ml-20",staticStyle:{"padding-left":"50px !important","padding-right":"0 !important"},attrs:{"indicator-id":indicator.id,"indicator":indicator,"dimension":_vm.dimension.name,"is-positive":_vm.outcome.isPositive},on:{"change":_vm.handleChangeInIndicator}})}),1):_vm._e()],1),_vm._v(" "),(_vm.dimension.toggle)?_c('v-col',{staticClass:"pl-5 pt-0 pb-0 pr-0 flex-column d-flex",attrs:{"md":"6","xl":"6"}},[_c('vertical-source-list',{staticStyle:{"flex-basis":"0px","flex-grow":"1","overflow-y":"auto"},attrs:{"id":_vm.dimension.name + 'Sources',"outcome":_vm.outcome,"indicators":_vm.indicators,"editable":true,"no-bullets":""}})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }