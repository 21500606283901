import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: {
    Treeselect: Treeselect
  },
  props: {
    businessActivityOptions: {
      type: Array,
      required: true
    },
    recommendedActivitiesOptions: {
      type: Array,
      required: true
    },
    selectedActivityIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    mergeRecommendedActivityIds: function mergeRecommendedActivityIds(selectedRecommendedActivities) {
      var selectedRecommendedActivityIds = selectedRecommendedActivities.map(function (e) {
        return e.id;
      }).filter(Boolean);
      if (selectedRecommendedActivityIds.length > 0) {
        var updatedActivityIds = _toConsumableArray(new Set([].concat(_toConsumableArray(this.selectedActivityIds), _toConsumableArray(selectedRecommendedActivityIds))));
        if (updatedActivityIds.length > this.selectedActivityIds.length) this.$emit('change', updatedActivityIds);
      }
    }
  }
});