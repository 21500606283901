import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useMutation } from '@vue/apollo-composable';
import UPDATE_IMPACT_GOAL from '~/graphql/mutations/impact-goal/UpdateImpactGoal.gql';
export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useMutation = useMutation(UPDATE_IMPACT_GOAL),
      updateImpactGoal = _useMutation.mutate;
    var businessActivities = computed(function () {
      return props.impactGoal.businessActivities;
    });
    var activityIds = ref(businessActivities.value.map(function (businessActivity) {
      return businessActivity.id;
    }).filter(Boolean) || []);
    return {
      updateImpactGoal: updateImpactGoal,
      activityIds: activityIds,
      businessActivities: businessActivities,
      ventureId: ventureId
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    onSelectActivity: function onSelectActivity(value) {
      this.activityIds = value;
      this.updateImpactGoalBusinessActivities();
    },
    mergeRecommendedActivityIds: function mergeRecommendedActivityIds(selectedRecommendedActivities) {
      var _this = this;
      var selectedRecommendedActivityIds = selectedRecommendedActivities.map(function (activity) {
        return Number(activity.id);
      });
      if (selectedRecommendedActivityIds.length > 0) {
        selectedRecommendedActivityIds.forEach(function (activityId) {
          if (!_this.activityIds.includes(activityId)) _this.activityIds.push(activityId);
        });
        this.updateImpactGoalBusinessActivities();
      }
    },
    updateImpactGoalBusinessActivities: function updateImpactGoalBusinessActivities() {
      this.updateImpactGoal({
        input: {
          id: this.$props.impactGoal.id,
          businessActivities: this.activityIds
        }
      });
    }
  }
});