import { render, staticRenderFns } from "./VenturePotentialScore.vue?vue&type=template&id=1ec32f68"
import script from "./VenturePotentialScore.vue?vue&type=script&lang=ts"
export * from "./VenturePotentialScore.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PortfolioScreeningImpactsScorePositiveNegative: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio-screening/impacts/ScorePositiveNegative.vue').default})
