import { render, staticRenderFns } from "./Iro.vue?vue&type=template&id=4b035682"
import script from "./Iro.vue?vue&type=script&setup=true&lang=ts"
export * from "./Iro.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Iro.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSearchText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SearchText.vue').default,IxDataTable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/DataTable.vue').default})
