import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import _ from 'lodash';
import { useListUserLogEventsQuery } from '~/types/types';
export default defineComponent({
  props: {
    email: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return ['name'];
      }
    },
    filter: {
      type: Function,
      default: function _default() {
        return true;
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var headers = [{
      value: 'user_id'
    }, {
      value: 'user_agent'
    }, {
      value: 'date'
    }, {
      value: 'ip'
    }, {
      value: 'location'
    }, {
      value: 'actions'
    }].filter(function (o) {
      var _props$columns;
      return (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.includes(o.value);
    });
    var options = ref({
      page: 1,
      itemsPerPage: 15,
      search: ''
    });
    var variables = computed(function () {
      return {
        email: props.email,
        perPage: options.value.itemsPerPage,
        page: options.value.page - 1
        // search: options.value.search || '',
      };
    });
    var _useListUserLogEvents = useListUserLogEventsQuery(variables),
      result = _useListUserLogEvents.result,
      refetch = _useListUserLogEvents.refetch,
      loading = _useListUserLogEvents.loading;
    var items = computed(function () {
      var _result$value$listUse, _result$value, _result$value$listUse2;
      return (_result$value$listUse = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$listUse2 = _result$value.listUserLogEvents) === null || _result$value$listUse2 === void 0 ? void 0 : _result$value$listUse2.logs) !== null && _result$value$listUse !== void 0 ? _result$value$listUse : [];
    });
    var selected = computed(function () {
      return items === null || items === void 0 ? void 0 : items.value.filter(function (user) {
        return (props.value || []).filter(Boolean).includes(user.id || '');
      });
    });
    var onInput = function onInput(val) {
      if (_.isEqual(val.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      }), props.value)) {
        // if the value is the same, don't fire the event
        return;
      }
      emit('input', val.map(function (_ref3) {
        var id = _ref3.id;
        return id;
      }));
    };
    return {
      loading: loading,
      items: items,
      refetch: refetch,
      selected: selected,
      headers: headers,
      onInput: onInput,
      options: options,
      itemCount: computed(function () {
        var _result$value2, _result$value2$listUs;
        return ((_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : (_result$value2$listUs = _result$value2.listUserLogEvents) === null || _result$value2$listUs === void 0 ? void 0 : _result$value2$listUs.total) || 0;
      })
    };
  }
});