export const handleUndefined = (object: any) => {
  if (typeof object === 'string' && object) return object.replaceAll('\n', '')
  return object || ''
}

export const handleUndefinedArray = (object: any) => {
  return object && object.length ? object : []
}
export const replaceAndSpecialSymbol = (text: string) => {
  if (!text) return ''
  return text.replace(/&/g, 'and')
}

export const horizontalDividerSvg = (
  height,
  width = 1284,
) => `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="0" y1="0" x2="${width}" y2="0" stroke="#E8EEF1"/>
</svg>`
