import "core-js/modules/es6.string.starts-with.js";
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    listItems: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {},
  methods: {
    getClickableLink: function getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : "http://".concat(link);
    }
  }
});