import { useGetStandardReferencePeriodsQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var referencePeriodsQueryReturn = useGetStandardReferencePeriodsQuery();
    var standardReferencePeriods = computed(function () {
      var _referencePeriodsQuer, _referencePeriodsQuer2;
      return (_referencePeriodsQuer = (_referencePeriodsQuer2 = referencePeriodsQueryReturn.result.value) === null || _referencePeriodsQuer2 === void 0 ? void 0 : _referencePeriodsQuer2.getStandardReferencePeriods) !== null && _referencePeriodsQuer !== void 0 ? _referencePeriodsQuer : [];
    });
    return {
      standardReferencePeriods: standardReferencePeriods
    };
  }
});