import { render, staticRenderFns } from "./funds.vue?vue&type=template&id=23bf21f2"
import script from "./funds.vue?vue&type=script&setup=true&lang=ts"
export * from "./funds.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxHeaderPortfolio: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/HeaderPortfolio.vue').default,IxDataTableFunds: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Funds.vue').default})
