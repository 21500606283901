import { render, staticRenderFns } from "./TargetProgress.vue?vue&type=template&id=6077395a&scoped=true"
import script from "./TargetProgress.vue?vue&type=script&lang=ts"
export * from "./TargetProgress.vue?vue&type=script&lang=ts"
import style0 from "./TargetProgress.vue?vue&type=style&index=0&id=6077395a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6077395a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MetricsProgressScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/ProgressScore.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VRow})
