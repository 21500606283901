import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMutation } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import UPSERT_GHG_SAVING_POTENTIAL from '~/graphql/mutations/impact-goal/UpsertGhgSavingPotential.gql';
export default defineComponent({
  props: {
    impactGoal: {
      required: true,
      type: Object
    },
    impact: {
      required: true,
      type: Object
    }
  },
  setup: function setup(props) {
    var _props$impact, _props$impact$impactS, _props$impact$impactS2, _props$impact$impactS3, _props$impact$impactS5, _props$impact3, _props$impact3$impact, _props$impact3$impact2, _props$impact3$impact3;
    var route = useRoute();
    var _useMutation = useMutation(UPSERT_GHG_SAVING_POTENTIAL),
      upsertGhgSavingPotential = _useMutation.mutate;
    var impactSolutionId = computed(function () {
      return toNumber(route.params.solution);
    });
    var ghgSavingPotentialId = ref((_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$impactS = _props$impact.impactSolutions) === null || _props$impact$impactS === void 0 ? void 0 : (_props$impact$impactS2 = _props$impact$impactS[impactSolutionId.value]) === null || _props$impact$impactS2 === void 0 ? void 0 : (_props$impact$impactS3 = _props$impact$impactS2.ghgSavingPotential) === null || _props$impact$impactS3 === void 0 ? void 0 : _props$impact$impactS3.id);
    var fairComparison = computed(function () {
      var _props$impact$impactS4, _props$impact2, _props$impact2$impact, _props$impact2$impact2;
      return (_props$impact$impactS4 = (_props$impact2 = props.impact) === null || _props$impact2 === void 0 ? void 0 : (_props$impact2$impact = _props$impact2.impactSolutions) === null || _props$impact2$impact === void 0 ? void 0 : (_props$impact2$impact2 = _props$impact2$impact[impactSolutionId.value]) === null || _props$impact2$impact2 === void 0 ? void 0 : _props$impact2$impact2.ghgSavingPotential) !== null && _props$impact$impactS4 !== void 0 ? _props$impact$impactS4 : {};
    });
    var comparativeUnitConfirmed = ref((_props$impact$impactS5 = (_props$impact3 = props.impact) === null || _props$impact3 === void 0 ? void 0 : (_props$impact3$impact = _props$impact3.impactSolutions) === null || _props$impact3$impact === void 0 ? void 0 : (_props$impact3$impact2 = _props$impact3$impact[impactSolutionId.value]) === null || _props$impact3$impact2 === void 0 ? void 0 : (_props$impact3$impact3 = _props$impact3$impact2.ghgSavingPotential) === null || _props$impact3$impact3 === void 0 ? void 0 : _props$impact3$impact3.comparativeUnitConfirmed) !== null && _props$impact$impactS5 !== void 0 ? _props$impact$impactS5 : false);
    var impactProfile = computed(function () {
      var _props$impact$impactS6, _props$impact4, _props$impact4$impact, _props$impact4$impact2, _props$impact4$impact3;
      return (_props$impact$impactS6 = (_props$impact4 = props.impact) === null || _props$impact4 === void 0 ? void 0 : (_props$impact4$impact = _props$impact4.impactSolutions) === null || _props$impact4$impact === void 0 ? void 0 : (_props$impact4$impact2 = _props$impact4$impact[impactSolutionId.value]) === null || _props$impact4$impact2 === void 0 ? void 0 : (_props$impact4$impact3 = _props$impact4$impact2.ghgSavingPotential) === null || _props$impact4$impact3 === void 0 ? void 0 : _props$impact4$impact3.impactProfileKind) !== null && _props$impact$impactS6 !== void 0 ? _props$impact$impactS6 : 'CIRCULAR_ECONOMY';
    });
    return {
      upsertGhgSavingPotential: upsertGhgSavingPotential,
      ghgSavingPotentialId: ghgSavingPotentialId,
      fairComparison: fairComparison,
      comparativeUnitConfirmed: comparativeUnitConfirmed,
      impactProfile: impactProfile
    };
  },
  data: function data() {
    return {
      showExample: false,
      showfunctionOfYourImpactSolutionGuidence: false,
      // showComparativeUnitGuidance: false,
      showFunctionalUnitGuidance: false,
      showComparativeScenarioGuidance: false
    };
  },
  methods: {
    renderMarkdown: renderMarkdown,
    upsertFairComparison: function upsertFairComparison(value) {
      this.upsertGhgSavingPotential({
        input: _objectSpread({
          id: this.ghgSavingPotentialId
        }, value)
      });
    }
  }
});