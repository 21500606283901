var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',[_vm._v(_vm._s(_vm.$t('companyPerspective.kpis.yourTarget')))]),_vm._v(" "),(
      _vm.metricAssignment &&
      _vm.metricAssignment.completionStatus &&
      _vm.metricAssignment.completionStatus.hasTarget
    )?_c('div',[_c('v-row',{staticClass:"ml-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Defined by:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":_vm.portfolioName}})],1),_vm._v(" "),_c('div',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Period:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":`${_vm.metricAssignment.completionStatus.targetParameters.startDate} -
                         ${_vm.metricAssignment.completionStatus.targetParameters.endDate}`}})],1),_vm._v(" "),_c('div',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Calculation method:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":_vm.calculationType}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Target progress:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":Math.round(
                  (_vm.metricAssignment.completionStatus.completionRate || 0) *
                    100,
                ) + ''}})],1),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Actual:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":`${_vm.metricAssignment.completionStatus.actual}`}})],1),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Target:")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":`${_vm.metricAssignment.completionStatus.targetParameters.target}`}})],1)]),_vm._v(" "),(_vm.metricAssignment.completionStatus.targetParameters.weight)?_c('div',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Weight")]),_vm._v(" "),_c('ix-chip-element',{attrs:{"content":`${_vm.metricAssignment.completionStatus.targetParameters.weight}%`}})],1):_vm._e()])],1)],1):_c('div',{staticClass:"neutral-grey-dark"},[_vm._v("N/A")])])
}
var staticRenderFns = []

export { render, staticRenderFns }