import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export default defineComponent({
  name: 'GuestOverview',
  components: {},
  auth: false,
  data: function data() {
    return {
      ventureDetailToggled: false
    };
  },
  computed: {
    assessment: function assessment() {
      return this.$store.getters['assessment/getAssessment'];
    },
    someImpactsDefined: function someImpactsDefined() {
      return this.assessment && this.assessment.outcomes && Object.keys(this.assessment.outcomes).length;
    },
    assessmentStatus: function assessmentStatus() {
      if (this.assessment !== undefined && this.assessment.assessment !== undefined && this.assessment.assessment.status !== undefined) {
        return this.assessment.assessment.status;
      }
      return 'Outcome-selection input';
    }
  },
  methods: {
    isAtOrAfterState: function (_isAtOrAfterState) {
      function isAtOrAfterState(_x) {
        return _isAtOrAfterState.apply(this, arguments);
      }
      isAtOrAfterState.toString = function () {
        return _isAtOrAfterState.toString();
      };
      return isAtOrAfterState;
    }(function (comparedState) {
      return isAtOrAfterState(this.assessmentStatus, comparedState);
    })
  }
});