var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.calculatedPaiReport && _vm.fund)?_c('div',{staticClass:"m-8"},[_c('v-row',{staticClass:"mb-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.form.pretext))}})]),_vm._v(" "),_c('v-row',{staticClass:"mb-8",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.fundName,"tooltip":_vm.form.fundDetails.fundNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.fund.name},on:{"input":(val) => {
              _vm.triggerUpdateFund('name', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.legalName,"tooltip":_vm.form.fundDetails.legalNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.fund?.portfolio?.nameOfFinancialMarketParticipant},on:{"input":(val) => {
              _vm.triggerUpdateAccount('nameOfFinancialMarketParticipant', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.legalEntityIdentifier,"tooltip":_vm.form.fundDetails.legalEntityIdentifierTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.account?.legalEntityIdentifier ?? ''},on:{"input":(val) => {
              _vm.triggerUpdateAccount('legalEntityIdentifier', val)
            }}})],1)],1)],1),_vm._v(" "),_c('section',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['howDidProductConsiderPais'].title,"subtitle":_vm.form.questions['howDidProductConsiderPais'].subtitle}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.currentUserInputObject,
                'howDidProductConsiderPais',
              )},on:{"input":(val) =>
                _vm.triggerUpdateReportAnswer('howDidProductConsiderPais', val)}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedPaiReport.howDidProductConsiderPais},on:{"copy":(val) =>
                _vm.triggerUpdateReportAnswer('howDidProductConsiderPais', val)}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('v-row',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['paisTable'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.form.questions['paisTable'].tooltip))}})]},proxy:true}],null,false,632351106)}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-data-table-metric-aggregations',{attrs:{"filters":{
              fundId: _vm.fund.id,
              isPaiResultsView: true,
            },"items-per-page":50,"sort-by":['paiSort'],"headers":[
              {
                text: 'Category',
                value: 'category',
                cellClass: '',
                width: '100px',
              },
              {
                text: 'Indicator',
                value: 'indicator',
                cellClass: '',
                width: '100px',
              },
              {
                text: 'Metric',
                value: 'paiMetricName',
                sortable: true,
                cellClass: '',
                width: '100px',
              },
              _vm.currentReferencePeriod,
              _vm.currentReferencePeriod - 1,
              {
                text: 'Explanations',
                value: 'explanation',
                cellClass: '',
                width: '50px',
                sortable: false,
              },
              {
                text: 'Actions',
                value: 'targets',
                cellClass: '',
                width: '50px',
                sortable: false,
              },
            ]},scopedSlots:_vm._u([{key:"item.explanation",fn:function({ item }){return [_c('ix-textarea',{staticClass:"text-body-2 min-w-[200px] max-w-[750px] break-words",attrs:{"value":_vm.currentUserInputObject
                    ?.principalAdverseImpactsExplanationsAndActions?.[
                    item.metric.id
                  ]?.explanation,"rows":"1","row-height":"10","hide-details":"","outlined":"","full-width":"","auto-grow":"","placeholder":"Add description..."},on:{"input":(val) =>
                    _vm.updatePaiInfoItem('explanation', val, item.metric.id)}})]}},{key:"item.targets",fn:function({ item }){return [_c('ix-textarea',{staticClass:"text-body-2 min-w-[200px] max-w-[750px] break-words",attrs:{"value":_vm.currentUserInputObject
                    ?.principalAdverseImpactsExplanationsAndActions?.[
                    item.metric.id
                  ]?.actions_and_targets,"rows":"1","row-height":"10","hide-details":"","outlined":"","full-width":"","auto-grow":"","placeholder":"Add description..."},on:{"input":(val) =>
                    _vm.updatePaiInfoItem(
                      'actions_and_targets',
                      val,
                      item.metric.id,
                    )}})]}}],null,false,2310990292)})],1)],1)],1),_vm._v(" "),_c('div',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['considerationOfPaisOnSustainabilityDescription']
              .title,"subtitle":_vm.form.questions['considerationOfPaisOnSustainabilityDescription']
              .subtitle},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.renderMarkdown(
                  _vm.form.questions[
                    'considerationOfPaisOnSustainabilityDescription'
                  ].tooltip,
                )
              )}})]},proxy:true}],null,false,770065965)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                  _vm.currentUserInputObject,
                  'considerationOfPaisOnSustainabilityDescription',
                )},on:{"input":(val) =>
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityDescription',
                    val,
                  )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedPaiReport?.considerationOfPaisOnSustainabilityDescription ??
                '',"non-copyable-text":_vm.previousUserInputObject?.considerationOfPaisOnSustainabilityDescription ??
                ''},on:{"copy":(val) =>
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityDescription',
                    val,
                  )}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
              'considerationOfPaisOnSustainabilityEngagementPolicies'
            ].title,"subtitle":_vm.form.questions[
              'considerationOfPaisOnSustainabilityEngagementPolicies'
            ].subtitle}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                  _vm.currentUserInputObject,
                  'considerationOfPaisOnSustainabilityEngagementPolicies',
                )},on:{"input":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityEngagementPolicies',
                    val,
                  )
                }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.fund.considerationOfPaisOnSustainabilityEngagementPolicies ||
                _vm.$t('navigateToFundSetup'),"non-copyable-text":_vm.previousUserInputObject?.considerationOfPaisOnSustainabilityEngagementPolicies ??
                ''},on:{"copy":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityEngagementPolicies',
                    val,
                  )
                }}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
              'considerationOfPaisOnSustainabilityInternationalStandards'
            ].title,"subtitle":_vm.form.questions[
              'considerationOfPaisOnSustainabilityInternationalStandards'
            ].subtitle}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                  _vm.currentUserInputObject,
                  'considerationOfPaisOnSustainabilityInternationalStandards',
                )},on:{"input":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityInternationalStandards',
                    val,
                  )
                }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.fund.considerationOfPaisOnSustainabilityInternationalStandards ||
                _vm.$t('navigateToFundSetup'),"non-copyable-text":_vm.previousUserInputObject?.considerationOfPaisOnSustainabilityInternationalStandards ??
                ''},on:{"copy":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityInternationalStandards',
                    val,
                  )
                }}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
              'considerationOfPaisOnSustainabilityHistoricalComparison'
            ].title,"subtitle":_vm.form.questions[
              'considerationOfPaisOnSustainabilityHistoricalComparison'
            ].subtitle}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                  _vm.currentUserInputObject,
                  'considerationOfPaisOnSustainabilityHistoricalComparison',
                )},on:{"input":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityHistoricalComparison',
                    val,
                  )
                }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedPaiReport.considerationOfPaisOnSustainabilityHistoricalComparison,"non-copyable-text":_vm.previousUserInputObject?.considerationOfPaisOnSustainabilityHistoricalComparison ??
                ''},on:{"copy":(val) => {
                  _vm.triggerUpdateReportAnswer(
                    'considerationOfPaisOnSustainabilityHistoricalComparison',
                    val,
                  )
                }}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['summaryOfPais'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.renderMarkdown(_vm.form.questions['summaryOfPais'].tooltip)
              )}})]},proxy:true}],null,false,2908294926)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(_vm.currentUserInputObject, 'summaryOfPais')},on:{"input":(val) => _vm.triggerUpdateReportAnswer('summaryOfPais', val)}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedPaiReport.summaryOfPais,"non-copyable-text":_vm.previousUserInputObject?.summaryOfPais ?? ''},on:{"copy":(val) => _vm.triggerUpdateReportAnswer('summaryOfPais', val)}})],1)],1)],1)],1)],1)],1):_c('v-container',{staticStyle:{"height":"75%"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Loading... ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"20"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }