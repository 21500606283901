var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',{staticStyle:{"border-radius":"12px !important"}},[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.title',
    )))]),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.comparativeScenarioAndFunctionalUnitDesc`,
              ),
            )
          )}})]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{attrs:{"title":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionOfYourImpactSolutionTitle',
            ),"subtitle":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionOfYourImpactSolutionQuestion',
            )}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionOfYourImpactSolutionWarning`,
              ),
            )
          )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showfunctionOfYourImpactSolutionGuidence =
              !_vm.showfunctionOfYourImpactSolutionGuidence}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showfunctionOfYourImpactSolutionGuidence
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.guidanceForProfile',
              ))+"\n          ")],1)]),_vm._v(" "),(_vm.showfunctionOfYourImpactSolutionGuidence)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.fairComparisonsPerImpactProfile.${[
                  _vm.impactProfile,
                ]}.guidanceFunctionOfYourImpactSolution`,
              ),
            )
          )}}):_vm._e(),_vm._v(" "),_c('ix-text-field',{attrs:{"type":"text","value":_vm.fairComparison.needFulfilled},on:{"input":(val) => _vm.upsertFairComparison({ needFulfilled: val })}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{attrs:{"title":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.comparativeScenarioTitle',
            ),"subtitle":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.comparativeScenarioText',
            )}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.comparativeScenarioQuestion`,
              ),
            )
          )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showComparativeScenarioGuidance = !_vm.showComparativeScenarioGuidance}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showComparativeScenarioGuidance
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.guidanceForProfile',
              ))+"\n          ")],1)]),_vm._v(" "),(_vm.showComparativeScenarioGuidance)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.fairComparisonsPerImpactProfile.${[
                  _vm.impactProfile,
                ]}.guidanceComparativeScenario`,
              ),
            )
          )}}):_vm._e(),_vm._v(" "),_c('ix-text-field',{attrs:{"type":"text","value":_vm.fairComparison.baselineSolutionDescription},on:{"input":(val) =>
              _vm.upsertFairComparison({ baselineSolutionDescription: val })}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{attrs:{"title":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionalUnitTitle',
            ),"subtitle":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionalUnitText',
            )}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.functionalUnitQuestion`,
              ),
            )
          )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showFunctionalUnitGuidance = !_vm.showFunctionalUnitGuidance}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showFunctionalUnitGuidance
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.guidanceForProfile',
              ))+"\n          ")],1)]),_vm._v(" "),(_vm.showFunctionalUnitGuidance)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.fairComparisonsPerImpactProfile.${[
                  _vm.impactProfile,
                ]}.guidanceFunctionalUnit`,
              ),
            )
          )}}):_vm._e(),_vm._v(" "),_c('ix-text-field',{attrs:{"type":"text","value":_vm.fairComparison.comparativeUnit},on:{"input":(val) => _vm.upsertFairComparison({ comparativeUnit: val })}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{attrs:{"title":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.describeComparisonTitle',
            ),"subtitle":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.fairComparison.describeComparisonSubtitle',
            )}}),_vm._v(" "),_c('ix-text-field',{attrs:{"type":"text","value":_vm.fairComparison.describeComparison},on:{"input":(val) => _vm.upsertFairComparison({ describeComparison: val })}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }