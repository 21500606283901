var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.loading)?_c('ix-page-loading'):(_setup.account)?_c('ix-section',[_c('ix-section-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-iterator',{attrs:{"items":_setup.funds,"item-key":"id","items-per-page":12},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div')]},proxy:true},{key:"default",fn:function({ items }){return [_c('v-row',_vm._l((items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"auto"}},[_c('v-card',{staticClass:"pb-2 flex flex-col w-[280px]"},[_c('v-card-title',{staticClass:"pb-0 h-[84px] items-baseline"},[_c('h4',{staticClass:"line-clamp-2 break-keep"},[_vm._v("\n                      "+_vm._s(item.name ? item.name : 'Unnamed fund')+"\n                    ")])]),_vm._v(" "),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pl-0",class:{ invisible: !item.volume }},[_c('v-list-item-content',[_vm._v(_vm._s(_vm.$t('textVolume')))]),_vm._v(" "),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v("\n                          "+_vm._s(_setup.abbreviateNumber(item.volume, _vm.$i18n.locale))+"\n                        ")])],1),_vm._v(" "),(item.investmentPeriod)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("\n                          "+_vm._s(_vm.$t('textPeriod'))+"\n                        ")]),_vm._v(" "),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v("\n                          "+_vm._s(_setup.formatAsGermanDate(
                              item.investmentPeriod.startDate,
                            ))+"\n                          -\n                          "+_vm._s(_setup.formatAsGermanDate(item.investmentPeriod.endDate))+"\n                        ")])],1):_vm._e()],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-around flex"},[_c('ix-button-action',{attrs:{"to":{
                        name: 'portfolio-funds-edit-fund',
                        params: { fund: item.id },
                      }}},[_vm._v("\n                      "+_vm._s(_vm.$t('btnFundSetup'))+"\n                    ")]),_vm._v(" "),(_setup.hasSfdrReport(item))?_c('ix-button-outlined',{attrs:{"to":{
                        name: 'portfolio-funds-export-tabs-fund',
                        params: { fund: item.id },
                      }}},[_vm._v("\n                      "+_vm._s(_vm.$t('btnEdit'))+"\n                    ")]):_c('ix-button-outlined',{staticClass:"invisible",attrs:{"disabled":""}},[_vm._v("\n                      "+_vm._s(_vm.$t('btnEdit'))+"\n                    ")])],1)],1)],1)}),1)]}}])})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-button-action',{on:{"click":() => _setup.addFund()}},[_vm._v("\n          "+_vm._s(_vm.$t('btnCreateNewFund'))+"\n        ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }