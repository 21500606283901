var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mt-4",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("\n      + Value Chain\n    ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"min-width":"300"}},[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$attrs.id ? _vm.$t('titleEdit') : _vm.$t('titleAdd'))+"\n      ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Name"},model:{value:(_setup.name),callback:function ($$v) {_setup.name=$$v},expression:"name"}}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"variant":"text"},on:{"click":function($event){dialog.value = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('btnCancel'))+"\n        ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","variant":"text","loading":_setup.loading},on:{"click":() => {
              if (_vm.$attrs.id) {
                _setup.editValueChain({ valueChain: { name: _setup.name, id: _vm.$attrs.id } }).then(
                  (v) => {
                    dialog.value = false
                    _setup.name = ''
                    _vm.$emit('done', v)
                  },
                )
              } else {
                _setup.addValueChain({ valueChain: { name: _setup.name } }).then((v) => {
                  dialog.value = false
                  _setup.name = ''
                  _vm.$emit('done', v)
                })
              }
            }}},[_vm._v("\n          "+_vm._s(_vm.$t('btnSubmit'))+"\n        ")])],1)],1)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }