export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    impactSolution: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {}
});