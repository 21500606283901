var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.toEditEmissionSource)?_c('ix-section',{staticClass:"h-full"},[_c('v-col',[_c('ix-section-title',{staticClass:"flex justify-between items-center"},[_vm._v("\n      "+_vm._s(_vm.toEditEmissionSource.sourceOfEmission)+" -\n      "+_vm._s(_vm.$t(
          'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.title',
        ))+"\n    ")]),_vm._v(" "),_c('ix-section-text',[_vm._v("\n      "+_vm._s(_vm.$t(
          'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.guidance',
        ))+"\n    ")]),_vm._v(" "),_c('div',[_c('ix-section-text',[_c('v-row',{staticClass:"row clickable-texts"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"max-width":"1500px","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer",attrs:{"role":"button"}},'span',attrs,false),on),[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.ownGhgText',
                    ))+"\n                ")]),_vm._v("\n                "+_vm._s(' '.repeat(4))+"\n              ")]}},{key:"default",fn:function(dialog){return [_c('custom-comparison-modal',{attrs:{"initial-data":_vm.toEditEmissionSource,"solution":false},on:{"selectItem":(val) => {
                      _vm.handleCustomSelectedItem(val, dialog)
                    },"closeModal":() => {
                      dialog.value = false
                    }}})]}}],null,false,3242042100)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"2"}},[_c('idemat-comparison-modal',{on:{"selectItem":_vm.handleSelectedItem}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.conceptLabel',
                ),"disabled":""},model:{value:(_vm.emissionSource.baselineConceptName),callback:function ($$v) {_vm.$set(_vm.emissionSource, "baselineConceptName", $$v)},expression:"emissionSource.baselineConceptName"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"value":_vm.getFactorText(_vm.emissionSource),"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.ghgFactorLabel',
                ),"disabled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--v-primary-base)"}},'v-icon',attrs,false),on),[_vm._v("\n                      mdi-information\n                    ")])]}}],null,false,2576269518)},[_vm._v(" "),_c('div',{staticStyle:{"max-width":"400px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.renderMarkdown(
                          _vm.$t(
                            `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.emissionSourceFormsTooltips.ghgFactorTooltip`,
                          ),
                        )
                      )}})])])]},proxy:true}],null,false,3944965819)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.customEvidenceLabel',
                ),"disabled":""},model:{value:(_vm.emissionSource.baselineEvidence),callback:function ($$v) {_vm.$set(_vm.emissionSource, "baselineEvidence", $$v)},expression:"emissionSource.baselineEvidence"}})],1)],1),_vm._v(" "),_c('v-row',[(_vm.emissionSource.customBaselineConcept)?_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.evidenceLabel',
                ),"disabled":""},model:{value:(_vm.emissionSource.baselineEvidence),callback:function ($$v) {_vm.$set(_vm.emissionSource, "baselineEvidence", $$v)},expression:"emissionSource.baselineEvidence"}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.consumptionAmountLabel',
                ),"rules":[_vm.rules.required],"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--v-primary-base)"}},'v-icon',attrs,false),on),[_vm._v("\n                      mdi-information\n                    ")])]}}],null,false,2576269518)},[_vm._v(" "),_c('div',{staticStyle:{"max-width":"400px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.renderMarkdown(
                          _vm.$t(
                            `impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.emissionSourceFormsTooltips.tooltipPerImpactProfile.${[
                              _vm.impactProfile,
                            ]}.consumptionAmountTooltip`,
                          ),
                        )
                      )}})])])]},proxy:true}],null,false,4048328109),model:{value:(_vm.emissionSource.baselineConsumption),callback:function ($$v) {_vm.$set(_vm.emissionSource, "baselineConsumption", $$v)},expression:"emissionSource.baselineConsumption"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('ix-text-field',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.unitLabel',
                ),"disabled":""},model:{value:(_vm.emissionSource.unitName),callback:function ($$v) {_vm.$set(_vm.emissionSource, "unitName", $$v)},expression:"emissionSource.unitName"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-textarea',{attrs:{"label":_vm.$t(
                  'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.baseline.assumptionLabel',
                )},model:{value:(_vm.emissionSource.baselineAssumption),callback:function ($$v) {_vm.$set(_vm.emissionSource, "baselineAssumption", $$v)},expression:"emissionSource.baselineAssumption"}})],1)],1)],1)],1),_vm._v(" "),_c('ix-section-text',[_c('v-row',{staticClass:"justify-end"},[_c('ix-button-action',{staticClass:"mt-2 mr-2",on:{"click":_vm.cancel}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.cancelModal',
            ))+"\n        ")]),_vm._v(" "),_c('ix-button-action',{staticClass:"mt-2",attrs:{"disabled":_vm.disableSaveButton},on:{"click":_vm.saveAndClose}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.comparativeEmissionSourceForm.closeModal',
            ))+"\n        ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }