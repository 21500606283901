import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { defineComponent, ref, watch, computed } from 'vue';
import { GChart } from 'vue-google-charts';
import { STAGES, STAGE_DESCRIPTION } from '~/functions/helpers';
export default defineComponent({
  name: 'GeographicalView',
  components: {
    GChart: GChart
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var viewModeToggle = ref(0);
    var STAGE_DESCRIPTION_REF = ref(STAGE_DESCRIPTION);
    var stageToggle = ref(3);
    var currentStage = ref('Assessment reviewer');
    var mapRegions = ref({
      world: undefined,
      africa: '002',
      asia: '142',
      europe: '150',
      oceania: '009',
      'north america': '021',
      'south america': '005'
    });
    var showPositive = ref(true);
    var selected = ref({});
    var assessments = computed(function () {
      return $store.getters['assessment/getAssessmentsWithImpactModule'].map(function (r) {
        return r.assessment;
      });
    });
    var stages = computed(function () {
      return STAGES;
    });
    var impacts = computed(function () {
      return $store.state.assessment.impacts;
    });
    var groupBy = function groupBy(elements, key) {
      return elements.reduce(function (rv, x) {
        ;
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var impactsByStage = computed(function () {
      return groupBy(impacts.value, 'stage');
    });
    var filteredImpactsByStage = computed(function () {
      if (!impacts.value) return [];
      return impactsByStage.value[currentStage.value];
    });
    var filteredImpactsByStageAndPositivity = computed(function () {
      return filteredImpactsByStage.value.filter(function (impact) {
        return impact.isPositive === showPositive.value;
      });
    });
    var availableStages = computed(function () {
      var availableStagesKeys = Object.keys(impactsByStage.value);
      return STAGES.filter(function (stageToTest) {
        return availableStagesKeys.includes(stageToTest);
      }); // ordering
    });
    watch(availableStages, function () {
      stageToggle.value = availableStages.value.length - 1;
      currentStage.value = availableStages.value[stageToggle.value];
    });
    var assessmentsForSelectedCountry = computed(function () {
      if (!selected.value) return [];
      var assessmentIds = selected.value.impacts.map(function (item) {
        return item.assessment[0];
      });
      return assessments.value.filter(function (assessment) {
        return assessmentIds.includes(assessment.id);
      });
    });
    return {
      viewModeToggle: viewModeToggle,
      STAGE_DESCRIPTION: STAGE_DESCRIPTION_REF,
      stageToggle: stageToggle,
      currentStage: currentStage,
      mapRegions: mapRegions,
      showPositive: showPositive,
      selected: selected,
      assessments: assessments,
      stages: stages,
      impacts: impacts,
      impactsByStage: impactsByStage,
      filteredImpactsByStage: filteredImpactsByStage,
      filteredImpactsByStageAndPositivity: filteredImpactsByStageAndPositivity,
      availableStages: availableStages,
      assessmentsForSelectedCountry: assessmentsForSelectedCountry
    };
  }
});