var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"locale":_vm.$i18n.locale,"value":_vm.selected,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.height,"item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"show-select":_vm.showSelect,"items":_vm.items,"sort-by":"createdAt","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},model:{value:(_vm.filters.text),callback:function ($$v) {_vm.$set(_vm.filters, "text", $$v)},expression:"filters.text"}}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => {
                _vm.editedItem = { labelI18n: {} }
              }}},'v-btn',attrs,false),on),[_vm._v("\n            New\n          ")])]}}]),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_vm._v(" "),(_vm.editedItem)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Create A New Section")])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Name in English"},model:{value:(_vm.editedItem.labelI18n.en),callback:function ($$v) {_vm.$set(_vm.editedItem.labelI18n, "en", $$v)},expression:"editedItem.labelI18n.en"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Name in German"},model:{value:(_vm.editedItem.labelI18n.de),callback:function ($$v) {_vm.$set(_vm.editedItem.labelI18n, "de", $$v)},expression:"editedItem.labelI18n.de"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":() => (_vm.editDialog = false)}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.editedItem.labelI18n.en == '' && _vm.editedItem.labelI18n.de == ''},on:{"click":() =>
                  _vm.addSection({
                    input: {
                      labelI18n: _vm.editedItem.labelI18n,
                    },
                  }).then(() => {
                    _vm.$nuxt.$emit('userMsg')
                    _vm.refetch()
                    _vm.editDialog = false
                  })}},[_vm._v("\n              "+_vm._s('Create')+"\n            ")])],1)],1):_vm._e()],1),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true},{key:"header.taxonomy.label",fn:function({ header }){return [_vm._v("\n    "+_vm._s(header.text)+"\n    "),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[(_vm.filters.taxonomyIds && _vm.filters.taxonomyIds.length)?_c('v-badge',{attrs:{"color":"green","content":_vm.filters.taxonomyIds.length}},[_c('v-icon',[_vm._v("mdi-filter")])],1):_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('ix-data-table-taxonomy',{model:{value:(_vm.filters.taxonomyIds),callback:function ($$v) {_vm.$set(_vm.filters, "taxonomyIds", $$v)},expression:"filters.taxonomyIds"}})],1)]}},{key:`item.labelI18n.en`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.labelI18n.en,"filter":_vm.filters.text}})]}},{key:`item.id`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.id)+"\n  ")]}},{key:`item.label`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.label)+"\n  ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":""},on:{"click":function($event){_vm.toDeleteVentureId = item.id}}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }