import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    score: {
      type: [Object, Number],
      required: true
    },
    isPositive: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    formatPercentage: function formatPercentage(score) {
      if (score !== undefined && score.specialValue !== 'NaN') {
        return (score * 100).toFixed(0);
      } else {
        return '—';
      }
    }
  }
});