// import { handleUndefined } from '../common'
import { getDifferenceInfo, percentage, percentageText } from '~/functions/esg'
import { translate } from '../../functions/translate'

export const benchmarkContent = (
  versionTextFirst: string,
  versionTextSecond: string,
  versionTextThird: string,
  mappingFirst: any,
  mappingSecond: any,
  mappingThird: any,
  esgSections: any,
) => {
  if (mappingFirst && mappingSecond && mappingThird)
    return [
      {
        margin: [0, 0, 0, 0],
        columns: [
          [
            {
              text: translate(
                'esg.comparison.latestPerformanceComparedToBenchmarks',
              ),
              style: ['esgHeader'],
              width: 'auto',
            },
          ],
        ],
      },
      table(
        versionTextFirst,
        versionTextSecond,
        versionTextThird,
        mappingFirst,
        mappingSecond,
        mappingThird,
        esgSections,
      ),
    ]
}
const table = (
  versionTextFirst: string,
  versionTextSecond: string,
  versionTextThird: string,
  mappingFirst: any,
  mappingSecond: any,
  mappingThird: any,
  esgSections: any,
) => {
  return {
    layout: 'noBorders',
    margin: [0, 10, 0, 0],
    table: {
      widths: [200, 100, 100, 100, 100, 100],
      headerRows: 1,
      body: [
        [
          {
            text: '',
          },
          {
            text: versionTextFirst,
            style: ['comparisonHeader'],
          },
          {
            text: versionTextSecond,
            style: ['comparisonHeader'],
          },
          {
            text: 'Difference',
            style: ['comparisonHeader'],
          },
          {
            text: versionTextThird,
            style: ['comparisonHeader'],
          },
          {
            text: 'Difference',
            style: ['comparisonHeader'],
          },
        ],
        ...getSubsectionRows(
          mappingFirst,
          mappingSecond,
          mappingThird,
          esgSections,
        ),
      ],
    },
  }
}

const calculateDifference = (firstScore: any, secondScore: any) => {
  if (firstScore === null || secondScore === null) return ''
  const diff = Math.round(firstScore) - Math.round(secondScore)
  return diff > 0 ? '+' + diff : diff
}

export const getSubsectionRows = (
  mappingFirst: any,
  mappingSecond: any,
  mappingThird: any,
  esgSections: any,
) => {
  const rows: any[] = []
  for (const section of esgSections) {
    rows.push([
      {
        text: section.label,
        style: ['section'],
        alignment: 'left',
      },
      {
        text: percentageText(mappingFirst[section.label]),
        style: ['section'],
      },
      {
        text: percentageText(mappingSecond[section.label]),
        style: ['section'],
      },
      {
        text:
          calculateDifference(
            percentage(mappingFirst[section.label]),
            percentage(mappingSecond[section.label]),
          ) + '%',
        style: ['section'],
      },
      {
        text: percentageText(mappingThird[section.label]),
        style: ['section'],
      },
      {
        text: getDifferenceInfo(
          mappingFirst[section.label],
          mappingSecond[section.label],
        )?.differenceText,
        style: ['section'],
      },
    ])
    for (const subsection of section.sections) {
      rows.push([
        {
          text: subsection.label,
          style: ['subsection'],
        },
        {
          text: percentageText(mappingFirst[subsection.label]),
          style: ['text'],
        },
        {
          text: percentageText(mappingSecond[subsection.label]),
          style: ['text'],
        },
        {
          text: getDifferenceInfo(
            mappingFirst[subsection.label],
            mappingSecond[subsection.label],
          )?.differenceText,
          style: ['text'],
        },
        {
          text: percentage(mappingThird[subsection.label]) + '%',
          style: ['text'],
        },
        {
          text: getDifferenceInfo(
            mappingFirst[subsection.label],
            mappingSecond[subsection.label],
          )?.differenceText,
          style: ['text'],
        },
      ])
    }
  }

  return rows
}
