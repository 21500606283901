import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import "core-js/modules/es6.array.map.js";
import { useLazyQuery } from '@vue/apollo-composable';
import { gql } from 'graphql-tag';
import VentureDashboard from '../../../pdf/Venture/venture_dashboard';
import { useGetAccountQuery } from '~/types/types';
import { esgVentureLinkList } from '~/functions/accounts';
export default defineComponent({
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result,
      onResult = _useGetAccountQuery.onResult;
    var account = computed(function () {
      var _accountResult$value;
      return ((_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount) || {};
    });
    var esgVentures = computed(function () {
      return esgVentureLinkList(account.value);
    });
    var GET_ESG_QUESTIONNAIRES = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      query getEsgQuestionnaireResultsForVentures($ventureIds: [String!]!) {\n        getEsgQuestionnaireResultsForVentures(ventureIds: $ventureIds) {\n          id\n          ventureId\n          updatedAt\n          sections {\n            label\n            questionnaireResult {\n              score\n              details\n            }\n            sections {\n              label\n              questionnaireResult {\n                score\n                details\n              }\n            }\n          }\n        }\n      }\n    "])));
    watch(esgVentures, function () {
      var _esgVentures$value;
      if ((_esgVentures$value = esgVentures.value) !== null && _esgVentures$value !== void 0 && _esgVentures$value.length) {
        var _esgVentures$value2;
        load(GET_ESG_QUESTIONNAIRES, {
          ventureIds: (_esgVentures$value2 = esgVentures.value) === null || _esgVentures$value2 === void 0 ? void 0 : _esgVentures$value2.map(function (venture) {
            return venture.ventureId;
          })
        });
      }
    });
    var _useLazyQuery = useLazyQuery(GET_ESG_QUESTIONNAIRES),
      result = _useLazyQuery.result,
      loading = _useLazyQuery.loading,
      error = _useLazyQuery.error,
      load = _useLazyQuery.load;
    var esgQuestionnaires = computed(function () {
      var _result$value$getEsgQ, _result$value;
      return (_result$value$getEsgQ = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getEsgQuestionnaireResultsForVentures) !== null && _result$value$getEsgQ !== void 0 ? _result$value$getEsgQ : [];
    });
    return {
      esgQuestionnaires: esgQuestionnaires,
      loading: loading,
      error: error,
      esgVentures: esgVentures
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    generateReport: function generateReport() {
      ;
      new VentureDashboard().download('venture.pdf');
    }
  }
});
//