import "core-js/modules/es6.number.constructor.js";
import _debounce from 'lodash/debounce';
import { renderMarkdownInline } from '~/functions/markdown';
export default defineComponent({
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, String, Number],
      default: function _default() {
        return [];
      }
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allowDeselection: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var updateValue = _debounce(function (value) {
      if (!props.allowDeselection && value === undefined) {
        console.log('Deselect disabled');
      } else {
        emitUpdate(value);
      }
    }, 400);
    var emitUpdate = function emitUpdate(value) {
      emit('input', value);
      emit('update:value', value);
    };
    onBeforeUnmount(function () {
      updateValue.flush();
    });
    return {
      updateValue: updateValue,
      renderMarkdownInline: renderMarkdownInline
    };
  }
});