export default defineComponent({
  name: 'ActiveFilters',
  props: {
    activeFilters: {
      type: Array,
      default: function _default() {
        return [[]];
      }
    }
  },
  setup: function setup() {}
});