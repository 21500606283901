import VueMarkdown from 'vue-markdown-render';
import { renderMarkdown } from '~/functions/markdown';

// export default defineComponent({})
export default defineComponent({
  components: {
    VueMarkdown: VueMarkdown
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});