export const editableFundReportForm = {
  pretext: {
    article9: `**Periodic disclosure for the financial products referred to in Article 9, paragraphs 1 to 4a, of Regulation (EU) 2019/2088 and Article 5, first paragraph, of Regulation (EU) 2020/852**
    <br>
    <br>
    All information in this view is required for the periodic disclosure of
        financial products falling under Article 9, paragraphs 1 to 4a of
        Regulation (EU) 2019/2088 and Article 5, first paragraph, of Regulation
        (EU) 2020/852. Therefore, it is essential that you carefully review that
        all the information provided is accurate and complete.

<strong style="color: var(--v-primary-base)">Please carefully read the following introduction on how to use the template.</strong>

**Using the Templates:**
Our system generated texts automatically based on the data you’ve provided in the Fund Setting Module
and your portfolio companies provided in the ESG assessment.
These texts can be easily transferred to the input field by clicking on the “Replace” button.

**Reviewing and Editing the Template:**
It’s crucial to carefully review the questions and the automatically generated sentences,
and if needed, add any data that is missing to make sure all information has been included to meet the periodic disclosure requirements.

**Personalizing the Responses:**
The templates serve as guidelines. You have the flexibility to use the provided texts in their entirety,
write your own response, or combine the two approaches to complete the questionnaire.
Most of the fields can be edited to provide a more accurate or detailed response.
However, please note that some fields are locked and cannot be edited.
Regardless, a review is always necessary to ensure accuracy and completeness.
Remember, the goal is to ensure full transparency about your sustainability risks and opportunities.`,
    article8: `**Periodic disclosure for the financial products referred to in Article 8, paragraphs 1, 2 and 2a, of Regulation (EU) 2019/2088 and Article 6, first paragraph, of Regulation (EU) 2020/852**
    <br>
    <br>
    All information in this view is required for the periodic disclosure of
        financial products falling under Article 8, paragraphs 1, 2 and 2a of
        Regulation (EU) 2019/2088 and Article 6, first paragraph, of Regulation
        (EU) 2020/852. Therefore, it is essential that you carefully review that
        all the information provided is accurate and complete.

<strong style="color: var(--v-primary-base)">Please carefully read the following introduction on how to use the template.</strong>

**Using the Templates:**
Our system generated texts automatically based on the data you’ve provided in the Fund Setting Module
and your portfolio companies provided in the ESG assessment.
These texts can be easily transferred to the input field by clicking on the “Replace” button.

**Reviewing and Editing the Template:**
It’s crucial to carefully review the questions and the automatically generated sentences,
and if needed, add any data that is missing to make sure all information has been included to meet the periodic disclosure requirements.

**Personalizing the Responses:**
The templates serve as orientation. You have the flexibility to use the provided texts in their entirety,
write your own response, or combine the two approaches to complete the questionnaire.
Most of the fields can be edited to provide a more accurate or detailed response.
However, please note that some fields are locked and cannot be edited.
Regardless, a review is always necessary to ensure accuracy and completeness.
Remember, the goal is to ensure full transparency about your sustainability risks and opportunities.`,
  },
  fundDetails: {
    fundName: 'What is the name of the fund?',
    fundNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalName: 'What is the name of the entity?',
    legalNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalEntityIdentifier:
      'What is the legal entity identifier (LEI code) of the Fund?',
    legalEntityIdentifierTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
  },
  title: {
    article8: 'Environmental and/or social characteristics',
    article8Tooltip: `This field is populated automatically, using the details you've provided in the Fund Setting Module. Modifications cannot be made directly in this field.`,
    article9: 'Sustainable Investment Objective',
    article9Tooltip: ``,
  },
  questions: {
    sustainableObjectiveTree: {
      title:
        'Did this financial product have a sustainable investment objective?',
      // subtitle:
      //   'tick and fill in as relevant, the percentage figure represents the minimum commitment to sustainable investments',
      tooltip:
        'Sustainable investment means an investment in an economic activity that contributes to an environmental or social objective, provided that the investment is compliant with the “do no significant harm” principle (for which disclosure of the PAI indicators is required), and that the investee companies follow good governance practice',
    },
    wereObjectivesMet: {
      article8: {
        title:
          'To what extent were the environmental and/or social characteristics promoted by this financial product met?',
      },
      article9: {
        title:
          'To what extent was the sustainable investment objective of this financial product met?',
      },
      tooltip:
        'Template answers for this question are based on your data input in the Fund Setup.',
    },
    esCharacteristicsDescription: {
      title: 'List of characteristics and (if applicable) objectives:',
      subtitle: {
        article8:
          'List the environmental and/or social characteristics promoted by the financial product. For the financial products referred to in Article 6, first paragraph, of Regulation (EU) 2020/852, in respect of sustainable investments with environmental objectives, list the environmental objectives set out in Article 9 of that Regulation to which the sustainable investment underlying the financial product contributed. For financial products that made sustainable investments with social objectives, list the social objectives',
        article9:
          'List the sustainable investment objective of this financial product, and describe how the sustainable investments contributed to the sustainable investment objective. For the financial products referred to in Article 5, first paragraph, of Regulation (EU) 2020/852, in respect of sustainable investments with environmental objectives, indicate to which environmental objectives set out in Article 9 of Regulation (EU) 2020/852 to the investment underlying the financial product contributed to. For the financial products referred to in Article 9(3) of Regulation (EU) 2019/2088, indicate how the objective of a reduction in carbon emissions was aligned with the Paris Agreement',
      },
    },
    sustainabilityIndicatorsPerformanceDescription: {
      title: 'How did the sustainability indicators perform?',
      tooltip: `Sustainability indicators measure how the environmental or social characteristics promoted by the fund are attained.`,
    },
    sustainabilityIndicatorsPerformanceDescriptionComparedToPrevious: {
      title: 'And compared to previous periods?',
      subtitle:
        'include for financial products where at least one previous periodic report was provided',
    },
    sustainableInvestmentsMadeByFund: {
      article8plus: {
        title:
          'What were the objectives of the sustainable investments that the financial product partially made and how did the sustainable investment contribute to such objectives?',
      },
      article9: {
        title:
          'What were the objectives of the sustainable investments that the financial product made and how did the sustainable investment contribute to such objectives?',
      },
      subtitle:
        'include for financial products that made sustainable investments, describe the objectives. Describe how the sustainable investments contributed to the sustainable investment objective. For the financial products referred to in Article 6, first paragraph, of Regulation (EU) 2020/852, list the environmental objectives set out in Article 9 of that Regulation to which the sustainable investment underlying the financial product contributed',
      tooltip:
        'The sustainability objectives and the description of the investment approach are derived from the Fund Setting Module you have completed earlier.',
    },
    howDidInvestmentsNotCauseHarm: {
      article8: {
        title:
          'How did the sustainable investments that the financial product partially made not cause significant harm to any environmental or social sustainable investment objective?',
        subtitle:
          'include where the financial product includes sustainable investments',
      },
      article9: {
        title:
          'How did the sustainable investments not cause significant harm to any sustainable investment objective?',
        subtitle:
          'include where the financial product includes sustainable investments',
      },
    },
    accountOnPais: {
      title:
        'How were the indicators for adverse impacts on sustainability factors taken into account?',
      tooltip:
        'Principal adverse impacts are the most significant negative impacts of investment decisions on sustainability factors relating to environmental, social and employee matters, respect for human right, anti-corruption and anti-bribery matters. For a fund to be considered Article 8+ or Article 9, the information on the Principal Adverse Impacts (PAIs) of the underlying investments should be provided.',
    },
    alignmentWithOecdGuidelines: {
      title:
        'Were sustainable investments aligned with the OECD Guidelines for Multinational Enterprises and the UN Guiding Principles on Business and Human Rights?',
      tooltip: `Please check the disclosure results of the ESG assessment to ensure that your investee companies  comply with the principles and guidelines.`,
    },
    howDidProductConsiderPais: {
      title:
        'How did this financial product consider principal adverse impacts on sustainability factors?',
      subtitle:
        'Include section if the financial product considered principal adverse impacts on sustainability factors',
    },
    topInvestments: {
      title: 'What were the top investments of this financial product?',
      tooltip: `If the assets ratio is not shown properly, please check if the data for the KPI "value of investment" is available in the KPI module for all your investee companies in the corresponding reference period`,
    },
    proportionSustainabilityRelatedInvestments: {
      title: 'What was the proportion of sustainability-related investments?',
    },
    sustainabilityRelatedInvestmentAssetAllocation: {
      title: 'What was the asset allocation?',
      tooltip: `Asset allocation describes the share of investments in specific assets.`,
    },
    sustainabilityRelatedInvestmentEconomicSector: {
      title: 'In which economic sectors were the investments made?',
      tooltip: `Mention the sectors and sub-sectors of your investments. It is recommended to use the NACE-categorization.`,
    },
    sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment: {
      title:
        'To what extent were the sustainable investments with an environmental objective aligned with the EU Taxonomy?',
      subtitle:
        'Include section for the financial products referred to in Article 6 , first paragraph, of Regulation (EU) 2020/852 and include information in accordance with Article 51 of this Regulation',
      tooltip: `Taxonomy-aligned activities are expressed as a share of:
      turnover reflects the “greenness” of investee companies today.
      capital expenditure (CapEx) shows the green investments made by investee companies, relevant for a transition to a green economy.
      operational expenditure (OpEx) reflects the green operational activities of investee companies.`,
    },
    fossilOrNuclear: {
      title: `Did the financial product invest in fossil gas and/or nuclear energy related activities complying with the EU taxonomy?`,
      subtitle:
        'Fossil gas and/or nuclear related activities will only comply with the EU Taxonomy where they contribute to limiting climate change (“climate change mitigation”) and do not significantly harm any EU Taxonomy objective. The full criteria for fossil gas and nuclear energy economic activities that comply with the EU Taxonomy are laid down in Commission Delegated Regulation (EU) 2022/1214.',
      tooltip:
        'To comply with the EU Taxonomy, the criteria for fossil gas include limitations on emissions and switching to fully renewable power or low-carbon fuels by the end of 2035. For nuclear energy, the criteria include comprehensive safety and waste management rules.',
    },
    shareOfInvestmentsInTransitionalAndEnablingActivities: {
      title:
        'What was the share of investments made in transitional and enabling activities?',
      subtitle:
        'Include a breakdown of the proportions of investments during the reference period',
      tooltip: `Enabling activities directly enable other activities to make a substantial contribution to an environmental objective.
      <br>
      <br>
      Transitional activities are activities for which low-carbon alternatives are not yet available and among others have greenhouse gas emission levels corresponding to the best performance.`,
    },
    investmentsAlignedWithEuTaxonomyComparisonWithPreviousPeriods: {
      title:
        'How did the percentage of investments that were aligned with the EU Taxonomy compare with previous reference periods?',
      subtitle:
        'Include where at least one previous periodic report was provided',
    },
    shareOfUnalignedSustainableInvestments: {
      title:
        'What was the share of sustainable investments with an environmental objective not aligned with the EU Taxonomy?',
      subtitle:
        'Include section only for the financial products referred to in Article 6, first subparagraph, of Regulation (EU) 2020/852 where the financial product included sustainable investments with an environmental objective that invested in economic activities that are not environmentally sustainable economic activities, and explain why the financial product invested in economic activities that were not Taxonomy-aligned',
      tooltip: `Investments with an environmental objective that do not take into account the criteria for environmentally sustainable economic activities under Regulation (EU) 2020/852, which are:
      <br>
      1. To contribute substantially to one or more of the environmental EU Taxonomy objectives.
      <br>
      2. To not significantly harm any of the environmental EU Taxonomy objectives.
      <br>
      3. To comply with the minimum safeguards.
      <br>
      4. To comply with EU technical screening criteria.`,
    },
    shareOfSociallySustainableInvestments: {
      title: 'What was the share of socially sustainable investments?',
      subtitle:
        'include only where the financial product included sustainable investments with a social objective',
    },
    purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards: {
      article8: {
        title:
          'What investments were included under “other”, what was their purpose and were there any minimum environmental or social safeguards?',
      },
      article9: {
        title:
          'What investments were included under “not sustainable”, what was their purpose and were there any minimum environmental or social safeguards?',
      },
      tooltip: `Article 53 requires financial market participants to provide information on
      the purpose of the remainder of the investments during the period covered by the periodic report, including a description of any minimum environmental or social safeguards and whether those investments are used for hedging,  relate to cash held as ancillary liquidity or are investments for which there are insufficient data.`,
    },
    actionsTakenToMeetEsCharacteristics: {
      article8: {
        title:
          'What actions have been taken to meet the environmental and/or social characteristics during the reference period?',
        subtitle:
          'List the actions taken within in the period covered by the periodic report to meet the environmental or social characteristics promoted by the financial product, including shareholder engagement as referred to in Article 3g of Directive 2007/36/EC and any other engagement relating to the environmental or social characteristics promoted by the financial product.',
      },
      article9: {
        title:
          'What actions have been taken to attain the sustainable investment objective during the reference period?',
        subtitle:
          'list the actions taken within the period covered by the periodic report to attain the sustainable investment objective of the financial product, including shareholder engagement as referred to in Article 3g of Directive 2007/36/EC and any other engagement relating to the sustainable investment objective.',
      },
      tooltip:
        'The template text is based on the data you entered in the fund setup.',
    },
    productPerformanceComparison: {
      title:
        'How did this financial product perform compared to the reference benchmark?',
      subtitle:
        'Include section where an index has been designated as a reference benchmark for the purpose of attaining the environmental or social characteristics promoted by the financial product and indicate where the methodology used for the calculation of the designated index can be found',
      tooltip: `Please note that it is <strong>optional</strong> to designate an index as a reference benchmark for the purpose of attaining the environmental or social characteristics
      Hence, it is also <strong>optional</strong> to report on this section.`,
    },
    referenceBenchmarkDifferenceToBroadMarket: {
      title:
        'How does the reference benchmark differ from a broad market index?',
      tooltip:
        'The reference benchmark is an index to assess whether and how a fund attains its environmental or social characteristics.',
    },
    fundPerformanceOnSustainabilityIndicators: {
      title:
        'How did this financial product perform with regard to the sustainability indicators to determine the alignment of the reference benchmark with the environmental or social characteristics promoted?',
    },
    fundPerformanceComparedToReferenceBenchmark: {
      title:
        'How did this financial product perform with regard to the sustainability indicators to determine the alignment of the reference benchmark with the sustainable investment objective?',
    },
    fundPerformanceComparedToBroadMarketIndex: {
      title:
        'How did this financial product perform compared with the broad market index?',
    },
  },
}

export const editableFundReportAdditionalInfo = {
  1: `**#1 Sustainable** covers sustainable investments with environmental or social objectives.\n
  **#2 Not sustainable** includes investments which do not qualify as sustainable investments.`,
  2: `**#1 Aligned with E/S characteristics** includes the investments of the financial product used to attain the environmental or social characteristics promoted by the financial product.\n
**#2 Other** includes the remaining investments of the financial product which are neither aligned with the environmental or social characteristics, nor are qualified as sustainable investments.\n\n
[include the note below where the financial product made sustainable investments]\n
The category **#1 Aligned with E/S characteristics** covers:\n
- The sub-category **#1A Sustainable** covers environmentally and socially sustainable investments.\n
- The sub-category **#1B Other E/S characteristics** covers investments aligned with the environmental or social characteristics that do not qualify as sustainable investments.`,
  3: `The graphs below show in green the percentage of investments that were aligned with the EU Taxonomy. As there is no appropriate methodology to determine the taxonomy-alignment of sovereign bonds, the first graph shows the Taxonomy alignment in relation to all the investments of the financial product including sovereign bonds, while the second graph shows the Taxonomy alignment only in relation to the investments of the financial product other than sovereign bonds.`,
  4: `For the purpose of these graphs, ‘sovereign bonds’ consist of all sovereign exposures`,
}
