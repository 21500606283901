import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { useGetImpactGoalQuery } from '~/types/types';
export default defineComponent({
  setup: function setup() {
    var route = useRoute();
    var routePath = computed(function () {
      return route.path;
    });
    var activeTabComputed = computed(function () {
      return [routePath.value];
    });
    var activeTab = ref([routePath.value]);
    var open = ref([]);
    var ventureId = computed(function () {
      return route.params.venture;
    });
    var _useGetImpactGoalQuer = useGetImpactGoalQuery({
        ventureId: ventureId.value
      }),
      result = _useGetImpactGoalQuer.result;
    var impactGoal = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getImpactGoal) || {};
    });
    var loading = useQueryLoading();
    return {
      activeTab: activeTab,
      activeTabComputed: activeTabComputed,
      open: open,
      ventureId: ventureId,
      impactGoal: impactGoal,
      loading: loading,
      routePath: routePath
    };
  },
  data: function data() {
    return {
      doGhgCheck: true
    };
  },
  computed: {
    sectionIndex: function sectionIndex() {
      var _this = this;
      return this.flatsections.findIndex(function (e) {
        return e.id === _this.routePath;
      }) || 0;
    },
    mainsections: function mainsections() {
      var _this$impactGoal,
        _this$impactGoal2,
        _this$impactGoal2$imp,
        _this2 = this;
      if (!this.impactGoal) return [];
      return [{
        label: this.$t('impactGoal.navigation.introduction.label'),
        id: "/".concat(this.ventureId, "/impact/edit/intro")
      }].concat(_toConsumableArray((_this$impactGoal = this.impactGoal) !== null && _this$impactGoal !== void 0 && _this$impactGoal.doEuTaxonomyCheck ? [{
        label: this.$t('impactGoal.navigation.euTaxonomy.label'),
        id: "/".concat(this.ventureId, "/impact/edit/eu-taxonomy"),
        sections: [{
          label: this.$t('impactGoal.navigation.eligibilityCheck.label'),
          id: "/".concat(this.ventureId, "/impact/edit/eu-taxonomy/check")
        }, {
          label: this.$t('impactGoal.navigation.financialMetrics.label'),
          id: "/".concat(this.ventureId, "/impact/edit/eu-taxonomy/financial")
        }]
      }] : []), [{
        label: this.$t('impactGoal.navigation.impactDefinition.label'),
        id: "/".concat(this.ventureId, "/impact/edit/impacts"),
        percentageCompleted: this.completionImpacts,
        sections: (_this$impactGoal2 = this.impactGoal) === null || _this$impactGoal2 === void 0 ? void 0 : (_this$impactGoal2$imp = _this$impactGoal2.impacts) === null || _this$impactGoal2$imp === void 0 ? void 0 : _this$impactGoal2$imp.map(function (impact, index) {
          var _impact$concept;
          return {
            label: (_impact$concept = impact.concept) !== null && _impact$concept !== void 0 && _impact$concept.label ? impact.concept.label : _this2.$t('impactGoal.navigation.impactNumber.label') + (index + 1),
            id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id),
            percentageCompleted: _this2.completionSingleImpact(impact),
            showImpactIcon: true,
            sections: [{
              label: _this2.$t('impactGoal.navigation.problemDescription.label'),
              id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/define"),
              percentageCompleted: _this2.completionDefine(impact),
              sections: []
            }, {
              label: _this2.$t('impactGoal.navigation.internationalFrameworks.label'),
              id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/classify"),
              percentageCompleted: _this2.completionClassify(impact),
              sections: []
            }, {
              label: _this2.$t('impactGoal.navigation.impactSolutions.label'),
              id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/"),
              percentageCompleted: _this2.completionSolutions(impact),
              sections: impact.impactSolutions.map(function (solution, solutionIndex) {
                var _solution$concept;
                return {
                  label: (_solution$concept = solution.concept) !== null && _solution$concept !== void 0 && _solution$concept.label ? solution.concept.label : _this2.$t('impactGoal.navigation.impactSolutionNumber.label') + (index + 1),
                  id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex),
                  percentageCompleted: _this2.completionSingleImpactSolution(solution),
                  sections: _this2.doGhgCheck && impact.hasFrozenOutcome && impact.conceptId === 13613 && solution.useGhgSavingPotentialTool ? [{
                    label: _this2.$t('impactGoal.navigation.ghgSavingPotential.label'),
                    id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex, "/ghgSavingPotential"),
                    sections: [{
                      label: _this2.$t('impactGoal.navigation.impactProfile.label'),
                      id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex, "/ghgSavingPotential/impactProfile")
                    }, {
                      label: _this2.$t('impactGoal.navigation.fairComparison.label'),
                      id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex, "/ghgSavingPotential/fairComparison")
                    }, {
                      label: _this2.$t('impactGoal.navigation.emissionSources.label'),
                      id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex, "/ghgSavingPotential/emissionSources")
                    }, {
                      label: _this2.$t('impactGoal.navigation.growthFactor.label'),
                      id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/solutions/").concat(solutionIndex, "/ghgSavingPotential/growthFactor")
                    }
                    // {
                    //   label: this.$t(
                    //     'impactGoal.navigation.result.label'
                    //   ),
                    //   id: `/${this.ventureId}/impact/edit/impacts/${impact.id}/solutions/${solutionIndex}/ghgSavingPotential/result`,
                    // },
                    ]
                  }] : []
                };
              })
            }, {
              label: _this2.$t('impactGoal.navigation.impactMeasurement.label'),
              id: "/".concat(_this2.ventureId, "/impact/edit/impacts/").concat(impact.id, "/measure"),
              percentageCompleted: _this2.completionMeasure(impact),
              sections: []
            }]
          };
        })
      }, {
        label: this.$t('impactGoal.navigation.impactTargets.label'),
        id: "/".concat(this.ventureId, "/impact/edit/impactTargets")
      }, {
        label: this.$t('impactGoal.navigation.nextSteps.label'),
        id: "/".concat(this.ventureId, "/impact/edit/outro"),
        disabled: this.isSubmitDisabled
      }]);
    },
    completionImpacts: function completionImpacts() {
      var _this$impactGoal3,
        _this$impactGoal4,
        _this$impactGoal4$imp,
        _this3 = this;
      if (!this.isAnswered((_this$impactGoal3 = this.impactGoal) === null || _this$impactGoal3 === void 0 ? void 0 : _this$impactGoal3.impacts)) return 0;
      return this.average.apply(this, _toConsumableArray(((_this$impactGoal4 = this.impactGoal) === null || _this$impactGoal4 === void 0 ? void 0 : (_this$impactGoal4$imp = _this$impactGoal4.impacts) === null || _this$impactGoal4$imp === void 0 ? void 0 : _this$impactGoal4$imp.map(function (impact) {
        return _this3.completionSingleImpact(impact);
      })) || []));
    },
    isSubmitDisabled: function isSubmitDisabled() {
      return this.completionImpacts !== 1;
    },
    flatsections: function flatsections() {
      return this.flatten(this.mainsections);
    },
    isIntroOrOutro: function isIntroOrOutro() {
      var _this$flatsections;
      return this.sectionIndex === 0 || this.sectionIndex === ((_this$flatsections = this.flatsections) === null || _this$flatsections === void 0 ? void 0 : _this$flatsections.length);
    }
  },
  watch: {
    sectionIndex: function sectionIndex(val) {
      this.activeTab = [this.flatsections[val].id];
      this.$router.push(this.activeTab[0]);
      this.activeTab = this.activeTabComputed;
    }
  },
  methods: {
    flatten: function flatten(sections) {
      var allSections = [];
      var _iterator = _createForOfIteratorHelper(sections),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var section = _step.value;
          allSections.push(section);
          if (section.sections) {
            var _iterator2 = _createForOfIteratorHelper(this.flatten(section.sections)),
              _step2;
            try {
              var _loop = function _loop() {
                var subsection = _step2.value;
                if (!allSections.find(function (e) {
                  return e.id === subsection.id;
                })) {
                  allSections.push(subsection);
                }
              };
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                _loop();
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return allSections;
    },
    average: function average() {
      for (var _len = arguments.length, arr = new Array(_len), _key = 0; _key < _len; _key++) {
        arr[_key] = arguments[_key];
      }
      var avg = arr.reduce(function (p, c) {
        return p + c;
      }, 0) / arr.length;
      return avg;
    },
    isAnswered: function isAnswered(val) {
      if (val === undefined || val === null || val === '') return 0;
      if (Array.isArray(val) && val.length === 0) return 0;
      return 1;
    },
    completionAlignmentCheck: function completionAlignmentCheck(impact) {
      var _this$impactGoal5;
      if (!((_this$impactGoal5 = this.impactGoal) !== null && _this$impactGoal5 !== void 0 && _this$impactGoal5.doEuTaxonomyCheck)) return [];
      var questionnaires = impact.euTaxonomyAlignmentQuestionnaires.map(function (e) {
        return e.questionnaireAssignment;
      });
      var checkResults = [];
      questionnaires.forEach(function (questionnaireAssignment) {
        if (questionnaireAssignment && questionnaireAssignment !== null && questionnaireAssignment !== void 0 && questionnaireAssignment.userInputs && questionnaireAssignment.userInputs.length !== 0) if ((questionnaireAssignment === null || questionnaireAssignment === void 0 ? void 0 : questionnaireAssignment.isEditable) === false) {
          checkResults.push(1);
        } else {
          checkResults.push(0);
        }
      });
      return checkResults;
    },
    completionDefine: function completionDefine(impact) {
      var toCheck = [this.isAnswered(impact.problem), this.isAnswered(impact.conceptId), this.isAnswered(impact.stakeholder), this.isAnswered(impact.geographies)];
      return this.average.apply(this, toCheck);
    },
    completionClassify: function completionClassify(impact) {
      var toCheck = [this.isAnswered(impact.sdgTargets)].concat(_toConsumableArray(this.completionAlignmentCheck(impact)));
      return this.average.apply(this, _toConsumableArray(toCheck));
    },
    completionSolutions: function completionSolutions(impact) {
      var _this4 = this;
      if (!this.isAnswered(impact.impactSolutions)) return 0;
      return this.average.apply(this, _toConsumableArray(impact.impactSolutions.map(function (solution) {
        return _this4.completionSingleImpactSolution(solution);
      })));
    },
    completionMeasure: function completionMeasure(impact) {
      return this.isAnswered(impact.metricAssignments);
    },
    completionSingleImpact: function completionSingleImpact(impact) {
      return this.average(this.completionDefine(impact), this.completionClassify(impact), this.completionSolutions(impact), this.completionMeasure(impact));
    },
    completionSingleImpactSolution: function completionSingleImpactSolution(solution) {
      var _solution$concept2;
      var result = this.average(this.isAnswered(solution.kind), this.isAnswered((_solution$concept2 = solution.concept) === null || _solution$concept2 === void 0 ? void 0 : _solution$concept2.label), this.isAnswered(solution.targetGroup), this.isAnswered(solution.solutionDescription)
      // this.isAnswered(solution.solutionSourceAssignments)
      );
      return result;
    },
    updateOpen: function updateOpen(id) {
      this.open = this.flatsections.filter(function (e) {
        return id.includes(e.id);
      }).map(function (e) {
        return e.id;
      });
    },
    updateNavigationViaSidebar: function updateNavigationViaSidebar(active) {
      if (active && active.length > 0) {
        this.activeTab = active;
        var id = active[0];
        this.$router.push(id);
        this.updateOpen(id);
      }
    },
    nextSection: function nextSection() {
      if (this.sectionIndex + 1 >= this.flatsections.length) return;
      var id = this.flatsections[this.sectionIndex + 1].id;
      this.activeTab = [id];
      this.updateOpen(id);
    },
    previousSection: function previousSection() {
      if (this.sectionIndex - 1 < 0) return;
      var id = this.flatsections[this.sectionIndex - 1].id;
      this.activeTab = [id];
      this.updateOpen(id);
    }
  }
});