import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var values = ref([]);
    // eslint-disable-next-line vue/no-setup-props-destructure
    values.value = props.value;
    return {
      values: values
    };
  },
  data: function data() {
    return {
      selectedSome: false
    };
  },
  computed: {
    selectedAll: function selectedAll() {
      return this.value.length === this.items.length;
    },
    icon: function icon() {
      if (this.selectedAll) return 'mdi-close-box';
      if (this.value.length > 0) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    label: function label() {
      if (this.selectedAll) return 'Clear all';
      return 'Select all';
    }
  },
  methods: {
    selectAllToggle: function selectAllToggle() {
      if (this.selectedAll) {
        this.values = [];
      } else {
        this.values = this.items.slice();
      }
      this.$emit('input', this.values);
    }
  }
});