import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { MetricReportingPeriodType, TypeMetricAssignedTo, useAddMetricAndAssignmentBatchMutation } from '~/types/types';
import { parseMetricForBackend } from '~/functions/metrics';
export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          editable: true,
          name: null,
          description: null,
          unitName: null,
          defaultGoalType: 'none',
          defaultGoalTarget: null,
          defaultReportingPeriodType: MetricReportingPeriodType.Annual,
          assignedTo: TypeMetricAssignedTo.Company
        };
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var dialog = ref(false);
    var metric = ref(_objectSpread({}, props.value));
    var assigneeIds = ref([]);
    var _useAddMetricAndAssig = useAddMetricAndAssignmentBatchMutation(),
      addMetricAndAssignmentBatch = _useAddMetricAndAssig.mutate,
      loading = _useAddMetricAndAssig.loading;
    var submit = function submit() {
      var _form$value, _form$value$validate;
      if ((_form$value = form.value) !== null && _form$value !== void 0 && (_form$value$validate = _form$value.validate) !== null && _form$value$validate !== void 0 && _form$value$validate.call(_form$value)) {
        addMetricAndAssignmentBatch({
          metric: parseMetricForBackend(metric.value),
          // FIXME: we should remove this
          assigneeIds: assigneeIds.value
        }).then(function (data) {
          emit('input', data);
          dialog.value = false;
        });
      }
    };
    var form = ref(null);
    return {
      loading: loading,
      assigneeIds: assigneeIds,
      metric: metric,
      form: form,
      submit: submit,
      dialog: dialog
    };
  }
});