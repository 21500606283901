import { percentage, scoreByMax, scoreText, skipped } from '~/functions/esg'
import { handleUndefined } from '../common'
import { getScoreDetail } from './common'
import { iconMapping, largeProgressBar } from './esg_report'

let ghg: any
let ghgFirstQuestion: any
let energy: any
let energyFirstQuestion: any
let transportation: any
let transportationFirstQuestion: any
let resource: any
let resourceFirstQuestion: any
let harm: any
let harmFirstQuestion: any
export const environmentalSlideContent = (tableData: any) => {
  if (tableData) {
    const subsectionsMap = new Map(
      tableData.subsections.map((subsection) => [subsection.key, subsection]),
    )
    ghg = subsectionsMap.get('ghgEmissions')
    ghgFirstQuestion = ghg?.questionSummaries[0]

    energy = subsectionsMap.get('energy')
    energyFirstQuestion = energy?.questionSummaries[0]

    transportation = subsectionsMap.get('transportation')
    transportationFirstQuestion = transportation?.questionSummaries[0]

    resource = subsectionsMap.get('resourceConsumptionAndUsage')
    resourceFirstQuestion = resource?.questionSummaries[0]

    harm = subsectionsMap.get('pollutionAndEnvironmentalHarm')
    harmFirstQuestion = harm?.questionSummaries[0]

    const percentageValue = percentage(tableData.result)
    const label = tableData.label
    return [
      {
        svg: `<svg width="1377" height="65" viewBox="0 0 1377 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0, -50) scale(1.6, 1.6)">
        ${iconMapping.governance}
        </g>
        <text fill="#343F49" xml:space="preserve" style="white-space: pre" font-family="Barlow" font-size="30" font-weight="600" letter-spacing="0em">
        <tspan x="100" y="50">${label}</tspan>
        </text>
        <g transform="translate(440, 30)">
        ${largeProgressBar(
          getScoreDetail(percentageValue, skipped(tableData.result)),
          percentageValue,
          scoreByMax(tableData.result),
        )}
        </g>
        </svg>`,
        width: 770,
        margin: [0, -10, 0, 0],
      },
      tableData && table(),
    ]
  }
}
const table = () => {
  const scoreColor = (result) =>
    getScoreDetail(percentage(result), skipped(result)).color
  return {
    layout: 'noBorders',
    margin: [0, 10, 0, 0],
    table: {
      widths: [20, 210, 175, 175, 150],
      body: [
        [
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: 'Actions taken',
            style: ['header'],
          },
          {
            text: 'Planned in next 12 months',
            style: ['header'],
          },
          {
            text: 'Comments',
            style: ['header'],
          },
        ],
        // start of Energy
        [
          {
            svg: `<svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 23.0116C11.5 23.6603 11.2629 24.2823 10.841 24.741C10.419 25.1996 9.84674 25.4573 9.25 25.4573C8.65326 25.4573 8.08097 25.1996 7.65901 24.741C7.23705 24.2823 7 23.6603 7 23.0116V18.9355H11.5V23.0116Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 18.9348C13.2463 18.3258 14.7691 17.1286 15.8477 15.5165C16.9264 13.9043 17.5051 11.9608 17.5 9.96739C17.5 7.58909 16.6308 5.3082 15.0837 3.62649C13.5365 1.94478 11.4381 1 9.25003 1C7.062 1 4.96358 1.94478 3.4164 3.62649C1.86923 5.3082 1.00003 7.58909 1.00003 9.96739C0.994952 11.9608 1.57364 13.9043 2.65233 15.5165C3.73102 17.1286 5.25373 18.3258 7.00003 18.9348" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 10.7824H6.25C5.95333 10.7824 5.66332 10.6867 5.41665 10.5076C5.16997 10.3284 4.97771 10.0738 4.86418 9.77586C4.75065 9.47794 4.72094 9.15011 4.77882 8.83384C4.8367 8.51756 4.97956 8.22705 5.18934 7.99903C5.39912 7.77101 5.66639 7.61572 5.95736 7.55281C6.24834 7.4899 6.54994 7.52219 6.82403 7.64559C7.09812 7.769 7.33238 7.97798 7.49721 8.2461C7.66203 8.51422 7.75 8.82945 7.75 9.15192V10.7824Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.75 10.7824H12.25C12.5467 10.7824 12.8367 10.6867 13.0834 10.5076C13.33 10.3284 13.5223 10.0738 13.6358 9.77586C13.7493 9.47794 13.7791 9.15011 13.7212 8.83384C13.6633 8.51756 13.5204 8.22705 13.3107 7.99903C13.1009 7.77101 12.8336 7.61572 12.5426 7.55281C12.2517 7.4899 11.9501 7.52219 11.676 7.64559C11.4019 7.769 11.1676 7.97798 11.0028 8.2461C10.838 8.51422 10.75 8.82945 10.75 9.15192V10.7824Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 18.9354V10.7832H10.75V18.9354" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 22.1953H11.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            width: 15,
            height: 15,
          },
          {
            text: [
              energy.label,
              {
                text: `   ${scoreText(energy.result)}\n`,
                color: scoreColor(energy.result),
                style: ['totalScore'],
              },
              {
                text: energyFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...energyFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...energyFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(energyFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(energy.questionSummaries),
        // start of Transportation
        [
          {
            svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 21.2461V22.8753C5.5 23.2897 5.65804 23.6871 5.93934 23.9801C6.22064 24.2731 6.60218 24.4378 7 24.4378C7.39782 24.4378 7.77936 24.2731 8.06066 23.9801C8.34196 23.6871 8.5 23.2897 8.5 22.8753V21.3128" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19 21.2461V22.8753C19 23.2897 18.842 23.6871 18.5607 23.9801C18.2794 24.2731 17.8978 24.4378 17.5 24.4378C17.1022 24.4378 16.7206 24.2731 16.4393 23.9801C16.158 23.6871 16 23.2897 16 22.8753V21.3128" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 5.6875H2.5C2.10218 5.6875 1.72064 5.85212 1.43934 6.14515C1.15804 6.43817 1 6.8356 1 7.25V10.375" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 5.6875H22C22.3978 5.6875 22.7794 5.85212 23.0607 6.14515C23.342 6.43817 23.5 6.8356 23.5 7.25V10.375" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.5 1H6C4.89543 1 4 1.93274 4 3.08333V19.2292C4 20.3798 4.89543 21.3125 6 21.3125H18.5C19.6046 21.3125 20.5 20.3798 20.5 19.2292V3.08333C20.5 1.93274 19.6046 1 18.5 1Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 15.0625H4" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5 18.1875H17.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 18.1875H10" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 4.125H14.5" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            width: 15,
            height: 15,
          },
          {
            text: [
              transportation.label,
              {
                text: `   ${scoreText(transportation.result)}\n`,
                color: scoreColor(transportation.result),
                style: ['totalScore'],
              },
              {
                text: transportationFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...transportationFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...transportationFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(transportationFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(transportation.questionSummaries),
        // start of GHG Emissions
        [
          {
            svg: `<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 25.4565C18.4632 25.4565 23.5 19.9817 23.5 13.2283C23.5 6.47478 18.4632 1 12.25 1C6.0368 1 1 6.47478 1 13.2283C1 19.9817 6.0368 25.4565 12.25 25.4565Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.25 9.15234C8.45435 9.15234 7.69129 9.4959 7.12868 10.1074C6.56607 10.719 6.25 11.5484 6.25 12.4132V14.0436C6.25 14.9085 6.56607 15.7379 7.12868 16.3494C7.69129 16.961 8.45435 17.3045 9.25 17.3045" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 9.15234C12.6022 9.15234 12.2206 9.32412 11.9393 9.62989C11.658 9.93565 11.5 10.3504 11.5 10.7828V15.6741C11.5 16.1065 11.658 16.5212 11.9393 16.827C12.2206 17.1327 12.6022 17.3045 13 17.3045C13.3978 17.3045 13.7794 17.1327 14.0607 16.827C14.342 16.5212 14.5 16.1065 14.5 15.6741V10.7828C14.5 10.3504 14.342 9.93565 14.0607 9.62989C13.7794 9.32412 13.3978 9.15234 13 9.15234Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.75 15.2658C16.75 14.9415 16.8685 14.6305 17.0795 14.4011C17.2905 14.1718 17.5766 14.043 17.875 14.043V14.043C18.1734 14.043 18.4595 14.1718 18.6705 14.4011C18.8815 14.6305 19 14.9415 19 15.2658V15.2658C18.9994 15.6608 18.8756 16.044 18.649 16.3528L16.75 18.9343H19" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            width: 15,
            height: 15,
          },
          {
            text: [
              ghg.label,
              {
                text: `   ${scoreText(ghg.result)}\n`,
                color: scoreColor(ghg.result),
                style: ['totalScore'],
              },
              {
                text: ghgFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...ghgFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...ghgFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(ghgFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(ghg.questionSummaries),
        [
          {
            svg: `<svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9049 3.58981C19.2435 2.17991 17.2511 1.30566 15.1547 1.0667C13.0584 0.827747 10.9425 1.23369 9.04812 2.23828C7.15373 3.24287 5.55707 4.80569 4.44009 6.74865C3.32311 8.6916 2.73073 10.9365 2.73047 13.2277V16.0498" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.05859 22.8687C8.72031 24.2783 10.7129 25.1521 12.8092 25.3907C14.9056 25.6293 17.0215 25.2231 18.9158 24.2183C20.8101 23.2135 22.4066 21.6505 23.5235 19.7075C24.6404 17.7644 25.2327 15.5195 25.233 13.2284V10.4062" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 14.168L2.7309 16.0494L4.4618 14.168" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.9618 12.2877L25.2309 10.4062L23.5 12.2877" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5947 17.7246C11.5947 17.7246 14.1391 19.8419 16.1597 18.4847C20.2584 15.7253 17.1267 9.68349 17.6932 6.64062C16.0355 6.64125 14.4015 7.06818 12.9246 7.88652C11.4478 8.70486 10.17 9.89139 9.19568 11.3492C7.59633 13.9217 8.4964 16.3123 11.5947 17.7246Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3294 13.2344C13.3294 13.2344 11.1784 14.7847 11.0977 19.8118" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            width: 15,
            height: 15,
          },
          {
            text: [
              resource.label,
              {
                text: `   ${scoreText(resource.result)}\n`,
                color: scoreColor(resource.result),
                style: ['totalScore'],
              },
              {
                text: resourceFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },

          {
            ul: [
              { text: '\n', listType: 'none' },
              ...resourceFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...resourceFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(resourceFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(resource.questionSummaries),
        [
          {
            svg: `<svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.91304 13.7598H1" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 3.12695H4.91304" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1407 1H6.95705C6.25374 1 5.68359 1.61973 5.68359 2.3842V14.1499C5.68359 14.9143 6.25374 15.534 6.95705 15.534H10.1407C10.844 15.534 11.4142 14.9143 11.4142 14.1499V2.3842C11.4142 1.61973 10.844 1 10.1407 1Z" stroke="#5B7185" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="26.1263" cy="16.3722" rx="1.54813" ry="1.68275" stroke="#5B7185"/>
            <ellipse cx="22.486" cy="7.47413" rx="2.06417" ry="2.24366" stroke="#5B7185"/>
            <path d="M11.4761 7.95898C15.8026 7.95897 20.963 10.4089 21.9951 19.5962C22.1008 20.5372 23.6781 20.1675 24.0592 21.4337C24.5753 23.1481 22.3906 22.9154 22.4577 24.4771C22.5111 25.7211 23.3603 25.4264 28.1876 25.4264M11.4761 14.2245C12.7063 14.2245 16.3186 17.1463 14.7705 21.4337C13.7384 23.8836 11.1582 20.8022 9.61006 24.4771C9.11311 25.6568 7.02985 25.4264 6.51381 25.4264C4.96568 25.4264 3.93359 25.4264 3.93359 25.4264" stroke="#5B7185" stroke-linecap="round"/>
            <path d="M13.2227 12.2461C14.7708 12.8586 17.867 15.3085 17.351 20.2084" stroke="#5B7185" stroke-linecap="round"/>
            </svg>`,
            width: 15,
            height: 15,
          },
          {
            text: [
              harm.label,
              {
                text: `   ${scoreText(harm.result)}\n`,
                color: scoreColor(harm.result),
                style: ['totalScore'],
              },
              {
                text: harmFirstQuestion.label,
                style: ['problems'],
              },
            ],
            style: ['subHeader'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...harmFirstQuestion.actionsTaken,
            ],
            style: ['label'],
          },
          {
            ul: [
              { text: '\n', listType: 'none' },
              ...harmFirstQuestion.actionsPlanned,
            ],
            style: ['label'],
          },
          {
            text: '\n' + handleUndefined(harmFirstQuestion.comment),
            style: ['label'],
          },
        ],
        getSecondQuestionIfExists(harm.questionSummaries),
      ],
    },
  }
}
export const getSecondQuestionIfExists = (questionSummaries: any) => {
  if (questionSummaries?.length > 1) {
    const questionSummary = questionSummaries[1]
    return [
      {
        text: ``,
      },
      {
        text: questionSummary.label,
        color: '#5B7185',
        style: ['problems'],
        margin: [-5, 0, 0, 0],
      },
      {
        ul: [{ text: '\n', listType: 'none' }, ...questionSummary.actionsTaken],
        style: ['label'],
      },
      {
        ul: [
          { text: '\n', listType: 'none' },
          ...questionSummary.actionsPlanned,
        ],
        style: ['label'],
      },
      {
        text: questionSummary.comment,
        style: ['label'],
      },
    ]
  }
  return [{}, {}, {}, {}, {}]
}
export const environmentalStyle = {
  header: {
    color: '#5B7185',
    bold: true,
    alignment: 'left',
    fontSize: 10,
    margin: [0, 0, 0, 5],
  },
  subHeader: {
    color: '#5B7185',
    bold: true,
    alignment: 'left',
    fontSize: 10,
    margin: [-5, 3, 0, 0],
  },
  label: {
    color: '#5B7185',
    alignment: 'left',
    fontSize: 9,
    margin: [0, 4],
  },
  totalScore: {
    bold: true,
    fontSize: 9,
  },
  problems: {
    fontSize: 9,
    color: '#5B7185',
    alignment: 'left',
    bold: false,
  },
}
