export const editablePrecontractualReportAdditionalInfo = {
  1: `**#1 Sustainable** covers sustainable investments with environmental or social objectives.\n
  **#2 Not sustainable** includes investments which do not qualify as sustainable investments.`,
  2: `**#1 Aligned with E/S characteristics** includes the investments of the financial product used to attain the environmental or social characteristics promoted by the financial product.\n
**#2 Other** includes the remaining investments of the financial product which are neither aligned with the environmental or social characteristics, nor are qualified as sustainable investments.\n\n
[include the note below where the financial product made sustainable investments]\n
The category **#1 Aligned with E/S characteristics** covers:\n
- The sub-category **#1A Sustainable** covers environmentally and socially sustainable investments.\n
- The sub-category **#1B Other E/S characteristics** covers investments aligned with the environmental or social characteristics that do not qualify as sustainable investments.`,
  3: `Enter the minimum percentage of EU taxonomy aligned investments (excluding sovereign bonds), that the fund intends to make. The percentage should be calculated as a share of the total capital allocated by the fund.`,
  4: `The percentages entered do not sum to 100. Please ensure that the percentages entered are correct before exporting.`,
}

export const editablePrecontractualReportForm = {
  pretext: {
    article9: `**Pre-contractual disclosure for the financial products referred to in Article 9, paragraphs 1 to 4a, of Regulation (EU) 2019/2088 and Article 5, first paragraph, of Regulation (EU) 2020/852**
    <br>
    <br>
    All information in this view is required for the pre-contractual disclosure of funds falling under Article 9, paragraphs 1 to 4a, of Regulation (EU) 2019/2088 and Article 5, first paragraph, of Regulation (EU) 2020/852. Therefore, it is essential that you carefully review that all the information provided is accurate and complete.

<strong style="color: var(--v-primary-base)">Please carefully read the following introduction on how to use the template.</strong>

**Using the Templates:**
Our system generated texts automatically based on the data you’ve provided in the Fund Setting Module. These texts can be easily transferred to the input field by clicking on the “Replace” button.

**Reviewing and Editing the Template:**
It’s crucial to carefully review the questions and the automatically generated sentences, and if needed, add any data that is missing to make sure all information has been included to meet the periodic disclosure requirements.

**Personalizing the Responses:**
The templates serve as guidelines. You have the flexibility to use the provided texts in their entirety, write your own response, or combine the two approaches to complete the questionnaire. Most of the fields can be edited to provide a more accurate or detailed response. However, please note that some fields are locked and cannot be edited. Regardless, a review is always necessary to ensure accuracy and completeness. Remember, the goal is to ensure full transparency about your sustainability risks and opportunities.`,
    article8: `**Pre-contractual disclosure for the financial products referred to in Article 8, paragraphs 1, 2 and 2a, of Regulation (EU) 2019/2088 and Article 6, first paragraph, of Regulation (EU) 2020/852**
    <br>
    <br>
    All information in this view is required for the precontractual disclosure of funds falling under Article 8, paragraphs 1, 2 and 2a, of Regulation (EU) 2019/2088 and Article 6, first paragraph, of Regulation (EU) 2020/852. Therefore, it is essential that you carefully review that all the information provided is accurate and complete.

<strong style="color: var(--v-primary-base)">Please carefully read the following introduction on how to use the template.</strong>

**Using the Templates:**
Our system generated texts automatically based on the data you’ve provided in the Fund Setting Module. These texts can be easily transferred to the input field by clicking on the “Replace” button.

**Reviewing and Editing the Template:**
It’s crucial to carefully review the questions and the automatically generated sentences, and if needed, add any data that is missing to make sure all information has been included to meet the periodic disclosure requirements.

**Personalizing the Responses:**
The templates serve as guidelines. You have the flexibility to use the provided texts in their entirety, write your own response, or combine the two approaches to complete the questionnaire. Most of the fields can be edited to provide a more accurate or detailed response. However, please note that some fields are locked and cannot be edited. Regardless, a review is always necessary to ensure accuracy and completeness. Remember, the goal is to ensure full transparency about your sustainability risks and opportunities.`,
  },
  fundDetails: {
    fundName: 'What is the name of the fund?',
    fundNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalName: 'What is the name of the entity?',
    legalNameTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
    legalEntityIdentifier:
      'What is the legal entity identifier (LEI code) of the Fund?',
    legalEntityIdentifierTooltip:
      'This information displayed is derived from the Fund Setting Module that you have completed earlier. You can make necessary changes and corrections in this view to ensure the accuracy and completeness of the information.',
  },
  title: {
    article8: 'Environmental and/or social characteristics',
    article8Tooltip: `Please see the calculated values based on the companies already assigned to the fund (“See calculated values based on companies assigned to fund"). You can either use these percentages or you can make adjustments and specify the estimated percentages manually. Please finalize the information accurately.`,
    article9: 'Sustainable Investment Objective',
    article9Tooltip: `Please see the calculated values based on the companies already assigned to the fund (“See calculated values based on companies assigned to fund"). You can either use these percentages or you can make adjustments and specify the estimated percentages manually. Please finalize the information accurately.`,
  },
  calculatedPercentageTexts: (percentage: number) => ({
    environmentalObjectiveCalculated: `Percentage of sustainable investments with an **environmental objective**: ${percentage}%`,
    socialObjectiveCalculated: `Percentage of sustainable investments with a **social objective**: ${percentage}%`,
    esCharacteristicCalculated: `Percentage of investments **aligned with the environmental or social characteristics** promoted by the financial product: ${percentage}%`,
  }),
  calculatedPercentageDialog: {
    show: 'See calculated values based on companies assigned to fund.',
    title: 'Calculated Values',
    close: 'Close',
  },
  questions: {
    sustainableObjectiveTree: {
      title:
        'Does this financial product have a sustainable investment objective?',
      tooltip:
        'Sustainable investment means an investment in an economic activity that contributes to an environmental or social objective, provided that the investment is compliant with the “do no significant harm” principle (for which disclosure of the PAI indicators is required), and that the investee companies follow good governance practice',
    },
    wereObjectivesMet: {
      article8: {
        title:
          'What environmental and/or social characteristics are promoted by this financial product? ',
      },
      article9: {
        title:
          'What is the sustainable investment objective of this financial product?',
      },
      tooltip:
        'The sustainability indicators listed here are derived from the Fund Setting Module you have completed earlier. Sustainability indicators measure how the environmental or social characteristics promoted by the financial product are attained.',
    },
    esCharacteristicsDescription: {
      title: 'List of characteristics and (if applicable) objectives:',
    },
    sustainabilityIndicatorsPerformanceDescription: {
      title: {
        article8:
          'What sustainability indicators are used to measure the attainment of each of the environmental or social characteristics promoted by this financial product?',
        article9:
          'What sustainability indicators are used to measure the attainment of the sustainable investment objective of this financial product?',
      },
      tooltip:
        'Sustainability indicators measure how the environmental or social characteristics promoted by the fund are attained.',
    },
    sustainableInvestmentsMadeByFund: {
      article8plus: {
        title:
          'What are the objectives of the sustainable investments that the financial product partially intends to make and how does the sustainable investment contribute to such objectives?',
      },
      article9: {
        title:
          'What are the objectives of the sustainable investments that the financial product intends to make and how will the sustainable investment contribute to such objectives?',
      },
      tooltip:
        'The sustainability objectives and the description of the investment approach are derived from the Fund Setting Module you have completed earlier.',
    },
    howDidInvestmentsNotCauseHarm: {
      title:
        'How do the sustainable investments that the financial product partially intends to make, not cause significant harm to any environmental or social sustainable investment objective? ',
    },
    accountOnPais: {
      title:
        'How have the indicators for adverse impacts on sustainability factors been taken into account? ',
      tooltip:
        'Principal adverse impacts are the most significant negative impacts of investment decisions on sustainability factors relating to environmental, social and employee matters, respect for human right, anti-corruption and anti-bribery matters. For a fund to be considered Article 8+ or Article 9, the information on the Principal Adverse Impacts (PAIs) of the underlying investments should be provided.',
    },
    alignmentWithOecdGuidelines: {
      title:
        'How are the sustainable investments aligned with the OECD Guidelines for Multinational Enterprises and the UN Guiding Principles on Business and Human Rights? Details:',
      tooltip: `Please check the disclosure results of the ESG assessment to ensure that your investee companies  comply with the principles and guidelines.`,
    },
    didProductConsiderPais: {
      title:
        'Does this financial product consider principal adverse impacts on sustainability factors?',
    },
    whyDidProductConsiderPais: {
      title:
        'Include a clear and reasoned explanation of how the fund considers principle adverse impacts on sustaibaility factors.',
      subtitle:
        'Indicate where, in the information disclosed pursuant to Article 11(2) of regulation (EU) 2019/2088, the information on principal adverse impacts on sustainability factors is available.',
    },
    investmentStrategy: {
      title: 'What investment strategy does this financial product follow?',
      tooltip:
        'The investment strategy guides investment decisions based on factors such as investment objectives and risk tolerance.',
    },
    bindingElementsOfInvestmentStrategy: {
      title:
        'What are the binding elements of the investment strategy used to select the investments to attain the sustainable investment objective?',
      tooltip:
        'Answer to this question is based on your data input in the Fund Setup',
    },
    companyGovernancePolicy: {
      title:
        'What is the policy to assess good governance practices of the investee companies?',
      tooltip:
        'Good governance practices include sound management structures, employee relations, remuneration of staff and tax compliance.',
    },
    // assetAllocation: {},
    howDidProductConsiderPais: {
      title:
        'How did this financial product consider principal adverse impacts on sustainability factors?',
      subtitle:
        'Include section if the financial product considered principal adverse impacts on sustainability factors',
    },
    sustainabilityRelatedInvestmentAssetAllocation: {
      title:
        'What is the asset allocation and the minimum share of sustainable investments?',
      tooltip: `Asset allocation describes the share of investments in specific assets.`,
    },
    sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment: {
      title:
        'To what minimum extent are sustainable investments with an environmental objective aligned with the EU Taxonomy? ',
      subtitle:
        'Include a section for the financial products referred to in Article 5, first paragraph, of Regulation (EU) 2020/852 and include information in accordance with Article 19(1) of this Regulation',
      tooltip: `Taxonomy-aligned activities are expressed as a share of:
      turnover reflects the “greenness” of investee companies today.
      capital expenditure (CapEx) shows the green investments made by investee companies, relevant for a transition to a green economy.
      operational expenditure (OpEx) reflects the green operational activities of investee companies.`,
    },
    fossilOrNuclear: {
      title: `Does the financial product invest in fossil gas and/or nuclear energy related activities that comply with the EU Taxonomy?`,
      subtitle:
        'Fossil gas and/or nuclear related activities will only comply with the EU Taxonomy where they contribute to limiting climate change (“climate change mitigation”) and do not significantly harm any EU Taxonomy objective. The full criteria for fossil gas and nuclear energy economic activities that comply with the EU Taxonomy are laid down in Commission Delegated Regulation (EU) 2022/1214.',
      tooltip:
        'To comply with the EU Taxonomy, the criteria for fossil gas include limitations on emissions and switching to fully renewable power or low-carbon fuels by the end of 2035. For nuclear energy, the criteria include comprehensive safety and waste management rules.',
    },
    shareOfInvestmentsInTransitionalAndEnablingActivities: {
      title:
        'What is the minimum share of investments in transitional and enabling activities?',
      tooltip: `Enabling activities directly enable other activities to make a substantial contribution to an environmental objective.
      <br>
      <br>
      Transitional activities are activities for which low-carbon alternatives are not yet available and among others have greenhouse gas emission levels corresponding to the best performance.`,
    },
    shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned:
      {
        title:
          'What is the minimum share of sustainable investments with an environmental objective that are not aligned with the EU Taxonomy? ',
        tooltip: `Investments with an environmental objective that do not take into account the criteria for environmentally sustainable economic activities under Regulation (EU) 2020/852, which are:
      <br>
      1. To contribute substantially to one or more of the environmental EU Taxonomy objectives.
      <br>
      2. To not significantly harm any of the environmental EU Taxonomy objectives.
      <br>
      3. To comply with the minimum safeguards.
      <br>
      4. To comply with EU technical screening criteria.`,
      },
    shareOfSociallySustainableInvestments: {
      title:
        'What is the minimum share of sustainable investments with a social objective?',
    },
    purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards: {
      article8: {
        title:
          'What investments are included under “#2 Other”, what is their purpose and are there any minimum environmental or social safeguards?',
      },
      article9: {
        title:
          'What investments were included under “Not sustainable”, what is their purpose and are there any minimum environmental or social safeguards?',
      },
      tooltip: `Article 53 requires financial market participants to provide information on
      the purpose of the remainder of the investments during the period covered by the periodic report, including a description of any minimum environmental or social safeguards and whether those investments are used for hedging,  relate to cash held as ancillary liquidity or are investments for which there are insufficient data.`,
    },
    productPerformanceComparison: {
      article8: {
        title:
          'Is a specific index designated as a reference benchmark to determine whether this financial product is algined with the environmental and/or social characteristics that it promotes?',
      },
      article9: {
        title:
          'Is a specific index designated as a reference benchmark to meet the sustainable investment objective?',
      },
      subtitle:
        'Include section where an index has been designated as a reference benchmark for the purpose of attaining the environmental or social characteristics promoted by the financial product and indicate where the methodology used for the calculation of the designated index can be found',
      tooltip: `Please note that it is <strong>optional</strong> to designate an index as a reference benchmark for the purpose of attaining the environmental or social characteristics
      Hence, it is also <strong>optional</strong> to report on this section.`,
    },
    sustainablyAlignedIndexExplanation: {
      title: {
        article8:
          'How is the reference benchmark continuously aligned with each of the environmental or social characteristics promoted by the financial product?',
        article9:
          'How does the reference benchmark take into account sustainability factors in a way that is continuously aligned with the sustainable investment objective?',
      },
    },
    investmentAlignedIndexExplanation: {
      title:
        'How is the alignment of the investment strategy with the methodology of the index ensured on a continuous basis?',
    },
    broadMarketIndexComparison: {
      title:
        'How does the designated index differ from a relevant broad market index?',
    },
    indexCalculationMethodology: {
      title:
        'Where can the methodology used for the calculation of the designated index be found?',
    },
    fundSpecificInfo: {
      title: 'Where can I find more product specific information online?',
    },
  },
}
