import { render, staticRenderFns } from "./IroStakeholderLink.vue?vue&type=template&id=5a0b2b39"
import script from "./IroStakeholderLink.vue?vue&type=script&setup=true&lang=ts"
export * from "./IroStakeholderLink.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./IroStakeholderLink.vue?vue&type=style&index=0&id=5a0b2b39&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./IroStakeholderLink.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSearchText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SearchText.vue').default,IxBtnAddStakeholder: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/AddStakeholder.vue').default,IxDataTable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/DataTable.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VCheckbox,VDialog,VDivider,VIcon,VSpacer,VTextField,VToolbar,VToolbarTitle})
