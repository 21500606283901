import { useQueryLoading } from '@vue/apollo-composable';
import { logObserver } from '~/functions/helpers';
import { useGetQuestionnairesForVenturesAndLabelQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    ventureName: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetQuestionnaires = useGetQuestionnairesForVenturesAndLabelQuery({
        ventureIds: [props.ventureId],
        label: 'ESG'
      }),
      resultAllEsg = _useGetQuestionnaires.result;
    function isCompleted(questionnaire) {
      return questionnaire !== null && !questionnaire.isEditable;
    }
    var first = computed(function () {
      var _resultAllEsg$value$g, _resultAllEsg$value;
      var allEsg = (_resultAllEsg$value$g = (_resultAllEsg$value = resultAllEsg.value) === null || _resultAllEsg$value === void 0 ? void 0 : _resultAllEsg$value.getQuestionnairesForVenturesAndLabel) !== null && _resultAllEsg$value$g !== void 0 ? _resultAllEsg$value$g : [];
      if (allEsg.length < 1) return null;
      return allEsg[0];
    });
    var second = computed(function () {
      var _resultAllEsg$value$g2, _resultAllEsg$value2;
      var allEsg = (_resultAllEsg$value$g2 = (_resultAllEsg$value2 = resultAllEsg.value) === null || _resultAllEsg$value2 === void 0 ? void 0 : _resultAllEsg$value2.getQuestionnairesForVenturesAndLabel) !== null && _resultAllEsg$value$g2 !== void 0 ? _resultAllEsg$value$g2 : [];
      if (allEsg.length < 2) return null;
      return allEsg[1];
    });
    var loading = useQueryLoading();
    return {
      loading: loading,
      isCompleted: isCompleted,
      first: first,
      second: second
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver
  }
});