var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.id)?_c('div',{staticClass:"text-sm-body-2 font-weight-bold"},[(false)?_c('v-badge',{attrs:{"left":"","top":"","content":_vm.badge,"value":_vm.badge,"color":"primary","bordered":""}},[_c('ix-search-text',{attrs:{"text":_vm.name,"filter":_vm.search}})],1):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":1300},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ix-search-text',{attrs:{"text":_vm.name,"filter":_vm.search}}),_vm._v(" "),_c('term',{attrs:{"explanation":"Computed KPI"}},[(_vm.isComputed)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-function ")]):_vm._e()],1),_vm._v(" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"p-0 justify-items-center flex items-baseline"},[_c('span',{staticClass:"max-w-[95%]"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.isComputed)?_c('v-icon',[_vm._v(" mdi-function ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){dialog.value = false}}},[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('metrics-metric-details',{attrs:{"metric":_vm.metric}}),_vm._v(" "),(_vm.metricAssignment && _vm.metricAssignment.completionStatus)?_c('metrics-target-parameters-details',{staticStyle:{"margin-left":"-14px !important"},attrs:{"metric-assignment":_vm.metricAssignment}}):_vm._e()],1),_vm._v(" "),(_vm.metricAssignment)?_c('v-col',{attrs:{"cols":"12"}},[_c('metrics-metric-values-plot',{attrs:{"metric-assignment":_vm.metricAssignment}})],1):_vm._e(),_vm._v(" "),(
                _vm.showDebugging &&
                _vm.metric.assignedTo == 'COMPANY' &&
                _vm.calculationType
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-lock")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.calculationType)+" on itself")])],1):(_vm.showDebugging && _vm.metric)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-lock")]),_vm._v(" "),(
                  _vm.metric.aggregationMethod && _vm.metric.assignedTo !== 'COMPANY'
                )?_c('span',[_vm._v("\n                "+_vm._s(_vm.metric.aggregationMethod)+"(\n              ")]):_c('span',[_vm._v("Datapoint")]),_vm._v(" "),_vm._l((_vm.metric.humanReadableDirectFormula),function(part,index){return _c('span',{key:index},[(part.type === 'VARIABLE')?_c('v-chip',{attrs:{"label":"","link":"","pill":""}},[_vm._v("\n                  $"+_vm._s(part.variable.id)+"\n                ")]):(part.type === 'OPERATOR')?_c('span',[_vm._v("\n                  "+_vm._s(part.value)+"\n                ")]):_c('v-chip',{attrs:{"label":"","link":"","pill":""}},[_vm._v(_vm._s(part.value))])],1)}),_vm._v(" "),(
                  _vm.metric.aggregationMethod && _vm.metric.assignedTo !== 'COMPANY'
                )?_c('span',[_vm._v("\n                )\n              ")]):_vm._e()],2):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(
                _vm.showDebugging && _vm.metric?.humanReadableDirectFormula?.length
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-lock")]),_vm._v(" "),(_vm.metric && _vm.metric.aggregationMethod)?_c('span',[_vm._v("\n                "+_vm._s(_vm.metric.aggregationMethod)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.metric.humanReadableDirectFormula),function(part,index){return _c('span',{key:index},[(part.type === 'VARIABLE')?_c('v-chip',{attrs:{"label":"","link":"","pill":""}},[_c('metrics-cells-metric-name',{attrs:{"id":part.variable.id,"is-computed":part.variable.isComputed,"name":part.variable.name}})],1):(part.type === 'OPERATOR')?_c('span',[_vm._v("\n                  "+_vm._s(part.value)+"\n                ")]):_c('v-chip',{attrs:{"label":"","link":"","pill":""}},[_vm._v(_vm._s(part.value))])],1)})],2):_vm._e()],1)],1)],1)]}}],null,false,2171225342)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }