// migrate all the logic from the script tag above to composition-api here
export default defineComponent({
  props: {
    outcome: {
      type: String,
      required: true
    },
    stakeholder: {
      type: String,
      default: ''
    },
    linksToImpact: {
      type: Boolean,
      required: false,
      default: null
    },
    isPositive: {
      type: Boolean,
      required: false,
      default: true
    },
    topIcon: {
      type: String,
      required: false,
      default: null
    },
    bottomIcon: {
      type: String,
      required: false,
      default: null
    }
  },
  setup: function setup(props) {
    var getLinksToImpact = computed(function () {
      if (props.linksToImpact === null) {
        return true;
      }
      return props.linksToImpact;
    });
    return {
      getLinksToImpact: getLinksToImpact
    };
  }
});