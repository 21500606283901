export default defineComponent({
  props: {
    impactGoal: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  },
  methods: {}
});