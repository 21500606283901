import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import Sugar from 'sugar';
export default defineComponent({
  name: 'VerticalSourceList',
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    highlights: {
      type: Boolean,
      default: false
    },
    outcome: Object,
    indicators: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var assessment = computed(function () {
      return $store.getters['assessment/getAssessment'];
    });
    var editingSource = ref({});
    var formatSource = function formatSource(text) {
      if (text === undefined) {
        text = '';
      }
      var paragraphs = text.split(/^\\?[-*•]\s/m);
      if (paragraphs.length > 1) {
        paragraphs = paragraphs.slice(1);
      }
      var parts = [];
      for (var i = 0; i < paragraphs.length; i++) {
        var paragraph = paragraphs[i];
        if (paragraph !== '') {
          parts.push({
            class: '',
            text: paragraph
          });
        }
      }
      return parts;
    };
    var setEditingSource = function setEditingSource(source) {
      var editingSrc = assessment.value.sources.find(function (e) {
        return e.id === source.id;
      });
      if (editingSrc) {
        editingSource.value = _objectSpread({}, editingSrc);
      }
    };
    var validSource = function validSource(source) {
      if (!source) {
        return false;
      }
      if (source.includes('urn:x-pdf:') || source.match(/\w\s\w/m)) {
        return false;
      }
      return true;
    };
    var outcomeEditingState = computed(function () {
      return $store.getters['assessment/getEditingState'](props.outcome.id);
    });
    var sources = computed(function () {
      if (assessment.value !== undefined) {
        var indicatorIds = props.indicators.map(function (e) {
          return e.id;
        });
        var _sources = assessment.value.sources.filter(function (source) {
          return indicatorIds.some(function (id) {
            return source.indicatorLinks.includes(id);
          });
        });

        // Filter duplicate items
        var uniqueSources = {};
        return _sources.filter(function (e) {
          var key = e.comment;
          var unique = !uniqueSources.hasOwnProperty(key);
          uniqueSources[key] = true;
          if (props.highlights) {
            return unique && (e.tags && e.tags.includes('Highlight') || e.hypothesisTags && e.hypothesisTags.includes('Highlight'));
          }
          return unique;
        }).map(function (e) {
          return _objectSpread(_objectSpread({}, e), {}, {
            created: new Sugar.Date(e.created).raw
          });
        });
      } else {
        return [];
      }
    });
    return {
      assessment: assessment,
      formatSource: formatSource,
      setEditingSource: setEditingSource,
      editingSource: editingSource,
      validSource: validSource,
      outcomeEditingState: outcomeEditingState,
      sources: sources
    };
  }
});