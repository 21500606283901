import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.find.js";
export default defineComponent({
  data: function data() {
    return {
      impactOverviewToggleOverride: null
    };
  },
  computed: {
    assessment: function assessment() {
      return this.$store.getters['assessment/getAssessment'];
    },
    stages: function stages() {
      if (this.assessment && this.assessment.outcomesByStages && this.assessment.outcomesByStages.length) {
        var permittedStages = this.assessment.outcomesByStages.map(function (item) {
          return item.stage;
        });
        return permittedStages.filter(function (value) {
          return ['Assessment venture', 'Assessment reviewer'].includes(value);
        });
      }
      return [];
    },
    impactOverviewToggle: {
      get: function get() {
        if (this.impactOverviewToggleOverride !== null) {
          return this.impactOverviewToggleOverride;
        }
        if (this.assessment !== undefined) {
          return this.stages.length - 1;
        }
        return 0;
      },
      set: function set(value) {
        this.impactOverviewToggleOverride = parseInt(value);
      }
    },
    outcomes: function outcomes() {
      var _this = this;
      if (this.impactOverviewToggle === null || this.assessment === undefined || this.assessment.outcomesByStages === undefined || this.assessment.outcomesByStages.length === 0) {
        return [];
      }
      var selectedStage = this.stages[this.impactOverviewToggle];
      var stage = this.assessment.outcomesByStages.find(function (item) {
        return item.stage === selectedStage;
      });
      var outcomeIds = stage ? stage.outcomes : [];
      return outcomeIds.map(function (id) {
        return _this.assessment.outcomes[id];
      });
    }
  },
  methods: {
    setImpactOverviewToggle: function setImpactOverviewToggle(value) {
      this.impactOverviewToggleOverride = parseInt(value);
    }
  }
});