import { useVentureId } from '~/functions/accounts';
export default defineComponent({
  props: {
    impactSolution: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var ventureId = useVentureId();
    return {
      ventureId: ventureId
    };
  }
});