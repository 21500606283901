import { render, staticRenderFns } from "./MetricValue.vue?vue&type=template&id=1642b33a"
import script from "./MetricValue.vue?vue&type=script&lang=ts"
export * from "./MetricValue.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./MetricValue.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MetricsFormattedMetricValue: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/FormattedMetricValue.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VIcon,VTooltip})
