var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',_vm._g(_vm._b({attrs:{"locale":_vm.$i18n.locale,"loading":_setup.loading,"dense":"","fixed-header":"","item-key":"id","headers":_setup.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"items":_setup.items,"sort-by":"name","server-items-length":_setup.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_setup.options.itemsPerPage,"options":_setup.options},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('NuxtLink',{attrs:{"to":`stakeholder/${item.id}/edit`}},[_c('ix-search-text',{attrs:{"text":item.name,"filter":_setup.search}})],1)]}},{key:`item.activityLinks`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":_setup.parseActivities(item.activityLinks)}})]}},{key:`item.iroLinks`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":_setup.parseIros(item.iroLinks)}})]}},{key:`item.impactMaterial`,fn:function({ item }){return [(_setup.getImpactMaterialStatus(item.iroLinks).count > 0)?_c('v-tooltip',{attrs:{"top":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer",attrs:{"color":"info"}},'span',attrs,false),on),[_vm._v("\n          "+_vm._s(_setup.getImpactMaterialStatus(item.iroLinks).count)+" IRO\n        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_c('ul',_vm._l((_setup.getImpactMaterialStatus(item.iroLinks)
              .names),function(name,index){return _c('li',{key:index},[_vm._v("\n            "+_vm._s(name)+"\n          ")])}),0)])]):_vm._e()]}},{key:`item.financialMaterial`,fn:function({ item }){return [(_setup.getFinancialMaterialStatus(item.iroLinks).count > 0)?_c('v-tooltip',{attrs:{"top":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-decoration-underline cursor-pointer",attrs:{"color":"info"}},'span',attrs,false),on),[_vm._v("\n          "+_vm._s(_setup.getFinancialMaterialStatus(item.iroLinks).count)+" IRO\n        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_c('ul',_vm._l((_setup.getFinancialMaterialStatus(item.iroLinks)
              .names),function(name,index){return _c('li',{key:index},[_vm._v("\n            "+_vm._s(name)+"\n          ")])}),0)])]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-white',_vm._g(_vm._b({attrs:{"small":"","color":"#23A9F1"}},'ix-button-white',attrs,false),on),[_c('img',{attrs:{"width":"14","src":_setup.settingsSvg}})])]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('ix-dialog-del-stakeholder',{attrs:{"item":item}})],1)],1)]}}],null,true)},'ix-data-table',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }