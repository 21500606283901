import { getSafe } from '~/functions/helpers';
export default defineComponent({
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  methods: {
    getSafe: getSafe
  }
});