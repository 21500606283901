var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"bg-white"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n    Delete Account: "+_vm._s(_vm.toDeleteAccount.name)+"\n  ")]),_vm._v(" "),_c('v-alert',{staticClass:"mx-6",attrs:{"text":"","outlined":"","type":"error"}},[_vm._v("\n    This action CANNOT be undone\n  ")]),_vm._v(" "),_c('v-card-text',{staticClass:"max-h-[750px] overflow-scroll"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("\n          Linked to portfolio(s):\n        ")]),_vm._v(" "),_c('v-expansion-panel-content',_vm._l((_vm.toDeleteAccount.portfolioLinks),function(link){return _c('v-list-item',{key:link.id,staticClass:"ml-4 min-h-[12px]"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(link.portfolio.name)+"\n              ")])],1)],1)}),1)],1),_vm._v(" "),(
          _vm.toDeleteAccount.metricAssignments &&
          _vm.toDeleteAccount.metricAssignments.length
        )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("\n          Data entry of those KPIs will be deleted:\n        ")]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"py-0"},_vm._l((_vm.toDeleteAccount.metricAssignments),function(assignment){return _c('v-list-item',{key:assignment.id,staticClass:"ml-4 min-h-[12px]"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(assignment.metric.name)+"\n              ")])],1)],1)}),1)],1):_vm._e(),_vm._v(" "),(
          _vm.toDeleteAccount.companyInfo &&
          _vm.toDeleteAccount.companyInfo.userInputs
        )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("\n          Company Info will be deleted:\n        ")]),_vm._v(" "),_c('v-expansion-panel-content',_vm._l((_vm.toDeleteAccount.companyInfo.userInputs),function(userInput){return _c('v-list-item',{key:userInput.id,staticClass:"ml-4 min-h-[12px]"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(userInput.question.label)+":\n                "+_vm._s(userInput.text || userInput.selectedOptions)+"\n              ")])],1)],1)}),1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"label":`type the exact company name:'${_vm.toDeleteAccount.name}' to delete`},model:{value:(_vm.inputConfirm),callback:function ($$v) {_vm.inputConfirm=$$v},expression:"inputConfirm"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":() => {
          _vm.inputConfirm = ''
          _vm.dialog.value = false
        }}},[_vm._v("\n      Cancel\n    ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":"","disabled":_vm.inputConfirm != _vm.toDeleteAccount.name},on:{"click":() => {
          _vm.deleteAccount({ id: _vm.id, dryRun: false })
        }}},[_vm._v("\n      DELETE\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }