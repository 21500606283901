import { renderMarkdown } from '~/functions/markdown';
import { MetricReportingPeriodType, TypeMetricAssignedTo, useDeleteMetricAggregationMutation, useUpdateMetricAggregationMutation } from '~/types/types';
export default defineComponent({
  components: {},
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var _useDeleteMetricAggre = useDeleteMetricAggregationMutation({}),
      deleteMetricAggregation = _useDeleteMetricAggre.mutate;
    var _useUpdateMetricAggre = useUpdateMetricAggregationMutation(),
      updateMetricAggregation = _useUpdateMetricAggre.mutate,
      onUpdateMetricAggregationDone = _useUpdateMetricAggre.onDone;
    onUpdateMetricAggregationDone(function (_ref) {
      var _data$updateMetricAgg;
      var data = _ref.data;
      if (data !== null && data !== void 0 && (_data$updateMetricAgg = data.updateMetricAggregation) !== null && _data$updateMetricAgg !== void 0 && _data$updateMetricAgg.id) {
        window.$nuxt.$emit('userMsg');
      }
    });
    var metricFormDialog = ref(false);
    var openedItem = ref(null);
    var openedPeriod = ref('');
    var openedAggregationId = ref(0);
    var dialog = ref(false);
    var groupBy = ref(null);
    var refetchList = function refetchList() {}; // TODO
    var refetch = function refetch() {}; // TOOD

    return {
      // loading,
      refetch: refetch,
      refetchList: refetchList,
      dialog: dialog,
      openedPeriod: openedPeriod,
      openedItem: openedItem,
      openedAggregationId: openedAggregationId,
      metricFormDialog: metricFormDialog,
      updateMetricAggregation: updateMetricAggregation,
      deleteMetricAggregation: deleteMetricAggregation,
      MetricReportingPeriodType: MetricReportingPeriodType,
      groupBy: groupBy,
      TypeMetricAssignedTo: TypeMetricAssignedTo
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});