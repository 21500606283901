import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    positive: {
      type: Number,
      default: 0
    },
    negative: {
      type: Number,
      default: 0
    },
    score: {
      type: Number,
      default: 0
    }
  },
  setup: function setup() {},
  methods: {
    formatScore: function formatScore(score) {
      if (score == null) {
        return '—&nbsp;';
      }
      if (this.type === 'confidence') {
        return score.toFixed(1);
      } else {
        return (score * 100).toFixed(0);
      }
    }
  }
});