import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.replace.js";
import { getGroupNames, venturesMap } from '~/functions/accounts';
import { formatAsGermanDate } from '~/functions/helpers';
import { useGetAccountQuery, useGetEsgQuestionnaireResultsForVenturesQuery, useUpdateVentureCommentMutation } from '~/types/types';
export default defineComponent({
  props: {},
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result,
      refetchAccount = _useGetAccountQuery.refetch;
    var account = computed(function () {
      var _result$value;
      return (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getAccount;
    });
    var getVenture = function getVenture(recordId) {
      var _getVentureLink;
      return ((_getVentureLink = getVentureLink(recordId)) === null || _getVentureLink === void 0 ? void 0 : _getVentureLink.venture) || {};
    };
    var getVentureLink = function getVentureLink(recordId) {
      var _account$value, _account$value$ventur;
      return (_account$value = account.value) === null || _account$value === void 0 ? void 0 : (_account$value$ventur = _account$value.ventureLinks) === null || _account$value$ventur === void 0 ? void 0 : _account$value$ventur.find(function (e) {
        return e.ventureId === recordId;
      });
    };
    var venturesMapping = computed(function () {
      return venturesMap(account.value);
    });

    // const esgVentures: any = computed(() => {
    //   // loop through venturesMapping
    //   // if ventureMapping has esg module
    //   // return ventureMapping.ventureId
    //   return Object.values(venturesMapping.value).filter(
    //     (ventureLink: PortfolioVentureLinkType) =>
    //       ventureLink.modules.includes(Module.Esg),
    //   )
    // })
    var _useGetEsgQuestionnai = useGetEsgQuestionnaireResultsForVenturesQuery({
        // ventureIds: esgVentures.value?.map((venture: any) => venture.ventureId),
      }),
      esgQuestionnairesResult = _useGetEsgQuestionnai.result,
      loading = _useGetEsgQuestionnai.loading,
      refetch = _useGetEsgQuestionnai.refetch;
    var esgQuestionnaires = computed(function () {
      var _esgQuestionnairesRes;
      return ((_esgQuestionnairesRes = esgQuestionnairesResult.value) === null || _esgQuestionnairesRes === void 0 ? void 0 : _esgQuestionnairesRes.getEsgQuestionnaireResultsForVentures) || [];
    });

    // const loading = useQueryLoading()

    var items = computed(function () {
      var _esgQuestionnaires$va, _esgQuestionnaires$va2;
      return (_esgQuestionnaires$va = esgQuestionnaires.value) === null || _esgQuestionnaires$va === void 0 ? void 0 : (_esgQuestionnaires$va2 = _esgQuestionnaires$va.filter(function (item) {
        return Boolean(item);
      })) === null || _esgQuestionnaires$va2 === void 0 ? void 0 : _esgQuestionnaires$va2.map(function (questionnaire) {
        var _questionnaire$enviro, _questionnaire$social, _questionnaire$govern;
        var ventureLink = getVentureLink(questionnaire.ventureId) || {
          comment: null,
          venture: null
        };
        return {
          ventureId: questionnaire.ventureId,
          venture: ventureLink.venture,
          totalscore: questionnaire.total,
          totalDiffToPrevious: questionnaire.totalDiffToPrevious,
          isCompleted: questionnaire.isCompleted,
          envperformance: questionnaire.environmental,
          socperformance: questionnaire.social,
          govperformance: questionnaire.governance,
          envDiffToPrevious: (_questionnaire$enviro = questionnaire.environmentalDiffToPrevious) !== null && _questionnaire$enviro !== void 0 ? _questionnaire$enviro : null,
          socDiffToPrevious: (_questionnaire$social = questionnaire.socialDiffToPrevious) !== null && _questionnaire$social !== void 0 ? _questionnaire$social : null,
          govDiffToPrevious: (_questionnaire$govern = questionnaire.governanceDiffToPrevious) !== null && _questionnaire$govern !== void 0 ? _questionnaire$govern : null,
          disclosureDetails: questionnaire.disclosure,
          update: questionnaire.updatedAt,
          groupNames: getGroupNames(questionnaire.ventureId, venturesMapping.value),
          comment: ventureLink.comment
        };
      }).sort(function (a, b) {
        return a.venture < b.venture ? -1 : 1;
      });
    });
    var _useUpdateVentureComm = useUpdateVentureCommentMutation(),
      updateVentureComment = _useUpdateVentureComm.mutate;
    var selected = ref([]);
    var filter = function filter(value, query, item) {
      var _item$venture, _item$groupNames, _item$totalscore, _item$envperformance, _item$socperformance, _item$govperformance, _item$geographies, _item$comment;
      var search = query.toString().toLocaleLowerCase();
      return ((_item$venture = item.venture) === null || _item$venture === void 0 ? void 0 : _item$venture.name.toLocaleLowerCase().includes(search)) || ((_item$groupNames = item.groupNames) === null || _item$groupNames === void 0 ? void 0 : _item$groupNames.toLocaleLowerCase().includes(search)) || ((_item$totalscore = item.totalscore) === null || _item$totalscore === void 0 ? void 0 : _item$totalscore.toString().includes(search)) || ((_item$envperformance = item.envperformance) === null || _item$envperformance === void 0 ? void 0 : _item$envperformance.toString().includes(search)) || ((_item$socperformance = item.socperformance) === null || _item$socperformance === void 0 ? void 0 : _item$socperformance.toString().includes(search)) || ((_item$govperformance = item.govperformance) === null || _item$govperformance === void 0 ? void 0 : _item$govperformance.toString().includes(search)) || ((_item$geographies = item.geographies) === null || _item$geographies === void 0 ? void 0 : _item$geographies.toLocaleLowerCase().includes(search)) || ((_item$comment = item.comment) === null || _item$comment === void 0 ? void 0 : _item$comment.toLocaleLowerCase().includes(search)) || formatAsGermanDate(item.update).includes(search);
    };
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var headers = computed(function () {
      return [{
        sortable: true,
        align: 'start',
        class: 'th',
        value: 'venture.name'
      }, {
        value: 'groupNames'
      }, {
        value: 'totalscore'
      }, {
        value: 'envperformance'
      }, {
        value: 'socperformance'
      }, {
        value: 'govperformance'
      }, {
        value: 'disclosureDetails'
      }, {
        value: 'update'
      }, {
        value: 'comment',
        sortable: false // override to false
      }].map(function (v) {
        return _objectSpread({
          sortable: true,
          align: 'center',
          class: 'th',
          text: $i18n.t("esg.portfolioListView.headers.".concat(v.value))
        }, v);
      });
    });
    return {
      refetchAccount: refetchAccount,
      getVenture: getVenture,
      // esgVentures,
      items: items,
      updateVentureComment: updateVentureComment,
      loading: loading,
      selected: selected,
      filter: filter,
      headers: headers,
      refetch: refetch
    };
  },
  data: function data() {
    return {
      search: '',
      sortDesc: false,
      sortBy: 'venture.name',
      groupBy: null,
      filterToggled: false
    };
  },
  computed: {
    sortKeys: function sortKeys() {
      var _this = this;
      return this.headers.filter(function (v) {
        return v.sortable;
      }).map(function (_ref) {
        var value = _ref.value;
        return {
          text: _this.$t("esg.portfolioListView.sortBy", {
            column: _this.$t("esg.portfolioListView.headers.".concat(value))
          }),
          value: value
        };
      });
    }
  },
  methods: {
    formatAsGermanDate: formatAsGermanDate,
    formatNumber: function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    parseDisclosureRisks: function parseDisclosureRisks(subsectionDetails) {
      return subsectionDetails.filter(function (subsection) {
        return subsection.hasRisk;
      }).map(function (subsection) {
        return subsection.section_label;
      });
    },
    sort: function sort(value) {
      if (typeof value === 'boolean') this.sortDesc = value;else this.sortBy = value;
    }
  }
});