import "core-js/modules/es6.number.constructor.js";
import _debounce from 'lodash/debounce';
export default defineComponent({
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: [Array, String, Number, Boolean],
      default: function _default() {
        return [];
      }
    },
    itemKey: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var internalValue = ref(props.value);
    var touched = ref(false);
    var updateValue = _debounce(function (value) {
      touched.value = false;
      emit('input', value);
      emit('update:value', value);
    }, 400);
    watch(function () {
      return props.value;
    }, function (value) {
      if (!touched.value) internalValue.value = value;
    });
    var updateInternalValue = function updateInternalValue(value) {
      touched.value = true;
      updateValue(value);
    };
    onBeforeUnmount(function () {
      updateValue.flush();
    });
    return {
      internalValue: internalValue,
      updateInternalValue: updateInternalValue
    };
  },
  methods: {
    getKey: function getKey(option) {
      return option[this.itemKey];
    },
    getValue: function getValue(option) {
      return option[this.itemValue];
    },
    getText: function getText(option) {
      return option[this.itemText];
    }
  }
});