var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.calculatedSfdrReport && _vm.fund)?_c('div',{staticClass:"m-8"},[_c('v-row',{staticClass:"mb-4"},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.renderMarkdown(
          _vm.calculatedSfdrReport.isArticle9
            ? _vm.form.pretext.article9
            : _vm.form.pretext.article8,
        )
      )}})]),_vm._v(" "),_c('v-row',{staticClass:"mb-8",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.fundName,"tooltip":_vm.form.fundDetails.fundNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.fund.name},on:{"input":(val) => {
              _vm.triggerUpdateFund('name', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.legalName,"tooltip":_vm.form.fundDetails.legalNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.fund.portfolio.nameOfFinancialMarketParticipant},on:{"input":(val) => {
              _vm.triggerUpdateAccount('nameOfFinancialMarketParticipant', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.fundDetails.legalEntityIdentifier,"tooltip":_vm.form.fundDetails.legalEntityIdentifierTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.fund?.legalEntityIdentifier ?? ''},on:{"input":(val) => {
              _vm.triggerUpdateFund('legalEntityIdentifier', val)
            }}})],1)],1),_vm._v(" "),_c('v-col',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['fundSpecificInfo'].title}}),_vm._v(" "),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                  _vm.fund,
                  'precontractualReport.fundSpecificInfo',
                )},on:{"input":(val) =>
                  _vm.triggerUpdatePrecontractualReport('fundSpecificInfo', val)}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.fundSpecificInfo},on:{"copy":(val) =>
                  _vm.triggerUpdatePrecontractualReport('fundSpecificInfo', val)}})],1)],1)],1)],1)],1),_vm._v(" "),_c('ix-dialog',{attrs:{"max-width":700,"readonly":""},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('h1',[_vm._v("\n        "+_vm._s(_vm.calculatedSfdrReport.isArticle9
            ? _vm.form.title.article9
            : _vm.form.title.article8)+"\n        "),_c('v-icon',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("\n          mdi-information-outline\n        ")])],1)]}},{key:"title",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t('esg.surveyPages.questionAdditionalInformation'))+"\n    ")]},proxy:true},{key:"text",fn:function({}){return [_c('div',{staticClass:"markdown-field survey-question-tooltip",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.form.title.article8Tooltip))}})]}}],null,false,3454812556)}),_vm._v(" "),_c('section',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['sustainableObjectiveTree'].title,"tooltip":_vm.form.questions['sustainableObjectiveTree'].tooltip}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],"value":_vm.calculatedSfdrReport.hasSustainableInvestmentObjective,"multiple":false,"disabled":true,"item-key":"label","item-value":"value","item-text":"label"}})],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_vm.formTreeviews.hasSustainableInvestmentObjective,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center text-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(item.name))}}),_vm._v(" "),(item.dynamicValue !== undefined)?_c('v-text-field',{staticClass:"ml-2 my-0 py-0 max-w-[40px]",attrs:{"value":item.dynamicValue,"readonly":!_vm.calculatedSfdrReport.isArticle9,"type":"number"},on:{"input":(val) => _vm.triggerUpdatePrecontractualReport(item.id, val)}}):_vm._e(),_vm._v(" "),(item.dynamicValue !== undefined)?_c('span',[_vm._v("%")]):_vm._e()],1)],1)]}}],null,false,3657240050)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_vm.formTreeviews.hasNoSustainableInvestmentObjective,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center text-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(item.name))}}),_vm._v(" "),(item.dynamicValue !== undefined)?_c('v-text-field',{staticClass:"ml-2 my-0 py-0 max-w-[40px]",attrs:{"value":item.dynamicValue,"readonly":_vm.calculatedSfdrReport.isArticle9,"type":"number"},on:{"input":(val) =>
                        _vm.triggerUpdatePrecontractualReport(
                          'percentSustainableInvestmentsWhilePromotingEsCharacteristics',
                          val,
                        )}}):_vm._e(),_vm._v(" "),(item.dynamicValue !== undefined)?_c('span',[_vm._v("%")]):_vm._e()],1)],1)])]}}],null,false,1830224106)})],1)],1),_vm._v(" "),_c('v-row',[_c('ix-dialog',{attrs:{"value":_vm.showCalculatedPercentage,"max-width":'700px'},scopedSlots:_vm._u([{key:"default",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v(_vm._s(_vm.form['calculatedPercentageDialog'].show))])]}},{key:"title",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.form['calculatedPercentageDialog'].title)+"\n        ")]},proxy:true},{key:"text",fn:function({ toggleDialog }){return _vm._l((_vm.calculatedPercentageQuestionKeys),function(question){return _c('div',{key:question.key},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.renderMarkdown(
                  _vm.form['calculatedPercentageTexts'](question.percentage)[
                    question.key
                  ],
                )
              )}})])})}},{key:"actions",fn:function({ toggleDialog }){return [_c('v-btn',{attrs:{"text":""},on:{"click":toggleDialog}},[_vm._v(_vm._s(_vm.form['calculatedPercentageDialog'].close))])]}}],null,false,3211549622)})],1)],1),_vm._v(" "),_c('section',[_c('ix-survey-question',{attrs:{"title":_vm.calculatedSfdrReport.isArticle9
          ? _vm.form.questions['wereObjectivesMet'].article9.title
          : _vm.form.questions['wereObjectivesMet'].article8.title,"tooltip":_vm.form.questions['wereObjectivesMet'].tooltip}}),_vm._v(" "),(_vm.calculatedSfdrReport.isArticle9)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
              _vm.fund,
              'precontractualReport.sustainableInvestmentsMadeByFund',
            )},on:{"input":(val) =>
              _vm.triggerUpdatePrecontractualReport(
                'sustainableInvestmentsMadeByFund',
                val,
              )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainableInvestmentsMadeByFund},on:{"copy":(val) =>
              _vm.triggerUpdatePrecontractualReport(
                'sustainableInvestmentsMadeByFund',
                val,
              )}})],1)],1):_vm._e(),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle9)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['esCharacteristicsDescription'].title}}),_vm._v(" "),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.esCharacteristicsDescription',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'esCharacteristicsDescription',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.esCharacteristicsDescription},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'esCharacteristicsDescription',
                  val,
                )}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.calculatedSfdrReport.isArticle9
            ? _vm.form.questions['sustainabilityIndicatorsPerformanceDescription']
                .title.article9
            : _vm.form.questions['sustainabilityIndicatorsPerformanceDescription']
                .title.article8},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions[
                  'sustainabilityIndicatorsPerformanceDescription'
                ].tooltip,
              )
            )}})]},proxy:true}],null,false,3135155986)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.sustainabilityIndicatorsPerformanceDescription',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainabilityIndicatorsPerformanceDescription',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainabilityIndicatorsPerformanceDescription},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainabilityIndicatorsPerformanceDescription',
                  val,
                )}})],1)],1)],1),_vm._v(" "),(_vm.calculatedSfdrReport.isArticle8Plus)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['sustainableInvestmentsMadeByFund'].article8plus
            .title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions['sustainableInvestmentsMadeByFund'].tooltip,
              )
            )}})]},proxy:true}],null,false,3462645131)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.sustainableInvestmentsMadeByFund',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainableInvestmentsMadeByFund',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainableInvestmentsMadeByFund},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainableInvestmentsMadeByFund',
                  val,
                )}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('ix-survey-question',{attrs:{"title":_vm.form.questions['howDidInvestmentsNotCauseHarm'].title}}),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle8)?_c('v-row',[_c('v-col',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['accountOnPais'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.renderMarkdown(_vm.form.questions['accountOnPais'].tooltip)
              )}})]},proxy:true}],null,false,946609852)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(_vm.fund, 'precontractualReport.accountOnPais')},on:{"input":(val) =>
                  _vm.triggerUpdatePrecontractualReport('accountOnPais', val)}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.accountOnPais},on:{"copy":(val) =>
                  _vm.triggerUpdatePrecontractualReport('accountOnPais', val)}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['alignmentWithOecdGuidelines'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions['alignmentWithOecdGuidelines'].tooltip,
              )
            )}})]},proxy:true}],null,false,4015424172)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.alignmentWithOecdGuidelines',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'alignmentWithOecdGuidelines',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.alignmentWithOecdGuidelines},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'alignmentWithOecdGuidelines',
                  val,
                )}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['didProductConsiderPais'].title}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.isConsideringPais,"disabled":""}}),_vm._v("\n              Yes\n            ")],1)]),_vm._v(" "),_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":!_vm.isConsideringPais,"disabled":""}}),_vm._v("\n              No\n            ")],1)])])],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['whyDidProductConsiderPais'].title,"subtitle":_vm.form.questions['whyDidProductConsiderPais'].subtitle}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.whyDidProductConsiderPais',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'whyDidProductConsiderPais',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.whyDidProductConsiderPais},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'whyDidProductConsiderPais',
                  val,
                )}})],1)],1)],1)],1),_vm._v(" "),_c('section',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['investmentStrategy'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(_vm.form.questions['investmentStrategy'].tooltip)
          )}})]},proxy:true}],null,false,1202357933)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(_vm.fund, 'precontractualReport.investmentStrategy')},on:{"input":(val) =>
              _vm.triggerUpdatePrecontractualReport('investmentStrategy', val)}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.investmentStrategy},on:{"copy":(val) =>
              _vm.triggerUpdatePrecontractualReport('investmentStrategy', val)}})],1)],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['bindingElementsOfInvestmentStrategy'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions['bindingElementsOfInvestmentStrategy'].tooltip,
              )
            )}})]},proxy:true}],null,false,3068146692)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.bindingElementsOfInvestmentStrategy',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'bindingElementsOfInvestmentStrategy',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.bindingElementsOfInvestmentStrategy},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'bindingElementsOfInvestmentStrategy',
                  val,
                )}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['companyGovernancePolicy'].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions['companyGovernancePolicy'].tooltip,
              )
            )}})]},proxy:true}],null,false,3774427762)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.companyGovernancePolicy',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'companyGovernancePolicy',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.companyGovernancePolicy},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'companyGovernancePolicy',
                  val,
                )}})],1)],1)],1)],1),_vm._v(" "),_c('section',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['sustainabilityRelatedInvestmentAssetAllocation']
            .title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions[
                  'sustainabilityRelatedInvestmentAssetAllocation'
                ].tooltip,
              )
            )}})]},proxy:true}],null,false,2588750320)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.sustainabilityRelatedInvestmentAssetAllocation',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainabilityRelatedInvestmentAssetAllocation',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainabilityRelatedInvestmentAssetAllocation},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainabilityRelatedInvestmentAssetAllocation',
                  val,
                )}})],1)],1)],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"info-box mt-4"},[_c('GChart',{attrs:{"type":"OrgChart","data":_vm.calculatedSfdrReport.investmentsCategorizationChartData,"settings":{ packages: ['orgchart'] },"options":_vm.investmentsCategorisationChartOptions}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.calculatedSfdrReport.isArticle8
            ? _vm.renderMarkdown(_vm.additionalInformation[2])
            : _vm.renderMarkdown(_vm.additionalInformation[1])
        )}})],1),_vm._v(" "),_c('br')],1),_vm._v(" "),_c('section',[(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
            'sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment'
          ].title,"subtitle":_vm.form.questions[
            'sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment'
          ].subtitle},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions[
                  'sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment'
                ].tooltip,
              )
            )}})]},proxy:true}],null,false,1059605449)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment',
              )},on:{"input":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment',
                  val,
                )
              }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment},on:{"copy":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'sustainableInvestmentsWithEnvironmentalObjectiveEuTaxonomyAlignment',
                  val,
                )
              }}})],1)],1)],1):_vm._e(),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['fossilOrNuclear'].title,"subtitle":_vm.form.questions['fossilOrNuclear'].subtitle,"tooltip":_vm.form.questions['fossilOrNuclear'].tooltip}}),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-treeview',{attrs:{"items":_vm.alignedInvestmentsTreeview,"open-all":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('span',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-simple-checkbox',{attrs:{"value":item.selected,"disabled":""}}),_vm._v(" "),_c('div',{staticClass:"treeview-label-markdown text-wrap mb-0 py-2 pl-2",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(item.name))}})],1)])]}}],null,false,1485712422)})],1)],1)],1):_vm._e(),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('div',{staticClass:"info-box mt-4"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.additionalInformation[3]))}}),_vm._v(" "),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"flex flex-col w-1/3 align-center justify-center"},[_c('v-text-field',{attrs:{"value":_vm.taxonomyAlignedFossil,"type":"number","label":"Taxonomy Aligned Fossil (%)"},on:{"input":(val) => {
                  _vm.triggerUpdatePrecontractualReport(
                    'taxonomyAlignedFossil',
                    val,
                  )
                }}}),_vm._v(" "),_c('v-text-field',{attrs:{"value":_vm.taxonomyAlignedNuclear,"type":"number","label":"Taxonomy Aligned Nuclear (%)"},on:{"input":(val) => {
                  _vm.triggerUpdatePrecontractualReport(
                    'taxonomyAlignedNuclear',
                    val,
                  )
                }}}),_vm._v(" "),_c('v-text-field',{attrs:{"value":_vm.taxonomyAlignedNoFossilNoNuclear,"type":"number","label":"Taxonomy Aligned No Fossil No Nuclear (%)"},on:{"input":(val) => {
                  _vm.triggerUpdatePrecontractualReport(
                    'taxonomyAlignedNoFossilNoNuclear',
                    val,
                  )
                }}}),_vm._v(" "),_c('v-text-field',{attrs:{"value":_vm.otherInvestments,"type":"number","label":"Other Investments (%)"},on:{"input":(val) => {
                  _vm.triggerUpdatePrecontractualReport('otherInvestments', val)
                }}})],1),_vm._v(" "),_c('div',{staticClass:"flex w-2/3 items-center justify-center h-64"},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.getPieChartData,"options":_vm.investmentsAlignedWithEuTaxonomyChartOptions(
                  'Taxonomy-alignment of investments excluding sovereign bonds*',
                )}})],1)]),_vm._v(" "),(!_vm.checkIfPercentagesSum())?_c('p',{staticClass:"text-red-500",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.additionalInformation[4]))}}):_vm._e()])]):_vm._e(),_vm._v(" "),(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
            'shareOfInvestmentsInTransitionalAndEnablingActivities'
          ].title},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions[
                  'shareOfInvestmentsInTransitionalAndEnablingActivities'
                ].tooltip,
              )
            )}})]},proxy:true}],null,false,492815404)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.shareOfInvestmentsInTransitionalAndEnablingActivities',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfInvestmentsInTransitionalAndEnablingActivities',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.shareOfInvestmentsInTransitionalAndEnablingActivities},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfInvestmentsInTransitionalAndEnablingActivities',
                  val,
                )}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('section',[(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions[
            'shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned'
          ].title,"is-mandatory":false},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.form.questions[
                  'shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned'
                ].tooltip,
              )
            )}})]},proxy:true}],null,false,1600101201)}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned',
              )},on:{"input":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned',
                  val,
                )
              }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned},on:{"copy":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfSustainableInvestmentsWithEnvironmentalObjectiveNonEuTaxonomyAligned',
                  val,
                )
              }}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('section',[(!_vm.calculatedSfdrReport.isArticle8)?_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['shareOfSociallySustainableInvestments'].title,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.shareOfSociallySustainableInvestments',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfSociallySustainableInvestments',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.shareOfSociallySustainableInvestments},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'shareOfSociallySustainableInvestments',
                  val,
                )}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('section',[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.calculatedSfdrReport.isArticle9
            ? _vm.form.questions[
                'purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards'
              ].article9.title
            : _vm.form.questions[
                'purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards'
              ].article8.title,"tooltip":_vm.form.questions[
            'purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards'
          ].tooltip}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards',
              )},on:{"input":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards',
                  val,
                )
              }}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards},on:{"copy":(val) => {
                _vm.triggerUpdatePrecontractualReport(
                  'purposeOfOtherOrNotSustainableInvestmentsIncludingSafeguards',
                  val,
                )
              }}})],1)],1)],1)],1),_vm._v(" "),_c('section',[_c('ix-survey-question',{attrs:{"title":_vm.calculatedSfdrReport.isArticle8
          ? _vm.form.questions['productPerformanceComparison'].article8.title
          : _vm.form.questions['productPerformanceComparison'].article9.title,"subtitle":_vm.form.questions['productPerformanceComparison'].subtitle,"is-mandatory":false},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.form.questions['productPerformanceComparison'].tooltip,
            )
          )}})]},proxy:true}],null,false,2577158193)}),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.calculatedSfdrReport.isArticle9
            ? _vm.form.questions['sustainablyAlignedIndexExplanation'].title
                .article9
            : _vm.form.questions['sustainablyAlignedIndexExplanation'].title
                .article8,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.sustainablyAlignedIndexExplanation',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainablyAlignedIndexExplanation',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.sustainablyAlignedIndexExplanation},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'sustainablyAlignedIndexExplanation',
                  val,
                )}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['investmentAlignedIndexExplanation'].title,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.investmentAlignedIndexExplanation',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'investmentAlignedIndexExplanation',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.investmentAlignedIndexExplanation},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'investmentAlignedIndexExplanation',
                  val,
                )}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['broadMarketIndexComparison'].title,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.broadMarketIndexComparison',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'broadMarketIndexComparison',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.broadMarketIndexComparison},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'broadMarketIndexComparison',
                  val,
                )}})],1)],1)],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_vm.form.questions['indexCalculationMethodology'].title,"is-mandatory":false}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ix-textarea',{attrs:{"value":_vm.getNestedProperty(
                _vm.fund,
                'precontractualReport.indexCalculationMethodology',
              )},on:{"input":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'indexCalculationMethodology',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',[_c('ix-info-copyable',{attrs:{"text":_vm.calculatedSfdrReport.indexCalculationMethodology},on:{"copy":(val) =>
                _vm.triggerUpdatePrecontractualReport(
                  'indexCalculationMethodology',
                  val,
                )}})],1)],1)],1)],1)],1):_c('v-container',{staticStyle:{"height":"75%"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Loading... ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"20"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }