var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',{staticClass:"indigo white--text text-h6"},[_vm._v("\n    ESG("+_vm._s(_vm.questionnaire.id)+")\n    "),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_vm._v(" "),_c('ix-data-table-sections',{attrs:{"show-select":true,"columns":['id', 'labelI18n.en', 'labelI18n.de']},on:{"input":(id) => {
            _vm.addLink({
              questionnaireId: _vm.questionnaire.id,
              sectionId: id[0],
            }).then(() => {
              _vm.$nuxt.$emit('userMsg')
              _vm.refetch()
            })
          }}})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"pa-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"5"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"min-height":"260","type":"card"}}):_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.sections,"activatable":"","item-children":"sections","item-text":"label","color":"warning","open-on-click":"","transition":""},on:{"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(!item || !item.children)?_c('v-icon',[_vm._v(" mdi-format-section ")]):_vm._e()]}},{key:"append",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                _vm.delLink({
                  questionnaireId: _vm.questionnaire.id,
                  sectionId: item.id,
                }).then(() => {
                  _vm.$nuxt.$emit('userMsg')
                  _vm.refetch()
                })
              }}},[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}])})],1),_vm._v(" "),_c('v-divider',{attrs:{"vertical":""}}),_vm._v(" "),_c('v-col',{staticClass:"d-flex text-center"},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',{staticClass:"text-h6 grey--text text--lighten-1 font-weight-light",staticStyle:{"align-self":"center"}},[_vm._v("\n          Select a [Sub]Section\n        ")]):_c('v-card',{key:_vm.selected.id,staticClass:"pt-6 mx-auto",attrs:{"flat":""}},[_c('v-card-text',[(_vm.avatar)?_c('v-avatar',{attrs:{"size":"88"}},[_c('v-img',{staticClass:"mb-6",attrs:{"src":`https://avataaars.io/${_vm.avatar}`}})],1):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"text-h5 mb-2"},[_vm._v("\n              "+_vm._s(_vm.selected.labelI18n.en)+" / "+_vm._s(_vm.selected.labelI18n.de)+"\n            ")]),_vm._v(" "),_c('ix-data-table-questions',{attrs:{"default-filters":{
                sections: [_vm.selected.id],
              }}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }