var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',[_c('ix-section-title',[_vm._v("\n    "+_vm._s(_vm.$t('impactGoal.impactSolutions.impactSolution.title'))+"\n  ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{domProps:{"innerHTML":_vm._s(
        _vm.renderMarkdown(_vm.$t('impactGoal.impactSolutions.impactSolution.text'))
      )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showIxDefinesSoution = !_vm.showIxDefinesSoution}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showIxDefinesSoution ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t(
            'impactGoal.impactSolutions.impactSolution.howIxDefinesSolutionsTitle',
          ))+"\n      ")],1)]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.showIxDefinesSoution)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
        _vm.renderMarkdown(
          _vm.$t(
            `impactGoal.impactSolutions.impactSolution.howIxDefinesSoutionsText`,
          ),
        )
      )}}):_vm._e(),_vm._v(" "),_c('v-form',{ref:"form",staticClass:"divide-y divide-primary",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionConceptTitle',
              ),"subtitle":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionConceptSubtitle',
              ),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.impactSolutionLabel,"disabled":!_vm.impactGoal.isEditable,"outlined":"","auto-grow":"","rows":"2","required":"","label":"","persistent-placeholder":"","rules":[
              (v) => !!v || _vm.$t('general.validationErrors.requiredError'),
              (v) =>
                (v && v.length <= 100) ||
                _vm.$t('general.validationErrors.lengthError.prefix') +
                  ' 100 ' +
                  _vm.$t('general.validationErrors.lengthError.suffix'),
            ]},on:{"change":(val) => _vm.handleConceptInput(val, _vm.conceptId)}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionKindTitle',
              ),"is-mandatory":""}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"value":_vm.impactSolution.kind,"item-key":"value","is-editable":"","item-value":"value","item-text":"label","options":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionKindOptions',
              )},on:{"input":(val) =>
                _vm.upsertImpactSolution({
                  kind: val,
                })}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionDescription',
              ),"subtitle":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionDescriptionSubtitle',
              ),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.impactSolution.solutionDescription,"outlined":"","auto-grow":"","rows":"2","required":"","label":"","placeholder":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionDescriptionPlaceHolder',
              ),"persistent-placeholder":"","rules":[
              (v) =>
                !!v ||
                _vm.$t(
                  'impactGoal.impactSolutions.impactSolution.solutionErrors.requiredError',
                ),
              (v) =>
                (v && v.length <= 500) ||
                _vm.$t(
                  'impactGoal.impactSolutions.impactSolution.solutionErrors.lengthError',
                ),
            ]},on:{"change":(val) =>
                _vm.upsertImpactSolution({
                  solutionDescription: val,
                })}})],1),_vm._v(" "),_c('v-col',[_c('ix-survey-question',{staticClass:"mb-4",attrs:{"title":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionTargetGroupTitle',
              ),"subtitle":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionTargetGroupSubtitle',
              ),"is-mandatory":""}}),_vm._v(" "),_c('v-textarea',{attrs:{"value":_vm.impactSolution.targetGroup,"hide-details":"auto","outlined":"","auto-grow":"","rows":"2","required":"","label":"","placeholder":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.solutionTargetGroupPlaceHolder',
              ),"persistent-placeholder":"","rules":[
              (v) =>
                !!v ||
                _vm.$t(
                  'impactGoal.impactSolutions.impactSolution.solutionErrors.requiredError',
                ),
              (v) =>
                (v && v.length <= 100) ||
                _vm.$t(
                  'impactGoal.impactSolutions.impactSolution.solutionErrors.lengthError',
                ),
            ]},on:{"change":(val) =>
                _vm.upsertImpactSolution({
                  targetGroup: val,
                })}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('impact-goal-edit-impact-solution-source-input',{attrs:{"impact-solution":_vm.impactSolution}})],1),_vm._v(" "),(
            _vm.doGhgCheck &&
            _vm.impact.hasFrozenOutcome &&
            _vm.impact.conceptId === 13613
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t(
                'impactGoal.impactSolutions.impactSolution.useGhgSavingPotentialToolTitle',
              )},on:{"change":() =>
                _vm.upsertImpactSolution({
                  useGhgSavingPotentialTool: _vm.useGhgSavingPotentialTool,
                })},model:{value:(_vm.useGhgSavingPotentialTool),callback:function ($$v) {_vm.useGhgSavingPotentialTool=$$v},expression:"useGhgSavingPotentialTool"}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }