var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.title',
    )))]),_vm._v(" "),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{on:{"click":function($event){_vm.showSelectingEmissionFactorGuidance =
            !_vm.showSelectingEmissionFactorGuidance}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showSelectingEmissionFactorGuidance
                ? 'mdi-chevron-up'
                : 'mdi-chevron-down')+"\n          ")]),_vm._v("\n          "+_vm._s(_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.guidanceLabel',
            ))+"\n        ")],1)]),_vm._v(" "),(_vm.showSelectingEmissionFactorGuidance)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
          _vm.renderMarkdown(
            _vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.guidanceText',
            ),
          )
        )}}):_vm._e()],1),_vm._v(" "),_c('v-row',[(!_vm.onlyFactor)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.customName',
            ),"hide-details":"auto"},model:{value:(_vm.CUSTOM_NAME),callback:function ($$v) {_vm.CUSTOM_NAME=$$v},expression:"CUSTOM_NAME"}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":_vm.onlyFactor ? 4 : 3}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.customGhg',
            ),"hide-details":"auto"},model:{value:(_vm.CUSTOM_GHG_FACTOR),callback:function ($$v) {_vm.CUSTOM_GHG_FACTOR=$$v},expression:"CUSTOM_GHG_FACTOR"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":_vm.onlyFactor ? 4 : 3}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.customUnit',
            ),"hide-details":"auto"},model:{value:(_vm.CUSTOM_UNIT),callback:function ($$v) {_vm.CUSTOM_UNIT=$$v},expression:"CUSTOM_UNIT"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":_vm.onlyFactor ? 4 : 3}},[_c('v-text-field',{attrs:{"t-field":"","outlined":"","label":_vm.$t(
              'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.customEvidence',
            ),"hide-details":"auto"},model:{value:(_vm.CUSTOM_EVIDENCE),callback:function ($$v) {_vm.CUSTOM_EVIDENCE=$$v},expression:"CUSTOM_EVIDENCE"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.exampleName',
              ),
            )
          )}})]),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.exampleGhg',
              ),
            )
          )}})]),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.exampleUnit',
              ),
            )
          )}})])],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t(
        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.closeModal',
      )))]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addCustomGHG}},[_vm._v(_vm._s(_vm.$t(
        'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.emissionSources.customComparisonModal.addAndCloseModal',
      )))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }