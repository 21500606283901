import { render, staticRenderFns } from "./EsgPerformance.vue?vue&type=template&id=4aac216c&scoped=true"
import script from "./EsgPerformance.vue?vue&type=script&lang=ts"
export * from "./EsgPerformance.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aac216c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PortfolioEsgTotalScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/portfolio/EsgTotalScore.vue').default,IxEsgScoresAndDetails: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/EsgScoresAndDetails.vue').default,IxDisclosure: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Disclosure.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
