import "core-js/modules/es6.array.filter.js";
import { useQueryLoading } from '@vue/apollo-composable';
import { logObserver, isDisclosure } from '~/functions/helpers';
import { mapQuestionnaireResultsBySectionLabel, questionnaireVersionTextShort } from '~/functions/esg';
import { useGetQuestionnaireForComparisonQuery, AggregatedQuestionnaireResultsVentureFilterType, useGetAggregatedEsgQuestionnaireResultQuery } from '~/types/types';
export default defineComponent({
  props: {
    first: {
      type: Object,
      required: true
    },
    second: {
      type: Object,
      required: true
    },
    titleFirst: {
      type: String,
      required: false
    },
    titleSecond: {
      type: String,
      required: false
    }
  },
  setup: function setup(props) {
    var firstVersionText = computed(function () {
      var version = questionnaireVersionTextShort(props.first);
      return props.titleFirst ? "".concat(props.titleFirst, ": ").concat(version) : version;
    });
    var secondVersionText = computed(function () {
      var version = questionnaireVersionTextShort(props.second);
      return props.titleSecond ? "".concat(props.titleSecond, ": ").concat(version) : version;
    });
    var _useGetQuestionnaireF = useGetQuestionnaireForComparisonQuery({
        id: props.first.id
      }),
      resultGetFirst = _useGetQuestionnaireF.result,
      refetchFirst = _useGetQuestionnaireF.refetch;
    var _useGetQuestionnaireF2 = useGetQuestionnaireForComparisonQuery({
        id: props.second.id
      }),
      resultGetSecond = _useGetQuestionnaireF2.result,
      refetchSecond = _useGetQuestionnaireF2.refetch;
    watch(function () {
      return [props.first.id, props.second.id];
    }, function (current, _) {
      refetchFirst({
        id: current[0]
      });
      refetchSecond({
        id: current[1]
      });
    });
    var firstData = computed(function () {
      var _resultGetFirst$value, _resultGetFirst$value2;
      if (!resultGetFirst.value) return {};
      return mapQuestionnaireResultsBySectionLabel(((_resultGetFirst$value = resultGetFirst.value.getQuestionnaire) === null || _resultGetFirst$value === void 0 ? void 0 : (_resultGetFirst$value2 = _resultGetFirst$value.questionnaireAssignment) === null || _resultGetFirst$value2 === void 0 ? void 0 : _resultGetFirst$value2.questionnaireResults) || []);
    });
    var esgSections = computed(function () {
      var _resultGetFirst$value3, _resultGetFirst$value4, _resultGetFirst$value5, _resultGetFirst$value6;
      return ((_resultGetFirst$value3 = resultGetFirst.value) === null || _resultGetFirst$value3 === void 0 ? void 0 : (_resultGetFirst$value4 = _resultGetFirst$value3.getQuestionnaire) === null || _resultGetFirst$value4 === void 0 ? void 0 : (_resultGetFirst$value5 = _resultGetFirst$value4.questionnaireTemplate) === null || _resultGetFirst$value5 === void 0 ? void 0 : (_resultGetFirst$value6 = _resultGetFirst$value5.sections) === null || _resultGetFirst$value6 === void 0 ? void 0 : _resultGetFirst$value6.filter(function (section) {
        return section && section.id && !isDisclosure(section.id);
      })) || [];
    });
    var secondData = computed(function () {
      var _resultGetSecond$valu, _resultGetSecond$valu2;
      if (!resultGetSecond.value) return {};
      return mapQuestionnaireResultsBySectionLabel(((_resultGetSecond$valu = resultGetSecond.value.getQuestionnaire) === null || _resultGetSecond$valu === void 0 ? void 0 : (_resultGetSecond$valu2 = _resultGetSecond$valu.questionnaireAssignment) === null || _resultGetSecond$valu2 === void 0 ? void 0 : _resultGetSecond$valu2.questionnaireResults) || []);
    });
    var _useGetAggregatedEsgQ = useGetAggregatedEsgQuestionnaireResultQuery({
        input: {
          scope: AggregatedQuestionnaireResultsVentureFilterType.Ix
        }
      }),
      resultAggregatedAll = _useGetAggregatedEsgQ.result;
    var thirdData = computed(function () {
      var _resultAggregatedAll$;
      if (!resultAggregatedAll.value) return null;
      return mapQuestionnaireResultsBySectionLabel((_resultAggregatedAll$ = resultAggregatedAll.value) === null || _resultAggregatedAll$ === void 0 ? void 0 : _resultAggregatedAll$.getAggregatedEsgQuestionnaireResult);
    });
    var loading = useQueryLoading();
    return {
      loading: loading,
      esgSections: esgSections,
      firstData: firstData,
      firstVersionText: firstVersionText,
      secondData: secondData,
      thirdData: thirdData,
      secondVersionText: secondVersionText
    };
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    logObserver: logObserver
  }
});