import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.string.pad-start.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.user.isAdmin ? _c('div', [_c('v-data-table', _vm._b({
    staticClass: "flex-grow-1",
    attrs: {
      "multi-sort": "",
      "fixed-header": "",
      "locale": _setup.$i18n.locale,
      "loading": _setup.loading,
      "items": _setup.items,
      "headers": _setup.headers.map(function (h) {
        return _objectSpread(_objectSpread({}, h), {}, {
          text: _vm.$t(h.value)
        });
      }),
      "options": _setup.options,
      "server-items-length": _setup.itemCount,
      "sort-by": "label",
      "footer-props": {
        'items-per-page-options': [15, 30, 50, -1]
      },
      "items-per-page": _setup.options.itemsPerPage,
      "item-key": "id",
      "show-select": "",
      "show-expand": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _setup.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header.status",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var _setup$statusFilters;
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [(_setup$statusFilters = _setup.statusFilters) !== null && _setup$statusFilters !== void 0 && _setup$statusFilters.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.statusFilters.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.statusFilters,
            callback: function callback($$v) {
              _setup.statusFilters = $$v;
            },
            expression: "statusFilters"
          }
        }, _vm._l([_setup.ConceptStatus.Autoverified, _setup.ConceptStatus.Disabled, _setup.ConceptStatus.Generated, _setup.ConceptStatus.Proposed, _setup.ConceptStatus.Verified], function (item) {
          return _c('v-list-item', {
            key: item,
            attrs: {
              "value": item
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var active = _ref3.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "header.scheme",
      fn: function fn(_ref4) {
        var header = _ref4.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var _setup$schemeFilters;
              var on = _ref5.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [(_setup$schemeFilters = _setup.schemeFilters) !== null && _setup$schemeFilters !== void 0 && _setup$schemeFilters.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.schemeFilters.length
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.schemeFilters,
            callback: function callback($$v) {
              _setup.schemeFilters = $$v;
            },
            expression: "schemeFilters"
          }
        }, _vm._l([_setup.RiskManagementScheme.Implementation, _setup.RiskManagementScheme.MonitoringEvaluation, _setup.RiskManagementScheme.ReductionSubstitution, _setup.RiskManagementScheme.Compensation, null], function (item) {
          return _c('v-list-item', {
            key: item,
            attrs: {
              "value": item
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref6) {
                var active = _ref6.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "header.isMaterial",
      fn: function fn(_ref7) {
        var header = _ref7.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": "",
            "content-class": "bg-white"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [_setup.isMaterialFilter.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.isMaterialFilter
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', {
          staticClass: "h-[100px]"
        }, [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.isMaterialFilter,
            callback: function callback($$v) {
              _setup.isMaterialFilter = $$v;
            },
            expression: "isMaterialFilter"
          }
        }, _vm._l([true, false], function (item) {
          return _c('v-list-item', {
            key: item,
            attrs: {
              "value": item
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref9) {
                var active = _ref9.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "header.isSubmeasure",
      fn: function fn(_ref10) {
        var header = _ref10.header;
        return [_vm._v("\n      " + _vm._s(header.text) + "\n      "), _c('v-menu', {
          attrs: {
            "bottom": "",
            "close-on-content-click": false,
            "left": "",
            "content-class": "bg-white"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                }
              }, on), [_setup.isSubmeasureFilter.length ? _c('v-badge', {
                attrs: {
                  "color": "green",
                  "content": _setup.isSubmeasureFilter
                }
              }, [_c('v-icon', [_vm._v("mdi-filter")])], 1) : _c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _c('v-list', {
          staticClass: "h-[100px]"
        }, [_c('v-list-item-group', {
          attrs: {
            "multiple": ""
          },
          model: {
            value: _setup.isSubmeasureFilter,
            callback: function callback($$v) {
              _setup.isSubmeasureFilter = $$v;
            },
            expression: "isSubmeasureFilter"
          }
        }, _vm._l([{
          label: 'Actions',
          value: true
        }, {
          label: 'Measures',
          value: false
        }], function (item) {
          return _c('v-list-item', {
            key: item.value,
            attrs: {
              "value": item.value
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref12) {
                var active = _ref12.active;
                return [_c('v-list-item-action', [_c('v-checkbox', {
                  attrs: {
                    "input-value": active,
                    "color": "primary"
                  }
                })], 1), _vm._v(" "), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.label))])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1)];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("List of Measures and Actions")]), _vm._v(" "), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm._v(" "), _c('v-spacer'), _vm._v(" "), _c('v-text-field', {
          staticClass: "mx-4",
          attrs: {
            "value": _setup.internalFilters.text,
            "append-icon": "mdi-magnify",
            "label": "Search",
            "single-line": "",
            "hide-details": ""
          },
          on: {
            "input": function input(v) {
              _setup.options = _objectSpread(_objectSpread({}, _setup.options), {}, {
                page: 1
              });
              _setup.internalFilters.text = v;
              _vm.$emit('update:text', v); // FIXME `update:query.text` doesn't work
            }
          }
        }), _vm._v(" "), _setup.esgConcepts == true ? _c('v-dialog', {
          attrs: {
            "max-width": "50%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref13) {
              var on = _ref13.on,
                attrs = _ref13.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "dark": ""
                },
                on: {
                  "click": _setup.openNewMeasureDialog
                }
              }, 'v-btn', attrs, false), on), [_vm._v("\n              " + _vm._s(_vm.$t('addMeasure')) + "\n            ")]), _vm._v(" "), _c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "dark": ""
                },
                on: {
                  "click": _setup.openNewActionDialog
                }
              }, 'v-btn', attrs, false), on), [_vm._v("\n              " + _vm._s(_vm.$t('addAction')) + "\n            ")])];
            }
          }], null, false, 1620711771),
          model: {
            value: _setup.createDialog,
            callback: function callback($$v) {
              _setup.createDialog = $$v;
            },
            expression: "createDialog"
          }
        }, [_vm._v(" "), _c('ix-esg-measure-edit', {
          attrs: {
            "item": _setup.newItem,
            "dialog-type": _setup.dialogType
          },
          on: {
            "input": function input(v) {
              return _setup.createDialog = v;
            }
          }
        })], 1) : _vm._e(), _vm._v(" "), _c('v-btn', {
          staticClass: "mb-2",
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _setup.refetch
          }
        }, [_vm._v("\n          " + _vm._s(_vm.$t('refresh')) + "\n        ")])], 1)];
      },
      proxy: true
    }, {
      key: "item.id",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(_vm._s(item.id))];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref15) {
        var headers = _ref15.headers,
          item = _ref15.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [item.id ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('ix-data-table-concepts', {
          attrs: {
            "height": '300px',
            "show-selected": false,
            "show-filters": false,
            "allow-edit": false,
            "allow-custom": false,
            "show-action": false,
            "is-action": item.isSubmeasure,
            "columns": ['id', 'label', 'status', 'sourceUrl', 'citation', 'isSubmeasure', 'actions'],
            "query": {
              submeasureOfConceptsId: [item.id]
            }
          }
        })], 1), _vm._v(" "), _c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('ix-data-table-audit-logs', {
          staticClass: "max-h-[400px] overflow-scroll",
          attrs: {
            "columns": ['diff', 'operationType', 'createdBy', 'createdAt'],
            "default-filters": {
              tableName: ['concept'],
              dataId: '00000000-0000-0000-0000-' + item.id.toString(16).padStart(12, '0')
            }
          }
        })], 1)], 1) : _vm._e()], 1)];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('ix-search-text', {
          attrs: {
            "text": item.label,
            "filter": _setup.internalFilters.text
          }
        })];
      }
    }, {
      key: "item.labelI18n.en",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('ix-search-text', {
          attrs: {
            "text": item.labelI18n.en,
            "filter": _setup.internalFilters.text
          }
        })];
      }
    }, {
      key: "item.labelI18n.de",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('ix-search-text', {
          attrs: {
            "text": item.labelI18n.de,
            "filter": _setup.internalFilters.text
          }
        })];
      }
    }, {
      key: "item.isSubmeasure",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_vm._v("\n      " + _vm._s(item.isSubmeasure ? 'Action' : 'Measure') + "\n    ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('v-dialog', {
          attrs: {
            "scrollable": "",
            "value": _setup.editDialog[item.id],
            "max-width": "50%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref21) {
              var on = _ref21.on,
                attrs = _ref21.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click() {
                    _setup.editDialog[item.id] = true;
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("\n            mdi-pencil\n          ")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c('ix-esg-measure-edit', {
          attrs: {
            "item": item
          },
          on: {
            "input": function input(v) {
              _setup.setEditDialog(item.id, v);
            }
          }
        })], 1), _vm._v(" "), _c('v-dialog', {
          attrs: {
            "max-width": "70%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref22) {
              var on = _ref22.on,
                attrs = _ref22.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true),
          model: {
            value: _setup.deleteDialog,
            callback: function callback($$v) {
              _setup.deleteDialog = $$v;
            },
            expression: "deleteDialog"
          }
        }, [_vm._v(" "), _c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v("\n            Are you sure you want to delete this item?\n          ")]), _vm._v(" "), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _setup.closeDelete
          }
        }, [_vm._v("\n              Cancel\n            ")]), _vm._v(" "), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": "",
            "disabled": ""
          },
          on: {
            "click": function click($event) {
              return _setup.deleteItem(item);
            }
          }
        }, [_vm._v("\n              OK(WIP)\n            ")]), _vm._v(" "), _c('v-spacer')], 1)], 1)], 1)];
      }
    }], null, true),
    model: {
      value: _setup.selected,
      callback: function callback($$v) {
        _setup.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', _vm.$attrs, false))], 1) : !_setup.loading ? _c('div', [_vm._v("404")]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };