var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"locale":_vm.$i18n.locale,"value":_vm.selected,"loading":_vm.loading,"dense":"","fixed-header":"","item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"search":_vm.search,"show-select":_vm.showSelect,"items":_vm.items,"sort-by":"createdAt","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Questions ")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.filters.text,"append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},on:{"input":(v) => {
            _vm.filters = { ..._vm.filters, text: v }
            _vm.options.page = 1
          }}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true},{key:`item.id`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.id)+"\n  ")]}},{key:`item.label`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"text":item.label,"filter":_vm.filters.text}})]}},{key:`item.scoringRules`,fn:function({ item }){return [_vm._v("\n    "+_vm._s(JSON.stringify(item.scoringRules))+"\n  ")]}},{key:`item.templateTexts`,fn:function({ item }){return [_c('v-carousel',{attrs:{"height":"100%","hide-delimiters":""}},_vm._l((item.templateTexts),function(obj){return _c('v-carousel-item',{key:`carousel-item-${obj.id}`,staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"text-body-2",staticStyle:{"max-height":"100%","overflow":"auto"}},[_vm._v("\n          Text:\n          "),_c('br'),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightPlaceholders(obj.text))}}),_vm._v(" "),_c('br'),_vm._v("\n          Conditions:\n          "),_c('br'),_vm._v(" "),_c('div',[_c('JsonEditorVue',_vm._b({model:{value:(obj.conds),callback:function ($$v) {_vm.$set(obj, "conds", $$v)},expression:"obj.conds"}},'JsonEditorVue',{
                /* local props & attrs */
              },false))],1)])])}),1)]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('ix-dialog-question',{staticStyle:{"margin-top":"500px"},attrs:{"id":item.id,"max-width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("\n            mdi-pencil\n          ")])]}}],null,true)}),_vm._v(" "),_c('v-icon',{attrs:{"disabled":"","small":""},on:{"click":function($event){_vm.toDeleteVentureId = item.id}}},[_vm._v("\n        mdi-delete\n      ")])],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }