export default defineComponent({
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    ventureId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      isError: false,
      rules: {
        requiredRule: function requiredRule(v) {
          return !!v || 'This field is required';
        }
      }
    };
  },
  computed: {
    form: function form() {
      return this.$refs.form;
    },
    isEditable: function isEditable() {
      return this.type === 'add' || this.type === 'edit' && this.value.createdBy === this.ventureId;
    }
  },
  methods: {
    validate: function validate() {
      return this.form.validate();
    }
  }
});