import "core-js/modules/es6.object.freeze.js";
import { useMutation, useQueryLoading } from '@vue/apollo-composable';
import _ from 'lodash';
import ADD_TASK_ASSIGNMENT from '~/graphql/mutations/task/AddTaskAssignment.gql';
import UPDATE_TASK_ASSIGNMENTS from '~/graphql/mutations/task/UpdateTaskAssignments.gql';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    taskAssignment: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  setup: function setup(props) {
    var temp = Object.freeze({
      origin: 'Company',
      status: 'Not started',
      task: {},
      assignedBy: props.ventureId
    });
    function calculatePrefilledFormValues(taskAssignment) {
      return props.type === 'edit' ? _.cloneDeep(taskAssignment) : _.cloneDeep(temp);
    }
    var editedTaskAssignment = ref(calculatePrefilledFormValues(props.taskAssignment));
    watch(function () {
      return props.taskAssignment;
    }, function (newVal, _) {
      editedTaskAssignment.value = calculatePrefilledFormValues(newVal);
    });
    var loading = useQueryLoading();
    var _useMutation = useMutation(ADD_TASK_ASSIGNMENT),
      addTaskAssignment = _useMutation.mutate;
    var _useMutation2 = useMutation(UPDATE_TASK_ASSIGNMENTS),
      updateTaskAssignments = _useMutation2.mutate;
    return {
      editedTaskAssignment: editedTaskAssignment,
      updateTaskAssignments: updateTaskAssignments,
      addTaskAssignment: addTaskAssignment,
      loading: loading,
      calculatePrefilledFormValues: calculatePrefilledFormValues
    };
  },
  data: function data() {
    return {
      isError: false,
      rules: {
        requiredRule: function requiredRule(v) {
          return !!v || 'This field is required';
        },
        startDateShouldBeBeforeDueDate: function startDateShouldBeBeforeDueDate(editedTaskAssignment) {
          var isValid = true;
          if (editedTaskAssignment.startDate && editedTaskAssignment.endDate) {
            isValid = new Date(editedTaskAssignment.startDate) <= new Date(editedTaskAssignment.endDate);
          }
          return isValid || 'The start date should be before (or the same as) the due date';
        }
      }
    };
  },
  computed: {},
  methods: {
    submit: function submit() {
      if (this.$refs.taskForm !== undefined && this.$refs.taskForm.validate() && this.$refs.taskAssignmentForm !== undefined && this.$refs.taskAssignmentForm.validate()) {
        var input = _.cloneDeep(this.editedTaskAssignment);
        delete input.task.__typename;
        delete input.__typename;
        delete input.updatedAt;
        if (this.type === 'add') {
          input.task.createdBy = this.ventureId;
          input.ventureId = this.ventureId;
          this.addTaskAssignment({
            input: input
          });
          this.resetForm();
        } else if (this.type === 'edit') {
          this.updateTaskAssignments({
            input: input
          });
        }
        this.$emit('submit');
        this.resetForm();
      }
    },
    resetForm: function resetForm() {
      this.editedTaskAssignment = this.calculatePrefilledFormValues(this.taskAssignment);
    }
  }
});