import { renderMarkdown } from '~/functions/markdown';
export default {
  props: {
    onlyFactor: {
      type: Boolean,
      default: false
    },
    solution: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(props) {
    var _props$initialData, _props$initialData2, _props$initialData3, _props$initialData4, _props$initialData5, _props$initialData6, _props$initialData7;
    var CUSTOM_NAME = ref(props.solution ? ((_props$initialData = props.initialData) === null || _props$initialData === void 0 ? void 0 : _props$initialData.solutionConceptName) || null : ((_props$initialData2 = props.initialData) === null || _props$initialData2 === void 0 ? void 0 : _props$initialData2.baselineConceptName) || null);
    var CUSTOM_GHG_FACTOR = ref(props.solution ? ((_props$initialData3 = props.initialData) === null || _props$initialData3 === void 0 ? void 0 : _props$initialData3.solutionGhgFactor) || null : ((_props$initialData4 = props.initialData) === null || _props$initialData4 === void 0 ? void 0 : _props$initialData4.baselineGhgFactor) || null);
    var CUSTOM_UNIT = ref(((_props$initialData5 = props.initialData) === null || _props$initialData5 === void 0 ? void 0 : _props$initialData5.unitName) || null);
    var CUSTOM_EVIDENCE = ref(props.solution ? ((_props$initialData6 = props.initialData) === null || _props$initialData6 === void 0 ? void 0 : _props$initialData6.solutionEvidence) || '' : ((_props$initialData7 = props.initialData) === null || _props$initialData7 === void 0 ? void 0 : _props$initialData7.baselineEvidence) || '');
    return {
      CUSTOM_NAME: CUSTOM_NAME,
      CUSTOM_GHG_FACTOR: CUSTOM_GHG_FACTOR,
      CUSTOM_UNIT: CUSTOM_UNIT,
      CUSTOM_EVIDENCE: CUSTOM_EVIDENCE,
      renderMarkdown: renderMarkdown
    };
  },
  data: function data() {
    return {
      showSelectingEmissionFactorGuidance: false
    };
  },
  methods: {
    selectItem: function selectItem(item) {
      this.$emit('selectItem', item[0]);
    },
    addCustomGHG: function addCustomGHG() {
      this.$emit('selectItem', {
        name: this.CUSTOM_NAME,
        factor: this.CUSTOM_GHG_FACTOR,
        unit: this.CUSTOM_UNIT,
        evidence: this.CUSTOM_EVIDENCE,
        customBaselineConcept: true
      });
    },
    closeModal: function closeModal() {
      this.$emit('closeModal');
    }
  }
};