import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  props: {
    recommendedBusinessActivities: {
      type: Array,
      required: true
    },
    selectedActivityIds: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var selectedRecommendedActivities = ref(props.recommendedBusinessActivities.filter(function (e) {
      return e.id && props.selectedActivityIds.includes(e.id);
    }));
    return {
      selectedRecommendedActivities: selectedRecommendedActivities
    };
  },
  data: function data() {
    return {
      activityTableHeader: [{
        text: 'Business Activity',
        value: 'label'
      }]
    };
  }
});