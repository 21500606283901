import { render, staticRenderFns } from "./CustomReferencePeriodPicker.vue?vue&type=template&id=57484e3e"
import script from "./CustomReferencePeriodPicker.vue?vue&type=script&lang=ts"
export * from "./CustomReferencePeriodPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CustomReferencePeriodPicker.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxFormInputDateRangePicker: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/DateRangePicker.vue').default,IxFormInputDateRangePicker2: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/DateRangePicker2.vue').default})
