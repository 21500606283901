var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._b({attrs:{"loading":_vm.loading,"value":_vm.selected,"dense":"","item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"items":_vm.items,"sort-by":"name","options":_vm.options,"server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [15, 30, 50, -1],
  },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.options.search,"append-icon":"mdi-magnify","label":_vm.$t('labelSearchVentures'),"single-line":"","hide-details":""},on:{"input":(v) => {
            _vm.options.search = v
            _vm.options.page = 1
          }}})],1)]},proxy:true},{key:`item.email`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"filter":_vm.options.search,"text":item.email}})]}},{key:`item.firstName`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"filter":_vm.options.search,"text":item.firstName}})]}},{key:`item.lastName`,fn:function({ item }){return [_c('ix-search-text',{attrs:{"filter":_vm.options.search,"text":item.lastName}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-layout',{staticClass:"flex-nowrap pa-4",attrs:{"row":""}},[_c('v-btn',{staticClass:"mb-2 custom-border-box",attrs:{"outlined":"","icon":""},on:{"click":function($event){_vm.toDeleteVentureId = item.id}}},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {}}},[_vm._v(" mdi-delete ")])],1)],1)]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":8}},[_c('ix-data-table-user-log-events',{staticClass:"max-h-[670px] overflow-scroll",attrs:{"columns":['user_id', 'user_agent', 'ip', 'location', 'date'],"email":item.email}})],1),_vm._v(" "),_c('td',{attrs:{"colspan":2}},[_c('ix-data-table-audit-logs',{staticClass:"max-h-[670px] overflow-scroll",attrs:{"columns":['diff', 'createdBy', 'createdAt'],"default-filters":{
          tableName: ['ix_tbl_user'],
          dataId: item.id,
        }}})],1)]}}],null,true)},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }