import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    itemDisabled: {
      type: String,
      default: 'disabled'
    }
  },
  setup: function setup(props) {
    var selectedValues = computed(function () {
      return props.value.map(function (e) {
        return e[props.itemValue];
      });
    });
    return {
      selectedValues: selectedValues
    };
  }
});