import { render, staticRenderFns } from "./compare.vue?vue&type=template&id=51c972d4"
import script from "./compare.vue?vue&type=script&lang=ts"
export * from "./compare.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/SectionTitle.vue').default,VentureSummary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/VentureSummary.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VContainer})
