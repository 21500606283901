import { render, staticRenderFns } from "./OutcomeStakeholderDialog.vue?vue&type=template&id=9a86f9b8"
import script from "./OutcomeStakeholderDialog.vue?vue&type=script&lang=ts"
export * from "./OutcomeStakeholderDialog.vue?vue&type=script&lang=ts"
import style0 from "./OutcomeStakeholderDialog.vue?vue&type=style&index=0&id=9a86f9b8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,ImpactGoalEditImpactSourceAssignmentsInput: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/edit/impact/SourceAssignmentsInput.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VForm,VIcon,VSelect,VTextarea})
