import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { camelCase } from '~/functions/helpers';
export default defineComponent({
  props: {
    dimension: Object,
    outcome: Object
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
      root = _ref.root;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _toRefs = toRefs(props),
      dimension = _toRefs.dimension,
      outcome = _toRefs.outcome;
    var state = reactive({
      indicators: [],
      nConfirmedIndicators: 0,
      score: 0
    });
    var nIndicators = computed(function () {
      return state.indicators.length;
    });
    function safeScore(score) {
      return parseFloat(score) || 0;
    }
    function getIndicators(outcome) {
      return $store.getters['assessment/getIndicators'](outcome.id);
    }
    function updateIndicators() {
      if (outcome.value !== undefined) {
        return getIndicators(outcome.value).filter(function (e) {
          return e['IMP dimensions'] === dimension.value.name;
        }).sort(function (a, b) {
          return a.Indicator > b.Indicator ? 1 : -1;
        });
      }
      return [];
    }
    function handleChangeInIndicator() {
      state.indicators = updateIndicators();
      state.nConfirmedIndicators = state.indicators.reduce(function (acc, cVal) {
        return acc + +(cVal.Confirmed !== undefined && cVal.Confirmed === true);
      }, 0);
      state.score = state.indicators.reduce(function (acc, cVal) {
        return acc + +(safeScore(cVal.Score) * +cVal['Scoring weight']);
      }, 0);
      emit('change');
    }
    watch(outcome, handleChangeInIndicator);
    var complete = computed(function () {
      return state.nConfirmedIndicators === nIndicators.value;
    });
    watch(complete, function () {
      emit('change');
    });
    var assessment = computed(function () {
      return $store.getters['assessment/getAssessment'];
    });
    var outcomeEditingState = computed(function () {
      return $store.getters['assessment/getEditingState'](outcome.value.id);
    });
    onMounted(handleChangeInIndicator);
    return _objectSpread(_objectSpread({}, toRefs(state)), {}, {
      nIndicators: nIndicators,
      complete: complete,
      assessment: assessment,
      outcomeEditingState: outcomeEditingState,
      camelCase: camelCase
    });
  }
});