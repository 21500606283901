var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"d-flex align-center info-card"},[(_vm.getAverageAnnualReduction.hcpCompany == true)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"blue-grey lighten-2","size":"40"}},[_vm._v("\n            mdi-trophy-outline\n          ")]),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('impactGoal.overview.climateImpactModule.reductionTextHcp', {
                averageAnnualReduction:
                  _vm.getAverageAnnualReduction.averageAnnualReduction,
              }))+"\n          ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v("\n                mdi-information-outline\n              ")])]}}],null,false,2100911430)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('impactGoal.overview.climateImpactModule.aboveAverage')))])])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"blue-grey lighten-2","size":"40"}},[_vm._v("\n            mdi-earth\n          ")]),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('impactGoal.overview.climateImpactModule.reductionText', {
                lowerBound: _vm.getRoundedBounds.lowerBound,
                upperBound: _vm.getRoundedBounds.upperBound,
                benchmarkText: _vm.benchmarkText,
                averageAnnualReduction:
                  _vm.getAverageAnnualReduction.averageAnnualReduction,
              }))+"\n          ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v("\n                mdi-information-outline\n              ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('impactGoal.overview.climateImpactModule.belowAverage')))])])],1)])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"'6'"}},[_c('v-card',{staticClass:"d-flex align-center info-card"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"blue-grey lighten-2","size":"40"}},[_vm._v("\n          mdi-lightbulb-on-outline\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t('impactGoal.overview.climateImpactModule.representedByMoney', {
            economicValue: _vm.economicValue,
          }))+"\n        "),_c('v-tooltip',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v("\n              mdi-information-outline\n            ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.overview.climateImpactModule.representedByMoneyTooltip',
              ))+"\n          ")])])],1)],1),_vm._v(" "),(_vm.usingGhgTool)?_c('v-col',{attrs:{"cols":"12"}},[_c('apexchart',{attrs:{"type":"bar","options":_vm.vueChartOptions,"series":_vm.vueChartSeries,"height":"400"}})],1):_vm._e()],1),_vm._v(" "),_vm._l((_vm.climateImpact.impactSolutions),function(impactSolution,index){return _c('v-row',{key:index},[_c('v-col',[_c('v-card',{staticClass:"info-card"},[_c('v-card-text',{staticClass:"p-2 d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"blue-grey lighten-2","size":"40"}},[_vm._v("\n            mdi-lightbulb-on-outline\n          ")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(_vm.getComparisonPledge(impactSolution))
            )}})],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }