export default defineComponent({
  props: {
    progressBarData: {
      type: Object,
      required: true,
      default: function _default() {}
    },
    targetStyle: {
      type: Object,
      required: true,
      default: function _default() {}
    },
    progressBarTooltip: {
      type: Object,
      required: true,
      default: function _default() {}
    }
  },
  setup: function setup() {}
});