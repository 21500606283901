import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6e17fd3f&scoped=true"
import script from "./default.vue?vue&type=script&setup=true&lang=ts"
export * from "./default.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=6e17fd3f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e17fd3f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxNav: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Nav.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VBtn,VIcon,VMain,VSnackbar})
