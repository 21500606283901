
import { defineComponent, Prop, computed } from 'vue'

export default defineComponent({
  name: 'VentureSummary',
  props: {
    assessments: Array,
    stages: Array,
    stageToShow: Number,
    showAll: Boolean,
  },
  setup(props) {
    const rows = [
      {
        name: 'Company name',
        component: 'summary-cells-venture-name',
        toggle: false,
      },
      {
        name: 'Company pitchline',
        component: 'summary-cells-venture-pitchline',
        toggle: false,
      },
      { name: '', component: 'summary-cells-divider' },
      {
        name: 'Impact Potential Score',
        component: 'summary-cells-venture-potential-score',
        toggle: false,
      },
      {
        name: 'Main impacts',
        component: 'summary-cells-venture-outcomes',
        toggle: false,
      },
      {
        name: 'SDGs',
        component: 'summary-cells-venture-sdgs',
        toggle: false,
      },
      {
        name: 'Target geographies',
        component: 'summary-cells-venture-target-geographies',
        toggle: false,
      },
      {
        name: 'Reviewer comments',
        component: 'summary-cells-venture-reviewer-comments',
        toggle: false,
      },
      // {
      //   name: 'Assessment status & next steps',
      //   component: 'summary-cells-venture-status-and-next-steps',
      //   toggle: false,
      // },
    ]

    const toggledRows = computed(() => {
      if (props.showAll) {
        return rows[props.stages[props.stageToShow]]
      }
      return rows[props.stages[props.stageToShow]].filter((e: any) => e.toggle)
    })

    const impactsForAssessmentAndStage = (assessment: any) => {
      const match = assessment.outcomesByStages.find(
        (e: any) => e.stage === stage.value,
      )
      if (!match) return []
      return match.outcomes.map((id: any) => assessment.outcomes[id])
    }

    const stage = computed(() => {
      return props.stages[props.stageToShow]
    })

    const available = (assessment: any) => {
      return assessment.outcomesByStages.some(
        (e: any) => e.stage === stage.value,
      )
    }

    return {
      toggledRows,
      impactsForAssessmentAndStage,
      stage,
      available,
    }
  },
})
